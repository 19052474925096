import { VariousSettings } from 'core/domain/variousSettings';

const initialState = null;

export const createInitialState = (): VariousSettings | null => initialState;

export const SET_VARIOUS_SETTINGS = 'various-settings/set' as const;
export const CLEAR_VARIOUS_SETTINGS = 'various-settings/clear' as const;

export const setVariousSettings = (data: VariousSettings) => ({
  type: SET_VARIOUS_SETTINGS,
  payload: {
    data,
  },
});

export const clearVariousSettings = () => ({
  type: CLEAR_VARIOUS_SETTINGS,
});

type SetVariousSettings = ReturnType<typeof setVariousSettings>;
type ClearVariousSettings = ReturnType<typeof clearVariousSettings>;
type Action = SetVariousSettings | ClearVariousSettings;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_VARIOUS_SETTINGS:
      return action.payload.data;
    case CLEAR_VARIOUS_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NoticeEdit from 'components/pages/NoticeEdit';
import { Modules, Usecases } from 'core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NoticeDetail } from 'core/domain/notices';
import { hooks } from 'libs';
import { clearNoticeDetail } from 'core/modules/noticeDetail';
import { setNoticeFiles, clearNoticeFiles } from 'core/modules/noticeFiles';

const NoticeEditPageContainer = () => {
  const dispatch = useDispatch();
  const { noticeId } = useParams<{ noticeId: string }>();
  const noticeDetail = useSelector(
    (state: Modules.AppState) => state.NoticeDetail,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  useEffect(
    () => () => {
      dispatch(clearNoticeDetail());
      dispatch(clearNoticeFiles());
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  // この中でAPI通信処理
  useEffect(() => {
    if (!userInfo || !token || !noticeId) {
      return;
    }

    dispatch(
      Usecases.notices.sendGetNoticeDetail(token, userInfo?.tenantId, noticeId),
    );

    //
  }, [dispatch, userInfo, token, noticeId]);

  useEffect(() => {
    if (!userInfo || !token || !noticeId) {
      return;
    }

    dispatch(setNoticeFiles(noticeDetail?.noticeFiles || []));
  }, [dispatch, userInfo, token, noticeId, noticeDetail]);

  // 登録ボタン押下時の処理
  const handleSubmit = (data: NoticeDetail) => {
    if (userInfo?.tenantId === undefined) return;
    // const baseURL = `${process.env.REACT_APP_SALAD_BAR_API_LOCAL_ENDPOINT}/`;

    // 新規か更新かでエンドポイントとメソッドを変更
    if (!noticeId) {
      dispatch(
        Usecases.notices.postNoticeDetail(
          token,
          userInfo?.tenantId,
          noticeId,
          data,
        ),
      );
    } else {
      dispatch(
        Usecases.notices.putNoticeDetail(
          token,
          userInfo?.tenantId,
          noticeId,
          data,
        ),
      );
    }
  };

  return (
    <NoticeEdit
      noticeDetail={noticeDetail}
      handleSubmit={(data: NoticeDetail) => handleSubmit(data)}
    />
  );
};

export default NoticeEditPageContainer;

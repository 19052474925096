import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar } from 'components/atoms';
import {
  Content,
  LPsTable,
  LPSearchField,
  TagSelectDialog,
  LpProductDetailDialog,
} from 'components/organisms';
import { hooks } from 'libs';
import { LpSearchCondition, LpTags } from 'core/domain/lp';
import { UserInfoDetail } from 'core/domain/user';

/**
 * Interface
 */

export interface LPsPageProps {
  rows: number;
  tags: LpTags | null;
  lpSearchCondition: LpSearchCondition | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleCheckStatus?: (status: string) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeTag?: (tags: string) => void;
  handleChangeOwnSwitch?: (checked: boolean) => void;
  handleClickFavorite: (lpId: number, checkd: boolean) => void;
  deleteTag?: (val: string) => void;
  handleClickLpProductInterestedCount: (lpId: number) => void;
  handleClickLpProductDocumentDownloadCount: (lpId: number) => void;
  openLpProductDetailDialog:
    | 'interested-count'
    | 'document-download-count'
    | undefined;
  handleClickLpProductClose: () => void;
  handleCheckLpStatus?: (status: string) => void;
  handleChangeLpTantoUser: (users: Array<UserInfoDetail>) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(1),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */

const LPsPage = React.memo((props: LPsPageProps) => {
  const classes = useStyles({});
  const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const { navigate } = hooks.useNavigate();
  const { setCurrentLpsUrl } = hooks.useLpsNavigation();
  const {
    rows,
    tags,
    lpSearchCondition,
    handleChangePage,
    handleChangeSortKey,
    handleCheckStatus,
    handleChangeFreeWord,
    handleChangeTag,
    handleChangeOwnSwitch,
    handleClickFavorite,
    deleteTag,
    handleClickLpProductInterestedCount,
    handleClickLpProductDocumentDownloadCount,
    openLpProductDetailDialog,
    handleClickLpProductClose,
    handleCheckLpStatus,
    handleChangeLpTantoUser,
  } = props;

  const lps = useSelector((state: Modules.AppState) => state.lps, shallowEqual);
  const [selectedTags, setSelectedTags] = useState<string[]>(
    lpSearchCondition && lpSearchCondition?.tags
      ? lpSearchCondition?.tags?.split(',')
      : [],
  );

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag]);
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (!lpSearchCondition || !lpSearchCondition.tags) {
      setSelectedTags([]);
      setInitialSelectedTags([]);

      return;
    }

    setSelectedTags(lpSearchCondition.tags.split(','));
    setInitialSelectedTags(
      JSON.parse(JSON.stringify(lpSearchCondition.tags.split(','))),
    );
    // eslint-disable-next-line
  }, [lpSearchCondition?.tags]);

  return (
    <Box mt={5}>
      <Content title="みんなのランディングページ一覧">
        <div>
          <LPSearchField
            switchLabel="自分のみ表示"
            searchCondition={lpSearchCondition}
            handleCheckStatus={handleCheckStatus}
            handleCheckLpStatus={handleCheckLpStatus}
            handleChangeFreeWord={handleChangeFreeWord}
            addTags={() => setTagDialogVisible(true)}
            switchValue={
              lpSearchCondition && lpSearchCondition?.own
                ? lpSearchCondition?.own
                : null
            }
            handleChangeSwitch={handleChangeOwnSwitch}
            deleteTag={(val: string) => {
              if (deleteTag) deleteTag(val);
            }}
            handleChangeLpTantoUser={handleChangeLpTantoUser}
          />
          <Box className={classes.table}>
            <LPsTable
              clickLp={(id: number, approved: boolean) => {
                if (!approved) {
                  navigate(`/adm/lps/${id}`);
                } else {
                  navigate(`/adm/lps/${id}/approved`);
                }
                setCurrentLpsUrl();
              }}
              handleChangeSortKey={handleChangeSortKey}
              handleClickFavorite={handleClickFavorite}
              handleClickLpProductInterestedCount={
                handleClickLpProductInterestedCount
              }
              handleClickLpProductDocumentDownloadCount={
                handleClickLpProductDocumentDownloadCount
              }
              lpSearchCondition={lpSearchCondition}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={lpSearchCondition ? lpSearchCondition.page : 1}
                maxPage={lps ? Math.ceil(lps?.totalCount / rows) : 1}
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={lps?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
      <TagSelectDialog
        handleCheck={handleTagCheck}
        tags={tags?.lpTags ? tags?.lpTags : undefined}
        usedTags={undefined}
        open={tagDialogVisible}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagDialogVisible(false);
        }}
        handleSubmit={(tags) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          if (handleChangeTag) handleChangeTag(tags.join(','));
          setTagDialogVisible(false);
        }}
        selectedTags={selectedTags}
        category="LP"
        tagType="all"
      />

      <LpProductDetailDialog
        open={openLpProductDetailDialog !== undefined}
        handleCancel={() => handleClickLpProductClose()}
        mode={openLpProductDetailDialog}
      />
    </Box>
  );
});
export default LPsPage;

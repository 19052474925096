import { lpAdminError } from "core/domain/lp";

const initialState = null;

export const createInitialState = (): lpAdminError | null => initialState;

export const SET_LP_ADMIN_ERROR = 'lp-admin-error/set' as const;
export const CLEAR_LP_ADMIN_ERROR = 'lp-admin-error/clear' as const;

export const setLpAdminError = (data: lpAdminError) => ({
    type: SET_LP_ADMIN_ERROR,
    payload: {
      data,
    },
  });

export const clearLpLpAdminError = () => ({
    type: CLEAR_LP_ADMIN_ERROR,
  });

type SetLpAdminErrorAction  = ReturnType<typeof setLpAdminError>;
type ClearLpAdminErrorAction = ReturnType<typeof clearLpLpAdminError>;
type Action = SetLpAdminErrorAction | ClearLpAdminErrorAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_LP_ADMIN_ERROR:
      return action.payload.data;
    case CLEAR_LP_ADMIN_ERROR:
      return initialState;
    default:
      return state;
  }
};
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageTemplate } from 'components/templates';
import { HeaderBar, Sidebar } from 'components/organisms';
import ProductDetail from 'containers/ProductDetailPageContainer';
import ProductRegisterEdit from 'containers/ProductRegisterEditPageContainer';
import ExampleRegisterEdit from 'containers/ExampleRegisterEditPageContainer';
import LPDetail from 'containers/LPDetailPageContainer';
import LPCreateModeSelection from 'containers/LPCreateModeSelectionPageContainer';
import Inquiries from 'containers/InquiriesPageContainer';
import InquiryEdit from 'containers/InquiryEditPageContainer';
import Products from 'containers/ProductsPageContainer';
import Examples from 'containers/ExamplesPageContainer';
import LPsPageContainer from 'containers/LPsPageContainer';
import LpAdminPageContainer from 'containers/LpAdminPageContainer';
import MyLPsPageContainer from 'containers/MyLPsPageContainer';
import ExampleDetailPageContainer from 'containers/ExampleDetailPageContainer';
import NormalEdit from 'containers/NormalEditPageContainer';
import Dashboard from 'containers/DashboardPageContainer';
import Account from 'containers/AccountSettingsPageContainer';
import NotFound from 'containers/NotFoundPageContainer';
import { AuthError } from 'core/domain/auth';
import AuthErrorDialog from 'components/organisms/AuthErrorDialog';
import ErrorDialog from 'components/organisms/ErrorDialog';
import { AppError } from 'core/domain/appError';
import { User } from 'core/domain/user';
import UserRegisterPageContainer from 'containers/UserRegisterPageContainer';
import FooterBar from 'components/atoms/FooterBar';
import CustomerRegisterPageContainer from 'containers/CustomerRegisterPageContainer';
import NoticeEditPageContainer from 'containers/NoticeEditPageContainer';
import InquiryRegisterContainer from 'containers/InquiryRegisterContainer';
import VariousSettingsPageContainer from 'containers/VariousSettingsPageContainer';
import ManageNoticesPageContainer from 'containers/ManageNoticesPageContainer';
import NoticesPageContainer from 'containers/NoticesPageContainer';
import NoticesDetailContainer from 'containers/NoticesDetailContainer';
import InquiryDetailPageContainer from 'containers/InquiryDetailPageContainer';
import ManageUsersPageContainer from 'containers/ManageUsersPageContainer';
import CustomersContainer from 'containers/CustomersPageContainer';
import FromEditPageContainer from 'containers/InquiryFormEditPageContainer.tsx';
import MyLibraryContainer from 'containers/MyLibraryContainer';
import TenantDashboardPageContainer from '../../../containers/TenantDashboardPageContainer';
import TrashesPageContainer from '../../../containers/TrashesPageContainer';

/**
 * Interface
 */

export interface MainPageProps {
  userInfo: User | null;
  error: AppError | null;
  authError: AuthError | null;
  handleCloseErrorDialog?: () => void;
  handleCloseAuthErrorDialog?: () => void;
}

/**
 * Presenter
 */

const MainPage = (props: MainPageProps) => {
  const {
    userInfo,
    error,
    authError,
    handleCloseErrorDialog,
    handleCloseAuthErrorDialog,
  } = props;

  return (
    <>
      {userInfo && (
        <PageTemplate
          headerBar={<HeaderBar />}
          sidebar={<Sidebar />}
          footerBar={<FooterBar />}
        >
          <Switch>
            <Route exact path={['/', '/adm', '/login']}>
              <Redirect to="/adm/dashboard" push />
            </Route>
            <Route exact path={['/adm/dashboard']} component={Dashboard} />
            {/* <Route exact path={['/', '/adm', '/adm/dashboard']} component={Dashboard} /> */}
            <Route
              exact
              path="/adm/lp-manage"
              component={LpAdminPageContainer}
            />
            <Route exact path="/adm/lps/own" component={MyLPsPageContainer} />
            <Route
              exact
              path="/adm/lps/subordinates"
              component={MyLPsPageContainer}
            />
            <Route
              exact
              path="/adm/lps/create-mode-selection"
              component={LPCreateModeSelection}
            />
            <Route path="/adm/lps/register" component={NormalEdit} />
            <Route path="/adm/lps/:lpId/register" component={NormalEdit} />
            <Route
              path="/adm/lps/approved/:lpId/register"
              component={NormalEdit}
            />
            <Route path="/adm/lps/:lpId/edit" component={NormalEdit} />
            <Route path="/adm/lps/approved/:lpId/edit" component={NormalEdit} />
            <Route exact path="/adm/lps/:lpId" component={LPDetail} />
            <Route exact path="/adm/lps/:lpId/approved" component={LPDetail} />
            <Route exact path="/adm/inquiries" component={Inquiries} />

            <Route exact path="/adm/inquiries/edit" component={InquiryEdit} />
            <Route exact path={['/', '/adm']}>
              <Redirect to="/adm/dashboard" push />
            </Route>
            <Route exact path={['/adm/dashboard']} component={Dashboard} />
            {/* <Route exact path={['/', '/adm', '/adm/dashboard']} component={Dashboard} /> */}
            <Route exact path="/adm/lps" component={LPsPageContainer} />
            <Route exact path="/adm/lps/own" component={MyLPsPageContainer} />
            <Route
              exact
              path="/adm/my-libraries"
              component={MyLibraryContainer}
            />
            <Route
              exact
              path="/adm/lps/subordinates"
              component={MyLPsPageContainer}
            />
            <Route
              exact
              path="/adm/lps/create-mode-selection"
              component={LPCreateModeSelection}
            />
            <Route path="/adm/lps/register" component={NormalEdit} />
            <Route path="/adm/lps/:lpId/register" component={NormalEdit} />
            <Route
              path="/adm/lps/approved/:lpId/register"
              component={NormalEdit}
            />
            <Route path="/adm/lps/:lpId/edit" component={NormalEdit} />
            <Route path="/adm/lps/approved/:lpId/edit" component={NormalEdit} />
            <Route exact path="/adm/lps/:lpId" component={LPDetail} />
            <Route exact path="/adm/lps/:lpId/approved" component={LPDetail} />
            <Route exact path="/adm/inquiries" component={Inquiries} />
            <Route
              exact
              path="/adm/inquiries/:inquiryId"
              component={InquiryDetailPageContainer}
            />
            <Route exact path="/adm/products" component={Products} />
            <Route exact path="/adm/products" component={Products} />
            <Route
              exact
              path="/adm/products/register"
              component={ProductRegisterEdit}
            />
            <Route
              exact
              path="/adm/products/:productId"
              component={ProductDetail}
            />
            <Route
              exact
              path="/adm/products/:productId/edit"
              component={ProductRegisterEdit}
            />
            <Route exact path="/adm/examples" component={Examples} />
            <Route
              exact
              path="/adm/examples/register"
              component={ExampleRegisterEdit}
            />
            <Route
              exact
              path="/adm/examples/:exampleId"
              component={ExampleDetailPageContainer}
            />
            <Route
              exact
              path="/adm/examples/:exampleId/edit"
              component={ExampleRegisterEdit}
            />
            <Route exact path="/adm/accounts" component={Account} />
            <Route
              exact
              path="/adm/customers/register"
              component={CustomerRegisterPageContainer}
            />
            <Route
              exact
              path="/adm/customers/:customerId/edit"
              component={CustomerRegisterPageContainer}
            />
            <Route
              exact
              path="/adm/users/register"
              component={UserRegisterPageContainer}
            />
            <Route
              exact
              path="/adm/users/:userId/edit"
              component={UserRegisterPageContainer}
            />
            <Route
              exact
              path="/adm/notices-manage"
              component={ManageNoticesPageContainer}
            />
            <Route
              exact
              path="/adm/notices/register"
              component={NoticeEditPageContainer}
            />
            <Route
              exact
              path="/adm/tenant/dashboard"
              component={TenantDashboardPageContainer}
            />
            <Route exact path="/adm/notices" component={NoticesPageContainer} />
            <Route
              exact
              path="/adm/notices/:noticeId"
              component={NoticesDetailContainer}
            />
            <Route
              exact
              path="/adm/notices/:noticeId/edit"
              component={NoticeEditPageContainer}
            />
            <Route
              exact
              path="/adm/inquiry/register"
              component={InquiryRegisterContainer}
            />
            <Route
              exact
              path="/adm/inquiry/:inquiryId/edit"
              component={InquiryRegisterContainer}
            />
            <Route
              exact
              path="/adm/settings"
              component={VariousSettingsPageContainer}
            />

            <Route
              exact
              path="/adm/users"
              component={ManageUsersPageContainer}
            />
            <Route exact path="/adm/customers" component={CustomersContainer} />

            <Route
              exact
              path="/adm/inquiry-forms"
              component={FromEditPageContainer}
            />

            <Route
              exact
              path="/adm/trashes"
              component={TrashesPageContainer}
            />

            <Route exact path={'/*'} component={NotFound} />
          </Switch>
        </PageTemplate>
      )}
      <AuthErrorDialog
        open={true && !!authError}
        title={authError?.message}
        handleClose={handleCloseAuthErrorDialog}
      />
      <ErrorDialog
        open={!authError && !!error}
        title={error?.title}
        message={error?.error}
        handleClose={handleCloseErrorDialog}
      />
    </>
  );
};
export default MainPage;

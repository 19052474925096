import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  SearchTextField,
  CommonSwitch,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { InquiriesQueryParameter } from 'core/domain/inquiries';

/**
 * Interface
 */

export interface InquiriesSearchFieldProps {
  inquiriesQueryParameter: InquiriesQueryParameter | null;
  switchLabel?: string;
  switchOwnValue?: boolean | null;
  switchUnreadValue?: boolean | null;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeOwnSwitch?: (own: boolean) => void;
  handleChangeUnreadSwitch?: (own: boolean) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line
      color: theme.palette.gray[800],
    },
    switch: {
      display: 'flex',
    },
    switchDescription: {
      margin: `0 ${theme.spacing(1)}px`,
    },
  }),
);

const InquiriesSearchField = (props: InquiriesSearchFieldProps) => {
  const classes = useStyles({});
  const {
    switchLabel,
    switchOwnValue,
    switchUnreadValue,
    inquiriesQueryParameter,
    handleChangeOwnSwitch,
    handleChangeUnreadSwitch,
    handleChangeFreeWord,
  } = props;

  return (
    <Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="caption">
          フリーワード検索
        </Typography>
        <Box className={classes.freeWord}>
          <SearchTextField
            label="検索ワードを入力"
            handleChangeText={handleChangeFreeWord}
            value={
              inquiriesQueryParameter?.free_word
                ? inquiriesQueryParameter.free_word
                : ''
            }
          />
        </Box>
        <Typography className={classes.description} variant="caption">
          登録されている情報全体からフリーワードで検索します。
        </Typography>
        {switchLabel && (
          <Box className={classes.switch} ml="auto">
            <CommonSwitch
              checked={switchOwnValue || undefined}
              value={switchOwnValue || undefined}
              handleChangeSwitch={handleChangeOwnSwitch}
            />
            <Typography className={classes.switchDescription} variant="caption">
              {switchLabel}
            </Typography>
          </Box>
        )}
        {switchLabel && (
          <Box className={classes.switch} ml={4}>
            <CommonSwitch
              checked={switchUnreadValue || undefined}
              value={switchUnreadValue || undefined}
              handleChangeSwitch={handleChangeUnreadSwitch}
            />
            <Typography className={classes.switchDescription} variant="caption">
              未読のみ表示
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InquiriesSearchField;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import { InquiryForm } from 'core/domain/settings';
import { CODE_MODE_EDIT } from 'constants/code';
import InquiryFormEdit from '../components/pages/InquiryFormEdit';

const FormEditPageContainer = () => {
  const dispatch = useDispatch();
  const inquiryFormsStore = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  const [choicesDialogOpen, setChoicesDialogOpen] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);

  const closeChoicesDialogOpen = () => {
    setChoicesDialogOpen(false);
  };

  const closeSuccessDialogOpen = () => {
    setSuccessDialogOpen(false);
  };

  const handleSubmit = (data: Array<InquiryForm>) => {
    const choisesErrorArray = data.filter(
      (item) =>
        // 自由項目かつ表示対象で、タイプが3:ラジオボタン 4:チェックボックス 5:プルダウンのどれかだった場合に、選択肢が設定され忘れていないか確認
        item.inquiryItemId >= 18 &&
        item.displayed === true &&
        item.inputType > 2 &&
        item.inquiryItemOptions?.length === 0,
    );

    const error = data.filter(
      (item) => item.inquiryItemName.length >= 100,
    ).length;
    if (error) return;
    if (!token || !userInfo) return;

    if (choisesErrorArray.length > 0) {
      setChoicesDialogOpen(true);
    } else {
      dispatch(
        Usecases.inquiries.sendPutInquiryForms(token, userInfo.tenantId, data,
          () => dispatch(
            // 保存後再取得 (自由項目の選択肢が残るため)
            Usecases.settings.sendGetInquiryForms(
              token,
              userInfo?.tenantId,
              CODE_MODE_EDIT,
            ),
          )),
      );
      setSuccessDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.settings.sendGetInquiryForms(
        token,
        userInfo?.tenantId,
        CODE_MODE_EDIT,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InquiryFormEdit
      inquiryFormsProps={inquiryFormsStore}
      handleSubmit={(data: Array<InquiryForm>) => handleSubmit(data)}
      choicesDialogOpen={choicesDialogOpen}
      handleCloseChoicesDialogOpen={closeChoicesDialogOpen}
      successDialogOpen={successDialogOpen}
      handleCloseSuccessDialogOpen={closeSuccessDialogOpen}
    />
  );
};

export default FormEditPageContainer;

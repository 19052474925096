import { Box, Checkbox, createStyles, FormControlLabel, makeStyles, Theme, Typography, } from '@material-ui/core';
import { ErrorIcon } from 'assets/images';
import { SubHeadlineLabel } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { SALAD_BAR_DESKTOP_FONT_SIZE_200, SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { inquiryItemOptions } from 'core/domain/inquiries';
import React, { useEffect, useState } from 'react';

interface InquiryCheckBoxProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
  title: string;
  options: inquiryItemOptions[] | undefined;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    formLabel: {
      display: 'block',
      marginBottom: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
        color: `${theme.palette.green[800]} !important`,
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
    checkbox: {
      '& .MuiIconButton-label': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const InquiryCheckBoxField = (props: InquiryCheckBoxProps) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
    title,
    options,
    disabled,
  } = props;
  const classes = useStyles();
  const [checkedArray, setCheckedArray] = useState<string[]>([]);

  useEffect(() => {
    if (checkedArray.length > 0 || !defaultValue) return;
    // safariでは正規表現の後読みが対応されていない。
    // 区切り文字ではないカンマをエスケープするためにカンマを2つとしているが、
    // 否定後読み無しで上手にsplitする方法が思いつかないため、
    // 暫定的に、通常入力されないであろう文字列に一旦置き換える。
    setCheckedArray(
      defaultValue.replaceAll(',,', '|エスケープカンマ|').split(',').map((value) => 
        value.replaceAll('|エスケープカンマ|', ',')));
  }, [checkedArray, checkedArray.length, defaultValue, options])

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checkedArray?.includes(e.target.value)) {
      setCheckedArray((prev) =>
        prev?.filter((item) => item !== e.target.value),
      );
      const checkedValue = checkedArray.filter((item) => item !== e.target.value).map((item) => item.replaceAll(',', ',,'));
      onChange(inquiryItemId, title, checkedValue.join(','));
    } else {
      setCheckedArray((prev) =>
        prev ? [...prev, e.target.value] : [e.target.value],
      );
      const checkedValue = checkedArray.map((item) => item);
      checkedValue.push(e.target.value);
      onChange(inquiryItemId, title, checkedValue.map((value) => value.replaceAll(',', ',,')).join(','));
    }
  }

  return (
    <Box
      className={`${classes.root} ${
        isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
      }`}
      display="flex"
      alignItems="center"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title={title} />
      ) : (
        <SubHeadlineLabel text={title} />
      )}
      <Box>
        {options?.map((option) => (
          <FormControlLabel
            className={classes.formLabel}
            control={
              <Checkbox
                className={classes.checkbox}
                value={option.displayOptionName}
                checked={checkedArray?.includes(option.displayOptionName)}
                disableRipple
                onChange={(e) => {
                  handleChangeCheckBox(e)
                }}
              />
            }
            label={option.displayOptionName}
            key={option.displayOrder}
            disabled={disabled}
          />
        ))}
        {error && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>{error}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default InquiryCheckBoxField;

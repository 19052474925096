/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Divider,
  ProductTag,
  ButtonLink,
  Grid,
  Box,
  RecommendButton,
} from 'components/atoms';
import { FavoriteButton, PreviewHeightFixedButton } from 'components/molecules';
import { StatusBar } from 'components/organisms';
import { LinkIcon, NoImage, PDFIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { ProductDetail } from 'core/domain/product';
import { MaterialImage } from 'core/domain/material';
import {
  formatDateISO8601,
  formatDateYYYYMMDD,
  formateTimeHHMMColon,
} from 'libs/date';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
} from 'constants/code';
import { LpView } from 'core/domain/lp';
import { hooks } from 'libs';
import { downloadFileFromUrl } from 'libs/downloader';
import { shallowEqual, useSelector } from 'react-redux';
import { Modules } from 'core';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';

/**
 * Interface
 */

export interface ProductDetailListProps {
  productDetail: ProductDetail;
  handleRecommendedUpdate: () => void;
  isDialog?: boolean;
  handleClickFavorite: () => void;
  previewData?: LpView;
  handleDelete?: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.green[800],
    },
    productSubtitle: {
      margin: '33px 0px 0px 0px',
    },
    productName: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: theme.palette.gray[900],
      marginBottom: theme.spacing(1),
    },
    preview: {
      float: 'right',
    },
    thickBorder: {
      marginTop: 10,
      height: 3,
      backgroundColor: theme.palette.gray[600],
      borderColor: theme.palette.gray[600],
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
    },
    caption: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
    },
    usePeriod: {
      marginLeft: 16,
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    container: {
      margin: '16px 0px',
    },
    videoLink: {
      height: '100%',
      marginLeft: '16px',
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
    },
    marginBottom: {
      marginBottom: 8,
    },
    image: {
      width: 245,
      height: 163,
      objectFit: 'contain',
    },
    url: {
      wordBreak: 'break-all',
    },
    docs: {
      overflow: 'hidden',
    },
    selectBox: {
      width: 140,
      border: '1px solid #ccc',
      borderRadius: 4,
      padding: '8px',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
    },
  }),
);

export default function ProductDetailList(props: ProductDetailListProps) {
  const classes = useStyles({});
  const {
    productDetail,
    handleRecommendedUpdate,
    isDialog,
    handleClickFavorite,
    previewData,
    handleDelete,
  } = props;
  const {
    approvalUsers,
    step,
    enableFavorite,
    deletePermission,
    enableRecommend,
  } = hooks.useProductDetailPermission();
  const {
    productPeriod,
    productCreatedUser,
    productDocuments,
    productExternalSite,
    productReaction,
    productImages,
  } = productDetail;

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  const tags = useMemo(() => {
    if (!productDetail.productTag) return [];

    return productDetail.productTag.split(',');
  }, [productDetail]);

  const getImageUrl = (materialImage: MaterialImage) => {
    switch (materialImage.materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return materialImage.url;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return materialImage.thumbnailUrl
          ? materialImage.thumbnailUrl
          : NoImage;
      default:
        break;
    }

    return undefined;
  };

  // 関連資料DL制御
  const [productDocumentDisabled, setProductDocumentDisabled] = useState<
    number | null
  >(null);

  // プレビュー用プルダウン
  const designTemplates = useSelector(
    (state: Modules.AppState) => state.designTemplates,
  );
  const [templateId, setTemplateId] = useState<number>(1);
  useEffect(() => {
    // 初期化
    setTemplateId(designTemplates ? designTemplates[0].designTemplateId : 1);
  }, []);
  const handleChange = (value: any) => {
    setTemplateId(Number(value));
  };

  return (
    <div>
      <StatusBar
        isDialog={isDialog}
        status={productPeriod.materialStatus}
        lpCount={productReaction.useLpCount}
        likeCount={productReaction.interestedCount}
        downloadCount={productReaction.downloadCount}
        contactCount={productReaction.inquiryCount}
        approvalUsers={approvalUsers}
        step={step}
        deletePermission={deletePermission}
        handleDelete={handleDelete}
      />
      {!isDialog && (
        <Box mt={4} display="flex" marginBottom="9px">
          <Box flexGrow={1}>
            <RecommendButton
              disabled={!enableRecommend}
              recommended={productDetail.recommended}
              click={handleRecommendedUpdate}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Typography variant="h4" style={{ marginBottom: 4 }}>
            {productDetail.productId}
          </Typography>
          <Typography variant="h4" className={classes.productName}>
            {productDetail.productName}
          </Typography>
          <Typography
            display="block"
            variant="caption"
            className={`${classes.caption} ${classes.marginBottom}`}
          >
            <span>{`登録日：${formatDateISO8601(
              productDetail.createdDate,
            )}`}</span>
            <span
              className={classes.usePeriod}
            >{`利用期限：${formatDateYYYYMMDD(
              productPeriod.usePeriodDate,
            )} ${formateTimeHHMMColon(productPeriod.usePeriodTime)}`}</span>
            <span
              className={classes.usePeriod}
            >{`公開期限：${formatDateYYYYMMDD(
              productPeriod.openPeriodDate,
            )} ${formateTimeHHMMColon(productPeriod.openPeriodTime)}`}</span>
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            className={classes.marginBottom}
          >
            <Typography
              display="block"
              variant="caption"
              className={classes.caption}
            >
              お気に入り登録数：
            </Typography>
            <FavoriteButton
              disabled={!enableFavorite}
              favorite={productDetail.favorite}
              favoriteCount={productDetail.favoriteCount}
              click={handleClickFavorite}
            />
          </Box>
        </Box>
        {!isDialog && (
          <Box marginBottom="5px">
            <Box marginBottom="5px">
              <select
                defaultValue={1}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                className={classes.selectBox}
                value={templateId}
              >
                {designTemplates?.map((option) => (
                  <option
                    key={option.designTemplateId}
                    value={option.designTemplateId}
                  >
                    {option.designTemplateName}
                  </option>
                ))}
              </select>
            </Box>
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <Typography className={classes.previewCaption}>
                プレビュー
              </Typography>
              <PreviewHeightFixedButton
                previewData={previewData}
                templateId={templateId}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Divider classes={{ root: classes.thickBorder }} />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            商材タグ
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {tags.map((tag, index) => (
            <ProductTag item={tag} key={index} />
          ))}
        </Grid>
      </Grid>
      <Divider />
      {/* 権限によって登録者/部署を表示・非表示制御する */}
      {(userInfo?.tenantAdmin ||
        userInfo?.materialAuthor ||
        userInfo?.materialApprover) && (
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              className={classes.subtitle}
              display="inline"
            >
              登録者/部署
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              {productCreatedUser.deptName
                ? `${productCreatedUser.userName}/${productCreatedUser.deptName}`
                : productCreatedUser.userName}
            </Typography>
          </Grid>
        </Grid>
      )}
      {(userInfo?.tenantAdmin ||
        userInfo?.materialAuthor ||
        userInfo?.materialApprover) && <Divider />}
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            お問い合わせ先
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {productDetail.contact}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            関連資料
          </Typography>
        </Grid>
        <Grid item xs={10} className={classes.docs}>
          {productDocuments.map((doc, index) => (
            <ButtonLink
              click={async () => {
                setProductDocumentDisabled(index);
                downloadFileFromUrl(doc.url, doc.productDocumentName);
                setTimeout(() => setProductDocumentDisabled(null), 1000);
              }}
              tooltipText={doc.productDocumentName}
              text={doc.productDocumentName}
              icon={<PDFIcon />}
              iconPosition="end"
              key={index}
              disabled={productDocumentDisabled === index}
            />
          ))}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            キャッチコピー
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {productDetail.catchCopy}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            サブコピー
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {productDetail.subCopy}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            商材説明
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {productDetail.productContent}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            外部サイトへのリンク
            <br />
            項目名/URL
          </Typography>
        </Grid>
        <Grid item xs={10} className={classes.url}>
          {productExternalSite && (
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              {productExternalSite.externalSiteName}/
              <br />
              <ButtonLink
                text={
                  productExternalSite.externalSiteUrl
                    ? productExternalSite.externalSiteUrl
                    : ''
                }
                icon={<LinkIcon />}
                click={() => window.open(productExternalSite.externalSiteUrl)}
              />
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.container}>
        <Typography variant="body2" className={classes.subtitle}>
          イメージ画像・動画
        </Typography>
        {productImages &&
          productImages.map((image, index) => (
            <Grid
              container
              spacing={0}
              className={classes.container}
              key={index}
            >
              <Grid item>
                {image.materialImageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO ? (
                  <video
                    src={getImageUrl(image)}
                    className={classes.image}
                    poster={image.thumbnailUrl ? image.thumbnailUrl : undefined}
                  />
                ) : (
                  <img src={getImageUrl(image)} className={classes.image} />
                )}
              </Grid>
              {image.materialImageType ===
                CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK && (
                <Grid item className={classes.url}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.videoLink}
                  >
                    <Box>
                      <ButtonLink
                        text={image.url}
                        icon={<LinkIcon />}
                        iconPosition="end"
                        click={() => window.open(image.url)}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          ))}
      </div>
    </div>
  );
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CircleArrowTopIcon,
  CircleArrowDownIcon,
  NoImage,
} from 'assets/images';
import {
  Box,
  PrimaryButton,
  DeleteMediaElementButton,
  Card,
  IconButton,
  SubHeadlineLabel,
  CardContent,
  OneLineTextField,
  Tooltip,
} from 'components/atoms';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
} from 'constants/code';

/**
 * Interface
 */
export type Order = 'up' | 'down';
export interface MediaCardProps {
  sortable?: boolean;
  handleSort?: (order: Order) => void;
  materialImageType: number;
  base64?: string;
  url?: string;
  thumbnailUrl?: string;
  handleChangeMedia?: () => void;
  handleDeleteMedia?: () => void;
  isTop?: boolean;
  isBottom?: boolean;
  thumbnailData?: string;
  handleChangeThumbnail?: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginTop: 8,
      marginBottom: 8,
      boxShadow: 'none',
      borderColor: theme.palette.gray[600],
      borderStyle: 'solid',
      borderWidth: 1,
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    img: {
      margin: 24,
      width: 265,
      height: 163,
      objectFit: 'contain',
    },
    url: {
      marginBottom: 8,
      '& .MuiTypography-root': {
        marginTop: theme.spacing(0),
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    changeButton: {
      marginTop: 'auto',
    },
    sortButton: {
      '&:disabled span svg g': {
        fill: theme.palette.gray[500],
        stroke: theme.palette.gray[400],
      },
      '&:disabled span svg path': {
        stroke: theme.palette.common.white,
      },
    },
    urlText: {
      '&>div, input': {
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    thumbnailButton: {
      marginTop: 'auto',
      width: 150,
    },
  }),
);

export default function MediaCard(props: MediaCardProps) {
  const classes = useStyles({});
  const {
    sortable,
    handleSort,
    materialImageType,
    base64,
    url,
    handleChangeMedia,
    handleDeleteMedia,
    isTop,
    isBottom,
    thumbnailUrl,
    thumbnailData,
    handleChangeThumbnail,
  } = props;

  const imageUrl = useMemo(() => {
    if (base64) return base64;
    if (materialImageType !== CODE_MATERIAL_IMAGE_TYPE_VIDEO && thumbnailUrl)
      return thumbnailUrl;
    if (materialImageType !== CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK && url)
      return url;

    return NoImage;
  }, [base64, thumbnailUrl, url, materialImageType]);

  return (
    <Card className={classes.root}>
      <Box>
        {materialImageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO ? (
          <video
            src={imageUrl}
            className={classes.img}
            poster={thumbnailData ? thumbnailData : thumbnailUrl ? thumbnailUrl : undefined}
          />
        ) : (
          <img src={imageUrl} className={classes.img} />
        )}
      </Box>
      <CardContent className={classes.content}>
        <Box>
          <Box display="flex" className={classes.url}>
            {materialImageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK && (
              <Box flexGrow={1} mt={1}>
                <SubHeadlineLabel text="動画URL" />
                {url ? (
                  <Tooltip title={url || ''} arrow placement="top">
                    <Box className={classes.urlText} width={500}>
                      <OneLineTextField
                        style={{ pointerEvents: 'none' }}
                        disabled
                        value={url}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Box className={classes.urlText} width={500}>
                    <OneLineTextField
                      style={{ pointerEvents: 'none' }}
                      disabled
                      value={url}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box height={36} className={classes.changeButton}>
              <PrimaryButton text="変更" click={handleChangeMedia} />
            </Box>
            {materialImageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO && (
              <Box height={36} className={classes.thumbnailButton}>
                <PrimaryButton
                  text={
                    thumbnailUrl || thumbnailData
                    ? 'サムネイルを変更'
                    : 'サムネイルを追加'
                  }
                  click={handleChangeThumbnail}
                />
              </Box>
            )}
          </Box>
          <Box mt={1}>
            <DeleteMediaElementButton
              text={
                materialImageType === CODE_MATERIAL_IMAGE_TYPE_IMAGE
                  ? '画像'
                  : '動画'
              }
              click={handleDeleteMedia}
            />
          </Box>
        </Box>
      </CardContent>
      {sortable !== false && (
        <Box display="flex" flexDirection="column" mt={6}>
          <IconButton
            className={classes.sortButton}
            onClick={handleSort ? () => handleSort('up') : undefined}
            disabled={isTop}
          >
            <CircleArrowTopIcon />
          </IconButton>
          <IconButton
            className={classes.sortButton}
            onClick={handleSort ? () => handleSort('down') : undefined}
            disabled={isBottom}
          >
            <CircleArrowDownIcon />
          </IconButton>
        </Box>
      )}
    </Card>
  );
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpReaction } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpReaction | null {
  return initialState
}

export const SET_LP_REACTION = 'lp-reaction/set' as const
export const CLEAR_LP_REACTION = 'lp-reaction/clear' as const

export function setLpReaction(data: LpReaction) {
  return {
    type: SET_LP_REACTION,
    payload: {
      data,
    },
  }
}

export function clearLpReaction() {
  return {
    type: CLEAR_LP_REACTION,
  }
}

type SetLpReactionAction = ReturnType<typeof setLpReaction>
type ClearLpReactionAction = ReturnType<typeof clearLpReaction>
type Action = SetLpReactionAction | ClearLpReactionAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_REACTION:
      return action.payload.data
    case CLEAR_LP_REACTION:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import { NoticeDetails } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticeDetails | null => initialState;

export const SET_MANAGE_NOTICES = 'manageNotices/set' as const;
export const CLEAR_MANAGE_NOTICES = 'manageNotices/clear' as const;

export const setManageNotices = (data: NoticeDetails) => ({
  type: SET_MANAGE_NOTICES,
  payload: {
    data,
  },
});

export const clearManageNotices = () => ({
  type: CLEAR_MANAGE_NOTICES,
});

type SetManageNoticesAction = ReturnType<typeof setManageNotices>;
type ClearManageNoticesAction = ReturnType<typeof clearManageNotices>;
type Action = SetManageNoticesAction | ClearManageNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_MANAGE_NOTICES:
      return action.payload.data;
    case CLEAR_MANAGE_NOTICES:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */

interface ProductExampleLabelProps {
  text: string
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      background: theme.palette.gray[400],
      borderRadius: 10,
      padding: theme.spacing(3)
    },
    product: {
      fontWeight: 'bold',
      color: theme.palette.gray[900],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginBottom: theme.spacing(1),
      '&:hover': {
        textDecoration: 'none'
      },
      wordWrap: 'break-word',
    },
  }),
)

/**
 * Presenter
 */

export default function ProductExampleLabel(props: ProductExampleLabelProps) {
  const classes = useStyles({})
  const { text } = props

  return (
    <Box className={classes.root}>
      <Typography className={classes.product}>
        {text}
      </Typography>
    </Box>
  )
}/* eslint-enable */

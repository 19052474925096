/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  InformationIconButton,
  Box,
  IconButton,
  Typography,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import {
  NoImage,
  CloseIcon,
  Grid,
  ArrowDownIcon,
  ArrowTopIcon,
} from 'assets/images';
import clsx from 'clsx';
import { ProductExample } from 'core/domain/example';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_SUSPENSION,
} from 'constants/code';

/**
 * Interface
 */
export interface NormalEditExampleItemProps {
  clickUp: () => void;
  clickDown: () => void;
  isTop: boolean;
  isBottom: boolean;
  example: ProductExample;
  removeExample: () => void;
  clickInformation: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 66,
      width: '100%',
      boxShadow: '1px',
      marginTop: theme.spacing(1),
    },
    buttonRoot: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      height: 66,
      width: '95%',
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        '& p': {
          color: theme.palette.green[900],
        },
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    button: {
      borderWidth: 1,
      borderColor: theme.palette.gray[800],
      borderStyle: 'solid',
      backgroundColor: theme.palette.green[800],
      '&:hover': {
        backgroundColor: theme.palette.green[900],
      },
      height: 20,
      width: 20,
      padding: 0,
      borderRadius: 20,
      marginLeft: 'auto',
    },
    icon: {
      height: 11,
      width: 11,
      paddingLeft: 4,
      paddingBottom: 2,
    },
    close: {
      height: 20,
      width: 20,
      padding: 4,
    },
    grid: {
      width: 9,
      height: 15,
      margin: 'auto 12px',
    },
    text: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      width: '100%',
    },
    example: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.green[900],
        cursor: 'pointer',
      },
    },
    company: {
      color: theme.palette.gray[800],
    },
    arrowIcon: {
      padding: 0,
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
      '&:disabled': {
        '& svg g path': {
          fill: theme.palette.gray[600],
        },
      },
      '& svg g path': {
        fill: theme.palette.green[800],
      },
    },
    imageBox: {
      position: 'relative',
    },
    img: {
      width: 63,
      height: 42,
      objectFit: 'contain',
      pointerEvents: 'none',
    },
    lineheight: {
      lineHeight: '16px',
    },
    red: {
      color: theme.palette.red[900],
    },
  }),
);

/**
 * Presenter
 */

export default function NormalEditExampleItem(
  props: NormalEditExampleItemProps,
) {
  const classes = useStyles({});
  const {
    clickUp,
    clickDown,
    isTop,
    isBottom,
    example,
    removeExample,
    clickInformation,
  } = props;
  const [hovered, setHovered] = useState(false);

  const image = useMemo(() => {
    if (
      !example ||
      !example.exampleImage ||
      (!example.exampleImage.url && !example.exampleImage.thumbnailUrl)
    ) {
      return <img src={NoImage} className={classes.img} />;
    }
    switch (example.exampleImage.materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        return <img src={example.exampleImage.url} className={classes.img} />;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return (
          <video
            src={example.exampleImage.url}
            className={classes.img}
            poster={
              example.exampleImage.thumbnailUrl
                ? example.exampleImage.thumbnailUrl
                : undefined
            }
          />
        );
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return (
          <img
            src={example.exampleImage.thumbnailUrl}
            className={classes.img}
          />
        );
      default:
        return <img src={NoImage} className={classes.img} />;
    }
  }, [example.exampleImage]);

  const statusLabel = useMemo(() => {
    switch (example.exampleStatus) {
      case CODE_MATERIAL_STATUS_EXPIRE:
        return <span>【利用期間外】</span>;
      case CODE_MATERIAL_STATUS_END:
        return <span>【公開終了】</span>;
      default:
    }
  }, [example]);

  const isRedColor = () =>
    example.exampleStatus >= CODE_MATERIAL_STATUS_SUSPENSION;

  return (
    <Box
      className={classes.root}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        boxShadow={hovered ? 0 : 2}
        className={classes.buttonRoot}
      >
        <Grid className={classes.grid} />
        <Box className={classes.imageBox}>
          <InformationIconButton handleClick={clickInformation} />
          {image}
        </Box>
        <Box ml={1} width={530}>
          <Typography className={clsx(classes.text, classes.example)}>
            {example.exampleMaterialId}
          </Typography>
          <Typography
            className={clsx(
              classes.lineheight,
              classes.text,
              classes.example,
              isRedColor() && classes.red,
            )}
          >
            {statusLabel}
            {example.exampleName}
          </Typography>
          <Typography
            variant="caption"
            className={clsx(classes.text, classes.company)}
          >
            {example.customerName}
          </Typography>
        </Box>
        <Box ml="auto" mr="4px" mt="4px" mb="auto">
          <IconButton className={classes.close} onClick={removeExample}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box width={20} height={44}>
        <IconButton
          disabled={isTop}
          className={classes.arrowIcon}
          onClick={clickUp}
        >
          <ArrowTopIcon />
        </IconButton>
        <IconButton
          disabled={isBottom}
          className={classes.arrowIcon}
          onClick={clickDown}
        >
          <ArrowDownIcon />
        </IconButton>
      </Box>
    </Box>
  );
} /* eslint-enable */

import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Chip, SvgIcon } from 'components/atoms'
import { CloseIcon, TagIcon, TagIconHover } from 'assets/images'
import { SALAD_BAR_DESKTOP_FONT_SIZE_50 } from 'constants/typography'


interface ManagerTagProps {
  item: string
  deleteTag:(val:string) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      height: 'auto',
      minHeight: 26,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.green[900],
      background: theme.palette.green[700],
      borderColor: theme.palette.green[700],
      backgroundColor: theme.palette.green[700],
      borderWidth: '1px',
      borderStyle: 'solid',
      marginLeft: 2,
      marginRight: 2,
      padding: '4px 0',
      '&:hover': {
        color: theme.palette.green[700],
        backgroundColor: theme.palette.green[900],
      },
      '& .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
    icon: {
      backgroundColor: 'Transparent',
      width: '12px',
      height: '12px',
      marginLeft: '12px',
    }
  }),
)

/**
 * Presenter
 */

 const ManagerTag = (props: ManagerTagProps) => {
  const classes = useStyles({})
  const [hovered, setHovered] = useState(false)
  const {item, deleteTag,} = props

  return (
    <Chip
      style={!hovered ? {backgroundColor: "#E6F7EA"} : {backgroundColor: "#298E41"}}
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      icon={<SvgIcon className={classes.icon} component={!hovered ? TagIcon : TagIconHover} />}
      label={item}
      onDelete={() => deleteTag(item)} 
      deleteIcon={<CloseIcon className={classes.icon} />}
      />
  )
}

export default ManagerTag;
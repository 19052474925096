/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Button } from 'components/atoms'

/**
 * Interface
 */
export interface QuaternaryButtonProps {
  text: string,
  icon?: React.ReactNode
  disabled?: boolean
  click?: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      background: theme.palette.pink[800],
      borderColor: theme.palette.pink[800],
      borderStyle: 'solid',
      borderWidth: '1px',
      color: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.pink[900],
        boxShadow: 'none'
      }
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
)

/**
 * Presenter
 */

export default function QuaternaryButton(props:QuaternaryButtonProps) {

  const classes = useStyles({})
  const { text, icon, disabled, click } = props

  return (
    <Button
      variant="contained"
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
      })}
      startIcon={icon}
      onClick={!disabled ? click : undefined}
    >
      {text}
    </Button>
  )
}/* eslint-enable */

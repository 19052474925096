import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { setMyLibrary } from 'core/modules/myLibrary'
import  {ImageCut, MyLibrary } from 'core/domain/myLibrary'
import { setImageCut } from 'core/modules/imageCut'

export const didCallGetMyLibraryApi = (dispatch: Dispatch, data: MyLibrary) => {
  dispatch(setMyLibrary(data))
}

export const sendGetMyLibrary = (token: string | undefined, tenantId: string) => async (dispatch: Dispatch) => {
    // Send GET
    await Api.callApi(Api.HttpMethod.get, `${tenantId}/users/my-library`, token, null, dispatch, didCallGetMyLibraryApi)
  }


// 画像切抜
export const didCallPostImageCutApi = (dispatch: Dispatch, data: ImageCut) => {
  const image = "data:image/png;base64,";
  
  dispatch(setImageCut({data : image + data.data}));
}

// 画像切抜
export const sendPostImageCut = (token: string | undefined, tenantId: string, data: string, mode:number,) => async (dispatch: Dispatch) => {
    // Send POST
    // mode1プレビューmode2マイライブラリ登録
    await Api.callApi(Api.HttpMethod.post, `${tenantId}/images/clipping`, token, JSON.stringify({ data,mode, }), dispatch, didCallPostImageCutApi)
}
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconButton, SvgIcon, Tooltip, Box } from 'components/atoms';
import { PreviewDialog } from 'components/organisms';
import { PCIcon, SmartPhoneIcon } from 'assets/images';
import { LpView } from 'core/domain/lp';

/**
 * Interface
 */
export interface PreviewHeightFixedButtonProps {
  handleClick?: () => void;
  handleCloseClick?: () => void;
  previewData?: LpView;
  templateId?: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      borderColor: theme.palette.gray[800],
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      textAlign: 'center',
      height: '35px',
      width: '35px',
      '&:hover': {
        background: theme.palette.green[800],
        '& path': {
          fill: theme.palette.text.secondary,
        },
      },
    },
    icon: {
      paddingTop: 3,
      paddingLeft: 3,
      paddingBottom: 0,
      paddingRight: 0,
    },
    buttonRight: {
      paddingLeft: 4,
    },
  }),
);

type PreviewMode = 'PC' | 'MOBILE';

/**
 * Presenter
 */

export default function PreviewHeightFixedButton(
  props: PreviewHeightFixedButtonProps,
) {
  const classes = useStyles({});
  const [preview, setPreview] = useState(false);
  const [previewMode, setPreviewMode] = useState<PreviewMode>('PC');
  const { handleClick, handleCloseClick, previewData, templateId } = props;

  const toPreview = (mode: PreviewMode) => {
    setPreviewMode(mode);
    setPreview(true);
  };

  const handleClose = () => {
    setPreview(false);
    handleCloseClick && handleCloseClick();
  };

  return (
    <Box display="flex">
      <Box m={0}>
        <Tooltip title="PCのプレビューを見る" arrow placement="top">
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              toPreview('PC');
              handleClick && handleClick();
            }}
          >
            <SvgIcon component={PCIcon} className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.buttonRight}>
        <Tooltip title="スマホのプレビューを見る" arrow placement="top">
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              toPreview('MOBILE');
              handleClick && handleClick();
            }}
          >
            <SvgIcon component={SmartPhoneIcon} className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Box>
      {preview && (
        <PreviewDialog
          open={preview}
          handleClose={() => handleClose()}
          mode={previewMode}
          previewData={previewData}
          templateId={templateId}
          isExampleOrProductPreview
        />
      )}
    </Box>
  );
} /* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpSearchCondition } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpSearchCondition | null {
  return initialState
}

export const SET_LP_OWN_SEARCH_CONDITION = 'lp-own-search-condition/set' as const
export const CLEAR_LP_OWN_SEARCH_CONDITION = 'lp-own-search-condition/clear' as const

export function setLpOwnSearchCondition(data: LpSearchCondition) {
  return {
    type: SET_LP_OWN_SEARCH_CONDITION,
    payload: {
      data,
    },
  }
}

export function clearLpOwnSearchCondition() {
  return {
    type: CLEAR_LP_OWN_SEARCH_CONDITION,
  }
}

type SetLpOwnSearchConditionAction = ReturnType<typeof setLpOwnSearchCondition>
type ClearLpOwnSearchConditionAction = ReturnType<typeof clearLpOwnSearchCondition>
type Action = SetLpOwnSearchConditionAction | ClearLpOwnSearchConditionAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_OWN_SEARCH_CONDITION:
      return action.payload.data
    case CLEAR_LP_OWN_SEARCH_CONDITION:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

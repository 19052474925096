/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import * as React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import RouteGroupSwitch from 'libs/RouteGroupSwitch'
import { NavigationProvider } from 'contexts/NavigationContext'
import Callback from 'containers/CallbackPageContainer'
import Main from 'containers/MainPageContainer'
import Login from 'containers/LoginPageContainer'

function PublicRoutes() {
  return (
    <Switch>
      <Route path="/callback" component={Callback} />
      <Route exact path="/login" component={Login} />

      <Route
        render={() => <Redirect to="/login" />}
      />
    </Switch>
  )
}

function PrivateRoutes() {
  window.scrollTo(0, 0)

  return (
    <NavigationProvider>
      <Switch>
        <Route path="/callback" component={Callback} />

        <Route path="/" component={Main} />

      </Switch>
    </NavigationProvider>
  )
}


export default function Routes() {
  return (
    <Router>
      <RouteGroupSwitch>
        {({ isLoggedIn }) => {
          if (isLoggedIn) {
            return <PrivateRoutes />
          }

          return <PublicRoutes />

        }}
      </RouteGroupSwitch>
    </Router>
  )
}
/* eslint-enable */

import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { setNoticesSearchCondition } from 'core/modules/noticesSearchCondition';

import { ManageNoticesSortKey, NoticesTargetType } from 'core/domain/notices';
import { hooks } from 'libs';
import ManageNotices from 'components/pages/ManageNotices';
import { clearNoticeDetail } from 'core/modules/noticeDetail';

const pageRows = 10;

/**
 * Presenter
 */

const ManageNoticesPageContainer = () => {
  const dispatch = useDispatch();

  const noticeDetails = useSelector(
    (state: Modules.AppState) => state.noticeDetails,
    shallowEqual,
  );
  const noticesSearchCondition = useSelector(
    (state: Modules.AppState) => state.noticesSearchCondition,
    shallowEqual,
  );

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  useEffect(
    () => () => {
      dispatch(clearNoticeDetail());
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  // 管理用お知らせ情報一覧取得/リクエストパラメーター初期値設定
  useEffect(() => {
    if (!noticesSearchCondition) {
      dispatch(
        setNoticesSearchCondition({
          page: 1,
          row: pageRows,
          target: NoticesTargetType.serviceNotices,
          // sort_key: 'publishStartDateTime',
          // sort_order: 'desc',
        }),
      );

      return;
    }

    if (!userInfo) return;

    dispatch(
      Usecases.notices.sendGetNoticeDetails(
        token,
        userInfo.tenantId,
        noticesSearchCondition,
      ),
    );
  }, [noticesSearchCondition, userInfo, dispatch, token]);

  // 取得したLP数からページ数を計算しリクエストパラメーターに設定
  useEffect(() => {
    if (
      noticeDetails &&
      noticeDetails?.notices?.length === 0 &&
      noticeDetails.totalCount &&
      noticeDetails.totalCount > 0
    ) {
      const latestPage = Math.ceil(noticeDetails?.totalCount / pageRows);
      dispatch(
        setNoticesSearchCondition({
          ...noticesSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [noticeDetails, dispatch, noticesSearchCondition]);

  // LPタグ取得リクエストパラメーター初期値設定
  // useEffect(() => {
  //   dispatch(setManageNoticesTagsRequest({ tagType: 'all', sortKey: 'tag' }));
  // }, []);

  // LPタグ取得
  // useEffect(() => {
  //   if (!userInfo) return;
  //   dispatch(
  //     Usecases.notices.sendGetManageNoticesTags(
  //       token,
  //       userInfo.tenantId,
  //       lPTagsRequest,
  //     ),
  //   );

  //   return () => {
  //     dispatch(clearManageNoticesTags());
  //   };
  // }, [lPTagsRequest, userInfo]);

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setNoticesSearchCondition({
        ...noticesSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */

  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (noticesSearchCondition?.sort_key === sortKey) {
      order = noticesSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setNoticesSearchCondition({
        ...noticesSearchCondition,
        sort_key: sortKey as ManageNoticesSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * ステータスチェックボックスチェック時
   * 該当のLPステータスをリクエストパラメーターに設定
   * @param status
   */
  // const handleCheckStatus = (status: string) => {
  //   dispatch(
  //     setNoticesSearchCondition({
  //       ...noticesSearchCondition,
  //       status,
  //     }),
  //   );
  // };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  // const handleChangeFreeWord = (freeWord: string) => {
  //   dispatch(
  //     setNoticesSearchCondition({
  //       ...noticesSearchCondition,
  //       free_word: freeWord,
  //     }),
  //   );
  // };

  /**
   * 自分のみ表示ラジオボタン切り替え時
   * リクエストパラメーターに設定
   * @param checked
   */

  // const handleChangeOwnSwitch = (checked: boolean) => {
  //   dispatch(
  //     setNoticesSearchCondition({
  //       ...noticesSearchCondition,
  //       own: checked,
  //     }),
  //   );
  // };

  return (
    <ManageNotices
      notices={noticeDetails}
      rows={pageRows}
      noticesSearchCondition={noticesSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      // handleCheckStatus={handleCheckStatus}
      // handleChangeFreeWord={handleChangeFreeWord}
      // handleChangeTag={handleChangeTag}
      // tags={manageNoticesTags}
      // handleChangeOwnSwitch={handleChangeOwnSwitch}
      // handleClickFavorite={handleClickFavorite}
    />
  );
};

export default ManageNoticesPageContainer;

/**
 *
 * 半角か全角か 全角はtrueが返る
 */

export const is2ByteCheck = (charCode: number) =>
  !(charCode >= 0x0 && charCode <= 0x7f);
/**
 * 半角を0.5 全角を1で計算する文字数カウンター
 */
export const get2ByteCheckedLength = (
  str: string,
  countValues?: {
    oneByte: number;
    twoByte: number;
  },
) => {
  let count = 0;
  const oneByte = countValues?.oneByte || 0.5;
  const twoByte = countValues?.twoByte || 1;

  for (let i = 0; i < str.length; i += 1){
    count += is2ByteCheck(str.charCodeAt(i)) ? twoByte : oneByte;
  }


  return count;
};
/**
 * 通常のstringのindexを全角半角でlengthを分けた場合のIndexに変換する
 * @param str 対象の文字列
 * @param index 変換したいIndex
 * @param countValues oneByte 半角でのカウント数 twoByte 全角でのカウント数
 */
export const getIndexTo2ByteIndex = (
  str: string,
  index: number,
  countValues?: {
    oneByte: number;
    twoByte: number;
  },
) => {
  let count = 0;
  const oneByte = countValues?.oneByte || 0.5;
  const twoByte = countValues?.twoByte || 1;
  for (let i = 0; i < str.length; i += 1) {
    if (index <= count) return i;

    count += is2ByteCheck(str.charCodeAt(i)) ? twoByte : oneByte;
  }

  return null;
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, MultipleLinesTextField, SecondaryButton, PrimaryButton, Typography, LetterCountLabel } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'
import { CloseIcon, CheckIcon } from 'assets/images'
import { ConfirmDialog } from 'components/organisms'

/**
 * Interface
 */

export interface TextEditDialogProps {
  open: boolean
  title: string
  value?: string
  handleCancel: () => void
  handleSubmit: (message: string) => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 1028,
      minHeight: 411,
      padding: '32px 24px 24px 24px',
      margin: 0,
      maxWidth: 'none',
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'left'
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(4),
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      }
    },
    count: {
      fontWeight: 'bold',
      marginLeft: '8px'
    },
    limit: {
      color: theme.palette.gray[800]
    }
  }),
)

export default function TextEditDialog(props: TextEditDialogProps) {
  const classes = useStyles({})
  const { open, value, title, handleCancel, handleSubmit } = props
  const [edittingText, setEdittingText] = useState<string | undefined>(value)
  const [errorMessage, setErrorMessage] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  return (
    <>
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}>
      <Box>
        <Typography variant="body2" className={classes.title} >{title}</Typography>
        <Box mt={4}>
          <MultipleLinesTextField
            error={errorMessage}
            rows={10}
            value={edittingText}
            handleChangeText={(text) => setEdittingText(text)}
          />
          <LetterCountLabel onlyInputCount count={edittingText ? `${edittingText.length}` : '0'}/>
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton icon={<CloseIcon className={classes.closeIcon} />} click={() => {
                if(value !== edittingText) {
                  setOpenConfirmDialog(true)
                }else{
                  handleCancel()
                }
                setErrorMessage('')
              }} text="キャンセル" />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton icon={<CheckIcon />} click={() => {
                if(edittingText&&edittingText.length > 1000){
                  setErrorMessage('1000文字以内で入力してください。')

                  return
                }
                handleSubmit(edittingText || '')
                setErrorMessage('')
              }} text="決定" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
    <ConfirmDialog
    buttonText="終了する"
    open={openConfirmDialog}
    title="編集画面を終了します"
    text="保存されていない入力は破棄されますが"
    handleCancel={() => setOpenConfirmDialog(false)}
    handleSubmit={() => {
      handleCancel()
      setEdittingText(value)
      setOpenConfirmDialog(false)
    }}
  />
  </>
  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { setPreviousLpsUrl } from "core/modules/previousLpsUrl"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"

export const useLpsNavigation = () => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  const setCurrentLpsUrl = () => {
    dispatch(setPreviousLpsUrl({url}))
  }

  return {
    setCurrentLpsUrl,
  }
}/* eslint-enable */

import React, { useEffect, useState } from 'react'
import NormalEditLPSettingPage from 'components/pages/NormalEditLPSetting'
import { useDispatch, useSelector } from 'react-redux'
import { Modules, Usecases } from 'core'
import { clearLpTokuisaki } from 'core/modules/lpTokuisaki'
import { clearLpTags } from 'core/modules/lpTags'
import { hooks } from 'libs'
import { setLpTagsRequest } from 'core/modules/lpTagsRequest'
import { setNewLp } from 'core/modules/newLp'

const NormalEditPageContainer = () => {
  const dispatch = useDispatch()
  const newLp = useSelector((state:Modules.AppState) => state.newLp)
  const lpView = useSelector((state:Modules.AppState) => state.lpView)
  const lpTokuisaki = useSelector((state:Modules.AppState) => state.lpTokuisaki)
  const userInfo = useSelector((state:Modules.AppState) => state.userInfo)
  const lPTagsRequest = useSelector((state:Modules.AppState) => state.lPTagsRequest)
  const lpTags = useSelector((state:Modules.AppState) => state.lpTags)
  const normalEditErrors = useSelector((state:Modules.AppState) => state.normalEditErrors)
  const designTemplates = useSelector((state:Modules.AppState) => state.designTemplates)
  const token = hooks.useAuth().accessToken
  const [tokuisakiParam, setTokuisakiParam] = useState('')
  const myLibrary = useSelector((state:Modules.AppState) => state.myLibrary)

  // LP得意先取得、デザインテンプレートデータ取得
  useEffect(() => {
    if (!userInfo) return
    dispatch(Usecases.lps.sendGetLPTokuisaki(token, userInfo.tenantId,tokuisakiParam))
    dispatch(Usecases.settings.sendGetDesignTemplates (token, userInfo.tenantId))
    // マイライブラリから選択
    dispatch(Usecases.myLibrary.sendGetMyLibrary(token, userInfo.tenantId)) 
    dispatch(clearLpTokuisaki())
    
  }, [tokuisakiParam, userInfo, dispatch, token])

  // LPタグ取得リクエストパラメーター初期値設定
  useEffect(() => {
    dispatch(setLpTagsRequest({tagType:"edit",sortKey:"tag"}))
  }, [dispatch])

  // LPタグ取得
  useEffect(() => {
    if (!userInfo) return
    dispatch(Usecases.lps.sendGetLpTags(token, userInfo.tenantId, lPTagsRequest))
    dispatch(clearLpTags())
    
  }, [lPTagsRequest, userInfo, dispatch, token])

  // パスワード設定初期値、ベースデザイン初期値設定
  useEffect(() => {
    if(newLp?.passwordSetting){
      dispatch(setNewLp({
        ...newLp,
        passwordSetting:newLp?.passwordSetting,
        designTemplateId: newLp?.designTemplateId ? newLp?.designTemplateId : 1,
      }))
    }else{
      dispatch(setNewLp({
          ...newLp,
          passwordSetting: 1,
          designTemplateId: newLp?.designTemplateId ? newLp?.designTemplateId : 1,
        }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <NormalEditLPSettingPage
      tagsRequest={lPTagsRequest}
      tags={lpTags}
      userDisplayName={userInfo?.displayName}
      tokuisakiParam={tokuisakiParam}
      setTokuisakiParam={setTokuisakiParam}
      newLp={newLp}
      lpTokuisaki={lpTokuisaki}
      normalEditErrors={normalEditErrors}
      previewData={lpView || undefined}
      userInfo={userInfo}
      designTemplates={designTemplates}
      myLibrary={myLibrary}
    />
  )
}
export default NormalEditPageContainer;
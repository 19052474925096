/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { MaterialWorkflow } from 'core/domain/material'

const initialState = null

export function createInitialState(): Array<MaterialWorkflow> | null {
  return initialState
}

export const SET_EXAMPLE_WORKFLOWS = 'example-workflows/set' as const
export const CLEAR_EXAMPLE_WORKFLOWS = 'example-workflows/clear' as const

export function setExampleWorkflows(data: Array<MaterialWorkflow>) {
  return {
    type: SET_EXAMPLE_WORKFLOWS,
    payload: {
      data,
    },
  }
}

export function clearExampleWorkflows() {
  return {
    type: CLEAR_EXAMPLE_WORKFLOWS,
  }
}

type SetExampleWorkflowsAction = ReturnType<typeof setExampleWorkflows>
type ClearExampleWorkflowsAction = ReturnType<typeof clearExampleWorkflows>
type Action = SetExampleWorkflowsAction | ClearExampleWorkflowsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_EXAMPLE_WORKFLOWS:
      return action.payload.data.reverse()
    case CLEAR_EXAMPLE_WORKFLOWS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { IconButton, Dialog } from 'components/atoms';
import { CloseWhiteIcon } from 'assets/images';
import clsx from 'clsx';
import { LpView } from 'core/domain/lp';

/**
 * Interface
 */
export interface PreviewDialogProps {
  open: boolean;
  handleClose?: () => void;
  mode: 'PC' | 'MOBILE';
  previewData?: LpView;
  templateId?: number;
  isExampleOrProductPreview?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: `calc(100vw - ${theme.spacing(12)}px)`,
      height: `calc(100vh - ${theme.spacing(12)}px)`,
      margin: 0,
      maxWidth: 'none',
      overflowY: 'unset',
    },
    exampleOrProductPreview: {
      maxHeight: '580px',
    },
    iframe: {
      width: '100%',
      height: '100%',
      borderWidth: 0,
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-4%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    smartPhone: {
      width: 375,
      height: 667,
    },
  }),
);

/**
 * Presenter
 */

export default function PreviewDialog(props: PreviewDialogProps) {
  const classes = useStyles({});
  const {
    open,
    handleClose,
    mode,
    previewData,
    templateId,
    isExampleOrProductPreview,
  } = props;
  const iframeEl = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState(false);

  const designAllTemplates = useSelector(
    (state: Modules.AppState) => state.designAllTemplates,
  );
  const network = useSelector((state: Modules.AppState) => state.network);

  useEffect(() => {
    if (!previewData || !loaded) return;
    sendLpData(previewData);
  }, [previewData, loaded]);

  const sendLpData = async (previewData: LpView) => {
    if (!iframeEl || !iframeEl.current || !iframeEl.current.contentWindow)
      return;
    await iframeEl.current?.contentWindow?.postMessage(
      {
        lpData: {
          ...previewData,
        },
      },
      `${process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT}`,
    );
    setLoaded(false);
  };

  const handleClosePreviewClose = () => {
    setLoaded(false);
    handleClose && handleClose();
  };

  const previewPath = useMemo(() => {
    if (!designAllTemplates || designAllTemplates.length === 0)
      return undefined;
    if (!templateId) return designAllTemplates[0].url;

    return designAllTemplates.find(
      (template) => template.designTemplateId === templateId,
    )?.url;
  }, [templateId]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 0 } }}
      classes={{
        paper: clsx(classes.dialog, {
          [classes.smartPhone]: mode === 'MOBILE',
          [classes.exampleOrProductPreview]: isExampleOrProductPreview,
        }),
      }}
      disableBackdropClick
      open={open}
    >
      {network === 0 && (
        <iframe
          ref={iframeEl}
          className={classes.iframe}
          onLoad={() => setLoaded(true)}
          src={previewPath}
        />
      )}
      <IconButton
        onClick={() => handleClosePreviewClose()}
        className={classes.closeButton}
      >
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
}
/* eslint-enable */

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
// import { Box } from '../index';
/**
 * Interface
 */
interface SideListProps {
  label: string;
  url: string;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.green[900],
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      fontSize: 15,
    },
  }),
);

const SideLink = (props: SideListProps) => {
  const { label, url } = props;
  const classes = useStyles();

  return (
    <NavLink className={classes.root} to={url}>
      {label}
      <ArrowRightIcon />
    </NavLink>
  );
};
export default SideLink;

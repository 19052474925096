type SortOrder = 'asc' | 'desc';

export interface NoticeFileDetail {
  noticeFileId: number;
  noticeFileName: string;
  url: string;
}
export interface Notice {
  noticeId: number;
  subject: string;
  content: string;
  publishStartDate: string;
  publishStartTime: string;
  publishEndDate: string;
  publishEndTime: string;
  noticeType: number;
  userId?: number;
  dataType?: number;
  dataId?: number;
  noticeFiles?: NoticeFileDetail[];
  unread: boolean;
}
export interface Notices {
  notices: Notice[];
  totalCount: number;
}

export const NoticesTargetType = {
  TenantAndSercice: 1,
  personalNotices: 2,
  serviceNotices: 3,
} as const;

type Sort = 'asc' | 'desc';
export type NoticesSortKey =
  | 'noticeStatus'
  | 'noticeId'
  | 'noticeType'
  | 'unread'
  | 'subject'
  | 'noticeCreatedUser'
  | 'publishStartDateTime'
  | 'publishEndDateTime';

export type ManageNoticesSortKey =
  | 'noticeStatus'
  | 'subject'
  | 'noticeCreatedUser'
  | 'publishStartDateTime'
  | 'publishEndDateTime';
// | 'noticeDate'
// | 'lpName'
// | 'lpCreateUserName'
// | 'companyName'
// | 'jobTitle'
// | 'customerName'
// | 'mailAddress'

export interface NoticesSearchCondition {
  page?: number;
  row?: number;
  // eslint-disable-next-line camelcase
  free_word?: string;
  status?: string;
  // eslint-disable-next-line camelcase
  sort_key?: NoticesSortKey;
  // eslint-disable-next-line camelcase
  sort_order?: SortOrder;
  // eslint-disable-next-line camelcase
  unread?: boolean;
  target?: number;
}

export interface NoticesQueryParameter {
  page?: number;
  row?: number;
  // eslint-disable-next-line camelcase
  free_word?: string;
  // eslint-disable-next-line camelcase
  sort_key?: NoticesSortKey;
  // eslint-disable-next-line camelcase
  sort_order?: Sort;
  // eslint-disable-next-line camelcase
  unread?: boolean;
  // status?: string;
  target?: number;
}

export interface NoticeQueryParameter {
  page?: number;
  row?: number;
  // eslint-disable-next-line camelcase
  free_word?: string;
  // eslint-disable-next-line camelcase
  sort_key?: NoticesSortKey;
  // eslint-disable-next-line camelcase
  sort_order?: Sort;
  // eslint-disable-next-line camelcase
  unread?: boolean;
  target?: number;
}

export interface NoticeFiles {
  noticeFileId?: number | null;
  noticeFileName?: string;
  url?: string | null;
  data?: string | null;
}

export interface NoticeDetail {
  noticeId: number | null;
  subject: string;
  content: string;
  publishStartDate?: string;
  publishStartTime?: string;
  publishEndDate?: string;
  publishEndTime?: string;
  noticeType: number | null;
  userId?: number;
  dataType?: number;
  dataId?: number;
  noticeStatus?: number;
  noticeFiles: NoticeFiles[];
  noticeCreatedUser?: NoticeCreatedUser | null;
}

export interface NoticeDetails {
  notices: NoticeDetail[] | null;
  totalCount?: number;
}

// お知らせ詳細
export interface NoticesDetail {
  noticeId: number;
  subject: string;
  content: string;
  publishStartDate?: string;
  publishStartTime?: string;
  publishEndDate?: string;
  publishEndTime?: string;
  noticeType: number;
  userId?: number;
  dataType?: number;
  dataId?: number;
  noticeFiles: Array<NoticesFiles>;
  noticeStatus: number;
  noticeCreatedUser?: NoticeCreatedUser;
}

export interface NoticesFiles {
  noticeFileId: number;
  noticeFileName: string;
  url: string;
}

export interface NoticeCreatedUser {
  userId: number;
  userName: string;
  deptName: string;
}

import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { PaginationBar, PrimaryButton, SecondaryButton } from "components/atoms";
import { ConfirmDialog, Content } from "components/organisms";
import TrashesDialog from "components/organisms/TrashesDialog";
import TrashesSearchField from "components/organisms/TrashesSearchField";
import TrashesTable from "components/organisms/TrashesTable";
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from "constants/typography";
import { Modules, Usecases } from "core";
import { SearchFilter, Trash, Trashes, TrashesSearchCondition } from "core/domain/trashes";
import { hooks } from "libs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * Interface
 */
export interface TrashesPageProps {
  trashes: Trashes | null;
  rows: number;
  trashesSearchCondition: TrashesSearchCondition | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangeFreeWord: (freeWord: string) => void;
  handleCheckStatus: (status: number) => void;
  handleLoadCondition: () => void;
  searchFilters: SearchFilter[] | null;
}

/**
 * Style
 */
const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    undoButton: {
      width: 160,
      height: 36,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      width: '332px',
      height: '48px',
      color: 'white',
      backgroundColor: '#44A557',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      border: '1px solid #558643',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '21px',
    },
    deletebutton: {
      '&>button': {
        width: 120,
        height: 36,
        marginLeft: 'auto',
        display: 'flex',
        marginRight: theme.spacing(10),
      },
    },
  }),
)

/**
 * Presenter
 */
const TrashesPage = (props: TrashesPageProps) => {
  const classes = UseStyles({});
  const {
    trashes,
    rows,
    trashesSearchCondition,
    handleChangePage,
    handleChangeSortKey,
    handleChangeFreeWord,
    handleCheckStatus,
    handleLoadCondition,
    searchFilters,
  } = props;

  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const [page, setPage] = useState<number>(1);
  const [checkedTrashes, setCheckedTrashes] = useState<Array<Trash>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openPageDialog, setOpenPageDialog] = useState<boolean>(false);
  const [headerChecked, setHeaderChecked] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<number>(0);
  const [pageTrashes, setPageTrashes] = useState<Trashes>({
    trashes: [],
    totalCount: 0,
  });

  // 初期化
  useEffect(() => {
    if (!trashes) return;
    setPageTrashes(trashes);
  }, [trashes]);

  useEffect(() => {
    // 検索時、チェックをクリア
    setHeaderChecked(false);
    setCheckedTrashes([]);
  }, [trashesSearchCondition]);

  // チェックの付け外し
  const handleChecked = (id: number, val: boolean) => {
    if (!trashes) return;
    let newData = checkedTrashes.slice();
    if (val) {
      const target = trashes.trashes.filter((item) => item.dataId === id)[0];
      newData.push(target);
    } else {
      newData = newData.filter((item) => item.dataId !== id);
      // ヘッダーすべて選択のチェックOFF
      if (headerChecked) {
        setHeaderChecked(false);
      }
    }

    setCheckedTrashes(newData);
  }

  // 元に戻すダイアログ
  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  // チェックされているか確認
  const checkedSelect = () => {
    if (checkedTrashes.map((item) => item.dataId).length) {
      return true;
    }

    return false;
  };

  // 更新後表示
  const updateDispProcess = () => {
    if (!userInfo) return;

    // 更新後の一覧を取得
    dispatch(
      Usecases.trashes.sendGetTrashes(
        token,
        userInfo.tenantId,
        trashesSearchCondition
      ),
    );

    // 更新後の値で一覧を更新
    handleLoadCondition();
    // 更新後にチェックを解除
    setCheckedTrashes([]);
    setHeaderChecked(false);
  };

  // 元に戻す、削除ダイアログで決定クリック
  const handleSubmitTrashesDialog = () => {
    if (!userInfo) return;
    const dataIds = checkedTrashes.map((item) => item.trashId);

    // 削除
    if (dialogType === 0) {
      dispatch(
        Usecases.trashes.sendPostTrashesDelete(
          token,
          userInfo.tenantId,
          dataIds,
          updateDispProcess,
        )
      );
    }

    // 元に戻す
    if (dialogType === 1) {
      dispatch(
        Usecases.trashes.sendPostTrashesUndo(
          token,
          userInfo.tenantId,
          dataIds,
          updateDispProcess,
        )
      );
    }

    // ボタン押下後、ダイアログクローズ
    setIsOpen(false);
  };

  // ヘッダのチェックボックス
  const allCheck = (val: boolean) => {
    if (val) {
      setHeaderChecked(true);
      setCheckedTrashes(pageTrashes.trashes);
    } else {
      setHeaderChecked(false);
      setCheckedTrashes([]);
    }
  };

  // チェック付きページ遷移確認ダイアログ決定後ページ変更
  const changePage = () => {
    setOpenPageDialog(false);
    setCheckedTrashes([]);
    allCheck(false);
    handleChangePage(page);
  };

  // チェック付きページ遷移確認ダイアログキャンセル後処理
  const handleClose = () => {
    setOpenPageDialog(false);
  };

  // チェック付きページ遷移確認ダイアログ表示せずページ変更
  const changePageFromPagenation = (page: number) => {
    setOpenPageDialog(false);
    setCheckedTrashes([]);
    allCheck(false);
    handleChangePage(page);
  };

  return(
    <Box mt={5}>
      <Content title="ゴミ箱">
        <div>
          <Box className={classes.section}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TrashesSearchField
                  handleChangeFreeWord={handleChangeFreeWord}
                  handleCheckStatus={handleCheckStatus}
                  trashesSearchCondition={trashesSearchCondition}
                  searchFilters={searchFilters}
                />
              </Grid>
              <Grid item xs={2}>
                <Box height={36} mx="auto">
                  <PrimaryButton
                    disabled={!checkedTrashes.length}
                    text="ゴミ箱から元に戻す"
                    click={() => {
                      setIsOpen(true);
                      setDialogType(1);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box height={36} mx="auto">
                  <SecondaryButton
                    disabled={!checkedTrashes.length}
                    text="削除する"
                    click={() => {
                      setIsOpen(true);
                      setDialogType(0);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.table}>
            <TrashesTable
              trashes={trashes}
              trashesSearchCondition={trashesSearchCondition}
              handleChecked={handleChecked}
              allCheck={(val: boolean) => allCheck(val)}
              handleChangeSortKey={handleChangeSortKey}
              checkedTrashes={checkedTrashes}
              headerChecked={headerChecked}
              searchFilters={searchFilters}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  trashesSearchCondition ? trashesSearchCondition.page : 1
                }
                maxPage={
                  trashes ? Math.ceil(trashes.totalCount / rows) : 1
                }
                handleChangePage={(page: number) => {
                  setPage(page);
                  if (checkedSelect()) setOpenPageDialog(true);
                  else changePageFromPagenation(page);
                }}
              />
            </Box>
          </Box>
        </div>
      </Content>
      {/* チェックをページ移動時に外す */}
      <ConfirmDialog
        buttonText="はい"
        open={openPageDialog}
        title=""
        text="ページ移動すると行選択チェックが無効になりますが"
        handleCancel={handleClose}
        handleSubmit={changePage}
      />
      {/* 元に戻す、削除ダイアログ */}
      <TrashesDialog
        open={isOpen}
        handleCancel={handleCloseDialog}
        handleSubmit={handleSubmitTrashesDialog}
        checkedTrashes={checkedTrashes}
        dialogType={dialogType}
      />
    </Box>
  )
};
export default TrashesPage;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { IconButton } from 'components/atoms'
import { Tooltip } from '@material-ui/core'

/**
 * Interface
 */
export interface OutLineIconButtonProps {
  icon: React.ReactNode
  hoverIcon: React.ReactNode
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      borderColor: theme.palette.gray[800],
      borderWidth: 1,
      borderStyle: 'solid',
      textAlign: 'center',
      height: '35px',
      width: '35px',
      padding: 0,
      '&:hover': {
        background: theme.palette.green[800]
      },
    },
  }),
)

/**
 * Presenter
 */

export default function OutLineIconButton(props: OutLineIconButtonProps) {

  const classes = useStyles({})
  const { icon, hoverIcon } = props
  const [hovered, setHovered] = useState(false)

  return (
    <Tooltip title="URLをクリップボードにコピーする" placement="top" arrow>
    <IconButton className={classes.iconButton}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {!hovered ? <>{icon}</> : <>{hoverIcon}</>}
    </IconButton>
    </Tooltip>
  )
}/* eslint-enable */

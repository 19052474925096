import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  NOTICE_STATUS_SCHEDULED_DELIVERY,
  NOTICE_STATUS_NOW_DELIVERY,
  NOTICE_STATUS_FINISHED_DELIVERY,
} from 'constants/text';

/**
 * Interface
 */

export interface NoticeStatusLabelProps {
  status: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    orange: {
      backgroundColor: '#3D9FCB',
      borderColor: '#3D9FCB',
      color: 'white',
    },
    blue: {
      backgroundColor: '#D87F24',
      borderColor: '#D87F24',
      color: 'white',
    },
    gray: {
      backgroundColor: 'white',
      borderColor: '#D87F24',
      color: '#D87F24',
    },
  }),
);

interface StatusType {
  status: number;
  label: string;
  color: string;
}

const STATUS_TYPES: Array<StatusType> = [
  {
    status: 1,
    label: NOTICE_STATUS_SCHEDULED_DELIVERY,
    color: 'orange',
  },
  {
    status: 2,
    label: NOTICE_STATUS_NOW_DELIVERY,
    color: 'blue',
  },
  {
    status: 3,
    label: NOTICE_STATUS_FINISHED_DELIVERY,
    color: 'gray',
  },
];

/**
 * Presenter
 */

const NoticeDetailStatusLabel = (props: NoticeStatusLabelProps) => {
  const classes = useStyles({});
  const { status } = props;

  const decideClass = useMemo(() => {
    let tmpClass = classes.orange;

    switch (status) {
      case 1:
        tmpClass = classes.orange;
        break;

      case 2:
        tmpClass = classes.blue;
        break;

      case 3:
        tmpClass = classes.gray;
        break;

      default:
        tmpClass = classes.orange;
        break;
    }

    return tmpClass;
  }, [status, classes.blue, classes.orange, classes.gray]);

  const statusLabel = useMemo(() => {
    const targetStatus = STATUS_TYPES.find((type) => type.status === status);
    if (!targetStatus) return '';

    return targetStatus.label;
  }, [status]);

  return (
    <Typography
      className={`${classes.root} ${decideClass}`}
      variant="caption"
      display="block"
    >
      {statusLabel}
    </Typography>
  );
};
export default NoticeDetailStatusLabel;

import { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modules, Usecases } from 'core';
import ExampleDetail from 'components/pages/ExampleDetail';
import { clearExampleDetail } from 'core/modules/exampleDetail';
import { clearExampleWorkflows } from 'core/modules/exampleWorkflows';
import { convertExampleDetailToLpView } from 'libs/converter';
import { hooks } from 'libs';
import { CODE_MODE_REFERENCE } from 'constants/code';

/**
 * Presenter
 */
const ExampleDetailPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { exampleId } = useParams<{ exampleId: string }>();

  const exampleDetail = useSelector(
    (state: Modules.AppState) => state.exampleDetail,
  );
  const exampleWorkflows = useSelector(
    (state: Modules.AppState) => state.exampleWorkflows,
  );
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;

  // 設定値取得LPプレビュー用
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
    dispatch(
      Usecases.settings.sendGetInquiriesGreetText(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetInquiryForms(token, userInfo.tenantId, 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 導入事例詳細/導入事例ワークフロー情報取得
  useEffect(() => {
    if (!userInfo) return undefined;
    dispatch(
      Usecases.examples.sendGetExampleDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
    dispatch(
      Usecases.examples.sendGetExampleWorkflows(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
      ),
    );

    return () => {
      dispatch(clearExampleDetail());
      dispatch(clearExampleWorkflows());
    };
  }, [dispatch, exampleId, token, userInfo]);

  /**
   * 導入事例オススメ更新
   */
  const handleRecommendedUpdate = useCallback(async () => {
    if (!exampleDetail || !userInfo) return;
    // awaitが無いと、画面が更新されなくなる.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.examples.sendPatchExampleRecommended(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
        !exampleDetail.recommended,
      ),
    );
    dispatch(
      Usecases.examples.sendGetExampleDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, exampleDetail, exampleId, token, userInfo]);

  /**
   * 導入事例ワークフローステータス更新
   */
  const handleWorkflowStatusUpdate = useCallback(
    async (step: number, status: number, message?: string) => {
      if (
        !exampleDetail ||
        !exampleWorkflows ||
        exampleWorkflows.length === 0 ||
        !userInfo
      )
        return;
      const workflow = exampleWorkflows[0];
      // awaitが無いと、画面が更新されなくなる.
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        Usecases.examples.sendPatchExampleWorkflowStatus(
          token,
          userInfo.tenantId,
          Number.parseInt(exampleId, 10),
          workflow.workflowId,
          step,
          status,
          message,
        ),
      );
      dispatch(
        Usecases.examples.sendGetExampleDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(exampleId, 10),
          CODE_MODE_REFERENCE,
        ),
      );
    },
    [dispatch, exampleDetail, exampleId, exampleWorkflows, token, userInfo],
  );

  /**
   * 公開一時停止処理
   */
  const handleStop = useCallback(() => {
    if (!exampleDetail || !userInfo) return;
    dispatch(
      Usecases.examples.sendPostExampleStop(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
      ),
    );
  }, [dispatch, exampleDetail, exampleId, token, userInfo]);

  /**
   * 再公開処理
   */
  const handleReopen = useCallback(() => {
    if (!exampleDetail || !userInfo) return;
    dispatch(
      Usecases.examples.sendPostExampleReopen(
        token,
        userInfo.tenantId,
        Number.parseInt(exampleId, 10),
      ),
    );
  }, [dispatch, exampleDetail, exampleId, token, userInfo]);

  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
  );
  const lpSettings = useSelector((state: Modules.AppState) => state.lpSettings);

  /**
   * LPプレビュー用データ変換
   */
  const previewData = useMemo(() => {
    if (!exampleDetail) return undefined;

    return {
      ...convertExampleDetailToLpView(
        exampleDetail,
        lpSettings,
        inquiriesGreetText,
        inquiryForms,
      ),
      isPreview: true,
    };
  }, [exampleDetail, inquiriesGreetText, inquiryForms, lpSettings]);

  /**
   * 削除処理
   */
  const handleDelete = async () => {
    if (!userInfo || !exampleId) return;
    // awaitが無いと、画面が更新されなくなる.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.examples.sendPostExampleTrash(
        token,
        userInfo.tenantId,
        exampleId,
      ),
    );
    navigate.navigatePop();
  };

  return (
    exampleDetail && (
      <ExampleDetail
        userInfo={userInfo}
        exampleDetail={exampleDetail}
        exampleWorkflows={exampleWorkflows}
        handleRecommendedUpdate={handleRecommendedUpdate}
        handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
        handleStop={handleStop}
        handleReopen={handleReopen}
        previewData={previewData}
        handleDelete={handleDelete}
      />
    )
  );
};

export default ExampleDetailPageContainer;

import React, { useState, useEffect } from 'react';
import { List, PaginationBar } from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DashboardNoticeItem } from 'components/molecules';
import { useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';
import { Notice } from 'core/domain/notices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      minHeight: 240,
      '& > :last-child': {
        marginBottom: theme.spacing(3),
      },
    },
  }),
);

const DashboardNoticeArea = () => {
  const classes = useStyles();
  const notices = useSelector(
    (state: Modules.AppState) => state.dashboardNotices,
    shallowEqual,
  );
  const [currPage, setCurrPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [displayNotices, setDisplayNotices] = useState<Notice[]>();

  const handleChangePage = (page: number) => {
    setCurrPage(page);
  };

  useEffect(() => {
    if (!notices) return;
    setMaxPage(Math.ceil(notices.totalCount / 4));
  }, [notices]);

  useEffect(() => {
    if (!notices) return;
    const sliceCount = (currPage - 1) * 4;
    const selectedNotices = notices.notices.slice(sliceCount, sliceCount + 4);
    setDisplayNotices(selectedNotices);
  }, [notices, currPage]);

  return (
    <>
      <List className={classes.list}>
        {displayNotices &&
          displayNotices.map((notice) => (
            <DashboardNoticeItem
              key={notice.noticeId}
              endDate={notice.publishEndDate}
              endTime={notice.publishEndTime}
              id={notice.noticeId}
              startDate={notice.publishStartDate}
              startTime={notice.publishStartTime}
              title={notice.subject}
              type={notice.noticeType}
            />
          ))}
      </List>
      <PaginationBar
        currentPage={currPage}
        maxPage={maxPage}
        handleChangePage={handleChangePage}
      />
    </>
  );
};

export default DashboardNoticeArea;

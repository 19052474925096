import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setLpSearchCondition } from 'core/modules/lpSearchCondition';

import { Modules } from 'core';

import clsx from 'clsx';
import {
  Box,
  Typography,
  SearchTextField,
  FormControlLabel,
  AdditionalTag,
  SearchProductTag,
  Checkbox,
  FormGroup,
  ManagerTag,
} from 'components/atoms';

import { TargetOpenPeriodLabel, TargetPeriodLabel } from 'components/molecules';

import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { LpSearchCondition } from 'core/domain/lp';
import { CODE_MATERIAL_STATUS_END, CODE_MATERIAL_STATUS_RELEASED, CODE_MATERIAL_STATUS_SUSPENSION } from 'constants/code';
import { UserInfoDetail } from 'core/domain/user';
import { clearUserTags, setUserTags } from 'core/modules/userTags';
import { LpManagerSelectDialog } from '..';


/**
 * Interface
 */

export interface LPSearchFieldProps {
  switchLabel?: string;
  searchCondition: LpSearchCondition | null;
  switchValue: boolean | null;
  handleCheckStatus?: (status: string) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  addTags?: () => void;
  handleChangeSwitch?: (checked: boolean) => void;
  deleteTag:(val:string) =>void;
  handleCheckLpStatus?: (status: string) => void;
  handleChangeLpTantoUser: (users: Array<UserInfoDetail>) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&>div:not(:first-child)': {
        // marginTop: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    checkSection: {
      display: 'flex',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(1),
      // eslint-disable-next-line
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line
      color: theme.palette.gray[800],
      minInlineSize: 'max-content',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
      padding:1,
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        marginLeft:5,
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkboxFavoriteAndMyPage: {
      display:'inline-grid',
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
      padding:1,
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkBoxTexts:{
      height:100,
      alignItems: 'center',
      width: 90,
      marginRight:5,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      flexDirection:"row",
    },
    checkBoxText:{
      margin:5,
      width:100,
    },
    statusMarginLeft:{
      marginLeft:11,
    }
  }),
);

// ステータス検索用
interface StatusFilter {
  label: string;
  // eslint-disable-next-line camelcase
  lp_status: number;
}
// ステータス検索用
const STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: '公開中',
    lp_status: CODE_MATERIAL_STATUS_RELEASED,
  },
  {
    label: '公開一時停止',
    lp_status: CODE_MATERIAL_STATUS_SUSPENSION,
  },
  {
    label: '公開終了',
    lp_status: CODE_MATERIAL_STATUS_END,
  },
];

const LPSearchField = React.memo((props: LPSearchFieldProps) => {
  const classes = useStyles({});

  const dispatch = useDispatch();

  const {
    handleCheckStatus,
    handleChangeFreeWord,
    addTags,
    handleChangeSwitch,
    switchValue,
    deleteTag,
    handleCheckLpStatus,
    handleChangeLpTantoUser,
  } = props;

  const searchCondition = useSelector(
    (state: Modules.AppState) => state.lpSearchCondition,
    shallowEqual,
  );

  // 以下集計期間
  const [addTermFrom, setAddTermFrom] = useState(
    searchCondition?.add_term_from || '',
  );
  const [addTermTo, setAddTermTo] = useState(
    searchCondition?.add_term_to || '',
  );

  useEffect(() => {
    if (!searchCondition) return;
    setAddTermFrom(searchCondition.add_term_from || '');
    setAddTermTo(searchCondition.add_term_to || '');
  }, [searchCondition]);

  const [addTermFromError, setAddTermFromError] = useState<string | undefined>(
    undefined,
  );
  const [addTermToError, setAddTermToError] = useState<string | undefined>(
    undefined,
  );

  const handleChangeAddTermFrom = (date: string) => {
    setAddTermFrom(date.split('/').join(''));
  };

  const handleChangeAddTermTo = (date: string) => {
    setAddTermTo(date.split('/').join(''));
  };

  const changeYearToTimestamp = (dt: string) => {
    const year = Number(dt.substr(0, 4));
    const month = Number(dt.substr(4, 2)) - 1;
    const date = Number(dt.substr(6, 2));

    return new Date(year, month, date).getTime();
  };

  useEffect(() => {
    const addTermFromTimeStamp = changeYearToTimestamp(addTermFrom || '');
    const addTermToTimeStamp = changeYearToTimestamp(addTermTo || '');

    const regex = /^[0-9]{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;

    if (addTermFrom !== '' && !regex.test(addTermFrom))
      setAddTermFromError('日付の形式で入力してください。');
    else setAddTermFromError(undefined);

    if (addTermTo !== '' && !regex.test(addTermTo))
      setAddTermToError('日付の形式で入力してください。');
    else setAddTermToError(undefined);

    if (
      (addTermFrom !== '' && !regex.test(addTermFrom)) ||
      (addTermTo !== '' && !regex.test(addTermTo))
    ) {
      return;
    }

    if (addTermFrom !== '' && addTermFromTimeStamp > Date.now()) {
      setAddTermFromError('開始日は現在日付以前で設定してください');

      return;
    }
    setAddTermFromError(undefined);

    if (
      addTermFrom !== '' &&
      addTermTo !== '' &&
      addTermFromTimeStamp > addTermToTimeStamp
    ) {
      setAddTermFromError('開始日は終了日以前で設定してください');

      return;
    }
    setAddTermFromError(undefined);

    setAddTermToError(undefined);

    // Errorどちらもundefinedなら実行

    if (searchCondition)
      dispatch(
        setLpSearchCondition({
          ...searchCondition,
          add_term_from: addTermFrom,
          add_term_to: addTermTo,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addTermFrom,
    addTermTo,
    setAddTermFromError,
    setAddTermToError,
    dispatch,
  ]);

  // 以上集計期間

  // 以下公開期間
  const [openTermFrom, setOpenTermFrom] = useState(
    searchCondition?.open_term_from || '',
  );
  const [openTermTo, setOpenTermTo] = useState(
    searchCondition?.open_term_to || '',
  );

  useEffect(() => {
    if (!searchCondition) return;
    setOpenTermFrom(searchCondition.open_term_from || '');
    setOpenTermTo(searchCondition.open_term_to || '');
  }, [searchCondition]);

  const [openTermFromError, setOpenTermFromError] = useState<string | undefined>(
    undefined,
  );
  const [openTermToError, setOpenTermToError] = useState<string | undefined>(
    undefined,
  );

  const handleChangeOpenTermFrom = (date: string) => {
    setOpenTermFrom(date.split('/').join(''));
  };

  const handleChangeOpenTermTo = (date: string) => {
    setOpenTermTo(date.split('/').join(''));
  };

  useEffect(() => {
    const openTermFromTimeStamp = changeYearToTimestamp(openTermFrom || '');
    const openTermToTimeStamp = changeYearToTimestamp(openTermTo || '');

    const regex = /^[0-9]{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;

    if (openTermFrom !== '' && !regex.test(openTermFrom))
      setOpenTermFromError('日付の形式で入力してください。');
    else setOpenTermFromError(undefined);

    if (openTermTo !== '' && !regex.test(openTermTo))
      setOpenTermToError('日付の形式で入力してください。');
    else setOpenTermToError(undefined);

    if (
      (openTermFrom !== '' && !regex.test(openTermFrom)) ||
      (openTermTo !== '' && !regex.test(openTermTo))
    ) {
      return;
    }

    if (openTermFrom !== '' && openTermFromTimeStamp > Date.now()) {
      setOpenTermFromError('開始日は現在日付以前で設定してください');

      return;
    }
    setOpenTermFromError(undefined);

    if (
      openTermFrom !== '' &&
      openTermTo !== '' &&
      openTermFromTimeStamp > openTermToTimeStamp
    ) {
      setOpenTermFromError('開始日は終了日以前で設定してください');

      return;
    }
    setOpenTermFromError(undefined);

    setOpenTermToError(undefined);

    // Errorどちらもundefinedなら実行

    if (searchCondition)
      dispatch(
        setLpSearchCondition({
          ...searchCondition,
          open_term_from: openTermFrom,
          open_term_to: openTermTo,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openTermFrom,
    openTermTo,
    setOpenTermFromError,
    setOpenTermToError,
    dispatch,
  ]);
  // 以上公開期間

  const tags = useMemo(() => {
    if (!searchCondition || !searchCondition.tags) return [];

    return searchCondition.tags.split(',');
  }, [searchCondition]);

  const statusCheck = useMemo(() => {
    if (!searchCondition || !searchCondition.lp_status) return [];

    return searchCondition.lp_status.split(',');
  }, [searchCondition]);


  // お気に入りチェック
  const [favoriteCheck, setFavoriteCheck] = useState(false);

  // お気に入りチェック同期
  useEffect(()=>{

    if(searchCondition?.status === 'favorite'){
      // チェックボックスチェックお気に入りを検索
      setFavoriteCheck(true);
    } else if (searchCondition?.status ==='all'){
      // チェックボックス外す、全てを検索
      setFavoriteCheck(false);
    } else{
      setFavoriteCheck(false);
    }
  },[searchCondition?.status])

  // お気に入りチェックボックス動作
  const favoriteChackBox = (val:boolean) => {
    if(!handleCheckStatus) return;

    if(val){
      // チェックボックスチェックお気に入りを検索
      setFavoriteCheck(true);
      handleCheckStatus("favorite")
    }else{
      // チェックボックス外す、全てを検索
      setFavoriteCheck(false);
      handleCheckStatus("all")
    }
  }

  // 以下担当者絞り込み機能
  // 選択中の担当者表示用state
  const userTags = useSelector((state: Modules.AppState) => state.userTags);
  const [selectedUser,setSelectedUser] = useState(['']);

  const [isManagerSelect, setManagerSelect] = useState<boolean>(false);

  // 担当者絞り込み用
  const [selectedUsers, setSelectedUsers] = useState<Array<UserInfoDetail>>([]);

  // 担当者絞り込み表示
  useEffect(()=>{
    // 一覧に戻ったときに担当者絞り込みしていたら表示する
    if(!searchCondition?.lp_tanto_user_ids){
      // 絞り込みしていなかったら担当者の表示をクリア
      dispatch(clearUserTags());
      setSelectedUser([]);
      setSelectedUsers([]);
    }

    if (!userTags?.userTags) return;

    setSelectedUser(userTags.userTags.map((userTags) => userTags))

  },[searchCondition?.lp_tanto_user_ids, userTags, dispatch])

  const handleSelectManager = () => {
    setManagerSelect(true);
  };

  const closeManagerSelect = () => {
    setManagerSelect(false);
  };

  const submitLpUserManager = (data: Array<UserInfoDetail>) => {
    setManagerSelect(false);
    handleChangeLpTantoUser(data);
    // 担当者絞り込み用
    const userNameData = data.map((data)=> data.userName);
    dispatch(setUserTags({userTags:userNameData}));
    // 絞り込み中ユーザ保持
    setSelectedUsers(data);
  };

  const deleteTantoUserTag = (val:string)=>{

    if (!searchCondition) return;

    if(!searchCondition?.lp_tanto_user_ids || !userTags)
    {
      dispatch(clearUserTags())
      setSelectedUser([]);

      return;
    }

    const userIdData = selectedUsers.find((data)=>data.userName.toString() === val);
    // バツをクリックしたユーザを除去
    const newUsers = selectedUsers.filter((data:UserInfoDetail)=> data !== userIdData);
    // 絞り込み中ユーザ更新
    setSelectedUsers(newUsers);
    // タグ表示更新
    dispatch(
      setUserTags({
        userTags:newUsers.map((a:UserInfoDetail)=>a.userName)
      })
    );

    if(!userIdData) return;
    // 検索リクエストパラメータのタグ設定
    const newTags = searchCondition?.lp_tanto_user_ids.split(',').filter(
      (data:string) => data !== userIdData.userId.toString()
    ).join(',');

    dispatch(
      setLpSearchCondition({
        ...searchCondition,
        lp_tanto_user_ids: newTags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: searchCondition?.free_word,
      }),
    );
  }
  // 以上担当者絞り込み機能

  return (
    <Box className={classes.root}>
      {/* 検索フォーム + 集計期間 */}
      <Box display="flex">
        {/* 検索フォーム */}
        <Box className={classes.section}>
          <Typography className={classes.title} variant="caption">
            フリーワード検索
          </Typography>
          <Box className={classes.freeWord}>
            <SearchTextField
              label="検索ワードを入力"
              handleChangeText={handleChangeFreeWord}
              value={
                searchCondition?.free_word ? searchCondition.free_word : ''
              }
            />
          </Box>
          <Typography className={classes.description} variant="caption">
            登録されている情報全体からフリーワードで検索します。
          </Typography>
        </Box>

        {/* 集計期間 */}
        <Box className={classes.checkSection} marginLeft="auto" flexWrap="wrap" maxWidth={400}>
          <TargetPeriodLabel
            start={addTermFrom}
            end={addTermTo}
            startError={addTermFromError}
            endError={addTermToError}
            handleChangeStartDate={handleChangeAddTermFrom}
            handleChangeEndDate={handleChangeAddTermTo}
          />
          {/* 公開期間 */}
          <TargetOpenPeriodLabel
            start={openTermFrom}
            end={openTermTo}
            startError={openTermFromError}
            endError={openTermToError}
            handleChangeStartDate={handleChangeOpenTermFrom}
            handleChangeEndDate={handleChangeOpenTermTo}
          />
        </Box>
      </Box>

      {/* タグ検索 */}
      <Box className={classes.section}>
        <Typography
          className={clsx([classes.title, classes.tagTitle])}
          variant="overline"
        >
          タグ検索
        </Typography>
        <Box>
          {tags.map((tag, index) => (
            <SearchProductTag 
              item={tag}
              key={index}
              deleteTag={
                (val:string)=>{
                  deleteTag(val)
                }
              } />
          ))}
          <AdditionalTag click={addTags} />
        </Box>
      </Box>
      <Box className={classes.section}>
          <Typography
            className={clsx([classes.title, classes.tagTitle])}
            variant="overline"
          >
            担当者絞り込み
          </Typography>
          <Box>
            {/* 担当者を表示 */}
            {selectedUser.map((tag, index) => (
              <ManagerTag item={tag} key={index} 
              deleteTag={
                (val:string)=>{
                  deleteTantoUserTag(val)
                }
              }/>
            ))}
            <AdditionalTag click={handleSelectManager} />
          </Box>
        </Box>
      <Box className={classes.checkSection}>
        <Box className={classes.checkBoxTexts}>
          <Box className={classes.checkBoxText}>
              お気に入り
          </Box>
          <Box className={classes.checkBoxText}>
              自分のみ
          </Box>
          <Box className={classes.checkBoxText}>
              ステータス
          </Box>
        </Box>
        <Box>
          <Box className={classes.checkboxFavoriteAndMyPage}>
          <Checkbox
            // {/* お気に入り */}
            checked={favoriteCheck}
            onChange={(e,val:boolean) =>
              favoriteChackBox(val)
            }
          />
          <Checkbox
            // {/* 自分のみ */}
            checked={switchValue || false}
            onChange={(e, checked) => handleChangeSwitch && handleChangeSwitch(checked)}
          />
          </Box>
          <FormGroup row className={classes.statusMarginLeft}>
          {/* eslint-disable-next-line camelcase */}
            {STATUS_FILTERS.map((lp_status, index) => (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={statusCheck?.includes(`${lp_status.lp_status}`)}
                    onClick={() =>
                      handleCheckLpStatus && handleCheckLpStatus(`${lp_status.lp_status}`)
                    }
                />}
                label={lp_status.label}
                key={index}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
      <LpManagerSelectDialog
        open={isManagerSelect}
        handleCancel={closeManagerSelect}
        selectManager={submitLpUserManager}
        dialogType={2}
        selectedUsers={selectedUsers}
      />
    </Box>
  );
});

export default LPSearchField;
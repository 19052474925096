import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  NoticeStatusLabel,
  NoticeUnreadLabel,
} from 'components/atoms';
import {
  formatDateYYYYMMDDSeparateSlash,
  formateTimeHHMMColon,
} from 'libs/date';

import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Notices, NoticesQueryParameter } from 'core/domain/notices';

/**
 * Interface
 */

export interface NoticesTableProps {
  clickNotice?: (id: number) => void;
  notices: Notices | null;
  handleChangeSortKey: (sortKey: string) => void;
  noticesQueryParameter: NoticesQueryParameter | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        fontWeight: 'bold',
        color: theme.palette.gray[800],
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    publishDateTime: {
      width: '11%',
    },
    noticeType: {
      width: '14%',
    },
    subject: {
      width: '56%',
    },
    status: {
      width: '8%',
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '掲載開始日',
    sortKey: 'publishStartDateTime',
    width: '11%',
  },
  {
    title: '掲載終了日',
    sortKey: 'publishEndDateTime',
    width: '11%',
  },
  {
    // 通知ラベル
    title: 'カテゴリ',
    sortKey: 'noticeType',
    width: '14%',
  },
  {
    title: 'タイトル',
    sortKey: 'subject',
    width: '56%',
  },
  {
    title: '開封状況',
    sortKey: '',
    width: '8%',
  },
];

const NoticesTable = (props: NoticesTableProps) => {
  const classes = useStyles({});
  const { clickNotice, notices, handleChangeSortKey, noticesQueryParameter } =
    props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <colgroup>
          {TABLE_HEADER_ITEMS.map((item, index ) => (
            <col key={index} width={item.width} />
          ))}
        </colgroup>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {item.sortKey !== '' ? (
                  <TableSortLabel
                    active
                    direction={noticesQueryParameter?.sort_order}
                    IconComponent={() =>
                      item.sortKey === noticesQueryParameter?.sort_key &&
                      noticesQueryParameter?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => {
                      handleChangeSortKey(item.sortKey);
                    }}
                  >
                    {item.title}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} hideSortIcon>
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {notices &&
            notices.notices.map((notice, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: notice.unread ? 'white' : '#eeeeee',
                }}
              >
                {/* 掲載開始日時 */}
                <TableCell>
                  {`${formatDateYYYYMMDDSeparateSlash(
                    notice.publishStartDate,
                  ).replace(' ', '\n')} ${formateTimeHHMMColon(
                    notice.publishStartTime,
                  )}`}
                </TableCell>

                {/* 掲載終了日時 */}
                <TableCell>
                  {`${formatDateYYYYMMDDSeparateSlash(
                    notice.publishEndDate,
                  ).replace(' ', '\n')} ${formateTimeHHMMColon(
                    notice.publishEndTime,
                  )}`}
                </TableCell>

                {/* 種別 */}
                <TableCell>
                  {notice.noticeType !== 2 && (
                    <NoticeStatusLabel status={notice.noticeType} />
                  )}
                </TableCell>

                {/* タイトル */}
                <TableCell className={classes.link}>
                  <ButtonLink
                    text={`${notice.subject}`}
                    tooltipText={`${notice.subject}`}
                    click={() => clickNotice && clickNotice(notice.noticeId)}
                  />
                </TableCell>

                {/* 既読状態 */}

                <TableCell>
                  <Box>
                    <NoticeUnreadLabel status={notice.unread} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default NoticesTable;

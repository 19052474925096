/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Typography, Box } from 'components/atoms'
import { Content } from 'components/organisms'

/**
 * Presenter
 */

export default function NotFoundPage() {

  return (
    <Box mt={5}>
      <Content title="不正なURLです">
        <Typography variant="h5" component="h5">
          リクエストされたページは存在しません
        </Typography>
      </Content>
    </Box>
  )
}
/* eslint-enable */

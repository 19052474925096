/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Drawer } from 'components/atoms';
import { Modules } from 'core';

/**
 * Interface
 */

export interface PageTemplateProps {
  headerBar: React.ReactNode;
  sidebar: React.ReactNode;
  children: React.ReactElement;
  footerBar: React.ReactNode;
}

/**
 * Style
 */

// FIXME: constants で管理する
const pageMinWidth = 1366;
const sidebarWidth = 226;
const sidebarShiftWidth = 72;
const headerHeight = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      fallbacks: {
        display: '-ms-grid',
      },
      width: '100%',
      height: '100%',
      '-ms-grid-rows': `${headerHeight}px 1fr`,
      '-ms-grid-columns': '1fr',
      gridTemplate: `
      'pageHeaderBar' ${headerHeight}px
      'pageContent' auto
      `,
      minWidth: pageMinWidth,
      minHeight: '100vh',
    },
    headerBar: {
      gridArea: 'pageHeaderBar',
      '-ms-grid-row': 1,
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      position: 'fixed',
    },
    content: {
      gridArea: 'pageContent',
      '-ms-grid-row': 2,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.gray[400],
    },
    container: {
      height: `100%`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(4)}px`,
    },
    sidebar: {
      width: sidebarWidth,
      flexShrink: 0,
      willChange: 'width',
    },
    sidebarPaper: {
      width: sidebarWidth,
      paddingTop: headerHeight,
      borderWidth: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    sidebarWidth: {
      width: sidebarWidth,
    },
    sidebarShiftWidth: {
      width: sidebarShiftWidth,
    },
    main: {
      height: '100%',
      minWidth: pageMinWidth - sidebarWidth,
      willChange: 'width',
      marginLeft: 'auto',
      flexGrow: 1,
      transition: theme.transitions.create(['width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      width: `calc(100vw - ${sidebarWidth}px)`,
    },
    mainShift: {
      minWidth: pageMinWidth - sidebarShiftWidth,
      width: `calc(100vw - ${sidebarShiftWidth}px)`,
    },
    mainFull: {
      minWidth: pageMinWidth,
      width: '100vw',
    },
    headerHide: {
      padding: 0,
    },
  }),
);

/**
 * Presenter
 */

const PageTemplate = React.memo((props: PageTemplateProps) => {
  const { children, headerBar, sidebar, footerBar } = props;
  const classes = useStyles();
  const sidebarShift = useSelector(
    (state: Modules.AppState) => state.sidebarShift,
  );
  const sidebarShown = useSelector(
    (state: Modules.AppState) => state.sidebarShown,
  );
  const headerShown = useSelector(
    (state: Modules.AppState) => state.headerShown,
  );

  return (
    <div className={clsx({ [classes.root]: headerShown })}>
      {headerShown && (
        <div className={classes.headerBar}>{headerBar && headerBar}</div>
      )}
      <div className={classes.content}>
        <Drawer
          className={clsx(classes.sidebar, {
            [classes.sidebarShiftWidth]: sidebarShift,
          })}
          variant="persistent"
          anchor="left"
          open={sidebarShown}
          classes={{
            paper: clsx(classes.sidebarPaper, {
              [classes.sidebarShiftWidth]: sidebarShift,
            }),
          }}
        >
          {sidebar && sidebar}
        </Drawer>
        <main
          className={clsx(classes.main, {
            [classes.mainShift]: sidebarShift,
            [classes.mainFull]: !sidebarShown,
          })}
        >
          <Container
            maxWidth={false}
            className={clsx({
              [classes.container]: headerShown,
              [classes.headerHide]: !headerShown,
            })}
          >
            {children}
            {footerBar}
          </Container>
        </main>
      </div>
    </div>
  );
});

export default PageTemplate;
/* eslint-enable */

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from 'components/atoms';
import { AppLogoIcon } from 'assets/images';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.text.secondary,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      // eslint-disable-next-line   @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
      borderBottomColor: theme.palette.gray[600],
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing(3)}px`,
    },
    logo: {
      width: 119,
      height: 20,
    },
    logoImage: {
      width: '116px',
      height: '27px',
    },
    id: {
      marginRight: theme.spacing(1),
    },
    title: {
      padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(4)}px`,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
);

/**
 * Presenter
 */

const NormalEditHeaderBar = () => {
  const classes = useStyles({});
  const newLp = useSelector((state: Modules.AppState) => state.newLp);
  const { lpId } = useParams<{ lpId: string }>();
  const isRegister = useHistory().location.pathname.includes('register');

  return (
    <header>
      <Box className={classes.root} display="flex">
        <Box>
          <img src={AppLogoIcon} className={classes.logoImage} />
        </Box>
        <Box
          flexGrow={1}
          alignItems="center"
          textAlign="center"
          className={classes.title}
        >
          {lpId && !isRegister && (
            <Typography variant="body2" className={classes.id} component="span">
              {lpId}
            </Typography>
          )}
          <Typography variant="body2" component="span">
            {newLp?.title ? newLp?.title : '管理用タイトルが入ります'}
          </Typography>
        </Box>
      </Box>
    </header>
  );
};
export default NormalEditHeaderBar;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from 'components/atoms'

/**
 * Interface
 */

export interface ReactionCountLabelProps {
  limit?: string,
  count: string,
  onlyInputCount?: boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      fontSize: '13px',
      color: theme.palette.gray[900],
      textAlign: "end",
      verticalAlign: "center",
      padding: `${theme.spacing(1)}px 0 0 0`
    },
    count: {
      fontWeight: 'bold',
      marginLeft: '8px'
    },
    limit: {
      color: theme.palette.gray[800]
    }
  }),
)

/**
 * Presenter
 */

export default function LetterCountLabel(props: ReactionCountLabelProps) {

  const { limit, count, onlyInputCount } = props
  const classes = useStyles({})

  return (
    <Typography className={classes.root} variant="caption" display="block">
      {onlyInputCount
      ? <span className={classes.limit}>入力文字数：</span>
      : <span className={classes.limit}>推奨文字数：{limit}文字程度</span>}
      <span className={classes.count}>{count}</span>
    </Typography>
  )

}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import LPCreateModeSelection from 'components/pages/LPCreateModeSelection'

/**
 * Presenter
 */

export default function LPCreateModeSelectionPageContainer() {
  return <LPCreateModeSelection />
}
/* eslint-enable */

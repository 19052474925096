import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

export const useAccountPermission = () => {
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);

  // LP承認者設定権限
  const setLpApproverPermission = useMemo(() => {
    if (userInfo?.lpAuthor || userInfo?.tenantAdmin) {
      return true;
    }

    return false;
  }, [userInfo]);

  const setMaterialApproverpermission = useMemo(() => {
    if (userInfo?.materialAuthor || userInfo?.tenantAdmin) {
      return true;
    }

    return false;
  }, [userInfo]);

  return {
    setLpApproverPermission,
    setMaterialApproverpermission,
  };
};

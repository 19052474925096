import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  PrimaryButton,
  Typography,
  InformationIconButton,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { CheckIcon, NoImage, PlusIcon, ReleaseIcon, } from 'assets/images';
import { MaterialImage } from 'core/domain/material';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
} from 'constants/code';
import clsx from 'clsx';

/**
 * Interface
 */
export interface NormalEditExampleCardProps {
  text: React.ReactNode;
  company: string;
  id: number;
  handleInformationClick: () => void;
  handleAdd: () => void;
  selected: boolean;
  useLpCount?: number;
  exampleImage?: MaterialImage;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      width: 184,
      height: 278,
      '&:hover': {
        borderColor: theme.palette.green[800],
        backgroundColor: theme.palette.green[700],
      },
    },
    deselectBox: {
      borderWidth: 1,
      borderColor: theme.palette.gray[600],
    },
    selectedBox: {
      borderWidth: 2,
      borderColor: theme.palette.green[900],
      backgroundColor: theme.palette.green[700],
    },
    box: {
      margin: 12,
    },
    imageBox: {
      position: 'relative',
    },
    img: {
      width: 160,
      height: 87,
      objectFit: 'contain',
      pointerEvents: 'none',
    },
    name: {
      fontWeight: 'bold',
      lineHeight: '28px',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    label: {
      color: theme.palette.green[900],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      marginLeft: 4,
    },
    countIcon: {
      width: 14,
      height: 13,
    },
    doc: {
      width: 11,
      height: 15,
    },
    selected: {
      '& #Icon_awesome-check-circle': {
        fill: theme.palette.green[900],
      },
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: 'auto',
    },
    selectedLabel: {
      marginLeft: theme.spacing(1),
      marginRight: 'auto',
    },
    company: {
      color: theme.palette.gray[700],
      fontSize: '10px',
      lineHeight: '17px',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    lineheight: {
      lineHeight: '16px',
    },
  }),
);

/**
 * Presenter
 */
const NormalEditExampleCard = (
  props: NormalEditExampleCardProps,
) => {
  const classes = useStyles({});
  const {
    text,
    company,
    id,
    useLpCount,
    handleInformationClick,
    handleAdd,
    selected,
    exampleImage,
  } = props;

  const button = selected ? (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <CheckIcon className={classes.selected} />
      <Typography className={clsx(classes.label, classes.selectedLabel)}>
        選択済み
      </Typography>
    </Box>
  ) : (
    <PrimaryButton click={handleAdd} icon={<PlusIcon />} text="追加する" />
  );

  const img = useMemo(() => {
    if (!exampleImage || (!exampleImage.url && !exampleImage.thumbnailUrl))
      return <img src={NoImage} className={classes.img} />;

    switch (exampleImage.materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        return <img src={exampleImage.url} className={classes.img} />;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return (
          <video
            src={exampleImage.url}
            className={classes.img}
            poster={
              exampleImage.thumbnailUrl ? exampleImage.thumbnailUrl : undefined
            }
          />
        );
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return (
          <img
            src={
              exampleImage.thumbnailUrl ? exampleImage.thumbnailUrl : NoImage
            }
            className={classes.img}
          />
        );
      default:
        return <img src={NoImage} className={classes.img} />;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleImage]);

  return (
    <Box
      className={clsx(
        classes.root,
        selected ? classes.selectedBox : classes.deselectBox,
      )}
    >
      <Box className={classes.box}>
        <Box className={classes.imageBox}>
          <InformationIconButton handleClick={handleInformationClick} />
          {img}
        </Box>
        <Typography
          variant="body2"
          className={clsx(classes.lineheight, classes.name)}
        >
          {id}
        </Typography>
        <Box height={60}>
          <Typography variant="body2" className={classes.name}>
            {text}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          width="80px"
        >
          <ReleaseIcon className={classes.countIcon} />
          <Typography
            component="span"
            variant="body2"
            className={classes.label}
          >
            {useLpCount?.toLocaleString() || 0}
          </Typography>
        </Box>
        <Typography variant="caption" className={classes.company}>
          {company}
        </Typography>
        <Box mx="auto" mt={2} width={108} height={27}>
          {button}
        </Box>
      </Box>
    </Box>
  );
};

export default NormalEditExampleCard;

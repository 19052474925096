/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { TagsRequest } from 'core/domain/tag';

const initialState: TagsRequest = {
  tagType: null,
  sortKey: null,
};

export function createInitialState(): TagsRequest {
  return initialState;
}

export const SET_PRODUCT_TAGS_REQUEST = 'product-tags-request/set' as const;
export const CLEAR_PRODUCT_TAGS_REQUEST = 'product-tags-request/clear' as const;

export function setProductTagsRequest(data: TagsRequest) {
  return {
    type: SET_PRODUCT_TAGS_REQUEST,
    payload: {
      data,
    },
  };
}

export function clearProductTagsRequest() {
  return {
    type: CLEAR_PRODUCT_TAGS_REQUEST,
  };
}

type SetProductTagsRequestAction = ReturnType<typeof setProductTagsRequest>;
type ClearProductTagsRequestAction = ReturnType<typeof clearProductTagsRequest>;
type Action = SetProductTagsRequestAction | ClearProductTagsRequestAction;

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCT_TAGS_REQUEST:
      return action.payload.data;
    case CLEAR_PRODUCT_TAGS_REQUEST:
      return initialState;
    default:
      return state;
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { InquiryForm } from 'core/domain/settings'

const initialState = null

export function createInitialState(): Array<InquiryForm> | null {
  return initialState
}

export const SET_INQUIRY_FORMS = 'inquiry-forms/set' as const
export const CLEAR_INQUIRY_FORMS = 'inquiry-forms/clear' as const

export function setInquiryForms(data: Array<InquiryForm>) {
  return {
    type: SET_INQUIRY_FORMS,
    payload: {
      data,
    },
  }
}

export function clearInquiryForms() {
  return {
    type: CLEAR_INQUIRY_FORMS,
  }
}

type SetInquiryFormsAction = ReturnType<typeof setInquiryForms>
type ClearInquiryFormsAction = ReturnType<typeof clearInquiryForms>
type Action = SetInquiryFormsAction | ClearInquiryFormsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_INQUIRY_FORMS:
      return action.payload.data
    case CLEAR_INQUIRY_FORMS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import { MyLibrary } from 'core/domain/myLibrary';
import MyLibraryPage from '../components/pages/MyLibrary';

const MyLibraryContainer = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  const myLibrary = useSelector(
    (state: Modules.AppState) => state.myLibrary,
    shallowEqual,
  );

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  useEffect(() => {
    if (!userInfo?.tenantId) return;
    dispatch(Usecases.user.sendGetMyLibrary(token, userInfo.tenantId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, token]);

  const handleSubmit = (data: MyLibrary) => {
    if (!userInfo?.tenantId || !myLibrary) return;

    const submitFunc = myLibrary.userId
      ? Usecases.user.sendPutMyLibrary
      : Usecases.user.sendPostMyLibrary;
    dispatch(submitFunc(token, userInfo.tenantId, data));

    setSuccessDialogOpen(true);
  };

  return (
    <MyLibraryPage
      libraries={myLibrary}
      submitMyLibrary={handleSubmit}
      successDialogOpen={successDialogOpen}
      setSuccessDialogOpen={setSuccessDialogOpen}
    />
  );
};

export default MyLibraryContainer;

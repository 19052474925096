import { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DownloadDocumentRow } from 'components/molecules';
import {
  DateTimePicker,
  FileUploadDialog,
  ConfirmDialog,
  Content,
  SuccessDialog,
} from 'components/organisms';
import { fileToBase64 } from 'libs/file';
import { Modules } from 'core';

import { useDispatch, useSelector } from 'react-redux';
import { UploadIcon } from 'assets/images';
import {
  Box,
  FormControl,
  RadioGroup,
  Typography,
  MultipleLinesTextField,
  CommonRadioButton,
  OneLineTextField,
  Divider,
  PrimaryButton,
  SecondaryButton,
  List,
  MandatoryLabel,
  MessageAlert,
} from 'components/atoms';
import { downloadFileFromBase64, downloadFileFromUrl } from 'libs/downloader';
import { OpenDialogType } from 'components/organisms/FileUploadDialog';
import { NoticeDetail, NoticeFiles } from 'core/domain/notices';
import { hooks } from 'libs';
import clsx from 'clsx';
import _ from 'lodash';
import { validDate } from 'libs/date';

interface NoticeEditProps {
  noticeDetail: NoticeDetail | null;
  handleSubmit: (data: NoticeDetail) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      margin: 15,
      // eslint-disable-next-line
      color: theme.palette.gray[900],
    },
    divider: {
      marginTop: 48,
    },
    alertVisible: {
      paddingTop: 49,
    },
  }),
);

const NoticeEdit = (props: NoticeEditProps) => {
  const { noticeDetail, handleSubmit } = props;

  const navigate = hooks.useNavigate();
  const classes = useStyles({});
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  // エラーを保持するためのstate
  // 日付エリア用
  const [saveStartError, setSaveStartError] = useState<string | undefined>(
    undefined,
  );
  // 日付エリア用
  const [saveEndError, setSaveEndError] = useState<string | undefined>(
    undefined,
  );
  // 時間エリア用
  const [saveStartTimeError, setSaveStartTimeError] = useState<
    string | undefined
  >(undefined);
  // 時間エリア用
  const [saveEndTimeError, setSaveEndTimeError] = useState<string | undefined>(
    undefined,
  );
  const [saveSubjectError, setSaveSubjectError] = useState<string | undefined>(
    undefined,
  );
  const [saveContentError, setSaveContentError] = useState<string | undefined>(
    undefined,
  );

  // 保存押下時にエラーを表示するためのstate
  // 日付エリア用
  const [startError, setStartError] = useState<string | undefined>(undefined);
  // 日付エリア用
  const [endError, setEndError] = useState<string | undefined>(undefined);
  // 時間エリア用
  const [startTimeError, setStartTimeError] = useState<string | undefined>(
    undefined,
  );
  // 時間エリア用
  const [endTimeError, setEndTimeError] = useState<string | undefined>(
    undefined,
  );
  const [subjectError, setSubjectError] = useState<string | undefined>(
    undefined,
  );
  const [contentError, setContentError] = useState<string | undefined>(
    undefined,
  );

  const [publishStartDate, setPublishStartDate] = useState('');
  const [publishStartTime, setPublishStartTime] = useState('');
  const [publishEndDate, setPublishEndDate] = useState('');
  const [publishEndTime, setPublishEndTime] = useState('');
  // 開始日、終了日エラーチェック用
  const [publishStartDateCheck, setPublishStartDateCheck] = useState('');
  const [publishEndDateCheck, setPublishEndDateCheck] = useState('');

  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [noticeSubject, setNoticeSubject] = useState('');
  const [noticeContent, setNoticeContent] = useState('');
  const [fileDialogType, setFileDialogType] =
    useState<OpenDialogType>('hidden');
  const [noticeType, setNoticeType] = useState('2');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const noticeFiles = useSelector(
    (state: Modules.AppState) => state.noticeFiles,
  );
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [initialNoticeDetail, setInitialNoticeDetail] =
    useState<NoticeDetail>();
  const [initialContent, setInitialContent] = useState('');
  const [initialSubject, setInitialSubject] = useState('');
  const [initialNoticeType, setInitialNoticeType] = useState('');
  const [initialPublishStartDate, setInitialPublishStartDate] = useState('');
  const [initialPublishStartTime, setInitialPublishStartTime] = useState('');
  const [initialPublishEndDate, setInitialPublishEndDate] = useState('');
  const [initialPublishEndTime, setInitialPublishEndTime] = useState('');
  const [initialNoticeFiles, setInitialNoticeFiles] = useState<
    NoticeFiles[] | null
  >([]);
  const [nextPagePath, setNextPagePath] = useState('');
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState<NoticeDetail>();
  const [editNoticeFiles, setEditNoticeFiles] = useState<NoticeFiles[] | null>(
    [],
  );

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const downloadDoc = async (index: number) => {
    if (editNoticeFiles && editNoticeFiles.length) {
      const docs = editNoticeFiles[index];
      if (!docs) return;

      if (docs.data) {
        await downloadFileFromBase64(docs.data, docs.noticeFileName || '');
      } else if (docs.url) {
        await downloadFileFromUrl(docs.url, docs.noticeFileName || '');
      }
    }
  };

  const removeDoc = (index: number) => {
    if (editNoticeFiles) {
      const docs = [...editNoticeFiles];
      docs.splice(index, 1);
      setEditNoticeFiles([...docs]);
    }
  };

  useEffect(() => {
    if (!noticeDetail) {
      // 新規作成前の編集データが残るため初期化
      setNoticeContent('');
      setNoticeSubject('');
      // setNoticeType初期値は2
      setNoticeType('2');
      setInitialNoticeType('2');
      // dispatch(setNoticeFiles([]));
      setPublishStartDate('');
      setPublishStartDateCheck('');
      setPublishStartTime('');
      setPublishEndDate('');
      setPublishEndDateCheck('');
      setPublishEndTime('');

      // dispatch(
      //   setNoticeDetail({
      //     noticeId: null,
      //     subject: '',
      //     content: '',
      //     noticeType: null,
      //     unread: true,
      //     noticeFiles: [],
      //   }),
      // );
      setInitialSubject('');
      setInitialContent('');
      setInitialNoticeType('2');
      setInitialPublishStartDate('');
      setInitialPublishStartTime('');
      setInitialPublishEndDate('');
      setInitialPublishEndTime('');
      setFirstTime(false);

      return;
    }

    if (noticeDetail.content) setNoticeContent(noticeDetail.content);
    if (noticeDetail.subject) setNoticeSubject(noticeDetail.subject);
    if (noticeDetail.noticeType) setNoticeType(String(noticeDetail.noticeType));
    if (noticeDetail.publishStartDate) {
      // 登録・表示用値
      setPublishStartDate(noticeDetail.publishStartDate);
      // エラーチェック用値
      setPublishStartDateCheck(noticeDetail.publishStartDate);
    }
    if (noticeDetail.publishStartTime)
      setPublishStartTime(
        `${noticeDetail.publishStartTime.substring(
          0,
          2,
        )}:${noticeDetail.publishStartTime.substring(2)}`,
      );
    if (noticeDetail.publishEndDate) {
      // 登録・表示用値
      setPublishEndDate(noticeDetail.publishEndDate);
      // エラーチェック用値
      setPublishEndDateCheck(noticeDetail.publishEndDate);
    }
    if (noticeDetail.publishEndTime)
      setPublishEndTime(
        `${noticeDetail.publishEndTime.substring(
          0,
          2,
        )}:${noticeDetail.publishEndTime.substring(2)}`,
      );

    if (!firstTime) {
      setFirstTime(true);
      const cloneDeepItem = _.cloneDeep(noticeDetail);
      if (cloneDeepItem.subject)
        setInitialSubject(JSON.parse(JSON.stringify(cloneDeepItem.subject)));
      if (cloneDeepItem.content)
        setInitialContent(JSON.parse(JSON.stringify(cloneDeepItem.content)));
      if (cloneDeepItem.noticeType)
        setInitialNoticeType(
          JSON.parse(JSON.stringify(cloneDeepItem.noticeType.toString())),
        );
      if (cloneDeepItem.publishStartDate)
        setInitialPublishStartDate(
          JSON.parse(JSON.stringify(cloneDeepItem.publishStartDate)),
        );
      if (cloneDeepItem.publishStartTime)
        setInitialPublishStartTime(
          JSON.parse(JSON.stringify(cloneDeepItem.publishStartTime)),
        );
      if (cloneDeepItem.publishEndDate)
        setInitialPublishEndDate(
          JSON.parse(JSON.stringify(cloneDeepItem.publishEndDate)),
        );
      if (cloneDeepItem.publishEndTime)
        setInitialPublishEndTime(
          JSON.parse(JSON.stringify(cloneDeepItem.publishEndTime)),
        );
      setInitialNoticeDetail(cloneDeepItem);
      setInitialNoticeFiles(_.cloneDeep(noticeFiles));
      setEditNoticeFiles(_.cloneDeep(noticeFiles));
    }
  }, [noticeDetail, dispatch, noticeFiles, initialNoticeFiles, firstTime]);

  useEffect(() => {
    if (!noticeFiles) {
      // dispatch(setNoticeFiles([]));
    } else {
      setInitialNoticeFiles(_.cloneDeep(noticeFiles));
      setEditNoticeFiles(_.cloneDeep(noticeFiles));
    }

    // dispatch(setNoticeFiles(noticeDetail.noticeFiles));
  }, [dispatch, noticeFiles]);

  const handleChangeTitle = (text: string) => {
    setNoticeSubject(text);
    setIsRegister(false);
  };
  const handleChangeContent = (text: string) => {
    setNoticeContent(text);
    setIsRegister(false);
  };
  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoticeType(event.target.value);
    setIsRegister(false);
  };
  const handleChangePublishStartDate = (date: string) => {
    // エラーチェック用
    setPublishStartDateCheck(date.replaceAll('/', ''));
    setIsRegister(false);

    let isError = false;
    isError = date.replaceAll('/', '')
      ? !validDate(date.replaceAll('/', ''))
      : false;
    if (isError) return;
    // 表示、登録用
    setPublishStartDate(date.replaceAll('/', ''));
  };
  const handleChangePublishStartTime = (date: string) => {
    setPublishStartTime(date);
    setIsRegister(false);
  };
  const handleChangePublishEndDate = (date: string) => {
    // エラーチェック用
    setPublishEndDateCheck(date.replaceAll('/', ''));
    setIsRegister(false);

    let isError = false;
    isError = date.replaceAll('/', '')
      ? !validDate(date.replaceAll('/', ''))
      : false;
    if (isError) return;
    // 表示、登録用
    setPublishEndDate(date.replaceAll('/', ''));
  };
  const handleChangePublishEndTime = (date: string) => {
    setPublishEndTime(date);
    setIsRegister(false);
  };

  const onFileUploadDialog = (files: File[]) => {
    setIsRegister(false);
    if (!files.length || !files[0]) return;

    const docs = editNoticeFiles || [];

    files.map((file) => {
      //  void
      void fileToBase64(file)
        .then((res) => {
          const fileData = {
            // noticeFileId: null,
            noticeFileName: file.name,
            // url: 'https://dev.salad-bar.net/00000000000000000001/577?1623993669801',
            data: res as string,
          };
          docs.push(fileData);
        })
        .then(() => setEditNoticeFiles([...docs]));
    });

    setFileDialogOpen(false);

    setFileDialogType('hidden');
  };

  const changeYearToTimestamp = (dt: string, time: string) => {
    const year = Number(dt.substr(0, 4));
    const month = Number(dt.substr(4, 2)) - 1;
    const date = Number(dt.substr(6, 2));
    const h = Number(time.split(':').join('').substr(0, 2));
    const m = Number(time.split(':').join('').substr(2, 4));

    return new Date(year, month, date, h, m).getTime();
  };

  const handleSave = () => {
    setIsSubmitting((prev) => !prev);
    // エラー表示を初期化
    setSubjectError(undefined);
    setContentError(undefined);
    setStartError(undefined);
    setEndError(undefined);
    setStartTimeError(undefined);
    setEndTimeError(undefined);

    // 登録・更新するデータ
    const data = {
      noticeId: noticeDetail?.noticeId || null,
      publishStartDate,
      publishStartTime: publishStartTime.split(':').join(''),
      publishEndDate,
      publishEndTime: publishEndTime.split(':').join(''),
      subject: noticeSubject,
      content: noticeContent,
      noticeFiles: editNoticeFiles || [],
      // unread: true,
      noticeType: Number(noticeType),
    };

    // 保持しているエラーがある場合はエラーを表示させる
    setSubjectError(saveSubjectError);
    setContentError(saveContentError);
    setStartError(saveStartError);
    setEndError(saveEndError);
    setStartTimeError(saveStartTimeError);
    setEndTimeError(saveEndTimeError);

    if (
      // エラーが無ければ更新・登録
      saveSubjectError === undefined &&
      saveContentError === undefined &&
      saveStartError === undefined &&
      saveEndError === undefined &&
      saveStartTimeError === undefined &&
      saveEndTimeError === undefined
    ) {
      // handleSubmit(data);
      // navigate.navigate('/adm/notices-manage');
      setSave(true);
      setSaveData(data);
    } else {
      setAlertOpen(true);
    }
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  useEffect(() => {
    if (save && saveData) {
      handleSubmit(saveData);

      setSave(false);
      setSuccessDialogOpen(true);
    }
  }, [dispatch, editNoticeFiles, handleSubmit, navigate, save, saveData]);

  // エラーがある場合はエラー情報を保持する
  useEffect(() => {
    // 保持エラーを初期化
    setSaveSubjectError(undefined);
    setSaveContentError(undefined);
    setSaveStartError(undefined);
    setSaveEndError(undefined);
    setSaveStartTimeError(undefined);
    setSaveEndTimeError(undefined);

    if (!noticeSubject) setSaveSubjectError('入力してください');
    if (!noticeContent) setSaveContentError('入力してください');
    if (noticeSubject?.length > 100)
      setSaveSubjectError('100文字以内で入力してください');
    if (noticeContent?.length > 1000)
      setSaveContentError('1000文字以内で入力してください');

    if (
      publishStartDateCheck ||
      publishStartTime ||
      publishEndDateCheck ||
      publishEndTime
    ) {
      // 開始日時・終了日時を設定する場合のエラーチェック

      // 開始日付・開始時間
      const startTimeStamp = changeYearToTimestamp(
        publishStartDateCheck,
        publishStartTime,
      );
      // 終了日・終了時間
      const endTimeStamp = changeYearToTimestamp(
        publishEndDateCheck,
        publishEndTime,
      );

      // 開始日時チェック日付と時間どちらも設定しないとエラー
      if (!publishStartDateCheck && publishStartTime)
        setSaveStartError('日付が未入力です');
      if (publishStartDateCheck && !publishStartTime)
        setSaveStartTimeError('時間が未入力です');

      // 終了日時チェック日付と時間どちらも設定しないとエラー
      if (!publishEndDateCheck && publishEndTime)
        setSaveEndError('日付が未入力です');
      if (publishEndDateCheck && !publishEndTime)
        setSaveEndTimeError('時間が未入力です');

      // 日付フォーマットチェック開始日
      if (publishStartDateCheck && !validDate(publishStartDateCheck))
        setSaveStartError('日付の形式で入力してください。');

      // 日付フォーマットチェック終了日
      if (publishEndDateCheck && !validDate(publishEndDateCheck))
        setSaveEndError('日付の形式で入力してください。');

      // 開始日時,終了日時どちらも入力されたとき
      if (
        publishEndDateCheck &&
        publishEndTime &&
        validDate(publishEndDateCheck) &&
        validDate(publishStartDateCheck) &&
        publishStartDateCheck &&
        publishStartTime &&
        startTimeStamp > endTimeStamp
      ) {
        // 日付・時間どちらも赤くする
        setSaveStartError('開始日時は終了日時より前に設定してください');
        setSaveStartTimeError('開始日時は終了日時より前に設定してください');
      }

      // 終了日時が現在より過去
      if (
        publishEndDateCheck &&
        publishEndTime &&
        validDate(publishEndDateCheck) &&
        endTimeStamp < Date.now()
      ) {
        // 日付・時間どちらも赤くする
        setSaveEndError('終了日時は現在時刻以降に設定してください');
        setSaveEndTimeError('終了日時は現在時刻以降に設定してください');
      }
    }
    // eslint-disable-next-line
  }, [
    noticeSubject,
    noticeContent,
    publishStartDateCheck,
    publishStartTime,
    publishEndDateCheck,
    publishEndTime,
  ]);

  useEffect(() => {
    setAlertOpen(false);
  }, []);

  useEffect(() => {
    const res = navigate.block((pathname: string): false | undefined => {
      setNextPagePath(pathname);

      if (
        (!noticeSubject &&
          !noticeContent &&
          !noticeType &&
          !publishStartDate &&
          !publishStartTime &&
          !publishEndDate &&
          !publishEndTime &&
          !initialSubject &&
          !initialContent &&
          initialNoticeType === '2' &&
          !initialPublishStartDate &&
          !initialPublishStartTime &&
          !initialPublishEndDate &&
          !initialPublishEndTime &&
          !editNoticeFiles &&
          !initialNoticeFiles) ||
        isRegister ||
        goAnotherPage
      ) {
        return undefined;
      }

      if (
        noticeSubject !== initialSubject ||
        noticeContent !== initialContent ||
        noticeType !== initialNoticeType ||
        publishStartDate !== initialPublishStartDate ||
        publishStartTime.split(':').join('') !== initialPublishStartTime ||
        publishEndDate !== initialPublishEndDate ||
        publishEndTime.split(':').join('') !== initialPublishEndTime ||
        JSON.stringify(editNoticeFiles) !== JSON.stringify(initialNoticeFiles)
      ) {
        setIsConfirmDialogOpen(true);

        return false;
      }

      return undefined;
    });

    return () => res();
  }, [
    goAnotherPage,
    navigate,
    isRegister,
    noticeDetail,
    noticeSubject,
    noticeContent,
    publishStartDate,
    publishStartTime,
    publishEndDate,
    publishEndTime,
    noticeFiles,
    initialNoticeFiles,
    noticeType,
    initialSubject,
    initialContent,
    initialNoticeType,
    initialPublishStartDate,
    initialPublishStartTime,
    initialPublishEndDate,
    initialPublishEndTime,
    editNoticeFiles,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  // 添付ファイルDL制御
  const [noticeFileDisabled, setNoticeFileDisabled] = useState<number | null>(
    null,
  );

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]:
          startError || endError || subjectError || contentError,
      })}
    >
      {alertOpen && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}

      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          setGoAnotherPage(true);
          navigate.navigate('/adm/notices-manage');
          setSuccessDialogOpen(false);
        }}
      />
      <Content title="お知らせ登録・編集">
        <>
          <FileUploadDialog
            handleSubmit={onFileUploadDialog}
            onClose={() => setFileDialogOpen(false)}
            open={fileDialogOpen}
            isProduct={fileDialogOpen}
            fileType={fileDialogType}
          />
          <ConfirmDialog
            open={isConfirmDialogOpen}
            title=""
            text="保存されていない入力は破棄されますが、"
            buttonText="終了する"
            handleCancel={() => setIsConfirmDialogOpen(!isConfirmDialogOpen)}
            handleSubmit={() => {
              setGoAnotherPage(true);
              navigate.navigate('/adm/notices-manage');
              setIsConfirmDialogOpen(false);
            }}
          />
          <Typography variant="h4" className={classes.title}>
            掲載日時
          </Typography>
          <Box>
            <DateTimePicker
              subtitle="掲載開始日時"
              width="332px"
              dateError={startError}
              timeError={startTimeError}
              date={publishStartDate}
              time={publishStartTime}
              handleChangeDate={handleChangePublishStartDate}
              handleChangeTime={(time) => handleChangePublishStartTime(time)}
            />
          </Box>
          <Box>
            <DateTimePicker
              subtitle="掲載終了日時"
              width="332px"
              dateError={endError}
              timeError={endTimeError}
              date={publishEndDate}
              time={publishEndTime}
              handleChangeDate={(date) => handleChangePublishEndDate(date)}
              handleChangeTime={(time) => handleChangePublishEndTime(time)}
            />
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="h4" className={classes.title}>
              件名
            </Typography>
            <MandatoryLabel />
          </Box>
          <OneLineTextField
            error={subjectError}
            label="件名を入力"
            handleChangeText={(text) => handleChangeTitle(text)}
            value={noticeSubject}
          />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="h4" className={classes.title}>
              本文
            </Typography>
            <MandatoryLabel />
          </Box>
          <MultipleLinesTextField
            error={contentError}
            rows={10}
            value={noticeContent}
            handleChangeText={(text) => handleChangeContent(text)}
          />
          <Typography variant="h4" className={classes.title}>
            添付ファイル
          </Typography>
          <Box mb={1} width={300} height={36}>
            <PrimaryButton
              click={() => setFileDialogOpen(true)}
              text="添付ファイルをアップロード"
              icon={<UploadIcon />}
            />
          </Box>

          <List>
            {editNoticeFiles &&
              editNoticeFiles.map((doc, index) => (
                <DownloadDocumentRow
                  key={index}
                  clickFile={() => {
                    setNoticeFileDisabled(index);
                    void downloadDoc(index);
                    setTimeout(() => setNoticeFileDisabled(null), 1000);
                  }}
                  clickClose={removeDoc}
                  name={doc.noticeFileName || ''}
                  index={index}
                  disabled={noticeFileDisabled === index}
                />
              ))}
          </List>

          <Typography variant="h4" className={classes.title}>
            カテゴリ
          </Typography>

          <FormControl>
            <RadioGroup
              row
              aria-label="position"
              value={noticeType}
              onChange={handleChangeCategory}
            >
              <CommonRadioButton value="1" label="重要" />
              <CommonRadioButton value="2" label="通常" />
            </RadioGroup>
          </FormControl>

          <Divider className={classes.divider} />
          <Box display="flex" justifyContent="center">
            <Box m={1} width={332} height={48}>
              <SecondaryButton
                click={() => {
                  if (noticeDetail && !initialNoticeDetail) {
                    setIsConfirmDialogOpen(true);
                  }
                  navigate.navigateReplace('/adm/notices-manage');
                }}
                text="キャンセル"
              />
            </Box>
            <Box m={1} width={332} height={48}>
              <PrimaryButton
                disabled={isSubmitting}
                text="保存"
                click={() => {
                  setIsRegister(true);
                  handleSave();
                }}
              />
            </Box>
          </Box>
        </>
      </Content>
    </Box>
  );
};

export default NoticeEdit;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { TokuisakiFixedPhraseTexts } from 'core/domain/settings'

const initialState = null

export function createInitialState(): TokuisakiFixedPhraseTexts | null {
  return initialState
}

export const SET_TOKUISAKI_FIXED_PHRASE_TEXTS = 'tokuisaki-fixed-phrase-texts/set' as const
export const CLEAR_TOKUISAKI_FIXED_PHRASE_TEXTS = 'tokuisaki-fixed-phrase-texts/clear' as const

export function setTokuisakiFixedPhraseTexts(data: TokuisakiFixedPhraseTexts) {
  return {
    type: SET_TOKUISAKI_FIXED_PHRASE_TEXTS,
    payload: {
      data,
    },
  }
}

export function clearTokuisakiFixedPhraseTexts() {
  return {
    type: CLEAR_TOKUISAKI_FIXED_PHRASE_TEXTS,
  }
}

type SetTokuisakiFixedPhraseTextsAction = ReturnType<typeof setTokuisakiFixedPhraseTexts>
type ClearTokuisakiFixedPhraseTextsAction = ReturnType<typeof clearTokuisakiFixedPhraseTexts>
type Action = SetTokuisakiFixedPhraseTextsAction | ClearTokuisakiFixedPhraseTextsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TOKUISAKI_FIXED_PHRASE_TEXTS:
      return action.payload.data
    case CLEAR_TOKUISAKI_FIXED_PHRASE_TEXTS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

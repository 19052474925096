/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Box, Typography, CalendarText } from 'components/atoms'

export interface TargetPeriodLabelProps {
  start?: string
  end?: string
  startError?: string
  endError?: string
  handleChangeStartDate?: (date: string) => void
  handleChangeEndDate?: (date: string) => void
}

export default function TargetPeriodLabel(props: TargetPeriodLabelProps) {
  const { start, end, startError, endError, handleChangeStartDate, handleChangeEndDate } = props

  return (
    <Box display="flex">
      <Box marginTop={0.9}>
        <Typography variant="body1" style={{width:80}}>集計期間:</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={155}>
          <CalendarText error={startError} value={start} handleChangeDate={handleChangeStartDate} />
        </Box>
        <Typography variant="body1" style={{marginRight:2,marginLeft:2}}>～</Typography>
        <Box width={155}>
          <CalendarText error={endError} value={end} handleChangeDate={handleChangeEndDate} />
        </Box>
      </Box>
    </Box>
  )
}
/* eslint-enable */

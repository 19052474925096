import { Box, Typography } from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_300,
} from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface UserIdFieldProps {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
    },
    main: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  }),
);

const UserIdField = (props: UserIdFieldProps) => {
  const { userId } = props;
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.title} variant="body1">
        ユーザーID
      </Typography>
      <Typography className={classes.main} variant="body1">
        {userId}
      </Typography>
    </Box>
  );
};

export default UserIdField;

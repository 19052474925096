/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */

export interface ReactionCountCardContentProps {
  icon?: React.ReactNode
  title: string
  count: string
}

/**
 * Styles 
 */

 const useStyles = makeStyles((theme:Theme) =>
 createStyles({
   root: {
     borderColor: theme.palette.gray[500],
     borderRadius: 8,
     borderStyle: 'solid',
     borderWidth: 3,
     marginRight: 8,
     padding: 16
   },
   count: {
     fontWeight: 'bold',
     marginLeft: '8px'
   },
   parcent: {
     backgroundColor: theme.palette.green[700],
     marginLeft: 8,
     borderRadius: 5,
     padding: 5
   },
   caption: {
     fontWeight: 'bold',
     fontSize: 12
   },
   box: {
     marginTop: 8,
     marginLeft: '100px'
   },
   typo: {
     fontSize: 28,
     fontWeight: 'bold',
     color: theme.palette.gray[900]
   },
   title: {
     color: theme.palette.gray[800],
     fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75
   }
 }),
)

/**
 * Presenter
 */

export default function ReactionCountCardContent(props: ReactionCountCardContentProps) {
  
  const classes = useStyles({})
  const { icon, title, count } = props
  
  const formatComma = (x: string) => x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

return (
  <Box className={classes.root}>
    <Box display="flex">
    {icon}
    <Box pl={2} m={1}>
    <Typography className={classes.title}>{title}</Typography>
    </Box>
    </Box>
    <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.box}>
    <Typography className={classes.typo} variant="body1">{formatComma(`${count}`)}</Typography>
    </Box>
  </Box>
  )
}
/* eslint-enable */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

export interface SidebarPermission {
  dashboardPermission: boolean;
  newLpPermission: boolean;
  myLpsPermission: boolean;
  subordinatesLpsPermission: boolean;
  inquiriesPermission: boolean;
  noticesListPermission: boolean;
  customersPermission: boolean;
  lpsPermission: boolean;
  productsPermission: boolean;
  examplesPermission: boolean;
  myLibrariesPermission: boolean;
  adminPermission: boolean;
  trashesPermission: boolean;
  imageCutPermission: boolean;
}

export const useSidebarPermission = (): SidebarPermission => {
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);

  const newLpPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpAuthor;
  }, [userInfo]);

  const myLpsPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpAuthor;
  }, [userInfo]);

  const subordinatesLpsPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpApprover;
  }, [userInfo]);

  const inquiriesPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpApprover || userInfo.lpAuthor;
  }, [userInfo]);

  const customersPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpApprover || userInfo.lpAuthor;
  }, [userInfo]);

  const lpsPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpApprover || userInfo.lpAuthor;
  }, [userInfo]);

  const productsPermission = useMemo(() => {
    if (!userInfo) return false;

    return (
      userInfo.tenantAdmin ||
      userInfo.materialApprover ||
      userInfo.materialAuthor ||
      userInfo.lpApprover ||
      userInfo.lpAuthor
    );
  }, [userInfo]);

  const examplesPermission = useMemo(() => {
    if (!userInfo) return false;

    return (
      userInfo.tenantAdmin ||
      userInfo.materialApprover ||
      userInfo.materialAuthor ||
      userInfo.lpApprover ||
      userInfo.lpAuthor
    );
  }, [userInfo]);

  const myLibrariesPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin || userInfo.lpAuthor;
  }, [userInfo]);

  // 画像切抜
  const imageCutPermission = useMemo(() => {
    if (!userInfo) return false;
    // 画像切抜表示非表示
    if(!userInfo.imageServiceUse) return false;

    return userInfo.tenantAdmin || userInfo.lpAuthor;
  }, [userInfo]);

  const trashesPermission = useMemo(() => {
    if (!userInfo) return false;

    return (userInfo.tenantAdmin ||
      userInfo.lpAuthor ||
      userInfo.materialAuthor
    );
  }, [userInfo]);

  const noticesListPermission = useMemo(() => {
    if (!userInfo) return false;

    return (
      userInfo.tenantAdmin ||
      userInfo.materialApprover ||
      userInfo.materialAuthor ||
      userInfo.lpApprover ||
      userInfo.lpAuthor
    );
  }, [userInfo]);

  const adminPermission = useMemo(() => {
    if (!userInfo) return false;

    return userInfo.tenantAdmin;
  }, [userInfo]);

  return {
    dashboardPermission: true,
    newLpPermission,
    myLpsPermission,
    inquiriesPermission,
    noticesListPermission,
    customersPermission,
    lpsPermission,
    productsPermission,
    examplesPermission,
    subordinatesLpsPermission,
    myLibrariesPermission,
    trashesPermission,
    adminPermission,
    imageCutPermission,
  };
};

import { NoticeFiles } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticeFiles[] | null => initialState;

export const SET_NOTICE_FILES = 'noticeFiles/set' as const;
export const CLEAR_NOTICE_FILES = 'noticeFiles/clear' as const;

export const setNoticeFiles = (data: NoticeFiles[]) => ({
  type: SET_NOTICE_FILES,
  payload: {
    data,
  },
});

export const clearNoticeFiles = () => ({
  type: CLEAR_NOTICE_FILES,
});

type SetNoticeFilesAction = ReturnType<typeof setNoticeFiles>;
type ClearNoticeFilesAction = ReturnType<typeof clearNoticeFiles>;
type Action = SetNoticeFilesAction | ClearNoticeFilesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NOTICE_FILES:
      return action.payload.data;
    case CLEAR_NOTICE_FILES:
      return initialState;
    default:
      return state;
  }
};

import { useEffect } from 'react';
import InquiryRegister from 'components/pages/InquiryRegister';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { useParams } from 'react-router-dom';
import { hooks } from 'libs';
import {
  clearInquiryInfoRequest,
  setInquiryInfoRequest,
} from 'core/modules/InquiryInfoRequest';
import { clearLpDetail } from 'core/modules/lpDetail';

import { clearInquiryDetail } from 'core/modules/inquiryDetail';
import { clearInquiriesForm } from 'core/modules/inquiriesForm';
import { CODE_MODE_REFERENCE } from 'constants/code';
import _ from 'lodash';

const InquiryRegisterContainer = () => {
  const dispatch = useDispatch();
  const { inquiryId } = useParams<{ inquiryId: string }>();
  const token = hooks.useAuth().accessToken;
  const inquiryInfoRequest = useSelector(
    (state: Modules.AppState) => state.inquiryInfoRequest,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const inquiryDetail = useSelector(
    (state: Modules.AppState) => state.inquiryDetail,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );
  const inquiriesForm = useSelector(
    (state: Modules.AppState) => state.inquiriesForm,
    shallowEqual,
  );

  useEffect(() => {
    // 新規登録後に再度登録するとき
    // データが残っていて未入力で登録できることがあるためclearInquiryDetailの値をクリア
    dispatch(clearInquiryDetail());
    dispatch(clearInquiryInfoRequest());

    return undefined;
    // eslint-disable-next-line
  }, []);

  // ページ離脱時に初期化
  useEffect(
    () => () => {
      dispatch(clearInquiryDetail());
      dispatch(clearLpDetail());
      dispatch(clearInquiriesForm());
    },
    // eslint-disable-next-line
    [],
  );

  const handleInquiryRegister = () => {
    if (!userInfo || !inquiryInfoRequest) return;

    // inquiryFormsと同じ並び順にする
    const sortInquiry = _.cloneDeep(inquiryInfoRequest);
    sortInquiry.inquiryFormAnswers = [];
    inquiriesForm.inquiryForms.map((form) => {
      inquiryInfoRequest?.inquiryFormAnswers.map((ans) => {
        if (form.inquiryItemId === ans.inquiryItemId) {
          sortInquiry.inquiryFormAnswers.push(ans);
        }
      });
    });

    if (inquiryId) {
      dispatch(
        Usecases.inquiries.sendPutInquiryRegister(
          token,
          userInfo.tenantId,
          inquiryId,
          sortInquiry,
        ),
      );

      return;
    }

    dispatch(
      Usecases.inquiries.sendPostInquiryRegister(token, userInfo.tenantId, {
        ...sortInquiry,
        inquiryId: null,
        lpId: lpDetail?.lpId || 1,
      }),
    );
  };

  useEffect(() => {
    if (userInfo && inquiryId) {
      dispatch(
        Usecases.inquiries.sendGetInquiryDetail(
          token,
          userInfo.tenantId,
          +inquiryId,
        ),
      );
    }

    return () => {
      dispatch(clearInquiryDetail());
    };
  }, [userInfo, inquiryId, token, dispatch, lpDetail]);

  useEffect(() => {
    if (inquiryDetail && inquiryId) {
      dispatch(
        setInquiryInfoRequest({
          inquiryId: +inquiryId,
          lpId: lpDetail?.lpId || 1,
          inquiryDate: inquiryDetail.inquiryDate,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          inquiryTime: inquiryDetail.inquiryTime,
          inquiryType: inquiryDetail.inquiryType,
          inquiryFormAnswers: inquiryDetail.inquiryFormAnswers ?? [],
        }),
      );
    }

    return () => {
      dispatch(clearInquiryInfoRequest());
    };
  }, [inquiryDetail, dispatch, inquiryId, lpDetail]);

  // お問い合わせ項目取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.settings.sendPostInquiriesForm(
        token,
        userInfo.tenantId,
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, token, userInfo]);

  return (
    <InquiryRegister
      error={error}
      handleInquiryRegister={handleInquiryRegister}
      inquiryDetail={inquiryDetail}
      inquiryInfoRequest={inquiryInfoRequest}
      lpDetail={lpDetail}
      lpName={inquiryDetail?.lpName || lpDetail?.title}
      inquiriesForm={inquiriesForm}
    />
  );
};

export default InquiryRegisterContainer;

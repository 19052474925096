/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import {
  PRODUCT_STATUS_AWAITING_APPROVAL,
  PRODUCT_STATUS_APPROVAL,
  PRODUCT_STATUS_DECLINE,
  PRODUCT_STATUS_DELETED,
  PRODUCT_STATUS_DRAFT,
  PRODUCT_STATUS_IN_TRASH,
  PRODUCT_STATUS_RELEASED,
  PRODUCT_STATUS_SUSPENSION,
  PRODUCT_STATUS_EXPIRE,
  PRODUCT_STATUS_CLOSED,
} from 'constants/text';
import {
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_STATUS_IN_TRASH,
  CODE_MATERIAL_STATUS_DELETED,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_END,
} from 'constants/code';

/**
 * Interface
 */

export interface ApplyStatusLabelProps {
  status: number;
  approvalUsers?: Array<string>;
  step?: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    gray: {
      borderColor: theme.palette.gray[800],
      color: theme.palette.gray[800],
    },
    orange: {
      backgroundColor: theme.palette.orange[900],
      color: 'white',
    },
    orangeOutline: {
      borderColor: theme.palette.orange[900],
      color: theme.palette.orange[900],
    },
    blue: {
      backgroundColor: theme.palette.blue[900],
      color: 'white',
    },
    blueOutline: {
      borderColor: theme.palette.blue[900],
      color: theme.palette.blue[900],
    },
    redOutline: {
      borderColor: theme.palette.red[900],
      color: theme.palette.red[900],
    },
    green: {
      backgroundColor: theme.palette.green[900],
      color: 'white',
    },
    greenOutline: {
      borderColor: theme.palette.green[900],
      color: theme.palette.green[900],
    },
  }),
);

interface StatusType {
  status: number;
  label: string;
}

const STATUS_TYPES: Array<StatusType> = [
  {
    status: CODE_MATERIAL_STATUS_DRAFT,
    label: PRODUCT_STATUS_DRAFT,
  },
  {
    status: CODE_MATERIAL_STATUS_DECLINE,
    label: PRODUCT_STATUS_DECLINE,
  },
  {
    status: CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
    label: PRODUCT_STATUS_AWAITING_APPROVAL,
  },
  {
    status: CODE_MATERIAL_STATUS_APPROVAL,
    label: PRODUCT_STATUS_APPROVAL,
  },
  {
    status: CODE_MATERIAL_STATUS_RELEASED,
    label: PRODUCT_STATUS_RELEASED,
  },
  {
    status: CODE_MATERIAL_STATUS_SUSPENSION,
    label: PRODUCT_STATUS_SUSPENSION,
  },
  {
    status: CODE_MATERIAL_STATUS_EXPIRE,
    label: PRODUCT_STATUS_EXPIRE,
  },
  {
    status: CODE_MATERIAL_STATUS_END,
    label: PRODUCT_STATUS_CLOSED,
  },
  {
    status: CODE_MATERIAL_STATUS_IN_TRASH,
    label: PRODUCT_STATUS_IN_TRASH,
  },
  {
    status: CODE_MATERIAL_STATUS_DELETED,
    label: PRODUCT_STATUS_DELETED,
  },
];

/**
 * Presenter
 */

export default function ApplyStatusLabel(props: ApplyStatusLabelProps) {
  const classes = useStyles({});
  const { status, approvalUsers, step } = props;

  const decideClass = useMemo(() => {
    let tmpClass = classes.orange;
    switch (status) {
      case CODE_MATERIAL_STATUS_DRAFT: {
        tmpClass = classes.greenOutline;
        break;
      }
      case CODE_MATERIAL_STATUS_DECLINE: {
        tmpClass = classes.green;
        break;
      }
      case CODE_MATERIAL_STATUS_AWAITING_APPROVAL: {
        tmpClass = classes.blueOutline;
        break;
      }
      case CODE_MATERIAL_STATUS_APPROVAL: {
        tmpClass = classes.blue;
        break;
      }
      case CODE_MATERIAL_STATUS_RELEASED: {
        tmpClass = classes.orange;
        break;
      }
      case CODE_MATERIAL_STATUS_SUSPENSION: {
        tmpClass = classes.redOutline;
        break;
      }
      case CODE_MATERIAL_STATUS_EXPIRE: {
        tmpClass = classes.gray;
        break;
      }
      case CODE_MATERIAL_STATUS_END: {
        tmpClass = classes.orangeOutline;
        break;
      }
      case CODE_MATERIAL_STATUS_IN_TRASH: {
        tmpClass = classes.gray;
        break;
      }
      case CODE_MATERIAL_STATUS_DELETED: {
        tmpClass = classes.gray;
        break;
      }
    }

    return tmpClass;
  }, [status]);

  const statusLabel = useMemo(() => {
    const targetStatus = STATUS_TYPES.find((type) => type.status === status);
    if (!targetStatus) return '';

    return targetStatus.label;
  }, [status]);

  const users = useMemo(() => {
    if (!approvalUsers || (!step && step !== 0)) return null;

    // 該当承認フェーズ以外のユーザは、文字色を変更する
    return (
      <Typography variant="caption">
        <span>承認ルート：</span>
        {approvalUsers.map((user, index) => {
          const userLabel = (
            <span style={index + 1 > step ? { color: '#B0B0AB' } : {}}>
              {user}
            </span>
          );
          const arrow =
            index + 1 !== approvalUsers.length ? (
              <span style={index + 2 > step ? { color: '#B0B0AB' } : {}}>
                {' > '}
              </span>
            ) : null;

          return (
            <span key={index}>
              {userLabel}
              {arrow}
            </span>
          );
        })}
      </Typography>
    );
  }, [approvalUsers, step]);

  const label = (
    <Typography
      className={`${classes.root} ${decideClass}`}
      variant="caption"
      display="block"
    >
      {status === CODE_MATERIAL_STATUS_AWAITING_APPROVAL && step !== undefined
        ? `${statusLabel}${step}/${approvalUsers?.length}`
        : statusLabel}
    </Typography>
  );
  const withToolTip = users && (
    <Tooltip title={users} arrow placement="top">
      {label}
    </Tooltip>
  );

  return (
    <>
      {status === CODE_MATERIAL_STATUS_AWAITING_APPROVAL && users
        ? withToolTip
        : label}
    </>
  );
} /* eslint-enable */

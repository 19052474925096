/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { createMuiTheme } from '@material-ui/core/styles'

import paletteOption from 'styles/palette'
import typographyOption from 'styles/typography'
import breakpointsOption from 'styles/breakpoints'

export const saladbar = createMuiTheme({
  breakpoints: Object.assign(breakpointsOption),
  typography: Object.assign(typographyOption),
  palette: Object.assign(paletteOption),
})
/* eslint-enable */

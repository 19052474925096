import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Checkbox,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  Typography,
  Tooltip,
} from 'components/atoms';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { CustomersQueryParameter, Customers } from 'core/domain/customer';
import { setDeleteCustomers } from 'core/modules/deleteCustomers';
import { formatDateTimeISO8601 } from 'libs/date';
import { Modules } from 'core';
import clsx from 'clsx';
/**
 * Interface
 */

export interface CustomersTableProps {
  customers: Customers | null;
  clickCustomer?: (id: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  customersQueryParameter: CustomersQueryParameter | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },

    header: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      backgroundColor: theme.palette.gray[400],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },

    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '&, .MuiCheckbox-root': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        color: theme.palette.gray[700],
      },
      '& .Mui-checked': {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        color: `${theme.palette.green[100]} !important`,
      },
    },
    companyName: {
      width: 150,
    },
    deptName: {
      width: 150,
    },
    jobTitle: {
      width: 100,
    },
    customerName: {
      '&>td, a': {
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100px',
      },
    },
    mailAddress: {
      width: 150,
    },
    errorMailAddress: {
      width: 150,
      color: theme.palette.red[900],
    },
    mailSendCount: {
      width: 100,
    },
    lpAccessCount: {
      width: 100,
    },
    inquiryCount: {
      width: 100,
    },
    customerCreatedUser: {
      width: 100,
    },
    customerdeptName: {
      width: 150,
    },
    updatedDate: {
      width: 100,
    },

    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '',
    sortKey: '',
  },
  {
    title: '社名・団体名',
    sortKey: 'companyName',
  },
  {
    title: '部署名',
    sortKey: 'deptName',
  },
  {
    title: '役職',
    sortKey: 'jobTitle',
  },
  {
    title: '氏名',
    sortKey: 'customerName',
  },
  {
    title: 'メールアドレス',
    sortKey: 'mailAddress',
  },
  {
    title: 'ランディングページ案内数',
    sortKey: 'mailSendCount',
  },
  {
    title: 'ページ閲覧数',
    sortKey: 'lpAccessCount',
  },
  {
    title: 'お問い合わせ数',
    sortKey: 'inquiryCount',
  },
  {
    title: '登録者',
    sortKey: 'createdUser',
  },
  {
    title: '部署',
    sortKey: 'createdUserDeptName',
  },
  {
    title: '最終更新日時',
    sortKey: 'updatedDate',
  },
];

//  顧客一覧
//  customerId: number;
//   userId: number;
//   companyName: string;
//   deptName: string;
//   jobTitle: string;
//   customerName: string;
//   mailAddress: string;
//   mailSendCount: number;
//   lpAccessCount: number;
//   inquiryCount: number;
//   customerTag: string;
//   createdDate: string;
//   updatedDate: string;
//   customerCreatedUser: CustomerCreatedUser;

const CustomersTable = (props: CustomersTableProps) => {
  const classes = useStyles({});
  const {
    customers,
    customersQueryParameter,
    clickCustomer,
    handleChangeSortKey,
  } = props;

  const dispatch = useDispatch();
  // 消すIDがdeleteCustomersに入る
  const [deleteCustomers, setDeleteIdList] = useState<number[]>([]);

  // 顧客削除等によりcustomerIdsに値が無ければdeleteCustomersを空にする
  const customerIds = useSelector(
    (state: Modules.AppState) => state.deleteCustomers,
  );

  useEffect(() => {
    if (!customerIds) setDeleteIdList([]);
  }, [customerIds]);

  // chkbox処理
  const checkBoxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetId = Number.parseInt(e.target.value, 10);
    // チェックされてるやつをdeleteIdListに入れる
    if (e.target.checked) {
      setDeleteIdList(deleteCustomers.concat(targetId));
    } else {
      // チェックされてないやつは入れない
      setDeleteIdList(deleteCustomers.filter((id) => id !== targetId));
    }
  };

  // チェックされているものIDをdeletecustomerに格納
  useEffect(() => {
    dispatch(setDeleteCustomers({ customerIds: deleteCustomers }));
  }, [deleteCustomers, dispatch]);

  // ユーザー情報取得顧客リンクの活性非活性用
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const editPermission = (id: number) => {
    if (userInfo?.lpAuthor && userInfo?.lpApprover && !userInfo?.tenantAdmin) {
      // LP承認者かつLP制作者でテナント管理者じゃない
      if (id === userInfo?.userId)
        // 自分の顧客のみ編集可能
        return false;
    } else if (userInfo?.lpAuthor || userInfo?.tenantAdmin) {
      // テナント管理またはLP制作者は顧客編集可能
      return false;
    }
    // 編集不可

    return true;
  };

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {item.sortKey !== '' ? (
                  <TableSortLabel
                    active
                    direction={customersQueryParameter?.sort_order}
                    IconComponent={() =>
                      item.sortKey === customersQueryParameter?.sort_key &&
                      customersQueryParameter?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleChangeSortKey(item.sortKey)}
                  >
                    {item.title}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} hideSortIcon>
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {/*
              customers.customers.length < 11 のチェックは、
              LP詳細から遷移してきたときに顧客情報全件レンダリングを防止するためです。
              要望対応などで1ページ内の表示件数を10件から増やす場合、
              右辺の「11」部分を設定変数+1に変更するようお願いします。
          */}
          {customers &&
            customers.customers &&
            customers.customers.length < 11 &&
            customers.customers.map((customer) => (
              <TableRow key={customer.customerId}>
                <TableCell>
                  {/* 削除顧客選択チェックボックス */}
                  <Checkbox
                    disabled={editPermission(
                      customer.customerCreatedUser.userId,
                    )}
                    key={customer.customerId}
                    value={customer.customerId}
                    checked={deleteCustomers.includes(customer.customerId)}
                    className={classes.checkbox}
                    onChange={checkBoxOnChange}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.companyName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.companyName)}
                      variant="body2"
                    >
                      {customer.companyName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.deptName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.deptName)}
                      variant="body2"
                    >
                      {customer.deptName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.jobTitle || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.jobTitle)}
                      variant="body2"
                    >
                      {customer.jobTitle}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.customerName}>
                  <ButtonLink
                    disabled={editPermission(
                      customer.customerCreatedUser.userId,
                    )}
                    text={`${customer.customerName}`}
                    tooltipText={`${customer.customerName}`}
                    click={() =>
                      clickCustomer && clickCustomer(customer.customerId)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.mailAddress || ''}
                    arrow
                    placement="top"
                  >
                    {customer.mailSendError ? (
                      <Typography
                        className={clsx(
                          classes.overflow,
                          classes.errorMailAddress,
                        )}
                        variant="body2"
                      >
                        {customer.mailAddress}
                      </Typography>
                    ) : (
                      <Typography
                        className={clsx(classes.overflow, classes.mailAddress)}
                        variant="body2"
                      >
                        {customer.mailAddress}
                      </Typography>
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={customer.mailSendCount.toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.mailSendCount)}
                      variant="body2"
                    >
                      {customer.mailSendCount.toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={customer.lpAccessCount.toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.lpAccessCount)}
                      variant="body2"
                    >
                      {customer.lpAccessCount.toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={customer.inquiryCount.toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.overflow, classes.inquiryCount)}
                      variant="body2"
                    >
                      {customer.inquiryCount.toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.customerCreatedUser?.userName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(
                        classes.overflow,
                        classes.customerCreatedUser,
                      )}
                      variant="body2"
                    >
                      {customer.customerCreatedUser?.userName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={customer.customerCreatedUser?.deptName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(
                        classes.overflow,
                        classes.customerdeptName,
                      )}
                      variant="body2"
                    >
                      {customer.customerCreatedUser?.deptName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography className={classes.updatedDate} variant="body2">
                    {/* yyyy/mm/dd hh:mi:ss */}
                    {formatDateTimeISO8601(customer.updatedDate).replace(
                      ' ',
                      '\n',
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CustomersTable;

import { DeleteCustomers } from 'core/domain/customer';

const initialState = null;

export const createInitialState = (): DeleteCustomers | null => initialState;

export const SET_DELETECUSTOMERS = 'customers-delete/set' as const;
export const CLEAR_DELETECUSTOMERS = 'customers-delete/clear' as const;

export const setDeleteCustomers = (data: DeleteCustomers) => ({
  type: SET_DELETECUSTOMERS,
  payload: {
    data,
  },
});

export const clearDeleteCustomers = () => ({
  type: CLEAR_DELETECUSTOMERS,
});

type SetDeleteCustomersAction = ReturnType<typeof setDeleteCustomers>;
type ClearDeleteCustomersAction = ReturnType<typeof clearDeleteCustomers>;
type Action = SetDeleteCustomersAction | ClearDeleteCustomersAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_DELETECUSTOMERS:
      return action.payload.data;
    case CLEAR_DELETECUSTOMERS:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Grid } from 'components/atoms'
import { ReactionCountCardContent } from 'components/molecules'
import { CVIcon, DLIcon, GoodIcon, PVIcon } from 'assets/images'
import { LpReaction } from 'core/domain/lp'

/**
 * Interface
 */
export interface LPReactionCountListProps {
  lpReaction: LpReaction
}

export default function ReactionCountList(props: LPReactionCountListProps) {
  const {lpReaction} = props

  return (
    <Grid container>
      <Grid item xs={3}>
        <ReactionCountCardContent title="ページ閲覧数" icon={<PVIcon />} count={lpReaction.accessCount} />
      </Grid>
      <Grid item xs={3}>
        <ReactionCountCardContent title="興味アリ獲得数" icon={<GoodIcon />} count={lpReaction.interestedCount} />
      </Grid>
      <Grid item xs={3}>
        <ReactionCountCardContent title="資料ダウンロード数" icon={<DLIcon />} count={lpReaction.downloadCount} />
      </Grid>
      <Grid item xs={3}>
        <ReactionCountCardContent title="お問い合わせ数" icon={<CVIcon />} count={lpReaction.inquiryCount} />
      </Grid>
    </Grid>
  )
}
/* eslint-enable */

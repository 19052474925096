import { NoticesDetail } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticesDetail | null => initialState;

export const SET_NOTICESDETAIL = 'noticesDetail/set' as const;
export const CLEAR_NOTICESDETAIL = 'noticesDetail/clear' as const;

export const setNoticesDetail = (data: NoticesDetail) => ({
  type: SET_NOTICESDETAIL,
  payload: {
    data,
  },
});

export const clearNoticesDetail = () => ({
  type: CLEAR_NOTICESDETAIL,
});

type SetNoticesDetailAction = ReturnType<typeof setNoticesDetail>;
type ClearNoticesDetailAction = ReturnType<typeof clearNoticesDetail>;
type Action = SetNoticesDetailAction | ClearNoticesDetailAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NOTICESDETAIL:
      return action.payload.data;
    case CLEAR_NOTICESDETAIL:
      return initialState;
    default:
      return state;
  }
};

import { Lps } from 'core/domain/lp';

const initialState = null;

export const createInitialState = (): Lps | null => initialState;

export const SET_LPS = 'lps/set' as const;
export const CLEAR_LPS = 'lps/clear' as const;

export const setLps = (data: Lps) => ({
    type: SET_LPS,
    payload: {
      data,
    },
  });

export const clearLps = () => ({
    type: CLEAR_LPS,
  });

type SetLpsAction = ReturnType<typeof setLps>;
type ClearLpsAction = ReturnType<typeof clearLps>;
type Action = SetLpsAction | ClearLpsAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_LPS:
      return action.payload.data;
    case CLEAR_LPS:
      return initialState;
    default:
      return state;
  }
};

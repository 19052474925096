/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ExampleDetailList } from 'components/organisms'
import { ExampleDetail } from 'core/domain/example'
import { Box, Dialog, DialogActions, IconButton } from 'components/atoms'
import { CloseWhiteIcon } from 'assets/images'

/**
 * Interface
 */
export interface ExampleInformationDialogProps{
  exampleDetail: ExampleDetail
  handleRecommendedUpdate: ()=>void
  open:boolean
  onClose:()=>void
}
/**
* Style
*/

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  dialog: {
    padding: theme.spacing(3),
    height: 700,
    overflowY: 'unset',
  },
  examples: {
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
   left: '100%',
    top: '-5%',
    '& span svg path': {
      fill: theme.palette.common.white,
    },
 },
}),
)

/**
* Presenter
*/

export default function ExampleInformationDialog(props:ExampleInformationDialogProps) {

const classes = useStyles({})
const { exampleDetail,handleRecommendedUpdate,open,onClose } =props

return (
<Dialog
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      maxWidth="lg"
      fullWidth
      open={open}
      disableBackdropClick>
      <Box height={787} className={classes.examples}>
        <ExampleDetailList
          isDialog
          exampleDetail={exampleDetail}
          handleRecommendedUpdate={handleRecommendedUpdate}
        />
      </Box>
      <DialogActions className={classes.closeButton}>
        <IconButton onClick={onClose} >
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
)
}/* eslint-enable */

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  OneLineTextField,
  PrimaryButton,
  IconButton,
  Typography,
  CommonComboBox,
} from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100, SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { ArrowDownIcon, ArrowTopIcon, ErrorIcon } from 'assets/images';

import { inquiryItemOptions } from 'core/domain/inquiries';
import { InquiryForm } from 'core/domain/settings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textDecoration: 'none',
      verticalAlign: 'text-top',
      backgroundColor: theme.palette.green[700],
      boxShadow: 'none',
      padding: '25px 10px',
      borderRadius: 10,
      width: '95%',
    },
    test: {
      backgroundColor: theme.palette.gray[500],
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
        color: theme.palette.gray[900],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkboxRequire: {
      color: 'red',
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
    inputType: {
      padding: 8,
    },
    required: {
      textAlign: 'right',
      color: 'red',
      justifyContent: 'flex-end',
    },
    arrowIcon: {
      padding: 0,
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
      '&:disabled': {
        '& svg g path': {
          fill: theme.palette.gray[600],
        },
      },
      '& svg g path': {
        fill: theme.palette.green[800],
      },
    },
    disabledCheck: {
      opacity: 0.5,
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900]
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

interface InquiryFormsItemProps {
  item: InquiryForm;
  handleCheck: () => void;
  handleSelect: (value: string) => void;
  handleChangeRequired: () => void;
  changeOrder: (derection: boolean) => void;
  isFirstLast: 'first' | 'last' | '';
  isFree: boolean;
  handleChangeName: (text: string) => void;
  handleOpenOptionsDialog: (options: Array<inquiryItemOptions>) => void;
}

const InquiryFormsItem: React.FC<InquiryFormsItemProps> = (props) => {
  const classes = useStyles({});
  const {
    handleCheck,
    item,
    handleSelect,
    handleChangeRequired,
    changeOrder,
    isFirstLast,
    isFree,
    handleChangeName,
    handleOpenOptionsDialog,
  } = props;
  const [typeOptions, setTypeOptions] = useState([
    {
      inputType: 1,
      label: 'テキストボックス',
    },
    {
      inputType: 2,
      label: 'テキストエリア',
    },
    {
      inputType: 3,
      label: 'ラジオボタン',
    },
    {
      inputType: 4,
      label: 'チェックボックス',
    },
    {
      inputType: 5,
      label: 'プルダウン',
    },
    {
      inputType: 8,
      label: 'カレンダー',
    },
  ]);

  const [inquiryItemNameError, setInquiryItemNameError] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!isFree) return;
    setTypeOptions(typeOptions.filter((item) => item.inputType !== 8));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFree]);

  const checkNameErrors = (text:string, required: boolean) => {
    if (required && !text) {
      setInquiryItemNameError('自由項目名を入力してください。');
    } else if (text.length > 100){
      setInquiryItemNameError('100文字以内で入力してください。');
    } else if (inquiryItemNameError !== undefined) {
      setInquiryItemNameError(undefined);
    }
  };

  const changeName = (text: string) => {
    handleChangeName(text);
    checkNameErrors(text, item.displayed);
  };

  const editOptions = () => {
    if (item.inquiryItemOptions === undefined) return;
    handleOpenOptionsDialog(item.inquiryItemOptions);
  };

  const displayedDefaultDisabled =
  item.inquiryItemId === 3 ||
  item.inquiryItemId === 6;

  const requiredDefaultDisabled =
    displayedDefaultDisabled ||
    item.inquiryItemId === 17;

  // 選択肢チェック
  const [emptyItem, setEmptyItem] = useState('');
  useEffect(() => {
    if (item.inquiryItemOptions === undefined) return;
    if (item.inputType === 1 || item.inputType === 2) {
      setEmptyItem('');
      
      return;
    }
    if (item.displayed && item.inquiryItemOptions.length === 0) 
      setEmptyItem('選択肢情報を入力してください。');
    else
      setEmptyItem('');
  }, [item.displayed, item.inputType, item.inquiryItemOptions]);

  return (
    <Grid container>
      <Grid item xs={11}>
        <Box
          my={2}
          className={`${classes.root} ${
            (!item.displayed && classes.test) || ''
          }`}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {isFree ? (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={item.displayed}
                      onChange={(event) => {
                        checkNameErrors(item.inquiryItemName, event.target.checked);
                        handleCheck();
                      }}
                    />
                  }
                  label={
                    <OneLineTextField
                      label="自由項目を入力"
                      value={item.inquiryItemName}
                      handleChangeText={(text) => changeName(text)}
                      error={inquiryItemNameError}
                    />
                  }
                />
              ) : (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={item.displayed}
                      onChange={handleCheck}
                      disabled={displayedDefaultDisabled}
                      className={
                        displayedDefaultDisabled ? classes.disabledCheck : undefined
                      }
                    />
                  }
                  label={item.inquiryItemName}
                />
              )}
            </Grid>

            <Grid item xs={8}>
              <Box display="flex">
                <Typography className={classes.inputType}>
                  入力タイプ
                </Typography>
                <CommonComboBox
                  // inquiryItemId：17までの項目のプルダウンは非活性
                  disabled={item.inquiryItemId < 18}
                  width="332px"
                  value={
                    typeOptions.filter(
                      (option) => option.inputType === item.inputType,
                    )[0]?.label
                  }
                  handleChangeText={(text) => handleSelect(text)}
                  options={typeOptions.map((option) => option.label)}
                />
                {item.inquiryItemId >= 18 ? (
                  <Box width={160} height={40} ml={2}
                    style={emptyItem ? {
                      border: '3px solid red',
                      borderRadius: '10%',
                      backgroundColor: 'red',
                      marginBottom: 34,
                    } : {}}>
                    <PrimaryButton
                      click={editOptions}
                      text="選択肢編集"
                      disabled={item.inputType === 1 || item.inputType === 2}
                    />
                    {emptyItem && <Box className={classes.error}>
                      <ErrorIcon />
                      <Typography className={classes.errorText}>{emptyItem}</Typography>
                    </Box>}
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box className={classes.required} display="flex" height={28}>
                <Typography>必須</Typography>

                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={item.required}
                      onChange={(event) => {
                        if(isFree) {
                          checkNameErrors(item.inquiryItemName, event.target.checked || item.displayed);
                        }
                        handleChangeRequired();
                      }}
                      disabled={requiredDefaultDisabled}
                      className={requiredDefaultDisabled ? classes.disabledCheck : ''}
                    />
                  }
                  label=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box width={20} height={44} pt={4}>
          <IconButton
            disabled={isFirstLast === 'first'}
            className={classes.arrowIcon}
            onClick={() => changeOrder(true)}
          >
            <ArrowTopIcon />
          </IconButton>
          <IconButton
            disabled={isFirstLast === 'last'}
            className={classes.arrowIcon}
            onClick={() => changeOrder(false)}
          >
            <ArrowDownIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InquiryFormsItem;

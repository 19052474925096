import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { useHistory } from 'react-router-dom';
import MyLPs from 'components/pages/MyLPs';
import { setLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';
import { LpSortKey } from 'core/domain/lp';
import { clearLps } from 'core/modules/lps';
import { clearLpsOwn } from 'core/modules/lpsOwn';

import { clearLpTags } from 'core/modules/lpTags';
import { hooks } from 'libs';
import { setLpTagsRequest } from 'core/modules/lpTagsRequest';
import { clearLpDetail } from 'core/modules/lpDetail';
import { UserInfoDetail } from 'core/domain/user';

const pageRows = 10;

/**
 * Presenter
 */
const MyLPsPageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // わたしのランディングページ or 部下のランディングページ の表示切替フラグ
  const own = useMemo(
    () => history.location.pathname.includes('/own'),
    [history.location.pathname],
  );

  const lpsOwn = useSelector(
    (state: Modules.AppState) => state.lpsOwn,
    shallowEqual,
  );
  const lpsSubordinates = useSelector(
    (state: Modules.AppState) => state.lpsSubordinates,
    shallowEqual,
  );
  const lpOwnSearchCondition = useSelector(
    (state: Modules.AppState) => state.lpOwnSearchCondition,
    shallowEqual,
  );
  const lPTagsRequest = useSelector(
    (state: Modules.AppState) => state.lPTagsRequest,
    shallowEqual,
  );
  const lpTags = useSelector(
    (state: Modules.AppState) => state.lpTags,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  // 現在入力されているフリーワードを取得
  const freeWord = useSelector((state: Modules.AppState) => state.freeWord);

  const [openLpProductDetailDialog, setOpenLpProductDetailDialog] = useState<
    'interested-count' | 'document-download-count' | undefined
  >(undefined);

  const token = hooks.useAuth().accessToken;

  // 自分・部下のLP一覧取得/リクエストパラメーター初期値設定
  useEffect(() => {
    if (!lpOwnSearchCondition) {
      dispatch(
        setLpOwnSearchCondition({
          page: 1,
          row: pageRows,
          subordinates: !own ? true : undefined,
        }),
      );

      return;
    }

    if (!userInfo) return;
    if (own) {
      dispatch(
        Usecases.lps.sendGetLpsOwn(
          token,
          userInfo.tenantId,
          lpOwnSearchCondition,
        ),
      );
    } else {
      dispatch(
        Usecases.lps.sendGetLpsSubordinates(
          token,
          userInfo.tenantId,
          lpOwnSearchCondition,
        ),
      );
    }

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [lpOwnSearchCondition, userInfo]);

  useEffect(
    () => {
      // LP詳細のデータが残っていると、
      // 承認済み→公開中のLP詳細に移動するときにダイアログが開いてしまう現象が起きるためここで空にしておく。
      dispatch(clearLpDetail());

      return () => {
        dispatch(clearLps());
        dispatch(clearLpsOwn());
      };
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  // 取得したLP数からページ数を計算しリクエストパラメーターに設定
  useEffect(() => {
    const tempLps = own ? lpsOwn : lpsSubordinates;
    if (tempLps && tempLps.lps.length === 0 && tempLps.totalCount > 0) {
      const latestPage = Math.ceil(tempLps?.totalCount / pageRows);
      dispatch(
        setLpOwnSearchCondition({
          ...lpOwnSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [lpsOwn, lpsSubordinates, own, dispatch, lpOwnSearchCondition]);

  // LPタグ取得リクエストパラメーター初期値設定
  useEffect(() => {
    dispatch(setLpTagsRequest({ tagType: 'own', sortKey: 'tag' }));
  }, [dispatch]);

  // LPタグ一覧取得
  useEffect(() => {
    if (userInfo) {
      dispatch(
        Usecases.lps.sendGetLpTags(token, userInfo.tenantId, lPTagsRequest),
      );
    }

    return () => {
      dispatch(clearLpTags());
    };
  }, [lPTagsRequest, userInfo, dispatch, token]);

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (lpOwnSearchCondition?.sort_key === sortKey) {
      order = lpOwnSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        sort_key: sortKey as LpSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * ステータスチェックボックスチェック時
   * 該当のLPステータスをリクエストパラメーターに設定
   * @param status
   */
  const handleCheckStatus = (status: number) => {
    if (!lpOwnSearchCondition || !lpOwnSearchCondition.status) {
      dispatch(
        setLpOwnSearchCondition({
          ...lpOwnSearchCondition,
          status: `${status}`,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }
    const statusArray = lpOwnSearchCondition.status.split(',');
    const index = statusArray.indexOf(`${status}`);
    if (index < 0) {
      statusArray.push(`${status}`);
    } else {
      statusArray.splice(index, 1);
    }

    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        status: statusArray.join(','),
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  /**
   * タグ変更時パラメーターに設定
   * @param tags
   */
  const handleChangeTag = (tags: string) => {
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        tags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * 作成者絞り込みパラメータ
   * @param users 
   */
  const handleChangeLpTantoUser = (users: Array<UserInfoDetail>) => {
    let ids = '';
    users.forEach((item) => {
      ids += `${item.userId},`;
    });
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        lp_tanto_user_ids: ids,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * 承認待ちのみ表示ラジオボタン切り替え時
   * リクエストパラメーターに設定
   * @param checked
   */
  const handleChangeApprovalTaskSwitch = (checked: boolean) => {
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        approval_task: checked,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * 部下のみ表示ラジオボタン切り替え時
   * リクエストパラメーターに設定
   * @param checked
   */
  const handleChangeSubordinatesSwitch = (checked: boolean) => {
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        subordinates: checked,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  // タグxボタン押下
  const deleteTag = (val: string) => {
    if (!lpOwnSearchCondition || !lpOwnSearchCondition.tags) {
      dispatch(
        setLpOwnSearchCondition({
          ...lpOwnSearchCondition,
          tags: '',
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }

    const newTags = lpOwnSearchCondition.tags
      .split(',')
      .filter((tag: string) => tag !== val);
    dispatch(
      setLpOwnSearchCondition({
        ...lpOwnSearchCondition,
        tags: newTags.join(','),
        free_word: freeWord?.freeWord,
      }),
    );
  };

  const handleClickLpProductClose = () => {
    setOpenLpProductDetailDialog(undefined);
  };

  const handleClickLpProductInterestedCount = async (lpId: number) => {
    if (!userInfo) return;

    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await dispatch(
      Usecases.lps.sendGetLpProductInterestedCount(
        token,
        userInfo.tenantId,
        lpId,
      ),
    );

    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await setOpenLpProductDetailDialog('interested-count');
  };

  const handleClickLpProductDocumentDownloadCount = async (lpId: number) => {
    if (!userInfo) return;
    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await dispatch(
      Usecases.lps.sendGetLpProductDocumentDownloadCount(
        token,
        userInfo.tenantId,
        lpId,
      ),
    );
    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await setOpenLpProductDetailDialog('document-download-count');
  };

  return (
    <MyLPs
      own={own}
      lps={own ? lpsOwn : lpsSubordinates}
      rows={pageRows}
      lpOwnSearchCondition={lpOwnSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleCheckStatus={handleCheckStatus}
      handleChangeFreeWord={handleChangeFreeWord}
      handleChangeTag={handleChangeTag}
      tags={lpTags}
      handleChangeSubordinatesSwitch={handleChangeSubordinatesSwitch}
      handleChangeApprovalTaskSwitch={handleChangeApprovalTaskSwitch}
      deleteTag={deleteTag}
      openLpProductDetailDialog={openLpProductDetailDialog}
      handleClickLpProductInterestedCount={handleClickLpProductInterestedCount}
      handleClickLpProductDocumentDownloadCount={
        handleClickLpProductDocumentDownloadCount
      }
      handleClickLpProductClose={handleClickLpProductClose}
      handleChangeLpTantoUser={handleChangeLpTantoUser}
    />
  );
};

export default MyLPsPageContainer;

import React from 'react';
import { Box, OneLineTextField } from 'components/atoms';
import { VariousSettings } from 'core/domain/variousSettings';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MandatoryTitleLabel } from 'components/molecules';

interface VariousSettingsCopyrightFieldAreaProps {
  error: string | undefined;
  handleInputChange: (v: string, key: keyof VariousSettings) => void;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

const VariousSettingsCopyrightFieldArea = (
  props: VariousSettingsCopyrightFieldAreaProps,
) => {
  const { error, handleInputChange, value } = props;
  const classes = useStyles();

  const handleChange = (v: string) => {
    handleInputChange(v, 'copyright');
  };

  return (
    <Box mt={5} className={classes.root}>
      <MandatoryTitleLabel mb title="コピーライト" />
      <OneLineTextField
        error={error}
        label="コピーライトを入力"
        value={value}
        handleChangeText={handleChange}
        maxLength={100}
      />
    </Box>
  );
};

export default VariousSettingsCopyrightFieldArea;

import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  SecondaryButton,
  Typography,
} from 'components/atoms';
import { ErrorOutlineIcon, CloseIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';

/**
 * Interface
 */

export interface ErrorDialogProps {
  open: boolean;
  title?: string;
  message?: Array<string>;
  handleClose?: () => void;
}

const SERVER_ERROR_MESSAGE = 'システムエラーが発生しました。';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialogBgColor: {
      backgroundColor: '#fff',
    },
    dialog: {
      width: 632,
      minHeight: 224,
      padding: '32px 24px 16px 24px',
      margin: 0,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        width: 64,
        height: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    message: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      whiteSpace: 'pre',
    },
    actions: {
      padding: 0,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line   @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
        fill: theme.palette.green[800],
      },
    },
  }),
);

const ErrorDialog = (props: ErrorDialogProps) => {
  const classes = useStyles({});
  const { open, title, message, handleClose } = props;

  const errorMessage = useMemo(() => {
    if (message !== undefined && message !== undefined) {
      // HTTPエラーメッセージ次第でstring[]ではなくstringとなることがある
      // string型だった場合の暫定処理追記
      if (typeof message === 'string') return message;
      if (title === message?.join('\n')) {
        return '';
      }
    }

    return message?.join('\n');
  }, [title, message]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ root: classes.dialogBgColor, paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleClose && handleClose()}
    >
      <Box>
        <Box className={classes.icon}>
          <ErrorOutlineIcon />
        </Box>
        <Typography
          variant="body2"
          className={classes.title ? classes.title : SERVER_ERROR_MESSAGE}
        >
          {title}
        </Typography>
        <Typography variant="body2" className={classes.message}>
          {errorMessage && errorMessage}
        </Typography>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={102} height={36}>
              <SecondaryButton
                icon={<CloseIcon className={classes.closeIcon} />}
                click={() => handleClose && handleClose()}
                text="閉じる"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default ErrorDialog;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  LP_OPEN_STATUS_DRAFT,
  LP_OPEN_STATUS_END,
  LP_OPEN_STATUS_OTHER,
  LP_OPEN_STATUS_RELEASED,
  LP_OPEN_STATUS_SUSPENSION,
  LP_OPEN_STATUS_TRASH,
} from 'constants/text';
import {
  CODE_LP_OPEN_STATUS_DRAFT,
  CODE_LP_OPEN_STATUS_END,
  CODE_LP_OPEN_STATUS_OTHER,
  CODE_LP_OPEN_STATUS_RELEASED,
  CODE_LP_OPEN_STATUS_SUSPENSION,
  CODE_LP_OPEN_STATUS_TRASH,
} from 'constants/code';

/**
 * Interface
 */

export interface OpenStatusLabelProps {
  status: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    greenOutline: {
      borderColor: theme.palette.green[900],
      color: theme.palette.green[900],
    },
    gray: {
      borderColor: theme.palette.gray[800],
      color: theme.palette.gray[800],
    },
    orange: {
      backgroundColor: theme.palette.orange[900],
      color: 'white',
    },
    orangeOutline: {
      borderColor: theme.palette.orange[900],
      color: theme.palette.orange[900],
    },
    redOutline: {
      borderColor: theme.palette.red[900],
      color: theme.palette.red[900],
    },
  }),
);

const OpenStatusDic = new Map<number, string>();
OpenStatusDic.set(CODE_LP_OPEN_STATUS_DRAFT, LP_OPEN_STATUS_DRAFT);
OpenStatusDic.set(CODE_LP_OPEN_STATUS_END, LP_OPEN_STATUS_END);
OpenStatusDic.set(CODE_LP_OPEN_STATUS_OTHER, LP_OPEN_STATUS_OTHER);
OpenStatusDic.set(CODE_LP_OPEN_STATUS_RELEASED, LP_OPEN_STATUS_RELEASED);
OpenStatusDic.set(CODE_LP_OPEN_STATUS_SUSPENSION, LP_OPEN_STATUS_SUSPENSION);
OpenStatusDic.set(CODE_LP_OPEN_STATUS_TRASH, LP_OPEN_STATUS_TRASH);

/**
 * Presenter
 */

export default function OpenStatusLabel(props: OpenStatusLabelProps) {
  const classes = useStyles({});
  const { status } = props;

  const decideClass = useMemo(() => {
    let tmpClass = classes.orange;
    switch (status) {
      case CODE_LP_OPEN_STATUS_DRAFT: {
        tmpClass = classes.greenOutline;
        break;
      }
      case CODE_LP_OPEN_STATUS_RELEASED: {
        tmpClass = classes.orange;
        break;
      }
      case CODE_LP_OPEN_STATUS_SUSPENSION: {
        tmpClass = classes.redOutline;
        break;
      }
      case CODE_LP_OPEN_STATUS_END: {
        tmpClass = classes.orangeOutline;
        break;
      }
      case CODE_LP_OPEN_STATUS_TRASH: {
        tmpClass = classes.gray;
        break;
      }
    }

    return tmpClass;
  }, [status]);

  return status === CODE_LP_OPEN_STATUS_OTHER ? (
    <></>
  ) : (
    <Typography
      className={`${classes.root} ${decideClass}`}
      variant="caption"
      display="block"
    >
      {OpenStatusDic.get(status)}
    </Typography>
  );
} /* eslint-enable */

import React, { useEffect, useState } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Usages } from 'core/domain/dashboard';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';

const TABLE_HEADER_ITEMS = [
  {
    label: '年月',
  },
  {
    label: '月ごとの公開LP数',
  },
  {
    label: '月ごとのログインユーザー数',
  },
  {
    label: '月ごとのデータ量',
  },
];

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    year: {
      marginBottom: 20,
    },
    pulldown: {
      width: 320,
      border: '1px solid #ccc',
      borderRadius: 4,
      padding: '8px',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
    },
    table: {
      minWidth: 1000,
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    message: {
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      margin: '10px 10px 0 10px',
    }
  }),
);

/**
 * Interface
 */
interface Options {
  label: string;
  value: string;
}

export interface UsagesHistoryTableProps {
  usagesHistory?: Array<Usages>;
  useStartDate?: string;
  handleChangeSelect: (value: string) => void;
}

/**
 * Presenter
 */

const UsagesHistoryTable = (props: UsagesHistoryTableProps) => {
  const {
    usagesHistory,
    useStartDate,
    handleChangeSelect
  } = props;

  const classes = useStyles({});

  const todayYear = new Date().getFullYear().toString();

  const [options, setOptions] = useState<Options[]>();

  // option作成
  useEffect(() => {
    if (!useStartDate) return;
    const option = [];
    const nToday = parseInt(todayYear, 10);
    const nStartDate = parseInt(useStartDate.slice(0,4), 10);
    for (let i = nToday; i >= nStartDate; i -= 1) {
      option.push({label: `${i.toString()}年`, value: i.toString()});
    }
    setOptions(option);
  }, [todayYear, useStartDate]);

  /**
   * データ量整形
   * MBまたはGBを付与する
   * 小数点第2位を四捨五入
   */
  const fomartDataSize = (dataSize: number) => {
    const kb = 1024;
    const mb = kb ** 2;
    const gb = kb ** 3;
    let formatData = '0.0 MB';

    if (dataSize < 50 * kb) {
      // 0.05KB未満は0.0MB
      formatData = '0.0 MB';
    } else if (dataSize < 999.95 * mb) {
      // ～999.94MB
      const size = Number((dataSize / mb).toFixed(1))
      if (size === 0) formatData = '0.0 MB'
      else formatData = `${size.toLocaleString()} MB`;
    } else if (dataSize < gb) {
      // 999.95MB以上は1.0GBに繰り上げ
      formatData = '1.0 GB';
    } else {
      // 1.0GB～
      const size = Number((dataSize / gb).toFixed(1))
      if (size === 1) formatData = '1.0 GB';
      else formatData = `${size.toLocaleString()} GB`;
    }

    return formatData;
  }

  return (
    <Box>
      <Box className={classes.year}>
        <select
          defaultValue={todayYear}
          onChange={(e) => {
            handleChangeSelect(e.target.value);
          }}
          className={`${classes.pulldown}`}
        >
          {options && options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Box>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow>
              {TABLE_HEADER_ITEMS.map((header, index) => (
                <TableCell key={index}>{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {usagesHistory &&
              usagesHistory.length > 0 &&
              usagesHistory.map((usage, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2">
                    {usage.yearMonth.toString().slice(0, 4)}/
                    {usage.yearMonth.toString().slice(4)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={usage.openLpCount.toLocaleString()}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {usage.openLpCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={usage.loginUserCount.toLocaleString()}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {usage.loginUserCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={fomartDataSize(usage.dataSize)}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {fomartDataSize(usage.dataSize)}
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography className={classes.message}>
        当月の値は、前日２４時までの利用状況を反映しています。
      </Typography>
    </Box>
  );
};
export default UsagesHistoryTable;

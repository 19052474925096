import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  ApplyStatusLabel,
  Tooltip,
  Typography,
} from 'components/atoms';
import { FavoriteButton } from 'components/molecules';
import FavoriteGrayIcon from 'assets/images/icn_favorite_gray.svg';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Products, ProductsQueryParameter } from 'core/domain/product';
import { formatDateTimeSecISO8601 } from 'libs/date';
import {
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
} from 'constants/code';

/**
 * Interface
 */
export interface ProductsTableProps {
  products: Products | null;
  clickProduct?: (id: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleClickFavorite: (productId: number, checked: boolean) => void;
  productSearchCondition: ProductsQueryParameter | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    status: {
      width: 96,
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    overflow: {
      '&>td, p': {
        maxWidth: 150,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'ステータス',
    sortKey: 'status',
  },
  {
    title: 'お気に入り',
    sortKey: 'favoriteCount',
  },
  {
    title: '商材ID',
    sortKey: 'productId',
  },
  {
    title: '商材名称',
    sortKey: 'productName',
  },
  {
    title: '商材タグ',
    sortKey: 'tag',
  },
  {
    title: '利用されている\nランディングページ数',
    sortKey: 'uselpCount',
  },
  {
    title: '興味アリ\n獲得数',
    sortKey: 'interestedCount',
  },
  {
    title: '資料ダウン\nロード数',
    sortKey: 'downloadCount',
  },
  {
    title: 'お問い合わせ先',
    sortKey: 'contact',
  },
  {
    title: '最終更新日時',
    sortKey: 'updatedDate',
  },
];

const ProductsTable = (props: ProductsTableProps) => {
  const classes = useStyles({});
  const {
    products,
    clickProduct,
    handleChangeSortKey,
    handleClickFavorite,
    productSearchCondition,
  } = props;

  const checkIsActiveFavoriteButton = (productStatus: number) =>
    [
      CODE_MATERIAL_STATUS_RELEASED,
      CODE_MATERIAL_STATUS_SUSPENSION,
      CODE_MATERIAL_STATUS_EXPIRE,
      CODE_MATERIAL_STATUS_END,
    ].some((e) => e === productStatus);

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active
                  direction={productSearchCondition?.sort_order}
                  IconComponent={() =>
                    item.sortKey === productSearchCondition?.sort_key &&
                    productSearchCondition?.sort_order === 'asc' ? (
                      <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <SortDownIcon />
                    )
                  }
                  onClick={() => handleChangeSortKey(item.sortKey)}
                >
                  {item.title !== 'お気に入り' ? (
                    item.title
                  ) : (
                    <img
                      className={classes.headerFavorite}
                      src={FavoriteGrayIcon}
                    />
                  )}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {products &&
            products.products &&
            products.products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box className={classes.status}>
                    <ApplyStatusLabel status={product.productStatus} />
                  </Box>
                </TableCell>
                <TableCell>
                  <FavoriteButton
                    disabled={
                      !checkIsActiveFavoriteButton(product.productStatus)
                    }
                    favorite={product.favorite}
                    favoriteCount={product.favoriteCount}
                    click={() =>
                      handleClickFavorite(product.productId, !product.favorite)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={product.productId}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flex"}}>{product.productId}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.link}>
                  <ButtonLink
                    text={product.productName}
                    tooltipText={product.productName}
                    click={() =>
                      clickProduct && clickProduct(product.productId)
                    }
                  />
                </TableCell>
                {/* TODO:ツールチップ...リーダー */}
                <TableCell className={classes.overflow}>
                  <Tooltip
                    title={
                      (product.productTag &&
                        product.productTag.replaceAll(',', '/')) ||
                      ''
                    }
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {product.productTag &&
                        product.productTag.replaceAll(',', '/')}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={product.productReaction
                      ? product.productReaction.useLpCount.toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {product.productReaction
                        ? product.productReaction.useLpCount.toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={product.productReaction
                      ? product.productReaction.interestedCount.toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {product.productReaction
                        ? product.productReaction.interestedCount.toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={product.productReaction
                      ? product.productReaction.downloadCount.toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {product.productReaction
                        ? product.productReaction.downloadCount.toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                {/* TODO:ツールチップ...リーダー */}
                <TableCell className={classes.overflow}>
                  <Tooltip title={product.contact || ''} arrow placement="top">
                    <Typography variant="body2">{product.contact}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {formatDateTimeSecISO8601(product.updatedDate).replace(
                    ' ',
                    '\n',
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;

import React, { useState, useMemo, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  CustomerDetail,
  CustomerRegisterRequest,
  Customers,
} from 'core/domain/customer';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  SecondaryButton,
  PrimaryButton,
  Typography,
  DialogTitle,
  Card,
  Grid,
  CommonComboBox,
  SearchTextField,
  Checkbox,
  FormControlLabel,
  OneLineTextField,
  IconButton,
  Tooltip,
} from 'components/atoms';
import { ConfirmDialog, DateTimePicker } from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import { CloseIcon, CloseWhiteIcon, ErrorOutlineIcon } from 'assets/images';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
} from 'constants/typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { checkPastDay, validDate } from 'libs/date';
import { MailSender } from 'core/domain/lp';
import _ from 'lodash';
import { SendErrorMailAddressWarningLabel } from 'components/molecules';
import { MAIL_SENDER_LABEL } from 'constants/text';

export interface LpMailDialogProps {
  open: boolean;
  date?: string;
  time?: string;
  handleCancel: () => void;
  handleSubmit: (
    mailSendDate: string,
    mailSendTime: string,
    mailSenders: MailSender[],
  ) => void;
  isRelease?: boolean;
  customersProps: Customers | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: 1206,
      padding: '24px',
      margin: 0,
      position: 'relative',
    },
    card: {
      backgroundColor: theme.palette.gray[400],
      height: 'calc(100vh - 266px)',
    },
    cardContent: {
      margin: theme.spacing(3),
    },
    closeButton: {
      position: 'fixed',
      left: '96%',
      top: '0%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    confirmBtn: {
      height: '48px',
      width: '332px',
      margin: '40px auto 20px auto',
    },
    searchComboBox: {
      maxWidth: '45%',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
        padding: 6,
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        // transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    warning: {
      margin: '5px',
      border: '2px solid red',
    },
    warningContent: {
      width: '65%',
      margin: '5px auto',
    },
    gridItem: {
      height: '100%',
      margin: 'auto',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    confirmTitle: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    listBox: {
      height: 'calc(100vh - 540px)',
    },
    listLabel: {
      width: '33%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:nth-child(1)': {
        paddingLeft: '12px',
      },
      '&:nth-child(2)': {
        paddingRight: '10px',
      },
      '& > label': {
        width: '100%',
        '& > span:nth-child(2)': {
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
      '& > p': {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
    },
    sendListLabel: {
      width: '33%',
      margin: '8px 0',
      '&:not(:last-child)': {
        paddingRight: '8px',
      },
      '& > p': {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
    listAlign: {
      paddingTop: 7,
    },
  }),
);

const initialCustomerInput = {
  customerId: null,
  companyName: '',
  deptName: '',
  jobTitle: '',
  customerName: '',
  mailAddress: '',
  customerTag: '',
};

// メアド形式チェック用
const PATTERN = new RegExp(
  /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
);

const LPMailDialog = (props: LpMailDialogProps) => {
  const classes = useStyles({});
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { open, handleCancel, handleSubmit, customersProps } = props;
  const [searchWord, setSearchWord] = useState<string | undefined>(undefined);
  const [tab, setTab] = React.useState(0);
  const [mailSendDate, setMailSendDate] = useState<string | undefined>(
    undefined,
  );
  const [mailSendTime, setMailSendTime] = useState<string | undefined>(
    undefined,
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const latestCustomerDetail = useSelector(
    (state: Modules.AppState) => state.customerDetail,
  );
  const [newCustomer, setNewCustomer] =
    useState<CustomerRegisterRequest>(initialCustomerInput);
  const customerTags = useSelector(
    (state: Modules.AppState) => state.customerTags,
  );
  // const [customers, setCustomers] = useState<
  //   Array<CustomerDetail | CustomerRegisterRequest>
  // >([]);
  const [customers, setCustomers] = useState<Array<CustomerDetail>>([]);
  const [campanyNames, setCampanyNames] = useState<string[]>([]);
  const [sendList, setSendList] = useState<
    Array<(CustomerDetail | CustomerRegisterRequest) & MailSender>
  >([]);
  const [checkList, setCheckList] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [saveCustomer, setSaveCustomer] = useState(true);
  const [step, setStep] = useState(0);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [companyNameError, setCompanyNameError] = useState<string | undefined>(
    undefined,
  );
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [dateError, setDateError] = useState<string | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    undefined,
  );
  const [customerTagsName, setCustomerTagsName] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  // 日付フォーマットエラーチェック用
  const [dateCheck, setDateCheck] = useState<string | undefined>(undefined);
  const [currCompanyName, setCurrCompanyName] = useState('');
  const [currName, setCurrName] = useState('');
  const [currMailAddress, setCurrMailAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkChangeValues = () => {
    // 自由入力での値
    const isInTheMiddleOfInput =
      !!currCompanyName || !!currName || !!currMailAddress;

    // 連絡リストに連絡先が追加されているか
    const hasContactAddress = sendList.length > 0;
    if (!step && (isInTheMiddleOfInput || hasContactAddress)) {
      setConfirmDialogOpen(true);

      return;
    }

    if (step) {
      setConfirmDialogOpen(true);

      return;
    }

    handleCancel();
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogSubmit = () => {
    handleCancel();
  };

  const handleChangeTab = (event: unknown, newValue: number) => {
    setTab(newValue);
  };
  // 送信先リストキャンセル時
  const removeSendList = (index: number) => {
    const result = sendList.filter((item) => item !== sendList[index]);
    setSendList(result);
  };

  // チェック時のステート更新
  const handleSetCheckList = (index: string) => {
    const isSelected = checkList.includes(index);
    let result = [];
    if (isSelected) {
      result = checkList.filter((item) => item !== index);
      setAllChecked(false);
    } else {
      result = [...checkList, index];
    }
    setCheckList(result);
  };
  const handleChangeTag = (text: string) => setSelectedTag(text);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkWord = (str: string) => {
    if (!str) return false;
    if (!searchWord) return true;
    if (str.indexOf(searchWord) !== -1) return true;

    return false;
  };

  // 検索フィルター
  const filterCustomer = useMemo(() => {
    if (!selectedCompany && !searchWord && !selectedTag) return customers;
    let filterd: CustomerDetail[] | CustomerRegisterRequest[];
    if (selectedCompany && selectedTag && searchWord) {
      filterd = customers.filter(
        (customer) =>
          (customer.companyName === selectedCompany &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.companyName)) ||
          (customer.companyName === selectedCompany &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.mailAddress)) ||
          (customer.companyName === selectedCompany &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.customerName)) ||
          (customer.companyName === selectedCompany &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            customer.customerTag.split(',').includes(searchWord)),
      );
    } else if (selectedCompany && selectedTag) {
      filterd = customers.filter(
        (customer) =>
          customer.companyName === selectedCompany &&
          customer.customerTag &&
          customer.customerTag.split(',').includes(selectedTag),
      );
    } else if (selectedCompany && searchWord) {
      filterd = customers.filter(
        (customer) =>
          (customer.companyName === selectedCompany &&
            checkWord(customer.companyName)) ||
          (customer.companyName === selectedCompany &&
            checkWord(customer.mailAddress)) ||
          (customer.companyName === selectedCompany &&
            checkWord(customer.customerName)) ||
          (customer.companyName === selectedCompany &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(searchWord)),
      );
    } else if (selectedTag && searchWord) {
      filterd = customers.filter(
        (customer) =>
          (customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.companyName)) ||
          (customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.mailAddress)) ||
          (customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            checkWord(customer.customerName)) ||
          (customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag) &&
            customer.customerTag &&
            customer.customerTag.split(',').includes(searchWord)),
      );
    } else if (searchWord) {
      filterd = customers.filter(
        (customer) =>
          checkWord(customer.companyName) ||
          checkWord(customer.mailAddress) ||
          checkWord(customer.customerName) ||
          (customer.customerTag &&
            searchWord &&
            customer.customerTag.split(',').includes(searchWord)),
      );
    } else {
      filterd = customers.filter(
        (customer) =>
          customer.companyName === selectedCompany ||
          (customer.customerTag &&
            customer.customerTag.split(',').includes(selectedTag)),
      );
    }

    return filterd;
  }, [selectedCompany, searchWord, selectedTag, customers, checkWord]);

  // 全選択チェック時
  const allSelect = (e: boolean) => {
    if (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const list = [...new Array(filterCustomer.length)].map((_, i) =>
        String(i),
      );
      setCheckList(list);
      setAllChecked(true);
    } else {
      setCheckList([]);
      setAllChecked(false);
    }
  };

  const handleChangeMailSendDate = (date: string) => {
    // エラーチェック用
    setDateCheck(date.replaceAll('/', ''));

    let isError = false;
    isError = date.replaceAll('/', '')
      ? !validDate(date.replaceAll('/', ''))
      : false;
    if (isError) return;

    setMailSendDate(date.replaceAll('/', ''));
  };

  const handleChangeMailSendTime = (time: string) => {
    const formatTime = time.length !== 0 ? time : undefined;
    setMailSendTime(formatTime);
  };

  // 送信者リストの変更
  const handleChangeSendList = (type: 'check' | 'input') => {
    setNameError(undefined);
    setCompanyNameError(undefined);
    setEmailError(undefined);
    let tmpNameError = false;
    let tmpCompanyNameError = false;
    let tmpEmailError = false;
    if (!filterCustomer) return;
    // チェックボタンから
    if (type === 'check') {
      const check: Array<
        (CustomerDetail | CustomerRegisterRequest) & MailSender
      > = checkList.map((item: string) => ({
        ...filterCustomer[Number(item)],
        customerRegist: null,
      }));
      const result = check
        .concat(sendList)
        .filter(
          (
            item: (CustomerDetail | CustomerRegisterRequest) & MailSender,
            index,
            self,
          ) =>
            self.findIndex(
              (e: (CustomerDetail | CustomerRegisterRequest) & MailSender) =>
                e.mailAddress === item.mailAddress,
            ) === index,
        );

      setSendList(result);
    }
    // フォームから
    if (type === 'input') {
      if (!userInfo) return;

      if (newCustomer.companyName.length === 0) {
        setCompanyNameError('会社名を入力してください。');
        tmpCompanyNameError = true;

        return;
      }
      if (newCustomer.customerName.length === 0) {
        setNameError('名前を入力してください。');
        tmpNameError = true;

        return;
      }
      if (newCustomer.mailAddress.length === 0) {
        setEmailError('メールアドレスを入力してください。');
        tmpEmailError = true;

        return;
      }

      if (newCustomer.companyName.length > 100) {
        setCompanyNameError('100文字以内で入力してください。');
        tmpCompanyNameError = true;

        return;
      }
      if (newCustomer.customerName.length > 100) {
        setNameError('100文字以内で入力してください。');
        tmpNameError = true;

        return;
      }
      if (newCustomer.mailAddress.length > 254) {
        setEmailError('254文字以内で入力してください。');
        tmpEmailError = true;

        return;
      }

      // メアド形式チェック
      if (newCustomer.mailAddress && !PATTERN.exec(newCustomer.mailAddress)) {
        setEmailError('メールアドレスの形式で入力してください。');
        tmpEmailError = true;

        return;
      }

      // ＠とドット区切り、文字数チェック
      if (newCustomer.mailAddress && PATTERN.exec(newCustomer.mailAddress)) {
        // ＠左側が64文字超えたらerror
        const atLeftcheck = newCustomer.mailAddress?.split('@') || undefined;
        if (atLeftcheck && atLeftcheck[0]?.length > 64) {
          setEmailError('ローカル部分は64文字以内で入力してください。');
          tmpEmailError = true;

          return;
        }

        // ＠右側を、ドット区切りで配列にしてそれぞれ63文字以内か
        // aaa@bbb.ccc→bbb,ccc
        // aaa@bbb.ccc.ddd→ bbb,ccc,ddd
        if (atLeftcheck) {
          const atRightcheck = atLeftcheck[1]?.split('.') || undefined;
          atRightcheck.map((domain) => {
            if (domain.length > 63) {
              setEmailError('メールアドレスの形式で入力してください。');
              tmpEmailError = true;
            }
          });
        } // if(atLeftcheck)
      } // if

      // 重複チェック
      const existingMailCheck = customers.filter(
        (value) => value.mailAddress === newCustomer.mailAddress,
      );
      if (existingMailCheck.length > 0) {
        setEmailError(
          '既に顧客一覧に登録済みです。リストから選択より選択してください。',
        );
        tmpEmailError = true;

        return;
      }
      const mailCheck = sendList.filter(
        (value) => value.mailAddress === newCustomer.mailAddress,
      );
      if (mailCheck.length > 0) {
        setEmailError('既に送信先リストに追加済みです。');
        tmpEmailError = true;

        return;
      }
      if (tmpNameError || tmpCompanyNameError || tmpEmailError) return;

      const result = [
        ...sendList,
        { ...newCustomer, customerRegist: saveCustomer },
      ];
      setNewCustomer(initialCustomerInput);
      setSendList(result);
    }
  };

  const checkDate = () => {
    setDateError(undefined);

    // 送信
    if ((!dateCheck || dateCheck === undefined) && mailSendTime === undefined) {
      return true;
    }

    if (!dateCheck || dateCheck === undefined || mailSendTime === undefined) {
      setDateError('日時を入力してください');

      return false;
    }

    // 日付フォーマットチェック
    if (dateCheck && !validDate(dateCheck)) {
      setDateError('日付の形式で入力してください。');

      return false;
    }

    if (checkPastDay(dateCheck, mailSendTime.replace(':', ''))) {
      setDateError('日時が過ぎています');

      return false;
    }

    return true;
  };
  // 会社名の配列を作成
  useEffect(() => {
    const names = customers.length
      ? customers
          .map((item) => item.companyName)
          .filter((item) => item !== null)
      : [];
    names.unshift('');

    setCampanyNames(_.uniq(names));
  }, [customers]);
  // 顧客タグの配列を作成
  useEffect(() => {
    const names = customerTags?.customerTags.map((item) => item.tag) || ['　'];
    if (names !== ['　']) names.unshift('');
    setCustomerTagsName(_.uniq(names));
  }, [customerTags?.customerTags]);

  // 顧客情報をstateに格納
  useEffect(() => {
    if (!customersProps?.customers) {
      setCustomers([]);
    } else {
      setCustomers(customersProps.customers);
    }
  }, [customersProps]);

  // 送信先リスト追加＋顧客保存時の処理
  useEffect(() => {
    if (!latestCustomerDetail) return;
    const result = [
      ...sendList,
      { ...newCustomer, customerRegist: saveCustomer },
    ];
    setNewCustomer(initialCustomerInput);
    setSendList(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestCustomerDetail]);

  useEffect(() => {
    setCheckList([]);
    setAllChecked(false);
  }, [selectedTag, selectedCompany, searchWord]);

  // 複数ドメインチェック
  const checkDomain = () => {
    let check = false;
    sendList.forEach((list) => {
      const domain = list.mailAddress.split('@')[1];
      const res = sendList.filter((i) => {
        const check = i.mailAddress.split('@')[1];
        if (domain !== check) {
          return true;
        }

        return false;
      });

      if (res.length > 0) check = true;

      return false;
    });

    return check;
  };

  // 左側リスト部分
  const customerRow = React.memo(
    ({
      index,
      style,
    }: {
      index: number;
      style: React.CSSProperties | undefined;
    }) => (
      <Grid container style={style}>
        <Grid item xs={4} className={classes.listLabel}>
          <Tooltip title={filterCustomer[index].companyName || ''}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={checkList.includes(String(index))}
                  onChange={(e) => handleSetCheckList(e.target.value)}
                  value={index}
                />
              }
              label={filterCustomer[index].companyName}
              key={filterCustomer[index].customerId}
            />
          </Tooltip>
        </Grid>
        <Grid
          className={`${classes.gridItem} ${classes.listLabel} ${classes.listAlign}`}
          item
          xs={4}
        >
          <Tooltip title={filterCustomer[index].customerName || ''}>
            <Typography>{filterCustomer[index].customerName}</Typography>
          </Tooltip>
        </Grid>

        <Grid
          className={`${classes.gridItem} ${classes.listLabel} ${classes.listAlign}`}
          item
          xs={4}
        >
          <Tooltip title={filterCustomer[index].mailAddress || ''}>
            {filterCustomer[index]?.mailSendError ? (
              <Typography color="error">
                {filterCustomer[index].mailAddress}
              </Typography>
            ) : (
              <Typography>{filterCustomer[index].mailAddress}</Typography>
            )}
          </Tooltip>
        </Grid>
      </Grid>
    ),
  );

  // 右側リスト部分
  const sendRow = React.memo(
    ({
      index,
      style,
    }: {
      index: number;
      style: React.CSSProperties | undefined;
    }) => (
      <Grid container style={style}>
        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={3}
        >
          <Tooltip title={sendList[index].companyName || ''}>
            <Typography>{sendList[index].companyName}</Typography>
          </Tooltip>
        </Grid>
        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={4}
        >
          <Tooltip title={sendList[index].customerName || ''}>
            <Typography>{sendList[index].customerName}</Typography>
          </Tooltip>
        </Grid>

        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={4}
        >
          <Tooltip title={sendList[index].mailAddress || ''}>
            {sendList[index]?.mailSendError ? (
              <Typography color="error">
                {sendList[index].mailAddress}
              </Typography>
            ) : (
              <Typography>{sendList[index].mailAddress}</Typography>
            )}
          </Tooltip>
        </Grid>
        <Grid className={classes.gridItem} item xs={1}>
          <CloseIcon onClick={() => removeSendList(index)} />
        </Grid>
      </Grid>
    ),
  );

  // 送信画面リスト部分
  const sendListRow = React.memo(
    ({
      index,
      style,
    }: {
      index: number;
      style: React.CSSProperties | undefined;
    }) => (
      <Grid container key={index} style={style}>
        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={3}
        >
          <Tooltip title={sendList[index].companyName || ''}>
            <Typography>{sendList[index].companyName}</Typography>
          </Tooltip>
        </Grid>
        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={4}
        >
          <Tooltip title={sendList[index].customerName || ''}>
            <Typography>{sendList[index].customerName}</Typography>
          </Tooltip>
        </Grid>

        <Grid
          className={`${classes.gridItem} ${classes.sendListLabel}`}
          item
          xs={4}
        >
          <Tooltip title={sendList[index].mailAddress || ''}>
            {sendList[index]?.mailSendError ? (
              <Box color={theme.palette.red[900]}>
                <Typography>{sendList[index].mailAddress}</Typography>
              </Box>
            ) : (
              <Typography>{sendList[index].mailAddress}</Typography>
            )}
          </Tooltip>
        </Grid>
        <Grid className={classes.gridItem} item>
          <CloseIcon onClick={() => removeSendList(index)} />
        </Grid>
      </Grid>
    ),
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={checkChangeValues}
    >
      <ConfirmDialog
        buttonText="終了する"
        open={confirmDialogOpen}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={handleConfirmDialogClose}
        handleSubmit={handleConfirmDialogSubmit}
      />
      {/* <CloseIcon onClick={handleCancel} /> */}
      <DialogTitle id="customized-dialog-title">
        送信先を選択してください
      </DialogTitle>
      <Box display="flex" flexDirection="column" alignItems="center">
        {!step ? (
          <>
            {
            (filterCustomer?.find(
              (customer) => customer?.mailSendError === true,
            ) 
            || 
            sendList?.find(
              (list)=>list?.mailSendError === true,
            ) 
            ) && (
              <Box mb={3}>
                <SendErrorMailAddressWarningLabel text={MAIL_SENDER_LABEL} />
              </Box>
            )}

            <Grid container justify="space-evenly">
              <Grid item xs={6}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="リストから選択" />
                  <Tab label="自由入力" />
                </Tabs>
              </Grid>

              <Grid item xs={6}>
                送信先リスト
              </Grid>

              <Grid item xs={6}>
                <Box p={3}>
                  <Card
                    className={classes.card}
                    style={{ position: 'relative' }}
                  >
                    <Box hidden={tab !== 0}>
                      <Box className={classes.cardContent} overflow-y="scroll">
                        <Box pl={1.5} pr={1}>
                          <Grid container justify="space-between">
                            <Grid item className={classes.searchComboBox}>
                              <Typography
                                className={classes.title}
                                variant="caption"
                              >
                                会社名
                              </Typography>
                              {campanyNames.length ? (
                                <CommonComboBox
                                  handleChangeText={(text) =>
                                    setSelectedCompany(text)
                                  }
                                  options={campanyNames}
                                  value={selectedCompany}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                            <Grid item className={classes.searchComboBox}>
                              <Typography
                                className={classes.title}
                                variant="caption"
                              >
                                顧客タグ
                              </Typography>
                              {campanyNames.length ? (
                                <CommonComboBox
                                  value={selectedTag}
                                  options={customerTagsName}
                                  handleChangeText={handleChangeTag}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={1.5} pl={1.5} pr={1}>
                          <SearchTextField
                            label="検索ワードを入力"
                            value={searchWord}
                            handleChangeText={(text: string) =>
                              setSearchWord(text)
                            }
                          />
                        </Box>
                        <Box pr={1}>
                          <Box pl={1.5}>
                            <Box my={2}>
                              <Typography
                                className={classes.title}
                                variant="caption"
                              >
                                人名を選択してください
                              </Typography>
                            </Box>
                            <FormControlLabel
                              className={classes.checkbox}
                              control={
                                <Checkbox
                                  checked={allChecked}
                                  onChange={(e) => allSelect(e.target.checked)}
                                />
                              }
                              label="全て選択する"
                            />
                          </Box>
                          {filterCustomer.length > 0 && (
                            <Box style={{ height: 'calc(100vh - 550px)' }}>
                              <AutoSizer>
                                {({
                                  height,
                                  width,
                                }: {
                                  height: number;
                                  width: number;
                                }) => (
                                  <List
                                    height={height}
                                    itemCount={filterCustomer.length}
                                    itemSize={36}
                                    overscanCount={10}
                                    width={width}
                                    className={classes.listBox}
                                  >
                                    {customerRow}
                                  </List>
                                )}
                              </AutoSizer>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box hidden={tab !== 1}>
                      <Box className={classes.cardContent}>
                        <Grid
                          container
                          justify="center"
                          style={{ paddingTop: '8px' }}
                        >
                          <Grid item xs={3}>
                            <Box pr={2}>
                              <OneLineTextField
                                label="会社名"
                                error={companyNameError}
                                value={newCustomer?.companyName || ''}
                                handleChangeText={(text) => {
                                  setNewCustomer({
                                    ...newCustomer,
                                    companyName: text,
                                  });
                                  setCurrCompanyName(text);
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box pr={2}>
                              <OneLineTextField
                                label="名前"
                                error={nameError}
                                value={newCustomer?.customerName || ''}
                                handleChangeText={(text) => {
                                  setNewCustomer({
                                    ...newCustomer,
                                    customerName: text,
                                  });
                                  setCurrMailAddress(text);
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <OneLineTextField
                              label="メールアドレス"
                              error={emailError}
                              value={newCustomer?.mailAddress || ''}
                              handleChangeText={(text) => {
                                setNewCustomer({
                                  ...newCustomer,
                                  mailAddress: text,
                                });
                                setCurrMailAddress(text);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Box pt={3} mx="auto" width={204} height={36}>
                          <FormControlLabel
                            className={classes.checkbox}
                            control={
                              <Checkbox
                                checked={saveCustomer}
                                onChange={() => setSaveCustomer(!saveCustomer)}
                              />
                            }
                            label="顧客情報に保存する"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      position="absolute"
                      left={0}
                      right={0}
                      bottom={0}
                      bgcolor={theme.palette.gray[400]}
                    >
                      <Box
                        py={1}
                        mx="auto"
                        width={172}
                        height={36}
                        pb={2}
                        pt={1}
                      >
                        <PrimaryButton
                          text="送信先リストに追加"
                          click={() => {
                            handleChangeSendList(tab ? 'input' : 'check');
                            setCurrCompanyName('');
                            setCurrName('');
                            setCurrMailAddress('');
                          }}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box p={3}>
                  <Card className={classes.card}>
                    <Box
                      className={classes.cardContent}
                      style={{ marginRight: 0 }}
                    >
                      {sendList.length > 0 && (
                        <Box style={{ height: 'calc(100vh - 314px)' }}>
                          <AutoSizer>
                            {({
                              height,
                              width,
                            }: {
                              height: number;
                              width: number;
                            }) => (
                              <List
                                height={height}
                                itemCount={sendList.length}
                                itemSize={37}
                                overscanCount={10}
                                width={width}
                                className={classes.listBox}
                              >
                                {sendRow}
                              </List>
                            )}
                          </AutoSizer>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container justify="center">
            <Grid item xs={10}>
              <Box p={3}>
                <Typography variant="body2" className={classes.confirmTitle}>
                  メール送信設定しますか？
                </Typography>

                {sendList?.find(
                  (customer) => customer?.mailSendError === true,
                ) && (
                  <Box className={classes.warning}>
                    <Box
                      className={classes.warningContent}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <ErrorOutlineIcon />
                      </Box>
                      <Box>
                        <SendErrorMailAddressWarningLabel
                          text={MAIL_SENDER_LABEL}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {sendList.length >= 2 && checkDomain() && (
                  <Box className={classes.warning}>
                    <Box
                      className={classes.warningContent}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <ErrorOutlineIcon />
                      </Box>
                      <Box>
                        <Typography color="error">
                          宛先に複数のドメインが含まれていますがお間違い無いでしょうか？
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Card
                  className={classes.card}
                  style={{ height: 'calc(100vh - 393px)' }}
                >
                  <Box
                    className={classes.cardContent}
                    style={{ marginRight: 0 }}
                  >
                    {sendList.length > 0 && (
                      <Box style={{ height: 'calc(100vh - 441px)' }}>
                        <AutoSizer>
                          {({
                            height,
                            width,
                          }: {
                            height: number;
                            width: number;
                          }) => (
                            <List
                              height={height}
                              itemCount={sendList.length}
                              itemSize={37}
                              overscanCount={10}
                              width={width}
                              className={classes.listBox}
                            >
                              {sendListRow}
                            </List>
                          )}
                        </AutoSizer>
                      </Box>
                    )}
                  </Box>
                </Card>
              </Box>
            </Grid>
            <DateTimePicker
              subtitle="送信日時"
              width="332px"
              date={mailSendDate}
              time={mailSendTime}
              dateError={dateError}
              handleChangeDate={(date: string) =>
                handleChangeMailSendDate(date)
              }
              handleChangeTime={(time: string) =>
                handleChangeMailSendTime(time)
              }
            />
          </Grid>
        )}
        <DialogActions>
          {!step ? (
            <>
              <Box width={144} height={36} ml="auto">
                <SecondaryButton
                  click={checkChangeValues}
                  icon={<CloseIcon className={classes.closeIcon} />}
                  text="キャンセル"
                />
              </Box>
              <Box width={144} height={36} mr="auto">
                <PrimaryButton
                  text="決定"
                  click={() => {
                    if (!sendList.length) return;
                    setStep(1);
                  }}
                />
              </Box>
            </>
          ) : (
            <Grid container justify="center">
              <Grid item xs={5}>
                <Box className={classes.confirmBtn}>
                  <SecondaryButton text="戻る" click={() => setStep(0)} />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box className={classes.confirmBtn}>
                  <PrimaryButton
                    disabled={isSubmitting}
                    text="送信する"
                    click={() => {
                      setIsSubmitting((prev) => !prev);
                      setTimeout(() => {
                        setIsSubmitting((prev) => !prev);
                      }, 1000);
                      const dateValid = checkDate();
                      if (!sendList.length || !dateValid) return;
                      const date = mailSendDate || '';
                      const time = mailSendTime?.replace(':', '') || '';
                      setMailSendDate(date);
                      setMailSendTime(time);
                      handleSubmit(
                        date,
                        time,
                        sendList.map((item) => {
                          const isSelectedList = item.customerRegist === null;

                          return {
                            customerId: item.customerId,
                            customerName: isSelectedList
                              ? null
                              : item.customerName,
                            companyName: isSelectedList
                              ? null
                              : item.companyName,
                            mailAddress: isSelectedList
                              ? null
                              : item.mailAddress,
                            customerRegist: item.customerRegist,
                          };
                        }),
                      );
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          <IconButton
            className={classes.closeButton}
            onClick={checkChangeValues}
          >
            <CloseWhiteIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LPMailDialog;

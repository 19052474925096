import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import InquiryDetailPage from 'components/pages/InquiryDetail';
import { Usecases, Modules } from 'core';
import { hooks } from 'libs';
import { useParams } from 'react-router-dom';
import { InquirySupportsRegisterRequest } from 'core/domain/inquiries';
import { clearInquiryDetail } from 'core/modules/inquiryDetail';
import { clearInquirySupports } from 'core/modules/inquirySupports';
import { CODE_MODE_REFERENCE } from 'constants/code';
import { clearInquiriesForm } from 'core/modules/inquiriesForm';

/**
 * Presenter
 */

const InquiryDetailPageContainer = () => {
  const dispatch = useDispatch();
  const inquiryDetail = useSelector(
    (state: Modules.AppState) => state.inquiryDetail,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  // const inquirySupports = useSelector(
  //   (state: Modules.AppState) => state.inquirySupports,
  // );
  const token = hooks.useAuth().accessToken;
  const { inquiryId } = useParams<{ inquiryId: string }>();

  const handleDeleteInquiryDetail = () => {
    if (!userInfo) return;

    dispatch(
      Usecases.inquiries.sendDeleteInquiryDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(inquiryId, 10),
      ),
    );
  };

  /**
   * お問い合わせ対応登録・更新処理
   * @param request リクエストパラメーター
   * @returns
   */
  const handleRegisterSupport = (request: InquirySupportsRegisterRequest) => {
    if (!userInfo || !inquiryDetail) return;
    if (request.inquirySupportId) {
      dispatch(
        Usecases.inquiries.sendPutInquirySupport(
          token,
          userInfo.tenantId,
          inquiryDetail?.inquiryId,
          request.inquirySupportId,
          request,
        ),
      );
    } else {
      dispatch(
        Usecases.inquiries.sendPostInquirySupport(
          token,
          userInfo.tenantId,
          inquiryDetail?.inquiryId,
          request,
        ),
      );
    }
  };

  // お問い合わせ詳細/お問い合わせ対応一覧/お問い合わせ項目一覧取得
  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      Usecases.inquiries.sendGetInquiryDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(inquiryId, 10),
      ),
    );

    dispatch(
      Usecases.inquiries.sendGetInquirySupports(
        token,
        userInfo.tenantId,
        Number.parseInt(inquiryId, 10),
      ),
    );

    dispatch(
      Usecases.settings.sendPostInquiriesForm(
        token,
        userInfo.tenantId,
        CODE_MODE_REFERENCE,
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearInquiryDetail());
      dispatch(clearInquirySupports());
      dispatch(clearInquiriesForm());
    };
  }, [dispatch, token, userInfo, inquiryId]);

  // お問い合わせ詳細取得
  // useEffect(() => {
  //   if (!userInfo || !inquiryId) return;
  //   dispatch(
  //     Usecases.inquiries.sendGetInquiryDetail(
  //       token,
  //       userInfo.tenantId,
  //       Number.parseInt(inquiryId, 10),
  //     ),
  //   );
  // }, [dispatch, token, userInfo, inquiryId]);

  // ヘッダーバーの未読件数を更新
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.inquiries.sendGetInquiries(token, userInfo.tenantId));
  }, [userInfo, token, inquiryId, dispatch, inquiryDetail]);

  return (
    <InquiryDetailPage
      handleRegisterSupport={handleRegisterSupport}
      handleDeleteInquiryDetail={handleDeleteInquiryDetail}
    />
  );
};

export default InquiryDetailPageContainer;

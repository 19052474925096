/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useNavigate = () => {
  const history = useHistory()

  const navigate = useCallback(
    (path: string, state?: unknown) => {
      history.push({
        pathname: path,
        state,
      })
    },
    [history],
  )

  const navigateReplace = useCallback(
    (path: string, state?: unknown) => {
      history.replace({
        pathname: path,
        state,
      })
    },
    [history],
  )

  const navigatePop = useCallback(
    () => {
      history.goBack()
    },
    [history],
  )

  const block = useCallback(
    (callback:(pathname:string) => string | false | void) => history.block((location) => {
        const res = callback(location.pathname)
        if(res || typeof res === 'boolean'){
          return res
        }
          
        
      }),
    [history],
  )

  return {
    navigate,
    navigateReplace,
    navigatePop,
    block,
  }
}
/* eslint-enable */

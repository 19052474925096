/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, createMuiTheme, ThemeProvider, Theme } from '@material-ui/core/styles'
import { TextField, Typography } from 'components/atoms'
import { COLORS } from 'constants/index'
import { SALAD_BAR_DESKTOP_FONT_SIZE_75, SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography'
import { ErrorIcon } from 'assets/images'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

/**
 * Interface
 */

export interface OneLineTextFieldProps {
  label?: string
  value?: string
  handleChangeText?: (text: string) => void
  handleBlur?: (text: string) => void;
  error?: string
  disabled?:boolean
  maxLength?: number
  style?:React.CSSProperties
}

/**
 * Style
 */
export const theme = createMuiTheme({
  overrides: {
    // For Container
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&:hover $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&$focused $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        height: 36
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_900,
        }
      }
    },
  },
})


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      '&, .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.green[700]
      },
    },
    input: {
      height: 20,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:focus:not($disabled):not($error)': {
        backgroundColor: theme.palette.green[700]
      },
    },
    errorRoot: {
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        backgroundColor: theme.palette.red[800]
      },
    },
    errorInput: {
      color: theme.palette.red[900],
      '&:focus': {
        backgroundColor: theme.palette.red[800]
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900]
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
)

/**
 * Presenter
 */

export default function OneLineTextField(props: OneLineTextFieldProps) {
  const { label, value, handleChangeText, handleBlur, error, disabled, maxLength, style } = props
  const classes = useStyles({})

  const handleChangeValue = (value: string) => {
    if (!handleChangeText) return
    handleChangeText(value)
  }

  const handleBlurValue = (value: string) => {
    if (!handleBlur) return
    handleBlur(value)
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        style={style}
        disabled={disabled}
        error={!!error}
        color="primary"
        inputProps={{ className: clsx(classes.input, {
          [classes.errorInput]: !!error
        }), maxLength}}
        className={clsx(classes.root, {
          [classes.errorRoot]: !!error
        })}
        placeholder={label}
        variant="outlined"
        value={value || ''}
        fullWidth
        onChange={(event) => handleChangeValue(event.target.value)}
        onBlur={(event) => handleBlurValue(event.currentTarget.value)}
      />
      {error && <Box className={classes.error}>
        <ErrorIcon />
        <Typography className={classes.errorText}>{error}</Typography>
      </Box>
      }
    </ThemeProvider>
  )
}/* eslint-enable */

import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Link } from 'components/atoms'
import { Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'

/**
 * Interface
 */

type IconPosition = "start" | "end"

export interface ButtonLinkProps{
  text: string,
  icon?: React.ReactNode,
  iconPosition? : IconPosition
  tooltipText?: string
  click?: () => void
  fontSize?: string
  disabled?: boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      justifyContent: 'center',
    },
    link: {
      color: theme.palette.gray[800],
      cursor: 'pointer',
      marginRight: 4,
      wordBreak: 'break-all',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
    text:{
      textAlign: 'center',
      fontSize: (props:ButtonLinkProps)=>props.fontSize
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
)

/**
 * Presenter
 */

const TextTypeButton = (props: ButtonLinkProps) => {

  const classes = useStyles(props)
  const { text, icon, iconPosition, tooltipText, click, disabled } = props

  const link =
    <Box component="span" display="flex" alignItems="center" className={classes.root}>
      {iconPosition === "start" && <>{icon}</>}
      <Link underline="always" className={clsx(classes.link,{[classes.disabled]:disabled})} onClick={click}><Typography component="span" variant="body2" className={classes.text}>{text}</Typography></Link>
      {iconPosition === "end" && <>{icon}</>}
    </Box>

  return tooltipText ?
    (
      <Tooltip title={tooltipText} arrow placement="top">
        {link}
      </Tooltip>
    ) : link
}
export default TextTypeButton;
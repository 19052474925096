import React, { useEffect, useMemo, useReducer } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  OneLineTextField,
  PrimaryButton,
} from 'components/atoms';
import SubHeadlineLabel from 'components/atoms/HeadlineLabel';
import {
  CircleArrowDownIcon,
  CircleArrowTopIcon,
  CloseIcon,
  NoImage,
} from 'assets/images';

interface ImageCardItemProps {
  data: string | undefined;
  displayOrder: number;
  error?: string;
  hasUrl?: boolean;
  handleFileDelete: (deleteId: number) => void;
  handleOrderChange: (currOrder: number, type: 'up' | 'down') => void;
  handleSetURLChange?: (currOrder: number, url: string) => void;
  handleUpdateFileClick: (currOrder: number) => void;
  isBottom: boolean;
  isTop: boolean;
  url?: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginTop: 8,
      marginBottom: 8,
      boxShadow: 'none',
      borderColor: theme.palette.gray[600],
      borderStyle: 'solid',
      borderWidth: 1,
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    img: {
      margin: 24,
      width: 265,
      height: 163,
      objectFit: 'contain',
    },
    sortButton: {
      '&:disabled span svg g': {
        fill: theme.palette.gray[500],
        stroke: theme.palette.gray[400],
      },
      '&:disabled span svg path': {
        stroke: theme.palette.common.white,
      },
    },
    urlText: {
      '&>div, input': {
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
  }),
);

const ImageCardItem = (props: ImageCardItemProps) => {
  const {
    data,
    displayOrder,
    error,
    hasUrl,
    handleFileDelete,
    handleOrderChange,
    handleSetURLChange,
    handleUpdateFileClick,
    isBottom,
    isTop,
    url,
  } = props;
  const classes = useStyles();
  const [urlValue, handleUrlChange] = useReducer(
    (_: string, value: string) => value,
    url || '',
  );

  const markImageUrl = useMemo(() => {
    if (data) return data;

    return NoImage;
  }, [data]);

  const handleSetUrlBlurChange = (v: string) => {
    if (handleSetURLChange) handleSetURLChange(displayOrder, v);
  };

  const handleUpdateBtn = () => {
    handleUpdateFileClick(displayOrder);
  };

  const handleUpBtn = () => {
    handleOrderChange(displayOrder, 'up');
  };

  const handleDownBtn = () => {
    handleOrderChange(displayOrder, 'down');
  };

  const handleDeleteBtn = () => {
    handleFileDelete(displayOrder);
  };

  useEffect(() => {
    handleUrlChange(url || '');
  }, [url, displayOrder]);

  return (
    <Card className={classes.root}>
      <img src={markImageUrl} className={classes.img} />
      <CardContent className={classes.content}>
        <Box mt={1} width={160} height={36}>
          <PrimaryButton text="画像をアップロード" click={handleUpdateBtn} />
        </Box>
        {hasUrl ? (
          <Box mt={4}>
            <SubHeadlineLabel text="URL" />
            <Box mt={1} className={classes.urlText}>
              <OneLineTextField
                error={error}
                value={urlValue}
                handleChangeText={handleUrlChange}
                handleBlur={handleSetUrlBlurChange}
                maxLength={1000}
              />
            </Box>
          </Box>
        ) : null}
      </CardContent>
      <Box display="flex" flexDirection="column" mt={2}>
        <IconButton onClick={handleDeleteBtn}>
          <CloseIcon />
        </IconButton>
        <IconButton
          className={classes.sortButton}
          onClick={handleUpBtn}
          disabled={isTop}
        >
          <CircleArrowTopIcon />
        </IconButton>
        <IconButton
          className={classes.sortButton}
          onClick={handleDownBtn}
          disabled={isBottom}
        >
          <CircleArrowDownIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

export default ImageCardItem;

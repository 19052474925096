import React from 'react';
import { Box, ButtonLink, Typography } from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface DashboardHeadlineLabelProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: '2px solid #48A45A',
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
);

const DashboardHeadlineLabel = (props: DashboardHeadlineLabelProps) => {
  const { title } = props;
  const classes = useStyles();

  return (
    <Box display="flex" mt={5}>
      <Typography
        className={classes.title}
        color="primary"
        component="h4"
        variant="h4"
      >
        {title}
      </Typography>
      <ButtonLink text="解説" fontSize="14px" />
    </Box>
  );
};

export default DashboardHeadlineLabel;

import React from 'react';
import {
  LetterCountLabel,
  MultipleLinesTextField,
  OneLineTextField,
} from 'components/atoms';

interface LetterCountTextFieldProps {
  error?: string | undefined;
  handleChangeText: (v: string) => void;
  label?: string;
  limit: string;
  maxLength?: number;
  multiline?: boolean;
  // 必要に応じて型は増やす。
  value: string;
}

const LetterCountTextField = (props: LetterCountTextFieldProps) => {
  const {
    error,
    handleChangeText,
    label,
    limit,
    maxLength,
    multiline = false,
    value,
  } = props;
  const inputLabel = label ? `${label}を入力` : '';

  return (
    <>
      {multiline ? (
        <MultipleLinesTextField
          error={error}
          value={value}
          handleChangeText={handleChangeText}
          label={inputLabel}
          maxLength={maxLength}
          rows={3}
        />
      ) : (
        <OneLineTextField
          error={error}
          value={value}
          handleChangeText={handleChangeText}
          label={inputLabel}
          maxLength={maxLength}
        />
      )}
      <LetterCountLabel limit={limit} count={`${value.length}` || '0'} />
    </>
  );
};

export default LetterCountTextField;

import React from 'react';
import { Box, InquirySelectBox, SubHeadlineLabel } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { inquiryItemOptions } from 'core/domain/inquiries';

interface InquiryPullDownFieldProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
  title: string;
  options: inquiryItemOptions[] | undefined;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
  }),
);

const InquiryPullDownField = (props: InquiryPullDownFieldProps) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
    title,
    options,
    disabled,
  } = props;
  const classes = useStyles();

  const setOptions = () => {
    const tmp: { value: string; label: string; }[] = [];
    options?.forEach((value) => {
      tmp.push({value: value.displayOptionName, label: value.displayOptionName})
    })

    return tmp
  }

  return (
    <Box
      className={`${classes.root} ${
        isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
      }`}
      display="flex"
      alignItems="center"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title={title} />
      ) : (
        <SubHeadlineLabel text={title} />
      )}
      <Box>
        {defaultValue ? (
          <InquirySelectBox
            defaultValue={defaultValue}
            error={error}
            label=""
            options={setOptions()}
            handleChange={(v: string) => {
              onChange(inquiryItemId, title, v);
            }}
            disabled={disabled}
          />
        ) : (
          <InquirySelectBox
            defaultValue={defaultValue}
            error={error}
            label=""
            options={setOptions()}
            handleChange={(v: string) => {
              onChange(inquiryItemId, title, v);
            }}
            disabled={disabled}
          />
        )}
      </Box>
    </Box>
  );
};

export default InquiryPullDownField;

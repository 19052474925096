import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { checkPastDay } from 'libs/date';
import { Notice } from 'core/domain/notices';
import HeaderDropMenu from '../HeaderDropMenu';
import { NotificationIcon } from '../../../assets/images';
import NotificationBox from '../NotificationBox';

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    headerMenu: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
    },
  }),
);

const compareBase = (a: string, b: string): number => {
  let comparison = 0;
  if (a > b) {
    comparison = -1;
  } else if (a < b) {
    comparison = 1;
  }

  return comparison;
};

type compareFunc = (a: Notice, b: Notice) => number;

const comparePublishDate: compareFunc = (a, b) =>
  compareBase(
    a.publishStartDate && a.publishStartTime
      ? a.publishStartDate + a.publishStartTime
      : '19700000',
    b.publishStartDate && b.publishStartTime
      ? b.publishStartDate + b.publishStartTime
      : '19700000',
  );

/**
 * Presenter
 */

const HeaderBarNotification = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { navigate } = hooks.useNavigate();
  const name = '通知';
  const headerName = 'お知らせ';
  const listButtonName = 'お知らせ一覧を見る';
  const disable = false;

  const notices = useSelector(
    (state: Modules.AppState) => state.headerNotices,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  // ログインしているかチェックし、しているなら該当テナントの通知取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.notices.sendGetHeaderNotices(token, userInfo.tenantId));
  }, [dispatch, token, userInfo]);

  const filterdAndSortedNotices = useMemo(() => {
    if (!notices || !notices.notices) return [];

    const filteredNotices = notices.notices.filter(
      (notice) =>
        notice.unread &&
        !checkPastDay(notice.publishEndDate, notice.publishEndTime),
    );

    return filteredNotices.sort(comparePublishDate);
  }, [notices]);

  const unreadCount = useMemo(() => {
    if (!notices) return 0;

    return filterdAndSortedNotices.length;
  }, [filterdAndSortedNotices, notices]);

  const filteredItem = React.memo((
    { index, style }:
    { index: number, style: React.CSSProperties | undefined }
  ) => (
    <div style={style}>
      <NotificationBox notice={filterdAndSortedNotices[index]} key={index} />
    </div>
  ));

  return (
    <div className={classes.headerMenu}>
      <HeaderDropMenu
        name={name}
        headerName={headerName}
        logo={<NotificationIcon />}
        listButtonName={listButtonName}
        unreadCount={unreadCount}
        disable={disable}
        handleClickListHeaderButton={() => navigate('/adm/notices')}
        filteredItem={filteredItem}
        filteredItemLength={filterdAndSortedNotices.length}
      />
    </div>
  );
};

export default HeaderBarNotification;

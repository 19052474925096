import React from 'react';
import { Box, HeadlineLabel } from 'components/atoms';
import { VariousSettings } from 'core/domain/variousSettings';
import { TagInputField } from 'components/molecules';

interface VariousSettingsLpTagManagerFieldAreaProps {
  bodyValue: string;
  handleInputChange: (v: string, key: keyof VariousSettings) => void;
  headValue: string;
}

const VariousSettingsLpTagManagerFieldArea = (
  props: VariousSettingsLpTagManagerFieldAreaProps,
) => {
  const { bodyValue, handleInputChange, headValue } = props;

  const handleHeadChange = (v: string) => {
    handleInputChange(v, 'tagManagerHead');
  };

  const handleBodyChange = (v: string) => {
    handleInputChange(v, 'tagManagerBody');
  };

  return (
    <Box mt={5}>
      <HeadlineLabel text="公開ランディングページ埋め込みタグ" />
      <TagInputField
        label="HEADタグ"
        value={headValue}
        handleChange={handleHeadChange}
        maxLength={1000}
        multiline
      />
      <TagInputField
        label="BODYタグ"
        value={bodyValue}
        handleChange={handleBodyChange}
        maxLength={1000}
        multiline
      />
    </Box>
  );
};

export default VariousSettingsLpTagManagerFieldArea;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  // Tooltip,
  Typography,
  PrimaryButton,
  NoticeDetailStatusLabel,
  Tooltip,
} from 'components/atoms';
import { Modules } from 'core';
import { useSelector, shallowEqual } from 'react-redux';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { NoticesSearchCondition } from 'core/domain/notices';
import {
  formateTimeHHMMColon,
  formatDateYYYYMMDDSeparateSlash,
} from 'libs/date';


/**
 * Interface
 */

export interface ManageNoticesTableProps {
  clickManageNotice?: (id: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  noticesSearchCondition: NoticesSearchCondition | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1000,
      tableLayout: 'auto',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    overflow: {
      '&>p': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
  minWidth?: number;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  // noticeStatus：お知らせ掲載ステータス, subject：件名, createdUser：登録者, publishStartDateTime：掲載開始日時, publishEndDateTime：掲載終了日時） <

  {
    title: 'ステータス',
    sortKey: 'noticeStatus',
    width: '110px',
  },
  {
    title: '件名',
    sortKey: 'subject',
    width: '180px',
  },
  {
    title: '登録者',
    sortKey: 'createdUser',
    width: '150px',
  },
  {
    title: '掲載開始日時',
    sortKey: 'publishStartDateTime',
    width: '150px',
  },
  {
    title: '掲載終了日時',
    sortKey: 'publishEndDateTime',
    width: '150px',
  },
  {
    title: '',
    sortKey: '',
    width: '150px',
  },
];

const ManageNoticesTable = (props: ManageNoticesTableProps) => {
  const classes = useStyles({});
  const noticeDetails = useSelector(
    (state: Modules.AppState) => state.noticeDetails,
    shallowEqual,
  );
  const { clickManageNotice, handleChangeSortKey, noticesSearchCondition } =
    props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <colgroup>
          {TABLE_HEADER_ITEMS.map((item, index ) => (
            <col key={`colWidth_${index}`} width={item.width} style={item.minWidth ? {minWidth: item.minWidth} : undefined} />
          ))}
        </colgroup>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {item.sortKey !== '' ? (
                  <TableSortLabel
                    active
                    direction={noticesSearchCondition?.sort_order}
                    IconComponent={() =>
                      item.sortKey === noticesSearchCondition?.sort_key &&
                      noticesSearchCondition?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => {
                      handleChangeSortKey(item.sortKey);
                    }}
                  >
                    {item.title}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} hideSortIcon>
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {noticeDetails &&
            noticeDetails.notices &&
            noticeDetails.notices.map((notice, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box>
                    <NoticeDetailStatusLabel
                      status={notice?.noticeStatus ?? 4}
                    />
                  </Box>
                  {/* 1「掲載予約」2「掲載中」３「掲載終了」 */}
                </TableCell>

                <TableCell className={classes.overflow}>
                  {/* 件名 */}
                  <Tooltip title={notice.subject || ''}>
                    <Typography variant="body2">{notice.subject}</Typography>
                  </Tooltip>
                </TableCell>

                <TableCell className={classes.overflow}>
                  {/* 登録者 */}
                  <Tooltip title={notice.noticeCreatedUser?.userName || ''}>
                    <Typography variant="body2">
                      {notice.noticeCreatedUser &&
                        notice.noticeCreatedUser.userName}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  {/* 掲載開始日時 */}
                  {`${formatDateYYYYMMDDSeparateSlash(
                    notice.publishStartDate,
                  )}`}
                  {` ${formateTimeHHMMColon(notice.publishStartTime)}`}
                </TableCell>

                <TableCell>
                  {/* 掲載終了日時 */}
                  {`${formatDateYYYYMMDDSeparateSlash(notice.publishEndDate)}`}
                  {` ${formateTimeHHMMColon(notice.publishEndTime)}`}
                </TableCell>

                <TableCell>
                  {/* 詳細へのリンクボタン */}
                  <PrimaryButton
                    text="詳細"
                    click={() => {
                      if (clickManageNotice && notice.noticeId)
                        clickManageNotice(notice.noticeId);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ManageNoticesTable;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  SubHeadlineLabel,
  Typography,
} from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_200,
} from 'constants/typography';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { ErrorIcon } from 'assets/images';

interface InquirySendingMaterialsFieldProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    formLabel: {
      display: 'block',
      marginBottom: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkbox: {
      '& .MuiIconButton-label': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const InquirySendingMaterialsField = (
  props: InquirySendingMaterialsFieldProps,
) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
  } = props;
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState<boolean>(!!defaultValue);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0 || !defaultValue) return;

    setIsChecked(!!defaultValue);
    setCount((prev) => prev + 1);
  }, [count, defaultValue]);

  return (
    <Box
      className={`${classes.root} ${
        isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
      }`}
      display="flex"
      alignItems="start"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title="資料送付" />
      ) : (
        <SubHeadlineLabel text="資料送付" />
      )}
      <Box>
        <FormControlLabel
          className={classes.formLabel}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                const fieldValue = !isChecked ? '資料送付を希望する' : '';
                onChange(inquiryItemId, '資料送付', fieldValue);
              }}
            />
          }
          label="資料送付を希望する"
        />
        {error && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>{error}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InquirySendingMaterialsField;

import { Notices } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): Notices | null => initialState;

export const SET_TENANTDASHBOARD_NOTICES =
  'tenantDashboardNotices/set' as const;
export const CLEAR_TENANTDASHBOARD_NOTICES =
  'tenantDashboardNotices/clear' as const;

export const setTenantDashboardNotices = (data: Notices) => ({
  type: SET_TENANTDASHBOARD_NOTICES,
  payload: {
    data,
  },
});

export const clearTenantDashboardNotices = () => ({
  type: CLEAR_TENANTDASHBOARD_NOTICES,
});

type SetTenantDashboardNoticesAction = ReturnType<
  typeof setTenantDashboardNotices
>;
type ClearTenantDashboardNoticesAction = ReturnType<
  typeof clearTenantDashboardNotices
>;
type Action =
  | SetTenantDashboardNoticesAction
  | ClearTenantDashboardNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TENANTDASHBOARD_NOTICES:
      return action.payload.data;
    case CLEAR_TENANTDASHBOARD_NOTICES:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Modules } from 'core'

interface LpCreateModeSelectionControl {
  show: boolean
  disabled: boolean
}

export const useLpCreateModeSelectionPermission = () => {

  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)

  // 通常作成を開始
  const lpNewCreatePermission: LpCreateModeSelectionControl = useMemo(() => {
    if (!userInfo) {
      return {
        show: true,
        disabled: true,
      }
    }
    if (userInfo.lpAuthor || userInfo.lpApprover || userInfo.tenantAdmin) {
      return {
        show: true,
        disabled: false,
      }
    }

    return {
      show: true,
      disabled: true,
    }
  }, [userInfo])

  return {
    lpNewCreatePermission,
  }
}/* eslint-enable */

import {
  LpDetail,
  LpView,
  ProductView,
  ProductExampleView,
  InquiryFormView,
  LPRegisterRequest,
} from 'core/domain/lp';
import { ProductDetail, ProductRegisterRequest } from 'core/domain/product';
import { ExampleDetail, ExampleRegisterRequest } from 'core/domain/example';
import { MaterialImage } from 'core/domain/material';
import {
  InquiryForm,
  LpSettings,
  InquiriesGreetText,
} from 'core/domain/settings';
import { CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK } from 'constants/code';

export const convertLpDetailToLpView = (
  lpDetail: LpDetail,
  productDetails: Array<ProductDetail>,
  exampleDetails: Array<ExampleDetail>,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  const products =
    lpDetail.products &&
    lpDetail.products.map((product) => {
      const productDetail = productDetails.find(
        (productDetail) => productDetail.productId === product.productId,
      );
      if (!productDetail) return undefined;

      const productView: ProductView = {
        hashProductId: `${productDetail.productId}`,
        productName: productDetail.productName,
        catchCopy: productDetail.catchCopy,
        subCopy: productDetail.subCopy,
        productContent: productDetail.productContent,
        productImages: productDetail.productImages,
        productExternalSite: productDetail.productExternalSite,
        productSalesImage: {
          lpProductImageId: product.productSalesImage?.lpProductImageId,
          url: product.productSalesImage?.url,
        },
        comment: product.comment,
        productPeriod: productDetail.productPeriod,
        examples: [],
      };

      productView.examples =
        product && product.exampleIds && product.exampleIds.length > 0
          ? product.exampleIds
              .map((id) => {
                const example = exampleDetails.find(
                  (exampleDetail) => id === exampleDetail.exampleMaterialId,
                );
                const productExampleView: ProductExampleView = {
                  exampleName: example?.exampleName,
                  customerName: example?.customerName,
                  exampleContent: example?.exampleContent,
                  exampleImage: example?.exampleImage,
                  materialPeriod: example?.materialPeriod,
                };

                return productExampleView;
              })
              .filter((product) => product.exampleName !== undefined)
          : [];

      return productView;
    });

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    if (inquiryForm.inquiryItemId === 15) {
      inquiryForm.interested = productDetails.map((productDetail) => ({
        id: '',
        name: productDetail.productName,
      }));
    }

    return inquiryForm;
  });

  const lpView: LpView = {
    pageTitle: lpDetail.title,
    header: lpDetail.header,
    tokuisakiName: lpDetail.tokuisakiName,
    tokuisakiDisplayed: lpDetail.tokuisakiDisplayed,
    tokuisakiFixedPhraseText: lpDetail.tokuisakiFixedPhraseText,
    tokuisakiTitle: lpDetail.tokuisakiTitle,
    lpMainImage: lpDetail.lpMainImage,
    tantoName: lpDetail.tantoName,
    tantoImage: lpDetail.tantoImageImage,
    tantoCompany: lpDetail.tantoCompany,
    profileFixedPhraseText: lpDetail.profileFixedPhraseText,
    profileDisplayed: lpDetail.profileDisplayed,
    greetText: lpDetail.greetText,
    beginningText: lpDetail.beginningText,
    products: products as Array<ProductView>,
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: lpDetail.openPeriod,
  };

  return lpView;
};

export const convertProductDetailToLpView = (
  productDetail: ProductDetail,
  exampleDetails: Array<ExampleDetail>,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  const productView: ProductView = {
    hashProductId: `${productDetail.productId}`,
    productName: productDetail.productName,
    catchCopy: productDetail.catchCopy,
    subCopy: productDetail.subCopy,
    productContent: productDetail.productContent,
    productImages: productDetail.productImages,
    productExternalSite: productDetail.productExternalSite,
    productSalesImage: undefined,
    comment: undefined,
    examples: [],
  };

  productView.examples =
    exampleDetails.length > 0
      ? exampleDetails.map((example) => {
          const productExampleView: ProductExampleView = {
            exampleName: example.exampleName,
            customerName: example.customerName,
            exampleContent: example.exampleContent,
            exampleImage: example.exampleImage,
            materialPeriod: example.materialPeriod,
          };

          return productExampleView;
        })
      : [];

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    if (inquiryForm.inquiryItemId === 15) {
      inquiryForm.interested = [
        {
          id: '',
          name: productDetail.productName,
        },
      ];
    }

    return inquiryForm;
  });

  const lpView: LpView = {
    pageTitle: undefined,
    header: undefined,
    tokuisakiName: undefined,
    tokuisakiDisplayed: false,
    tokuisakiFixedPhraseText: undefined,
    tokuisakiTitle: undefined,
    lpMainImage: undefined,
    tantoName: undefined,
    tantoImage: undefined,
    tantoCompany: undefined,
    profileFixedPhraseText: undefined,
    profileDisplayed: false,
    greetText: undefined,
    beginningText: undefined,
    products: [productView],
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

export const convertExampleDetailToLpView = (
  exampleDetail: ExampleDetail,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  const productView: ProductView = {
    hashProductId: '',
    productName: '',
    catchCopy: '',
    subCopy: '',
    productContent: '',
    productImages: [],
    productExternalSite: undefined,
    productSalesImage: undefined,
    comment: '',
    examples: [],
  };

  productView.examples = [
    {
      exampleName: exampleDetail.exampleName,
      customerName: exampleDetail.customerName,
      exampleContent: exampleDetail.exampleContent,
      exampleImage: exampleDetail.exampleImage,
    },
  ];

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    return inquiryForm;
  });

  const lpView: LpView = {
    pageTitle: undefined,
    header: undefined,
    tokuisakiName: undefined,
    tokuisakiDisplayed: false,
    tokuisakiFixedPhraseText: undefined,
    tokuisakiTitle: undefined,
    lpMainImage: undefined,
    tantoName: undefined,
    tantoImage: undefined,
    tantoCompany: undefined,
    profileFixedPhraseText: undefined,
    profileDisplayed: false,
    greetText: undefined,
    beginningText: undefined,
    products: [productView],
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

export const convertNewLpToLpView = (
  lpRegisterRequest: LPRegisterRequest,
  productDetails: Array<ProductDetail>,
  exampleDetails: Array<ExampleDetail>,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  const products =
    lpRegisterRequest.products &&
    lpRegisterRequest.products.map((product) => {
      const productDetail = productDetails.find(
        (productDetail) => productDetail.productId === product.productId,
      );
      if (!productDetail) return undefined;

      const productView: ProductView = {
        hashProductId: `${productDetail.productId}`,
        productName: productDetail.productName,
        catchCopy: productDetail.catchCopy,
        subCopy: productDetail.subCopy,
        productContent: productDetail.productContent,
        productImages: productDetail.productImages,
        productExternalSite: productDetail.productExternalSite,
        productSalesImage: {
          lpProductImageId: product.productSalesImage?.lpProductImageId,
          url: product.productSalesImage?.lpProductImageData
            ? product.productSalesImage?.lpProductImageData
            : product.productSalesImage?.url,
        },
        comment: product.comment,
        productPeriod: productDetail.productPeriod,
        examples: [],
      };

      productView.examples =
        product && product.exampleIds && product.exampleIds.length > 0
          ? product.exampleIds
              .map((id) => {
                const example = exampleDetails.find(
                  (exampleDetail) => id === exampleDetail.exampleMaterialId,
                );
                const productExampleView: ProductExampleView = {
                  exampleName: example?.exampleName,
                  customerName: example?.customerName,
                  exampleContent: example?.exampleContent,
                  exampleImage: example?.exampleImage,
                  materialPeriod: example?.materialPeriod,
                };

                return productExampleView;
              })
              .filter((product) => product.exampleName !== undefined)
          : [];

      return productView;
    });

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    if (inquiryForm.inquiryItemId === 15) {
      inquiryForm.interested = productDetails.map((productDetail) => ({
        id: '',
        name: productDetail.productName,
      }));
    }

    return inquiryForm;
  });

  let tantoImage: string | undefined = '';
  if (lpRegisterRequest.tantoImageData) {
    tantoImage = lpRegisterRequest.tantoImageData.lpTantoImageData
      ? lpRegisterRequest.tantoImageData.lpTantoImageData
      : lpRegisterRequest.tantoImageData.url;
  }

  const lpView: LpView = {
    pageTitle: lpRegisterRequest.title,
    header: lpRegisterRequest.header,
    tokuisakiName: lpRegisterRequest.tokuisakiName,
    tokuisakiDisplayed: lpRegisterRequest.tokuisakiDisplayed,
    tokuisakiFixedPhraseText: lpRegisterRequest.tokuisakiFixedPhraseText,
    tokuisakiTitle: lpRegisterRequest.tokuisakiTitle,
    lpMainImage: {
      lpMainImageId: 0,
      url:
        lpRegisterRequest.lpMainImageData &&
        lpRegisterRequest.lpMainImageData.url
          ? lpRegisterRequest.lpMainImageData.url
          : '',
    },
    tantoName: lpRegisterRequest.tantoName,
    tantoImage: {
      lpTantoImageId: 0,
      url: tantoImage || '',
    },
    tantoCompany: lpRegisterRequest.tantoCompany,
    profileFixedPhraseText: lpRegisterRequest.profileFixedPhraseText,
    profileDisplayed: lpRegisterRequest.profileDisplayed,
    greetText: lpRegisterRequest.greetText,
    beginningText: lpRegisterRequest.beginningText,
    products: products as Array<ProductView>,
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

export const convertNewProductToLpView = (
  productRegisterRequest: ProductRegisterRequest | null,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  let productView: ProductView | undefined;
  if (productRegisterRequest) {
    productView = {
      hashProductId: undefined,
      productName: productRegisterRequest.productName,
      catchCopy: productRegisterRequest.catchCopy,
      subCopy: productRegisterRequest.subCopy,
      productContent: productRegisterRequest.productContent,
      productImages: undefined,
      productExternalSite: productRegisterRequest.productExternalSite,
      productSalesImage: undefined,
      comment: undefined,
      examples: [],
    };

    if (productRegisterRequest && productRegisterRequest.productImages) {
      productView.productImages = productRegisterRequest.productImages.map(
        (image) => {
          let thumbnail = '';
          if (image.thumbnailData)
            thumbnail = image.thumbnailData;
          else if (image.thumbnailUrl)
            thumbnail = image.thumbnailUrl;

          const productImage: MaterialImage = {
            materialImageId: image.materialImageId ? image.materialImageId : 1,
            materialImageType: image.materialImageType,
            url: '',
            thumbnailUrl: thumbnail,
            materialPeriod: {
              usePeriodDate: '',
              usePeriodTime: '',
              openPeriodDate: '',
              openPeriodTime: '',
              materialStatus: 0,
            },
          };

          if (image.url || image.data) {
            if (
              image.materialImageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK
            ) {
              productImage.thumbnailUrl = image.data ? image.data : thumbnail;
              productImage.url = image.url ? image.url : '';
            } else {
              productImage.url = image.data || image.url || '';
            }
          }

          return productImage;
        },
      );
    }
  }

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    if (inquiryForm.inquiryItemId === 15) {
      inquiryForm.interested = [
        {
          id: '',
          name:
            productRegisterRequest && productRegisterRequest.productName
              ? productRegisterRequest.productName
              : '',
        },
      ];
    }

    return inquiryForm;
  });

  const lpView: LpView = {
    pageTitle: undefined,
    header: undefined,
    tokuisakiName: undefined,
    tokuisakiDisplayed: false,
    tokuisakiFixedPhraseText: undefined,
    tokuisakiTitle: undefined,
    lpMainImage: undefined,
    tantoName: undefined,
    tantoImage: undefined,
    tantoCompany: undefined,
    profileFixedPhraseText: undefined,
    profileDisplayed: false,
    greetText: undefined,
    beginningText: undefined,
    products: productView ? [productView] : [],
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

export const convertNewExampleToLpView = (
  exampleRegisterRequest: ExampleRegisterRequest | null,
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  let productView: ProductView | undefined;
  if (exampleRegisterRequest) {
    productView = {
      hashProductId: '',
      productName: '',
      catchCopy: '',
      subCopy: '',
      productContent: '',
      productImages: [],
      productExternalSite: undefined,
      productSalesImage: undefined,
      comment: '',
      examples: [],
    };

    let thumbnail = '';
    if (exampleRegisterRequest.exampleImage?.thumbnailData)
      thumbnail = exampleRegisterRequest.exampleImage.thumbnailData;
    else if (exampleRegisterRequest.exampleImage?.thumbnailUrl)
      thumbnail = exampleRegisterRequest.exampleImage.thumbnailUrl;

    const exampleImage: MaterialImage | undefined =
      exampleRegisterRequest.exampleImage
        ? {
            materialImageId: exampleRegisterRequest.exampleImage.materialImageId
              ? exampleRegisterRequest.exampleImage.materialImageId
              : 1,
            materialImageType:
              exampleRegisterRequest.exampleImage.materialImageType,
            url: '',
            thumbnailUrl: thumbnail,
            materialPeriod: {
              usePeriodDate: '',
              usePeriodTime: '',
              openPeriodDate: '',
              openPeriodTime: '',
              materialStatus: 0,
            },
          }
        : undefined;

    if (exampleImage && exampleRegisterRequest.exampleImage) {
      if (exampleRegisterRequest.exampleImage.data) {
        if (
          exampleRegisterRequest.exampleImage.materialImageType ===
          CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK
        ) {
          exampleImage.thumbnailUrl = exampleRegisterRequest.exampleImage.data;
          exampleImage.url = exampleRegisterRequest.exampleImage.url
            ? exampleRegisterRequest.exampleImage.url
            : '';
        } else {
          exampleImage.url = exampleRegisterRequest.exampleImage.data;
        }
      } else {
        exampleImage.url = exampleRegisterRequest.exampleImage.url
          ? exampleRegisterRequest.exampleImage.url
          : '';
      }
    }

    productView.examples = [
      {
        exampleName: exampleRegisterRequest.exampleName,
        customerName: exampleRegisterRequest.customerName,
        exampleContent: exampleRegisterRequest.exampleContent,
        exampleImage,
      },
    ];
  }

  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    return inquiryForm;
  });

  const lpView: LpView = {
    pageTitle: undefined,
    header: undefined,
    tokuisakiName: undefined,
    tokuisakiDisplayed: false,
    tokuisakiFixedPhraseText: undefined,
    tokuisakiTitle: undefined,
    lpMainImage: undefined,
    tantoName: undefined,
    tantoImage: undefined,
    tantoCompany: undefined,
    profileFixedPhraseText: undefined,
    profileDisplayed: false,
    greetText: undefined,
    beginningText: undefined,
    products: productView ? [productView] : [],
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

export const convertPublishDateToString = ({
  date,
  time,
}: {
  date?: string | null;
  time?: string | null;
}) => {
  const useDate = !date
    ? ''
    : `${+date.substring(0, 4)}/${+date.substring(4, 6)}/${+date.substring(
        6,
        8,
      )}`;
  const useTime = !time
    ? ''
    : ` ${time.substring(0, 2)}:${time.substring(2, 4)}`;

  return useDate + useTime;
};
/**
 * オブジェクトをGetのクエリーに変換する
 * Object.freezeを使う前に使用する必要がある。
 */

export const convertObjectToGetQuery = (
  object: {
    [key in string | number]: string | number | undefined;
  },
) => {
  let query = '?';
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value === undefined) return;
    query += `${key}=${value}&`;
  });

  return query.slice(0, -1);
};

export const convertInquiryToLpView = (
  lpSettings: LpSettings | null,
  inquriiesGreetText: InquiriesGreetText | null,
  inquiryForms: Array<InquiryForm> | null,
): LpView => {
  const filterdInquiryForms = inquiryForms
    ? inquiryForms.filter((form) => form.displayed)
    : [];
  const inquiryFormView = filterdInquiryForms.map((form) => {
    const inquiryForm: InquiryFormView = {
      inquiryItemId: form.inquiryItemId,
      inquiryItemName: form.inquiryItemName,
      inputType: form.inputType,
      inquiryItemOptions:
        form.inquiryItemOptions?.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ) || [],
      interested: [],
      required: form.required,
    };

    return inquiryForm;
  });
  const lpView: LpView = {
    pageTitle: undefined,
    header: undefined,
    tokuisakiName: undefined,
    tokuisakiDisplayed: false,
    tokuisakiFixedPhraseText: undefined,
    tokuisakiTitle: undefined,
    lpMainImage: undefined,
    tantoName: undefined,
    tantoImage: undefined,
    tantoCompany: undefined,
    profileFixedPhraseText: undefined,
    profileDisplayed: false,
    greetText: undefined,
    beginningText: undefined,
    products: [],
    tenantLogoUrl: lpSettings?.tenantLogoUrl,
    certificationMarks: lpSettings?.certificationMarks,
    copyright: lpSettings?.copyright,
    privacyPolicyUrl: lpSettings?.privacyPolicyUrl,
    privacyPolicy: lpSettings?.privacyPolicy,
    privacyPolicyCaption: lpSettings?.privacyPolicyCaption,
    inquiryForms: inquiryFormView,
    inquiryHeader: inquriiesGreetText?.inquiryHeader,
    inquiryGreetText: inquriiesGreetText?.inquiryGreetText,
    openPeriod: {
      openDate: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    },
  };

  return lpView;
};

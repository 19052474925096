import { useState, useCallback, useMemo, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  PaginationBar,
  PrimaryButton,
  SecondaryButton,
  Grid,
  Link,
  Typography,
} from 'components/atoms';
import {
  Content,
  CustomersTable,
  CustomersSearchField,
  ConfirmDialog,
  TagSelectDialog,
  FileUploadDialog,
  SuccessDialog,
} from 'components/organisms';
import { SendErrorMailAddressWarningLabel } from 'components/molecules';

import { hooks } from 'libs';
import {
  CustomersQueryParameter,
  Customers,
  CustomerTags,
} from 'core/domain/customer';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';
import { PlusIcon, BulkIcon, DastIcon } from 'assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { Modules } from 'core';
import { clearDeleteCustomers } from 'core/modules/deleteCustomers';
// import { fileToBase64 } from 'libs/file';
import { CUSTOMERS_LIST_LABEL } from 'constants/text';

/**
 * Interface
 */
export interface CustomersProps {
  rows: number;
  customers: Customers | null;
  customersQueryParameter: CustomersQueryParameter | null;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangePage: (page?: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  tags: CustomerTags | null;
  handleChangeTag?: (tags: string) => void;
  handleDelete: () => void;
  fileDialogOpen: boolean;
  setFileDialogOpen: (arg0: boolean) => void;
  handleFileSubmit: (files: File[]) => void;
  onFileUploadDialog: () => void;
  csvUploadSuccessDialogOpen: boolean;
  setCsvUploadSuccessDialogOpen: (state: boolean) => void;
  deleteTag: (val: string) => void;
}

/*
顧客一覧
新規登録ボタン
一括登録ボタン
フリーワード検索
削除ボタン→ダイアログに遷移？
（一覧）
  chkボックス
  社名・団体名
  部署名
  肩書
  氏名（リンク）→遷移
  メールアドレス
  LP案内数
  ページ閲覧数
  お問い合わせ数
  登録者
  所属
  最終更新日時
ページング

*/

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      width: '332px',
      height: '48px',
      color: 'white',
      backgroundColor: '#44A557',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      border: '1px solid #558643',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '21px',
    },
    deletebutton: {
      '&>button': {
        width: 120,
        height: 36,
        marginLeft: 'auto',
        display: 'flex',
        marginRight: theme.spacing(10),
      },
    },
    warningLabel: {
      marginLeft: 'auto',
      display: 'flex',
      marginRight: theme.spacing(10),
    },
  }),
);

/**
 * Presenter
 */

// 削除ダイアログ表示用
interface Dialog {
  confirmDialog: boolean;
  chengePageDialog: boolean;
}

const CustomersPage = (props: CustomersProps) => {
  const classes = useStyles({});
  const {
    rows,
    customers,
    customersQueryParameter,
    handleChangeSortKey,
    handleChangePage,
    handleChangeFreeWord,
    handleChangeTag,
    handleDelete,
    tags,
    fileDialogOpen,
    setFileDialogOpen,
    handleFileSubmit,
    onFileUploadDialog,
    csvUploadSuccessDialogOpen,
    setCsvUploadSuccessDialogOpen,
    deleteTag,
  } = props;

  // 権限
  const { customerCreatePermission } = hooks.useCustomersPermission();

  // 削除ダイアログ用
  const { navigate } = hooks.useNavigate();
  const [modalVisible, setModalVisible] = useState<Dialog>({
    confirmDialog: false,
    chengePageDialog: false,
  });
  const closeDialog = useCallback(() => {
    setModalVisible({
      confirmDialog: false,
      chengePageDialog: false,
    });
  }, []);

  // 顧客削除処理
  const deleteCustomer = () => {
    handleDelete();
    closeDialog();
  };
  const dispatch = useDispatch();

  // 削除ボタン活性非活性
  const deleteCustomers = useSelector(
    (state: Modules.AppState) => state.deleteCustomers,
  );

  const deleteFlg = useMemo(() => {
    if (!deleteCustomers?.customerIds?.length) return true;

    return false;
  }, [deleteCustomers]);

  // ページ移動用変数
  const [pageChenge, setPage] = useState<number>();

  // ページ移動でチェック取り消し
  const handleChangePages = (page?: number) => {
    if (!deleteCustomers?.customerIds?.length) handleChangePage(page);

    if (deleteCustomers?.customerIds?.length) {
      setModalVisible({
        ...modalVisible,
        chengePageDialog: true,
      });
    }
  };

  // タグ用処理
  const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(
    customersQueryParameter && customersQueryParameter?.tags
      ? customersQueryParameter?.tags?.split(',')
      : [],
  );

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (!customersQueryParameter || !customersQueryParameter.tags) {
      setSelectedTags([]);
      setInitialSelectedTags([]);

      return;
    }

    setSelectedTags(customersQueryParameter.tags.split(','));
    setInitialSelectedTags(
      JSON.parse(JSON.stringify(customersQueryParameter.tags.split(','))),
    );
    // eslint-disable-next-line
  }, [customersQueryParameter?.tags]);
  // タグ
  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }

    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag]);
  };

  // ファイルアップロードダイアログ用
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  // 一括登録テンプレートDLボタン制御
  const [csvTemplateDisabled, setCsvTemplateDisabled] = useState(false);

  const SideButtons = () => (
    <Box display="flex" flexDirection="column" textAlign="right">
      <Box className={classes.sideItems}>
        <PrimaryButton
          disabled={customerCreatePermission?.disabled}
          text="新規登録"
          icon={<PlusIcon />}
          click={() => navigate('/adm/customers/register')}
        />
        <PrimaryButton
          disabled={customerCreatePermission?.disabled}
          text="一括登録"
          icon={<BulkIcon />}
          click={() => setFileDialogOpen(true)}
        />
      </Box>
      <Box padding={1.0}>
        <Typography variant="caption">
          <Link
            href={`${
              process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT ||
              window.location.origin
            }/csvTemplate/customers.csv`}
            underline="always"
            onClick={() => {
              setCsvTemplateDisabled(true);
              setTimeout(() => setCsvTemplateDisabled(false), 1000);
            }}
            style={
              !csvTemplateDisabled
                ? { pointerEvents: 'auto' }
                : { pointerEvents: 'none', color: 'gray' }
            }
          >
            一括登録テンプレート
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box mt={5}>
      <SuccessDialog
        title="登録処理が完了され次第、お知らせにて通知いたします。"
        text="※件数により反映されるまでに数分かかる場合がございます"
        open={csvUploadSuccessDialogOpen}
        handleClose={() => setCsvUploadSuccessDialogOpen(false)}
      />

      <Content
        title="顧客一覧"
        sideItem={customerCreatePermission?.show && SideButtons}
      >
        <div>
          <Box className={classes.section}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {/* フリーワード検索 */}
                <CustomersSearchField
                  handleChangeFreeWord={handleChangeFreeWord}
                  customersQueryParameter={customersQueryParameter}
                  // タグのボタンの押下時にタグダイアログ開く
                  addTags={() => setTagDialogVisible(true)}
                  deleteTag={(val) => deleteTag(val)}
                />
              </Grid>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="column"
                >
                  {/* ボタン押下で削除ダイアログ表示 */}
                  <span className={classes.deletebutton}>
                    {customerCreatePermission?.show && (
                      <SecondaryButton
                        disabled={deleteFlg}
                        icon={<DastIcon />}
                        text="削除する"
                        click={() => {
                          setModalVisible({
                            ...modalVisible,
                            confirmDialog: true,
                          });
                        }}
                      />
                    )}
                  </span>

                  {customers?.customers.find(
                    (customer) => customer.mailSendError === true,
                  ) && (
                    <Box className={classes.warningLabel} mt={2}>
                      <SendErrorMailAddressWarningLabel
                        text={CUSTOMERS_LIST_LABEL}
                        icon
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.table}>
            <CustomersTable
              clickCustomer={(customerId) =>
                navigate(`/adm/customers/${customerId}/edit`)
              }
              customers={customers}
              handleChangeSortKey={handleChangeSortKey}
              customersQueryParameter={customersQueryParameter}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  customersQueryParameter ? customersQueryParameter.page : 1
                }
                maxPage={
                  customers ? Math.ceil(customers?.totalCount / rows) : 1
                }
                handleChangePage={(page: number) => {
                  setPage(page);
                  handleChangePages(page);
                }}
                totalCount={customers?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
      {/* 削除ダイアログ */}
      <ConfirmDialog
        buttonText="削除する"
        open={modalVisible.confirmDialog}
        title=""
        text="顧客情報を削除します。"
        handleCancel={() => closeDialog()}
        handleSubmit={() => deleteCustomer()}
      />
      {/* チェックをページ移動時に外す */}
      <ConfirmDialog
        buttonText="はい"
        open={modalVisible.chengePageDialog}
        title=""
        text="ページ移動すると行選択チェックが無効になりますが"
        handleCancel={() => closeDialog()}
        handleSubmit={() => {
          handleChangePage(pageChenge);
          dispatch(clearDeleteCustomers());
          closeDialog();
        }}
      />
      {/* タグ検索 */}
      <TagSelectDialog
        handleCheck={handleTagCheck}
        tags={tags?.customerTags ? tags?.customerTags : undefined}
        usedTags={undefined}
        open={tagDialogVisible}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagDialogVisible(false);
        }}
        handleSubmit={(tags) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          if (handleChangeTag) handleChangeTag(tags.join(','));
          setTagDialogVisible(false);
        }}
        selectedTags={selectedTags}
        category="Customer"
        tagType="list"
      />
      {/* 一括登録 */}
      <FileUploadDialog
        handleSubmit={handleFileSubmit}
        onClose={onFileUploadDialog}
        open={fileDialogOpen}
        isProduct={false}
        fileType="csv"
      />
      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => setSuccessDialogOpen(false)}
      />
    </Box>
  );
};

export default CustomersPage;

import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import {
  Box,
  Dialog,
  PrimaryButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Tooltip,
  SecondaryButton,
} from 'components/atoms';
import { Modules } from 'core';
import { setManageUsers } from 'core/modules/manageUsers';

import { UsersStatusBatchChangeComfirm } from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import {
  GreenCircle,
  GrayCircle,
  CloseWhiteIcon,
  CloseIcon,
} from 'assets/images';

import { Users } from 'core/domain/user';
import { useSelector, useDispatch } from 'react-redux';

/**
 * Interface
 */
export interface UsersStatusBatchChangeDialogProps {
  open: boolean;
  date?: string;
  time?: string;
  isComfirmOpen: boolean;
  setComfirmOpen: (value: boolean) => void;
  handleCancel: () => void;
  handleSubmit: (date?: string, time?: string) => void;
  isRelease?: boolean;
  handleUsersStatusBatchChange: (available: boolean) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 'calc(100vh - 400px)',
      overflow: 'auto',
    },
    dialog: {
      width: 1400,
      minHeight: 226,
      padding: '32px 24px 24px 24px',
      margin: 0,
    },
    table: {
      tableLayout: 'fixed',
      minWidth: 1090,
      overflow: 'auto',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 54,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(1)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      overflow: 'auto',
      maxHeight: 250,
      '&>tr': {
        height: 84,
      },
      '&>tr, td': {
        whiteSpace: 'pre-wrap',
      },
      '& td': {
        padding: `${theme.spacing(1)}px`,
      },
    },
    link: {
      '&>td, a': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 150,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },
    formLabel: {
      display: 'block',
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        // fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    grayFont: {
      color: '#aaaaaa',
    },
    normalFont: {
      color: '#000000',
    },
    centerCell: {
      textAlign: 'center',
      '& > span.MuiTableSortLabel-root': {
        width: 'inherit',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 'inherit',
      },
    },
    closeButton: {
      position: 'fixed',
      right: 20,
      top: 0,
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
  isCenter?: boolean;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'checkBox',
    sortKey: '',
    width: '6%',
    isCenter: true,
  },
  {
    title: '氏名',
    sortKey: 'userName',
    width: '21%',
  },
  {
    title: 'メールアドレス',
    sortKey: '',
    width: '29%',
  },
  {
    title: '権限',
    sortKey: '',
    width: '20%',
    isCenter: true,
  },
  {
    title: 'LP\n作成数',
    sortKey: 'lpCreatedCount',
    width: '8%',
  },
  {
    title: '商材\n作成数',
    sortKey: 'productCreatedCount',
    width: '8%',
  },
  {
    title: '事例\n作成数',
    sortKey: 'productCreatedCount',
    width: '8%',
  },
];

const UsersStatusBatchChangeDialog = (
  props: UsersStatusBatchChangeDialogProps,
) => {
  const classes = useStyles({});
  const {
    open,
    isComfirmOpen,
    setComfirmOpen,
    handleCancel,
    handleUsersStatusBatchChange,
  } = props;

  const circle = {
    width: 10,
    height: 10,
  };

  const manageUsers = useSelector(
    (state: Modules.AppState) => state.manageUsers,
  );

  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [radioValue, setRadioValue] = React.useState(true);
  // ヘッダのチェックボックスにチェックが入った際にtrue
  const OnAllChecked = (allChecked: boolean) => {
    if (!manageUsers) return;
    dispatch(
      setManageUsers({
        ...manageUsers,
        users: manageUsers.users.map((user) => ({
          ...user,
          checked: allChecked,
        })),
      }),
    );
  };
  // ここの型は指定しなくてもうまく動く？
  const allChecked = useMemo((): boolean | 'indeterminate' | null => {
    if (!manageUsers) return false;
    // 多分全部trueだったらtrueが返るはず、要検証
    if (manageUsers.users.every((user) => user.checked)) return true;
    if (manageUsers.users.every((user) => !user.checked)) return false;

    return 'indeterminate';
  }, [manageUsers]);

  useEffect(() => {
    if (!manageUsers) return;
    setIsDisabled(manageUsers.users.every((e) => !e.checked));
  }, [manageUsers]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={false}
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      onBackdropClick={handleCancel}
    >
      <Box>
        <Typography variant="body2" className={classes.title}>
          ステータス一括変更
        </Typography>
        <Box padding={3}>
          チェックボックスにて変更対象のユーザを選択し、
          画面下部で変更後のステータスを指定して決定ボタンを押してください
        </Box>

        <TableContainer className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <colgroup>
              {TABLE_HEADER_ITEMS.map((item: TableHeaderItems, index) => (
                <col key={index} width={item.width} />
              ))}
            </colgroup>
            <TableHead className={classes.header}>
              <TableRow>
                {TABLE_HEADER_ITEMS.map((item, index) => (
                  <TableCell
                    key={index}
                    className={item.isCenter ? classes.centerCell : undefined}
                  >
                    <TableSortLabel hideSortIcon>
                      {item.title !== 'checkBox' ? (
                        item.title
                      ) : (
                        <FormControlLabel
                          className={classes.formLabel}
                          control={
                            <Checkbox
                              disabled={!manageUsers} // manageUsersがない時に押される可能性があるなら書く
                              // checked={allChecked === true}
                              checked={allChecked === true}
                              indeterminate={allChecked === 'indeterminate'}
                              onChange={(e) => {
                                OnAllChecked(e.target.checked);
                              }}
                            />
                          }
                          label=""
                        />
                      )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={classes.body}>
              {manageUsers &&
                manageUsers.users.map((manageUser, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={classes.centerCell}>
                      {/* チェックボックス */}
                      <FormControlLabel
                        className={classes.formLabel}
                        control={
                          <Checkbox
                            disabled={!manageUsers} // manageUsersがない時に押される可能性があるなら書く
                            checked={manageUser.checked || false}
                            onChange={() => {
                              const newUsers: Users = {
                                ...manageUsers,
                                users: manageUsers.users.map((user) =>
                                  user === manageUser
                                    ? {
                                        ...manageUser,
                                        checked: !user.checked,
                                      }
                                    : user,
                                ),
                              };

                              dispatch(setManageUsers(newUsers));
                            }}
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    <TableCell className={classes.overflow}>
                      {/* 氏名 */}
                      <Tooltip title={manageUser.userName || ''}>
                        <Typography
                          className={
                            manageUser.available
                              ? classes.normalFont
                              : classes.grayFont
                          }
                        >
                          {' '}
                          {manageUser.userName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.overflow}>
                      {/* メールアドレス */}
                      <Tooltip title={manageUser.mailAddress || ''}>
                        <Typography
                          className={
                            manageUser.available
                              ? classes.normalFont
                              : classes.grayFont
                          }
                        >
                          {manageUser.mailAddress}
                        </Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        width="100%"
                        textAlign="center"
                        justifyContent="center"
                      >
                        {/* 権限：LP */}
                        <Box pl={1} pr={1}>
                          <Box fontWeight="fontWeightBold">LP</Box>
                          <Box
                            style={
                              manageUser.lpAuthor ? {} : { color: '#aaaaaa' }
                            }
                          >
                            <img
                              src={
                                manageUser.lpAuthor ? GreenCircle : GrayCircle
                              }
                              width={circle.width}
                              height={circle.height}
                            />
                            作成
                          </Box>
                          <Box
                            style={
                              manageUser.lpApprover ? {} : { color: '#aaaaaa' }
                            }
                          >
                            <img
                              src={
                                manageUser.lpApprover ? GreenCircle : GrayCircle
                              }
                              width={circle.width}
                              height={circle.height}
                            />
                            承認
                          </Box>
                        </Box>
                        {/* 権限：商材事例 */}
                        <Box pl={1} pr={1}>
                          <Box fontWeight="fontWeightBold">商材 事例</Box>
                          <Box
                            style={
                              manageUser.materialAuthor
                                ? {}
                                : { color: '#aaaaaa' }
                            }
                          >
                            <img
                              src={
                                manageUser.materialAuthor
                                  ? GreenCircle
                                  : GrayCircle
                              }
                              width={circle.width}
                              height={circle.height}
                            />
                            作成
                          </Box>
                          <Box
                            style={
                              manageUser.materialApprover
                                ? {}
                                : { color: '#aaaaaa' }
                            }
                          >
                            <img
                              src={
                                manageUser.materialApprover
                                  ? GreenCircle
                                  : GrayCircle
                              }
                              width={circle.width}
                              height={circle.height}
                            />
                            承認
                          </Box>
                        </Box>
                        {/* 権限：管理者 */}
                        <Box
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="stretch"
                          pl={1}
                          pr={1}
                        >
                          <Box
                            display="flex"
                            fontWeight="fontWeightBold"
                            alignItems="center"
                          >
                            管理者
                          </Box>

                          <Box
                            paddingTop={1.5}
                            paddingBottom={1.5}
                            display="flex"
                            alignItems="center"
                            style={
                              manageUser.tenantAdmin ? {} : { color: '#aaaaaa' }
                            }
                          >
                            <img
                              src={
                                manageUser.tenantAdmin
                                  ? GreenCircle
                                  : GrayCircle
                              }
                              width={circle.width}
                              height={circle.height}
                            />
                            管理
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell align="right">
                      {/* LP作成数 */}
                      <Tooltip title={manageUser.lpCreatedCount.toLocaleString()}>
                        <span style={{display: "flow-root"}}>
                          {manageUser.lpCreatedCount.toLocaleString()}
                        </span>
                      </Tooltip>
                    </TableCell>

                    <TableCell align="right">
                      {/* 商材作成数 */}
                      <Tooltip title={manageUser.productCreatedCount.toLocaleString()}>
                        <span style={{display: "flow-root"}}>
                          {manageUser.productCreatedCount.toLocaleString()}
                        </span>
                      </Tooltip>
                    </TableCell>

                    <TableCell align="right">
                      {/* 事例作成数 */}
                      <Tooltip title={manageUser.exampleCreatedCount.toLocaleString()}>
                        <span style={{display: "flow-root"}}>
                          {manageUser.exampleCreatedCount.toLocaleString()}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          textAlign="center"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding={3}
        >
          <Box fontWeight="fontWeightBold" display="inline" paddingRight={5}>
            ユーザーステータス
          </Box>
          <FormControl component="fieldset">
            <FormLabel component="legend" />
            <RadioGroup
              value={radioValue}
              onChange={() => {
                setRadioValue(!radioValue);
              }}
              row
            >
              <FormControlLabel value control={<Radio />} label="有効" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="無効"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box width={332} height={48} mr={1}>
          <SecondaryButton
            click={handleCancel}
            icon={<CloseIcon className={classes.closeIcon} />}
            text="キャンセル"
          />
        </Box>
        <Box width={332} height={48} ml={1}>
          <PrimaryButton
            disabled={isDisabled}
            text="決定"
            click={() => {
              setComfirmOpen(true);
            }}
          />
        </Box>
      </Box>
      <IconButton onClick={handleCancel} className={classes.closeButton}>
        <CloseWhiteIcon />
      </IconButton>

      <UsersStatusBatchChangeComfirm
        open={isComfirmOpen}
        userStatus={radioValue}
        handleCancel={() => {
          setComfirmOpen(false);
          // do nothing.
        }}
        handleSubmit={handleUsersStatusBatchChange}
      />
    </Dialog>
  );
};
export default UsersStatusBatchChangeDialog;

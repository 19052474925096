import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from 'components/atoms';
import { BoxProps } from '@material-ui/core';

/**
 * Interface
 */

export interface ContentTemplateProps extends BoxProps {
  title: string;
  children: React.ReactElement;
  sideItem?: React.ReactNode;
  childBoxProps?: BoxProps;
  titleBoxProps?: BoxProps;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginBottom: 25,
      color: theme.palette.gray[900],
    },
    children: {
      backgroundColor: theme.palette.text.secondary,
    },
  }),
);

const ContentTemplate = (props: ContentTemplateProps) => {
  const {
    title,
    children,
    sideItem,
    childBoxProps,
    titleBoxProps,
    ...parentBoxProps
  } = props;
  const classes = useStyles({});

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...parentBoxProps}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Box display="flex" mb={2} {...titleBoxProps}>
        <Box flexGrow={1}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box>{sideItem}</Box>
      </Box>
      <Box
        p="24px"
        borderRadius={16}
        className={classes.children}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...childBoxProps}
      >
        {children}
      </Box>
    </Box>
  );
};
export default ContentTemplate;

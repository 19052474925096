/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Grid,
  SecondaryButton,
  PrimaryButton,
  MessageAlert,
} from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_300 } from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CustomerItemsField,
  Content,
  CustomerTagsField,
  TagSelectDialog,
  ConfirmDialog,
  RegisterContinueDialog,
  SuccessDialog,
} from 'components/organisms';
import {
  CustomerDetail,
  CustomerMailAddress,
  CustomerRegisterRequest,
  CustomerTags,
} from 'core/domain/customer';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearNewCustomer, setNewCustomer } from 'core/modules/newCustomer';
import { hooks } from 'libs';
import { AppError } from 'core/domain/appError';
import { removeIncorrectTagEntry } from 'libs/validation';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Modules } from 'core';
import { setError } from 'core/modules/error';

interface CustomerRegisterProps {
  customerDetail: CustomerDetail | null;
  newCustomer: CustomerRegisterRequest | null;
  customerTags: CustomerTags | null;
  customerMailAddress: CustomerMailAddress | null;
  handleCustomerRegister: (newTags?: string) => void;
  error: AppError | null;
}

interface Validations {
  mailAddress: string | undefined;
  customerName: string | undefined;
  companyName: string | undefined;
  deptName: string | undefined;
  jobTitle: string | undefined;
  customerTag: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    fields: {
      borderBottom: '1px solid #c4c4c4',
      marginBottom: theme.spacing(4),
      '& > :nth-child(n + 2)': {
        marginBottom: theme.spacing(5),
      },
      '& > :last-child': {
        marginBottom: theme.spacing(6),
      },
    },
  }),
);

const PATTERN = new RegExp(
  /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
);

const CustomerRegister = (props: CustomerRegisterProps) => {
  const {
    customerDetail,
    newCustomer,
    customerTags,
    customerMailAddress,
    handleCustomerRegister,
    error,
  } = props;
  const { customerId } = useParams<{ customerId: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const navigate = hooks.useNavigate();
  const [tagSelectDialogOpen, setTagSelectDialogOpen] = useState(false);
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [openRegisterContinueDialog, setOpenRegisterContinueDialog] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialNewCustomer, setInitialNewCustomer] = useState<
    CustomerRegisterRequest | null | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isExistingMailAddress, setIsExistingMailAddress] =
    useState<boolean>(false);
  const [validationError, setValidationError] = useState<Validations>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [nextPagePath, setNextPagePath] = useState<string>('');
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [goAnotherPage, setGoAnotherPage] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  const validationInputValue = (): boolean => {
    const validations: Validations = {
      mailAddress: undefined,
      customerName: undefined,
      companyName: undefined,
      deptName: undefined,
      jobTitle: undefined,
      customerTag: undefined,
    };

    // 必須チェック
    if (!newCustomer?.mailAddress)
      validations.mailAddress = 'メールアドレスを入力してください。';
    if (!newCustomer?.customerName)
      validations.customerName = '氏名を入力してください。';

    // 最大文字数チェック
    if (newCustomer?.customerName && newCustomer.customerName.length > 100)
      validations.customerName = '氏名は100文字以内で入力してください。';
    if (newCustomer?.companyName && newCustomer.companyName.length > 100)
      validations.companyName = '社名・団体名は100文字以内で入力してください。';
    if (newCustomer?.deptName && newCustomer.deptName.length > 100)
      validations.deptName = '部署名は100文字以内で入力してください。';
    if (newCustomer?.jobTitle && newCustomer.jobTitle.length > 100)
      validations.jobTitle = '役職は100文字以内で入力してください。';
    if (newCustomer?.customerTag) {
      const newTag = removeIncorrectTagEntry(newCustomer.customerTag);
      const newTags = newTag.split(',');
      if (
        newTag.replaceAll(',', '').length > 5000 ||
        newTags.some((e) => e.length > 100)
      )
        validations.customerTag =
          '顧客タグは、タグ1つにつき100文字以内、全体で5000文字以内で入力してください。';
    }

    if (newCustomer?.mailAddress) {
      // (メールアドレス全体の)最大文字数チェック
      if (newCustomer.mailAddress.length > 254) {
        validations.mailAddress =
          'メールアドレスは254文字以内で入力してください。';
      }
      // フォーマットチェック
      else if (!PATTERN.exec(newCustomer.mailAddress)) {
        validations.mailAddress = 'メールアドレスの形式で入力してください。';
      } else {
        // パターンに一致していたら区切ごとの最大文字数チェック
        const atLeftcheck = newCustomer?.mailAddress?.split('@') || undefined;

        if (atLeftcheck) {
          const atRightcheck = atLeftcheck[1]?.split('.') || undefined;
          // ＠右側を、ドット区切りで配列にしてそれぞれ63文字以内か
          // aaa@bbb.ccc→bbb,ccc
          // aaa@bbb.ccc.ddd→ bbb,ccc,ddd
          if (atRightcheck.some((domain) => domain.length > 63)) {
            validations.mailAddress =
              'メールアドレスの形式で入力してください。';
          } else if (atLeftcheck[0]?.length > 64) {
            // ＠左側が64文字超えたらerror
            validations.mailAddress =
              'ローカル部分は64文字以内で入力してください。';

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
          } else if (checkIsExistingMailAddressImpl(newCustomer.mailAddress)) {
            // 重複チェック
            // ※他項目で入力があると、重複チェック結果がクリアされてしまうため、登録前に再チェック
            validations.mailAddress = '登録済みのメールアドレスです。';
          }
        }
      }
    }

    if (!Object.values(validations).every((e) => e === undefined)) {
      setAlertOpen(true);
      setValidationError(validations);

      return false;
    }

    return true;
  };

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag].filter((tag) => !!tag));
  };

  const handleCustomerTag = (selectedTags: string[]) => {
    const customerTag = selectedTags.join(',');
    dispatch(
      setNewCustomer({
        ...newCustomer!,
        customerTag,
      }),
    );
  };

  const handleMailAddress = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          mailAddress: v,
        }),
      );
    },
    [dispatch, newCustomer],
  );

  const checkIsExistingMailAddressImpl = useCallback(
    (target: string) => {
      if (!customerMailAddress) return false;

      // 登録済みメールアドレスと重複（＝エラー）したらtrueを返す
      return customerMailAddress.some((address) => address === target);
    },
    [customerMailAddress],
  );

  const checkIsExistingMailAddress = (v: string) => {
    setValidationError(undefined);
    if (!checkIsExistingMailAddressImpl(v)) return;

    setValidationError({
      mailAddress: '登録済みのメールアドレスです。',
      customerName: undefined,
      companyName: undefined,
      deptName: undefined,
      jobTitle: undefined,
      customerTag: undefined,
    });
    setAlertOpen(true);
    setIsExistingMailAddress(true);
  };

  const handleCustomerName = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          customerName: v,
        }),
      );
    },
    [dispatch, newCustomer],
  );

  const handleCompanyName = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          companyName: v,
        }),
      );
    },
    [dispatch, newCustomer],
  );

  const handleDeptName = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          deptName: v,
        }),
      );
    },
    [dispatch, newCustomer],
  );

  const handleJobTitle = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          jobTitle: v,
        }),
      );
    },
    [dispatch, newCustomer],
  );

  const handleCustomerTagChange = useCallback(
    (v: string) => {
      dispatch(
        setNewCustomer({
          ...newCustomer!,
          customerTag: v,
        }),
      );
      const tags = v.split(',');
      setSelectedTags([...tags]);
    },
    [dispatch, newCustomer],
  );

  useEffect(() => {
    if (error && error?.error[0] === '権限がありません。')
      setGoAnotherPage(true);
  }, [error]);

  const handleCloseSuccessDialogOpen = () => {
    // 編集の場合、登録続行のダイアログを開くことなくページ遷移

    setSuccessDialogOpen(false);
    if (initialNewCustomer) {
      setGoAnotherPage(true);
      dispatch(clearNewCustomer());
      setInitialNewCustomer(undefined);
      navigate.navigate('/adm/customers');

      return;
    }

    // return ;

    if (userInfo?.tenantAdmin || userInfo?.lpAuthor)
      setOpenRegisterContinueDialog(true);
  };

  const handleRegister = () => {
    setValidationError(undefined);
    setIsSubmitting((prev) => !prev);

    if (!validationInputValue()) return;
    if (newCustomer && newCustomer.customerTag) {
      const newTag = removeIncorrectTagEntry(newCustomer.customerTag);
      handleCustomerRegister(newTag);
      dispatch(
        setNewCustomer({
          ...newCustomer,
          customerTag: newTag,
        }),
      );
    } else {
      handleCustomerRegister();
    }
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);

    setSuccessDialogOpen(true);
  };

  useEffect(() => {
    // nullの場合は初期値が書き換えられているので、リターンしたい。
    // しかし編集の場合はnullが入ったあと、API取得後の１度だけ値を書き換えたいのでcustomerIdで区別をつける。
    if ((initialNewCustomer === null && !customerId) || initialNewCustomer)
      return;

    // 初期値はundefinedで、１度実行した場合はnullを入れることで、１度目だけnewCustomerの変更が入ってしまうバグを防ぐ。
    setInitialNewCustomer(newCustomer ? { ...newCustomer } : null);
    setSelectedTags(newCustomer?.customerTag.split(',') || []);
    setInitialSelectedTags(newCustomer?.customerTag.split(',') || []);
  }, [newCustomer, customerId, initialNewCustomer]);

  useEffect(() => {
    if (error) return;
    if (userInfo && !userInfo.tenantAdmin && !userInfo.lpAuthor) {
      dispatch(
        setError({
          title: '以下のエラーにより、処理を中止しました。',
          error: ['権限がありません。'],
        }),
      );

      return;
    }

    const res = navigate.block((pathname: string): false | undefined => {
      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);
        setIsSubmitting(false);

        return false;
      }

      if ((!newCustomer && !initialNewCustomer) || isRegister || goAnotherPage)
        return undefined;

      if (
        newCustomer &&
        initialNewCustomer &&
        JSON.stringify(newCustomer) !== JSON.stringify(initialNewCustomer)
      ) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      if (newCustomer && !initialNewCustomer) {
        if (customerDetail) return undefined;
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => res();
  }, [
    newCustomer,
    isRegister,
    goAnotherPage,
    error,
    navigate,
    initialNewCustomer,
    customerDetail,
    userInfo,
    dispatch,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  // 入力されたらアラートが消え、ボタンが活性
  useEffect(() => {
    setAlertOpen(false);
    setIsSubmitting(false);
    setIsExistingMailAddress(false);
  }, [newCustomer]);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: validationError,
      })}
    >
      {alertOpen && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}
      <TagSelectDialog
        tagType="edit"
        category="Customer"
        handleCheck={handleTagCheck}
        tags={
          customerTags?.customerTags ? customerTags?.customerTags : undefined
        }
        usedTags={
          customerTags?.usedCustomerTags
            ? customerTags?.usedCustomerTags
            : undefined
        }
        open={tagSelectDialogOpen}
        selectedTags={selectedTags}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagSelectDialogOpen(false);
        }}
        handleSubmit={(selectedTags: Array<string>) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          handleCustomerTag(selectedTags);
          setTagSelectDialogOpen(false);
        }}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />

      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          handleCloseSuccessDialogOpen();
        }}
      />
      <RegisterContinueDialog
        buttonText="OK"
        open={openRegisterContinueDialog}
        title="登録画面を終了します"
        item="顧客"
        handleCancel={() => {
          setOpenRegisterContinueDialog(false);
          navigate.navigate('/adm/customers');
        }}
        handleSubmit={() => {
          dispatch(clearNewCustomer());
          setInitialNewCustomer(undefined);
          setOpenRegisterContinueDialog(false);
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      />
      <Content title="顧客登録・編集">
        <>
          <Box className={classes.fields}>
            <CustomerItemsField
              title="メールアドレス"
              value={newCustomer ? newCustomer.mailAddress : ''}
              handleChange={handleMailAddress}
              handleBlur={checkIsExistingMailAddress}
              error={validationError?.mailAddress}
              isMandatory
            />
            <CustomerItemsField
              title="氏名"
              value={newCustomer ? newCustomer.customerName : ''}
              handleChange={handleCustomerName}
              error={validationError?.customerName}
              isMandatory
            />
            <CustomerItemsField
              title="社名・団体名"
              value={newCustomer ? newCustomer.companyName : ''}
              handleChange={handleCompanyName}
              error={validationError?.companyName}
            />
            <CustomerItemsField
              title="部署名"
              value={newCustomer ? newCustomer.deptName : ''}
              handleChange={handleDeptName}
              error={validationError?.deptName}
            />
            <CustomerItemsField
              title="役職"
              value={newCustomer ? newCustomer.jobTitle : ''}
              handleChange={handleJobTitle}
              error={validationError?.jobTitle}
            />
            <CustomerTagsField
              value={newCustomer ? newCustomer.customerTag : ''}
              handleChange={handleCustomerTagChange}
              setTagSelectDialogOpen={setTagSelectDialogOpen}
              error={validationError?.customerTag}
            />
          </Box>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <SecondaryButton
                text="キャンセル"
                click={() => {
                  if (newCustomer) setOpenExitConfirmDialog(true);
                  navigate.navigateReplace('/adm/customers');
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton
                text="保存"
                click={handleRegister}
                disabled={isSubmitting || isExistingMailAddress}
              />
            </Grid>
          </Grid>
        </>
      </Content>
    </Box>
  );
};

export default CustomerRegister;
/* eslint-enable @typescript-eslint/no-non-null-assertion */

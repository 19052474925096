import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

interface ExamplesControl {
  show: boolean;
  disabled: boolean;
}

export const useExamplesPermission = () => {
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);

  // 新規作成
  const exampleNewCreatePermission: ExamplesControl = useMemo(() => {
    if (!userInfo) {
      return {
        show: false,
        disabled: true,
      };
    }
    if (userInfo.materialAuthor || userInfo.tenantAdmin) {
      return {
        show: true,
        disabled: false,
      };
    }

    return {
      show: false,
      disabled: true,
    };
  }, [userInfo]);

  const isMaterialAuthorOrApprover = useMemo(() => {
    if (
      userInfo?.materialAuthor ||
      userInfo?.materialApprover ||
      userInfo?.tenantAdmin
    ) {
      return true;
    }

    return false;
  }, [userInfo]);

  return {
    exampleNewCreatePermission,
    isMaterialAuthorOrApprover,
  };
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { Dispatch } from 'redux';
import { isOnline } from 'core/domain/app';
// import RecoverableError from 'core/domain/recoverableError'
import { setError } from 'core/modules/error';
import { setAuthError } from 'core/modules/authError';
import { beginCommunication, endCommunication } from 'core/modules/network';
import { AppError } from 'core/domain/appError';

const NETWORK_ERROR_MESSAGE =
  'ネットワークに接続されていません。\nネットワーク環境をご確認ください。';
const SERVER_ERROR_MESSAGE = 'システムエラーが発生しました。';
// const RELOAD_LABEL = '再読み込み'
const SERVER_AUTH_MESSAGE = 'サーバーとの接続がタイムアウトとなりました。';
const SERVER_DENIED_MESSAGE = '権限がありません。';
const AUTH_ERROR_MESSAGE =
  '大変申し訳ありませんが、現在アクセスできない状態となっております。';

export enum HttpMethod {
  get = 'GET',
  put = 'PUT',
  post = 'POST',
  patch = 'PATCH',
}

export type DidCallApiCallback<T> = (
  dispatch: Dispatch,
  data: T,
  options?: Options,
) => void;
export interface Options {
  reload?: () => void;
  navigate?: () => void;
}

function isJsonResponse(response: Response): boolean {
  return (
    response.headers.get('Content-Type')?.includes('application/json') ?? false
  );
}

export function validateOnLine(dispatch: Dispatch) {
  if (isOnline()) {
    return true;
  }
  dispatch(setError({ title: NETWORK_ERROR_MESSAGE, error: [] }));

  return false;
}

// ----- call GET/POST/PUT/DELETE Api -----
export async function callApi<T>(
  httpMethod: HttpMethod,
  apiName: string,
  accessToken: string | undefined,
  body: string | null,
  dispatch: Dispatch,
  didCallApiCallback?: DidCallApiCallback<T>,
  options?: Options,
) {
  const apiUrl = `${process.env.REACT_APP_SALAD_BAR_API_ENDPOINT}/${apiName}`;

  try {
    dispatch(beginCommunication());

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    const init = body
      ? {
          method: httpMethod,
          headers,
          body,
        }
      : {
          method: httpMethod,
          headers,
        };

    const response = await fetch(apiUrl, init);

    if (response.status === 401) {
      // トークン切れエラー
      dispatch(setAuthError({ message: SERVER_AUTH_MESSAGE }));

      return;
    }

    if (response.status === 403 && apiName === 'users/profile') {
      // 権限エラー（ユーザ未存在エラー）
      dispatch(setAuthError({ message: SERVER_DENIED_MESSAGE }));

      return;
    }

    if (
      response.status === 400 ||
      response.status === 403 ||
      response.status === 404 ||
      response.status === 405 ||
      response.status === 500
    ) {
      // サーバエラー
      if (!isJsonResponse(response)) {
        dispatch(setError({ title: SERVER_ERROR_MESSAGE, error: [] }));

        return;
      }

      const apiErrorResponse: AppError = await response.json();
      if (response.status === 500) {
        if (apiErrorResponse.message) {
          dispatch(setError({ title: apiErrorResponse.message, error: [] }));
        } else {
          dispatch(setError({ title: SERVER_ERROR_MESSAGE, error: [] }));
        }
      } else if (response.status === 405) {
        dispatch(setAuthError({ message: AUTH_ERROR_MESSAGE }));
      } else {
        dispatch(setError(apiErrorResponse));
      }

      return;
    }

    const data: T = await response.json();
    if (didCallApiCallback) {
      didCallApiCallback(dispatch, data, options);
    }
  } catch (e) {
    if (options && options.reload) {
      dispatch(setError({ title: SERVER_ERROR_MESSAGE, error: [] }));

      return;
    }

    if (!validateOnLine(dispatch)) {
      return;
    }
  } finally {
    dispatch(endCommunication());
  }
}

/**
 * リクエストパラメーターをエンコード
 */
export function createApi<Type>(param: Type) {
  const queryParams: string[] = [];
  for (const [key, value] of Object.entries(param)) {
    if (value !== '' && value !== undefined) {
      queryParams.push(`${key}=${encodeURIComponent(value)}`);
    }
  }

  return queryParams.join('&');
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpTags } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpTags | null {
  return initialState
}

const SET_LP_TAGS = 'lp-tags/set' as const
const CLEAR_LP_TAGS = 'lp-tags/clear' as const

export function setLpTags(data: LpTags) {
  return {
    type: SET_LP_TAGS,
    payload: {
      data
    }
  }
}

export function clearLpTags() {
  return {
    type: CLEAR_LP_TAGS
  }
}

type SetLpTags = ReturnType<typeof setLpTags>
type ClearLpTags = ReturnType<typeof clearLpTags>
type Action = SetLpTags | ClearLpTags

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_TAGS:
      return action.payload.data
    case CLEAR_LP_TAGS:
      return initialState
    default:
      return state
  }
}/* eslint-enable */

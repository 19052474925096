import { NoticeDetails } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticeDetails | null => initialState;

// eslint-disable-next-line  camelcase
export const SET_NOTICE_Details = 'noticeDetails/set' as const;
// eslint-disable-next-line  camelcase
export const CLEAR_NOTICE_Details = 'noticeDetails/clear' as const;

export const setNoticeDetails = (data: NoticeDetails) => ({
  type: SET_NOTICE_Details,
  payload: {
    data,
  },
});

export const clearNoticeDetails = () => ({
  type: CLEAR_NOTICE_Details,
});

type SetNoticesAction = ReturnType<typeof setNoticeDetails>;
type ClearNoticesAction = ReturnType<typeof clearNoticeDetails>;
type Action = SetNoticesAction | ClearNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    // eslint-disable-next-line  camelcase
    case SET_NOTICE_Details:
      return action.payload.data;
    // eslint-disable-next-line  camelcase
    case CLEAR_NOTICE_Details:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { TYPOGRAPHY } from 'constants/index'

const typographies = {
  htmlFontSize: 16,
  fontSize: 14,
  button: {
    textTransform: 'none',
  },
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_900,
    fontWeight: 'normal',
  },
  h2: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_700,
    fontWeight: 'normal',
  },
  h3: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_600,
    fontWeight: 'normal',
  },
  h4: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
    fontWeight: 'normal',
  },
  h5: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_200,
    fontWeight: 'normal',
  },
  h6: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
  subtitle1: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_400,
    fontWeight: 'normal',
    lineHight: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_400,
  },
  subtitle2: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
  body1: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_200,
  },
  body2: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
    lineHeight: '28px'
  },
  caption: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
  overline: {
    fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
    fontWeight: 'bold',
  },
}

export default typographies
/* eslint-enable */

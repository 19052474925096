import { LpSearchCondition } from 'core/domain/lp';

const initialState = null;

export const createInitialState = (): LpSearchCondition | null => initialState;

export const SET_LP_SEARCH_CONDITION = 'lp-search-condition/set' as const;
export const CLEAR_LP_SEARCH_CONDITION = 'lp-search-condition/clear' as const;

export const setLpSearchCondition = (data: LpSearchCondition) => ({
    type: SET_LP_SEARCH_CONDITION,
    payload: {
      data,
    },
  });

export const clearLpSearchCondition = () => ({
    type: CLEAR_LP_SEARCH_CONDITION,
  });

type SetLpSearchConditionAction = ReturnType<typeof setLpSearchCondition>;
type ClearLpSearchConditionAction = ReturnType<typeof clearLpSearchCondition>;
type Action = SetLpSearchConditionAction | ClearLpSearchConditionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_LP_SEARCH_CONDITION:
      return action.payload.data;
    case CLEAR_LP_SEARCH_CONDITION:
      return initialState;
    default:
      return state;
  }
};

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Modules, Usecases } from 'core';
import {
  setLpSearchCondition,
  clearLpSearchCondition,
} from 'core/modules/lpSearchCondition';
import { clearLpSearchDefaultAddTerm } from 'core/modules/lpSearchDefaultAddTerm';
import { LpSortKey } from 'core/domain/lp';
import { clearLpTags } from 'core/modules/lpTags';
import { hooks } from 'libs';
import LPs from 'components/pages/LPs';
import { setLpTagsRequest } from 'core/modules/lpTagsRequest';
import { clearLpDetail } from 'core/modules/lpDetail';
import { clearLps, setLps } from 'core/modules/lps';
import { UserInfoDetail } from 'core/domain/user';

const pageRows = 10;

/**
 * Presenter
 */

const LPsPageContainer = React.memo(() => {
  const dispatch = useDispatch();

  const lps = useSelector((state: Modules.AppState) => state.lps, shallowEqual);
  const lpSearchCondition = useSelector(
    (state: Modules.AppState) => state.lpSearchCondition,
    shallowEqual,
  );
  const lpSearchDefaultAddTerm = useSelector(
    (state: Modules.AppState) => state.lpSearchDefaultAddTerm,
    shallowEqual,
  );

  const lPTagsRequest = useSelector(
    (state: Modules.AppState) => state.lPTagsRequest,
    shallowEqual,
  );
  const lpTags = useSelector(
    (state: Modules.AppState) => state.lpTags,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  const token = hooks.useAuth().accessToken;

  const [openLpProductDetailDialog, setOpenLpProductDetailDialog] = useState<
    'interested-count' | 'document-download-count' | undefined
  >(undefined);

  // ページ離脱時の初期化処理
  useEffect(
    () => {
      // LP詳細のデータが残っていると、
      // 承認済み→公開中のLP詳細に移動するときにダイアログが開いてしまう現象が起きるためここで空にしておく。
      dispatch(clearLpDetail());

      return () => {
        dispatch(clearLps());
        // 集計期間変更後、値が更新されるように
        dispatch(clearLpSearchDefaultAddTerm());
      };
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  // みんなのLP一覧取得/リクエストパラメーター初期値設定
  useEffect(() => {
    if (!userInfo) return undefined;

    if (!lpSearchCondition) {
      dispatch(
        Usecases.lps.sendGetLpSearchDefaultAddTerm(token, userInfo.tenantId),
      );

      // 集計期間が全期間ならリクエストに集計期間を設定しない
      if (
        lpSearchDefaultAddTerm &&
        lpSearchDefaultAddTerm.addTermFrom === null &&
        lpSearchDefaultAddTerm.addTermTo === null
      ) {
        dispatch(
          setLpSearchCondition({
            page: 1,
            row: pageRows,
          }),
        );
      } else if (
        lpSearchDefaultAddTerm &&
        lpSearchDefaultAddTerm.addTermFrom !== '' &&
        lpSearchDefaultAddTerm.addTermTo !== ''
      ) {
        dispatch(
          setLpSearchCondition({
            page: 1,
            row: pageRows,
            add_term_from: lpSearchDefaultAddTerm.addTermFrom,
            add_term_to: lpSearchDefaultAddTerm.addTermTo,
          }),
        );
      }
    }

    if (
      userInfo &&
      lpSearchCondition &&
      lpSearchCondition.page &&
      lpSearchCondition.row
    )
      dispatch(
        Usecases.lps.sendGetLps(token, userInfo.tenantId, lpSearchCondition),
      );

    return () => {
      clearLpSearchCondition();
      clearLpSearchDefaultAddTerm();
    };
  }, [lpSearchDefaultAddTerm, lpSearchCondition, userInfo, dispatch, token]);

  // 取得したLP数からページ数を計算しリクエストパラメーターに設定
  useEffect(() => {
    if (!userInfo) return;
    if (!lpSearchCondition) return;

    if (lps && lps.lps.length === 0 && lps.totalCount > 0) {
      const latestPage = Math.ceil(lps?.totalCount / pageRows);
      dispatch(
        setLpSearchCondition({
          ...lpSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [lps, userInfo, dispatch, lpSearchCondition]);

  // LPタグ取得リクエストパラメーター初期値設定
  useEffect(() => {
    if (!userInfo) return;

    dispatch(setLpTagsRequest({ tagType: 'all', sortKey: 'tag' }));
  }, [dispatch, userInfo]);

  // LPタグ取得
  useEffect(() => {
    if (!userInfo) return undefined;
    dispatch(
      Usecases.lps.sendGetLpTags(token, userInfo.tenantId, lPTagsRequest),
    );

    return () => {
      dispatch(clearLpTags());
    };
  }, [lPTagsRequest, userInfo, dispatch, token]);

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (lpSearchCondition?.sort_key === sortKey) {
      order = lpSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        sort_key: sortKey as LpSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * ステータスチェックボックスチェック時
   * 該当のLPステータスをリクエストパラメーターに設定
   * @param status
   */
  const handleCheckStatus = (status: string) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        status,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  // lpステータスチェック追加
  // eslint-disable-next-line camelcase
  const handleCheckLpStatus = (lp_status: string) => {
    if (!lpSearchCondition || !lpSearchCondition.lp_status) {
      dispatch(
        setLpSearchCondition({
          ...lpSearchCondition,
          // eslint-disable-next-line camelcase
          lp_status: `${lp_status}`,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }

    const statusArray = lpSearchCondition.lp_status.split(',');

    // eslint-disable-next-line camelcase
    const index = statusArray.indexOf(`${lp_status}`);
    if (index < 0) {
      // eslint-disable-next-line camelcase
      statusArray.push(`${lp_status}`);
    } else {
      statusArray.splice(index, 1);
    }

    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        lp_status: statusArray.join(','),
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  /**
   * タグ変更時パラメーターに設定
   * @param tags
   */
  const handleChangeTag = (tags: string) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        tags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * 作成者絞り込みパラメータ
   * @param users 
   */
   const handleChangeLpTantoUser = (users: Array<UserInfoDetail>) => {
    let ids = '';
    users.forEach((item) => {
      ids += `${item.userId},`;
    });
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        lp_tanto_user_ids: ids,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * 自分のみ表示ラジオボタン切り替え時
   * リクエストパラメーターに設定
   * @param checked
   */
  const handleChangeOwnSwitch = (checked: boolean) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        own: checked,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * ☆マーククリック時お気に入り更新処理
   */
  const handleClickFavorite = useCallback(
    (lpId: number, checked: boolean) => {
      if (!userInfo || !lps) return;
      dispatch(
        Usecases.lps.sendPatchLpFavorite(
          token,
          userInfo.tenantId,
          lpId,
          checked,
        ),
      );
      dispatch(
        setLps({
          ...lps,
          lps: [
            ...lps?.lps.map((lp) => {
              if (lp.lpId !== lpId) return lp;

              return {
                ...lp,
                favorite: checked,
                favoriteCount: lp.favoriteCount + (checked ? 1 : -1),
              };
            }),
          ],
        }),
      );
    },
    [userInfo, lps, dispatch, token],
  );

  // タグxボタン押下
  const deleteTag = (val: string) => {
    if (!lpSearchCondition || !lpSearchCondition.tags) {
      dispatch(
        setLpSearchCondition({
          ...lpSearchCondition,
          tags: '',
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }

    const newTags = lpSearchCondition.tags
      .split(',')
      .filter((tag: string) => tag !== val);
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        tags: newTags.join(','),
        free_word: freeWord?.freeWord,
      }),
    );
  };

  const handleClickLpProductClose = () => {
    setOpenLpProductDetailDialog(undefined);
  };

  const handleClickLpProductInterestedCount = async (lpId: number) => {
    if (!userInfo || !lps) return;

    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await dispatch(
      Usecases.lps.sendGetLpProductInterestedCount(
        token,
        userInfo.tenantId,
        lpId,
      ),
    );

    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await setOpenLpProductDetailDialog('interested-count');
  };

  const handleClickLpProductDocumentDownloadCount = async (lpId: number) => {
    if (!userInfo || !lps) return;
    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await dispatch(
      Usecases.lps.sendGetLpProductDocumentDownloadCount(
        token,
        userInfo.tenantId,
        lpId,
      ),
    );
    // eslint-disable-next-line  @typescript-eslint/await-thenable
    await setOpenLpProductDetailDialog('document-download-count');
  };

  return (
    <LPs
      rows={pageRows}
      lpSearchCondition={lpSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleCheckStatus={handleCheckStatus}
      handleChangeFreeWord={handleChangeFreeWord}
      handleChangeTag={handleChangeTag}
      tags={lpTags}
      handleChangeOwnSwitch={handleChangeOwnSwitch}
      handleClickFavorite={handleClickFavorite}
      deleteTag={deleteTag}
      openLpProductDetailDialog={openLpProductDetailDialog}
      handleClickLpProductInterestedCount={handleClickLpProductInterestedCount}
      handleClickLpProductDocumentDownloadCount={
        handleClickLpProductDocumentDownloadCount
      }
      handleClickLpProductClose={handleClickLpProductClose}
      handleCheckLpStatus={handleCheckLpStatus}
      handleChangeLpTantoUser={handleChangeLpTantoUser}
    />
  );
});
export default LPsPageContainer;

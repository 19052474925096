import { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Link } from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';

/**
 * Interface
 */

export interface IntroductionExampleLabelProps {
  exampleName: string;
  customerName: string;
  click: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      background: theme.palette.gray[400],
      borderRadius: 10,
      padding: theme.spacing(3),
    },
    example: {
      fontWeight: 'bold',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      color: theme.palette.gray[900],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginBottom: theme.spacing(1),
      '&:hover': {
        textDecoration: 'none',
      },
      wordWrap: 'break-word',
    },
    exampleHover: {
      fontWeight: 'bold',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      color: theme.palette.green[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginBottom: theme.spacing(1),
    },
    company: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      wordWrap: 'break-word',
    },
  }),
);

/**
 * Presenter
 */

const IntroductionExampleLabel = (props: IntroductionExampleLabelProps) => {
  const classes = useStyles({});
  const [hovered, setHovered] = useState(false);
  const { exampleName, customerName, click } = props;

  return (
    <Box
      className={classes.root}
      boxShadow={!hovered ? 0 : 3}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={click}
    >
      <Link
        display="block"
        className={!hovered ? classes.example : classes.exampleHover}
        underline="none"
      >
        <span>{exampleName}</span>
      </Link>
      <Link display="block" className={classes.company} underline="none">
        <span>{customerName}</span>
      </Link>
    </Box>
  );
};
export default IntroductionExampleLabel;

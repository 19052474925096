import { CustomerTags } from 'core/domain/customer'

const initialState = null

export const createInitialState = (): CustomerTags | null => initialState

export const SET_CUSTOMER_TAGS = 'customer-tags/set' as const
export const CLEAR_CUSTOMER_TAGS = 'customer-tags/clear' as const

export const setCustomerTags = (data: CustomerTags) => ({
  type: SET_CUSTOMER_TAGS,
  payload: {
    data,
  }
})

export const clearCustomerTags = () => ({
  type: CLEAR_CUSTOMER_TAGS,
})

type SetCustomerTagsAction = ReturnType<typeof setCustomerTags>
type ClearCustomerTagsAction = ReturnType<typeof clearCustomerTags>
type Action = SetCustomerTagsAction | ClearCustomerTagsAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMER_TAGS:
      return action.payload.data
    case CLEAR_CUSTOMER_TAGS:
      return initialState
    default:
      return state
  }
}

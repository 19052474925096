/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { MaterialApprovers } from '../domain/user'

const initialState = null

export function createInitialState(): MaterialApprovers | null {
  return initialState
}

export const SET_MATERIAL_APPROVER_INFO = 'materialApprover-info/set' as const
export const CLEAR_MATERIAL_APPROVER_INFO = 'materialApprover-info/clear' as const

export function setMaterialApproversInfo(data: MaterialApprovers) {
  return {
    type: SET_MATERIAL_APPROVER_INFO,
    payload: {
      data,
    }
  }
}

export function clearMaterialApproversInfo() {
  return {
    type: CLEAR_MATERIAL_APPROVER_INFO
  }
}

type SetMaterialApproversInfo = ReturnType<typeof setMaterialApproversInfo>
type ClearMaterialApproversInfo = ReturnType<typeof clearMaterialApproversInfo>
type Action = SetMaterialApproversInfo | ClearMaterialApproversInfo

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_MATERIAL_APPROVER_INFO:
      return action.payload.data
    case CLEAR_MATERIAL_APPROVER_INFO:
      return initialState
    default:
      return state
  }
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { InquiriesGreetText } from 'core/domain/settings'

const initialState = null

export function createInitialState(): InquiriesGreetText | null {
  return initialState
}

export const SET_INQUIRIES_GREET_TEXT = 'inquiries-greet-text/set' as const
export const CLEAR_INQUIRIES_GREET_TEXT = 'inquiries-greet-text/clear' as const

export function setInquiriesGreetText(data: InquiriesGreetText) {
  return {
    type: SET_INQUIRIES_GREET_TEXT,
    payload: {
      data,
    },
  }
}

export function clearInquiriesGreetText() {
  return {
    type: CLEAR_INQUIRIES_GREET_TEXT,
  }
}

type SetInquiriesGreetTextAction = ReturnType<typeof setInquiriesGreetText>
type ClearInquiriesGreetTextAction = ReturnType<typeof clearInquiriesGreetText>
type Action = SetInquiriesGreetTextAction | ClearInquiriesGreetTextAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_INQUIRIES_GREET_TEXT:
      return action.payload.data
    case CLEAR_INQUIRIES_GREET_TEXT:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

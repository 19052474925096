import { Usage } from '../domain/dashborad';

const initialState = null;

export const createInitialState = (): Usage | null => initialState;

const SET_USAGE = 'usage/setUsage' as const;
const CLEAR_USAGE = 'settingNotices/clearUsage' as const;

export const setUsage = (payload: Usage) => ({
  type: SET_USAGE,
  payload,
});
export const clearUsage = () => ({
  type: CLEAR_USAGE,
});
type Action = ReturnType<typeof setUsage | typeof clearUsage>;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USAGE:
      return action.payload;
    case CLEAR_USAGE:
      return initialState;
    default:
      return state;
  }
};

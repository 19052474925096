import { UserInfoDetailRequest } from '../domain/user';

const initialState: UserInfoDetailRequest = {
  userId: null,
  userName: '',
  mailAddress: '',
  userType: '',
  lpAuthor: true,
  lpApprover: false,
  materialAuthor: false,
  materialApprover: false,
  tenantAdmin: false,
  note1: '',
  note2: '',
  note3: '',
  available: true,
};

export const createInitialState = (): UserInfoDetailRequest | null =>
  initialState;

export const SET_USER_INFO_DETAIL_REQ = 'user-info-detail-req/set' as const;
export const CLEAR_USER_INFO_DETAIL_REQ = 'user-info-detail-req/clear' as const;

export const setUserInfoDetailReq = (data: UserInfoDetailRequest) => ({
  type: SET_USER_INFO_DETAIL_REQ,
  payload: {
    data,
  },
});

export const clearUserInfoDetailReq = () => ({
  type: CLEAR_USER_INFO_DETAIL_REQ,
});

type SetUserInfoDetailReq = ReturnType<typeof setUserInfoDetailReq>;
type ClearUserInfoDetailReq = ReturnType<typeof clearUserInfoDetailReq>;
type Action = SetUserInfoDetailReq | ClearUserInfoDetailReq;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USER_INFO_DETAIL_REQ:
      return action.payload.data;
    case CLEAR_USER_INFO_DETAIL_REQ:
      return initialState;
    default:
      return state;
  }
};

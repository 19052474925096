const initialState = false;

export const createInitialState = () => initialState;

export const SET_ADMIN_MODE = 'sidebar-admin-mode/set' as const;
export const CLEAR_ADMIN_MODE = 'sidebar-admin-mode/clear' as const;

export const setSidebarAdminMode = (sidebarAdminMode: boolean) => ({
  type: SET_ADMIN_MODE,
  payload: {
    sidebarAdminMode,
  },
});

export const clearSidebarAdminMode = () => ({
  type: CLEAR_ADMIN_MODE,
});

type SetSidebarAdminModeAction = ReturnType<typeof setSidebarAdminMode>;
type ClearSidebarAdminModeAction = ReturnType<typeof clearSidebarAdminMode>;
type Action = SetSidebarAdminModeAction | ClearSidebarAdminModeAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_ADMIN_MODE:
      return action.payload.sidebarAdminMode;
    case CLEAR_ADMIN_MODE:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

/**
 * ファイルダウンロード
 * @param url ダウンロード対象のURL
 * @param fileName ファイル名
 */
export async function downloadFileFromUrl(url: string, fileName: string) {
  const res = await fetch(url, {
    method: 'GET',
  });
  const blob = await res.blob();
  const tempLink = document.createElement('a');
  tempLink.href = window.URL.createObjectURL(blob);
  tempLink.target = '_blank';
  tempLink.setAttribute('download', fileName);
  tempLink.click();
}

/**
 * ファイルダウンロード
 * @param base64 BASE64文字列
 * @param fileName ファイル名
 */
export async function downloadFileFromBase64(base64: string, fileName: string) {
  const blob = toBlob(base64);
  const tempLink = document.createElement('a');
  tempLink.href = window.URL.createObjectURL(blob);
  tempLink.target = '_blank';
  tempLink.setAttribute('download', fileName);
  tempLink.click();
}

function toBlob(base64: string): Blob | undefined {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i));
  try {
    const blob = new Blob([buffer.buffer], {
      type: 'text/csv',
    });

    return blob;
  } catch (e) {
    return undefined;
  }
} /* eslint-enable */

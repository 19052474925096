import { Users } from 'core/domain/user';

const initialState = null;

export const createInitialState = (): Users | null => initialState;

export const SET_MANAGE_USERS = 'manageUsers/set' as const;
export const CLEAR_MANAGE_USERS = 'manageUsers/clear' as const;

export const setManageUsers = (data: Users) => ({
  type: SET_MANAGE_USERS,
  payload: { data },
});

export const clearManageUsers = () => ({
  type: CLEAR_MANAGE_USERS,
});

type SetManageUsersAction = ReturnType<typeof setManageUsers>;
type ClearManageUsersAction = ReturnType<typeof clearManageUsers>;
type Action = SetManageUsersAction | ClearManageUsersAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_MANAGE_USERS:
      return action.payload.data;
    case CLEAR_MANAGE_USERS:
      return initialState;
    default:
      return state;
  }
};


import { CustomerDetail } from 'core/domain/customer'

const initialState = null

export const SET_CUSTOMER_DETAIL = 'customer-detail/set' as const
export const CLEAR_CUSTOMER_DETAIL = 'customer-detail/clear' as const

export const createInitialState = (): CustomerDetail | null => initialState

export const setCustomerDetail = (data: CustomerDetail) => ({
  type: SET_CUSTOMER_DETAIL,
  payload: {
    data,
  },
})

export const clearCustomerDetail = () => ({
  type: CLEAR_CUSTOMER_DETAIL,
})

type SetCustomerDetailAction = ReturnType<typeof setCustomerDetail>
type ClearCustomerDetailAction = ReturnType<typeof clearCustomerDetail>
type Action = SetCustomerDetailAction | ClearCustomerDetailAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAIL:
      return action.payload.data
    case CLEAR_CUSTOMER_DETAIL:
      return initialState
    default:
      return state
  }
}

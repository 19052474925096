/* このファイルでは、bit演算、for-ofを使用するため、以下の通りルールを無効化する. */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */

import { Severity } from 'components/atoms/MessageAlert';
import {
  CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
  CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_APPROVAL,
} from 'constants/code';
import { UserFlg, UserFlgs } from 'constants/flgs';
import { Modules } from 'core';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useProductDetailPermission = () => {
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
  );
  const productWorkflows = useSelector(
    (state: Modules.AppState) => state.productWorkflows,
  );

  const step = useMemo(() => {
    if (!productWorkflows || !productWorkflows.length) return 0;
    const workflow = productWorkflows[0];
    let step = 0;
    const regex = new RegExp(/^step[1-3]Status/);
    const stepRegex = new RegExp(/[1-3]/);
    for (const [key, value] of Object.entries(workflow)) {
      if (regex.test(key) && value === CODE_MATERIAL_WORKFLOW_STATUS_DECLINE) {
        return 0;
      }
      if (
        regex.test(key) &&
        value === CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL
      ) {
        const res = stepRegex.exec(key);
        if (res) {
          const tmpStep = Number.parseInt(res[0], 10);
          if (step === 0 || step > tmpStep) {
            step = tmpStep;
          }
        }
      }
    }

    if (
      step === 0 &&
      workflow.workflowStatus === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL
    ) {
      return (
        [
          workflow.step1ApprovalUserId,
          workflow.step2ApprovalUserId,
          workflow.step3ApprovalUserId,
        ].filter((userId) => !!userId).length + 1
      );
    }

    return step;
  }, [productWorkflows]);

  const checkUserFlg = useCallback(() => {
    const user: UserFlg = {
      flags: UserFlgs.None,
    };
    if (userInfo?.tenantAdmin) {
      user.flags |= UserFlgs.TenantAdmin;
    }

    if (productDetail && userInfo?.materialAuthor) {
      user.flags |= UserFlgs.MaterialAuthor;
    }

    if (productWorkflows && productWorkflows[0] && step) {
      const workflows = productWorkflows[0];
      for (const [key, value] of Object.entries(workflows)) {
        if (
          key === `step${step}ApprovalUserId` &&
          Number.parseInt(value, 10) === userInfo?.userId
        ) {
          user.flags |= UserFlgs.MaterialApprover;
        }
      }
    }

    return user;
  }, [productDetail, productWorkflows, step, userInfo]);

  const declinePermission = useMemo(() => {
    const userFlg = checkUserFlg();
    if (
      !(userFlg.flags & UserFlgs.TenantAdmin) &&
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.MaterialApprover)
    )
      return undefined;

    switch (productDetail?.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_DRAFT:
      case CODE_MATERIAL_STATUS_DECLINE:
        return {
          show: true,
          disabled: true,
        };
      case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
        return {
          show: true,
          disabled: false,
        };
      default:
        return {
          show: false,
          disabled: true,
        };
    }
  }, [checkUserFlg, productDetail]);

  const deletePermission = useMemo(() => {
    const userFlg = checkUserFlg();

    if (
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.TenantAdmin)
    )
      return undefined;

    switch (productDetail?.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_DRAFT:
      case CODE_MATERIAL_STATUS_DECLINE:
      case CODE_MATERIAL_STATUS_APPROVAL:
      case CODE_MATERIAL_STATUS_EXPIRE:
      case CODE_MATERIAL_STATUS_END:
        return {
          show: true,
          disabled: false,
        };
      default:
        return {
          show: false,
          disabled: true,
        };
    }
  }, [checkUserFlg, productDetail]);

  const approvalPermission = useMemo(() => {
    const userFlg = checkUserFlg();

    if (
      userFlg.flags & UserFlgs.MaterialApprover ||
      userFlg.flags & UserFlgs.TenantAdmin
    ) {
      switch (productDetail?.productPeriod.materialStatus) {
        case CODE_MATERIAL_STATUS_DRAFT:
        case CODE_MATERIAL_STATUS_DECLINE:
          return {
            show: true,
            disabled: true,
          };
        case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: true,
          };
      }
    } else if (userFlg.flags & UserFlgs.MaterialAuthor) {
      switch (productDetail?.productPeriod.materialStatus) {
        case CODE_MATERIAL_STATUS_DRAFT:
        case CODE_MATERIAL_STATUS_DECLINE:
        case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
          return {
            show: true,
            disabled: true,
          };
        default:
          return {
            show: false,
            disabled: true,
          };
      }
    }

    return undefined;
  }, [checkUserFlg, productDetail]);

  const editPermission = useMemo(() => {
    const userFlg = checkUserFlg();
    if (
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.TenantAdmin)
    )
      return undefined;

    switch (productDetail?.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
        return {
          show: true,
          disabled: true,
        };
      case CODE_MATERIAL_STATUS_DRAFT:
      case CODE_MATERIAL_STATUS_DECLINE:
      case CODE_MATERIAL_STATUS_RELEASED:
      case CODE_MATERIAL_STATUS_SUSPENSION:
      case CODE_MATERIAL_STATUS_END:
      case CODE_MATERIAL_STATUS_EXPIRE:
        return {
          show: true,
          disabled: false,
        };
      default:
        return {
          show: false,
          disabled: true,
        };
    }
  }, [checkUserFlg, productDetail]);

  const suspensionPermission = useMemo(() => {
    // 条件に合致しない場合の設定
    const noPermResult = {
      show: false,
      disabled: true,
    };
    const userFlg = checkUserFlg();
    if (
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.TenantAdmin)
    )
      return noPermResult;

    switch (productDetail?.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_RELEASED:
        return {
          show: true,
          disabled: false,
        };
      default:
        return noPermResult;
    }
  }, [checkUserFlg, productDetail]);

  const reopenPermission = useMemo(() => {
    // 条件に合致しない場合の設定
    const noPermResult = {
      show: false,
      disabled: true,
    };
    const userFlg = checkUserFlg();
    if (
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.TenantAdmin)
    )
      return noPermResult;

    switch (productDetail?.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_SUSPENSION:
        return {
          show: true,
          disabled: false,
        };
      default:
        return noPermResult;
    }
  }, [checkUserFlg, productDetail]);

  const alertMessage = useMemo(() => {
    const userFlg = checkUserFlg();
    if (
      !(userFlg.flags & UserFlgs.MaterialAuthor) &&
      !(userFlg.flags & UserFlgs.MaterialApprover)
    )
      return undefined;

    if (
      userFlg.flags & UserFlgs.MaterialAuthor &&
      productDetail?.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_DECLINE
    ) {
      return {
        text: '申請が差し戻されました。差し戻し理由を確認の上、修正してください。',
        severity: 'error' as Severity,
      };
    }
    if (
      userFlg.flags & UserFlgs.MaterialApprover &&
      productDetail?.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_AWAITING_APPROVAL
    ) {
      return {
        text: '商材の承認依頼です。内容を確認の上、承認を行ってください。',
        severity: 'warning' as Severity,
      };
    }

    return undefined;
  }, [checkUserFlg, productDetail]);

  const approvalUsers = useMemo(() => {
    if (!productWorkflows || productWorkflows.length === 0) return undefined;
    const currentWorkflow = productWorkflows[0];
    const users = [
      currentWorkflow.step1ApprovalUserName,
      currentWorkflow.step2ApprovalUserName,
      currentWorkflow.step3ApprovalUserName,
    ];

    return users.filter((user) => user);
  }, [productWorkflows]);

  const enableFavorite = useMemo(() => {
    if (!productDetail) return false;

    return (
      productDetail.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_RELEASED ||
      productDetail.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_SUSPENSION ||
      productDetail.productPeriod.materialStatus === CODE_MATERIAL_STATUS_END ||
      productDetail.productPeriod.materialStatus === CODE_MATERIAL_STATUS_EXPIRE
    );
  }, [productDetail]);

  const enableRecommend = useMemo(() => {
    const userFlg = checkUserFlg();
    if (
      userFlg.flags & UserFlgs.MaterialAuthor ||
      userFlg.flags & UserFlgs.TenantAdmin ||
      userInfo?.materialApprover
    ) {
      return true;
    }

    return false;
  }, [checkUserFlg, userInfo]);

  const applyHistoryPermission = useMemo(() => {
    const userFlg = checkUserFlg();
    if (
      userFlg.flags & UserFlgs.MaterialAuthor ||
      userInfo?.materialApprover ||
      userFlg.flags & UserFlgs.TenantAdmin
    ) {
      return true;
    }

    return false;
  }, [checkUserFlg, userInfo]);

  return {
    step,
    checkUserFlg,
    declinePermission,
    deletePermission,
    approvalPermission,
    editPermission,
    suspensionPermission,
    reopenPermission,
    alertMessage,
    approvalUsers,
    enableFavorite,
    enableRecommend,
    applyHistoryPermission,
  };
};

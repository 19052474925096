import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { setInquiries } from 'core/modules/inquiries';
import {
  Inquiries,
  InquiryDetailResponse,
  InquirySupports,
  InquirySupportsRegisterRequest,
  InquiriesQueryParameter,
  InquiryInfoRequest,
  InquiryDetail,
} from 'core/domain/inquiries';
import { setInquiryDetail } from 'core/modules/inquiryDetail';
import { setInquirySupports } from 'core/modules/inquirySupports';
import { setAllInquiries } from 'core/modules/allInquiries';
import { InquiryForm } from 'core/domain/settings';

const didCallGetLpDetailApi = (dispatch: Dispatch, data: Inquiries) => {
  dispatch(setAllInquiries(data));
};

export const sendGetInquiries =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/inquiries
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/inquiries?own=true`,
      token,
      null,
      dispatch,
      didCallGetLpDetailApi,
    );
  };

const didCallGetInquiryDetail = (dispatch: Dispatch, data: InquiryDetail) => {
  dispatch(setInquiryDetail(data));
};

export const sendGetInquiryDetail =
  (token: string | undefined, tenantId: string, inquiryId: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/inquiries/${inquiryId}`,
      token,
      null,
      dispatch,
      didCallGetInquiryDetail,
    );
  };

// InquiryDetail削除。論理消去なので実態はPatch
const didCallDeleteInquiryDetail = (
  dispatch: Dispatch,
  data: InquiryDetailResponse,
) => {
  dispatch(setInquiryDetail(data.inquiry));
};

export const sendDeleteInquiryDetail =
  (token: string | undefined, tenantId: string, inquiryId: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/inquiries/${inquiryId}`,
      token,
      null,
      dispatch,
      didCallDeleteInquiryDetail,
    );
  };

const didCallGetInquirySupports = (
  dispatch: Dispatch,
  data: InquirySupports,
) => {
  dispatch(setInquirySupports(data));
};

export const sendGetInquirySupports =
  (token: string | undefined, tenantId: string, inquiryId: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/inquiries/${inquiryId}/supports`,
      token,
      null,
      dispatch,
      didCallGetInquirySupports,
    );
  };

const didCallPostInquirySupport = (
  dispatch: Dispatch,
  data: InquirySupports,
) => {
  dispatch(setInquirySupports(data));
};

export const sendPostInquirySupport =
  (
    token: string | undefined,
    tenantId: string,
    inquiryId: number,
    param: InquirySupportsRegisterRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/inquiries/${inquiryId}/supports`,
      token,
      JSON.stringify({ ...param }),
      dispatch,
      didCallPostInquirySupport,
    );
  };

const didCallPutInquirySupport = (
  dispatch: Dispatch,
  data: InquirySupports,
) => {
  dispatch(setInquirySupports(data));
};

export const sendPutInquirySupport =
  (
    token: string | undefined,
    tenantId: string,
    inquiryId: number,
    supportId: number,
    param: InquirySupportsRegisterRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/inquiries/${inquiryId}/supports/${supportId}`,
      token,
      JSON.stringify({ ...param }),
      dispatch,
      didCallPutInquirySupport,
    );
  };

const didCallGetInquiries = (dispatch: Dispatch, data: Inquiries) => {
  dispatch(setInquiries(data));
};

export const sendGetInquiriesTable =
  (
    token: string | undefined,
    tenantId: string,
    param: InquiriesQueryParameter,
  ) =>
  async (dispatch: Dispatch) => {
    const api = Api.createApi(param);
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/inquiries?${api}`,
      token,
      null,
      dispatch,
      didCallGetInquiries,
    );
  };

const didCallPostInquiryRegister = (
  dispatch: Dispatch,
  data: InquiryDetail,
) => {
  dispatch(setInquiryDetail(data));
};

export const sendPostInquiryRegister =
  (token: string | undefined, tenantId: string, inquiry: InquiryInfoRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/inquiries`,
      token,
      JSON.stringify(inquiry),
      dispatch,
      didCallPostInquiryRegister,
    );
  };

const didCallPutInquiryRegister = (dispatch: Dispatch, data: InquiryDetail) => {
  dispatch(setInquiryDetail(data));
};

export const sendPutInquiryRegister =
  (
    token: string | undefined,
    tenantId: string,
    inquiryId: string,
    inquiry: InquiryInfoRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/inquiries/${inquiryId}`,
      token,
      JSON.stringify(inquiry),
      dispatch,
      didCallPutInquiryRegister,
    );
  };

  const didCallPutInquiryForms = (/* dispath:Dispatch,data:Array<InquiryForm> */) => {
    // console.log("called")
    // console.log(data)
  }
  
export const sendPutInquiryForms = 
  (
    token: string,
    tenantId:string,
    data: Array<InquiryForm>,
    nextFunc: (() => void) | null | undefined,
  ) =>
  async(dispatch:Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/settings/inquiries/form`,
      token,
      JSON.stringify({inquiryForms:data}),
      dispatch,
      didCallPutInquiryForms
    ).finally(nextFunc);
  }
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Divider,
  Grid,
  Box,
  PrimaryButton,
  SecondaryButton,
  ButtonLink,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { hooks } from 'libs';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { useEffect, useMemo, useState } from 'react';

/**
 * Interface
 */
export interface InquiryOverviewProps {
  handleDeleteInquiryDetail: () => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.green[800],
    },
    productSubtitle: {
      margin: '33px 0px 0px 0px',
    },
    productName: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: theme.palette.gray[900],
      marginBottom: theme.spacing(1),
    },
    preview: {
      float: 'right',
    },
    thickBorder: {
      marginTop: 18,
      height: 3,
      backgroundColor: theme.palette.gray[600],
      borderColor: theme.palette.gray[600],
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
    },
    caption: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
    },
    usePeriod: {
      margin: '0px 16px',
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    container: {
      margin: '10px 0px',
    },
    videoLink: {
      height: '100%',
      marginLeft: '16px',
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
    },
    marginBottom: {
      marginBottom: 8,
    },
    multipleDescriptionLeft: {
      transform: `translateX(${theme.spacing(1) / 2}px)`,
    },
    multipleDescriptionRight: {
      transform: `translateX(${-theme.spacing(1) / 2}px)`,
    },
    secBtn: {
      marginRight: '8px',
    },
    priBtn: {
      marginLeft: '8px',
    },
    detail: {
      marginLeft: '0px',
    },
    togglerTxt1: {
      '& .MuiButton-label': {
        fontSize: '18px',
        color: theme.palette.gray[800],
      },
    },
  }),
);

const InquiryOverview = (props: InquiryOverviewProps) => {
  const classes = useStyles({});
  const { handleDeleteInquiryDetail } = props;
  const { navigate } = hooks.useNavigate();
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);

  const inquiryDetail = useSelector(
    (state: Modules.AppState) => state.inquiryDetail,
  );

  const inquiriesForm = useSelector(
    (state: Modules.AppState) => state.inquiriesForm,
  );

  const inquiries = useSelector((state: Modules.AppState) => state.inquiries);
  const [buttonDisp, setButtonDisp] = useState<boolean>(false);
  // 修正ボタンの表示非表示
  const [editButtonDisp, setEditButtonDisp] = useState<boolean>(false);

  const handleInquiryDelete = () => {
    // 確認通知後、OKなら削除API飛ばして成功したらダイアログ->一覧に画面遷移
    // /{tenant_id}/inquiries/{inquiry_id}  patch  query:Authorization

    handleDeleteInquiryDetail();
  };

  const handleInquiryEdit = () =>
    inquiryDetail && navigate(`/adm/inquiry/${inquiryDetail.inquiryId}/edit`);

  const lpLink = useMemo(() => {
    if (inquiryDetail && inquiryDetail.lpId) {
      return (
        <ButtonLink
          text={(inquiryDetail && inquiryDetail.lpName) || ''}
          tooltipText={(inquiryDetail && inquiryDetail.lpName) || ''}
          click={() => {
            navigate(
              `/adm/lps/${
                (inquiryDetail && inquiryDetail.lpId) || ''
              }/approved`,
            );
          }}
        />
      );
    }

    return (
      <Typography
        variant="body2"
        className={classes.description}
        display="inline"
      >
        {inquiryDetail && inquiryDetail.lpName}
      </Typography>
    );
  }, [classes.description, inquiryDetail, navigate]);

  useEffect(() => {
    if (inquiryDetail === null) {
      setButtonDisp(false);

      return undefined;
    }

    // お問い合わせフォーム
    if (inquiryDetail.inquiryType === 9) {
      setButtonDisp(false);

      return undefined;
    }

    // テナント管理者
    if (userInfo?.tenantAdmin) {
      setButtonDisp(true);

      return undefined;
    }

    // LP担当者
    if (inquiryDetail.lpTantoUserId === userInfo?.userId) {
      setButtonDisp(true);

      return undefined;
    }
    setButtonDisp(false);

    return undefined;
  }, [
    inquiries,
    inquiryDetail,
    userInfo?.displayName,
    userInfo?.tenantAdmin,
    userInfo?.userId,
  ]);

  useEffect(() => {
    if (!inquiryDetail || !inquiriesForm || !inquiriesForm.updateDate)
      return;

    // お問い合わせ最終更新日時
    const inqUpdateDate =
      inquiryDetail.updateDate.split('T')[0].replaceAll('-', '');
    const inqUpdateTime =
      inquiryDetail.updateDate.split('T')[1].replaceAll(':', '').replaceAll('Z', '');
    const inqUpdateDateTime = parseInt(`${inqUpdateDate}${inqUpdateTime}`, 10);
    // お問い合わせフォーム更新日時
    const formUpdateDate =
      inquiriesForm.updateDate.split('T')[0].replaceAll('-', '');
    const formUpdateTime =
      inquiriesForm.updateDate.split('T')[1].replaceAll(':', '').replaceAll('Z', '');
    const formUpdateDateTime = parseInt(`${formUpdateDate}${formUpdateTime}`, 10);

    if (inqUpdateDateTime < formUpdateDateTime) {
      // 修正ボタン非表示
      setEditButtonDisp(false);
    } else {
      // 修正ボタン表示
      setEditButtonDisp(true);
    }
  }, [inquiriesForm, inquiryDetail]);

  return (
    <>
      <Grid>
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              className={classes.subtitle}
              display="inline"
            >
              お問い合わせID
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.multipleDescriptionLeft}>
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              {inquiryDetail?.inquiryId}
            </Typography>
          </Grid>
        </Grid>
        {/* 1-1段目 LP名表示 */}
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              className={classes.subtitle}
              display="inline"
            >
              ランディングページ名称
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {lpLink}
          </Grid>
        </Grid>

        {/* 1-2段目 会社名等の情報があれば表として表示 */}
        {/* Dividerで各行に区切り線 */}
        <Divider />
        {inquiryDetail &&
          inquiryDetail.inquiryFormAnswers &&
          inquiryDetail.inquiryFormAnswers?.map((ele, index) => (
            <Grid key={index} item xs={12}>
              <Grid container spacing={0} className={classes.container}>
                <Grid item xs={2}>
                  <Typography
                    variant="body2"
                    className={classes.subtitle}
                    display="inline"
                  >
                    {ele.inquiryItemName}
                  </Typography>
                </Grid>
                <Grid item xs={10} className={classes.multipleDescriptionLeft}>
                  <Typography
                    variant="body2"
                    className={classes.description}
                    display="inline"
                  >
                    {ele.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {/* 削除ボタンと修正ボタン */}
      {buttonDisp && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'space-around',
            }}
          >
            <Box
              className={classes.priBtn}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'space-around',
                paddingTop: 20,
              }}
            >
              <Box width={332} height={48} marginLeft={10} marginRight={10}>
                <SecondaryButton
                  click={() => {
                    handleInquiryDelete();
                  }}
                  text="削除する"
                />
              </Box>

              {editButtonDisp && (
                <Box width={332} height={48} marginLeft={10} marginRight={10}>
                  <PrimaryButton
                    click={() => {
                      // お問い合わせidに紐づくエディットへ遷移
                      handleInquiryEdit();
                      // inquirySupportsRegisterRequest);
                    }}
                    text="お問い合わせ情報を修正する"
                  />
                </Box>
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
};
export default InquiryOverview;

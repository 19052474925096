import { NoticeDetail } from 'core/domain/settings';

const initialState = null;

export const createInitialState = (): Array<NoticeDetail> | null =>
  initialState;

const SET_SETTING_NOTICES = 'settingNotices/set' as const;
const CLEAR_SETTING_NOTICES = 'settingNotices/clear' as const;

export const setSettingNotices = (data: Array<NoticeDetail>) => ({
  type: SET_SETTING_NOTICES,
  payload: {
    data,
  },
});

export const clearSettingNotices = () => ({
  type: CLEAR_SETTING_NOTICES,
});
type Action = ReturnType<typeof setSettingNotices | typeof clearSettingNotices>;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_SETTING_NOTICES:
      return action.payload.data;
    case CLEAR_SETTING_NOTICES:
      return initialState;
    default:
      return state;
  }
};

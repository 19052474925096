/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ExampleRegisterRequest } from 'core/domain/example'

const initialState = null

export function createInitialState(): ExampleRegisterRequest | null {
  return initialState
}

export const SET_NEW_EXAMPLE = 'new-example/set' as const
export const CLEAR_NEW_EXAMPLE = 'new-example/clear' as const

export function setNewExample(data: ExampleRegisterRequest) {
  return {
    type: SET_NEW_EXAMPLE,
    payload: {
      data,
    },
  }
}

export function clearNewExample() {
  return {
    type: CLEAR_NEW_EXAMPLE,
  }
}

type SetNewExampleAction = ReturnType<typeof setNewExample>
type ClearNewExampleAction = ReturnType<typeof clearNewExample>
type Action = SetNewExampleAction | ClearNewExampleAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NEW_EXAMPLE:
      return action.payload.data
    case CLEAR_NEW_EXAMPLE:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import { combineReducers } from 'redux';
import * as SidebarShift from 'core/modules/sidebarShift';
import * as SidebarShown from 'core/modules/sidebarShown';
import * as SidebarAdminMode from 'core/modules/sidebarAdminMode';
import * as HeaderShown from 'core/modules/headerShown';
import * as Network from 'core/modules/network';
import * as ApiError from 'core/modules/error';
import * as ExampleDetail from 'core/modules/exampleDetail';
import * as LpDetail from 'core/modules/lpDetail';
import * as LpReaction from 'core/modules/lpReaction';
import * as LpInquiries from 'core/modules/lpInquiries';
import * as LpWorkflows from 'core/modules/lpWorkflows';
import * as ExampleWorkflows from 'core/modules/exampleWorkflows';
import * as NewExample from 'core/modules/newExample';
import * as NewProduct from 'core/modules/newProduct';
import * as RelationProducts from 'core/modules/relationProducts';
import * as ProductDetail from 'core/modules/productDetail';
import * as ProductTags from 'core/modules/productTags';
import * as ProductWorkflows from 'core/modules/productWorkflows';
import * as ProductExamples from 'core/modules/productExamples';
import * as ProductTagsRequest from 'core/modules/productTagsRequest';
import * as DashboardNotices from 'core/modules/dashboardNotices';
import * as TenantDashboardNotices from 'core/modules/tenantDashboardNotices';
import * as Notices from 'core/modules/notices';
import * as Inquiries from 'core/modules/inquiries';
import * as AllInquiries from 'core/modules/allInquiries';
import * as NewLP from 'core/modules/newLp';
import * as UserInfo from 'core/modules/userInfo';
import * as LpApproverInfo from 'core/modules/lpApprovers';
import * as LPTags from 'core/modules/lpTags';
import * as LPTagsRequest from 'core/modules/lpTagsRequest';
import * as TokuisakiFixedPhraseTexts from 'core/modules/tokuisakiFixedPhraseTexts';
import * as ProfileFixedPhraseTexts from 'core/modules/profileFixedPhraseTexts';
import * as Products from 'core/modules/products';
import * as Examples from 'core/modules/examples';
import * as ProductComment from 'core/modules/productComment';
import * as LPTokuisaki from 'core/modules/lpTokuisaki';
import * as NormalEditSidebarShown from 'core/modules/normalEditSidebarShown';
import * as NormalEditErrors from 'core/modules/normalEditErrors';
import * as Lps from 'core/modules/lps';
import * as LpSearchDefaultAddTerm from 'core/modules/lpSearchDefaultAddTerm';
import * as LpsOwn from 'core/modules/lpsOwn';
import * as LpsSubordinates from 'core/modules/lpsSubordinates';
import * as LpOwnSearchCondition from 'core/modules/lpOwnSearchCondition';
import * as LpSearchCondition from 'core/modules/lpSearchCondition';
import * as ProductSearchCondition from 'core/modules/productSearchCondition';
import * as ExampleQueryParameter from 'core/modules/examplesQueryParameter';
import * as InquiriesQueryParameter from 'core/modules/inquiriesQueryParameter';
import * as InquiriesGreetText from 'core/modules/inquiriesGreetText';
import * as InquiryForms from 'core/modules/inquiryForms';
import * as LpSettings from 'core/modules/lpSettings';
import * as LpProductDetails from 'core/modules/lpProductDetails';
import * as LpExampleDetails from 'core/modules/lpExampleDetails';
import * as LpView from 'core/modules/lpView';
import * as NewInquirySupport from 'core/modules/newInquirySupport';
import * as InquiryDetail from 'core/modules/inquiryDetail';
import * as InquirySupports from 'core/modules/inquirySupports';
import * as UserInfoReq from 'core/modules/userRegReq';
import * as MaterialApproverInfo from 'core/modules/materialApprovers';
import * as LpMainVisualImages from 'core/modules/lpMainVisualImages';
import * as TokuisakiTitles from 'core/modules/tokuisakiTitles';
import * as TantoCompanies from 'core/modules/tantoCompanies';
import * as AuthError from 'core/modules/authError';
import * as AddTerm from 'core/modules/addTerm';
import * as DesignTemplates from 'core/modules/designTemplates';
import * as DesignAllTemplates from 'core/modules/designAllTemplates';
import * as PreviousLpsUrl from 'core/modules/previousLpsUrl';
import * as IsDuplicatedLP from 'core/modules/isDuplicatedLP';
import * as CustomerDetail from 'core/modules/customerDetail';
import * as NewCustomer from 'core/modules/newCustomer';
import * as CustomerTags from 'core/modules/customerTags';
import * as CustomerTagsRequest from 'core/modules/customerTagsRequest';
import * as CustomerMailAddress from 'core/modules/customerMailAddress';
import * as UserInfoDetail from 'core/modules/userInfoDetail';
import * as UserInfoDetailRegReq from 'core/modules/userInfoDetailReq';
import * as NoticeDetail from 'core/modules/noticeDetail';
import * as DashboardLps from 'core/modules/dashboardLps';
import * as TotalReaction from 'core/modules/totalReaction';
import * as ApprovalStatus from 'core/modules/approvalStatus';
import * as ReactionRanking from 'core/modules/reactionRanking';
import * as InquiryInfoRequest from 'core/modules/InquiryInfoRequest';
import * as LpsAdmin from 'core/modules/lpsAdmin';
import * as Users from 'core/modules/users';
import * as VariousSettings from 'core/modules/variousSettings';
import * as NewVariousSettings from 'core/modules/newVariousSettings';
import * as DashboardAddTerm from 'core/modules/dashboardAddTerm';
import * as SettingNotice from 'core/modules/settingNotices';
import * as Usage from 'core/modules/usage';
import * as ManageNotices from 'core/modules/manageNotices';
import * as HeaderNotices from 'core/modules/headerNotices';
import * as NoticesSearchCondition from 'core/modules/noticesSearchCondition';
import * as NoticeDetails from 'core/modules/noticeDetails';
import * as NoticesDetail from 'core/modules/noticesDetail';
import * as NoticesQueryParameter from 'core/modules/noticesQueryParameter';
import * as ManageUsers from 'core/modules/manageUsers';
import * as ManageUsersSearchCondition from 'core/modules/manageUsersSearchCondition';
import * as Customers from 'core/modules/customers';
import * as CustomersQueryParameter from 'core/modules/customersQueryParameter';
import * as DeleteCustomers from 'core/modules/deleteCustomers';
import * as MyLibrary from 'core/modules/myLibrary';
import * as MailHistory from 'core/modules/mailHistory';
import * as InquiryFormsItem from 'core/modules/inquiryFormsItem';
import * as LpAdminError from 'core/modules/lpAdminError';
import * as NoticeFiles from 'core/modules/noticeFiles';
import * as FreeWord from 'core/modules/freeWord';
import * as UserTags from 'core/modules/userTags';
import * as Trashes from 'core/modules/trashes';
import * as TrashesSearchCondition from 'core/modules/trashesSearchCondition';
import * as TrashesSearchDataType from 'core/modules/trashesSearchDataType';
import * as Usages from 'core/modules/usages';
import * as LpProductDetailCounts from 'core/modules/lpProductDetailCounts';
import * as LpOpenSettings from 'core/modules/lpOpenSettings';
import * as InquiriesForm from 'core/modules/inquiriesForm';
import * as ImageCut from 'core/modules/imageCut';

export type AppState = {
  readonly sidebarShift: ReturnType<typeof SidebarShift.createInitialState>;
  readonly sidebarShown: ReturnType<typeof SidebarShown.createInitialState>;
  readonly sidebarAdminMode: ReturnType<
    typeof SidebarAdminMode.createInitialState
  >;
  readonly headerShown: ReturnType<typeof HeaderShown.createInitialState>;
  readonly network: ReturnType<typeof Network.createInitialState>;
  readonly error: ReturnType<typeof ApiError.createInitialState>;
  readonly exampleDetail: ReturnType<typeof ExampleDetail.createInitialState>;
  readonly lpDetail: ReturnType<typeof LpDetail.createInitialState>;
  readonly lpReaction: ReturnType<typeof LpReaction.createInitialState>;
  readonly lpInquiries: ReturnType<typeof LpInquiries.createInitialState>;
  readonly exampleWorkflows: ReturnType<
    typeof ExampleWorkflows.createInitialState
  >;
  readonly newExample: ReturnType<typeof NewExample.createInitialState>;
  readonly newProduct: ReturnType<typeof NewProduct.createInitialState>;
  readonly productDetail: ReturnType<typeof ProductDetail.createInitialState>;
  readonly NoticeDetail: ReturnType<typeof NoticeDetail.createInitialState>;
  readonly relationProducts: ReturnType<
    typeof RelationProducts.createInitialState
  >;
  readonly lpWorkflows: ReturnType<typeof LpWorkflows.createInitialState>;
  readonly productTags: ReturnType<typeof ProductTags.createInitialState>;
  readonly productWorkflows: ReturnType<
    typeof ProductWorkflows.createInitialState
  >;
  readonly productExamples: ReturnType<
    typeof ProductExamples.createInitialState
  >;
  readonly productTagsRequest: ReturnType<
    typeof ProductTagsRequest.createInitialState
  >;
  readonly headerNotices: ReturnType<typeof HeaderNotices.createInitialState>;
  readonly dashboardNotices: ReturnType<typeof Notices.createInitialState>;
  readonly tenantDashboardNotices: ReturnType<
    typeof Notices.createInitialState
  >;
  readonly notices: ReturnType<typeof Notices.createInitialState>;
  readonly noticeDetails: ReturnType<typeof NoticeDetails.createInitialState>;
  readonly inquiries: ReturnType<typeof Inquiries.createInitialState>;
  readonly allInquiries: ReturnType<typeof AllInquiries.createInitialState>;
  readonly newLp: ReturnType<typeof NewLP.createInitialState>;
  readonly userInfo: ReturnType<typeof UserInfo.createInitialState>;
  readonly lpApproverInfo: ReturnType<typeof LpApproverInfo.createInitialState>;
  readonly materialApproverInfo: ReturnType<
    typeof MaterialApproverInfo.createInitialState
  >;
  readonly lpTags: ReturnType<typeof LPTags.createInitialState>;
  readonly lPTagsRequest: ReturnType<typeof LPTagsRequest.createInitialState>;
  readonly tokuisakiFixedPhraseTexts: ReturnType<
    typeof TokuisakiFixedPhraseTexts.createInitialState
  >;
  readonly profileFixedPhraseTexts: ReturnType<
    typeof ProfileFixedPhraseTexts.createInitialState
  >;
  readonly products: ReturnType<typeof Products.createInitialState>;
  readonly examples: ReturnType<typeof Examples.createInitialState>;
  readonly productComment: ReturnType<typeof ProductComment.createInitialState>;
  readonly lpTokuisaki: ReturnType<typeof LPTokuisaki.createInitialState>;
  readonly normalEditSidebarShown: ReturnType<
    typeof NormalEditSidebarShown.createInitialState
  >;
  readonly normalEditErrors: ReturnType<
    typeof NormalEditErrors.createInitialState
  >;
  readonly lps: ReturnType<typeof Lps.createInitialState>;
  readonly lpsOwn: ReturnType<typeof LpsOwn.createInitialState>;
  readonly lpsSubordinates: ReturnType<
    typeof LpsSubordinates.createInitialState
  >;
  readonly lpOwnSearchCondition: ReturnType<
    typeof LpOwnSearchCondition.createInitialState
  >;
  readonly lpSearchCondition: ReturnType<
    typeof LpSearchCondition.createInitialState
  >;

  readonly lpSearchDefaultAddTerm: ReturnType<
    typeof LpSearchDefaultAddTerm.createInitialState
  >;

  readonly productSearchCondition: ReturnType<
    typeof ProductSearchCondition.createInitialState
  >;
  readonly exampleQueryParameter: ReturnType<
    typeof ExampleQueryParameter.createInitialState
  >;
  readonly inquiriesQueryParameter: ReturnType<
    typeof InquiriesQueryParameter.createInitialState
  >;
  readonly inquiriesGreetText: ReturnType<
    typeof InquiriesGreetText.createInitialState
  >;
  readonly inquiryForms: ReturnType<typeof InquiryForms.createInitialState>;
  readonly lpSettings: ReturnType<typeof LpSettings.createInitialState>;
  readonly lpProductDetails: ReturnType<
    typeof LpProductDetails.createInitialState
  >;

  readonly lpExampleDetails: ReturnType<
    typeof LpExampleDetails.createInitialState
  >;
  readonly lpView: ReturnType<typeof LpView.createInitialState>;
  readonly inquirySupports: ReturnType<
    typeof InquirySupports.createInitialState
  >;
  readonly inquiryDetail: ReturnType<typeof InquiryDetail.createInitialState>;
  readonly newInquirySupport: ReturnType<
    typeof NewInquirySupport.createInitialState
  >;
  readonly userInfoReq: ReturnType<typeof UserInfoReq.createInitialState>;
  readonly lpMainVisualImages: ReturnType<
    typeof LpMainVisualImages.createInitialState
  >;
  readonly tokuisakiTitles: ReturnType<
    typeof TokuisakiTitles.createInitialState
  >;
  readonly tantoCompanies: ReturnType<typeof TantoCompanies.createInitialState>;
  readonly authError: ReturnType<typeof AuthError.createInitialState>;
  readonly addTerm: ReturnType<typeof AddTerm.createInitialState>;
  readonly designTemplates: ReturnType<
    typeof DesignTemplates.createInitialState
  >;
  readonly designAllTemplates: ReturnType<
    typeof DesignAllTemplates.createInitialState
  >;
  readonly previousLpsUrl: ReturnType<typeof PreviousLpsUrl.createInitialState>;
  readonly isDuplicatedLP: ReturnType<typeof IsDuplicatedLP.createInitialState>;
  readonly userInfoDetail: ReturnType<typeof UserInfoDetail.createInitialState>;
  readonly userInfoDetailRegReq: ReturnType<
    typeof UserInfoDetailRegReq.createInitialState
  >;
  readonly customerDetail: ReturnType<typeof CustomerDetail.createInitialState>;
  readonly newCustomer: ReturnType<typeof NewCustomer.createInitialState>;
  readonly customerTags: ReturnType<typeof CustomerTags.createInitialState>;
  readonly customerTagsRequest: ReturnType<
    typeof CustomerTagsRequest.createInitialState
  >;
  readonly customerMailAddress: ReturnType<
    typeof CustomerMailAddress.createInitialState
  >;
  readonly dashboardLps: ReturnType<typeof DashboardLps.createInitialState>;
  readonly totalReaction: ReturnType<typeof TotalReaction.createInitialState>;
  readonly approvalStatus: ReturnType<typeof ApprovalStatus.createInitialState>;
  readonly reactionRanking: ReturnType<
    typeof ReactionRanking.createInitialState
  >;
  readonly inquiryInfoRequest: ReturnType<
    typeof InquiryInfoRequest.createInitialState
  >;
  readonly lpsAdmin: ReturnType<typeof LpsAdmin.createInitialState>;
  readonly users: ReturnType<typeof Users.createInitialState>;
  readonly variousSettings: ReturnType<
    typeof VariousSettings.createInitialState
  >;
  readonly newVariousSettings: ReturnType<
    typeof NewVariousSettings.createInitialState
  >;
  readonly dashboardAddTerm: ReturnType<
    typeof DashboardAddTerm.createInitialState
  >;
  readonly settingNotices: ReturnType<typeof SettingNotice.createInitialState>;
  readonly usage: ReturnType<typeof Usage.createInitialState>;

  readonly manageNotices: ReturnType<typeof ManageNotices.createInitialState>;
  readonly noticesSearchCondition: ReturnType<
    typeof NoticesSearchCondition.createInitialState
  >;
  readonly noticesQueryParameter: ReturnType<
    typeof NoticesQueryParameter.createInitialState
  >;
  readonly manageUsers: ReturnType<typeof ManageUsers.createInitialState>;
  readonly manageUsersSearchCondition: ReturnType<
    typeof ManageUsersSearchCondition.createInitialState
  >;
  readonly myLibrary: ReturnType<typeof MyLibrary.createInitialState>;
  readonly noticesDetail: ReturnType<typeof NoticesDetail.createInitialState>;
  readonly customers: ReturnType<typeof Customers.createInitialState>;
  readonly customersQueryParameter: ReturnType<
    typeof CustomersQueryParameter.createInitialState
  >;
  readonly deleteCustomers: ReturnType<
    typeof DeleteCustomers.createInitialState
  >;
  readonly mailHistory: ReturnType<typeof MailHistory.createInitialState>;
  readonly inquiryFormsItem: ReturnType<
    typeof InquiryFormsItem.createInitialState
  >;
  readonly lpAdminError: ReturnType<typeof LpAdminError.createInitialState>;

  readonly noticeFiles: ReturnType<typeof NoticeFiles.createInitialState>;
  readonly freeWord: ReturnType<typeof FreeWord.createInitialState>;
  readonly userTags: ReturnType<typeof UserTags.createInitialState>;
  readonly trashes: ReturnType<typeof Trashes.createInitialState>;
  readonly trashesSearchCondition: ReturnType<
    typeof TrashesSearchCondition.createInitialState
  >;
  readonly trashesSearchDataType: ReturnType<
    typeof TrashesSearchDataType.createInitialState
  >;
  readonly usages: ReturnType<typeof Usages.createInitialState>;

  readonly lpProductDetailCounts: ReturnType<
    typeof LpProductDetailCounts.createInitialState
  >;
  readonly imageCut: ReturnType<typeof ImageCut.createInitialState>;
  readonly lpOpenSettings: ReturnType<typeof LpOpenSettings.createInitialState>;
  readonly inquiriesForm: ReturnType<typeof InquiriesForm.createInitialState>;
};

export const createInitialState = (): AppState => ({
  sidebarShift: SidebarShift.createInitialState(),
  sidebarShown: SidebarShown.createInitialState(),
  sidebarAdminMode: SidebarAdminMode.createInitialState(),
  headerShown: HeaderShown.createInitialState(),
  network: Network.createInitialState(),
  error: ApiError.createInitialState(),
  exampleDetail: ExampleDetail.createInitialState(),
  lpDetail: LpDetail.createInitialState(),
  lpReaction: LpReaction.createInitialState(),
  lpInquiries: LpInquiries.createInitialState(),
  lpWorkflows: LpWorkflows.createInitialState(),
  lpsAdmin: LpsAdmin.createInitialState(),
  exampleWorkflows: ExampleWorkflows.createInitialState(),
  newExample: NewExample.createInitialState(),
  newProduct: NewProduct.createInitialState(),
  productDetail: ProductDetail.createInitialState(),
  relationProducts: RelationProducts.createInitialState(),
  productTags: ProductTags.createInitialState(),
  productWorkflows: ProductWorkflows.createInitialState(),
  productExamples: ProductExamples.createInitialState(),
  productTagsRequest: ProductTagsRequest.createInitialState(),
  headerNotices: HeaderNotices.createInitialState(),
  notices: Notices.createInitialState(),
  inquiries: Inquiries.createInitialState(),
  allInquiries: AllInquiries.createInitialState(),
  newLp: NewLP.createInitialState(),
  userInfo: UserInfo.createInitialState(),
  lpApproverInfo: LpApproverInfo.createInitialState(),
  materialApproverInfo: MaterialApproverInfo.createInitialState(),
  lpTags: LPTags.createInitialState(),
  lPTagsRequest: LPTagsRequest.createInitialState(),
  tokuisakiFixedPhraseTexts: TokuisakiFixedPhraseTexts.createInitialState(),
  profileFixedPhraseTexts: ProfileFixedPhraseTexts.createInitialState(),
  products: Products.createInitialState(),
  examples: Examples.createInitialState(),
  productComment: ProductComment.createInitialState(),
  lpTokuisaki: LPTokuisaki.createInitialState(),
  normalEditSidebarShown: NormalEditSidebarShown.createInitialState(),
  normalEditErrors: NormalEditErrors.createInitialState(),
  lps: Lps.createInitialState(),
  lpsOwn: LpsOwn.createInitialState(),
  lpsSubordinates: LpsSubordinates.createInitialState(),
  lpOwnSearchCondition: LpOwnSearchCondition.createInitialState(),
  lpSearchCondition: LpSearchCondition.createInitialState(),
  lpSearchDefaultAddTerm: LpSearchDefaultAddTerm.createInitialState(),
  productSearchCondition: ProductSearchCondition.createInitialState(),
  exampleQueryParameter: ExampleQueryParameter.createInitialState(),
  inquiriesQueryParameter: InquiriesQueryParameter.createInitialState(),
  inquiriesGreetText: InquiriesGreetText.createInitialState(),
  inquiryForms: InquiryForms.createInitialState(),
  lpSettings: LpSettings.createInitialState(),
  lpProductDetails: LpProductDetails.createInitialState(),
  lpExampleDetails: LpExampleDetails.createInitialState(),
  lpView: LpView.createInitialState(),
  inquirySupports: InquirySupports.createInitialState(),
  inquiryDetail: InquiryDetail.createInitialState(),
  newInquirySupport: NewInquirySupport.createInitialState(),
  userInfoReq: UserInfoReq.createInitialState(),
  lpMainVisualImages: LpMainVisualImages.createInitialState(),
  tokuisakiTitles: TokuisakiTitles.createInitialState(),
  tantoCompanies: TantoCompanies.createInitialState(),
  authError: AuthError.createInitialState(),
  addTerm: AddTerm.createInitialState(),
  designTemplates: DesignTemplates.createInitialState(),
  designAllTemplates: DesignAllTemplates.createInitialState(),
  previousLpsUrl: PreviousLpsUrl.createInitialState(),
  isDuplicatedLP: IsDuplicatedLP.createInitialState(),
  userInfoDetail: UserInfoDetail.createInitialState(),
  userInfoDetailRegReq: UserInfoDetailRegReq.createInitialState(),
  NoticeDetail: NoticeDetail.createInitialState(),
  customerDetail: CustomerDetail.createInitialState(),
  newCustomer: NewCustomer.createInitialState(),
  customerTags: CustomerTags.createInitialState(),
  customerTagsRequest: CustomerTagsRequest.createInitialState(),
  customerMailAddress: CustomerMailAddress.createInitialState(),
  dashboardLps: DashboardLps.createInitialState(),
  totalReaction: TotalReaction.createInitialState(),
  approvalStatus: ApprovalStatus.createInitialState(),
  reactionRanking: ReactionRanking.createInitialState(),
  inquiryInfoRequest: InquiryInfoRequest.createInitialState(),
  users: Users.createInitialState(),
  variousSettings: VariousSettings.createInitialState(),
  newVariousSettings: NewVariousSettings.createInitialState(),
  dashboardAddTerm: DashboardAddTerm.createInitialState(),
  settingNotices: SettingNotice.createInitialState(),
  usage: Usage.createInitialState(),
  manageNotices: ManageNotices.createInitialState(),
  dashboardNotices: DashboardNotices.createInitialState(),
  tenantDashboardNotices: TenantDashboardNotices.createInitialState(),
  noticesSearchCondition: NoticesSearchCondition.createInitialState(),
  noticeDetails: NoticeDetails.createInitialState(),
  noticesQueryParameter: NoticesQueryParameter.createInitialState(),
  manageUsers: ManageUsers.createInitialState(),
  manageUsersSearchCondition: ManageUsersSearchCondition.createInitialState(),
  myLibrary: MyLibrary.createInitialState(),
  noticesDetail: NoticesDetail.createInitialState(),
  customers: Customers.createInitialState(),
  customersQueryParameter: CustomersQueryParameter.createInitialState(),
  deleteCustomers: DeleteCustomers.createInitialState(),
  mailHistory: MailHistory.createInitialState(),
  inquiryFormsItem: InquiryFormsItem.createInitialState(),
  lpAdminError: LpAdminError.createInitialState(),
  noticeFiles: NoticeFiles.createInitialState(),
  freeWord: FreeWord.createInitialState(),
  userTags: UserTags.createInitialState(),
  trashes: Trashes.createInitialState(),
  trashesSearchCondition: TrashesSearchCondition.createInitialState(),
  trashesSearchDataType: TrashesSearchDataType.createInitialState(),
  usages: Usages.createInitialState(),
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  lpProductDetailCounts: LpProductDetailCounts.createInitialState(),
  lpOpenSettings: LpOpenSettings.createInitialState(),
  inquiriesForm: InquiriesForm.createInitialState(),
  imageCut: ImageCut.createInitialState(),
});

const createAppReducer = () =>
  combineReducers<AppState>({
    sidebarShift: SidebarShift.reducer,
    sidebarShown: SidebarShown.reducer,
    sidebarAdminMode: SidebarAdminMode.reducer,
    headerShown: HeaderShown.reducer,
    network: Network.reducer,
    error: ApiError.reducer,
    exampleDetail: ExampleDetail.reducer,
    lpDetail: LpDetail.reducer,
    lpReaction: LpReaction.reducer,
    lpInquiries: LpInquiries.reducer,
    lpWorkflows: LpWorkflows.reducer,
    exampleWorkflows: ExampleWorkflows.reducer,
    newExample: NewExample.reducer,
    newProduct: NewProduct.reducer,
    productDetail: ProductDetail.reducer,
    relationProducts: RelationProducts.reducer,
    productTags: ProductTags.reducer,
    productWorkflows: ProductWorkflows.reducer,
    productExamples: ProductExamples.reducer,
    productTagsRequest: ProductTagsRequest.reducer,
    headerNotices: HeaderNotices.reducer,
    dashboardNotices: DashboardNotices.reducer,
    tenantDashboardNotices: TenantDashboardNotices.reducer,
    notices: Notices.reducer,
    inquiries: Inquiries.reducer,
    allInquiries: AllInquiries.reducer,
    newLp: NewLP.reducer,
    userInfo: UserInfo.reducer,
    materialApproverInfo: MaterialApproverInfo.reducer,
    lpApproverInfo: LpApproverInfo.reducer,
    lpTags: LPTags.reducer,
    lPTagsRequest: LPTagsRequest.reducer,
    tokuisakiFixedPhraseTexts: TokuisakiFixedPhraseTexts.reducer,
    profileFixedPhraseTexts: ProfileFixedPhraseTexts.reducer,
    products: Products.reducer,
    examples: Examples.reducer,
    productComment: ProductComment.reducer,
    lpTokuisaki: LPTokuisaki.reducer,
    normalEditSidebarShown: NormalEditSidebarShown.reducer,
    normalEditErrors: NormalEditErrors.reducer,
    lps: Lps.reducer,
    lpsOwn: LpsOwn.reducer,
    lpsSubordinates: LpsSubordinates.reducer,
    lpOwnSearchCondition: LpOwnSearchCondition.reducer,
    lpSearchCondition: LpSearchCondition.reducer,
    lpSearchDefaultAddTerm: LpSearchDefaultAddTerm.reducer,
    noticesSearchCondition: NoticesSearchCondition.reducer,
    productSearchCondition: ProductSearchCondition.reducer,
    exampleQueryParameter: ExampleQueryParameter.reducer,
    inquiriesQueryParameter: InquiriesQueryParameter.reducer,
    inquiriesGreetText: InquiriesGreetText.reducer,
    inquiryForms: InquiryForms.reducer,
    lpSettings: LpSettings.reducer,
    lpProductDetails: LpProductDetails.reducer,
    lpExampleDetails: LpExampleDetails.reducer,
    lpView: LpView.reducer,
    inquirySupports: InquirySupports.reducer,
    inquiryDetail: InquiryDetail.reducer,
    newInquirySupport: NewInquirySupport.reducer,
    userInfoReq: UserInfoReq.reducer,
    lpMainVisualImages: LpMainVisualImages.reducer,
    tokuisakiTitles: TokuisakiTitles.reducer,
    tantoCompanies: TantoCompanies.reducer,
    authError: AuthError.reducer,
    addTerm: AddTerm.reducer,
    designTemplates: DesignTemplates.reducer,
    designAllTemplates: DesignAllTemplates.reducer,
    previousLpsUrl: PreviousLpsUrl.reducer,
    isDuplicatedLP: IsDuplicatedLP.reducer,
    userInfoDetail: UserInfoDetail.reducer,
    userInfoDetailRegReq: UserInfoDetailRegReq.reducer,
    NoticeDetail: NoticeDetail.reducer,
    customerDetail: CustomerDetail.reducer,
    newCustomer: NewCustomer.reducer,
    customerTags: CustomerTags.reducer,
    customerTagsRequest: CustomerTagsRequest.reducer,
    customerMailAddress: CustomerMailAddress.reducer,
    dashboardLps: DashboardLps.reducer,
    totalReaction: TotalReaction.reducer,
    approvalStatus: ApprovalStatus.reducer,
    reactionRanking: ReactionRanking.reducer,
    inquiryInfoRequest: InquiryInfoRequest.reducer,
    inquiryFormsItem: InquiryFormsItem.reducer,
    lpsAdmin: LpsAdmin.reducer,
    users: Users.reducer,
    variousSettings: VariousSettings.reducer,
    newVariousSettings: NewVariousSettings.reducer,
    dashboardAddTerm: DashboardAddTerm.reducer,
    settingNotices: SettingNotice.reducer,
    usage: Usage.reducer,
    manageNotices: ManageNotices.reducer,
    noticeDetails: NoticeDetails.reducer,
    noticesQueryParameter: NoticesQueryParameter.reducer,
    manageUsers: ManageUsers.reducer,
    manageUsersSearchCondition: ManageUsersSearchCondition.reducer,
    myLibrary: MyLibrary.reducer,
    noticesDetail: NoticesDetail.reducer,
    customers: Customers.reducer,
    customersQueryParameter: CustomersQueryParameter.reducer,
    deleteCustomers: DeleteCustomers.reducer,
    mailHistory: MailHistory.reducer,
    lpAdminError: LpAdminError.reducer,
    noticeFiles: NoticeFiles.reducer,
    freeWord: FreeWord.reducer,
    userTags: UserTags.reducer,
    trashes: Trashes.reducer,
    trashesSearchCondition: TrashesSearchCondition.reducer,
    trashesSearchDataType: TrashesSearchDataType.reducer,
    usages: Usages.reducer,
    lpProductDetailCounts: LpProductDetailCounts.reducer,
    lpOpenSettings: LpOpenSettings.reducer,
    inquiriesForm: InquiriesForm.reducer,
    imageCut: ImageCut.reducer,
  });

export default createAppReducer;

import { SALAD_BAR_GREEN_700 } from 'constants/color';
import { ListItem, Typography, Tooltip, Link } from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';

interface DashboardNoticeItemProps {
  endDate: string | undefined;
  endTime: string | undefined;
  id: number;
  startDate: string | undefined;
  startTime: string | undefined;
  title: string;
  type: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    important: {
      background: '#DB3218',
      color: '#fff',
      padding: '6px 12px',
      borderRadius: 6,
      fontSize: 14,
      fontWeight: 'bold',
    },
    item: {
      borderBottom: '1px solid #DCDCDA',
      paddingBottom: theme.spacing(2),

      '& > :nth-child(n-1)': {
        marginRight: theme.spacing(4),
      },
      '& > :first-child': {
        marginRight: theme.spacing(1),
      },
    },
    tooltip: {
      fontSize: 18,
      cursor: 'pointer',
      overflow: 'hidden',
      maxWidth: 900,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    btnGreen: {
      backgroundColor: SALAD_BAR_GREEN_700,
      maxHeight: '24px',
      width: '180px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 4,
      paddingBottom: 4,
      fontWeight: 'bold',
      fontSize: 14,
    },
  }),
);

const DashboardNoticeItem = (props: DashboardNoticeItemProps) => {
  const { endDate, endTime, id, startDate, startTime, title, type } = props;
  const classes = useStyles();
  const navigate = hooks.useNavigate();
  const isImportant = type === 1;
  const noticeFromService = type ===3;

  const formattedDate = (date: string | undefined): string => {
    if (!date) return '';
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return `${year}/${month}/${day}`;
  };

  const formattedTime = (time: string | undefined): string => {
    if (!time) return '';
    const hour = time.slice(0, 2);
    const minute = time.slice(2, 4);

    return `${hour}:${minute}`;
  };

  const handleNavigate = () => {
    navigate.navigate(`/adm/notices/${id}`);
  };

  const startDateTime = `${formattedDate(startDate)} ${formattedTime(
    startTime,
  )}`;
  const endDateTime = `${formattedDate(endDate)} ${formattedTime(endTime)}`;

  return (
    <ListItem className={classes.item}>
      {isImportant && (
        <Typography className={classes.important}>重要</Typography>
      )}
      {noticeFromService && (
        <Typography className={classes.btnGreen}>サービスからのお知らせ</Typography>
      )}
      <Tooltip
        title={title}
        placement="top"
        arrow
        onClick={handleNavigate}
        className={classes.tooltip}
      >
        <Link>{title}</Link>
      </Tooltip>
      <Typography component="p" variant="body2" style={{minWidth: '207px'}}>
        掲載開始日時：{startDateTime}
      </Typography>
      <Typography component="p" variant="body2" style={{minWidth: '207px'}}>
        掲載終了日時：{endDateTime}
      </Typography>
    </ListItem>
  );
};

export default DashboardNoticeItem;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Link,
  InquiryStatusLabel,
  Typography,
  Tooltip,
  NoticeUnreadLabel,
} from 'components/atoms';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Inquiries, InquiriesQueryParameter } from 'core/domain/inquiries';
import {
  INQUIRY_TYPE_TEL,
  INQUIRY_TYPE_MAIL,
  INQUIRY_TYPE_OTHER,
  INQUIRY_TYPE_INQUIRY_FORM,
} from 'constants/text';
import clsx from 'clsx';
import { formatDateTimeISO8601 } from '../../../libs/date';

/**
 * Interface
 */

export interface InquiriesTableProps {
  clickInquiry?: (id: number) => void;
  inquiries: Inquiries | null;
  handleChangeSortKey: (sortKey: string) => void;
  inquiriesQueryParameter: InquiriesQueryParameter | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    status: {
      width: 80,
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
        fontWeight: 'bold',
        cursor: 'pointer',
      },
    },
    date: {
      width: 102,
    },
    lp: {
      width: 200,
    },
    createUser: {
      width: 150,
    },
    company: {
      width: 107,
    },
    position: {
      width: 80,
    },
    name: {
      width: 100,
    },
    jobTitle: {
      width: 100,
    },
    mail: {
      width: 200,
    },
    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'ステータス',
    sortKey: 'status',
  },
  {
    title: 'お問い合わせ種別',
    sortKey: 'inquiryType',
  },
  {
    title: 'お問い合わせID',
    sortKey: 'inquiryId',
  },
  {
    title: '受信日時',
    sortKey: 'inquiryDate',
  },
  {
    title: 'お問い合わせ元\nランディングページ',
    sortKey: 'lpName',
  },
  {
    title: 'お問い合わせ元\nランディングページ担当者',
    sortKey: 'lpCreateUserName',
  },
  {
    title: '貴社名',
    sortKey: 'companyName',
  },
  {
    title: '役職',
    sortKey: 'jobTitle',
  },
  {
    title: 'お名前',
    sortKey: 'customerName',
  },
  {
    title: 'メールアドレス',
    sortKey: 'mailAddress',
  },
  {
    title: '開封状況',
    sortKey: '',
  },
];

const inqType = (inq: number) => {
  let tmpStr = '';
  switch (inq) {
    case 1: {
      tmpStr = INQUIRY_TYPE_TEL;
      break;
    }
    case 2: {
      tmpStr = INQUIRY_TYPE_MAIL;
      break;
    }
    case 3: {
      tmpStr = INQUIRY_TYPE_OTHER;
      break;
    }
    case 9: {
      tmpStr = INQUIRY_TYPE_INQUIRY_FORM;
      break;
    }
    default: {
      break;
    }
  }

  return tmpStr;
};

const InquiriesTable = (props: InquiriesTableProps) => {
  const classes = useStyles({});
  const {
    clickInquiry,
    inquiries,
    handleChangeSortKey,
    inquiriesQueryParameter,
  } = props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {item.sortKey !== '' ? (
                  <TableSortLabel
                    active
                    direction={inquiriesQueryParameter?.sort_order}
                    IconComponent={() =>
                      item.sortKey === inquiriesQueryParameter?.sort_key &&
                      inquiriesQueryParameter?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleChangeSortKey(item.sortKey)}
                  >
                    {item.title}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} hideSortIcon>
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {inquiries?.inquiries.map((inquiry, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor: inquiry.unread ? 'white' : '#eeeeee',
              }}
            >
              <TableCell>
                <Box className={classes.status}>
                  <InquiryStatusLabel status={inquiry.newestSupportType} />
                </Box>
              </TableCell>
              <TableCell>{inqType(inquiry.inquiryType)}</TableCell>
              <TableCell className={classes.link}>
                <Box
                  fontWeight="fontWeightBold"
                  className={classes.link}
                  display="inline"
                >
                  <Tooltip
                    title={inquiry.inquiryId}
                    onClick={() =>
                      clickInquiry && clickInquiry(inquiry.inquiryId)
                    }
                  >
                    <Link underline="always">{inquiry.inquiryId}</Link>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell>
                {formatDateTimeISO8601(inquiry.inquiryDate).replace(' ', '\n')}
              </TableCell>
              <TableCell>
                <Tooltip title={inquiry.lpName} arrow placement="top">
                  <Typography
                    className={clsx(classes.lp, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.lpName}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={inquiry.lpCreateUserName} arrow placement="top">
                  <Typography
                    className={clsx(classes.createUser, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.lpCreateUserName}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={inquiry.companyName || ''}
                  arrow
                  placement="top"
                >
                  <Typography
                    className={clsx(classes.company, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.companyName}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={inquiry.jobTitle || ''} arrow placement="top">
                  <Typography
                    className={clsx(classes.jobTitle, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.jobTitle}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={inquiry.customerName || ''}
                  arrow
                  placement="top"
                >
                  <Typography
                    className={clsx(classes.name, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.customerName}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={inquiry.mailAddress || ''}
                  arrow
                  placement="top"
                >
                  <Typography
                    className={clsx(classes.mail, classes.overflow)}
                    variant="body2"
                  >
                    {inquiry.mailAddress}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Box className={classes.status}>
                  <NoticeUnreadLabel status={inquiry.unread} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InquiriesTable;

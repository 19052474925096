import { lpInquiry } from 'core/domain/lp'
import { formatDateTimeISO8601 } from 'libs/date'
import {
  SUPPORT_STATUS_NEW,
  SUPPORT_STATUS_REPLIED,
  SUPPORT_STATUS_IN_TELEPHONE_READY,
  SUPPORT_STATUS_SUPPORTING,
 INQUIRY_TYPE_TEL, INQUIRY_TYPE_MAIL, INQUIRY_TYPE_OTHER, INQUIRY_TYPE_INQUIRY_FORM } from 'constants/text'
import {
  CODE_STATUS_REPLIED,
  CODE_STATUS_IN_TELEPHONE_READY,
  CODE_STATUS_SUPPORTING,
 CODE_INQUIRY_TYPE_TEL, CODE_INQUIRY_TYPE_MAIL, CODE_INQUIRY_TYPE_OTHER, CODE_INQUIRY_TYPE_INQUIRY_FORM } from 'constants/code'



interface InquiryType {
  type: number
  label: string
}

const INQUIRY_TYPES: Array<InquiryType> = [
  {
    type: CODE_INQUIRY_TYPE_TEL,
    label: INQUIRY_TYPE_TEL,
  },
  {
    type: CODE_INQUIRY_TYPE_MAIL,
    label: INQUIRY_TYPE_MAIL,
  },
  {
    type: CODE_INQUIRY_TYPE_OTHER,
    label: INQUIRY_TYPE_OTHER,
  },
  {
    type: CODE_INQUIRY_TYPE_INQUIRY_FORM,
    label: INQUIRY_TYPE_INQUIRY_FORM,
  }
]

interface StatusType {
  status: number
  label: string
}

const STATUS_TYPES: Array<StatusType> = [
  {
    status: CODE_STATUS_REPLIED,
    label: SUPPORT_STATUS_REPLIED,
  },
  {
    status: CODE_STATUS_IN_TELEPHONE_READY,
    label: SUPPORT_STATUS_IN_TELEPHONE_READY,
  },
  {
    status: CODE_STATUS_SUPPORTING,
    label: SUPPORT_STATUS_SUPPORTING,
  },
]

const escapeCsv = (str: string) => {
	let result
  let arg = str

  // TODO 暫定対応[null]を[""]に変換
  if (arg === null) {
    arg = ""
  }

  // 「"」を「""」に変換
  // eslint-disable-next-line no-useless-escape
	result = arg.replace(/\"/g, "\"\"")

	// 「,」を含む場合は全体を「"」で囲む
	// if (result.indexOf(",") >= 0) {
  // 全項目「"」で囲む
		result = `"${  result  }"`
	// }

	return result
}

const createInquiryCsv = (inquiries: Array<lpInquiry>, lpId: number, lpTitle: string, lpCreatUser: string): string => {
  const header = '"ステータス","お問い合わせ種別","お問い合わせID","受信日時",'
  + '"お問い合わせ元ランディングページID","お問い合わせ元ランディングページ名","お問い合わせ元ランディングページ担当者",'
  + '"貴社名","部署名","お名前","役職","業種","メールアドレス","電話番号","都道府県",'
  + '"郵便番号","住所","性別","年齢","年代","生年月日","気になったテーマ","ご相談・ご希望","資料送付",'
  let freeItemName = '';
  const inquiryText = inquiries.map((inquiry) => {
    const status = STATUS_TYPES.find(statusType => statusType.status === inquiry.newestSupportType)
    const tmpDataList = [
      escapeCsv(status ? status.label : SUPPORT_STATUS_NEW),
      escapeCsv(INQUIRY_TYPES.find(inquiryType => inquiryType.type === inquiry.inquiryType)?.label || ''),
      escapeCsv(inquiry.inquiryId.toString()),
      escapeCsv(formatDateTimeISO8601(inquiry.inquiryDate)),
      escapeCsv(lpId.toString()),
      escapeCsv(lpTitle),
      escapeCsv(lpCreatUser),
      escapeCsv(inquiry.companyName),
      escapeCsv(inquiry.deptName),
      escapeCsv(inquiry.customerName),
      escapeCsv(inquiry.jobTitle),
      escapeCsv(inquiry.industryType),
      escapeCsv(inquiry.mailAddress),
      escapeCsv(inquiry.tel),
      escapeCsv(inquiry.prefectures),
      escapeCsv(inquiry.postalCode),
      escapeCsv(inquiry.address),
      escapeCsv(inquiry.gender),
      escapeCsv(inquiry.age),
      escapeCsv(inquiry.generation),
      escapeCsv(inquiry.birth),
      escapeCsv(inquiry.interestedProducts),
      escapeCsv(inquiry.consultation),
      escapeCsv(inquiry.sendingDocument),
      escapeCsv(inquiry.freeItem1Answer),
      escapeCsv(inquiry.freeItem2Answer),
      escapeCsv(inquiry.freeItem3Answer),
      escapeCsv(inquiry.freeItem4Answer),
      escapeCsv(inquiry.freeItem5Answer),
    ]

    if (!freeItemName || freeItemName.length === 0) {
      const freeItem1Name = escapeCsv(inquiry.freeItem1Name).length !== 0 ? escapeCsv(inquiry.freeItem1Name) : '自由項目１'
      const freeItem2Name = escapeCsv(inquiry.freeItem2Name).length !== 0 ? escapeCsv(inquiry.freeItem2Name) : '自由項目２'
      const freeItem3Name = escapeCsv(inquiry.freeItem3Name).length !== 0 ? escapeCsv(inquiry.freeItem3Name) : '自由項目３'
      const freeItem4Name = escapeCsv(inquiry.freeItem4Name).length !== 0 ? escapeCsv(inquiry.freeItem4Name) : '自由項目４'
      const freeItem5Name = escapeCsv(inquiry.freeItem5Name).length !== 0 ? escapeCsv(inquiry.freeItem5Name) : '自由項目５'
      freeItemName = `${freeItem1Name},${freeItem2Name},${freeItem3Name},${freeItem4Name},${freeItem5Name}\n`
    }

    const dataList = tmpDataList.map(data => data || '')

    return dataList.join(',')
  })

  return header + freeItemName + inquiryText.join('\n')
}
export default createInquiryCsv

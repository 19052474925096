/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { setExampleDetail } from 'core/modules/exampleDetail'
import { setExampleWorkflows } from 'core/modules/exampleWorkflows'
import { ExampleDetail, ExampleWorkflows, ExampleRegisterRequest, Examples, ExamplesQueryParameter } from 'core/domain/example'
import { Products } from 'core/domain/product'
import { setRelationProducts } from 'core/modules/relationProducts'
import { setExamples } from 'core/modules/examples'

function didCallGetExampleDetailApi(dispatch: Dispatch, data: ExampleDetail) {
  dispatch(setExampleDetail(data))
}

export function sendGetExampleDetail(token: string | undefined, tenantId: string, exampleId: number, mode: number) {
  return async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/examples/{example_id}
    await Api.callApi(Api.HttpMethod.post, `${tenantId}/examples/${exampleId}`, token, JSON.stringify({ mode }), dispatch, didCallGetExampleDetailApi)
  }
}

function didCallGetExampleWorkflowApi(dispatch: Dispatch, data: ExampleWorkflows) {
  dispatch(setExampleWorkflows(data.exampleWorkflows))
}

export function sendGetExampleWorkflows(token: string | undefined, tenantId: string, exampleId: number) {
  return async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/examples/{example_id}
    await Api.callApi(Api.HttpMethod.get, `${tenantId}/examples/${exampleId}/workflows`, token, null, dispatch, didCallGetExampleWorkflowApi)
  }
}

export function sendPatchExampleRecommended(token: string | undefined, tenantId: string, exampleId: number, recommended: boolean) {
  return async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/examples/{example_id}/recommended
    await Api.callApi(Api.HttpMethod.patch, `${tenantId}/examples/${exampleId}/recommended`, token, JSON.stringify({recommended}), dispatch)
  }
}

function didCallPatchExampleWorkflowStatusApi(dispatch: Dispatch, data: ExampleWorkflows) {
  dispatch(setExampleWorkflows(data.exampleWorkflows))
}

export function sendPatchExampleWorkflowStatus(token: string | undefined, tenantId: string, exampleId: number, workflowId: number, step: number, status: number, message?: string) {
  return async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/examples/{example_id}/workflows/{workflow_id}/status
    await Api.callApi(Api.HttpMethod.patch, `${tenantId}/examples/${exampleId}/workflows/${workflowId}/status`, token, JSON.stringify({step, status, message}), dispatch, didCallPatchExampleWorkflowStatusApi)
  }
}

function didCallPostExampleStopApi(dispatch: Dispatch, data: ExampleDetail) {
  dispatch(setExampleDetail(data))
}

export function sendPostExampleStop(token: string | undefined, tenantId: string, exampleId: number) {
  return async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/examples/{example_id}/stop
    await Api.callApi(Api.HttpMethod.post, `${tenantId}/examples/${exampleId}/stop`, token, null, dispatch, didCallPostExampleStopApi)
  }
}

function didCallPostExampleReopenApi(dispatch: Dispatch, data: ExampleDetail) {
  dispatch(setExampleDetail(data))
}

export function sendPostExampleReopen(token: string | undefined, tenantId: string, exampleId: number) {
  return async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/examples/{example_id}/reopen
    await Api.callApi(Api.HttpMethod.post, `${tenantId}/examples/${exampleId}/reopen`, token, null, dispatch, didCallPostExampleReopenApi)
  }
}

function didCallPostExample(dispatch: Dispatch, data: ExampleDetail) {
  dispatch(setExampleDetail(data))
}

export function sendPostExample(token: string | undefined, tenantId: string, example: ExampleRegisterRequest, draft: boolean) {
  return async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/examples
    await Api.callApi(Api.HttpMethod.post, `${tenantId}/examples`, token, JSON.stringify({...example, draft}), dispatch, didCallPostExample)
  }
}

function didCallPutExample(dispatch: Dispatch, data: ExampleDetail) {
  dispatch(setExampleDetail(data))
}

export function sendPutExample(token: string | undefined, tenantId: string, exampleId: string, example: ExampleRegisterRequest, draft: boolean) {
  return async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/examples
    await Api.callApi(Api.HttpMethod.put, `${tenantId}/examples/${exampleId}`, token, JSON.stringify({...example, draft}), dispatch, didCallPutExample)
  }
}

function didCallGetProducts(dispatch: Dispatch, data: Products) {
  dispatch(setRelationProducts(data))
}

export function sendGetProducts(token: string | undefined, tenantId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/products
    // FIXME: クエリパラメータは公開中のもの、かつ全件取得（page,row未指定）で指定
    await Api.callApi(Api.HttpMethod.get, `${tenantId}/products?sort_key=productId`, token, null, dispatch, didCallGetProducts)
  }
}

function didCallGetExamples(dispatch:Dispatch,data:Examples){
  dispatch(setExamples(data))
}

export function sendGetExamples(token: string | undefined, tenantId:string, param:ExamplesQueryParameter){
  return async(dispatch:Dispatch) => {
    const api = Api.createApi(param)
    await Api.callApi(Api.HttpMethod.get,`${tenantId}/examples?${api === '' ? 'page=1&row=999' : api}`, token, null, dispatch, didCallGetExamples)
  }
}

function didCallPostExampleTrash(dispatch: Dispatch,data: ExampleDetail){
  dispatch(setExampleDetail(data))
}

export function sendPostExampleTrash(token: string | undefined, tenantId:string, exampleId: string,){
  return async(dispatch:Dispatch) => {
    await Api.callApi(Api.HttpMethod.post,`${tenantId}/examples/${exampleId}/trash`, token, null, dispatch, didCallPostExampleTrash)
  }
}/* eslint-enable */

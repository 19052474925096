import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Usage } from '../../../core/domain/dashborad';
import RadiusBox from '../../atoms/RadiusBox';
import ServiceUsage from '../../molecules/ServiceUsage';
import {
  ReleaseWithBGIcon,
  StopWithBGIcon,
  UsersIcon,
} from '../../../assets/images';

/**
 * Interface
 */
interface TenantUsages {
  usage: Usage;
}

/**
 * Style
 */
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& >*': {
        marginRight: '24px',
        marginBottom: '24px',
      },
      '& >:last-child': {
        marginRight: '0px',
        marginBottom: '24px',
      },
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
);
const TenantUsages = (props: TenantUsages) => {
  const { usage } = props;
  const classes = useStyles();

  return (
    <RadiusBox className={classes.root}>
      {(usage?.openLpCount || usage.openLpCount === 0) && (
        <ServiceUsage
          title={`公開中\nランディングページ数`}
          icon={<ReleaseWithBGIcon />}
          value={usage.openLpCount.toLocaleString()}
        />
      )}
      {(usage?.closeLpCount || usage.closeLpCount === 0) && (
        <ServiceUsage
          title={`公開終了\nランディングページ数`}
          icon={<StopWithBGIcon />}
          value={usage.closeLpCount.toLocaleString()}
        />
      )}
      {(usage?.userCount || usage.userCount === 0) && (
        <ServiceUsage
          title="ユーザー数"
          icon={<UsersIcon />}
          value={usage.userCount.toLocaleString()}
        />
      )}
    </RadiusBox>
  );
};
export default TenantUsages;

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { sendGetUsagesHistory } from 'core/usecases/dashboards';
import { hooks } from 'libs';
import { AppState } from '../../../core/modules';
import TenantNoticesTable from '../../organisms/TenantNoticesTable';
import TenantUsages from '../../organisms/TenantUsages';
import TenantNotices from '../../organisms/TenantNoticesList/TenantNotices';
import { Content } from '../../organisms';
import SideLink from '../../atoms/SideLink';
import UsagesHistoryTable from '../../organisms/UsagesHistoryTable';

/**
 * Interface
 */

/**
 * Style
 */

const Index = () => {
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;

  const notices = useSelector(
    (state: AppState) => state.tenantDashboardNotices,
    shallowEqual,
  );
  const usages = useSelector((state: AppState) => state.usage, shallowEqual);
  const settingNotices = useSelector(
    (state: AppState) => state.settingNotices,
    shallowEqual,
  );

  const tenantId = useSelector(
    (state: AppState) => state.userInfo?.tenantId,
    shallowEqual,
  );

  const usagesHistory = useSelector(
    (state: AppState) => state.usages,
    shallowEqual,
  );

  // 利用年プルダウン選択
  const handleChangeSelect = (value: string) => {
    if (!tenantId) return;

    dispatch(sendGetUsagesHistory(
      token, tenantId, value
    ));
  };

  return (
    <>
      <Content
        sideItem={<SideLink url="/adm/notices" label="もっと見る" />}
        mt={5}
        title="サービスに関するお知らせ"
      >
        <TenantNotices notices={notices?.notices} />
      </Content>
      <Content
        mt={10}
        childBoxProps={{ pb: '0px!important' }}
        title="サービス利用状況"
      >
        <>{usages && <TenantUsages usage={usages} />}</>
      </Content>
      <Content
        sideItem={<SideLink url="/adm/notices-manage" label="お知らせ管理へ" />}
        mt={10}
        title="お知らせ状況"
      >
        <TenantNoticesTable notices={settingNotices || []} />
      </Content>
      <Content
        mt={10}
        title="ご利用履歴"
      >
        <UsagesHistoryTable
          usagesHistory={usagesHistory || []}
          useStartDate={usages?.useStartDate}
          handleChangeSelect={handleChangeSelect}
        />
      </Content>
    </>
  );
};
export default Index;

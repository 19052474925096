import { InquiryFormRequest } from "core/domain/inquiries";

const initialState:Array<InquiryFormRequest>|null = null
// [
//     {
//         "inquiryItemId": 1,
//         "inquiryItemName": "貴社名",
//         "inquiryItemType": 0,
//         "displayOrder": 0,
//         "inputType": 1,
//         "inquiryItemOptions": [
//         {
//         "displayOrder": 0,
//         "displayOptionName": "string"
//         }
//         ],
//         "required": true,
//         "displayed": true
//     },

//     {
//         "inquiryItemId": 2,
//         "inquiryItemName": "部署名",
//         "inquiryItemType": 0,
//         "displayOrder": 1,
//         "inputType": 1,
//         "inquiryItemOptions": [
//             {
//             "displayOrder": 0,
//             "displayOptionName": "項目２-1"
//             },
//             {
//                 "displayOrder": 1,
//                 "displayOptionName": "項目２-2"
//             }
//         ],
//         "required": false,
//         "displayed": true
//     },

//     {
//         "inquiryItemId": 3,
//         "inquiryItemName": "お名前",
//         "inquiryItemType": 0,
//         "displayOrder": 2,
//         "inputType": 1,
//         "inquiryItemOptions": [
//             {
//             "displayOrder": 0,
//             "displayOptionName": "項目4-1"
//             },
//             {
//                 "displayOrder": 1,
//                 "displayOptionName": "項目4-2"
//             }
//         ],
//         "required": true,
//         "displayed": true
//     },
//     {
//         "inquiryItemId": 4,
//         "inquiryItemName": "役職",
//         "inquiryItemType": 0,
//         "displayOrder": 3,
//         "inputType": 1,
//         "inquiryItemOptions": [
//             {
//             "displayOrder": 0,
//             "displayOptionName": "項目5-1"
//             },
//             {
//                 "displayOrder": 1,
//                 "displayOptionName": "項目5-2"
//             }
//         ],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 5,
//         "inquiryItemName": "業種",
//         "inquiryItemType": 0,
//         "displayOrder": 4,
//         "inputType": 1,
//         "inquiryItemOptions": [
//             {
//             "displayOrder": 0,
//             "displayOptionName": "項目5-1"
//             },
//             {
//                 "displayOrder": 1,
//                 "displayOptionName": "項目5-2"
//             }
//         ],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 6,
//         "inquiryItemName": "メールアドレス",
//         "inquiryItemType": 0,
//         "displayOrder": 5,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": true,
//         "displayed": true
//     },
//     {
//         "inquiryItemId": 7,
//         "inquiryItemName": "電話番号",
//         "inquiryItemType": 0,
//         "displayOrder": 6,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 8,
//         "inquiryItemName": "都道府県",
//         "inquiryItemType": 0,
//         "displayOrder": 7,
//         "inputType": 3,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 9,
//         "inquiryItemName": "郵便番号",
//         "inquiryItemType": 0,
//         "displayOrder": 8,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 10,
//         "inquiryItemName": "住所",
//         "inquiryItemType": 0,
//         "displayOrder": 9,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 11,
//         "inquiryItemName": "性別",
//         "inquiryItemType": 0,
//         "displayOrder": 10,
//         "inputType": 2,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 12,
//         "inquiryItemName": "年齢",
//         "inquiryItemType": 0,
//         "displayOrder": 11,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 13,
//         "inquiryItemName": "年代",
//         "inquiryItemType": 0,
//         "displayOrder": 12,
//         "inputType": 3,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 14,
//         "inquiryItemName": "生年月日",
//         "inquiryItemType": 0,
//         "displayOrder": 13,
//         "inputType": 8,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
//     {
//         "inquiryItemId": 15,
//         "inquiryItemName": "気になったテーマ",
//         "inquiryItemType": 0,
//         "displayOrder": 14,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": true,
//         "displayed": true
//     },
//     {
//         "inquiryItemId": 16,
//         "inquiryItemName": "ご相談・ご要望",
//         "inquiryItemType": 0,
//         "displayOrder": 15,
//         "inputType": 1,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": true
//     },
//     {
//         "inquiryItemId": 17,
//         "inquiryItemName": "資料送付",
//         "inquiryItemType": 0,
//         "displayOrder": 16,
//         "inputType": 4,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": true
//     },
//     {
//         "inquiryItemId": 18,
//         "inquiryItemName": "自由項目１",
//         "inquiryItemType": 0,
//         "displayOrder": 17,
//         "inputType": 4,
//         "inquiryItemOptions": [],
//         "required": false,
//         "displayed": false
//     },
// ]

export const createInitialState = ():Array<InquiryFormRequest>|null => initialState

export const SET_INQUIRY_FORMS_ITEM = 'inquiry-forms-item/set' as const
export const CLEAR_INQUIRY_FORMS = 'inquiry-forms-item/clear' as const

export const setInquiryForms = (data:Array<InquiryFormRequest>) => ({
    type: SET_INQUIRY_FORMS_ITEM,
    payload: {
        data
    }
})

export const clearInquiryForms = () => ({
    type:CLEAR_INQUIRY_FORMS
})

type SetInquiryFormsAction = ReturnType<typeof setInquiryForms>
type ClearInquiryFormsDetailAction = ReturnType<typeof clearInquiryForms>
type Action = SetInquiryFormsAction | ClearInquiryFormsDetailAction

export const reducer = (state = createInitialState(), action: Action) => {
    switch (action.type) {
        case SET_INQUIRY_FORMS_ITEM:
            return action.payload.data
        case CLEAR_INQUIRY_FORMS:
            return initialState
        default:
            return state
    }
}


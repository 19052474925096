/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

const DateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: undefined,
  minute: undefined,
  second: undefined,
  hour12: false,
};

const DateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: undefined,
  hour12: false,
};

const DateTimeSecOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};

/**
 * 日付フォーマット（yyyy-MM-ddTHH:mm:ss → yyyy/MM/dd）
 * @param dateString yyyy-MM-ddTHH:mm:ss
 */

export function formatDateISO8601(dateString: string | undefined): string {
  if (!dateString) return '';

  if (dateString.includes('T')) {
    return new Intl.DateTimeFormat('ja-JP', DateOptions).format(
      new Date(dateString.replace('T', ' ').replace(/-/g, '/')),
    );
  }

  return new Intl.DateTimeFormat('ja-JP', DateOptions).format(
    new Date(dateString.replace(/-/g, '/')),
  );
}

/**
 * 日付フォーマット（yyyy-MM-ddTHH:mm:ss → yyyy/MM/dd HH:mm）
 * @param dateString yyyy-MM-ddTHH:mm:ss
 */

export function formatDateTimeISO8601(dateString: string | undefined): string {
  if (!dateString) return '';

  return new Intl.DateTimeFormat('ja-JP', DateTimeOptions).format(
    new Date(dateString),
  );
}

/**
 * 日付フォーマット（yyyy-MM-ddTHH:mm:ss → yyyy/MM/dd HH:mm:ss）
 * @param dateString yyyy-MM-ddTHH:mm:ss
 */

export function formatDateTimeSecISO8601(
  dateString: string | undefined,
): string {
  if (!dateString) return '';

  return new Intl.DateTimeFormat('ja-JP', DateTimeSecOptions).format(
    new Date(dateString),
  );
}

/**
 * 日付フォーマット（yyyyMMdd → yyyy-MM-dd）
 * @param dateString yyyyMMdd
 */

export function formatDateYYYYMMDD(dateString: string | undefined): string {
  if (!dateString) return '';
  const dateStringISO8601 = `${dateString.substr(0, 4)}-${dateString.substr(
    4,
    2,
  )}-${dateString.substr(6, 2)}`;

  return new Intl.DateTimeFormat('ja-JP', DateOptions).format(
    new Date(dateStringISO8601),
  );
}

/**
 * 日付フォーマット（yyyyMMdd → yyyy/MM/dd）
 * @param dateString yyyyMMdd
 */
export function formatDateYYYYMMDDSeparateSlash(
  dateString: string | undefined,
): string {
  if (!dateString) return '';

  return `${dateString.substr(0, 4)}/${dateString.substr(
    4,
    2,
  )}/${dateString.substr(6, 2)}`;
}

/**
 * 日付フォーマット（Date型 → yyyyMMdd）
 * @param date yyyyMMdd
 */

export function formateDateStrYYYYMMDD(date: Date): string {
  return (
    date.getFullYear() +
    `0${date.getMonth() + 1}`.slice(-2) +
    `0${date.getDate()}`.slice(-2)
  );
}

/**
 * 時間フォーマット（HHmm → HH:mm）
 * @param timeString HH:mm
 */

export function formateTimeHHMMColon(timeString?: string): string {
  if (!timeString) return '';

  return `${timeString.slice(0, 2)}:${timeString.slice(2)}`;
}

/**
 * 日付フォーマット（引数のyyyy/MM/ddが、今日の場合 → 今日 ／ 昨日の場合 → 昨日 ／ その他 → yyyy/MM/dd）
 *（その他の日付 → 日付）
 * @param dateString yyyy/MM/dd
 */
export function formateDateToJpString(dateString: string): string {
  const today: string = formatDate(new Date());
  const temp = parseInt(today.slice(8, 10), 10) - 1;
  const yesterday: string = today.slice(0, 8) + temp;
  if (dateString === today) {
    return '今日';
  }
  if (dateString === yesterday) {
    return '昨日';
  }

  return dateString;
}

/**
 * 過去日チェッック
 * @param dateString yyyyMMdd
 * @param timeString HHmm
 */
export function checkPastDay(
  dateString?: string,
  timeString?: string,
): boolean {
  if (!dateString || !timeString) return false;
  const year = Number(dateString.substr(0, 4));
  const month = Number(dateString.substr(4, 2));
  const day = Number(dateString.substr(6, 2));
  const hour = Number(timeString.substr(0, 2));
  const minute = Number(timeString.substr(2, 2));
  const period = new Date(year, month - 1, day, hour, minute);
  const now = new Date();
  if (period < now) {
    return true;
  }

  return false;
}

/**
 * 5日以内チェック
 * @param dateString yyyyMMdd
 * @param timeString HHmm
 */
export function checkWithin5Days(
  dateString?: string,
  timeString?: string,
): boolean {
  if (!dateString || !timeString) return false;
  const year = Number(dateString.substr(0, 4));
  const month = Number(dateString.substr(4, 2));
  const day = Number(dateString.substr(6, 2));
  const hour = Number(timeString.substr(0, 2));
  const minute = Number(timeString.substr(2, 2));
  const period = new Date(year, month - 1, day, hour, minute);
  const within5days = new Date();
  within5days.setHours(within5days.getHours() + 120);
  if (period < within5days) {
    return true;
  }

  return false;
}

/**
 * 日付有効チェックと有効な日付の場合はDateを返却
 * @param dateString yyyyMMdd
 */
export function validDate(dateString: string): Date | undefined | '' {
  if (dateString === '') return '';
  if (dateString.includes('_')) return undefined;
  if (dateString.length !== 8) return undefined;
  const y = Number(dateString.substr(0, 4));
  const m = Number(dateString.substr(4, 2)) - 1;
  const d = Number(dateString.substr(6, 2));
  const date = new Date(y, m, d);
  if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
    return undefined;
  }

  return date;
}

function formatDate(dt: Date): string {
  const y = dt.getFullYear();
  const m = `00${dt.getMonth() + 1}`.slice(-2);
  const d = `00${dt.getDate()}`.slice(-2);

  return `${y}/${m}/${d}`;
} /* eslint-enable */

import { Dispatch } from 'redux';
import { setDashboardLps } from 'core/modules/dashboardLps';
import { Lps, LpSearchCondition } from 'core/domain/lp';
import * as Api from 'libs/api';
import {
  AddTerm,
  ApprovalStatus,
  ReactionRanking,
  TotalReaction,
} from 'core/domain/dashboard';
import { setTotalReaction } from 'core/modules/totalReaction';
import { setApprovalStatus } from 'core/modules/approvalStatus';
import { setReactionRanking } from 'core/modules/reactionRanking';
import { setDashboardAddTerm } from 'core/modules/dashboardAddTerm';

const didCallGetDashboardLpsApi = (dispatch: Dispatch, data: Lps) => {
  dispatch(setDashboardLps(data));
};

export const sendGetDashboardLps =
  (
    token: string | undefined,
    tenantId: string,
    param: LpSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/lps${api}`,
      token,
      null,
      dispatch,
      didCallGetDashboardLpsApi,
    );
  };

const didCallGetTotalReactionApi = (
  dispatch: Dispatch,
  data: TotalReaction,
) => {
  dispatch(setTotalReaction(data));
};

export const sendGetTotalReaction =
  (token: string | undefined, tenantId: string, start?: string, end?: string) =>
  async (dispatch: Dispatch) => {
    const param = {
      add_term_from: start || undefined,
      add_term_to: end || undefined,
    };
    const api = param ? `?${Api.createApi(param)}` : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/reaction${api}`,
      token,
      null,
      dispatch,
      didCallGetTotalReactionApi,
    );
  };

const didCallGetApprovalStatusApi = (
  dispatch: Dispatch,
  data: ApprovalStatus,
) => {
  dispatch(setApprovalStatus(data));
};

export const sendGetApprovalStatus =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/timeline`,
      token,
      null,
      dispatch,
      didCallGetApprovalStatusApi,
    );
  };

const didCallGetReactionRankingApi = (
  dispatch: Dispatch,
  data: ReactionRanking,
) => {
  dispatch(setReactionRanking(data));
};

export const sendGetReactionRanking =
  (token: string | undefined, tenantId: string, start?: string, end?: string) =>
  async (dispatch: Dispatch) => {
    const param = {
      add_term_from: start || undefined,
      add_term_to: end || undefined,
    };
    const api = param ? `?${Api.createApi(param)}` : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/reaction-ranking${api}`,
      token,
      null,
      dispatch,
      didCallGetReactionRankingApi,
    );
  };

const didCallGetDashboardAddTermApi = (dispatch: Dispatch, data: AddTerm) => {
  dispatch(setDashboardAddTerm(data));
};

export const sendGetDashboardAddTerm =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/dashboard/add-term`,
      token,
      null,
      dispatch,
      didCallGetDashboardAddTermApi,
    );
  };

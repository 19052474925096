import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLpSearchCondition } from 'core/modules/lpSearchCondition';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import LPsAdmin from 'components/pages/LPsAdmin';
import { LpSortKey } from 'core/domain/lp';
import { UserInfoDetail } from 'core/domain/user';
import { clearLpLpAdminError } from 'core/modules/lpAdminError';

const pageRows = 10;

const LpAdminPageContainer = () => {
  const dispatch = useDispatch();

  const lps = useSelector((state: Modules.AppState) => state.lpsAdmin);
  const lpSearchCondition = useSelector(
    (state: Modules.AppState) => state.lpSearchCondition,
  );
  const lpTags = useSelector((state: Modules.AppState) => state.lpTags);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;
  const error = useSelector((state: Modules.AppState) => state.lpAdminError);
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector((state: Modules.AppState) => state.freeWord);
  const { navigate } = hooks.useNavigate();

  // page、rowは必須
  useEffect(() => {
    if (!lpSearchCondition) {
      dispatch(
        setLpSearchCondition({
          page: 1,
          row: pageRows,
        }),
      );
    }

    if (lps && lps.lps && lps.lps.length === 0 && lps.totalCount > 0) {
      const latestPage = Math.ceil(lps?.totalCount / pageRows);
      dispatch(
        setLpSearchCondition({
          ...lpSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [lpSearchCondition, dispatch, lps]);

  const init = () => {
    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetLpsAdmin(token, userInfo.tenantId, lpSearchCondition),
    );
  };

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (lpSearchCondition?.sort_key === sortKey) {
      order = lpSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        sort_key: sortKey as LpSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  // /**
  //  * ステータスチェックボックスチェック時
  //  * 該当のLPステータスをリクエストパラメーターに設定
  //  * @param status
  //  */
  // const handleCheckStatus = (status: string) => {
  //     dispatch(setLpSearchCondition({
  //     ...lpSearchCondition,
  //     status
  //     }))
  // }

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  const handleChangeLpTantoUser = (users: Array<UserInfoDetail>) => {
    let ids = '';
    users.forEach((item) => {
      ids += `${item.userId},`;
    });
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        lp_tanto_user_ids: ids,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  useEffect(() => {
    if (!userInfo || !lpSearchCondition) return;
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpSearchCondition, userInfo]);

  // 変更、停止、削除後に一覧を更新
  const handleLoadCondition = () => {
    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
      }),
    );
  };

  // エラー時にダイアログ表示
  const handleCloseErrorDialog = () => {
    navigate(`/adm/lp-manage`);
    dispatch(clearLpLpAdminError());
  };

  return (
    <LPsAdmin
      lps={lps}
      rows={pageRows}
      handleChangeLpTantoUser={handleChangeLpTantoUser}
      lpSearchCondition={lpSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleChangeFreeWord={handleChangeFreeWord}
      tags={lpTags}
      handleLoadCondition={handleLoadCondition}
      error={error}
      handleCloseErrorDialog={handleCloseErrorDialog}
    />
  );
};

export default LpAdminPageContainer;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'
import { CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE, CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL, CODE_MATERIAL_WORKFLOW_STATUS_DECLINE, CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL } from 'constants/code'
import { MATERIAL_WORKFLOW_STATUS_MESSAGE, MATERIAL_WORKFLOW_STATUS_DECLINE, MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL, MATERIAL_WORKFLOW_STATUS_APPROVAL } from 'constants/text'

/**
 * Interface
 */

export interface ApplyHistoryLabelProps {
	status: number
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
	createStyles({
		root: {
			textAlign: 'center',
			backgroundColor: theme.palette.gray[500],
			color: theme.palette.gray[800],
			height: 24,
			borderRadius: '4px',
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		},
		caption: {
			fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50
		}
	}),
)

/**
 * Presenter
 */

export default function ApplyHistoryLabel(props: ApplyHistoryLabelProps) {
	const classes = useStyles({})
	const { status } = props

	const label = useMemo(() => {
		switch (status) {
			case CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE:
				return MATERIAL_WORKFLOW_STATUS_MESSAGE
			case CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL:
				return MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL
			case CODE_MATERIAL_WORKFLOW_STATUS_DECLINE:
				return MATERIAL_WORKFLOW_STATUS_DECLINE
			case CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL:
				return MATERIAL_WORKFLOW_STATUS_APPROVAL
			default:
				break
		}

		return null
	}, [status])

	return label && (
		<Box className={classes.root}>
			<Typography variant="caption" className={classes.caption} >
				{label}
			</Typography>
		</Box>
	)
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Divider,
  ProductTag,
  Grid,
  Box,
  ApplyStatusLabel,
  HeadlineLabel,
  PrimaryButton,
  Tooltip,
} from 'components/atoms';
import { FavoriteButton, PreviewButton } from 'components/molecules';
import { EditIcon } from 'assets/images/';
import { LpDetail, LpOpenSettings, LpView } from 'core/domain/lp';
import {
  formateTimeHHMMColon,
  formatDateYYYYMMDDSeparateSlash,
  formatDateTimeSecISO8601,
} from 'libs/date';
import { hooks } from 'libs';
import DeleteIconButton from 'components/atoms/DeleteIconButton';
import { TYPOGRAPHY } from 'constants/index';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import LpOpenSettingsEditDialog from '../LpOpenSettingsEditDialog';

/**
 * Interface
 */
export interface LPDetailListProps {
  lpDetail: LpDetail;
  handleClickFavorite?: () => void;
  previewData?: LpView;
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleLpOpenSettingsUpdate: (
    lpOpenSettings: LpOpenSettings,
    tag: string,
  ) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 8,
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: 20,
    },
    description: {
      color: theme.palette.gray[900],
    },
    mailDescription: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
    },
    container: {
      margin: '16px 0px',
    },
    titleBox: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    listBox: {
      paddingTop: 8,
      paddingRight: 24,
      paddingBottom: 24,
      paddingLeft: 24,
    },
    titleDivider: {
      height: 2,
    },
    statusLabel: {
      width: 142,
    },
    body: {
      color: theme.palette.gray[900],
      fontSize: 14,
    },
    borderBox: {
      borderBottomColor: theme.palette.gray[600],
      borderBottom: 1,
      borderBottomStyle: 'solid',
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
      height: 36,
      width: 36,
    },
    caption: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
    },
    urlClipboard: {
      color: theme.palette.green[800],
      cursor: 'pointer',
    },
    tagsOpen: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomColor: theme.palette.gray[600],
      borderBottom: 1,
      borderBottomStyle: 'solid',
    },
    lpOpenInfoEdit: {
      width: 110,
      minWidth: 110,
    }
  }),
);

export default function LPDetailList(props: LPDetailListProps) {
  const classes = useStyles({});
  const {
    lpEditPermission,
    step,
    approvalUsers,
    lpDeletePermission,
    enableFavorite,
    lpReleasedURLPermission,
    showUrl,
    lpOpenSettingsEditPermission,
  } = hooks.useLpDetailPermission();
  const {
    lpDetail,
    handleClickFavorite,
    previewData,
    handleEdit,
    handleDelete,
    handleLpOpenSettingsUpdate,
  } = props;
  const prod = lpDetail.lpTag.split(',');
  const lpView = useSelector((state: Modules.AppState) => state.lpView);

  /** タグ・公開設定編集 */
  const [lpOpenSettingsEdit, setLpOpenSettingsEdit] = useState<boolean>(false);

  return (
    <Box>
      <Box className={classes.box}>
        <Box
          className={classes.titleBox}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography variant="subtitle1">{lpDetail.lpId}</Typography>
            <HeadlineLabel text={lpDetail.title} />
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center" mr={1}>
            <Typography
              display="block"
              variant="caption"
              className={classes.caption}
            >
              お気に入り登録数：
            </Typography>
            <FavoriteButton
              disabled={!enableFavorite}
              favorite={lpDetail.favorite}
              favoriteCount={lpDetail.favoriteCount}
              click={handleClickFavorite}
            />
          </Box>
          <Box className={classes.statusLabel}>
            <ApplyStatusLabel
              approvalUsers={approvalUsers}
              step={step}
              status={lpDetail.lpStatus}
            />
          </Box>
          {lpDeletePermission?.show && (
            <Box className={classes.deleteButton}>
              <DeleteIconButton
                disabled={lpDeletePermission.disabled}
                handleDelete={handleDelete}
              />
            </Box>
          )}
        </Box>
        <Divider className={classes.titleDivider} />
        <Box className={classes.listBox}>
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                担当者/部署
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {lpDetail.createdUser.deptName
                  ? `${lpDetail.createdUser.userName}／${lpDetail.createdUser.deptName}`
                  : `${lpDetail.createdUser.userName}`}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                得意先名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {lpDetail.tokuisakiName}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                商材名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {lpDetail.products
                  .map((product) => product.productName)
                  .join('、')}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                商材ID
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {lpDetail.products
                  .map((product) => product.productId)
                  .join('、')}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {lpReleasedURLPermission && (
            <Grid container spacing={0} className={classes.container}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitle}
                  display="inline"
                >
                  公開URL
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {showUrl && (
                  <Box display="flex">
                    <Tooltip
                      title="URLをクリップボードにコピーする"
                      placement="top"
                      arrow
                      className={classes.urlClipboard}
                    >
                      <CopyToClipboard text={lpDetail.url}>
                        <a>ここをクリックすると公開URLをコピーできます。</a>
                      </CopyToClipboard>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
          <Divider />
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                公開期間
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {`${formatDateTimeSecISO8601(
                  lpDetail.openPeriod.openDate,
                ).slice(0, 16)}`}
                -
                {`${formatDateYYYYMMDDSeparateSlash(
                  lpDetail.openPeriod.openPeriodDate,
                )}`}
                {` ${formateTimeHHMMColon(lpDetail.openPeriod.openPeriodTime)}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.tagsOpen}>
        <Box>
          <Box mt={3} mb={1} pb={2}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              タグ
            </Typography>
            <Box mb={1}>
              {prod.map(
                (item, index) => item && <ProductTag item={item} key={index} />,
              )}
            </Box>
          </Box>
          <Box mt={3} mb={1} pb={2}>
            <Box>
              <Typography variant="subtitle1" className={classes.subtitle}>
                メールタイトル
              </Typography>
              <p>{lpDetail.mailSubject}</p>
              <Typography variant="subtitle1" className={classes.subtitle}>
                メール本文
              </Typography>
              <div className={classes.mailDescription}>
                宛先
                <br />
                <p>{lpDetail.mailText}</p>
                URL
                <br />
                {lpDetail.passwordSetting == 2 && 'パスワード'}
                <br />
                <p>{lpDetail.mailSignature}</p>
              </div>
            </Box>
          </Box>
        </Box>
        <Box className={classes.lpOpenInfoEdit}>
          {lpOpenSettingsEditPermission?.show && (
            <PrimaryButton
              click={() => setLpOpenSettingsEdit(true)}
              text="編集する"
            />
          )}
        </Box>
      </Box>
      <Box display="flex" mt={2}>
        <Box flexGrow={1}>
          <Box width={332} ml="43%">
            {lpEditPermission?.show && (
              <PrimaryButton
                text="ランディングページを編集する"
                icon={<EditIcon />}
                disabled={lpEditPermission?.disabled}
                click={handleEdit}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Typography className={classes.previewCaption}>プレビュー</Typography>
          <PreviewButton
            previewData={lpView || undefined}
            templateId={Number(lpView?.designTemplateId)}
          />
        </Box>
      </Box>
      {/* タグ・公開設定編集 */}
      <LpOpenSettingsEditDialog
        open={lpOpenSettingsEdit}
        handleCancel={() => setLpOpenSettingsEdit(false)}
        handleSubmit={
          (lpOpenSettings: LpOpenSettings, tag: string) => {
            setLpOpenSettingsEdit(false);
            handleLpOpenSettingsUpdate(lpOpenSettings, tag)
          }
        }
        lpDetail={lpDetail}
      />
    </Box>
  );
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { InquirySupportsRegisterRequest } from 'core/domain/inquiries'

const initialState = null

export function createInitialState(): InquirySupportsRegisterRequest | null {
  return initialState
}

export const SET_NEW_INQUIRY_SUPPORT = 'new-inquiry-support/set' as const
export const CLEAR_NEW_INQUIRY_SUPPORT = 'new-inquiry-support/clear' as const

export function setNewInquirySupport(data: InquirySupportsRegisterRequest) {
  return {
    type: SET_NEW_INQUIRY_SUPPORT,
    payload: {
      data,
    },
  }
}

export function clearNewInquirySupport() {
  return {
    type: CLEAR_NEW_INQUIRY_SUPPORT,
  }
}

type SetNewInquirySupportAction = ReturnType<typeof setNewInquirySupport>
type ClearNewInquirySupportAction = ReturnType<typeof clearNewInquirySupport>
type Action = SetNewInquirySupportAction | ClearNewInquirySupportAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NEW_INQUIRY_SUPPORT:
      return action.payload.data
    case CLEAR_NEW_INQUIRY_SUPPORT:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React, { useState, useEffect } from 'react';
import {
  Content,
  FileUploadDialog,
  ConfirmDialog,
  SuccessDialog,
} from 'components/organisms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  MultipleLinesTextField,
  OneLineTextField,
  Divider,
  PrimaryButton,
  SecondaryButton,
  Grid,
  LetterCountLabel,
  MessageAlert,
} from 'components/atoms';
import { MyLibraryFiles, MyLibrary } from 'core/domain/myLibrary';
import { PlusIcon } from 'assets/images';
import { OpenDialogType } from 'components/organisms/FileUploadDialog';
import MyLibraryItem from 'components/organisms/MyLibraryItem';
import { fileToBase64 } from 'libs/file';
import { hooks } from 'libs';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';
import { setError } from 'core/modules/error';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      margin: 15,
      color: theme.palette.gray[900],
    },
    divider: {
      marginTop: 48,
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
  }),
);

interface MyLibraryProps {
  libraries: MyLibrary | null;
  submitMyLibrary: (data: MyLibrary) => void;
  successDialogOpen: boolean;
  setSuccessDialogOpen: (state: boolean) => void;
}

const MyLibraryPage: React.FC<MyLibraryProps> = (props) => {
  const {
    libraries,
    submitMyLibrary,
    successDialogOpen,
    setSuccessDialogOpen,
  } = props;
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [fileDialogType, setFileDialogType] = useState<OpenDialogType>('image');
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [openExitConfirmDialog, setOpenExitConfirmDialog] =
    useState<boolean>(false);
  const [greetText, setGreetText] = useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [mailText, setMailText] = useState('');
  const [mailSignature, setMailSignature] = useState('');
  const [greetTextError, setGreetTextError] = useState<string | undefined>(
    undefined,
  );
  const [mailSubjectError, setMailSubjectError] = useState<string | undefined>(
    undefined,
  );
  const [mailTextError, setMailTextError] = useState<string | undefined>(
    undefined,
  );
  const [mailSignatureError, setMailSignatureError] = useState<
    string | undefined
  >(undefined);
  const [myLibraryFiles, setMyLibraryFiles] = useState<Array<MyLibraryFiles>>(
    [],
  );
  const navigate = hooks.useNavigate();
  const [nextPagePath, setNextPagePath] = useState('/adm/dashboard');
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [hasValidation, setHasValidation] = useState(false);
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const location = useLocation();

  const handleSubmit = () => {
    setGreetTextError(undefined);
    setMailSubjectError(undefined);
    setMailTextError(undefined);
    setMailSignatureError(undefined);

    const greetTextMaxLength = 1000;
    const mailSubjectMaxLength = 500;
    const mailTextMaxLength = 5000;
    const mailSignatureMaxLength = 5000;

    const greetTextError = greetText?.length > greetTextMaxLength;
    const mailSubjectError = mailSubject?.length > mailSubjectMaxLength;
    const mailTextError = mailText?.length > mailTextMaxLength;
    const mailSignatureError = mailSignature?.length > mailSignatureMaxLength;

    if (greetTextError) {
      setGreetTextError(`${greetTextMaxLength}文字以内で入力してください`);
    }
    if (mailSubjectError) {
      setMailSubjectError(`${mailSubjectMaxLength}文字以内で入力してください`);
    }
    if (mailTextError) {
      setMailTextError(`${mailTextMaxLength}文字以内で入力してください`);
    }
    if (mailSignatureError) {
      setMailSignatureError(
        `${mailSignatureMaxLength}文字以内で入力してください`,
      );
    }

    if (
      greetTextError ||
      mailSubjectError ||
      mailTextError ||
      mailSignatureError
    ) {
      setHasValidation(true);

      return;
    }

    setHasValidation(false);

    setGoAnotherPage(false);

    submitMyLibrary({
      userId: libraries?.userId || null,
      greetText,
      mailSubject,
      mailText,
      mailSignature,
      myLibraryFiles,
    });
  };

  const deleteImage = (id: number, index: number) => {
    const deleteIndex = index;

    const filteredFiles = myLibraryFiles.filter(
      (item, index) => index !== deleteIndex,
    );

    setMyLibraryFiles(filteredFiles);
  };

  const onFileUploadDialog = (file: File[]) => {
    // const data:Array<MyLibraryFiles> = [];
    const newMyLibraryFiles = myLibraryFiles.slice();
    file.map((f) => {
      void fileToBase64(f).then((res) => {
        const fileData = {
          data: res as string,
          used: false,
        };
        newMyLibraryFiles.push(fileData);
        setMyLibraryFiles(JSON.parse(JSON.stringify(newMyLibraryFiles)));
      });
    });

    setFileDialogType('image');
    // アップ後に子画面クローズ
    setFileDialogOpen(false);
  };

  useEffect(() => {
    if (
      !libraries ||
      libraries.greetText === undefined ||
      libraries.mailSubject === undefined ||
      libraries.mailText === undefined ||
      libraries.mailSignature === undefined ||
      libraries.myLibraryFiles === undefined
    ) {
      return;
    }
    setGreetText(libraries.greetText);
    setMailSubject(libraries.mailSubject);
    setMailText(libraries.mailText);
    setMailSignature(libraries.mailSignature);
    setMyLibraryFiles(libraries.myLibraryFiles);
  }, [libraries]);

  useEffect(() => {
    if (error) return;
    if (userInfo && !userInfo.tenantAdmin && !userInfo.lpAuthor) {
      dispatch(
        setError({
          title: '以下のエラーにより、処理を中止しました。',
          error: ['権限がありません。'],
        }),
      );

      return;
    }

    const res = navigate.block((pathname: string) => {
      if (pathname === location.pathname) return undefined;

      setNextPagePath(pathname);

      if (goAnotherPage) return undefined;

      if (
        greetText !== libraries?.greetText ||
        mailSubject !== libraries?.mailSubject ||
        mailText !== libraries?.mailText ||
        mailSignature !== libraries?.mailSignature ||
        myLibraryFiles !== libraries?.myLibraryFiles
      ) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      res();
    };
  }, [
    dispatch,
    error,
    goAnotherPage,
    greetText,
    libraries,
    mailSignature,
    mailSubject,
    mailText,
    myLibraryFiles,
    navigate,
    nextPagePath,
    openExitConfirmDialog,
    userInfo,
    location.pathname,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  return (
    <Box mt={5}>
      {hasValidation && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}
      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          setSuccessDialogOpen(false);
        }}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
      <Typography variant="h1" className={classes.title}>
        マイライブラリ
      </Typography>
      <FileUploadDialog
        handleSubmit={onFileUploadDialog}
        onClose={() => setFileDialogOpen(false)}
        open={fileDialogOpen}
        isProduct={fileDialogOpen}
        fileType={fileDialogType}
      />
      <Box mt={3}>
        <Content
          title="イメージ"
          sideItem={
            <Box className={classes.sideItems}>
              <PrimaryButton
                click={() => setFileDialogOpen(true)}
                text="新規登録"
                icon={<PlusIcon />}
              />
            </Box>
          }
        >
          <Grid container spacing={3}>
            {myLibraryFiles.map((item, index) => (
              <Grid item xs={2} key={index}>
                <MyLibraryItem
                  used={item.used}
                  fileId={item.fileId || index}
                  imgUrl={item.url || item.data}
                  deleteImage={deleteImage}
                  index={index}
                />
              </Grid>
            ))}
          </Grid>
        </Content>

        <Typography variant="h4" className={classes.title}>
          挨拶文
        </Typography>
        <MultipleLinesTextField
          label="挨拶文を入力"
          error={greetTextError}
          rows={5}
          value={greetText}
          handleChangeText={(text) => setGreetText(text)}
        />
        <LetterCountLabel
          limit="100"
          count={greetText ? String(greetText.length) : '0'}
        />

        <Typography variant="h4" className={classes.title}>
          メールタイトル
        </Typography>
        <OneLineTextField
          label="メールタイトルを入力"
          value={mailSubject}
          error={mailSubjectError}
          handleChangeText={(text) => setMailSubject(text)}
        />
        <LetterCountLabel
          limit="30"
          count={mailSubject ? String(mailSubject.length) : '0'}
        />

        <Typography variant="h4" className={classes.title}>
          メール本文
        </Typography>
        <MultipleLinesTextField
          label="メール本文を入力"
          rows={5}
          value={mailText}
          error={mailTextError}
          handleChangeText={(text) => setMailText(text)}
        />
        <LetterCountLabel
          limit="300"
          count={mailText ? String(mailText.length) : '0'}
        />

        <Typography variant="h4" className={classes.title}>
          メール署名
        </Typography>
        <MultipleLinesTextField
          label="メール署名を入力"
          rows={5}
          value={mailSignature}
          error={mailSignatureError}
          handleChangeText={(text) => setMailSignature(text)}
        />
        <LetterCountLabel
          limit="300"
          count={mailSignature ? String(mailSignature.length) : '0'}
        />
      </Box>
      <Divider className={classes.divider} />
      <Box display="flex" justifyContent="center">
        <Box m={1} width={332} height={48}>
          <SecondaryButton
            text="キャンセル"
            click={() => {
              navigate.navigateReplace('/adm/dashboard');
            }}
          />
        </Box>
        <Box m={1} width={332} height={48}>
          <PrimaryButton text="保存" click={handleSubmit} />
        </Box>
      </Box>
    </Box>
  );
};
export default MyLibraryPage;

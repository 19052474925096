/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { CSSProperties } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, IconButton, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'
import { EditIcon, ErrorIcon, CloseIcon, Grid, ArrowDownIcon, ArrowTopIcon, CheckIcon } from 'assets/images'
import { NavLink, useRouteMatch } from 'react-router-dom'
import paletteOption from 'styles/palette'

/**
 * Interface
 */
export interface NormalEditSideBarItemProps {
  text: string
  clickUp: () => void
  clickDown: () => void
  isTop: boolean
  isBottom: boolean
  handleRemove: () => void
  productId: number
  hasError: boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 242,
      margin: `${theme.spacing(1)}px auto`,
      boxShadow: '1px'
    },
    buttonRoot: {
      background: theme.palette.gray[400],
      borderRadius: 4,
      height: 66,
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        '& p': {
          color: theme.palette.green[900]
        },
      },
      width: 192,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      '&:hover': {
        cursor: 'pointer'
      },
      margin: 'auto 0',
      lineHeight: '18px',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      width: 143,
    },
    button: {
      borderWidth: 1,
      borderColor: theme.palette.gray[800],
      borderStyle: 'solid',
      backgroundColor: theme.palette.green[800],
      '&:hover': {
        backgroundColor: theme.palette.green[900]
      },
      height: 20,
      width: 20,
      padding: 0,
      borderRadius: 20,
      marginLeft: 'auto'
    },
    icon: {
      height: 11,
      width: 11,
      paddingLeft: 4,
      paddingBottom: 2
    },
    errorIcon: {
      marginRight: theme.spacing(1),
      '& path': {
        fill: theme.palette.orange[800]
      },
      width: 14,
      height: 14
    },
    close: {
      height: 20,
      width: 20,
      padding: 4,
      marginBottom: 5,
      marginTop: 4
    },
    grid: {
      width: 9,
      height: 15,
      margin: 'auto 12px',
    },
    arrowIcon: {
      padding: 0,
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
      '&:disabled': {
        '& svg g path': {
          fill: theme.palette.gray[600],
        }
      },
      '& svg g path': {
        fill: theme.palette.green[800],
      }
    },
    link: {
      textDecoration: 'none',
      verticalAlign: 'text-top',
      background: theme.palette.gray[400],
      borderRadius: 4,
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        boxShadow: 'none',
        '& span': {
          color: theme.palette.green[900]
        },
      },
      width: 192,
      height: 55,
      boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
    },
    checkIcon: {
      marginRight: theme.spacing(1),
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.gray[600]
      }
    }
  }),
)

const current: CSSProperties = {
  backgroundColor: paletteOption.green[700],
  color: paletteOption.green[900],
  boxShadow: 'none',
}

/**
 * Presenter
 */

export default function NormalEditSideBarProduct(props: NormalEditSideBarItemProps) {

  const classes = useStyles({})
  const { text, clickUp, clickDown, isTop, isBottom, handleRemove, productId, hasError } = props
  const { url } = useRouteMatch()

  return (
    <Box className={classes.root} display="flex" flexWrap="wrap" alignItems="center">
      {hasError ? <ErrorIcon className={classes.errorIcon} />
        : <CheckIcon className={classes.checkIcon} />}
      <NavLink activeStyle={current} className={classes.link} exact to={`${url}/product/${productId}/edit`}>
        <Box display="flex" height={55}>
          <Grid className={classes.grid} />
          <Typography className={classes.title} variant="body2">{text}</Typography>
          <Box ml="auto" mr="4px">
            <IconButton
              onClick={handleRemove}
              className={classes.close}>
              <CloseIcon />
            </IconButton>
            <Box className={classes.button}>
              <EditIcon className={classes.icon} />
            </Box>
          </Box>
        </Box>

      </NavLink>
      <Box width={20} height={44}>
        <IconButton disabled={isTop} className={classes.arrowIcon} onClick={clickUp}>
          <ArrowTopIcon />
        </IconButton>
        <IconButton disabled={isBottom} className={classes.arrowIcon} onClick={clickDown}>
          <ArrowDownIcon />
        </IconButton>
      </Box>
    </Box>
  )
}/* eslint-enable */

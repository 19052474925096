const initialState = false;

export const createInitialState = () => initialState;

export const SET_SIDEBAR_SHIFT = 'sidebar-shift/set' as const;
export const CLEAR_SIDEBAR_SHIFT = 'sidebar-shift/clear' as const;

export const setSidebarShift = (sidebarShift: boolean) => ({
  type: SET_SIDEBAR_SHIFT,
  payload: {
    sidebarShift,
  },
});

export const clearSidebarShift = () => ({
  type: CLEAR_SIDEBAR_SHIFT,
});

type SetSidebarShiftAction = ReturnType<typeof setSidebarShift>;
type ClearSidebarShiftAction = ReturnType<typeof clearSidebarShift>;
type Action = SetSidebarShiftAction | ClearSidebarShiftAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_SIDEBAR_SHIFT:
      return action.payload.sidebarShift;
    case CLEAR_SIDEBAR_SHIFT:
      return initialState;
    default:
      return state;
  }
};

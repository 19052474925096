import { ProductsQueryParameter } from 'core/domain/product';

const initialState = null;

export const createInitialState = (): ProductsQueryParameter | null =>
  initialState;

export const SET_PRODUCT_SEARCH_CONDITION =
  'productSearchCondition/set' as const;
export const CLEAR_PRODUCT_SEARCH_CONDITION =
  'productSearchCondition/clear' as const;

export const setProductSearchCondition = (data: ProductsQueryParameter) => ({
  type: SET_PRODUCT_SEARCH_CONDITION,
  payload: {
    data,
  },
});

export const clearProductSearchCondition = () => ({
  type: CLEAR_PRODUCT_SEARCH_CONDITION,
});

type SetProductSearchConditionAction = ReturnType<
  typeof setProductSearchCondition
>;
type ClearProductSearchConditionAction = ReturnType<
  typeof clearProductSearchCondition
>;
type Action =
  | SetProductSearchConditionAction
  | ClearProductSearchConditionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_PRODUCT_SEARCH_CONDITION:
      return action.payload.data;
    case CLEAR_PRODUCT_SEARCH_CONDITION:
      return initialState;
    default:
      return state;
  }
};

import { useMemo, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NoImage } from 'assets/images';
import {
  ButtonLink,
  CommonComboBox,
  Box,
  FormControl,
  RadioGroup,
  Typography,
  LetterCountLabel,
  PrimaryButton,
  NormalEditBottomButton,
  NormalEditTopButton,
  CommonRadioButton,
  MultipleLinesTextField,
  SubHeadlineLabel,
  ErrorText,
} from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { TYPOGRAPHY } from 'constants/index';
import { LPRegisterRequest } from 'core/domain/lp';
import { useDispatch } from 'react-redux';
import { setNewLp } from 'core/modules/newLp';
import {
  FileUploadDialog,
  ConfirmDialog,
  MyLibraryImageSelectDialog,
} from 'components/organisms';
import { fileToBase64 } from 'libs/file';
import { hooks } from 'libs';
import { useRouteMatch } from 'react-router';
import { LpMainImage, TantoCompanies } from 'core/domain/settings';
import { NormalEditErrors } from 'core/domain/validationErrors';
import { setNormalEditErrors } from 'core/modules/normalEditErrors';
import { MyLibrary, MyLibraryFiles } from 'core/domain/myLibrary';

/**
 * Interface
 */
export interface NormalEditGreetingProps {
  newLp?: LPRegisterRequest | null;
  tantoCompanies?: TantoCompanies | null;
  normalEditErrors: NormalEditErrors | null;
  myLibrary?: MyLibrary | null;
  myLibraryImage: Array<MyLibraryFiles> | null;
  lpMainVisualImage: Array<LpMainImage> | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    company: {
      marginLeft: theme.spacing(1),
    },
    name: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.green[800],
    },
    icon: {
      width: 16.5,
      height: 16.5,
    },
    img: {
      width: 251,
      height: 136,
      objectFit: 'contain',
    },
  }),
);

/**
 * Presenter
 */

const NormalEditGreeting = (props: NormalEditGreetingProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { newLp, tantoCompanies, normalEditErrors, myLibrary, myLibraryImage } =
    props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = hooks.useNavigate();
  const { url } = useRouteMatch();
  // マイライブラリ空選択子画面
  const [myLibraryDialogOpen, setMyLibraryDialogOpen] = useState(false);
  // マイライブラリ上書き確認ダイアログ
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  // マイライブラリからコピーボタン活性非活性
  const [copyFromMyLibraryDisabled, setcopyFromMyLibraryDisabled] =
    useState(false);
  // マイライブラリから選択ボタン活性非活性
  const [selectFromMyLibraryDisabled, setSelectFromMyLibraryDisabled] =
    useState(false);
  // マイライブラリからコピーボタン活性非活性処理
  useEffect(() => {
    if (myLibrary?.greetText) {
      setcopyFromMyLibraryDisabled(false);
    } else {
      setcopyFromMyLibraryDisabled(true);
    }
  }, [myLibrary]);
  // マイライブラリから選択ボタン活性非活性
  useEffect(() => {
    if (myLibrary?.myLibraryFiles?.length) {
      setSelectFromMyLibraryDisabled(false);
    } else {
      setSelectFromMyLibraryDisabled(true);
    }
  }, [myLibrary]);

  // マイライブラリからtextをコピーする
  const textCopyFromMyLibrary = () => {
    dispatch(
      setNewLp({
        ...newLp,
        greetText: myLibrary?.greetText,
      }),
    );
    // 未入力エラーundefined
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        greetText: undefined,
      }),
    );
  };

  // マイライブラリのtextで上書きするか否か
  const copyFromMyLibrary = (value?: string) => {
    if (value) {
      // 上書き時にダイアログ表示
      setOpenExitConfirmDialog(true);
    } else {
      textCopyFromMyLibrary();
    }
  };

  useEffect(() => {
    if (!newLp) return;
    if (
      newLp &&
      (newLp.profileDisplayed === null || newLp.profileDisplayed === undefined)
    ) {
      dispatch(
        setNewLp({
          ...newLp,
          profileDisplayed: false,
        }),
      );
    }
  }, [newLp, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setNewLp({
        ...newLp,
        profileDisplayed: event.target.value === 'yes',
      }),
    );
    if (
      event.target.value === 'no' ||
      (event.target.value === 'yes' && newLp?.tantoCompany)
    ) {
      dispatch(
        setNormalEditErrors({
          ...normalEditErrors,
          tantoCompany: undefined,
        }),
      );
    }
  };

  const handleTextChange = (key: string, value: string) => {
    dispatch(
      setNewLp({
        ...newLp,
        [key]: value,
      }),
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        [key]: undefined,
      }),
    );
  };

  // マイライブラリから選択
  const handleFileSubmit = (ids?: number) => {
    if (!ids) {
      dispatch(
        setNewLp({
          ...newLp,
          tantoImageData: {
            lpTantoImageId: undefined,
            url: undefined,
          },
        }),
      );
    } else {
      const image = myLibraryImage?.find((image) => image.fileId === ids);
      dispatch(
        setNewLp({
          ...newLp,
          tantoImageData: {
            lpTantoImageId: image?.fileId,
            url: image?.url,
          },
        }),
      );
    }
  };

  const handleClearTantoImage = () => {
    dispatch(
      setNewLp({
        ...newLp,
        tantoImageData: {
          lpTantoImageId: undefined,
          url: undefined,
        },
      }),
    );
  };

  const handleUploadFileSubmit = async (files: File[]) => {
    if (!files || !files.length || !files[0]) return;

    const tantoImageData = {
      ...newLp?.tantoImageData,
      url: undefined,
      lpTantoImageData: undefined,
    };

    await fileToBase64(files[0]).then((res) => {
      dispatch(
        setNewLp({
          ...newLp,
          tantoImageData: {
            ...tantoImageData,
            lpTantoImageData: res as string,
          },
        }),
      );
    });
  };

  const handleNavigate = (to = '') => {
    const index = url.lastIndexOf('/');
    const tmp = url.slice(0, index);
    navigate.navigate(`${tmp}${to}`);
  };

  const nextNavigateTitle = useMemo(() => {
    let title = '商材の選択';
    if (
      !newLp ||
      !newLp?.products ||
      !newLp.products.length ||
      !newLp.products[0]
    )
      return title;
    title = newLp.products[0].productName;

    return title;
  }, [newLp]);

  const lpTantoImageData = useMemo(() => {
    if (!newLp || !newLp.tantoImageData) return undefined;

    return newLp.tantoImageData.lpTantoImageData
      ? newLp.tantoImageData.lpTantoImageData
      : newLp.tantoImageData.url;
  }, [newLp]);

  return (
    <Box className={classes.root}>
      <FileUploadDialog
        open={dialogOpen}
        fileType="image"
        isProduct={false}
        onClose={() => setDialogOpen(false)}
        handleSubmit={async (files) => {
          setDialogOpen(false);
          await handleUploadFileSubmit(files);
        }}
      />
      {/* マイライブラリから選択 */}
      <MyLibraryImageSelectDialog
        myLibraryImages={myLibraryImage}
        selectedId={newLp?.tantoImageData?.lpTantoImageId}
        open={myLibraryDialogOpen}
        handleCancel={() => setMyLibraryDialogOpen(false)}
        handleSubmit={(id?: number) => {
          setMyLibraryDialogOpen(false);
          handleFileSubmit(id);
        }}
      />

      <Box mt={3} width={696} mx="auto">
        <MandatoryHeadlineLabel title="自己紹介文" />
        <FormControl>
          <RadioGroup
            aria-label="company"
            value={newLp?.profileDisplayed ? 'yes' : 'no'}
            onChange={handleChange}
          >
            <CommonRadioButton value="yes" label="表示あり" />
            <Box>
              <Box
                mb={2}
                ml={4}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <CommonComboBox
                  disabled={!newLp?.profileDisplayed}
                  width="332px"
                  value={newLp?.tantoCompany}
                  handleChangeText={(text) =>
                    handleTextChange('tantoCompany', text)
                  }
                  options={
                    tantoCompanies?.tantoCompanies
                      ? tantoCompanies.tantoCompanies
                      : []
                  }
                />
                <Typography className={classes.company} variant="caption">
                  <span className={classes.name}>{newLp?.tantoName}</span>です。
                </Typography>
              </Box>
              {normalEditErrors?.tantoCompany && (
                <ErrorText error={normalEditErrors.tantoCompany} />
              )}
            </Box>
            <CommonRadioButton value="no" label="非表示" />
          </RadioGroup>
        </FormControl>
        <Box display="flex">
          <Box flexGrow={1}>
            <MandatoryHeadlineLabel title="挨拶文" />
          </Box>
          {/* マイライブラリからコピー */}
          <Box mt={3}>
            <ButtonLink
              click={() => copyFromMyLibrary(newLp?.greetText)}
              disabled={copyFromMyLibraryDisabled}
              fontSize={TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75}
              text="マイライブラリからコピー"
            />
          </Box>
        </Box>
        <MultipleLinesTextField
          error={normalEditErrors?.greetText}
          value={newLp?.greetText}
          handleChangeText={(text) => handleTextChange('greetText', text)}
          rows={2}
        />
        <LetterCountLabel
          limit="100"
          count={`${newLp?.greetText ? newLp.greetText.length : '0'}`}
        />
        <SubHeadlineLabel text="営業写真" />
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <img className={classes.img} src={lpTantoImageData || NoImage} />
          <Box width={182} ml={1}>
            {/* マイライブラリから選択 */}
            <Box height={27} mb={1}>
              <PrimaryButton
                click={() => setMyLibraryDialogOpen(true)}
                disabled={selectFromMyLibraryDisabled}
                fontSize={TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75}
                text="マイライブラリから選択"
              />
            </Box>
            <Box height={27} mb={1}>
              <PrimaryButton
                fontSize={TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75}
                text="画像をアップロード"
                click={() => setDialogOpen(true)}
              />
            </Box>
            <Box height={27}>
              <PrimaryButton
                fontSize={TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75}
                text="画像をクリア"
                click={() => handleClearTantoImage()}
              />
            </Box>
          </Box>
        </Box>
        <MandatoryHeadlineLabel title="商材案内の冒頭文" />
        <MultipleLinesTextField
          error={normalEditErrors?.beginningText}
          value={newLp?.beginningText}
          rows={3}
          handleChangeText={(text) => handleTextChange('beginningText', text)}
        />
        <LetterCountLabel
          limit="30～40"
          count={`${newLp?.beginningText ? newLp.beginningText.length : '0'}`}
        />
      </Box>
      <NormalEditTopButton
        click={() => handleNavigate()}
        text="メインビジュアル"
      />
      <NormalEditBottomButton
        click={() => {
          if (
            !newLp?.products ||
            !newLp?.products.length ||
            !newLp.products[0]
          ) {
            handleNavigate('/select-product');
          } else {
            handleNavigate(`/product/${newLp.products[0].productId}/edit`);
          }
        }}
        text={nextNavigateTitle}
      />

      {/* マイライブラリからコピー上書きの時に出すダイアログ */}
      <ConfirmDialog
        buttonText="OK"
        open={openExitConfirmDialog}
        title="上書きします"
        text="内容を上書きしても"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          textCopyFromMyLibrary();
          setOpenExitConfirmDialog(false);
        }}
      />
    </Box>
  );
};

export default NormalEditGreeting;

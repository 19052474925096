import { IntroductionExampleLabel } from 'components/molecules';
import { Grid, Box } from 'components/atoms';
import { ExampleDetail } from 'core/domain/example';

/**
 * Interface
 */

export interface ExampleListProps {
  productExamples: Array<ExampleDetail> | null;
  click?: (id: number) => void;
}

/**
 * Presenter
 */
const ExampleList = (props: ExampleListProps) => {
  const { productExamples, click } = props;

  return (
    <Box>
      <Grid container spacing={2}>
        {productExamples &&
          productExamples.map((example, index) => {
            if (example) {
              return (
                <Grid item xs={4} key={index}>
                  <IntroductionExampleLabel
                    exampleName={example.exampleName}
                    customerName={example.customerName}
                    click={() => click && click(example.exampleMaterialId)}
                  />
                </Grid>
              );
            }

            return null;
          })}
      </Grid>
    </Box>
  );
};
export default ExampleList;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CheckIcon,
  ErrorIcon,
  DownloadIcon,
  EditIcon,
  ArrowLeftIcon,
  CopyIcon,
  ReturnIcon,
} from 'assets/images';
import {
  MessageAlert,
  Box,
  ButtonLink,
  PrimaryButton,
  SupportTextLabel,
  QuaternaryButton,
  Typography,
  Grid,
  SecondaryButton,
  TertiaryButton,
  TextTypeButton,
} from 'components/atoms';
import {
  Content,
  ApplyHistoryList,
  ReactionCountList,
  ContactHistoryTable,
  LPDetailList,
  TextEditDialog,
  NoticeDialog,
  DeclineDialog,
  ApplyDialog,
  AppliedDialog,
  LpOpenDialog,
  SuccessDialog,
  OpendDialog,
  ConfirmDialog,
  LPMailDialog,
  ErrorDialog,
} from 'components/organisms';
import { TargetPeriodLabel } from 'components/molecules';
import { hooks } from 'libs';
import {
  LpDetail,
  LpInquiries,
  LpOpenSettings,
  LpReaction,
  MailSender,
  OpenPeriod,
} from 'core/domain/lp';
import { MaterialWorkflow } from 'core/domain/material';
import {
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
  CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_LP_STATUS_APPROVAL,
  CODE_LP_STATUS_RELEASED,
  CODE_LP_STATUS_SUSPENSION,
  CODE_LP_STATUS_END,
} from 'constants/code';
import { checkPastDay, formateTimeHHMMColon, validDate } from 'libs/date';

import { AddTerm } from 'core/domain/settings';
import clsx from 'clsx';
import { Modules, Usecases } from 'core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearPreviousLpsUrl } from 'core/modules/previousLpsUrl';
import { setIsDuplicatedLp } from 'core/modules/isDuplicatedLP';
import { INVALID_DATE } from 'constants/text';
import MailHistoryList from 'components/organisms/MailHistoryList';

/**
 * Interface
 */
interface LpDetailPageProps {
  lpDetail: LpDetail;
  lpReaction: LpReaction | null;
  lpReactionRange: AddTerm | null;
  handleFavoriteUpdate: () => void;
  searchReaction: (start?: string, end?: string) => void;
  lpWorkflows: Array<MaterialWorkflow> | null;
  lpInquiries: LpInquiries | null;
  inquiryTable: (sortKey?: string, sortOrder?: string) => void;
  handleNoteUpdate: (note: string) => void;
  handleWorkflowStatusUpdate: (
    step: number,
    status: number,
    message?: string,
  ) => void;
  handleStop: () => void;
  handleReopen: () => void;
  handleChangeOpenPeriod: (date?: string, time?: string) => void;
  handleCsvDl: () => void;
  handleInquiry: () => void;
  handleDelete?: () => void;
  handleMailHistoryPageChange: (page: number) => void;
  mailHistoryPage: number;
  handleLpOpenSettingsUpdate: (
    lpOpenSettings: LpOpenSettings,
    tag: string,
  ) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginBottom: 25,
      color: theme.palette.gray[900],
    },
    root: {
      fontSize: '14px',
      position: 'relative',
      wordWrap: 'break-word',
    },
    body: {
      fontSize: '14px',
      position: 'relative',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    buttonContainer: {
      height: '72px',
      fontSize: '18px',
      marginBottom: 30,
      marginTop: 30,
    },
    buttonContainers: {
      fontSize: '18px',
      margin: '20px auto',
    },
    button: {
      height: '48px',
      width: '332px',
      margin: '40px auto',
    },
    textButton: {
      justifyContent: 'center',
      height: '18px',
      width: '332px',
      margin: '20px auto',
    },
    link: {
      color: theme.palette.green[800],
      cursor: 'pointer',
    },
    goBackLink: {
      marginBottom: theme.spacing(2),
    },
    box: {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
    },
    stopButton: {
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(7),
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: 20,
    },
    borderBox: {
      borderBottom: 1,
      borderBottomColor: theme.palette.gray[600],
      borderBottomStyle: 'solid',
    },
    count: {
      color: theme.palette.pink[900],
    },
    caption: {
      color: theme.palette.gray[800],
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
  }),
);

export interface ReactionRange {
  start?: string;
  end?: string;
}

interface LpModalVisible {
  noticeDialog: boolean;
  noteDialog: boolean;
  declineDialog: boolean;
  applyDialog: boolean;
  appliedDialog: boolean;
  openDialog: boolean;
  successDialog: boolean;
  opendDialog: boolean;
  confirmDialog: boolean;
  moveToTashDialog: boolean;
  pastDateDialog: boolean;
  invalidMaterialDialog: boolean;
  mailDialog: boolean;
  mailReservedDialog: boolean;
  lpStopDialog: boolean;
}

interface ValidationError {
  startReactionRange?: string;
  endReactionRange?: string;
}

interface TmpOpenPeriod {
  date: string;
  time: string;
}

/**
 * Presenter
 */

const LPDetail = (props: LpDetailPageProps) => {
  const {
    lpDetail,
    lpReaction,
    lpReactionRange,
    handleFavoriteUpdate,
    searchReaction,
    lpWorkflows,
    lpInquiries,
    inquiryTable,
    handleNoteUpdate,
    handleWorkflowStatusUpdate,
    handleStop,
    handleReopen,
    handleChangeOpenPeriod,
    handleCsvDl,
    handleInquiry,
    handleDelete,
    handleMailHistoryPageChange,
    mailHistoryPage,
    handleLpOpenSettingsUpdate,
  } = props;
  const classes = useStyles({});
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const previousLpsUrl = useSelector(
    (state: Modules.AppState) => state.previousLpsUrl,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const lpView = useSelector(
    (state: Modules.AppState) => state.lpView,
    shallowEqual,
  );
  const customers = useSelector(
    (state: Modules.AppState) => state.customers,
    shallowEqual,
  );
  const mailHistory = useSelector(
    (state: Modules.AppState) => state.mailHistory,
  );
  const error = useSelector((state: Modules.AppState) => state.error);
  const isApproved = useHistory().location.pathname.includes('approved');
  const customerTagsRequest = useSelector(
    (state: Modules.AppState) => state.customerTagsRequest,
    shallowEqual,
  );
  const {
    lpCreatePermission,
    lpMemoEditPermission,
    lpDeclinePermission,
    lpApprovePermission,
    lpOpenPermission,
    lpPeriodChangePermission,
    lpSuspenstionPermission,
    lpReopenPermission,
    notification,
    step,
    hasInvalidMaterial,
    showInquiriesHistory,
    lpMailPermission,
  } = hooks.useLpDetailPermission();
  const [reactionRange, setReactionRange] = useState<ReactionRange>({
    start: lpReactionRange?.addTermFrom,
    end: lpReactionRange?.addTermTo,
  });
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [currentLpStatus, setCurrentLpStatus] = useState<number | undefined>();
  const [currentOpenPeriod, setCurrentOpenPeriod] = useState<
    OpenPeriod | undefined
  >(lpDetail.openPeriod);
  const [tmpOpenPeriod, setTmpOpenPeriod] = useState<
    TmpOpenPeriod | undefined
  >();
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [modalVisible, setModalVisible] = useState<LpModalVisible>({
    noticeDialog: false,
    noteDialog: false,
    declineDialog: false,
    applyDialog: false,
    appliedDialog: false,
    openDialog: false,
    successDialog: false,
    opendDialog: false,
    confirmDialog: false,
    moveToTashDialog: false,
    pastDateDialog: false,
    invalidMaterialDialog: false,
    mailDialog: false,
    mailReservedDialog: false,
    lpStopDialog: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [invalidMaterialDialogTitle, setInvalidMaterialDialogTitle] =
    useState('');
  const [isWorkflowStatusUpdate, setIsWorkflowStatusUpdate] =
    useState<boolean>(false);
  const [inquiryRegisterControl, setInquiryRegisterControl] = useState(false);

  // CSVダウンロードボタン制御
  const [lpCSVDisabled, setLpCSVDisabled] = useState(false);
  const [inquiryCSVDisabled, setInquiryCSVDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [mailErrorDialogOpen, setMailErrorDialogOpen] =
    useState<boolean>(false);

  const searchReactionByRange = (start?: string, end?: string) => {
    const startDate = validDate(start || '');
    const endDate = validDate(end || '');

    if (startDate !== undefined && endDate !== undefined) {
      setValidationError(undefined);
      searchReaction(start, end);
    }

    setValidationError({
      startReactionRange: startDate !== undefined ? undefined : INVALID_DATE,
      endReactionRange: endDate !== undefined ? undefined : INVALID_DATE,
    });
  };

  useEffect(() => {
    // メール送信のダイアログを閉じた時にメール送信履歴を最新化
    if (!userInfo || modalVisible.mailDialog !== false) return;

    dispatch(
      Usecases.lps.sendGetMailHistory(
        token,
        userInfo.tenantId,
        lpDetail.lpId.toString(),
        mailHistoryPage,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userInfo, lpDetail.lpId, modalVisible.mailDialog]);

  // カレンダーに集計期間をセット
  useEffect(()=>{
    setReactionRange({
      start: lpReactionRange?.addTermFrom,
      end: lpReactionRange?.addTermTo,
    });
  },[lpReactionRange])

  useEffect(() => {
    if (reactionRange.start !== undefined && reactionRange.end !== undefined)
      searchReactionByRange(reactionRange.start, reactionRange.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactionRange]);
  useEffect(() => {
    dispatch(
      Usecases.customer.sendGetCustomers(token, userInfo?.tenantId || '', {}),
    );
    dispatch(
      Usecases.customer.sendGetCustomerTags(
        token,
        userInfo?.tenantId || '',
        customerTagsRequest,
      ),
    );
    // dispatch(Usecases.lps.sendGetMailHistory(token , userInfo?.tenantId , String(lpDetail.lpId)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (!currentOpenPeriod ||
        currentLpStatus === CODE_MATERIAL_STATUS_APPROVAL) &&
      lpDetail.lpStatus === CODE_MATERIAL_STATUS_RELEASED
    ) {
      // 公開された場合
      setModalVisible({
        ...modalVisible,
        opendDialog: true,
      });
    } else if (
      currentOpenPeriod &&
      currentLpStatus === CODE_MATERIAL_STATUS_RELEASED &&
      lpDetail.openPeriod.openPeriodDate &&
      lpDetail.openPeriod.openPeriodTime &&
      (currentOpenPeriod.openPeriodDate !==
        lpDetail.openPeriod.openPeriodDate ||
        currentOpenPeriod.openPeriodTime !==
          lpDetail.openPeriod.openPeriodTime) &&
      lpDetail.lpStatus === CODE_MATERIAL_STATUS_RELEASED
    ) {
      // LP公開期限が設定済みから日付が変更された
      setModalVisible({
        ...modalVisible,
        successDialog: true,
      });
    }

    setCurrentLpStatus(lpDetail.lpStatus);
    setCurrentOpenPeriod(lpDetail.openPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail.openPeriod]);

  useEffect(() => {
    if (currentStep && step < currentStep) {
      // 承認フローが巻き戻った場合（ = 差し戻しが実行された場合）は、
      // 差し戻し完了ダイアログを表示
      setModalVisible({
        ...modalVisible,
        noticeDialog: true,
      });
    } else if (currentStep && step > currentStep) {
      // 承認フローが進んだ場合（ = 承認が実行された場合）は、
      // 承認完了ダイアログを表示
      setModalVisible({
        ...modalVisible,
        appliedDialog: true,
      });
    }

    setCurrentStep(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setInquiryRegisterControl(JSON.parse(JSON.stringify(false)));
    if (lpDetail) {
      if (
        lpDetail.lpStatus === CODE_LP_STATUS_RELEASED ||
        lpDetail.lpStatus === CODE_LP_STATUS_SUSPENSION ||
        lpDetail.lpStatus === CODE_LP_STATUS_END
      ) {
        if (
          (userInfo?.lpAuthor &&
            lpDetail?.createdUser.userId === userInfo.userId) ||
          userInfo?.tenantAdmin
        ) {
          setInquiryRegisterControl(JSON.parse(JSON.stringify(true)));
        }
      }
    }
  }, [lpDetail, userInfo, userInfo?.displayName, userInfo?.tenantAdmin]);

  const enableDecline = useMemo(
    () => lpDetail.lpStatus !== CODE_MATERIAL_STATUS_DECLINE,
    [lpDetail],
  );

  const handleChangeStartDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      start: date.replaceAll('/', ''),
    });
  };

  const handleChangeEndDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      end: date.replaceAll('/', ''),
    });
  };

  const handleOpen = (date?: string, time?: string) => {
    handleChangeOpenPeriod(
      date && date.replaceAll('/', ''),
      time && time.replace(':', ''),
    );
  };

  const updateSortParam = (keyValue: string, orderValue: string) => {
    inquiryTable(keyValue, orderValue);
  };

  const closeDialog = useCallback(() => {
    setModalVisible({
      noticeDialog: false,
      noteDialog: false,
      declineDialog: false,
      applyDialog: false,
      appliedDialog: false,
      openDialog: false,
      successDialog: false,
      opendDialog: false,
      confirmDialog: false,
      moveToTashDialog: false,
      pastDateDialog: false,
      invalidMaterialDialog: false,
      mailDialog: false,
      mailReservedDialog: false,
      lpStopDialog: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const handleDeleteLp = () => {
    closeDialog();
    if (handleDelete) handleDelete();
  };

  // LP公開時にメール配信設定押下
  const handleMailOpen = () => {
    setModalVisible({
      ...modalVisible,
      opendDialog: false,
      mailDialog: true,
    });
  };

  const test = () => {
    if (
      !userInfo?.tenantId ||
      reactionRange.start === undefined ||
      reactionRange.end === undefined
    )
      return;

    dispatch(
      Usecases.lps.sendLpReactionDownLoad(
        token,
        userInfo?.tenantId,
        lpDetail.lpId,
        reactionRange.start,
        reactionRange.end,
      ),
    );
  };

  const newInquiryCount = useMemo(() => {
    if (!lpInquiries || !lpInquiries.inquiries) return 0;

    return lpInquiries.inquiries.filter((inquiry) => !inquiry.newestSupportType)
      .length;
  }, [lpInquiries]);

  const lpButton = lpCreatePermission?.show && (
    <Box mb={6} width={319} height={36}>
      <PrimaryButton
        disabled={lpCreatePermission.disabled}
        text="このランディングページを元に作成する"
        icon={<CopyIcon />}
        click={() => {
          dispatch(setIsDuplicatedLp(true));
          if (!isApproved)
            navigate.navigate(`/adm/lps/${lpDetail.lpId}/register`);
          else navigate.navigate(`/adm/lps/approved/${lpDetail.lpId}/register`);
        }}
      />
      <Box mt={1}>
        <SupportTextLabel text="今ランディングページを複製して再編集することで新しくページを作成することができます。" />
      </Box>
    </Box>
  );
  const contactButton = (
    <Box display="flex">
      <Box width={144} height={36}>
        {inquiryRegisterControl ? (
          <PrimaryButton
            text="＋ 新規登録する"
            click={async () => {
              // eslint-disable-next-line
              await handleInquiry();
              navigate.navigate('/adm/inquiry/register');
            }}
          />
        ) : null}
      </Box>
      <Box width={178} height={36} ml={1}>
        <PrimaryButton
          text="CSVダウンロード"
          icon={<DownloadIcon />}
          disabled={inquiryCSVDisabled}
          click={() => {
            setInquiryCSVDisabled(true);
            handleCsvDl();
            setTimeout(() => setInquiryCSVDisabled(false), 1000);
          }}
        />
      </Box>
    </Box>
  );

  // 連続承認時にボタンを押せるようにする
  const handleAppliedDialogClose = useCallback(() => {
    if (!lpWorkflows || !userInfo) return;
    const lpWorkflow = lpWorkflows[0];
    const isTenantAdmin = userInfo.tenantAdmin;
    const isStep2Approver = !!lpWorkflow.step2ApprovalUserId;
    const isStep1Approved =
      lpWorkflow.step1Status === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL;
    const isStep3Approver = !!lpWorkflow.step3ApprovalUserId;
    const isStep2Approved =
      lpWorkflow.step2Status === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL;

    if (isStep2Approver && isStep1Approved && !isTenantAdmin) {
      const isSameUserFrom1To2 =
        lpWorkflow.step1ApprovalUserId === lpWorkflow.step2ApprovalUserId;
      setIsWorkflowStatusUpdate(!isSameUserFrom1To2);
    }
    if (isStep3Approver && isStep2Approved && !isTenantAdmin) {
      const isSameUserFrom2To3 =
        lpWorkflow.step2ApprovalUserId === lpWorkflow.step3ApprovalUserId;
      setIsWorkflowStatusUpdate(!isSameUserFrom2To3);
    }
    if (isTenantAdmin) setIsWorkflowStatusUpdate(false);

    closeDialog();
  }, [closeDialog, lpWorkflows, userInfo]);

  const handleMailDialogSubmit = useCallback(
    (date: string, time: string, data: MailSender[]) => {
      if (!token || !userInfo || !lpDetail) return;
      dispatch(
        Usecases.lps.sendPostLpMail(
          token,
          userInfo.tenantId,
          String(lpDetail.lpId),
          {
            mailSendDate: date,
            mailSendTime: time,
            mailSenders: data.map((item: MailSender) => ({
              customerId: item.customerId,
              customerName: item.customerName,
              companyName: item.companyName,
              mailAddress: item.mailAddress,
              customerRegist: item.customerRegist,
            })),
          },
        ),
      );
      if (!error) {
        setModalVisible({ ...modalVisible, mailReservedDialog: true });
      }
    },
    [dispatch, error, lpDetail, modalVisible, token, userInfo],
  );

  const mailDataCheck = () => {
    if (lpDetail.mailSubject === null || lpDetail.mailText === null) {
      setMailErrorDialogOpen(true);

      return;
    }

    setModalVisible({
      ...modalVisible,
      mailDialog: true,
    });
  };

  // 公開終了押下時
  const getTimeNow = () => {

    // 現在時刻取得
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const date = new Date().getDate()
    const hour = new Date().getHours()
    const minute = new Date().getMinutes()
    
    // 0埋めフォーマットyyyymmddmmss
    const tmpDate = (year.toString()+(`0${month.toString()}`.slice(-2))+
    (`0${date.toString()}`.slice(-2)))
    const tmpTime = ((`0${hour.toString()}`.slice(-2))+(`0${minute.toString()}`.slice(-2)))

    setTmpOpenPeriod({ date: tmpDate, time: tmpTime } as TmpOpenPeriod);

  }

  return (
    <Box
      mt={5}
      className={clsx(classes.root, { [classes.alertVisible]: notification })}
    >
      <ErrorDialog
        open={mailErrorDialogOpen}
        title="メール内容が登録されておりません。"
        message={[
          'ランディングページを修正し、メール内容を追加登録してください。\n※修正には再度承認者の承認が必要となります',
        ]}
        handleClose={() => {
          setMailErrorDialogOpen(false);
        }}
      />

      {notification && (
        <Box className={classes.messageAlert}>
          <MessageAlert
            text={notification.text}
            severity={notification.severity}
          />
        </Box>
      )}
      <Box
        className={classes.goBackLink}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        <ButtonLink
          icon={<ArrowLeftIcon />}
          iconPosition="start"
          text="前の画面に戻る"
          click={() => {
            if (previousLpsUrl) navigate.navigate(previousLpsUrl.url);
            else navigate.navigatePop();
            dispatch(clearPreviousLpsUrl());
          }}
        />
      </Box>
      <Content title="ランディングページ詳細情報" sideItem={lpButton}>
        <LPDetailList
          lpDetail={lpDetail}
          handleClickFavorite={handleFavoriteUpdate}
          handleEdit={() =>
            !isApproved
              ? navigate.navigate(`/adm/lps/${lpDetail.lpId}/edit`)
              : navigate.navigate(`/adm/lps/approved/${lpDetail.lpId}/edit`)
          }
          handleDelete={() => {
            if (lpDetail.anotherlpStatus) {
              setModalVisible({
                ...modalVisible,
                confirmDialog: true,
              });
            } else {
              setModalVisible({
                ...modalVisible,
                moveToTashDialog: true,
              });
            }
          }}
          handleLpOpenSettingsUpdate={handleLpOpenSettingsUpdate}
        />
      </Content>
      <Box>
        <Box mt={6} className={classes.box}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            備考・メモ
          </Typography>
          <Box className={classes.borderBox} mb={3} pb={3}>
            <Typography variant="body2" className={classes.body}>
              {lpDetail.note}
            </Typography>
          </Box>
          <Box width={332} mx="auto">
            {lpMemoEditPermission?.show && (
              <PrimaryButton
                disabled={lpMemoEditPermission.disabled}
                text="備考・メモを編集する"
                icon={<EditIcon />}
                click={() =>
                  setModalVisible({
                    ...modalVisible,
                    noteDialog: true,
                  })
                }
              />
            )}
          </Box>
        </Box>
      </Box>
      <Grid spacing={2} container justify="center" direction="column">
        {lpOpenPermission?.show && (
          <Grid item>
            <Box
              mx="auto"
              width={506}
              height={72}
              className={classes.buttonContainers}
            >
              <TertiaryButton
                disabled={lpOpenPermission.disabled}
                text="ランディングページを公開する"
                icon={<CheckIcon />}
                click={() => {
                  setModalVisible({
                    ...modalVisible,
                    openDialog: true,
                  });
                }}
              />
            </Box>
          </Grid>
        )}
        {lpMailPermission?.show && (
          <Grid item>
            <Box
              mx="auto"
              width={506}
              height={72}
              className={classes.buttonContainers}
            >
              <TertiaryButton
                disabled={lpMailPermission.disabled}
                text="ランディングページをメール配信する"
                icon={<CheckIcon />}
                click={() => {
                  mailDataCheck();
                }}
              />
            </Box>
          </Grid>
        )}
        {lpPeriodChangePermission?.show && (
          <Grid item>
            <Box
              mx="auto"
              width={332}
              height={48}
              className={classes.buttonContainers}
            >
              <QuaternaryButton
                disabled={lpPeriodChangePermission?.disabled}
                text="公開終了する"
                icon={<ErrorIcon />}
                click={() => {
                  // 公開終了日時を現在にする
                  getTimeNow();
                  // 公開終了ダイアログ開く
                  setModalVisible({
                    ...modalVisible,
                    pastDateDialog: true,
                  });
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {lpPeriodChangePermission?.show && (
        <Box
          mx="auto"
          width={506}
          height={18}
          className={classes.textButton}
        >
          <TextTypeButton
              disabled={lpPeriodChangePermission.disabled}
              text="公開終了日時を予約（変更）する"
              fontSize="18"
              click={() => {
              setModalVisible({
                ...modalVisible,
                openDialog: true,
              });
            }}
          />
        </Box>
      )}
      {lpSuspenstionPermission?.show && (
        <Box
          mx="auto"
          width={506}
          height={18}
          className={classes.textButton}
        >
          <TextTypeButton
            disabled={lpSuspenstionPermission?.disabled}
            text="公開を一時停止する"
            fontSize="18"
            click={() => setModalVisible({
               ...modalVisible,
              lpStopDialog: true,
            })}
          />
        </Box>
      )}
      {lpReopenPermission?.show && (
        <Box
        mx="auto"
        width={506}
        height={18}
        className={classes.textButton}
        >
          <TextTypeButton
            disabled={lpReopenPermission?.disabled}
            text="公開を再開する"
            fontSize="18"
            click={() => handleReopen()}
          />
        </Box>
      )}
      
      <Grid
        container
        spacing={2}
        justify="center"
      >
        {lpDeclinePermission?.show && enableDecline && (
          <Grid item xs={5} 
          className={classes.buttonContainer}
          >
            <SecondaryButton
              disabled={lpDeclinePermission.disabled || isSubmitting}
              text="ランディングページを差し戻す"
              icon={<ReturnIcon />}
              click={() => {
                setModalVisible({
                  ...modalVisible,
                  declineDialog: true,
                });
              }}
            />
          </Grid>
        )}
        {lpApprovePermission?.show && (
          <Grid item xs={5}
          className={classes.buttonContainer}
        >
            <PrimaryButton
              disabled={lpApprovePermission.disabled || isSubmitting}
              text="ランディングページを公開承認する"
              icon={<CheckIcon />}
              click={() => {
                setModalVisible({
                  ...modalVisible,
                  applyDialog: true,
                });
              }}
            />
          </Grid>
        )}
      </Grid>
      <Box mt={5}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Typography variant="h3" className={classes.title}>
              ランディングページの
              <br />
              リアクション数
            </Typography>
          </Grid>
          <Box style={{ display: 'flex', float: 'right' }}>
            <Grid item>
              <TargetPeriodLabel
                start={reactionRange.start}
                end={reactionRange.end}
                startError={validationError?.startReactionRange}
                endError={validationError?.endReactionRange}
                handleChangeStartDate={(date: string) =>
                  handleChangeStartDate(date)
                }
                handleChangeEndDate={(date: string) =>
                  handleChangeEndDate(date)
                }
              />
            </Grid>
            <Grid item>
              <Box width={178} height={36} ml={1}>
                <PrimaryButton
                  text="CSVダウンロード"
                  click={() => {
                    setLpCSVDisabled(true);
                    test();
                    setTimeout(() => setLpCSVDisabled(false), 1000);
                  }}
                  icon={<DownloadIcon />}
                  disabled={lpCSVDisabled}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Content title="">
          {lpReaction ? <ReactionCountList lpReaction={lpReaction} /> : <></>}
        </Content>
      </Box>
      <Box mt={7} mb={7}>
        <Content title="承認申請履歴">
          <Box>
            {lpWorkflows ? (
              <ApplyHistoryList pagination exampleWorkflows={lpWorkflows} />
            ) : (
              <></>
            )}
          </Box>
        </Content>
      </Box>
      {showInquiriesHistory && (
        <Content title="お問い合わせ履歴" sideItem={contactButton}>
          <Box>
            <Box ml="auto" textAlign="right" marginRight="9px">
              <Typography variant="overline" className={classes.caption}>
                未対応：<span className={classes.count}>{newInquiryCount}</span>
                {`件／${lpInquiries ? lpInquiries?.totalCount : 0}件`}
              </Typography>
            </Box>
            <ContactHistoryTable
              clickInquiry={(id) => navigate.navigate(`/adm/inquiries/${id}`)}
              lpTitle={lpDetail.title}
              lpCreatUser={lpDetail.createdUser.userName}
              lpInquiries={lpInquiries || undefined}
              handleChangeSortParam={(keyValue: string, orderValue: string) =>
                updateSortParam(keyValue, orderValue)
              }
              pagination
            />
          </Box>
        </Content>
      )}
      <Box mt={7} mb={7}>
        <Content title="メール送信履歴">
          <Box>
            {mailHistory ? (
              <MailHistoryList
                pagination
                mailHistory={mailHistory}
                handleMailHistoryPageChange={handleMailHistoryPageChange}
              />
            ) : (
              <></>
            )}
          </Box>
        </Content>
      </Box>
      <TextEditDialog
        open={modalVisible.noteDialog}
        title="備考・メモ"
        value={lpDetail.note}
        handleCancel={() => closeDialog()}
        handleSubmit={(text: string) => {
          if (text) handleNoteUpdate(text);
          closeDialog();
        }}
      />
      <NoticeDialog
        title="ランディングページを差し戻しました"
        open={modalVisible.noticeDialog}
        handleClose={() => closeDialog()}
      />
      <DeclineDialog
        open={modalVisible.declineDialog}
        handleCancel={() => closeDialog()}
        handleSubmit={(message) => {
          setIsSubmitting((prev) => !prev);
          handleWorkflowStatusUpdate(
            step,
            CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
            message,
          );
          setTimeout(() => {
            setIsSubmitting((prev) => !prev);
          }, 1000);
          closeDialog();
        }}
      />
      <ApplyDialog
        title="ランディングページを承認しますか？"
        contentTitle={lpDetail.title}
        open={modalVisible.applyDialog}
        handleCancel={() => closeDialog()}
        handleApply={() => {
          setIsSubmitting((prev) => !prev);
          if (isWorkflowStatusUpdate) return;
          setIsWorkflowStatusUpdate(true);
          const invalidMaterial = hasInvalidMaterial();
          if (invalidMaterial) {
            setInvalidMaterialDialogTitle(
              `公開終了・利用が停止されている${invalidMaterial}がランディングページに含まれています。差し戻しをしてください。`,
            );
            setModalVisible({
              ...modalVisible,
              invalidMaterialDialog: true,
            });
            setIsWorkflowStatusUpdate(false);
          } else {
            handleWorkflowStatusUpdate(
              step,
              CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
            );

            setTimeout(() => {
              setIsSubmitting((prev) => !prev);
            }, 1000);
            closeDialog();
          }
        }}
        previewData={lpView || undefined}
        templateId={lpDetail.designTemplateId}
      />
      {lpWorkflows && lpWorkflows.length > 0 && (
        <AppliedDialog
          workflow={lpWorkflows[0]}
          open={modalVisible.appliedDialog}
          handleClose={handleAppliedDialogClose}
        />
      )}
      <LpOpenDialog
        open={modalVisible.openDialog}
        date={
          lpDetail.openPeriod.openPeriodDate
            ? lpDetail.openPeriod.openPeriodDate
            : undefined
        }
        time={
          lpDetail.openPeriod.openPeriodTime
            ? formateTimeHHMMColon(lpDetail.openPeriod.openPeriodTime)
            : undefined
        }
        isRelease={lpDetail.lpStatus > CODE_LP_STATUS_APPROVAL}
        handleCancel={() => {
          closeDialog();
        }}
        handleSubmit={(date?: string, time?: string) => {
          const tmpDate = date?.replaceAll('/', '');
          const tmpTime = time?.replace(':', '');
          if (checkPastDay(tmpDate, tmpTime)) {
            setTmpOpenPeriod({ date: tmpDate, time: tmpTime } as TmpOpenPeriod);
            setModalVisible({
              ...modalVisible,
              pastDateDialog: true,
            });
          } else {
            handleOpen(date, time);
            closeDialog();
          }
        }}
      />
      <SuccessDialog
        open={modalVisible.successDialog}
        title="ランディングページの公開期間を変更しました"
        handleClose={() => closeDialog()}
      />
      <OpendDialog
        open={modalVisible.opendDialog}
        url={lpDetail.url}
        handleMailOpen={() => handleMailOpen()}
        handleClose={() => closeDialog()}
      />
      <ConfirmDialog
        buttonText="破棄する"
        open={modalVisible.confirmDialog}
        title=""
        text="編集内容を破棄して、承認済み時点の内容に戻しますが"
        handleCancel={() => closeDialog()}
        handleSubmit={() => handleDeleteLp()}
      />

      <ConfirmDialog
        buttonText="ゴミ箱に移動する"
        open={modalVisible.moveToTashDialog}
        title=""
        text="ゴミ箱に移動します。"
        handleCancel={() => closeDialog()}
        handleSubmit={() => handleDeleteLp()}
      />
      <ConfirmDialog
        buttonText="公開終了する"
        open={modalVisible.pastDateDialog}
        title="公開終了"
        showIcon
        text="公開終了になります。"
        handleCancel={() =>
          setModalVisible({ ...modalVisible, pastDateDialog: false })
        }
        handleSubmit={() => {
          handleOpen(tmpOpenPeriod?.date, tmpOpenPeriod?.time);
          setTmpOpenPeriod(undefined);
          closeDialog();
        }}
      />
      <ConfirmDialog
        buttonText="公開を一時停止する"
        open={modalVisible.lpStopDialog}
        title="公開を一時停止する"
        showIcon
        text="公開を一時停止します。"
        handleCancel={() =>
          setModalVisible({ ...modalVisible, lpStopDialog: false })
        }
        handleSubmit={() => {
          // ボタン連打時のエラー防止
          if(modalVisible?.lpStopDialog)
          handleStop();
          closeDialog();
        }}
      />
      <NoticeDialog
        title={invalidMaterialDialogTitle}
        open={modalVisible.invalidMaterialDialog}
        handleClose={() => closeDialog()}
      />
      <NoticeDialog
        title="メールの配信予約をしました。"
        open={modalVisible.mailReservedDialog}
        handleClose={closeDialog}
      />
      {modalVisible.mailDialog && (
        <LPMailDialog
          open={modalVisible.mailDialog}
          date={
            lpDetail.openPeriod.openPeriodDate
              ? lpDetail.openPeriod.openPeriodDate
              : undefined
          }
          time={
            lpDetail.openPeriod.openPeriodTime
              ? formateTimeHHMMColon(lpDetail.openPeriod.openPeriodTime)
              : undefined
          }
          isRelease={lpDetail.lpStatus > CODE_LP_STATUS_APPROVAL}
          handleCancel={() => {
            setModalVisible({ ...modalVisible, mailDialog: false });
          }}
          customersProps={customers}
          handleSubmit={handleMailDialogSubmit}
        />
      )}
    </Box>
  );
};
export default LPDetail;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, createMuiTheme, ThemeProvider, Theme } from '@material-ui/core/styles'
import { Box, TextField, SearchIconButton } from 'components/atoms'
import { COLORS } from 'constants/index'
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography'
import { useDispatch } from 'react-redux'

/**
 * Interface
 */

export interface LpAdminSearchTextFieldProps {
  label?: string
  value?: string
  maxLength?: number
  handleChangeText?: (text: string) => void
}

/**
 * Style
 */
export const theme = createMuiTheme({
  overrides: {
    // For Container
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: COLORS.SALAD_BAR_GREEN_900,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: COLORS.SALAD_BAR_GREEN_900,
          borderWidth: 2,
          borderStyle: 'solid'
        },
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_900,
        }
      }
    },
  },
})


const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      margin: 0,
      '&:hover': {
        backgroundColor: theme.palette.green[700]
      }
    },
    input: {
      height: 20,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      padding: `${theme.spacing(1)}px ${theme.spacing(5)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:focus': {
        backgroundColor: theme.palette.green[700]
      },
    },
    icon: {
      top: 4,
      right: theme.spacing(1),
      position: 'absolute',
    },
  }),
)

/**
 * Presenter
 */

export default function LpAdminSearchTextField(props: LpAdminSearchTextFieldProps) {
  const { value, label, handleChangeText, maxLength } = props
  const classes = useStyles({})
  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <TextField
          onChange={(event) => setText(event.target.value)}
          value={text}
          color="primary"
          inputProps={{ className: (classes.input), maxLength}}
          placeholder={label} variant="outlined" fullWidth
          onKeyPress={e => {
            if (e.key === 'Enter' && handleChangeText) handleChangeText(text || '')
          }}
        />
        <div className={classes.icon} >
          <SearchIconButton
            handleClick={()=>{
              if(!handleChangeText) return
              handleChangeText(text || '')
            }} />
        </div>
      </Box>
    </ThemeProvider>
  )
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */

/**
 * タグから重複、,,、半角・全角スペースを削除
 */
export const removeIncorrectTagEntry = (tag: string) => {
  const tagArray = tag.split(',');
  const noSpaceArray = tagArray.reduce(
    (acc: string[], curr: string): string[] => {
      // eslint-disable-next-line no-irregular-whitespace
      const target = curr.trim();
      acc.push(target);

      return acc;
    },
    [],
  );

  const filteredTagArray = noSpaceArray.filter((item) => !!item);

  // ⬇︎以下動作確認中、上手く動かなかったので修正
  // const noSpaceArray = tagArray.filter(t => {
  //   // eslint-disable-next-line no-irregular-whitespace
  //   const target = t.replaceAll(' ', '').replaceAll('', '')

  //   return target!==''
  // })
  const set = new Set<string>(filteredTagArray);

  return Array.from(set).join(',');
};

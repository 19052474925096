import React from 'react';
import { Box, Typography } from 'components/atoms';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { useDispatch } from 'react-redux';
import { setLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';

interface DashboardApprovalStatusItemProps {
  approveStatus: string;
  approveStatusCount: number;
  color: 'red' | 'green';
  title: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#fff',
      borderRadius: 6,
      padding: 6,
      marginLeft: 8,
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 16,
      },
    },
  }),
);

const DashboardApprovalStatusItem = (
  props: DashboardApprovalStatusItemProps,
) => {
  const { approveStatus, approveStatusCount, color, title } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { navigate } = hooks.useNavigate();
  const countColor =
    (approveStatusCount === 0 && '#B0B0AB') ||
    (color === 'red' && '#EF6060') ||
    (color === 'green' && '#20C168');

  const handleNavigate = () => {
    const isSubordinatesStatus = title === '承認待ち';
    const searchConditionBody = {
      page: 1,
      row: 10,
      status: isSubordinatesStatus ? undefined : approveStatus,
      approval_task: isSubordinatesStatus || undefined,
    };
    const link = isSubordinatesStatus
      ? '/adm/lps/subordinates'
      : '/adm/lps/own';

    dispatch(setLpOwnSearchCondition(searchConditionBody));
    navigate(link);
  };

  return (
    <Box className={classes.root} display="flex" onClick={handleNavigate}>
      <Typography variant="body2">{title}</Typography>
      <Typography style={{ color: countColor || '' }} variant="body2">
        ({approveStatusCount.toLocaleString()})
      </Typography>
    </Box>
  );
};

export default DashboardApprovalStatusItem;

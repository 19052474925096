/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, createStyles, Theme , withStyles } from '@material-ui/core/styles'

import { MenuProps } from '@material-ui/core/Menu'
import { Button, Menu, MenuItem, ListItemText, Avatar, Tooltip, Typography} from 'components/atoms'
import clsx from 'clsx'
import { hooks } from 'libs'
import { Modules } from 'core'
import { ConfirmDialog, NoticeDialog } from 'components/organisms'
import { DropDownIcon , UserIcon } from '../../../assets/images'



/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'normal',
      fontSize:'12px',
      boxShadow: 'none',
      fill: "#82827e",
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
      '&:selected': {
        color: theme.palette.primary.main,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    icon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: -1,
    },
    profilePic: {
      height: '24px',
      width: '24px'
    },
    active: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    button: {
      color: theme.palette.text.primary,
      '& .MuiTypography-root': {
        fontSize: 13,
        textAlign: 'center',
      },
    },
    accountButtonArea: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '149px',
      color: 'red'
    },
    logout: {
      '&:hover': {
        backgroundColor: '#FFF2F0 !important',
      },
    },
    displayNameBox: {
      display: 'flex',
      marginTop: 1,
    },
    displayName: {
      maxWidth: 250,
      fontSize: 12,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
)

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    top: '64px !important',
    borderRadius: '0px',
    width: '149px',
    right: '0px',
    left: 'auto !important',
    padding: '0px 8px',
  },

})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: '35px',
    fontWeight: 'normal',
    font: 'bold',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(37, 36, 30, 0.08)',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:selected': {
      color: theme.palette.primary.main,
    },
  },
}))(MenuItem)

export default function HeaderUserSelect() {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false)
  const [openNoticeDialog, setOpenNoticeDialog] = React.useState<boolean>(false)
  const { logout, changePassword, idTokenPayload } = hooks.useAuth()
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)
  const navigate  = hooks.useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpen = () => {
    setAnchorEl(null)
    navigate.navigate(`/adm/accounts`)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePassword = () => {
    setAnchorEl(null)
    setOpenConfirmDialog(true)
  }

  const executeChangePassword = () => {
    setOpenConfirmDialog(false)
    changePassword(() => {
      setOpenNoticeDialog(true)
    })
  }

  const isPasswordChangable = useMemo(() => {
    if (!idTokenPayload || !idTokenPayload.sub) return false

    return idTokenPayload.sub.toString().startsWith('auth0')
  }, [idTokenPayload])

  return (
    <div>
      <Button
        className={classes.root}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color= "inherit"
        onClick={handleClick}
      >
        <div className={clsx(classes.icon, {[classes.active]: anchorEl})}>
        <div className={classes.icon}>{userInfo?.profileImage ? <Avatar className={classes.profilePic}  src={userInfo.profileImage.url} /> : <UserIcon />}</div>
        <div className={classes.displayNameBox}>
          <Tooltip title={userInfo && userInfo.displayName || ''} arrow placement="top">
            <Typography className={classes.displayName}>{userInfo && userInfo.displayName}</Typography>
          </Tooltip>
          <DropDownIcon style={{marginTop: "7px", marginBottom: "3px", marginLeft: "5px"}}/>
        </div>
        </div>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        <StyledMenuItem className={classes.root}>
          <ListItemText className={classes.button} primary="アカウント設定画面" onClick={handleOpen}/>
        </StyledMenuItem>
        {isPasswordChangable && <StyledMenuItem className={classes.root} onClick={() => handleChangePassword()}>
          <ListItemText className={classes.button} primary="パスワード変更" />
        </StyledMenuItem>}
        <StyledMenuItem className={classes.logout} onClick={() => logout()}>
          <ListItemText className={classes.button} style={{color : 'red'}} primary="ログアウト" />
        </StyledMenuItem>
      </StyledMenu>
      <ConfirmDialog
        buttonText="変更する"
        open={openConfirmDialog}
        title=""
        text="パスワードを変更します。"
        handleCancel={()=> setOpenConfirmDialog(false)}
        handleSubmit={() => executeChangePassword()}
        />
      <NoticeDialog
        open={openNoticeDialog}
        handleClose={() => setOpenNoticeDialog(false)}
        title="ご登録済みのメールアドレスにパスワード変更の案内をお送りしました。"
      />
    </div>
  )
}
/* eslint-enable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { CheckIcon, EditIcon, ErrorIcon } from 'assets/images';
import { NavLink, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import paletteOption from 'styles/palette';

/**
 * Interface
 */
export interface NormalEditSideBarItemProps {
  text: React.ReactNode;
  to: string;
  hasError: boolean | undefined;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 214,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: 'auto',
      lineHeight: '18px',
      textAlign: 'left',
    },
    button: {
      borderWidth: 1,
      borderColor: theme.palette.gray[800],
      borderStyle: 'solid',
      backgroundColor: theme.palette.green[800],
      '&:hover': {
        backgroundColor: theme.palette.green[900],
      },
      height: 20,
      width: 20,
      padding: 0,
      borderRadius: 20,
      marginLeft: 'auto',
      marginRight: 4,
    },
    icon: {
      height: 11,
      width: 11,
      margin: 4,
    },
    validationIcon: {
      marginRight: theme.spacing(1),
      width: 14,
      height: 14,
    },
    errorIcon: {
      '& path': {
        fill: '#DB3218',
      },
    },
    checkIcon: {
      '& path': {
        fill: theme.palette.gray[600],
      },
    },
    link: {
      textDecoration: 'none',
      verticalAlign: 'text-top',
      background: theme.palette.gray[400],
      borderRadius: 4,
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        boxShadow: 'none',
        '& span': {
          color: theme.palette.green[900],
        },
      },
      width: 192,
      boxShadow: '2px 2px 2px rgba(0,0,0,0.1)',
    },
  }),
);

const current: CSSProperties = {
  backgroundColor: paletteOption.green[700],
  color: paletteOption.green[900],
  boxShadow: 'none',
};

/**
 * Presenter
 */

const NormalEditSideBarItem = (props: NormalEditSideBarItemProps) => {
  const classes = useStyles({});
  const { text, to, hasError } = props;
  const { url } = useRouteMatch();

  // FIXME:クリック後文字色が紫になる
  return (
    <Box
      ml={2}
      className={classes.root}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      {hasError ? (
        <ErrorIcon
          className={clsx(classes.validationIcon, classes.errorIcon)}
        />
      ) : (
        <CheckIcon
          className={clsx(classes.validationIcon, classes.checkIcon)}
        />
      )}
      <NavLink
        activeStyle={current}
        className={classes.link}
        exact
        to={`${url}${to}`}
      >
        <Box
          mb="8px"
          ml={2}
          mt="8px"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <Typography
            component="span"
            className={classes.title}
            variant="body2"
          >
            {text}
          </Typography>
          <Box className={classes.button}>
            <EditIcon className={classes.icon} />
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};
export default NormalEditSideBarItem;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  PrimaryButton,
  Typography,
  Tooltip,
} from 'components/atoms';
import { Modules } from 'core';

import { TYPOGRAPHY } from 'constants/index';
import { useSelector } from 'react-redux';
import { ManageUsersSearchCondition } from 'core/domain/user';

import { SortDownIcon, GreenCircle, GrayCircle } from 'assets/images';

import { hooks } from 'libs';

/**
 * Interface
 */
export interface ManageUsersTableProps {
  // manageUsers: Users | null;
  clickManageUsers?: (id: number, approved: boolean) => void;
  handleChangeSortKey: (sortKey: string) => void;
  // handleClickFavorite: (manageUsersId: number, checked: boolean) => void;
  manageUsersSearchCondition: ManageUsersSearchCondition | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 150,
      },
    },
    grayFont: {
      color: '#aaaaaa',
    },
    normalFont: {
      color: '#000000',
    },
    centerLabel: {
      '& > span': {
        justifyContent: 'center',
      },
    }
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
  minWidth?: number;
  isCenter?: boolean;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'ユーザーID',
    sortKey: 'userId',
    width: '6%',
    minWidth: 102,
  },
  {
    title: '氏名',
    sortKey: 'userName',
    width: '19%',
  },
  {
    title: 'メールアドレス',
    sortKey: 'mailAddress',
    width: '24%',
  },
  {
    title: '権限',
    sortKey: '',
    width: '17%',
    isCenter: true,
  },
  {
    title: 'ランディングページ\n作成数',
    sortKey: 'lpCreatedCount',
    width: '11%',
  },
  {
    title: '商材\n作成数',
    sortKey: 'productCreatedCount',
    width: '7%',
  },
  {
    title: '事例\n作成数',
    sortKey: 'exampleCreatedCount',
    width: '7%',
  },

  {
    title: '',
    sortKey: '',
    width: '9%',
  },
];

const ManageUsersTable = (props: ManageUsersTableProps) => {
  const classes = useStyles({});

  const manageUsers = useSelector(
    (state: Modules.AppState) => state.manageUsers,
  );

  const {
    // manageUsers,
    clickManageUsers,
    handleChangeSortKey,
    // handleClickFavorite,
    manageUsersSearchCondition,
  } = props;
  const { navigate } = hooks.useNavigate();

  const circle = {
    width: 10,
    height: 10,
  };

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <colgroup>
          {TABLE_HEADER_ITEMS.map((item, index ) => (
            <col key={index} width={item.width} style={item.minWidth ? {minWidth: item.minWidth} : undefined} />
          ))}
        </colgroup>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item: TableHeaderItems, index) => (
              <TableCell key={index} className={item.isCenter ? classes.centerLabel : undefined}>
                {item.sortKey !== '' ? (
                  <TableSortLabel
                    active
                    direction={manageUsersSearchCondition?.sort_order}
                    IconComponent={() =>
                      item.sortKey === manageUsersSearchCondition?.sort_key &&
                      manageUsersSearchCondition?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleChangeSortKey(item.sortKey)}
                  >
                    {item.title}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} hideSortIcon>
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {manageUsers &&
            manageUsers.users.map((manageUser, index) => (
              <TableRow key={index}>
                <TableCell>
                  {/* ユーザID */}
                  <Tooltip title={manageUser.userId}>
                    <Typography
                      className={
                        manageUser.available
                          ? classes.normalFont
                          : classes.grayFont
                      }
                    >
                      {manageUser.userId}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.overflow}>
                  {/* 氏名 */}
                  <Tooltip title={manageUser.userName || ''}>
                    <Typography
                      className={
                        manageUser.available
                          ? classes.normalFont
                          : classes.grayFont
                      }
                    >
                      {manageUser.userName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.overflow}>
                  {/* メールアドレス */}
                  <Tooltip title={manageUser.mailAddress || ''}>
                    <Typography
                      className={
                        manageUser.available
                          ? classes.normalFont
                          : classes.grayFont
                      }
                    >
                      {manageUser.mailAddress}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  {/* 権限：LP */}
                  <Box display="flex" flexDirection="row" width="100%" textAlign="center" justifyContent="center">
                    <Box pl={1} pr={1}>
                      <Box fontWeight="fontWeightBold">LP</Box>
                      <Box
                        style={manageUser.lpAuthor ? {} : { color: '#aaaaaa' }}
                      >
                        <img
                          src={manageUser.lpAuthor ? GreenCircle : GrayCircle}
                          width={circle.width}
                          height={circle.height}
                        />
                        作成
                      </Box>
                      <Box
                        style={
                          manageUser.lpApprover ? {} : { color: '#aaaaaa' }
                        }
                      >
                        <img
                          src={manageUser.lpApprover ? GreenCircle : GrayCircle}
                          width={circle.width}
                          height={circle.height}
                        />
                        承認
                      </Box>
                    </Box>
                    {/* 権限：商材事例 */}
                    <Box pl={1} pr={1}>
                      <Box fontWeight="fontWeightBold">商材 事例</Box>
                      <Box
                        style={
                          manageUser.materialAuthor ? {} : { color: '#aaaaaa' }
                        }
                      >
                        <img
                          src={
                            manageUser.materialAuthor ? GreenCircle : GrayCircle
                          }
                          width={circle.width}
                          height={circle.height}
                        />
                        作成
                      </Box>
                      <Box
                        style={
                          manageUser.materialApprover
                            ? {}
                            : { color: '#aaaaaa' }
                        }
                      >
                        <img
                          src={
                            manageUser.materialApprover
                              ? GreenCircle
                              : GrayCircle
                          }
                          width={circle.width}
                          height={circle.height}
                        />
                        承認
                      </Box>
                    </Box>
                    {/* 権限：管理者 */}
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="stretch"
                      pl={1} pr={1}
                    >
                      <Box
                        display="flex"
                        fontWeight="fontWeightBold"
                        alignItems="center"
                      >
                        管理者
                      </Box>

                      <Box
                        paddingTop={1.5}
                        paddingBottom={1.5}
                        display="flex"
                        alignItems="center"
                        style={
                          manageUser.tenantAdmin ? {} : { color: '#aaaaaa' }
                        }
                      >
                        <img
                          src={
                            manageUser.tenantAdmin ? GreenCircle : GrayCircle
                          }
                          width={circle.width}
                          height={circle.height}
                        />
                        管理
                      </Box>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell align="right">
                  {/* LP作成数 */}
                  <Tooltip title={manageUser.lpCreatedCount.toLocaleString()}>
                    <span style={{display: "flow-root"}}>
                      {manageUser.lpCreatedCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell align="right">
                  {/* 商材作成数 */}
                  <Tooltip title={manageUser.productCreatedCount.toLocaleString()}>
                    <span style={{display: "flow-root"}}>
                      {manageUser.productCreatedCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell align="right">
                  {/* 事例作成数 */}
                  <Tooltip title={manageUser.exampleCreatedCount.toLocaleString()}>
                    <span style={{display: "flow-root"}}>
                      {manageUser.exampleCreatedCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  {/* 編集ボタン */}
                  <PrimaryButton
                    text="編集"
                    click={() => {
                      if (clickManageUsers)
                        navigate(`/adm/users/${manageUser?.userId}/edit`);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManageUsersTable;

import React, { useEffect } from 'react';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TrashesPage from 'components/pages/Trashes';
import { TrashesSortKey } from 'core/domain/trashes';
import { setTrashesSearchCondition } from 'core/modules/trashesSearchCondition';
import { setTrashesSearchDataType } from 'core/modules/trashesSearchDataType';

const pageRows = 10;

/**
 * Presenter
 */
const TrashesPageContainer = () => {
  const dispatch = useDispatch();

  const trashes = useSelector(
    (state: Modules.AppState) => state.trashes,
    shallowEqual,
  );

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  // 検索条件
  const trashesSearchCondition = useSelector(
    (state: Modules.AppState) => state.trashesSearchCondition,
    shallowEqual,
  );

  // 検索の絞り込みチェックボックス
  const trashesSearchDataType = useSelector(
    (state: Modules.AppState) => state.trashesSearchDataType,
    shallowEqual,
  )

  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  const token = hooks.useAuth().accessToken;

  // 権限によってデータ種類設定
  const createSearchFilters = () => {
    if (!userInfo) return;
    if (
      userInfo.tenantAdmin ||
      (userInfo.materialAuthor && userInfo.lpAuthor)
    ) {
      dispatch(setTrashesSearchDataType(
        [
          {
            label: 'ランディングページ',
            dataType: 3,
          },
          {
            label: '商材',
            dataType: 1,
          },
          {
            label: '導入事例',
            dataType: 2,
          },
        ]
      ))
    } else if (userInfo.materialAuthor) {
      dispatch(setTrashesSearchDataType(
        [
          {
            label: '商材',
            dataType: 1,
          },
          {
            label: '導入事例',
            dataType: 2,
          },
        ]
      ))
    } else if (userInfo.lpAuthor) {
      dispatch(setTrashesSearchDataType(
        [
          {
            label: 'ランディングページ',
            dataType: 3,
          }
        ]
      ))
    }
  };

  // 一覧取得リクエストパラメーターpage、row設定
  useEffect(() => {
    if (!userInfo) return;
    if (!trashesSearchCondition) {

      dispatch(
        setTrashesSearchCondition({
          page: 1,
          row: pageRows,
          sort_key: 'trashDate',
          sort_order: 'desc',
        }),
      );
    }

    if (
      trashes &&
      trashes.trashes &&
      trashes.trashes.length === 0 &&
      trashes.totalCount > 0
    ) {
      const latestPage = Math.ceil(trashes.totalCount / pageRows);
      dispatch(
        setTrashesSearchCondition({
          ...trashesSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }

    // データ種類
    createSearchFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trashes, trashesSearchCondition, userInfo]);

  // ページネーション押下時処理
  const handleChangePage = (page: number) => {
    dispatch(
      setTrashesSearchCondition({
        ...trashesSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  // ソート変更処理
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (trashesSearchCondition?.sort_key === sortKey) {
      order = trashesSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setTrashesSearchCondition({
        ...trashesSearchCondition,
        sort_key: sortKey as TrashesSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  // フリーワード検索押下時、パラメーターにセット
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setTrashesSearchCondition({
        ...trashesSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  const init = () => {
    if (!userInfo) return;
    if (!trashesSearchCondition) return;

    dispatch(
      Usecases.trashes.sendGetTrashes(
        token,
        userInfo.tenantId,
        trashesSearchCondition,
      )
    );
  };

  // 初期化
  useEffect(() => {
    if (!userInfo) return;
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trashesSearchCondition, userInfo]);

  // 元に戻す、削除後に一覧を更新
  const handleLoadCondition = () => {
    dispatch(
      setTrashesSearchCondition({
        ...trashesSearchCondition,
      }),
    );
  };

  // チェックボックスチェック時リクエストパラメーターに設定
  const handleCheckStatus = (dataType: number) => {
    if (!trashesSearchCondition || !trashesSearchCondition.data_type) {
      dispatch(
        setTrashesSearchCondition({
          ...trashesSearchCondition,
          data_type: `${dataType}`,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );
      
      return;
    }

    const dataTypeArray = trashesSearchCondition.data_type.split(',');
    const index = dataTypeArray.indexOf(`${dataType}`);
    if (index < 0) {
      dataTypeArray.push(`${dataType}`);
    } else {
      dataTypeArray.splice(index, 1);
    }

    dispatch(
      setTrashesSearchCondition({
        ...trashesSearchCondition,
        data_type: dataTypeArray.join(','),
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  return (
    <TrashesPage
      trashes={trashes}
      rows={pageRows}
      trashesSearchCondition={trashesSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleCheckStatus={handleCheckStatus}
      handleChangeFreeWord={handleChangeFreeWord}
      handleLoadCondition={handleLoadCondition}
      searchFilters={trashesSearchDataType}
    />
  );
};
export default TrashesPageContainer;

import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  ButtonLink,
  Divider,
  SecondaryButton,
  TertiaryButton,
  Typography,
} from 'components/atoms';
import { TYPOGRAPHY, TEXT } from 'constants/index';
import { PreviewButton } from 'components/molecules';
import {
  ConfirmDialog,
  ErrorDialog,
  NormalEditSideBarItem,
  NormalEditSideBarProduct,
} from 'components/organisms';
import { Modules } from 'core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { LpRelationProduct, LPRegisterRequest } from 'core/domain/lp';
import { setNewLp } from 'core/modules/newLp';
import { hooks } from 'libs';
import { setLpProductDetails } from 'core/modules/lpProductDetails';
import { checkPastDay, validDate } from 'libs/date';
import { removeIncorrectTagEntry } from 'libs/validation';
import { CODE_MATERIAL_STATUS_SUSPENSION } from 'constants/code';
import clsx from 'clsx';

/**
 * Interface
 */
export interface NormalEditSideBarProps {
  handleSaveDraft: (tag: string | undefined) => void;
  setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% - 64px)',
      backgroundColor: theme.palette.gray[400],
    },
    lpBox: {
      height: 'calc(100% - 237px)',
      backgroundColor: theme.palette.text.secondary,
      borderRadius: '0 8px 8px 0',
      borderColor: theme.palette.gray[600],
      borderWidth: 1,
      borderStyle: 'solid',
    },
    titleBox: {
      backgroundColor: theme.palette.gray[500],
      textAlign: 'center',
    },
    title: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
      marginBottom: theme.spacing(1),
    },
    copyright: {
      fontSize: 11,
      color: theme.palette.gray[800],
      marginTop: 8,
      marginBottom: 5,
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
    },
    productBox: {
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.gray[700],
      borderBottomWidth: 1,
      color: theme.palette.gray[800],
      marginLeft: 37.5,
      marginRight: 47.5,
    },
    productTitle: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: 1.5,
    },
    scroll: {
      height: 'calc(100% - 52px - 28px - 16px)',
      overflowY: 'auto',
    },
    controller: {
      height: 213,
    },
  }),
);

type Order = 'up' | 'down';

const NormalEditSideBar = (props: NormalEditSideBarProps) => {
  const { handleSaveDraft, setIsDraft } = props;
  const classes = useStyles({});
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteProductConfirmDialog, setOpenDeleteProductConfirmDialog] =
    useState(false);
  const [initialNewLp, setInitialNewLp] = useState<
    LPRegisterRequest | undefined
  >(undefined);
  const [openApplyConfirmDialog, setOpenAppyConfirmDialog] = useState(false);
  const [deleteProductIndex, setDeleteProductIndex] = useState(-1);
  const lpSettingText = (
    <Typography style={{ fontSize: 13, fontWeight: 'bold' }}>
      ランディングページ
      <br />
      基本設定
    </Typography>
  );
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );
  const newLp = useSelector(
    (state: Modules.AppState) => state.newLp,
    shallowEqual,
  );
  const lpView = useSelector(
    (state: Modules.AppState) => state.lpView,
    shallowEqual,
  );
  const lpProductDetails = useSelector(
    (state: Modules.AppState) => state.lpProductDetails,
    shallowEqual,
  );
  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { url } = useRouteMatch();
  const { hasErrorsDraft, hasInvalidMaterials } =
    hooks.useNormalEditValidation();
  const { lpId } = useParams<{ lpId: string }>();
  const isApproved = useHistory().location.pathname.includes('approved');

  useEffect(() => {
    if (!newLp || initialNewLp) return;
    setInitialNewLp({ ...newLp });
  }, [newLp, initialNewLp]);

  const reorderList = (index: number, order: Order) => {
    if (!newLp || !newLp.products) return;
    if (index === -1) return;
    const newList = [...newLp.products];
    let targetIndex = 0;
    if (order === 'up') {
      targetIndex = index - 1;
      newList.splice(targetIndex, 2, newList[index], newList[targetIndex]);
    } else {
      targetIndex = index + 1;
      newList.splice(index, 2, newList[targetIndex], newList[index]);
    }

    dispatch(
      setNewLp({
        ...newLp,
        products: newList,
      }),
    );
  };

  const goSelectProduct = () => {
    navigate.navigate(`${url}/select-product`);
  };

  const handleProductRemove = () => {
    if (deleteProductIndex < 0) {
      setOpenDeleteProductConfirmDialog(false);

      return;
    }

    const newProducts: LpRelationProduct[] = newLp?.products
      ? [...newLp.products]
      : [];
    const targetProductId = newProducts[deleteProductIndex];
    newProducts.splice(deleteProductIndex, 1);
    dispatch(
      setNewLp({
        ...newLp,
        products: [...newProducts],
      }),
    );

    if (lpProductDetails) {
      const newLpProductDetails = [...lpProductDetails].filter(
        (product) => product.productId !== targetProductId.productId,
      );
      dispatch(setLpProductDetails(newLpProductDetails));
    }

    setOpenDeleteProductConfirmDialog(false);
    setDeleteProductIndex(-1);

    const newProduct = newProducts[deleteProductIndex - 1];
    if (newProduct) {
      navigate.navigate(`${url}/product/${newProduct.productId}/edit`);
    } else {
      navigate.navigate(`${url}/greeting`);
    }
  };

  const checkProductComment = () => {
    if (newLp && newLp.products) {
      const noComment = newLp.products.find((product) => !product.comment);
      if (noComment) {
        setOpenAppyConfirmDialog(true);
      } else {
        navigate.navigate(`${url}/apply-request`);
      }
    }
  };

  const hasInvalidExample = (index: number) => {
    if (!newLp || !newLp.products) return false;
    const invalidExamples = lpExampleDetails.filter(
      (e) => e.materialPeriod.materialStatus >= CODE_MATERIAL_STATUS_SUSPENSION,
    );
    const product = newLp.products[index];
    if (!product.exampleIds || !invalidExamples) return false;
    let res = false;
    product.exampleIds.forEach((id) =>
      invalidExamples.forEach((e) => {
        if (e.exampleMaterialId === id) {
          res = true;

          return res;
        }

        return undefined;
      }),
    );

    return res;
  };

  const mainVisual = useMemo(() => {
    let hasError: boolean | undefined;

    // 既存LPを開いた際に読み込むまでエラーチェックをundefinedにする
    if (lpId && (!lpDetail || newLp?.header === undefined))
      hasError = undefined;
    else if (
      !newLp ||
      !newLp.header ||
      (newLp.tokuisakiDisplayed && !newLp.tokuisakiTitle) ||
      !newLp.lpMainImageData?.lpMainImageId ||
      !newLp.lpMainImageData?.url ||
      !!(newLp.header && newLp.header.length > 100)
    )
      hasError = true;
    else hasError = false;

    return (
      <NormalEditSideBarItem
        hasError={hasError}
        to=""
        text="メインビジュアル"
      />
    );
  }, [newLp, lpDetail, lpId]);

  const greeting = useMemo(() => {
    let hasError: boolean | undefined;

    // 既存LPを開いた際に読み込むまでエラーチェックをundefinedにする
    if (lpId && (!lpDetail || newLp?.greetText === undefined))
      hasError = undefined;
    else if (
      !newLp ||
      !newLp.greetText ||
      !newLp.beginningText ||
      (newLp.profileDisplayed && !newLp.tantoCompany) ||
      !!(newLp.greetText && newLp.greetText.length > 1000) ||
      !!(newLp.beginningText && newLp.beginningText.length > 100)
    )
      hasError = true;
    else hasError = false;

    return (
      <NormalEditSideBarItem hasError={hasError} to="/greeting" text="挨拶" />
    );
  }, [newLp, lpDetail, lpId]);

  const checkTagsValidation = (tag: string): boolean => {
    const newTag = removeIncorrectTagEntry(tag);
    const newTags = newTag.split(',');
    if (newTag.length > 5000 || newTags.some((e) => e.length > 100))
      return true;

    return false;
  };

  const lpSetting = useMemo(() => {
    let hasError: boolean | undefined;

    if (lpId && !lpDetail) hasError = undefined;
    else if (
      !newLp ||
      !newLp.pageTitle ||
      !newLp.tokuisakiName ||
      !newLp.tantoName ||
      !newLp.title ||
      // 公開設定
      !newLp.mailSubject ||
      !newLp.mailText ||
      (newLp.passwordSetting !== 1 && !newLp.password) ||
      (newLp?.openPeriod &&
        newLp.openPeriod.openPeriodDate &&
        !validDate(newLp.openPeriod.openPeriodDate)) ||
      (newLp?.openPeriod &&
        checkPastDay(
          newLp.openPeriod.openPeriodDate,
          newLp.openPeriod.openPeriodTime,
        )) ||
      (newLp?.openPeriod &&
        newLp.openPeriod.openPeriodDate &&
        !newLp.openPeriod.openPeriodTime) ||
      (newLp?.openPeriod &&
        !newLp.openPeriod.openPeriodDate &&
        newLp.openPeriod.openPeriodTime) ||
      !!(newLp.lpTag && checkTagsValidation(newLp.lpTag)) ||
      !!(newLp.title && newLp.title.length > 100) ||
      !!(newLp.pageTitle && newLp.pageTitle.length > 100) ||
      !!(newLp.tokuisakiName && newLp.tokuisakiName.length > 100) ||
      !!(newLp.tantoName && newLp.tantoName.length > 100) ||
      // 公開設定
      !!(newLp.mailSubject && newLp.mailSubject.length > 500) ||
      !!(newLp.mailText && newLp.mailText.length > 5000) ||
      !!(
        newLp?.password &&
        (newLp.password.length > 100 || newLp.password.length < 8) &&
        newLp.passwordSetting !== 1
      ) ||
      !!(
        newLp?.password &&
        newLp.passwordSetting !== 1 &&
        /^[0-9a-zA-Z]+$/.test(newLp?.password) === false
      ) ||
      !!(newLp?.mailSignature && newLp.mailSignature.length > 5000)
    )
      hasError = true;
    else hasError = false;

    return (
      <NormalEditSideBarItem
        hasError={hasError}
        to="/lp-setting"
        text={lpSettingText}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLp, lpDetail, lpId]);

  const products = useMemo(() => {
    if (!newLp?.products) return undefined;

    return newLp.products.map((item, index) => {
      const productDetail = lpProductDetails.find(
        (product) => product.productId === item.productId,
      );
      if (!productDetail) return undefined;

      return (
        <NormalEditSideBarProduct
          hasError={
            checkPastDay(
              productDetail.productPeriod.openPeriodDate,
              productDetail.productPeriod.openPeriodTime,
            ) ||
            !!(
              ((!item.comment || item.comment === '') &&
                (item.productSalesImage?.lpProductImageData ||
                  item.productSalesImage?.lpProductImageId ||
                  item.productSalesImage?.url)) ||
              (item.comment &&
                item.comment !== '' &&
                !item.productSalesImage?.lpProductImageData &&
                !item.productSalesImage?.lpProductImageId &&
                !item.productSalesImage?.url)
            ) ||
            !!(item.comment && item.comment.length > 5000) ||
            productDetail.productPeriod.materialStatus >=
              CODE_MATERIAL_STATUS_SUSPENSION ||
            hasInvalidExample(index)
          }
          productId={item.productId}
          handleRemove={() => {
            setDeleteProductIndex(index);
            setOpenDeleteProductConfirmDialog(true);
          }}
          isTop={index === 0}
          isBottom={index + 1 === newLp?.products?.length}
          clickUp={() => reorderList(index, 'up')}
          clickDown={() => reorderList(index, 'down')}
          key={index}
          text={item.productName}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLp, lpProductDetails]);

  const navigateEnd = () => {
    if (lpId) {
      if (isApproved) navigate.navigate(`/adm/lps/${lpId}/approved`);
      else navigate.navigate(`/adm/lps/${lpId}`);
    } else {
      navigate.navigate('/adm/lps/own');
    }
  };

  const enableApplyRequest = useMemo(() => {
    if (!newLp || !newLp.products || newLp.products.length === 0) return false;

    return true;
  }, [newLp]);

  const formatTag = () => {
    if (!newLp || !newLp.lpTag) return undefined;
    const newTag = removeIncorrectTagEntry(newLp.lpTag);
    dispatch(
      setNewLp({
        ...newLp,
        lpTag: newTag,
      }),
    );

    return newTag;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.lpBox}>
        <Box className={classes.titleBox}>
          <Typography className={classes.title} variant="body2">
            ランディングページ構成
          </Typography>
        </Box>
        <Box className={classes.scroll}>
          {mainVisual}
          {greeting}
          <Box className={classes.productBox}>
            <Typography className={classes.productTitle} variant="body2">
              商材
            </Typography>
          </Box>
          {products}
        </Box>
        <Divider />
        <Box mx="auto" pt={1} pb={1} width={192} height={36}>
          <SecondaryButton click={goSelectProduct} text="＋商材を選ぶ" />
        </Box>
      </Box>
      <Box
        pb="5px"
        mt={1}
        pt={1}
        className={clsx(classes.lpBox, classes.controller)}
      >
        {lpSetting}
        <Box
          width={144}
          mb={1}
          mx="auto"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <Typography className={classes.previewCaption}>プレビュー</Typography>
          <PreviewButton
            previewData={lpView || undefined}
            templateId={newLp?.designTemplateId}
          />
        </Box>
        <Box mx="auto" display="flex" height={27} mr={7} ml={7} mb={1}>
          <Box width={112}>
            <SecondaryButton
              click={() => {
                const newTag = formatTag();
                const errors = hasErrorsDraft();
                if (errors) {
                  setOpenNoticeDialog(true);
                  const array: string[] = [];
                  // eslint-disable-next-line  no-restricted-syntax
                  for (const [key, value] of Object.entries(errors)) {
                    if (key === 'products' && value) {
                      array.push(
                        '商材の一言コメントは5000文字以内で入力してください。',
                      );
                    } else if (value) {
                      array.push(value);
                    }
                  }
                  setErrorMessage(array);

                  return;
                }
                handleSaveDraft(newTag);
                setIsDraft(true);
              }}
              text="下書き保存"
            />
          </Box>
          <Box width={50} ml={1} p={0}>
            <ButtonLink
              click={() => navigateEnd()}
              fontSize={TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75}
              text="終了"
            />
          </Box>
        </Box>
        <Box ml={2} mr={2} mb={1}>
          <TertiaryButton
            disabled={!enableApplyRequest}
            click={() => {
              const invalidMaterial = hasInvalidMaterials();
              if (!newLp) {
                setOpenNoticeDialog(true);

                return;
              }
              if (invalidMaterial) {
                setOpenNoticeDialog(true);
                setErrorMessage([
                  `公開終了・利用が停止されている${invalidMaterial}がランディングページに含まれています。削除してください。`,
                ]);

                return;
              }
              checkProductComment();
            }}
            text="保存して承認申請へ進む"
          />
        </Box>
        <Box textAlign="center">
          <Typography className={classes.copyright} variant="caption">
            {TEXT.COPYRIGHT}
          </Typography>
        </Box>
      </Box>
      <ConfirmDialog
        buttonText="終了する"
        open={openConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力画面は破棄されますが"
        handleCancel={() => setOpenConfirmDialog(false)}
        handleSubmit={() => {
          setOpenConfirmDialog(false);
          navigateEnd();
        }}
      />
      <ConfirmDialog
        open={openApplyConfirmDialog}
        buttonText="保存して承認申請へ進む"
        text="商材のコメントが未入力ですが"
        title=""
        handleCancel={() => setOpenAppyConfirmDialog(false)}
        handleSubmit={() => {
          setOpenAppyConfirmDialog(false);
          navigate.navigate(`${url}/apply-request`);
        }}
      />
      <ConfirmDialog
        open={openDeleteProductConfirmDialog}
        buttonText="削除する"
        text="入力されている内容は破棄されますが"
        title="商材情報を削除します"
        showIcon
        handleCancel={() => setOpenDeleteProductConfirmDialog(false)}
        handleSubmit={() => handleProductRemove()}
      />
      <ErrorDialog
        open={openNoticeDialog}
        title="入力内容にエラーが存在します。"
        message={errorMessage}
        handleClose={() => {
          setOpenNoticeDialog(false);
          setErrorMessage([]);
        }}
      />
    </Box>
  );
};
export default NormalEditSideBar;

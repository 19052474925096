/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { useSelector, } from 'react-redux'
import { Modules } from 'core'
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Alert, Typography } from 'components/atoms'
import { ErrorIcon } from 'assets/images'
import { COLORS } from 'constants/index'

/**
 * Interface
 */
export type Severity = "error" | "success" | "info" | "warning" | undefined

export interface MessageAlertProps {
  text: string,
  severity: Severity,
}

/**
 * Style
 */
const theme = createMuiTheme({
  overrides: {
    MuiAlert: {
      filledWarning: {
        backgroundColor: COLORS.SALAD_BAR_ORANGE_800
      },
      filledError: {
        backgroundColor: COLORS.SALAD_BAR_PINK_900
      }
    }
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginRight: 'auto',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    alertIcon: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(1)
    },
    alertMessage: {
      fontWeight: 'bold',
      fontSize: theme.typography.fontSize
    },
  }),
)

/**
 * Presenter
 */

export default function MessageAlert(props: MessageAlertProps) {
  const { severity, text } = props
  const classes = useStyles({})
  const sidebarShift = useSelector((state: Modules.AppState) => state.sidebarShift)

  return (
    <ThemeProvider theme={theme}>
      <Alert style={sidebarShift ? { width: 'calc(100% - 168px)' } : { width: 'calc(100% - 322px)' }}classes={{ message: classes.alert, icon: classes.alertIcon }} icon={<ErrorIcon />} variant="filled" severity={severity} className={classes.alert}>
        <Typography className={classes.alertMessage}>{text}</Typography>
      </Alert>
    </ThemeProvider>
  )
}/* eslint-enable */

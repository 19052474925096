import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { NormalEditPageTemplate } from 'components/templates';
import {
  ConfirmDialog,
  NormalEditHeaderBar,
  NormalEditSideBar,
} from 'components/organisms';
import NormalMainVisualEdit from 'containers/NormalMainVisualEditContainer';
import NormalGreeting from 'containers/NormalGreetingContainer';
import NormalEditSelectProduct from 'containers/NormalEditSelectProductContainer';
import NormalProductEdit from 'containers/NormalProductEditContainer';
import NormalEditLPSetting from 'containers/NormalEditLPSettingContainer';
import NormalEditApplyRequest from 'containers/NormalEditApplyRequestContainer';
import { LPRegisterRequest } from 'core/domain/lp';
import { useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';
import { useNavigate } from 'libs/hooks';
import CompleteEdit from '../CompleteEdit';

/**
 * Interface
 */
export interface NormalEditProps {
  handleSaveDraft: (tag: string | undefined) => void;
  handleSubmitApply: (workflow: string) => void;
}

/**
 * Presenter
 */

const NormalEdit = (props: NormalEditProps) => {
  const { path } = useRouteMatch();
  const { handleSaveDraft, handleSubmitApply } = props;
  const newLp = useSelector(
    (state: Modules.AppState) => state.newLp,
    shallowEqual,
  );
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const [initialNewLp, setInitialNewLp] = useState<
    LPRegisterRequest | undefined
  >(undefined);
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [nextPagePath, setNextPagePath] = useState('');
  const navigate = useNavigate();
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  useEffect(() => {
    const res = navigate.block((pathname: string) => {
      if (
        (pathname.includes('register') || pathname.includes('edit')) &&
        !pathname.includes('apply-completed')
      )
        return undefined;
      setNextPagePath(pathname);

      // 権限エラーの時にページ遷移できるようにする
      if (
        error &&
        (error?.error[0] === '権限がありません。' ||
          error?.error[0] === '対象のランディングページが存在しません。')
      ) {
        setGoAnotherPage(true);

        return undefined;
      }

      if (error) {
        return false;
      }
      if (pathname.includes('apply-completed')) return undefined;

      if ((!newLp && !initialNewLp) || goAnotherPage || isRegister)
        return undefined;

      if (newLp && initialNewLp) {
        if (JSON.stringify(newLp) !== JSON.stringify(initialNewLp)) {
          setOpenExitConfirmDialog(true);

          return false;
        }
      }

      if (newLp && !initialNewLp) {
        if (lpDetail) return undefined;
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    if (!newLp || initialNewLp) return undefined;
    setInitialNewLp({ ...newLp });

    return () => {
      res();
    };

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [newLp, goAnotherPage, isRegister, error]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [goAnotherPage]);

  useEffect(() => {
    if (isRegister) navigate.navigate(`/adm/lps/own`);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isRegister]);

  return (
    <>
      <NormalEditPageTemplate
        headerBar={<NormalEditHeaderBar />}
        sidebar={
          <NormalEditSideBar
            setIsDraft={setIsRegister}
            handleSaveDraft={handleSaveDraft}
          />
        }
      >
        <Switch>
          <Route exact path={path} component={NormalMainVisualEdit} />
          <Route path={`${path}/greeting`} component={NormalGreeting} />
          <Route
            path={`${path}/select-product`}
            component={NormalEditSelectProduct}
          />
          <Route
            path={`${path}/product/:productId/edit`}
            component={NormalProductEdit}
          />
          <Route path={`${path}/lp-setting`} component={NormalEditLPSetting} />
          <Route path={`${path}/apply-request`}>
            <NormalEditApplyRequest handleSubmitApply={handleSubmitApply} />
          </Route>
          <Route path={`${path}/apply-completed`}>
            <CompleteEdit setIsRegister={setIsRegister} />
          </Route>
        </Switch>
      </NormalEditPageTemplate>
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
    </>
  );
};

export default NormalEdit;

import React from 'react';
import { Box, HeadlineLabel } from 'components/atoms';
import { TagInputField } from 'components/molecules';
import { VariousSettings } from 'core/domain/variousSettings';

interface VariousSettingsLpEditSettingsAreaProps {
  handleInputChange: (v: string, key: keyof VariousSettings) => void;
  tantoCompanyOptions: string;
  tantoCompanyOptionsError: string | undefined;
  tokuisakiTitleOptions: string;
  tokuisakiTitleOptionsError: string | undefined;
}

const VariousSettingsLpEditSettingsArea = (
  props: VariousSettingsLpEditSettingsAreaProps,
) => {
  const {
    handleInputChange,
    tantoCompanyOptions,
    tantoCompanyOptionsError,
    tokuisakiTitleOptions,
    tokuisakiTitleOptionsError,
  } = props;

  const handleTokuisakiTitleOptionsChange = (v: string) => {
    handleInputChange(v, 'tokuisakiTitleOptions');
  };

  const handleTantoCompanyOptionsChange = (v: string) => {
    handleInputChange(v, 'tantoCompanyOptions');
  };

  return (
    <Box mt={5}>
      <HeadlineLabel text="ランディングページ編集画面設定" />
      <TagInputField
        error={tokuisakiTitleOptionsError}
        handleChange={handleTokuisakiTitleOptionsChange}
        maxLength={100}
        label="得意先敬称 選択肢"
        isMandatory
        value={tokuisakiTitleOptions}
      />
      <TagInputField
        error={tantoCompanyOptionsError}
        handleChange={handleTantoCompanyOptionsChange}
        maxLength={100}
        label="担当会社名 選択肢"
        isMandatory
        value={tantoCompanyOptions}
      />
    </Box>
  );
};

export default VariousSettingsLpEditSettingsArea;

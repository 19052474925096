import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from 'components/atoms';
import { ErrorOutlineIcon, CloseIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';

/**
 * Interface
 */

export interface ErrorDialogProps {
  open: boolean;
  handleSubmit?: () => void;
  handleClose?: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 632,
      minHeight: 224,
      padding: '32px 24px 16px 24px',
      margin: 0,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        width: 64,
        height: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    message: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    actions: {
      padding: 0,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      },
    },
  }),
);

const LpAdminChangePageDialog = (props: ErrorDialogProps) => {
  const classes = useStyles({});
  const { open, handleClose, handleSubmit } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleClose && handleClose()}
    >
      <Box>
        <Box className={classes.icon}>
          <ErrorOutlineIcon />
        </Box>
        <Typography variant="body2" className={classes.title}>
          ページ移行すると行選択チェックが無効になりますがよろしいですか？
        </Typography>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={102} height={36}>
              <SecondaryButton
                icon={<CloseIcon className={classes.closeIcon} />}
                click={() => handleClose && handleClose()}
                text="キャンセル"
              />
            </Box>
            <Box width={102} height={36} ml={2}>
              <PrimaryButton click={handleSubmit} text="決定" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LpAdminChangePageDialog;

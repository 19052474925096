/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import NormalEditApplyRequestPage from 'components/pages/NormalEditApplyRequest'
import { useSelector } from 'react-redux'
import { Modules } from 'core'

export interface NormalEditApplyProps{
  handleSubmitApply:(workflow:string)=>void
}

export default function NormalEditApplyRequest(props:NormalEditApplyProps) {
  
  const newLp = useSelector((state:Modules.AppState) => state.newLp)
  const normalEditErrors = useSelector((state:Modules.AppState) => state.normalEditErrors)
  const { handleSubmitApply } = props

  return (
    <NormalEditApplyRequestPage
      handleSubmitApply={handleSubmitApply}
      newLp={newLp}
      normalEditErrors={normalEditErrors}
    />
  )
}/* eslint-enable */

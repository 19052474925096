import { Dialog, DialogActions, FormControl, RadioGroup } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';
import {
  Box,
  ButtonLink,
  CommonRadioButton,
  LetterCountLabel,
  MultipleLinesTextField,
  OneLineTextField,
  PasswordTextField,
  PrimaryButton,
  SecondaryButton,
  SubHeadlineLabel,
  SupportTextLabel,
  Typography,
} from 'components/atoms';
import {
  NORMAL_EDIT_LP_TAG_OVER_CHAR,
  NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR,
  NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR,
  NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_TEXT_OVER_CHAR,
  NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED,
  NORMAL_EDIT_PASSWORD_NOT_ENTERED
} from 'constants/text';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { TYPOGRAPHY } from 'constants/index';
import { Modules, Usecases } from 'core';
import { LpDetail, LpOpenSettings } from 'core/domain/lp';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  clearNormalEditErrors,
  setNormalEditErrors
} from 'core/modules/normalEditErrors';
import { clearLpOpenSettings, setLpOpenSettings } from 'core/modules/lpOpenSettings';
import { NormalEditErrors } from 'core/domain/validationErrors';
import { removeIncorrectTagEntry } from 'libs/validation';
import { setLpTagsRequest } from 'core/modules/lpTagsRequest';
import { clearLpTags } from 'core/modules/lpTags';
import TagSelectDialog from '../TagSelectDialog';
import ConfirmDialog from '../ConfirmDialog';


/**
 * Interface
 */
export interface LpOpenInfoEditDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (
    lpOpenSettings: LpOpenSettings,
    tag: string,
  ) => void;
  lpDetail: LpDetail;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    box: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 8,
      margin: 12,
      width: '94%',
      border: `1px solid ${theme.palette.gray[600]}`,
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      textAlign: 'left',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    dialog: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
    },
    description: {
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    mailTextName: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_200,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
    },
    passwordDescription: {
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    preview: {
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
  }),
);

/** タグ・公開設定編集 */
const LpOpenSettingsEditDialog = (props: LpOpenInfoEditDialogProps) => {
  const classes = useStyles({});
  const token = hooks.useAuth().accessToken;
  const dispatch = useDispatch();
  const {
    open,
    handleCancel,
    handleSubmit,
    lpDetail,
  } = props;
  const { lpOpenSettingsEditPermission } = hooks.useLpDetailPermission();

  const [original, setOriginal] = useState<LpOpenSettings>();
  const [openTagSelectDialog, setOpenTagSelectDialog] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  // マイライブラリからコピーボタン活性非活性
  const [copyFromMyLibraryDisabled, setcopyFromMyLibraryDisabled] =
    useState(false);

  // マイライブラリ上書き確認ダイアログ
  const [openSubjectDialog, setOpenSubjectDialog] = useState(false);

  // ラジオボタンパスワード設定
  const [passwordSet, setPasswordSetting] = useState('1');

  // パスワードtextボックス活性非活性
  const [passwordTextBox, setpasswordTextBox] = useState(true);

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const lPTagsRequest = useSelector(
    (state:Modules.AppState) => state.lPTagsRequest,
    shallowEqual,
  );
  const lpTags = useSelector(
    (state:Modules.AppState) => state.lpTags,
    shallowEqual,
  );
  const normalEditErrors = useSelector(
    (state:Modules.AppState) => state.normalEditErrors,
    shallowEqual,
  );
  const myLibrary = useSelector(
    (state:Modules.AppState) => state.myLibrary,
    shallowEqual,
  );
  const lpOpenSettings = useSelector(
    (state:Modules.AppState) => state.lpOpenSettings,
    shallowEqual,
  )

  // 初期化
  useEffect(() => {
    if (!userInfo) return;

    // MyLibrary
    if (lpOpenSettingsEditPermission?.show) {
      dispatch(
        Usecases.myLibrary.sendGetMyLibrary(
          token,
          userInfo?.tenantId,
        )
      );
    }

    dispatch(clearLpOpenSettings());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LPタグ取得リクエストパラメーター初期値設定
  useEffect(() => {
    dispatch(setLpTagsRequest({tagType:"edit",sortKey:"tag"}))
  }, [dispatch])

  // LPタグ取得
  useEffect(() => {
    if (!userInfo) return
    dispatch(Usecases.lps.sendGetLpTags(token, userInfo.tenantId, lPTagsRequest))
    dispatch(clearLpTags())
    
  }, [lPTagsRequest, userInfo, dispatch, token])

  const initLpOpenSettings = () => {
    if (!lpDetail) return;
    dispatch(
      setLpOpenSettings({
        lpTag: lpDetail.lpTag || '',
        mailSubject: lpDetail.mailSubject || '',
        mailText: lpDetail.mailText || '',
        mailSignature: lpDetail.mailSignature || '',
        passwordSetting: lpDetail.passwordSetting || 1,
        password: lpDetail.password || '',
      })
    );
    setPasswordSetting(lpDetail.passwordSetting?.toString() || '1');
  }

  const initOriginal = () => {
    if (!lpDetail) return;
    setOriginal({
      lpTag: lpDetail.lpTag || '',
      mailSubject: lpDetail.mailSubject || '',
      mailText: lpDetail.mailText || '',
      mailSignature: lpDetail.mailSignature || '',
      passwordSetting: lpDetail.passwordSetting || 1,
      password: lpDetail.password || '',
    });
  }

  useEffect(() => {
    if (!lpDetail) return;

    initLpOpenSettings();
    initOriginal();

    setPasswordSetting(lpDetail.passwordSetting?.toString() || '1');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail])

  // ラジオボタンの値をセット
  const handleChangePasswordSetting = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value !== lpOpenSettings?.passwordSetting?.toString()) {
      setPasswordSetting(event.target.value);
    }
  };

  // パスワードエラー判定
  useEffect(() => {
    if (lpOpenSettings?.passwordSetting?.toString() === '1') {
      dispatch(
        setNormalEditErrors({
          ...normalEditErrors,
          password: undefined,
        }),
      );
    } else if (
      (lpOpenSettings?.passwordSetting?.toString() === '2' ||
      lpOpenSettings?.passwordSetting?.toString() === '3') &&
      lpOpenSettings?.password &&
      lpOpenSettings?.password?.length <= 100 &&
      lpOpenSettings?.password?.length >= 8 &&
      /^[0-9a-zA-Z]+$/.test(lpOpenSettings?.password) === true
    ) {
      dispatch(
        setNormalEditErrors({
          ...normalEditErrors,
          password: undefined,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpOpenSettings?.passwordSetting, lpOpenSettings?.password]);

  // パスワードのテキストボックス活性非活性
  useEffect(() => {
    if (passwordSet === '1') {
      setpasswordTextBox(true);
    } else {
      setpasswordTextBox(false);
    }
    dispatch(
      setLpOpenSettings({
        ...lpOpenSettings,
        passwordSetting: Number.parseInt(passwordSet, 10),
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSet]);

  // マイライブラリからコピーする
  const subjectCopy = () => {
    dispatch(
      setLpOpenSettings({
        ...lpOpenSettings,
        mailSubject: myLibrary?.mailSubject,
        mailText: myLibrary?.mailText,
        mailSignature: myLibrary?.mailSignature,
      })
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        greetText: undefined,
      }),
    );
  };

  // マイライブラリのsubjectで上書きするか否か
  const subjectCopyFromMyLibrary = (
    subjectValue?: string,
    mailValue?: string,
    signatureValue?: string,
  ) => {
    if (subjectValue || mailValue || signatureValue) {
      // 空でない項目ある場合上書き時にダイアログ表示
      setOpenSubjectDialog(true);
    } else {
      subjectCopy();
    }
  };

  // マイライブラリからコピーボタン活性非活性処理
  useEffect(() => {
    if (
      myLibrary?.mailSubject ||
      myLibrary?.mailText ||
      myLibrary?.mailSignature
    ) {
      setcopyFromMyLibraryDisabled(false);
    } else {
      setcopyFromMyLibraryDisabled(true);
    }
  }, [myLibrary]);

  // タグ選択
  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag].filter((tag) => !!tag));
  };

  const handleSubmitTags = () => {
    if (selectedTags[0] === '') {
      selectedTags.splice(0, 1);
    }
    dispatch(
      setLpOpenSettings({
        ...lpOpenSettings,
        lpTag: selectedTags.join(','),
      })
    );
    setOpenTagSelectDialog(false);
  };

  useEffect(() => {
    if (!lpOpenSettings || !lpOpenSettings.lpTag) return;

    const tmpTags = lpOpenSettings.lpTag.split(',');
    setSelectedTags([...tmpTags]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTagSelectDialog]);

  const handleSelectedTagChange = (value: string) => {
    const tags = value.split(',');
    setSelectedTags([...tags]);
  };

  const handleTextChange = (key: string, value: string) => {
    dispatch(
      setLpOpenSettings({
        ...lpOpenSettings,
        [key]: value,
      })
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        [key]: undefined,
      }),
    );
    if (key === 'lpTag') {
      handleSelectedTagChange(value);
    }
  };

  /** 編集されていたら確認ダイアログ表示 */
  const checkEdited = () => {
    if (
      JSON.stringify(original) ===
      JSON.stringify(lpOpenSettings)
    ) {
      initLpOpenSettings();
      handleCancel();
    } else {
      setOpenConfirmDialog(true);
    }
  }

  /** タグ用バリデーション */
  const checkTagsValidation = (tag: string): boolean => {
    const newTag = removeIncorrectTagEntry(tag);
    const newTags = newTag.split(',');
    if (newTag.length > 5000 || newTags.some((e) => e.length > 100))
      return true;

    return false;
  };

  /** タグから半角スペース削除等 */
  const formatTag = () => {
    if (!lpOpenSettings || !lpOpenSettings.lpTag) return '';
    const newTag = removeIncorrectTagEntry(lpOpenSettings.lpTag);

    return newTag;
  };

  /** エラーチェック */
  const checkErrors = () => {
    if (!lpOpenSettings) return;

    const errors: NormalEditErrors = {
      ...normalEditErrors,
    };

    // タグ
    if (lpOpenSettings?.lpTag && checkTagsValidation(lpOpenSettings.lpTag)) {
      errors.lpTag = NORMAL_EDIT_LP_TAG_OVER_CHAR;
    } else {
      errors.lpTag = undefined;
    }

    // 公開設定 メールタイトル
    if (lpOpenSettings?.mailSubject && lpOpenSettings.mailSubject.length <= 500) {
      errors.mailSubject = undefined;
    } else if (!lpOpenSettings || !lpOpenSettings.mailSubject) {
      errors.mailSubject = NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED;
    } else if (lpOpenSettings.mailSubject.length > 500) {
      errors.mailSubject = NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR;
    }

    // 公開設定 メール本文
    if (lpOpenSettings?.mailText && lpOpenSettings.mailText.length <= 5000) {
      errors.mailText = undefined;
    } else if (!lpOpenSettings || !lpOpenSettings.mailText) {
      errors.mailText = NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED;
    } else if (lpOpenSettings.mailText.length > 5000) {
      errors.mailText = NORMAL_EDIT_MAIL_TEXT_OVER_CHAR;
    }

    // 公開設定 メール署名
    if (lpOpenSettings?.mailSignature && lpOpenSettings.mailSignature.length <= 5000) {
      errors.mailSignature = undefined;
    } else if (lpOpenSettings?.mailSignature && lpOpenSettings.mailSignature.length > 5000) {
      errors.mailSignature = NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR;
    }

    // 公開設定 パスワード
    if (
      lpOpenSettings?.passwordSetting ===1 ||
      (lpOpenSettings?.passwordSetting &&
        lpOpenSettings?.password &&
        lpOpenSettings.password.length <= 100 &&
        lpOpenSettings.password.length >= 8 &&
      /^[0-9a-zA-Z]+$/.test(lpOpenSettings?.password)
      )
    ) {
      errors.password = undefined;
      // 8文字未満
    } else if (
      lpOpenSettings?.password &&
      lpOpenSettings?.passwordSetting !== 1 &&
      (lpOpenSettings.password.length < 8 || !/^[0-9a-zA-Z]+$/.test(lpOpenSettings?.password))
    ) {
      errors.password = NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED;
      // 未入力
    } else if (!lpOpenSettings || (!lpOpenSettings.password && lpOpenSettings.passwordSetting !== 1)) {
      errors.password = NORMAL_EDIT_PASSWORD_NOT_ENTERED;
    }

    if (
      errors.lpTag ||
      errors.mailSubject ||
      errors.mailText ||
      errors.mailSignature ||
      errors.password
    ) {
      dispatch(setNormalEditErrors(errors));
    } else {
      const tag = formatTag();
      dispatch(clearNormalEditErrors());
      handleSubmit(lpOpenSettings, tag);
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: 16 } }}
        classes={{ paper: classes.dialog}}
        disableBackdropClick
        open={open}
        onClose={() => handleCancel()}
      >
        <Box>
          <Typography variant="body2" className={classes.title}>
            <span style={{marginRight: 10}}>タグ・公開設定</span>編集
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <SubHeadlineLabel text="タグ" />
            </Box>
            <Box mt={3}>
              <ButtonLink
                fontSize="13px"
                text="＋リストから追加する"
                click={() => setOpenTagSelectDialog(true)}
              />
            </Box>
          </Box>
          <OneLineTextField
            error={normalEditErrors?.lpTag}
            label="タグを入力"
            value={lpOpenSettings?.lpTag}
            handleChangeText={(text) => handleTextChange('lpTag', text)}
          />
          <SupportTextLabel text="複数登録の場合はカンマ(,)で区切ってください。" />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <MandatoryHeadlineLabel title="メールタイトル" />
            </Box>
            <Box mt={3}>
              <ButtonLink
                disabled={copyFromMyLibraryDisabled}
                click={() =>
                  subjectCopyFromMyLibrary(
                    lpOpenSettings?.mailSubject,
                    lpOpenSettings?.mailText,
                    lpOpenSettings?.mailSignature,
                  )
                }
                text="マイライブラリからコピー"
                fontSize="13px"
              />
            </Box>
          </Box>
          <OneLineTextField
            error={normalEditErrors?.mailSubject}
            handleChangeText={(text) => handleTextChange('mailSubject', text)}
            value={lpOpenSettings?.mailSubject}
            label='メールタイトルを入力'
          />
          <LetterCountLabel
            limit="30"
            count={`${
              lpOpenSettings?.mailSubject?.length ?
              lpOpenSettings?.mailSubject.length :
              '0'
            }`}
          />
          <MandatoryHeadlineLabel title="メール本文" />
          <Typography variant="body1">
            <span className={classes.mailTextName}>〇〇〇〇〇 〇〇〇〇〇〇</span>
            様
            <span className={classes.preview}>
              {' '}
              ※メール本文冒頭には、送信先リストの「お客さま会社」「お客さま氏名」が入ります
            </span>
          </Typography>
          <MultipleLinesTextField
            error={normalEditErrors?.mailText}
            value={lpOpenSettings?.mailText}
            handleChangeText={(text) => handleTextChange('mailText', text)}
            label="メール本文を入力"
            rows={7}
          />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <span className={classes.description}>
                URL：公開ランディングページのURLが入ります
              </span>
            </Box>
            <Box>
              <LetterCountLabel
                limit="300"
                count={`${
                  lpOpenSettings?.mailText?.length ?
                  lpOpenSettings?.mailText.length :
                  '0'
                }`}
              />
            </Box>
          </Box>
          <MandatoryHeadlineLabel title="パスワード設定" />
          <FormControl>
            <RadioGroup
              aria-label="order"
              value={passwordSet}
              onChange={handleChangePasswordSetting}
            >
              <CommonRadioButton value="1" label="設定しない" />
              <CommonRadioButton value="2" label="設定して本文に自動で入れる" />
              <CommonRadioButton
                value="3"
                label="設定してパスワードを別でお知らせする"
              />
            </RadioGroup>
            <span className={classes.passwordDescription}>
            ※システムからは自動送信されません。ご自身でメール等でお客さまへご連絡ください。
            </span>
          </FormControl>
          <Box width={332}>
            <PasswordTextField
              error={normalEditErrors?.password}
              disabled={passwordTextBox}
              value={lpOpenSettings?.password}
              handleChangeText={(text) => handleTextChange('password', text)}
            />
          </Box>
          <SupportTextLabel text="半角英数字8文字以上" />
          <SubHeadlineLabel text="メール署名" />
          <MultipleLinesTextField
            error={normalEditErrors?.mailSignature}
            value={lpOpenSettings?.mailSignature}
            handleChangeText={(text) => handleTextChange('mailSignature', text)}
            label="自分のメール署名を入力"
            rows={7}
          />
          <LetterCountLabel
            limit="300"
            count={`${
              lpOpenSettings?.mailSignature?.length ?
              lpOpenSettings?.mailSignature.length :
              '0'
            }`}
          />
        </Box>
        <DialogActions className={classes.actions}>
          <Box width={332} height={48}>
            <SecondaryButton
              click={checkEdited}
              text="キャンセル"
            />
          </Box>
          <Box p={0} display="flex" mt={0}>
            <Box ml={2} width={332} height={48}>
              <PrimaryButton
                click={() => {
                  if (!lpOpenSettings) return;
                  checkErrors();
                }}
                text="決定" />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      {/* タグ選択子画面 */}
      <TagSelectDialog
        handleCheck={handleTagCheck}
        tags={lpTags?.lpTags ? lpTags?.lpTags : undefined}
        usedTags={lpTags?.usedLpTags ? lpTags?.usedLpTags : undefined}
        open={openTagSelectDialog}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setOpenTagSelectDialog(false);
        }}
        handleSubmit={() => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          handleSubmitTags();
        }}
        selectedTags={selectedTags}
        tagType="edit"
        category="LP"
      />
      {/* マイライブラリからコピー上書きの時に出すダイアログ メール件名 */}
      <ConfirmDialog
        buttonText="OK"
        open={openSubjectDialog}
        title="上書きします"
        text="内容を上書きしても"
        handleCancel={() => setOpenSubjectDialog(false)}
        handleSubmit={() => {
          subjectCopyFromMyLibrary();
          setOpenSubjectDialog(false);
        }}
      />
      {/* キャンセル確認画面 */}
      <ConfirmDialog
        buttonText="終了する"
        open={openConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenConfirmDialog(false)}
        handleSubmit={() => {
          dispatch(clearNormalEditErrors());
          handleCancel();
          initLpOpenSettings();
          setOpenConfirmDialog(false);
        }}
      />
    </div>
  );
};
export default LpOpenSettingsEditDialog;
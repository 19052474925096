/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { CommonComboBox } from 'components/atoms'

/**
 * Interface
 */
export interface TimePickerProps {
	width?: string
	value?: string
	handleChangeTime?: (time: string) => void
	error?: string
}

/**
 * Presenter
 */

export default function TimePicker(props: TimePickerProps) {
	const { value, handleChangeTime } = props
	const timeSlots = Array.from(new Array(24 * 2)).map(
		(_, index) => `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`)

	return (
		<>
			<CommonComboBox
				placeholder="時間を選択してください"
				{...props}
				value={value}
				options={[''].concat(timeSlots)}
				handleChangeText={handleChangeTime}
			/>
		</>
	)
}/* eslint-enable */

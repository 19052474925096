/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { hooks } from 'libs';
import { useDispatch } from 'react-redux';
import { Usecases } from 'core';
import CallbackPage from 'components/pages/Callback';

/**
 * Interface
 */

interface ChildrenProps {
  authenticated: boolean;
  error: Error | string | undefined;
}

interface CallbackPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement;
}

/**
 * Container
 */

function CallbackPageContainer(props: CallbackPageContainerProps) {
  const { children } = props;
  const dispatch = useDispatch();
  const { authenticated, handleAuthentication, accessToken } = hooks.useAuth();
  const [error, setError] = useState<Error | string | undefined>(undefined);

  // 認証成功時ユーザーログイン履歴登録
  useEffect(() => {
    if (authenticated) {
      dispatch(Usecases.user.sendPostLoginHistory(accessToken));

      return;
    }
    handleAuthentication()
      .then(() => {
        // Nothing to do
      })
      .catch((error) => {
        setError(error);
      });
  }, [authenticated, handleAuthentication]);

  return children({ authenticated, error });
}

export default function CallbackPageComponent() {
  return (
    <CallbackPageContainer>
      {(props) => <CallbackPage {...props} />}
    </CallbackPageContainer>
  );
}

/* eslint-enable */

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar } from 'components/atoms';
import { Content, NoticesTable, NoticeSearchField } from 'components/organisms';
import { hooks } from 'libs';
import { NoticesQueryParameter, Notices } from 'core/domain/notices';

/**
 * Interface
 */
export interface NoticesfunProps {
  rows: number;
  notices: Notices | null;
  noticesQueryParameter: NoticesQueryParameter | null;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangePage: (page: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeUnreadOnlySwitch?: (checked: boolean) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */

const Noticesfun = (props: NoticesfunProps) => {
  const classes = useStyles({});
  const {
    notices,
    rows,
    handleChangeSortKey,
    noticesQueryParameter,
    handleChangePage,
    handleChangeFreeWord,
    handleChangeUnreadOnlySwitch,
  } = props;
  const { navigate } = hooks.useNavigate();

  return (
    <Box mt={5}>
      <Content title="お知らせ一覧">
        <div>
          <NoticeSearchField
            switchLabel="未読のみ表示"
            switchValue={
              noticesQueryParameter?.unread
                ? noticesQueryParameter.unread
                : null
            }
            noticesQueryParameter={noticesQueryParameter}
            handleChangeFreeWord={handleChangeFreeWord}
            handleChangeUnreadOnlySwitch={handleChangeUnreadOnlySwitch}
          />

          <Box className={classes.table}>
            <NoticesTable
              clickNotice={(id) => navigate(`/adm/notices/${id}`)}
              notices={notices}
              handleChangeSortKey={handleChangeSortKey}
              noticesQueryParameter={noticesQueryParameter}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  noticesQueryParameter ? noticesQueryParameter.page : 1
                }
                maxPage={notices ? Math.ceil(notices.totalCount / rows) : 1}
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={notices?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
    </Box>
  );
};
export default Noticesfun;

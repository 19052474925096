/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpDetail } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpDetail | null {
  return initialState
}

export const SET_LP_DETAIL = 'lp-detail/set' as const
export const CLEAR_LP_DETAIL = 'lp-detail/clear' as const

export function setLpDetail(data: LpDetail) {
  return {
    type: SET_LP_DETAIL,
    payload: {
      data,
    },
  }
}

export function clearLpDetail() {
  return {
    type: CLEAR_LP_DETAIL,
  }
}

type SetLpDetailAction = ReturnType<typeof setLpDetail>
type ClearLpDetailAction = ReturnType<typeof clearLpDetail>
type Action = SetLpDetailAction | ClearLpDetailAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_DETAIL:
      return action.payload.data
    case CLEAR_LP_DETAIL:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


const initialState = true

export function createInitialState(): boolean {
  return initialState
}

export const SET_NORMAL_EDIT_SIDEBAR_SHOWN = 'normal-edit-sidebar-shown/set' as const
export const CLEAR_NORMAL_EDIT_SIDEBAR_SHOWN = 'normal-edit-sidebar-shown/clear' as const

export function setNormalEditSidebarShown(sidebarShown: boolean) {
  return {
    type: SET_NORMAL_EDIT_SIDEBAR_SHOWN,
    payload: {
      sidebarShown,
    },
  }
}

export function clearNormalEditSidebarShown() {
  return {
    type: CLEAR_NORMAL_EDIT_SIDEBAR_SHOWN,
  }
}

type SetNormalEditSidebarShownAction = ReturnType<typeof setNormalEditSidebarShown>
type ClearNormalEditSidebarShownAction = ReturnType<typeof clearNormalEditSidebarShown>
type Action = SetNormalEditSidebarShownAction | ClearNormalEditSidebarShownAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NORMAL_EDIT_SIDEBAR_SHOWN:
      return action.payload.sidebarShown
    case CLEAR_NORMAL_EDIT_SIDEBAR_SHOWN:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

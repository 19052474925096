/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, createMuiTheme, Theme } from '@material-ui/core/styles'
import { FormControlLabel, Radio} from 'components/atoms'
import { COLORS, TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */
export interface CommonRadioButtonProps{
  label: string
  value: string
  disabled?:boolean
}

/**
 * Style
 */

export const theme = createMuiTheme({
  overrides: {
    // For Container
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: COLORS.SALAD_BAR_GREEN_800,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: COLORS.SALAD_BAR_GREEN_800,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        "&&& $input": {
          padding: 0,
          fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
        },
        height: 36,
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_800,
        },
      }
    },    
  },
})


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		radio: {
      '& .MuiRadio-root':{
        color: theme.palette.gray[700],
        padding: 8,
      },
      '& .Mui-checked':{
        color: theme.palette.green[800],
      },
      '& .MuiTypography-root': {
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
        color: theme.palette.gray[900],
        margin: 0
      }
    },
	}),
)

/**
 * Presenter
 */

export default function CommonRadioButton(props:CommonRadioButtonProps) {

	const classes = useStyles(props)

	return (
		<FormControlLabel className={classes.radio} {...props} control={<Radio />} />
	)
}/* eslint-enable */

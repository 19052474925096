import { Box, FooterBar, PrimaryButton, Typography } from 'components/atoms';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SALAD_BAR_GRAY_400 } from 'constants/color';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';
import { LoginLogo } from 'assets/images';
import { downloadFileFromUrl } from 'libs/downloader';

/**
 * Interface
 */

interface LoginPageProps {
  handleLogin: () => void;
}
/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      backgroundColor: SALAD_BAR_GRAY_400,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    menu: {
      width: '506px',
      height: '266px',
      borderRadius: '10px',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    logo: {
      marginTop: '51px',
    },
    button: {
      width: '332px',
      height: '48px',
      color: 'white',
      backgroundColor: '#44A557',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      border: '1px solid #558643',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '21px',
    },
    footer: {
      width: '100%',
      '& .MuiDivider-root': {
        margin: '0px 35px',
      },
    },
    links: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '-58px',
      width: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    text: {
      color: '#44A557',
      fontSize: '14px',
      cursor: 'pointer',
    },
    anchor: {
      textDecoration: 'none',
    },
  }),
);

/**
 * Presenter
 */

const LoginPage = (props: LoginPageProps) => {
  const classes = useStyles({});
  const { handleLogin } = props;

  const handleDownload = () => {
    void downloadFileFromUrl(
      `${
        process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT || window.location.origin
      }/terms/SALAD-BAR_SERVICE_TERM.pdf`,
      'SALAD-BARサービス利用規約.pdf',
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.menu}>
        <LoginLogo className={classes.logo} />
        <Box className={classes.button}>
          <PrimaryButton text="ログイン" click={handleLogin} />
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.links}>
          <a
            href="https://www.toppan.co.jp/privacy.html"
            className={classes.anchor}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography className={classes.text}>
              プライバシーポリシー
            </Typography>
          </a>
          <a
            href="https://www.toppan.co.jp/"
            className={classes.anchor}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography className={classes.text}>運営会社</Typography>
          </a>
          <a className={classes.anchor} onClick={() => handleDownload()}>
            <Typography className={classes.text}>利用規約</Typography>
          </a>
        </Box>
        <FooterBar />
      </Box>
    </Box>
  );
};
export default LoginPage;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Modules } from 'core'
import { makeStyles, createStyles} from '@material-ui/core/styles'
import  HeaderBarCustomer  from '../../atoms/HeaderBarCustomer'
import  HeaderBarNotification from '../../atoms/HeaderBarNotification'
import  HeaderBarUser from '../../atoms/HeaderBarUser'

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    user: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
)

/**
 * Presenter
 */

export default function HeaderBarItem() {
  const classes = useStyles({})
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)
  const customerVisible = useMemo(() => {
    if (!userInfo) return false

    return userInfo.lpAuthor
  }, [userInfo])

  return (
    <div className={classes.user}>
      {customerVisible && <HeaderBarCustomer />}
      <HeaderBarNotification />
      <HeaderBarUser />
    </div>

  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles,Theme } from '@material-ui/core/styles'
import { IconButton } from 'components/atoms'
import { CloseIcon } from 'assets/images'

/**
 * Interface
 */
export interface CloseIconButtonProps{
  click?:()=>void
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.text.secondary,
      height: 20,
      width: 20,
      padding: 0,
    },
    icon: {
      height: 14,
      width: 14,
    }
  }),
)

/**
 * Presenter
 */

export default function CloseIconButton(props:CloseIconButtonProps) {

  const classes = useStyles({})

  return (
    <IconButton onClick={props.click} className={classes.button}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  )
}/* eslint-enable */

const GIF = 'image/gif';
const PNG = 'image/png';
const JPEG = 'image/jpeg';
const MP4 = 'video/mp4';
const PDF = 'application/pdf';

const EXTENSION_GIF = '.gif';
const EXTENSION_PNG = '.png';
const EXTENSION_JPEG = '.jpeg';
const EXTENSION_JPG = '.jpg';
const EXTENSION_ICO = '.ico';
const EXTENSION_MP4 = '.mp4';
const EXTENSION_PDF = '.pdf';
const EXTENSION_CSV = '.csv';

export {
  GIF,
  PNG,
  JPEG,
  MP4,
  PDF,
  EXTENSION_GIF,
  EXTENSION_PNG,
  EXTENSION_JPEG,
  EXTENSION_JPG,
  EXTENSION_ICO,
  EXTENSION_MP4,
  EXTENSION_PDF,
  EXTENSION_CSV,
};

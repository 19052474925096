/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */

export interface SupportTextLabelProps{
  text: string,
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
	createStyles({
		root: {
			margin: 0,
		},
		caption: {
			color: theme.palette.gray[800],
			fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50
		}
	}),
)

/**
 * Presenter
 */

export default function SupportTextLabel(props: SupportTextLabelProps) {

	const classes = useStyles({})
  const { text } = props

	return (
		<Box className={classes.root}>
			<Typography component="span" variant="caption" className={classes.caption}>
				{text}
			</Typography>
		</Box>
	)
}/* eslint-enable */

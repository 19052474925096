import React from 'react'
import { Box, Typography, CalendarText } from 'components/atoms'

export interface TargetOpenPeriodLabelProps {
  start?: string
  end?: string
  startError?: string
  endError?: string
  handleChangeStartDate?: (date: string) => void
  handleChangeEndDate?: (date: string) => void
}

const TargetOpenPeriodLabel = (props: TargetOpenPeriodLabelProps) => {
  const { start, end, startError, endError, handleChangeStartDate, handleChangeEndDate } = props

  return (
    <Box display="flex">
      <Box marginTop={0.9}>
        <Typography variant="body1" style={{width:80}}>公開期間:</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={155}>
          <CalendarText error={startError} value={start} handleChangeDate={handleChangeStartDate} />
        </Box>
        <Typography variant="body1" style={{marginRight:2,marginLeft:2}}>～</Typography>
        <Box width={155}>
          <CalendarText error={endError} value={end} handleChangeDate={handleChangeEndDate} />
        </Box>
      </Box>
    </Box>
  )
}
export default TargetOpenPeriodLabel;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, IconButton } from 'components/atoms'
import { ProductDetailList } from 'components/organisms'
import { ProductDetail } from 'core/domain/product'
import { MaterialWorkflow } from 'core/domain/material'
import { CloseWhiteIcon } from 'assets/images'

/**
 * Interface
 */
export interface ProductInformationDialogProps {
  onClose: () => void
  productDetail: ProductDetail
  productWorkflows?: MaterialWorkflow[] | null
  handleRecommendedUpdate: () => Promise<void>
  handleClickFavorite: () => void
  open:boolean
}
/**
* Style
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(3),
      height: 700,
      overflowY: 'unset',
    },
    products: {
      overflowY: 'auto',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-5%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
   },
  }),
)

/**
* Presenter
*/

export default function ProductInformationDialog(props: ProductInformationDialogProps) {

  const classes = useStyles({})
  const { onClose, handleRecommendedUpdate, productDetail, open, handleClickFavorite
  } = props

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick>
      <Box height={787} className={classes.products}>
        <ProductDetailList
          isDialog
          productDetail={productDetail}
          handleRecommendedUpdate={handleRecommendedUpdate}
          handleClickFavorite={handleClickFavorite}
        />
      </Box>
      <DialogActions className={classes.closeButton}>
        <IconButton onClick={onClose} >
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


/**
 * Font Size
 *
 * - Desktop
 */

/* Desktop scale */
const SALAD_BAR_DESKTOP_FONT_SIZE_50 = '12px'
const SALAD_BAR_DESKTOP_FONT_SIZE_75 = '13px'
const SALAD_BAR_DESKTOP_FONT_SIZE_100 = '14px' // Base size
const SALAD_BAR_DESKTOP_FONT_SIZE_200 = '16px'
const SALAD_BAR_DESKTOP_FONT_SIZE_300 = '18px'
const SALAD_BAR_DESKTOP_FONT_SIZE_400 = '20px'
const SALAD_BAR_DESKTOP_FONT_SIZE_500 = '22px'
const SALAD_BAR_DESKTOP_FONT_SIZE_600 = '24px'
const SALAD_BAR_DESKTOP_FONT_SIZE_700 = '28px'
const SALAD_BAR_DESKTOP_FONT_SIZE_800 = '32px'
const SALAD_BAR_DESKTOP_FONT_SIZE_900 = '36px'
const SALAD_BAR_DESKTOP_FONT_SIZE_1000 = '40px'
const SALAD_BAR_DESKTOP_FONT_SIZE_1100 = '45px'
const SALAD_BAR_DESKTOP_FONT_SIZE_1200 = '50px'
const SALAD_BAR_DESKTOP_FONT_SIZE_1300 = '60px'

export {
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_200,
  SALAD_BAR_DESKTOP_FONT_SIZE_300,
  SALAD_BAR_DESKTOP_FONT_SIZE_400,
  SALAD_BAR_DESKTOP_FONT_SIZE_500,
  SALAD_BAR_DESKTOP_FONT_SIZE_600,
  SALAD_BAR_DESKTOP_FONT_SIZE_700,
  SALAD_BAR_DESKTOP_FONT_SIZE_800,
  SALAD_BAR_DESKTOP_FONT_SIZE_900,
  SALAD_BAR_DESKTOP_FONT_SIZE_1000,
  SALAD_BAR_DESKTOP_FONT_SIZE_1100,
  SALAD_BAR_DESKTOP_FONT_SIZE_1200,
  SALAD_BAR_DESKTOP_FONT_SIZE_1300
}
/* eslint-enable */

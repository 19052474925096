import React, { useCallback, useReducer, useState } from 'react';
import { Box, HeadlineLabel, PrimaryButton } from 'components/atoms';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FileUploadDialog } from 'components/organisms';
import {
  AuthenticationMark,
  VariousSettings,
} from 'core/domain/variousSettings';
import { fileToBase64 } from 'libs/file';
import ImageCardItem from '../ImageCardItem';

interface VariousSettingsCertificationMarkAreaProps {
  authenticationMark: AuthenticationMark[] | undefined;
  errors?: { [key: number]: string };
  handleInputChange: (
    v: AuthenticationMark[],
    key: keyof VariousSettings,
  ) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    titleArea: {
      '& > :nth-child(2)': {
        width: 160,
        height: 35,
      },
    },
  }),
);

const VariousSettingsCertificationMarkArea = (
  props: VariousSettingsCertificationMarkAreaProps,
) => {
  const { authenticationMark, errors, handleInputChange } = props;
  const classes = useStyles();
  const [upDateFileDialogOpen, setUpdateFileDialogOpen] =
    useState<boolean>(false);
  const [selectedDisplayOrder, handleDisplayOrderChange] = useReducer(
    (_: number, curr: number) => curr,
    0,
  );

  const handleAddFileList = () => {
    const imageList = authenticationMark ? [...authenticationMark] : [];
    const newImageList = [
      ...imageList,
      {
        displayOrder: imageList.length + 1,
      },
    ];
    handleInputChange(
      newImageList as AuthenticationMark[],
      'authenticationMark',
    );
  };

  const handleUpdateFileDialogClose = () => {
    setUpdateFileDialogOpen(false);
  };

  const handleUpdateFileClick = (currOrder: number) => {
    setUpdateFileDialogOpen(true);
    handleDisplayOrderChange(currOrder);
  };

  const dispatchImage = (
    existingImages: AuthenticationMark[],
    data: string,
  ) => {
    const imageDataList = [
      ...existingImages,
      {
        ...authenticationMark?.filter(
          (mark) => mark.displayOrder === selectedDisplayOrder,
        )[0],
        authenticationMarkFileData: data,
      },
    ];

    handleInputChange(
      imageDataList as AuthenticationMark[],
      'authenticationMark',
    );
  };

  const updateFileData = (files: File[]) => {
    if (!authenticationMark) return;
    const images = authenticationMark.filter(
      (mark) => mark.displayOrder !== selectedDisplayOrder,
    );
    files.map((file) => {
      fileToBase64(file)
        .then((res) => {
          dispatchImage(images, res as string);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    });
  };

  const handleUpdateFileSubmit = (files: File[]) => {
    if (!files.length || !files[0]) return;

    updateFileData(files);
    setUpdateFileDialogOpen(false);
  };

  const handleSetURLChange = useCallback(
    (currOrder: number, url: string) => {
      if (!authenticationMark) return;
      handleInputChange(
        authenticationMark.map((mark) => {
          if (mark.displayOrder === currOrder) {
            return {
              ...mark,
              url,
            };
          }

          return mark;
        }),
        'authenticationMark',
      );
    },
    [authenticationMark, handleInputChange],
  );

  const handleOrderChange = useCallback(
    (currOrder: number, type: 'up' | 'down') => {
      const changedOrder = type === 'up' ? currOrder - 1 : currOrder + 1;

      if (!authenticationMark) return;
      handleInputChange(
        authenticationMark.map((mark) => {
          if (mark.displayOrder === currOrder) {
            return {
              ...mark,
              displayOrder: changedOrder,
            };
          }
          if (mark.displayOrder === changedOrder) {
            return {
              ...mark,
              displayOrder: currOrder,
            };
          }

          return mark;
        }),
        'authenticationMark',
      );
    },
    [authenticationMark, handleInputChange],
  );

  const handleFileDelete = useCallback(
    (deleteId: number) => {
      const deletedAuthenticationMark =
        authenticationMark?.filter((mark) => mark.displayOrder !== deleteId) ||
        [];

      handleInputChange(
        deletedAuthenticationMark.map((mark, i) => ({
          ...mark,
          displayOrder: i + 1,
        })),
        'authenticationMark',
      );
    },
    [authenticationMark, handleInputChange],
  );

  return (
    <Box mt={5}>
      <FileUploadDialog
        open={upDateFileDialogOpen}
        onClose={handleUpdateFileDialogClose}
        fileType="image"
        handleSubmit={handleUpdateFileSubmit}
      />
      <Box
        className={classes.titleArea}
        display="flex"
        justifyContent="space-between"
      >
        <HeadlineLabel text="認証マーク" />
        <PrimaryButton text="認証マークを追加" click={handleAddFileList} />
      </Box>
      {authenticationMark &&
        authenticationMark
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((mark, index) => (
            <ImageCardItem
              key={mark.displayOrder}
              data={
                mark.authenticationMarkFileData ||
                mark.authenticationMarkFileUrl
              }
              displayOrder={mark.displayOrder}
              error={errors ? errors[index] : undefined}
              hasUrl
              handleFileDelete={handleFileDelete}
              handleOrderChange={handleOrderChange}
              handleSetURLChange={handleSetURLChange}
              handleUpdateFileClick={handleUpdateFileClick}
              isBottom={mark.displayOrder === authenticationMark.length}
              isTop={mark.displayOrder === 1}
              url={mark.url}
            />
          ))}
    </Box>
  );
};

export default VariousSettingsCertificationMarkArea;

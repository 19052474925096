import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Modules } from 'core';
import { fileToBase64 } from 'libs/file';
import {
  Typography,
  Box,
  PrimaryButton,
  HeadlineLabel,
  OneLineTextField,
  SecondaryButton,
  MessageAlert,
  CommonComboBox,
  ButtonLink,
  ErrorText,
  CommonTextSwitch,
} from 'components/atoms';
import {
  Content,
  FileUploadDialog,
  AccountSettingApprover,
  SuccessDialog,
  ConfirmDialog,
  ErrorDialog,
} from 'components/organisms';
import { UploadIcon, UserIcon } from 'assets/images';
import {
  MaterialApprovers,
  UserRegisterRequest,
  LPApprovers,
  AdminUser,
  ProfileImageRegisterRequest,
} from 'core/domain/user';
import { setUserInfoReq } from 'core/modules/userRegReq';

import clsx from 'clsx';
import { hooks } from 'libs';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    profilePhoto: {
      width: '277px',
      height: '127px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    name: {
      width: '332px',
      marginTop: '48px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    department: {
      width: '332px',
      marginTop: '48px',
    },
    lpApprover: {
      width: '688px',
      marginTop: '48px',
    },
    ingredientApprover: {
      width: '688px',
      marginTop: '48px',
    },
    emailNotification: {
      width: '427px',
      height: '179px',
      marginTop: '48px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    button: {
      width: '100%',
      height: '73px',
      marginTop: '40px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    userIcon: {
      height: '71px',
      width: '71px',
      fill: '#82827E',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    approver: {
      width: '332px',
    },
    butonBox: {
      height: '72px',
      width: '190px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    downloadBtn: {
      height: '36px',
      width: '190px',
    },
    dlttext: {
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: '#DB3218',
      },
    },
    helpText: {
      fontSize: '12px',
      color: '#82827E',
    },
    instText: {
      marginTop: '25px',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    switch: {
      display: 'flex',
      flexDirection: 'row',
    },
    cancelBtn: {
      height: '48px',
      width: '332px',
      margin: '8px 8px',
    },
    impText: {
      color: '#DB3218',
      fontSize: '11px',
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    image: {
      height: '71px',
      width: '71px',
      borderRadius: '50%',
    },
  }),
);

/**
 * Interface
 */

export interface AccountProps {
  materialApproverInfo: MaterialApprovers | null;
  lpApproverInfo: LPApprovers | null;
  handleCancel: () => void;
  handleRegister: () => void;
  goAnotherPage: boolean;
  setGoAnotherPage: (state: boolean) => void;
  initialNewUser: UserRegisterRequest | undefined;
  setInitialNewUser: (state: UserRegisterRequest | undefined) => void;
}
interface ValidationError {
  step1LpApprovalUserId?: string;
  step2LpApprovalUserId?: string;
  step3LpApprovalUserId?: string;
  step1MaterialApprovalUserId?: string;
  step2MaterialApprovalUserId?: string;
  step3MaterialApprovalUserId?: string;
  displayName?: string;
  deptName?: string;
}

/**
 * Presenter
 */
interface LpApprovalUsersName {
  step1ApprovalUserName?: string;
  step2ApprovalUserName?: string;
  step3ApprovalUserName?: string;
}
interface MaterialApprovalUserName {
  step1ApprovalUserName?: string;
  step2ApprovalUserName?: string;
  step3ApprovalUserName?: string;
}

const PATTERN = new RegExp(
  /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
);

const Account = (props: AccountProps) => {
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  const newUser = useSelector(
    (state: Modules.AppState) => state.userInfoReq,
    shallowEqual,
  );

  const classes = useStyles({});
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const {
    materialApproverInfo,
    lpApproverInfo,
    handleCancel,
    handleRegister,
    goAnotherPage,
    setGoAnotherPage,
    initialNewUser,
    setInitialNewUser,
  } = props;
  const materialApprover = materialApproverInfo?.materialAdminUsers;
  const lpApprover = lpApproverInfo?.lpAdminUsers;
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [LpApprovalUsersName, setLpApprovalUserName] =
    useState<LpApprovalUsersName>({
      step1ApprovalUserName: undefined,
      step2ApprovalUserName: undefined,
      step3ApprovalUserName: undefined,
    });
  const [MaterialApprovalUsersName, setMaterialApprovalUsersName] =
    useState<MaterialApprovalUserName>({
      step1ApprovalUserName: undefined,
      step2ApprovalUserName: undefined,
      step3ApprovalUserName: undefined,
    });

  const location = useLocation();

  const [nextPagePath, setNextPagePath] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const { setLpApproverPermission, setMaterialApproverpermission } =
    hooks.useAccountPermission();
  const noApprovalAuthUserMsg = '権限のないユーザーです。';

  const clearValidation = useCallback(() => {
    setValidationError({
      step1LpApprovalUserId: undefined,
      step1MaterialApprovalUserId: undefined,
      displayName: undefined,
      deptName: undefined,
    });
  }, [setValidationError]);

  const handleAccountChangeCancel = useCallback(() => {
    clearValidation();
    handleCancel();
  }, [clearValidation, handleCancel]);

  useEffect(() => {
    const res = navigate.block((pathname: string) => {
      // 入力情報と保存済み情報が存在することを確認
      // ページ遷移が許可されていればblockしない

      // 遷移先が同じパスなら遷移自体キャンセル
      if (pathname === location.pathname) return undefined;

      if ((!newUser && !initialNewUser) || goAnotherPage) return undefined;
      setNextPagePath(pathname);

      if (newUser && initialNewUser) {
        // 入力情報と保存済み情報が異なる場合にページ遷移をブロック
        if (JSON.stringify(newUser) !== JSON.stringify(initialNewUser)) {
          setConfirmDialogOpen(true);

          return false;
        }
      }

      if (newUser && !initialNewUser) {
        setConfirmDialogOpen(true);

        return false;
      }

      return undefined;
    });

    if (!newUser || initialNewUser) return undefined;

    // InitialNewUser更新
    // 新規ユーザではstep1ApprovalUserIdがnullで取得される可能性あり?
    setInitialNewUser({
      ...newUser,
      lpApprovalUsers: {
        step1ApprovalUserId:
          newUser?.lpApprovalUsers?.step1ApprovalUserId || null,
        step2ApprovalUserId:
          newUser?.lpApprovalUsers?.step2ApprovalUserId || null,
        step3ApprovalUserId:
          newUser?.lpApprovalUsers?.step3ApprovalUserId || null,
      },
      materialApprovalUsers: {
        step1ApprovalUserId:
          newUser?.materialApprovalUsers?.step1ApprovalUserId || null,
        step2ApprovalUserId:
          newUser?.materialApprovalUsers?.step2ApprovalUserId || null,
        step3ApprovalUserId:
          newUser?.materialApprovalUsers?.step3ApprovalUserId || null,
      },
    });

    return () => {
      res();
    };
  }, [
    newUser,
    goAnotherPage,
    isCancel,
    dispatch,
    setLpApproverPermission,
    setMaterialApproverpermission,
    initialNewUser,
    handleAccountChangeCancel,
    isCancel,
    navigate,
    nextPagePath,
    setInitialNewUser,
    location.pathname,
  ]);

  useEffect(() => {
    if (goAnotherPage) {
      if (isCancel) {
        handleAccountChangeCancel();
      }
      navigate.navigate(nextPagePath);
    }
  }, [
    goAnotherPage,
    handleAccountChangeCancel,
    isCancel,
    navigate,
    nextPagePath,
  ]);

  const errorRef = useRef({
    ...validationError,
  });

  useEffect(() => {
    if (
      !newUser?.lpApprovalUsers ||
      !newUser.materialApprovalUsers ||
      !lpApprover ||
      !materialApprover
    )
      return;

    const error: ValidationError = {
      ...errorRef.current,
    };

    if (setLpApproverPermission) {
      // eslint-disable-next-line  no-restricted-syntax
      for (const [key, value] of Object.entries(newUser.lpApprovalUsers)) {
        if (typeof value === 'number') {
          const user = lpApprover.find((approver) => approver.userId === value);
          const step = key.replace(/[^0-9]/g, '');
          if (!user && value) {
            switch (step) {
              case '1':
                error.step1LpApprovalUserId = noApprovalAuthUserMsg;
                break;
              case '2':
                error.step2LpApprovalUserId = noApprovalAuthUserMsg;
                break;
              case '3':
                error.step3LpApprovalUserId = noApprovalAuthUserMsg;
                break;

              default:
                break;
            }
          }
        }
      }
    }

    if (setMaterialApproverpermission) {
      // eslint-disable-next-line  no-restricted-syntax
      for (const [key, value] of Object.entries(
        newUser.materialApprovalUsers,
      )) {
        if (typeof value === 'number') {
          const user = materialApprover.find(
            (approver) => approver.userId === value,
          );
          const step = key.replace(/[^0-9]/g, '');
          if (!user && value) {
            switch (step) {
              case '1':
                error.step1MaterialApprovalUserId = noApprovalAuthUserMsg;
                break;
              case '2':
                error.step2MaterialApprovalUserId = noApprovalAuthUserMsg;
                break;
              case '3':
                error.step3MaterialApprovalUserId = noApprovalAuthUserMsg;
                break;

              default:
                break;
            }
          }
        }
      }
    }

    setValidationError(error);

    // }, [newUser, lpApprover, materialApprover]);
    // }, [newUser]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  }, [
    newUser,
    lpApprover,
    materialApprover,
    setLpApproverPermission,
    setMaterialApproverpermission,
    setValidationError,
  ]);
  // 無限ループ

  const handleClickOpen = () => {
    setFileDialogOpen(true);
  };

  const handleDeleteImage = () => {
    if (!newUser) return;
    const image = newUser.profileImage
      ? ({
          profileImageId: undefined,
          profileImageData: undefined,
          url: undefined,
        } as ProfileImageRegisterRequest)
      : undefined;

    dispatch(
      setUserInfoReq({
        ...newUser,
        profileImage: image,
      }),
    );
  };

  const setFileData = async (file: File) => {
    await fileToBase64(file).then((res) => {
      const imageData = {
        profileImageId: newUser?.profileImage
          ? newUser?.profileImage.profileImageId
          : undefined,
        profileImageData: res as string,
        url: undefined,
      };
      dispatch(
        setUserInfoReq({
          ...newUser,
          profileImage: imageData,
        }),
      );
    });
  };

  const handleFileSubmit = async (files: File[]) => {
    if (!files.length || !files[0]) return;

    await setFileData(files[0]);
    setFileDialogOpen(false);
  };

  const onFileUploadDialog = () => {
    setFileDialogOpen(false);
  };

  const setDisplayName = (text: string) => {
    dispatch(
      setUserInfoReq({
        ...newUser,
        displayName: text,
      }),
    );
  };

  const setDeptName = (text: string) => {
    dispatch(
      setUserInfoReq({
        ...newUser,
        deptName: text,
      }),
    );
  };

  const subStringNameAndMail = (text: string) => {
    const splittedText = text.split(/[（）]/).filter((e) => e);

    const mail = splittedText.find((e) => PATTERN.exec(e));
    const name = mail ? text.split(`（${mail}）`)[0] : text;

    return {
      name,
      mail,
    };
  };

  const handleChangeLpApprover = (index: number, text: string) => {
    if (!lpApprover || !newUser) return;
    const res = subStringNameAndMail(text);
    let approver: AdminUser[] = [];
    if (typeof res === 'string') {
      approver = lpApprover.filter(
        (approver) => approver.userName === res || approver.mailAddress === res,
      );
    } else {
      approver = lpApprover.filter(
        (approver) =>
          approver.userName === res.name || approver.mailAddress === res.mail,
      );
    }
    if (!approver[0]) return;
    const approvalUsers = newUser.lpApprovalUsers
      ? newUser.lpApprovalUsers
      : {
          step1ApprovalUserId: undefined,
          step2ApprovalUserId: undefined,
          step3ApprovalUserId: undefined,
        };
    switch (index) {
      case 1:
        approvalUsers.step1ApprovalUserId = approver[0].userId;
        setLpApprovalUserName({
          ...LpApprovalUsersName,
          step1ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step1LpApprovalUserId: undefined,
        });
        break;
      case 2:
        approvalUsers.step2ApprovalUserId = approver[0].userId;
        setLpApprovalUserName({
          ...LpApprovalUsersName,
          step2ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step2LpApprovalUserId: undefined,
        });
        break;
      case 3:
        approvalUsers.step3ApprovalUserId = approver[0].userId;
        setLpApprovalUserName({
          ...LpApprovalUsersName,
          step3ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step3LpApprovalUserId: undefined,
        });
        break;
      default:
        break;
    }
    dispatch(
      setUserInfoReq({
        ...newUser,
        lpApprovalUsers: approvalUsers,
      }),
    );
  };

  const handleChangeMaterialApprover = (index: number, text: string) => {
    if (!materialApprover || !newUser) return;
    const res = subStringNameAndMail(text);
    let approver: AdminUser[] = [];
    if (typeof res === 'string') {
      approver = materialApprover.filter(
        (approver) => approver.userName === res || approver.mailAddress === res,
      );
    } else {
      approver = materialApprover.filter(
        (approver) =>
          approver.userName === res.name || approver.mailAddress === res.mail,
      );
    }

    if (!approver[0]) return;
    const approvalUsers = newUser.materialApprovalUsers
      ? newUser.materialApprovalUsers
      : {
          step1ApprovalUserId: undefined,
          step2ApprovalUserId: undefined,
          step3ApprovalUserId: undefined,
        };
    switch (index) {
      case 1:
        approvalUsers.step1ApprovalUserId = approver[0].userId;
        setMaterialApprovalUsersName({
          ...MaterialApprovalUsersName,
          step1ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step1MaterialApprovalUserId: undefined,
        });
        break;
      case 2:
        approvalUsers.step2ApprovalUserId = approver[0].userId;
        setMaterialApprovalUsersName({
          ...MaterialApprovalUsersName,
          step2ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step2MaterialApprovalUserId: undefined,
        });
        break;
      case 3:
        approvalUsers.step3ApprovalUserId = approver[0].userId;
        setMaterialApprovalUsersName({
          ...MaterialApprovalUsersName,
          step3ApprovalUserName: undefined,
        });
        setValidationError({
          ...validationError,
          step3MaterialApprovalUserId: undefined,
        });
        break;
      default:
        break;
    }

    dispatch(
      setUserInfoReq({
        ...newUser,
        materialApprovalUsers: approvalUsers,
      }),
    );
  };

  const handleDeleteLpApprover = (index: number) => {
    if (!newUser) return;

    const approvalUsers = newUser.lpApprovalUsers;
    if (!approvalUsers) return;
    switch (index) {
      case 1:
        approvalUsers.step1ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step1LpApprovalUserId: undefined,
        });
        break;
      case 2:
        approvalUsers.step2ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step2LpApprovalUserId: undefined,
        });
        break;
      case 3:
        approvalUsers.step3ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step3LpApprovalUserId: undefined,
        });
        break;
      default:
        break;
    }

    dispatch(
      setUserInfoReq({
        ...newUser,
        lpApprovalUsers: approvalUsers,
      }),
    );
  };

  const handleDeleteMaterialApprover = (index: number) => {
    if (!newUser) return;

    const approvalUsers = newUser.materialApprovalUsers;
    if (!approvalUsers) return;
    switch (index) {
      case 1:
        approvalUsers.step1ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step1MaterialApprovalUserId: undefined,
        });
        break;
      case 2:
        approvalUsers.step2ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step2MaterialApprovalUserId: undefined,
        });
        break;
      case 3:
        approvalUsers.step3ApprovalUserId = undefined;
        setValidationError({
          ...validationError,
          step3MaterialApprovalUserId: undefined,
        });
        break;
      default:
        break;
    }

    dispatch(
      setUserInfoReq({
        ...newUser,
        materialApprovalUsers: approvalUsers,
      }),
    );
  };

  const getUserlp = useCallback(
    (id: number | undefined) => {
      if (!lpApprover || !Number.isInteger(id)) return undefined;

      return lpApprover.find((approver) => approver.userId === id);
    },
    [lpApprover],
  );

  const getUser = useCallback(
    (id: number | undefined) => {
      if (!materialApprover || !Number.isInteger(id)) return undefined;

      return materialApprover.find((approver) => approver.userId === id);
    },
    [materialApprover],
  );

  const registerIfValidParams = (register: () => void) => {
    clearValidation();

    const validation: ValidationError = {
      step1LpApprovalUserId: undefined,
      step1MaterialApprovalUserId: undefined,
      displayName: undefined,
      deptName: undefined,
    };

    // 必須チェック

    // 表示名
    if (newUser?.displayName === '') {
      validation.displayName = '表示名を入力してください';
    }

    // STEP1 LP承認者
    if (LpApprovalUsersName && LpApprovalUsersName.step1ApprovalUserName) {
      validation.step1LpApprovalUserId = '有効な値を入力してください';
    } else if (
      !newUser ||
      (setLpApproverPermission &&
        !newUser?.lpApprovalUsers?.step1ApprovalUserId)
    ) {
      validation.step1LpApprovalUserId = '一次承認者は必須です';
    }
    // STEP1 具材承認者
    if (
      MaterialApprovalUsersName &&
      MaterialApprovalUsersName.step1ApprovalUserName
    ) {
      validation.step1MaterialApprovalUserId = '有効な値を入力してください';
    } else if (
      !newUser ||
      (setMaterialApproverpermission &&
        !newUser?.materialApprovalUsers?.step1ApprovalUserId)
    ) {
      validation.step1MaterialApprovalUserId = '一次承認者は必須です';
    }

    // STEP2・STEP3 LP承認者 有効値チェック
    if (LpApprovalUsersName) {
      if (LpApprovalUsersName.step2ApprovalUserName)
        validation.step2LpApprovalUserId = '有効な値を入力してください';
      if (LpApprovalUsersName.step3ApprovalUserName)
        validation.step3LpApprovalUserId = '有効な値を入力してください';
    }

    if (MaterialApprovalUsersName) {
      if (MaterialApprovalUsersName.step2ApprovalUserName)
        validation.step2MaterialApprovalUserId = '有効な値を入力してください';
      if (MaterialApprovalUsersName.step3ApprovalUserName)
        validation.step3MaterialApprovalUserId = '有効な値を入力してください';
    }

    if (
      validation.step1LpApprovalUserId ||
      validation.step2LpApprovalUserId ||
      validation.step3LpApprovalUserId ||
      validation.step1MaterialApprovalUserId ||
      validation.step2MaterialApprovalUserId ||
      validation.step3MaterialApprovalUserId ||
      validation.displayName ||
      validation.deptName
    ) {
      setValidationError(validation);
    } else {
      register();
      setInitialNewUser(undefined);
      setSuccessDialogOpen(true);
    }
  };

  const register = () => {
    dispatch(
      setUserInfoReq({
        ...newUser,
      }),
    );
    if (
      validationError?.step1LpApprovalUserId === noApprovalAuthUserMsg ||
      validationError?.step2LpApprovalUserId === noApprovalAuthUserMsg ||
      validationError?.step3LpApprovalUserId === noApprovalAuthUserMsg ||
      validationError?.step1MaterialApprovalUserId === noApprovalAuthUserMsg ||
      validationError?.step2MaterialApprovalUserId === noApprovalAuthUserMsg ||
      validationError?.step3MaterialApprovalUserId === noApprovalAuthUserMsg
    ) {
      setErrorDialogOpen(true);
    } else {
      registerIfValidParams(() => handleRegister());
    }
  };

  const userImage = useMemo(() => {
    if (!newUser?.profileImage) return undefined;

    if (newUser.profileImage.profileImageData)
      return newUser.profileImage.profileImageData;

    if (newUser.profileImage.url) return newUser.profileImage.url;

    return '';
  }, [newUser]);

  const hasValidation = useMemo(() => {
    if (!validationError) return false;
    if (
      validationError.step1LpApprovalUserId ||
      validationError.step2LpApprovalUserId ||
      validationError.step3LpApprovalUserId ||
      validationError.step1MaterialApprovalUserId ||
      validationError.step2MaterialApprovalUserId ||
      validationError.step3MaterialApprovalUserId ||
      validationError.displayName ||
      validationError.deptName
    ) {
      return true;
    }

    return false;
  }, [validationError]);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: validationError,
      })}
    >
      {hasValidation && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}
      <FileUploadDialog
        handleSubmit={handleFileSubmit}
        onClose={onFileUploadDialog}
        open={fileDialogOpen}
        isProduct={false}
        fileType="image"
      />
      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          setSuccessDialogOpen(false);
          setInitialNewUser(newUser || undefined);
        }}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={confirmDialogOpen}
        title="アカウント設定画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setConfirmDialogOpen(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setConfirmDialogOpen(false);
        }}
      />
      <ErrorDialog
        open={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        message={['権限のないユーザーが含まれています。削除してください']}
      />
      <Content title="アカウント設定">
        <Box>
          <Box className={classes.profilePhoto}>
            <HeadlineLabel text="プロフィール写真" />
            <Box className={classes.uploadBox}>
              {userImage ? (
                <img src={userImage} className={classes.image} />
              ) : (
                <UserIcon className={classes.userIcon} />
              )}
              <Box className={classes.butonBox}>
                <Box className={classes.downloadBtn}>
                  <PrimaryButton
                    text="画像をアップロード"
                    icon={<UploadIcon />}
                    click={() => handleClickOpen()}
                  />
                </Box>
                <Box className={classes.dlttext}>
                  <ButtonLink
                    text="写真を削除"
                    click={() => handleDeleteImage()}
                    disabled={!userImage}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.name}>
            <HeadlineLabel text="表示名" require />
            {/* <Typography className={classes.instText}> */}
            {/* </Typography> */}
            <Box mt={3}>
              <OneLineTextField
                label="表示名を入力"
                value={newUser?.displayName}
                handleChangeText={(text) => {
                  setDisplayName(text);
                }}
                error={validationError?.displayName}
              />
            </Box>
            <Box className={classes.helpText}>
              SALAD-BAR内 で利用するコンテンツに反映されます。
            </Box>
          </Box>
          <Box className={classes.department}>
            <HeadlineLabel text="部署名" />
            <Box mt={3}>
              <OneLineTextField
                label="部署名を入力"
                value={newUser?.deptName}
                handleChangeText={(text) => {
                  setDeptName(text);
                }}
                error={validationError?.deptName}
              />
            </Box>
          </Box>
          {setLpApproverPermission && (
            <Box className={classes.lpApprover}>
              <HeadlineLabel text="ランディングページ承認者設定" />
              <Typography className={classes.instText}>
                一次承認者<span className={classes.impText}>＊必須</span>
              </Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.lpApprovalUsers?.step1ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step1ApprovalUserName ||
                        ''
                      }
                      email={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step1MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteLpApprover(1)}
                      unauthorized={
                        !getUserlp(
                          newUser?.lpApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step1LpApprovalUserId && (
                      <ErrorText
                        error={validationError.step1LpApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setLpApprovalUserName({
                          ...LpApprovalUsersName,
                          step1ApprovalUserName: text,
                        });
                        handleChangeLpApprover(1, text);
                      }}
                      mode="TEXT"
                      placeholder="一次承認者名を入力"
                      options={
                        lpApprover
                          ? lpApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      handleChangeText={(text) =>
                        handleChangeLpApprover(1, text)
                      }
                      error={validationError?.step1LpApprovalUserId}
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
              <Typography className={classes.instText}>二次承認者</Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.lpApprovalUsers?.step2ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step2ApprovalUserName ||
                        ''
                      }
                      email={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step2MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteLpApprover(2)}
                      unauthorized={
                        !getUserlp(
                          newUser?.lpApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step2LpApprovalUserId && (
                      <ErrorText
                        error={validationError.step2LpApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setLpApprovalUserName({
                          ...LpApprovalUsersName,
                          step2ApprovalUserName: text,
                        });
                        handleChangeLpApprover(2, text);
                      }}
                      mode="TEXT"
                      placeholder="二次承認者名を入力"
                      options={
                        lpApprover
                          ? lpApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      error={validationError?.step2LpApprovalUserId}
                      handleChangeText={(text) =>
                        handleChangeLpApprover(2, text)
                      }
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
              <Typography className={classes.instText}>三次承認者</Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.lpApprovalUsers?.step3ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step3ApprovalUserName ||
                        ''
                      }
                      email={
                        getUserlp(
                          newUser?.lpApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.lpApprovalUsers?.step3MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteLpApprover(3)}
                      unauthorized={
                        !getUserlp(
                          newUser?.lpApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step3LpApprovalUserId && (
                      <ErrorText
                        error={validationError.step3LpApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setLpApprovalUserName({
                          ...LpApprovalUsersName,
                          step3ApprovalUserName: text,
                        });
                        handleChangeLpApprover(3, text);
                      }}
                      mode="TEXT"
                      placeholder="三次承認者名を入力"
                      options={
                        lpApprover
                          ? lpApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      error={validationError?.step3LpApprovalUserId}
                      handleChangeText={(text) =>
                        handleChangeLpApprover(3, text)
                      }
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {setMaterialApproverpermission && (
            <Box className={classes.ingredientApprover}>
              <HeadlineLabel text="具材承認者設定" />
              <Typography className={classes.instText}>
                一次承認者<span className={classes.impText}>＊必須</span>
              </Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.materialApprovalUsers?.step1ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUser(
                          newUser?.materialApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step1DisplayName ||
                        ''
                      }
                      email={
                        getUser(
                          newUser?.materialApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step1MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteMaterialApprover(1)}
                      unauthorized={
                        !getUserlp(
                          newUser?.materialApprovalUsers?.step1ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step1MaterialApprovalUserId && (
                      <ErrorText
                        error={validationError.step1MaterialApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setMaterialApprovalUsersName({
                          ...MaterialApprovalUsersName,
                          step1ApprovalUserName: text,
                        });
                        handleChangeMaterialApprover(1, text);
                      }}
                      mode="TEXT"
                      placeholder="一次承認者名を入力"
                      options={
                        materialApprover
                          ? materialApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      handleChangeText={(text) =>
                        handleChangeMaterialApprover(1, text)
                      }
                      error={validationError?.step1MaterialApprovalUserId}
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
              <Typography className={classes.instText}>二次承認者</Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.materialApprovalUsers?.step2ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUser(
                          newUser?.materialApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step2DisplayName ||
                        ''
                      }
                      email={
                        getUser(
                          newUser?.materialApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step2MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteMaterialApprover(2)}
                      unauthorized={
                        !getUserlp(
                          newUser?.materialApprovalUsers?.step2ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step2MaterialApprovalUserId && (
                      <ErrorText
                        error={validationError.step2MaterialApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setMaterialApprovalUsersName({
                          ...MaterialApprovalUsersName,
                          step2ApprovalUserName: text,
                        });
                        handleChangeMaterialApprover(2, text);
                      }}
                      mode="TEXT"
                      placeholder="二次承認者名を入力"
                      options={
                        materialApprover
                          ? materialApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      handleChangeText={(text) =>
                        handleChangeMaterialApprover(2, text)
                      }
                      error={validationError?.step2MaterialApprovalUserId}
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
              <Typography className={classes.instText}>三次承認者</Typography>
              <Box mt={1}>
                {Number.isInteger(
                  newUser?.materialApprovalUsers?.step3ApprovalUserId,
                ) ? (
                  <>
                    <AccountSettingApprover
                      name={
                        getUser(
                          newUser?.materialApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.userName ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step3DisplayName ||
                        ''
                      }
                      email={
                        getUser(
                          newUser?.materialApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.mailAddress ||
                        // 存在しない承認者
                        newUser?.materialApprovalUsers?.step3MailAddress ||
                        ''
                      }
                      handleDelete={() => handleDeleteMaterialApprover(3)}
                      unauthorized={
                        !getUserlp(
                          newUser?.materialApprovalUsers?.step3ApprovalUserId ||
                            undefined,
                        )?.userName
                      }
                    />
                    {validationError?.step3MaterialApprovalUserId && (
                      <ErrorText
                        error={validationError.step3MaterialApprovalUserId}
                      />
                    )}
                  </>
                ) : (
                  <Box className={classes.approver}>
                    <CommonComboBox
                      onChange={(text) => {
                        setMaterialApprovalUsersName({
                          ...MaterialApprovalUsersName,
                          step3ApprovalUserName: text,
                        });
                        handleChangeMaterialApprover(3, text);
                      }}
                      mode="TEXT"
                      placeholder="三次承認者名を入力"
                      options={
                        materialApprover
                          ? materialApprover.map((ele) =>
                              ele.mailAddress
                                ? `${ele.userName}（${ele.mailAddress}）`
                                : `${ele.userName}`,
                            )
                          : []
                      }
                      handleChangeText={(text) =>
                        handleChangeMaterialApprover(3, text)
                      }
                      error={validationError?.step3MaterialApprovalUserId}
                      maxLength={100}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {(userInfo?.lpApprover || userInfo?.lpAuthor) && (
            <Box className={classes.emailNotification}>
              <HeadlineLabel text="メール通知設定" />

              {userInfo?.lpApprover && (
                <Box className={classes.switch}>
                  <CommonTextSwitch
                    handleChange={(checked) => {
                      dispatch(
                        setUserInfoReq({
                          ...newUser,
                          lpNoticeApply: checked,
                        }),
                      );
                    }}
                    checked={newUser?.lpNoticeApply}
                    checkedText="有効"
                    uncheckedText="無効"
                  />
                  <Typography
                    className={classes.instText}
                    style={{ margin: '0px 16px' }}
                  >
                    ランディングページ承認申請の通知
                  </Typography>
                </Box>
              )}

              {userInfo?.lpAuthor && (
                <Box className={classes.switch}>
                  <CommonTextSwitch
                    handleChange={(checked) => {
                      dispatch(
                        setUserInfoReq({
                          ...newUser,
                          lpNoticeRemand: checked,
                        }),
                      );
                    }}
                    checked={newUser?.lpNoticeRemand}
                    checkedText="有効"
                    uncheckedText="無効"
                  />
                  <Typography
                    className={classes.instText}
                    style={{ margin: '0px 16px' }}
                  >
                    ランディングページ承認申請差戻しの通知
                  </Typography>
                </Box>
              )}

              {userInfo?.lpAuthor && (
                <Box className={classes.switch}>
                  <CommonTextSwitch
                    handleChange={(checked) => {
                      dispatch(
                        setUserInfoReq({
                          ...newUser,
                          lpNoticeApproved: checked,
                        }),
                      );
                    }}
                    checked={newUser?.lpNoticeApproved}
                    checkedText="有効"
                    uncheckedText="無効"
                  />
                  <Typography
                    className={classes.instText}
                    style={{ margin: '0px 16px' }}
                  >
                    ランディングページ承認申請完了の通知
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {/*  キャンセル/決定ボタン */}
          <Box className={classes.button}>
            <Box className={classes.cancelBtn}>
              <SecondaryButton
                text="キャンセル"
                click={() => {
                  setIsCancel(true);
                  navigate.navigate('/adm/dashboard');
                }}
              />
            </Box>
            <Box className={classes.cancelBtn}>
              <PrimaryButton
                text="保存"
                // disabled={
                //   validationError?.step1LpApprovalUserId !== undefined ||
                //   validationError?.step2LpApprovalUserId !== undefined ||
                //   validationError?.step3LpApprovalUserId !== undefined ||
                //   validationError?.step1MaterialApprovalUserId !== undefined ||
                //   validationError?.step2MaterialApprovalUserId !== undefined ||
                //   validationError?.step3MaterialApprovalUserId !== undefined ||
                //   validationError?.displayName !== undefined ||
                //   validationError?.deptName !== undefined
                // }
                click={() => register()}
              />
            </Box>
          </Box>
        </Box>
      </Content>
    </Box>
  );
};
export default Account;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

/**
 * Style
 */

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

/**
 * Interface
 */

interface CenterAlignmentTemplateProps {
  children: React.ReactNode
}

export default function CenterAlignmentTemplate(props: CenterAlignmentTemplateProps) {
  const classes = useStyles({})

  return <div className={classes.root}>{props.children}</div>
}
/* eslint-enable */

import { Usages } from '../domain/dashboard';

const initialState = null;

export const createInitialState = (): Array<Usages> | null => initialState;

const SET_USAGES_HISOTRY = 'usages-history/set' as const;
const CLEAR_USAGE_HISTORY = 'usages-history/clear' as const;

export const setUsagesHistory = (data: Array<Usages>) => ({
  type: SET_USAGES_HISOTRY,
  payload: {
    data,
  },
});
export const clearUsagesHistory = () => ({
  type: CLEAR_USAGE_HISTORY,
});
type Action = ReturnType<typeof setUsagesHistory | typeof clearUsagesHistory>;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USAGES_HISOTRY:
      return action.payload.data;
    case CLEAR_USAGE_HISTORY:
      return initialState;
    default:
      return state;
  }
};

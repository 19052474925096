/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Tooltip, Typography } from '../../atoms'
import { CloseIcon } from '../../../assets/images'

/**
 * Interface
 */
export interface AccountSettingApproverProps{
  name?: string
  email?: string
  handleDelete?: () => void
  unauthorized?: boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    selectDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectBtn: {
      height: '36px',
      border: '1px solid #2925251E',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    sBText: {
      color: 'sBText',
      fontSize: '14px',
      marginTop: -1,
      maxWidth: 292,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    sBTextUnauthorized: {
      color: 'red',
      fontSize: '14px',
      marginTop: -1,
      maxWidth: 292,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    helpText: {
      fontSize: '12px',
      color: '#82827E'
    },
    helpTextUnauthorized: {
      fontSize: '12px',
      color: 'red'
    },
    close: {
      height: '14px',
      width: '14px',
      marginLeft: '7px'
    }
  })
)

export default function AccountSettingApprover(props: AccountSettingApproverProps) {

  const classes = useStyles({})
  const { name, email, handleDelete, unauthorized } = props

  return (
    <Box className={classes.root}>
      <Box className={classes.selectDiv}>
        <Box className={classes.selectBtn}>
          <Tooltip title={name && name || ''} arrow placement="top">
            <Typography
              className={unauthorized ? classes.sBTextUnauthorized : classes.sBText}
            >{name && name}</Typography>
          </Tooltip>
          <CloseIcon className={classes.close} onClick={handleDelete}/>
        </Box>
        <Box
          className={unauthorized ? classes.helpTextUnauthorized : classes.helpText}
          style={{marginLeft: '8px'}}
        >{email}</Box>
      </Box>
    </Box>
  )
}
/* eslint-enable */

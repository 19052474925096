/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useMemo, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Box, Divider, List, PaginationBar } from 'components/atoms'
import { ApplyHistoryListItem } from 'components/molecules'
import { MaterialWorkflow } from 'core/domain/material'
import { CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE, CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL } from 'constants/code'

/**
 * Interface
 */
interface ApplyHistoryListProps {
  exampleWorkflows: Array<MaterialWorkflow>
  pagination?: boolean
}

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '10px'
    },
    divider: {
      marginTop: '16px',
      marginBottom: '16px'
    }
  }),
)

interface ApplyItem {
  date: string
  status: number
  message?: string
  name?: string
}

export default function ApplyHistoryList(props: ApplyHistoryListProps) {
  const classes = useStyles({})
  const { exampleWorkflows, pagination } = props
  const [currentWorkflowPage, setCurrentWorkflowPage] = useState<number>(1)

  const applyHistory = useMemo(() => {
    const history: Array<ApplyItem> = []
    exampleWorkflows.map((workflow) => {
      if (workflow.step2ApprovalDate && workflow.step2Status === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL && workflow.step3ApprovalUserId) {
        const step3 = {
          date: workflow.step3ApprovalDate,
          status: workflow.step3Status,
          message: workflow.step3Message,
          name: workflow.step3ApprovalUserName
        }
        history.push(step3)
      }

      if (workflow.step1ApprovalDate && workflow.step1Status === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL && workflow.step2ApprovalUserId) {
        const step2 = {
          date: workflow.step2ApprovalDate,
          status: workflow.step2Status,
          message: workflow.step2Message,
          name: workflow.step2ApprovalUserName
        }
        history.push(step2)
      }

      const step1 = {
        date: workflow.step1ApprovalDate,
        status: workflow.step1Status,
        message: workflow.step1Message,
        name: workflow.step1ApprovalUserName
      }
      history.push(step1)

      if (workflow.workflowMessage) {
        const step0 = {
          date: workflow.workflowStartDate,
          status: CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE,
          message: workflow.workflowMessage,
          name: ''
        }
        history.push(step0)
      }
    })

    return history
  }, [exampleWorkflows])

  const displayApplyHistory = useMemo(() => {
    if (!pagination) return applyHistory
    const index = (currentWorkflowPage - 1) * 4

    return applyHistory.slice(index, index + 4)

  }, [applyHistory, currentWorkflowPage])

  const handleChangeWorkflowPage = (page: number) => {
    setCurrentWorkflowPage(page)
  }

  return (
    <div>
      <List>
        {displayApplyHistory.map((history, index) => (
            <div key={index}>
              <ApplyHistoryListItem
                date={history.date}
                status={history.status}
                message={history.message}
                name={history.name}
              />
              {index + 1 !== applyHistory.length && <Divider className={classes.divider}/>}
            </div>
            ))
        }
      </List>
      {pagination && <Box mt={5}>
        <PaginationBar
          currentPage={currentWorkflowPage}
          maxPage={Math.ceil(applyHistory.length / 4)}
          handleChangePage={(page: number) => handleChangeWorkflowPage(page)}
        />
      </Box>}
    </div>
  )
}
/* eslint-enable */

import { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modules, Usecases } from 'core';
import ProductDetail from 'components/pages/ProductDetail';
import { clearProductDetail } from 'core/modules/productDetail';
import { clearProductWorkflows } from 'core/modules/productWorkflows';
import { clearProductExamples } from 'core/modules/productExamples';
import { convertProductDetailToLpView } from 'libs/converter';
import { hooks } from 'libs';
import { CODE_MODE_REFERENCE } from 'constants/code';

/**
 * Presenter
 */
const ProductDetailPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { productId } = useParams<{ productId: string }>();

  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
  );
  const productWorkflows = useSelector(
    (state: Modules.AppState) => state.productWorkflows,
  );
  const productExamples = useSelector(
    (state: Modules.AppState) => state.productExamples,
  );
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;

  // 設定値取得LPプレビュー用
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
    dispatch(
      Usecases.settings.sendGetInquiriesGreetText(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetInquiryForms(token, userInfo.tenantId, 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // アンマウント時storeの値をクリアする
  useEffect(
    () => () => {
      dispatch(clearProductDetail());
      dispatch(clearProductWorkflows());
      dispatch(clearProductExamples());
    },
    [dispatch],
  );

  // 商材詳細/商材ワークフロー/商材導入事例取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductWorkflows(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
      ),
    );
    dispatch(
      Usecases.products.sendGetProductExamples(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
      ),
    );
  }, [dispatch, productId, token, userInfo]);

  /**
   * 商材オススメ更新
   */
  const handleRecommendedUpdate = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // awaitが無いと、画面が更新されなくなる.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductRecommended(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        !productDetail.recommended,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, productDetail, productId, token, userInfo]);

  /**
   * 商材ワークフローステータス更新
   */
  const handleWorkflowStatusUpdate = useCallback(
    async (step: number, status: number, message?: string) => {
      if (
        !productWorkflows ||
        !productWorkflows ||
        productWorkflows.length === 0 ||
        !userInfo
      )
        return;
      const workflow = productWorkflows[0];
      // awaitが無いと、画面が更新されなくなる.
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        Usecases.products.sendPatchProductWorkflowStatus(
          token,
          userInfo.tenantId,
          Number.parseInt(productId, 10),
          workflow.workflowId,
          step,
          status,
          message,
        ),
      );
      dispatch(
        Usecases.products.sendGetProductDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(productId, 10),
          CODE_MODE_REFERENCE,
        ),
      );
    },
    [dispatch, productId, productWorkflows, token, userInfo],
  );

  /**
   * 商材お気に入り更新
   */
  const handleClickFavorite = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // awaitが無いと、画面が更新されなくなる.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductFavorite(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        !productDetail.favorite,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, productDetail, productId, token, userInfo]);

  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
  );
  const lpSettings = useSelector((state: Modules.AppState) => state.lpSettings);

  /**
   * LPプレビュー用データ変換
   */
  const previewData = useMemo(() => {
    if (!productDetail || !productExamples) return undefined;

    return {
      ...convertProductDetailToLpView(
        productDetail,
        productExamples,
        lpSettings,
        inquiriesGreetText,
        inquiryForms,
      ),
      isPreview: true,
    };
  }, [
    inquiriesGreetText,
    inquiryForms,
    lpSettings,
    productDetail,
    productExamples,
  ]);

  /**
   * 商材ゴミ箱移動
   */
  const handleDelete = async () => {
    if (!userInfo || !productId) return;
    // awaitが無いと、画面が更新されなくなる.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPostProductTrash(
        token,
        userInfo.tenantId,
        productId,
      ),
    );
    navigate.navigatePop();
  };

  /**
   * 商材公開停止
   */
  const handleStop = () => {
    if (!userInfo || !productId) return;
    dispatch(
      Usecases.products.sendPostProductStop(
        token,
        userInfo.tenantId,
        productId,
      ),
    );
  };

  /**
   * 商材公開再開
   */
  const handleReopen = () => {
    if (!userInfo || !productId) return;
    dispatch(
      Usecases.products.sendPostProductReopen(
        token,
        userInfo.tenantId,
        productId,
      ),
    );
  };

  return (
    productDetail && (
      <ProductDetail
        productDetail={productDetail}
        productWorkflows={productWorkflows}
        productExamples={productExamples}
        handleRecommendedUpdate={handleRecommendedUpdate}
        handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
        handleClickFavorite={handleClickFavorite}
        previewData={previewData}
        handleDelete={handleDelete}
        handleStop={handleStop}
        handleReopen={handleReopen}
      />
    )
  );
};
export default ProductDetailPageContainer;

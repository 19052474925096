/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  LP_WORKFLOW_STATUS_AWAITING_APPROVAL,
  LP_WORKFLOW_STATUS_DECLINE,
  LP_WORKFLOW_STATUS_APPROVAL,
} from 'constants/text';
import {
  CODE_LP_LIST_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_LP_LIST_WORKFLOW_STATUS_DECLINE,
  CODE_LP_LIST_WORKFLOW_STATUS_APPROVAL,
} from 'constants/code';

/**
 * Interface
 */

export interface WorkflowStatusLabelProps {
  status: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    green: {
      backgroundColor: theme.palette.green[900],
      color: 'white',
    },
    blue: {
      backgroundColor: theme.palette.blue[900],
      color: 'white',
    },
    blueOutline: {
      borderColor: theme.palette.blue[900],
      color: theme.palette.blue[900],
    },
  }),
);

interface StatusType {
  status: number;
  label: string;
}

const STATUS_TYPES: Array<StatusType> = [
  {
    status: CODE_LP_LIST_WORKFLOW_STATUS_AWAITING_APPROVAL,
    label: LP_WORKFLOW_STATUS_AWAITING_APPROVAL,
  },
  {
    status: CODE_LP_LIST_WORKFLOW_STATUS_DECLINE,
    label: LP_WORKFLOW_STATUS_DECLINE,
  },
  {
    status: CODE_LP_LIST_WORKFLOW_STATUS_APPROVAL,
    label: LP_WORKFLOW_STATUS_APPROVAL,
  },
];

/**
 * Presenter
 */

export default function WorkflowStatusLabel(props: WorkflowStatusLabelProps) {
  const classes = useStyles({});
  const { status } = props;

  const decideClass = useMemo(() => {
    let tmpClass;
    switch (status) {
      case CODE_LP_LIST_WORKFLOW_STATUS_AWAITING_APPROVAL: {
        tmpClass = classes.blueOutline;
        break;
      }
      case CODE_LP_LIST_WORKFLOW_STATUS_DECLINE: {
        tmpClass = classes.green;
        break;
      }
      case CODE_LP_LIST_WORKFLOW_STATUS_APPROVAL: {
        tmpClass = classes.blue;
        break;
      }
    }

    return tmpClass;
  }, [status]);

  const statusLabel = useMemo(() => {
    const targetStatus = STATUS_TYPES.find((type) => type.status === status);
    if (!targetStatus) return undefined;

    return targetStatus.label;
  }, [status]);

  return statusLabel ? (
    <Typography
      className={`${classes.root} ${decideClass}`}
      variant="caption"
      display="block"
    >
      {statusLabel}
    </Typography>
  ) : (
    <></>
  );
} /* eslint-enable */

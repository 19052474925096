const COPYRIGHT = '© 2021 TOPPAN INC.';

/** Sidebar */
const SIDEBAR_MENU_DASHBOARD = 'ダッシュボード';
const SIDEBAR_MENU_CREATE_LPS = '新規ランディング\nページ作成';
const SIDEBAR_MENU_MY_LPS = 'わたしのランディング\nページ一覧';
const SIDEBAR_MENU_SUBORDINATES_LPS = '部下のランディング\nページ一覧';
const SIDEBAR_MENU_INQUIRIES = 'お問い合わせ一覧';
const SIDEBAR_MENU_NOTICES_LIST = 'お知らせ一覧';
const SIDEBAR_MENU_CUSTOMER = '顧客管理';
const SIDEBAR_MENU_ALL_LPS = 'みんなのランディング\nページ一覧';
const SIDEBAR_MENU_PRODUCTS = '商材一覧';
const SIDEBAR_MENU_PRODUCT_EXAMPLES = '導入事例一覧';
const SIDEBAR_MENU_MY_LIBRARY = 'マイライブラリ';
const SIDEBAR_IMAGE_CUT = '画像切り抜き';
const SIDEBAR_MENU_TRASHES = 'ゴミ箱';
const SIDEBAR_MENU_TENANT_DASHBOARD = '管理者用\nダッシュボード';
const SIDEBAR_MENU_NOTICES = 'お知らせ管理';
const SIDEBAR_MENU_USERS = 'ユーザー管理';
const SIDEBAR_MENU_INQUIRY_FORMS = 'お問い合わせフォーム\n管理';
const SIDEBAR_MENU_LPS = 'ランディングページ\n管理';
const SIDEBAR_MENU_SETTINGS = '各種設定';

/** 商材ステータス */
const PRODUCT_STATUS_DRAFT = '下書き';
const PRODUCT_STATUS_DECLINE = '差し戻し';
const PRODUCT_STATUS_AWAITING_APPROVAL = '申請中';
const PRODUCT_STATUS_APPROVAL = '承認済';
const PRODUCT_STATUS_RELEASED = '公開中';
const PRODUCT_STATUS_SUSPENSION = '公開一時停止';
const PRODUCT_STATUS_EXPIRE = '利用期間外';
const PRODUCT_STATUS_CLOSED = '公開終了';
const PRODUCT_STATUS_IN_TRASH = 'ゴミ箱';
const PRODUCT_STATUS_DELETED = '削除';

/** 素材ワークフローステータス */
const MATERIAL_WORKFLOW_STATUS_MESSAGE = '申し送り';
const MATERIAL_WORKFLOW_STATUS_DECLINE = '差し戻し';
const MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL = '申請中';
const MATERIAL_WORKFLOW_STATUS_APPROVAL = '承認';
const MATERIAL_WORKFLOW_STATUS_APPROVAL_COMPLETE = '承認済';

/** お問い合わせ種別 */
const INQUIRY_TYPE_TEL = '電話';
const INQUIRY_TYPE_MAIL = 'メール';
const INQUIRY_TYPE_OTHER = 'その他';
const INQUIRY_TYPE_INQUIRY_FORM = 'お問い合わせフォーム';

/** 通知種別 */
const NOTICE_TYPE_IMPORTANT = '重要';
const NOTICE_TYPE_COMMON = '通常';
const NOTICE_TYPE_OVERALL = 'サービスからのお知らせ';

/** 通知ステータス */
/** 1「掲載予約」2「掲載中」３「掲載終了」 */
const NOTICE_STATUS_SCHEDULED_DELIVERY = '掲載予約';
const NOTICE_STATUS_NOW_DELIVERY = '掲載中';
const NOTICE_STATUS_FINISHED_DELIVERY = '掲載終了';

/** 申請アラートメッセージ */
// TODO: 表示するメッセージが決定したら追加していく
const ALERT_ERROR =
  '申請が差し戻されました。差し戻し理由を確認の上、修正してください。';
const ALERT_APPROVAL =
  'ランディングページの承認がおりました。内容を確認の上、公開・メール配信を実行してください。';

/** 申請ダイアログメッセージ */
const DIALOG_NO_APPROVAL_USER =
  '一次承認者が未設定です。\nアカウント設定画面にて、承認者を設定してから申請してください。';

/** 通常編集モードエラーメッセージ */
const NORMAL_EDIT_HEADER_OVER_CHAR = '見出しは100文字以内で入力してください。';
const NORMAL_EDIT_HEADER_NOT_ENTERED = '見出しを入力してください。';
const NORMAL_EDIT_MAIN_IMAGE_NOT_SELECTED =
  'メインビジュアルを選択してください。';
const NORMAL_EDIT_TOKUISAKI_TITLE_NOT_SELECTED = '敬称を選択してください。';
const NORMAL_EDIT_BEGINNING_TEXT_NOT_ENTERED =
  '商材案内の冒頭文を入力してください。';
const NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR =
  '商材案内の冒頭文は100文字以内で入力してください。';
const NORMAL_EDIT_GREET_TEXT_NOT_ENTERED = '挨拶文を入力してください。';
const NORMAL_EDIT_GREET_TEXT_OVER_CHAR =
  '挨拶文は1000文字以内で入力してください。';
const NORMAL_EDIT_TANTO_COMPANY_NOT_SELECTED =
  '担当者会社名を選択してください。';
const NORMAL_EDIT_PAGE_TITLE_NOT_ENTERED =
  'ランディングページのタブに表示されるタイトルを入力してください。';
const NORMAL_EDIT_PAGE_TITLE_OVER_CHAR =
  'ランディングページのタブに表示されるタイトルは100文字以内で入力してください。';
const NORMAL_EDIT_TOKUISAKI_NAME_NOT_ENTERED =
  '表示用得意先名を入力してください。';
const NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR =
  '表示用得意先名は100文字以内で入力してください。';
const NORMAL_EDIT_TANTO_NAME_NOT_ENTERED = '表示用送信者名を入力してください。';
const NORMAL_EDIT_TANTO_NAME_OVER_CHAR =
  '表示用送信者名は100文字以内で入力してください。';
const NORMAL_EDIT_TITLE_NOT_ENTERED = '管理用タイトルを入力してください。';
const NORMAL_EDIT_TITLE_OVER_CHAR =
  '管理用タイトルを入力は100文字以内で入力してください。';
const NORMAL_EDIT_LP_TAG_OVER_CHAR =
  'タグは、タグ1つにつき100文字以内、全体で5000文字以内で入力してください。';
const NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED =
  '一言コメントを入力してください。';
const NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR =
  '一言コメントは5000文字以内で入力してください。';
const NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED = '営業写真を選択してください。';

// 公開設定
const NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED =
  'メールタイトルを入力してください。';
const NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR =
  'メールタイトルは500文字以内で入力してください。';
const NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED = 'メール本文を入力してください。';
const NORMAL_EDIT_MAIL_TEXT_OVER_CHAR =
  'メール本文は5000文字以内で入力してください。';
const NORMAL_EDIT_PASSWORD_NOT_ENTERED = 'パスワードを入力してください。';
const NORMAL_EDIT_PASSWORD_OVER_CHAR =
  'パスワードは100文字以内で入力してください。';
const NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED =
  'パスワードは8文字以上半角英数字で入力してください';
const NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR =
  'メール署名は5000文字以内で入力してください。';

/** LP注意書き */
const LP_OPEN_PERIOD_CAUNTION =
  '予約した公開終了日時を過ぎると、ランディングページの公開が終了します。\n公開終了後は編集できなくなりますのでご注意ください。';

/**
 * 共通エラーメッセージ
 */
const INVALID_DATE = '日付の形式で入力してください。';
const DATE_NOT_ENTERED = '日付が未入力です。';
const TIME_NOT_ENTERED = '時間が未入力です。';
const PAST_DATE_SELECTED = '過去の日時です。未来の日時を指定してください。';

/** 対応状況ステータス */
const SUPPORT_STATUS_NEW = '未対応';
const SUPPORT_STATUS_REPLIED = '返信済';
const SUPPORT_STATUS_IN_TELEPHONE_READY = '電話対応済';
const SUPPORT_STATUS_SUPPORTING = '対応中';
const SUPPORT_STATUS_DOCUMENT_SENDED = '資料送付済';
const SUPPORT_STATUS_APPOINTMENT_SET = 'アポ設定済';
const SUPPORT_STATUS_OTHERS = 'その他';

/** 未読状況ステータス */
const NOTICE_UNREAD = '未読';
const NOTICE_READED = '既読';

/** LP公開ステータス */
const LP_OPEN_STATUS_DRAFT = '下書き';
const LP_OPEN_STATUS_RELEASED = '公開中';
const LP_OPEN_STATUS_SUSPENSION = '公開一時停止';
const LP_OPEN_STATUS_END = '公開終了';
const LP_OPEN_STATUS_TRASH = 'ゴミ箱';
const LP_OPEN_STATUS_OTHER = '-';

/** LPワークフローステータス */
const LP_WORKFLOW_STATUS_DECLINE = '差し戻し';
const LP_WORKFLOW_STATUS_AWAITING_APPROVAL = '申請中';
const LP_WORKFLOW_STATUS_APPROVAL = '承認済';
const LP_WORKFLOW_STATUS_OTHER = '-';

/** 商材・導入事例注意書き */
const END_OF_OPEN_CAUTION =
  '公開終了の通知について\n' +
  '　具材が公開終了になるとランディングページ作成者へ公開終了が通知されます。\n' +
  '　・通知対象：この具材をランディングページに入れたランディングページ作成者\n' +
  '　・通知日：公開終了日の4日前\n' +
  '　※その他、特別な対応が必要な場合は「お知らせ」に掲載するなどで告知してください。';

/** 不達メール警告文 */
const CUSTOMERS_LIST_LABEL =
  '過去の送信で不達となったメールアドレスは\n赤文字で表示されます。\n' +
  '確認または削除してください。';
const MAIL_SENDER_LABEL =
  '過去の送信で不達となったメールアドレスは赤文字で表示されます。顧客一覧ページにて確認または削除してください。';

export {
  COPYRIGHT,
  SIDEBAR_MENU_DASHBOARD,
  SIDEBAR_MENU_CREATE_LPS,
  SIDEBAR_MENU_MY_LPS,
  SIDEBAR_MENU_SUBORDINATES_LPS,
  SIDEBAR_MENU_INQUIRIES,
  SIDEBAR_MENU_NOTICES_LIST,
  SIDEBAR_MENU_CUSTOMER,
  SIDEBAR_MENU_ALL_LPS,
  SIDEBAR_MENU_PRODUCTS,
  SIDEBAR_MENU_PRODUCT_EXAMPLES,
  SIDEBAR_MENU_MY_LIBRARY,
  SIDEBAR_IMAGE_CUT,
  SIDEBAR_MENU_TRASHES,
  SIDEBAR_MENU_TENANT_DASHBOARD,
  SIDEBAR_MENU_NOTICES,
  SIDEBAR_MENU_USERS,
  SIDEBAR_MENU_INQUIRY_FORMS,
  SIDEBAR_MENU_LPS,
  SIDEBAR_MENU_SETTINGS,
  PRODUCT_STATUS_DRAFT,
  PRODUCT_STATUS_DECLINE,
  PRODUCT_STATUS_AWAITING_APPROVAL,
  PRODUCT_STATUS_APPROVAL,
  PRODUCT_STATUS_RELEASED,
  PRODUCT_STATUS_SUSPENSION,
  PRODUCT_STATUS_EXPIRE,
  PRODUCT_STATUS_CLOSED,
  PRODUCT_STATUS_IN_TRASH,
  PRODUCT_STATUS_DELETED,
  ALERT_ERROR,
  ALERT_APPROVAL,
  DIALOG_NO_APPROVAL_USER,
  MATERIAL_WORKFLOW_STATUS_MESSAGE,
  MATERIAL_WORKFLOW_STATUS_DECLINE,
  MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL,
  MATERIAL_WORKFLOW_STATUS_APPROVAL,
  MATERIAL_WORKFLOW_STATUS_APPROVAL_COMPLETE,
  INQUIRY_TYPE_TEL,
  INQUIRY_TYPE_MAIL,
  INQUIRY_TYPE_OTHER,
  INQUIRY_TYPE_INQUIRY_FORM,
  SUPPORT_STATUS_NEW,
  SUPPORT_STATUS_REPLIED,
  SUPPORT_STATUS_IN_TELEPHONE_READY,
  SUPPORT_STATUS_SUPPORTING,
  SUPPORT_STATUS_DOCUMENT_SENDED,
  SUPPORT_STATUS_APPOINTMENT_SET,
  SUPPORT_STATUS_OTHERS,
  LP_OPEN_STATUS_DRAFT,
  LP_OPEN_STATUS_END,
  LP_OPEN_STATUS_OTHER,
  LP_OPEN_STATUS_RELEASED,
  LP_OPEN_STATUS_SUSPENSION,
  LP_OPEN_STATUS_TRASH,
  LP_WORKFLOW_STATUS_DECLINE,
  LP_WORKFLOW_STATUS_APPROVAL,
  LP_WORKFLOW_STATUS_AWAITING_APPROVAL,
  LP_WORKFLOW_STATUS_OTHER,
  NORMAL_EDIT_BEGINNING_TEXT_NOT_ENTERED,
  NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR,
  NORMAL_EDIT_HEADER_NOT_ENTERED,
  NORMAL_EDIT_HEADER_OVER_CHAR,
  NORMAL_EDIT_GREET_TEXT_NOT_ENTERED,
  NORMAL_EDIT_LP_TAG_OVER_CHAR,
  NORMAL_EDIT_MAIN_IMAGE_NOT_SELECTED,
  NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED,
  NORMAL_EDIT_GREET_TEXT_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED,
  NORMAL_EDIT_TANTO_COMPANY_NOT_SELECTED,
  NORMAL_EDIT_TANTO_NAME_NOT_ENTERED,
  NORMAL_EDIT_TANTO_NAME_OVER_CHAR,
  NORMAL_EDIT_PAGE_TITLE_NOT_ENTERED,
  NORMAL_EDIT_PAGE_TITLE_OVER_CHAR,
  NORMAL_EDIT_TITLE_NOT_ENTERED,
  NORMAL_EDIT_TITLE_OVER_CHAR,
  NORMAL_EDIT_TOKUISAKI_NAME_NOT_ENTERED,
  NORMAL_EDIT_TOKUISAKI_TITLE_NOT_SELECTED,
  NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR,
  DATE_NOT_ENTERED,
  PAST_DATE_SELECTED,
  INVALID_DATE,
  TIME_NOT_ENTERED,
  LP_OPEN_PERIOD_CAUNTION,
  NOTICE_UNREAD,
  NOTICE_READED,
  NOTICE_TYPE_IMPORTANT,
  NOTICE_TYPE_COMMON,
  NOTICE_TYPE_OVERALL,
  NOTICE_STATUS_SCHEDULED_DELIVERY,
  NOTICE_STATUS_NOW_DELIVERY,
  NOTICE_STATUS_FINISHED_DELIVERY,
  NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR,
  NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_TEXT_OVER_CHAR,
  NORMAL_EDIT_PASSWORD_NOT_ENTERED,
  NORMAL_EDIT_PASSWORD_OVER_CHAR,
  NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR,
  NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED,
  END_OF_OPEN_CAUTION,
  CUSTOMERS_LIST_LABEL,
  MAIL_SENDER_LABEL,
};

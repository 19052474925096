/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useRef, useState } from 'react'
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Box, IconButton, TextField, Typography } from 'components/atoms'
import { SALAD_BAR_DESKTOP_FONT_SIZE_75, SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography'
import { COLORS } from 'constants/index'
import { PasswordHiddenIcon, PasswordShowIcon, ErrorIcon } from 'assets/images'
import clsx from 'clsx'

/**
 * Interface
 */

export interface PasswordTextFieldProps {
  value?: string
  handleChangeText?: (text: string) => void
  error?: string
  disabled?:boolean
}

/**
* Style
*/

export const theme = createMuiTheme({
  overrides: {
    // For Container
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&:hover $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&$focused $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        height: 36
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_900,
        }
      }
    },
  },
})


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      '&, .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.green[700]
      },
    },
    input: {
      height: 20,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      padding:
        `${theme.spacing(1)}px 
        35px 
        ${theme.spacing(1)}px 
        ${theme.spacing(2)}px`,
      '&:focus:not($disabled):not($error)': {
        backgroundColor: theme.palette.green[700]
      },
      // MS Edge独自の目玉アイコン非表示
      '&::-ms-reveal': {
        display: 'none',
      },
    },
    errorRoot: {
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        backgroundColor: theme.palette.red[800]
      },
    },
    errorInput: {
      color: theme.palette.red[900],
      '&:focus': {
        backgroundColor: theme.palette.red[800]
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900]
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    field: {
      position: 'relative',
      margin: 0,
    },
    icon: {
      top: 6,
      right: theme.spacing(1),
      position: 'absolute',
      padding: 2,
    },
  }),
)

/**
* Presenter
*/

export default function PasswordTextField(props: PasswordTextFieldProps) {
  const { value, handleChangeText, error, disabled } = props
  const classes = useStyles({})
  const inputRef = useRef()
  const [showPassword, setShowPassword] = useState(false)

  // FIXME:6月はメールのパスワード設定は範囲外のため非活性
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.field}>
        <TextField
          disabled={disabled}
          fullWidth
          color="primary"
          type={showPassword ? 'text':'password'}
          inputProps={{
            className: classes.input,
            maxLength: 100,
            minLength: 8,
            pattern: "^([a-zA-Z0-9]{8,})$",
          }}
          className={clsx(classes.root, {
            [classes.errorRoot]: !!error
          })}
          inputRef={inputRef}
          defaultValue=""
          variant="outlined"
          placeholder="パスワードを入力"
          value={value}
          onChange={(event) => handleChangeText && handleChangeText(event.target.value)}
        />
        <IconButton className={classes.icon} onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon/>}
        </IconButton>
      </Box>
      {error && <Box className={classes.error}>
        <ErrorIcon />
        <Typography className={classes.errorText}>{error}</Typography>
      </Box>}
    </ThemeProvider>
  )
}/* eslint-enable */

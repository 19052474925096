/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { TokuisakiTitles } from 'core/domain/settings'

const initialState = null

export function createInitialState(): TokuisakiTitles | null {
  return initialState
}

export const SET_TOKUISAKI_TITLES = 'tokuisaki-titles/set' as const
export const CLEAR_TOKUISAKI_TITLES = 'tokuisaki-titles/clear' as const

export function setTokuisakiTitles(data: TokuisakiTitles) {
  return {
    type: SET_TOKUISAKI_TITLES,
    payload: {
      data,
    },
  }
}

export function clearTokuisakiTitles() {
  return {
    type: CLEAR_TOKUISAKI_TITLES,
  }
}

type SetTokuisakiTitlesAction = ReturnType<typeof setTokuisakiTitles>
type ClearTokuisakiTitlesAction = ReturnType<typeof clearTokuisakiTitles>
type Action = SetTokuisakiTitlesAction | ClearTokuisakiTitlesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TOKUISAKI_TITLES:
      return action.payload.data
    case CLEAR_TOKUISAKI_TITLES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Box, MandatoryLabel, SubHeadlineLabel } from 'components/atoms'

/**
 * Interface
 */
export interface MandatoryHeadlineLabelProp {
  title: string
}

/**
 * Presenter
 */
export default function MandatoryHeadlineLabel(props: MandatoryHeadlineLabelProp) {

  const { title } = props

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <SubHeadlineLabel text={title} />
      <MandatoryLabel margin="24px 0 4px 8px" />
    </Box>
  )
}/* eslint-enable */

import { LpsAdmin } from "core/domain/lp";

const initialState:LpsAdmin = {
    "lps": [
      
    ],
    "totalCount": 0
  }
export const createInitialState= ():LpsAdmin  => initialState

export const SET_LPS_ADMIN = 'lps-admin/set' as const
export const CLEAR_LPS_ADMIN = 'lps-admin' as const

export const setLpsAdmin = (data:LpsAdmin) => ({
    type: SET_LPS_ADMIN,
    payload:{
        data
    }
})

export const clearLpsAdmin = () => ({
    type: CLEAR_LPS_ADMIN
})

type SetLpsAdminAction = ReturnType<typeof setLpsAdmin>
type ClearLpsAdminAction = ReturnType<typeof clearLpsAdmin>
type Action = SetLpsAdminAction | ClearLpsAdminAction

export const reducer = (state = createInitialState(), action: Action) => {
    switch (action.type) {
        case SET_LPS_ADMIN:
          return action.payload.data
        case CLEAR_LPS_ADMIN:
          return initialState
        default:
          return state
    }
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  ApplyStatusLabel,
  Typography,
  Tooltip,
} from 'components/atoms';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Examples, ExamplesQueryParameter } from 'core/domain/example';
import { formatDateTimeSecISO8601 } from 'libs/date';
import clsx from 'clsx';

/**
 * Interface
 */
export interface ExamplesTableProps {
  clickExample?: (id: number) => void;
  examples: Examples | null;
  handleChangeSortKey: (sortKey: string) => void;
  exampleQueryParameter: ExamplesQueryParameter | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    status: {
      width: 96,
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    customer: {
      width: 100,
    },
    product: {
      width: 300,
    },
    contact: {
      width: 150,
    },
    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'ステータス',
    sortKey: 'status',
  },
  {
    title: '事例ID',
    sortKey: 'exampleMaterialId',
  },
  {
    title: '事例名称',
    sortKey: 'exampleName',
  },
  {
    title: '導入お客様名',
    sortKey: 'customerName',
  },
  {
    title: '関連する商材',
    sortKey: 'relatedProduct',
  },
  {
    title: '利用されている\nランディングページ数',
    sortKey: 'useLpCount',
  },
  {
    title: 'お問い合わせ先',
    sortKey: 'contact',
  },
  {
    title: '最終更新日時',
    sortKey: 'updatedDate',
  },
];

const ExamplesTable = (props: ExamplesTableProps) => {
  const classes = useStyles({});
  const { clickExample, examples, handleChangeSortKey, exampleQueryParameter } =
    props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active
                  direction={exampleQueryParameter?.sort_order}
                  IconComponent={() =>
                    item.sortKey === exampleQueryParameter?.sort_key &&
                    exampleQueryParameter?.sort_order === 'asc' ? (
                      <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <SortDownIcon />
                    )
                  }
                  onClick={() => handleChangeSortKey(item.sortKey)}
                >
                  {item.title}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {examples &&
          examples.examples.map((example, index) => (
            <TableBody key={index} className={classes.body}>
              <TableRow>
                <TableCell>
                  <Box className={classes.status}>
                    <ApplyStatusLabel status={example.exampleStatus} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={example.exampleMaterialId}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {example.exampleMaterialId}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.link}>
                  <ButtonLink
                    text={example.exampleName}
                    tooltipText={example.exampleName}
                    click={() =>
                      clickExample && clickExample(example.exampleMaterialId)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={example.customerName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography
                      className={clsx(classes.customer, classes.overflow)}
                      variant="body2"
                    >
                      {example.customerName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {example.relatedProduct ? (
                    <Tooltip
                      title={example.relatedProduct || ''}
                      arrow
                      placement="top"
                    >
                      <Typography
                        className={clsx(classes.product, classes.overflow)}
                        variant="body2"
                      >
                        {example.relatedProduct}
                      </Typography>
                    </Tooltip>
                  ) : (
                    example.relatedProduct
                  )}
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={example.exampleReaction?.useLpCount.toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {example.exampleReaction?.useLpCount.toLocaleString()}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={example.contact || ''} arrow placement="top">
                    <Typography
                      className={clsx(classes.contact, classes.overflow)}
                      variant="body2"
                    >
                      {example.contact}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {formatDateTimeSecISO8601(example.updatedDate).replace(
                    ' ',
                    '\n',
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>
    </TableContainer>
  );
};

export default ExamplesTable;

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Typography,
  SearchTextField,
  AdditionalTag,
  PaginationBar,
  PrimaryButton,
  SecondaryButton,
  Grid,
  QuaternaryButton,
  ManagerTag,
} from 'components/atoms';
import {
  Content,
  LPsAdminTable,
  LpAdminDialog,
  LpManagerSelectDialog,
  LpAdminChangePageDialog,
  ErrorDialog,
} from 'components/organisms';
import { hooks } from 'libs';
import {
  // SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import {
  LpAdmin,
  lpAdminError,
  LpsAdmin,
  LpSearchCondition,
  LpTags,
} from 'core/domain/lp';
import { RefreshIcon, ErrorIcon, DastIcon } from 'assets/images';
import { UserInfoDetail } from 'core/domain/user';
import { Modules, Usecases } from 'core';
import { setLpAdminError } from 'core/modules/lpAdminError';
import { clearUserTags, setUserTags } from 'core/modules/userTags';
import { setLpSearchCondition } from 'core/modules/lpSearchCondition';

export interface LPsPageProps {
  lps: LpsAdmin | null;
  rows: number;
  tags: LpTags | null;
  lpSearchCondition: LpSearchCondition | null;
  error: lpAdminError | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeLpTantoUser: (users: Array<UserInfoDetail>) => void;
  handleLoadCondition: () => void;
  handleCloseErrorDialog: () => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.gray[800],
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    greenIcon: {
      '& g path': {
        fill: theme.palette.green[900],
      },
    },
  }),
);

/**
 * Presenter
 */
const LPsPage = (props: LPsPageProps) => {
  const {
    lps,
    rows,
    lpSearchCondition,
    handleChangePage,
    handleChangeSortKey,
    handleChangeFreeWord,
    handleChangeLpTantoUser,
    handleLoadCondition,
    handleCloseErrorDialog,
    error,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles({});
  const { navigate } = hooks.useNavigate();
  const { setCurrentLpsUrl } = hooks.useLpsNavigation();
  const [selectedManager, setSelectedManager] = useState<UserInfoDetail | null>(
    null,
  );
  const [lpsAdmin, setLpsAdmin] = useState<LpsAdmin>({
    lps: [],
    totalCount: 0,
  });
  const [isOpen, setIsopen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<number>(0);
  // １：担当者変更２：絞り込み
  const [selectManagerType, setSelectManagerType] = useState<number>(1);
  const [checkedLps, setCheckedLps] = useState<Array<LpAdmin>>([]);
  const [isManagerSelect, setManagerSelect] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [openPageDialog, setOpenPageDialog] = useState<boolean>(false);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const [headerChecked, setHeaderChecked] = useState<boolean>(false);
  const token = hooks.useAuth().accessToken;
  // 選択中の担当者表示用state
  const userTags = useSelector((state: Modules.AppState) => state.userTags);
  const [selectedUser, setSelectedUser] = useState(['']);
  // 担当者絞り込み用
  const [selectedUsers, setSelectedUsers] = useState<Array<UserInfoDetail>>([]);
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector((state: Modules.AppState) => state.freeWord);

  // 2レコード存在する同IdのLPを区別するためidとopenstatusで判断する
  const handleChecked = (id: number, val: boolean, status: number) => {
    if (!lps) return;
    let newData = checkedLps.slice();
    if (val) {
      const target = lps.lps.filter(
        (item) => item.lpId === id && item.openStatus === status,
      )[0];
      newData.push(target);
    } else {
      newData = newData.filter((item) => item.lpId !== id);
      // ヘッダーすべて選択のチェックOFF
      if (headerChecked) {
        setHeaderChecked(false);
      }
    }

    setCheckedLps(newData);
  };

  const handleChangePublic = () => {
    // 公開中以外選択されていたらtrueになる
    const chkError = checkedLps
      .map((checkedLps) => {
        if (checkedLps.openStatus !== 5) {
          return true;
        }

        return false;
      })
      .includes(true);

    if (chkError) {
      // 公開中以外があり
      dispatch(
        setLpAdminError({
          error: [
            '「公開中」以外のランディングページが含まれています。選択しなおしてください。',
          ],
          title: '入力内容にエラーが存在します。',
        }),
      );

      // 更新後にチェックを解除
      setCheckedLps([]);
      setHeaderChecked(false);
      // 更新後の値で一覧を更新
      handleLoadCondition();
    } else {
      setDialogType(0);
      setIsopen(true);
    }
  };

  const handleDeleteLps = () => {
    // 下書き、差し戻し、承認済、公開終了以外選択されていたらtrueになる
    // openStatus 公開ステータス（1：下書き, 5：公開中, 6：公開一時停止, 8：公開終了, 98：ゴミ箱, 99：その他（画面上は"-"表示））
    // workflowStatus ワークフローステータス（2：差し戻し, 3：承認中, 4：承認完了, 99：その他（画面上は"-"表示））

    const chkError = checkedLps
      .map((checkedLps) => {
        if (
          checkedLps.openStatus !== 1 && // 下書き
          checkedLps.openStatus !== 2 &&
          checkedLps.openStatus !== 8 && // 公開終了
          checkedLps.workflowStatus !== 1 &&
          checkedLps.workflowStatus !== 2 && // 差し戻し
          checkedLps.workflowStatus !== 4 // 承認完了(承認済)
        ) {
          return true;
        }

        return false;
      })
      .includes(true);

    if (chkError) {
      //  下書き、差し戻し、承認済、公開終了以外あり
      dispatch(
        setLpAdminError({
          error: [
            '「下書き」「差し戻し」「承認済」「公開終了」以外のランディングページが含まれています。',
            '選択しなおしてください。',
          ],
          title: '入力内容にエラーが存在します。',
        }),
      );

      // 更新後にチェックを解除
      setCheckedLps([]);
      setHeaderChecked(false);
      // 更新後の値で一覧を更新
      handleLoadCondition();
    } else {
      setDialogType(1);
      setIsopen(true);
    }
  };

  const handleSelectManager = (type: number) => {
    setSelectManagerType(type);
    setManagerSelect(true);
  };

  const handleCloseDialog = () => {
    setIsopen(false);
  };

  const closeManagerSelect = () => {
    setManagerSelect(false);
  };

  const submitLpUserManager = (data: Array<UserInfoDetail>) => {
    if (selectManagerType === 2) {
      setManagerSelect(false);
      // setSelectedManager(data);
      handleChangeLpTantoUser(data);
      // 担当者絞り込み用
      const userNameData = data.map((data) => data.userName);
      dispatch(setUserTags({ userTags: userNameData }));
      // 絞り込み中ユーザ保持
      setSelectedUsers(data);
    } else {
      // 担当者変更
      setManagerSelect(false);
      setSelectedManager(data[0]);
      setDialogType(2);
      setIsopen(true);
    }
  };

  useEffect(() => {
    if (!lps) return;
    setLpsAdmin(lps);
  }, [lps]);

  const updateDispProcess = () => {
    // handleSubmitLpsDialogから処理を外出し

    // 更新後の一覧を取得
    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetLpsAdmin(token, userInfo.tenantId, lpSearchCondition),
    );

    // 更新後の値で一覧を更新
    handleLoadCondition();
    // 更新後にチェックを解除
    setCheckedLps([]);
    setHeaderChecked(false);
  };

  const handleSubmitLpsDialog = () => {
    if (!userInfo) return;
    const lpIds = checkedLps.map((item) => item.lpId);

    if (dialogType === 0) {
      dispatch(Usecases.lps.sendPostStopLps(token, userInfo.tenantId, lpIds));
      updateDispProcess();
    }

    if (dialogType === 1) {
      dispatch(
        Usecases.lps.sendPostDeleteLps(
          token,
          userInfo.tenantId,
          lpIds,
          updateDispProcess,
        ),
      );
    }

    if (dialogType === 2) {
      if (!selectedManager) return;
      const tantoId = selectedManager.userId;
      dispatch(
        Usecases.lps.PostChangeTantoLps(
          token,
          userInfo.tenantId,
          lpIds,
          tantoId,
          updateDispProcess,
        ),
      );
    }

    // ボタン押下後、ダイアログクローズ
    setIsopen(false);
  };

  const allCheck = (val: boolean) => {
    if (val) {
      setHeaderChecked(true);
      setCheckedLps(lpsAdmin.lps);
    } else {
      setHeaderChecked(false);
      setCheckedLps([]);
    }
  };

  const changePage = () => {
    setOpenPageDialog(false);
    setCheckedLps([]);
    allCheck(false);
    handleChangePage(page);
  };

  const changePageFromPagenation = (page: number) => {
    setOpenPageDialog(false);
    setCheckedLps([]);
    allCheck(false);
    handleChangePage(page);
  };

  const handleClose = () => {
    setOpenPageDialog(false);
  };

  const checkedSelect = () => {
    if (checkedLps.map((item) => item.lpId).length) {
      return true;
    }

    return false;
  };

  // 担当者絞り込み表示
  useEffect(() => {
    // 一覧に戻ったときに担当者絞り込みしていたら表示する
    if (!lpSearchCondition?.lp_tanto_user_ids) {
      // 絞り込みしていなかったら担当者の表示をクリア
      dispatch(clearUserTags());
      setSelectedUser([]);
      setSelectedUsers([]);
    }

    if (!userTags?.userTags) return;

    setSelectedUser(userTags.userTags.map((userTags) => userTags));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpSearchCondition?.lp_tanto_user_ids, userTags, dispatch]);

  useEffect(() => {
    // 検索時、チェックをクリア
    setHeaderChecked(false);
    setCheckedLps([]);

    // 担当者絞り込まれていなければ担当者チェッククリア
    if (!lpSearchCondition) setSelectedUsers([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpSearchCondition]);

  const deleteTag = (val: string) => {
    if (!lpSearchCondition) {
      return;
    }

    if (!lpSearchCondition?.lp_tanto_user_ids || !userTags) {
      dispatch(clearUserTags());
      setSelectedUser([]);

      return;
    }

    const userIdData = selectedUsers.find(
      (data) => data.userName.toString() === val,
    );
    // バツをクリックしたユーザを除去
    const newUsers = selectedUsers.filter(
      (data: UserInfoDetail) => data !== userIdData,
    );
    // 絞り込み中ユーザ更新
    setSelectedUsers(newUsers);
    // タグ表示更新
    dispatch(
      setUserTags({
        userTags: newUsers.map((a: UserInfoDetail) => a.userName),
      }),
    );

    if (!userIdData) return;
    // 検索リクエストパラメータのタグ設定
    const newTags = lpSearchCondition?.lp_tanto_user_ids
      .split(',')
      .filter((data: string) => data !== userIdData.userId.toString())
      .join(',');

    dispatch(
      setLpSearchCondition({
        ...lpSearchCondition,
        lp_tanto_user_ids: newTags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  return (
    <Box mt={5}>
      <ErrorDialog
        open={!!error}
        title={error?.title}
        message={error?.error}
        handleClose={handleCloseErrorDialog}
      />
      <LpAdminChangePageDialog
        open={openPageDialog}
        handleSubmit={changePage}
        handleClose={handleClose}
      />
      <LpAdminDialog
        open={isOpen}
        handleCancel={handleCloseDialog}
        handleSubmit={handleSubmitLpsDialog}
        checkedLps={checkedLps}
        dialogType={dialogType}
        manager={selectedManager?.userName || null}
      />
      <LpManagerSelectDialog
        open={isManagerSelect}
        handleCancel={closeManagerSelect}
        selectManager={submitLpUserManager}
        dialogType={selectManagerType}
        selectedUsers={selectedUsers}
      />
      <Content title="ランディングページ管理">
        <div>
          <Box className={classes.section}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box display="flex">
                  <Box className={classes.freeWord}>
                    <SearchTextField
                      label="検索ワードを入力"
                      handleChangeText={handleChangeFreeWord}
                      value={
                        lpSearchCondition?.free_word
                          ? lpSearchCondition.free_word
                          : ''
                      }
                    />
                  </Box>
                  <Box className={classes.description}>
                    登録されている情報全体からフリーワードで検索します。
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <PrimaryButton
                  disabled={!checkedLps.length}
                  text="担当者を変更する"
                  click={() => handleSelectManager(1)}
                  icon={<RefreshIcon />}
                />
              </Grid>
              <Grid item xs={2}>
                <QuaternaryButton
                  disabled={!checkedLps.length}
                  text="公開を停止する"
                  click={handleChangePublic}
                  icon={<ErrorIcon />}
                />
              </Grid>
              <Grid item xs={2}>
                <SecondaryButton
                  disabled={!checkedLps.length}
                  text="ゴミ箱に移動する"
                  click={handleDeleteLps}
                  icon={<DastIcon className={classes.greenIcon} />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Typography
              className={clsx([classes.title, classes.tagTitle])}
              variant="overline"
            >
              担当者絞り込み
            </Typography>
            <Box>
              {/* 担当者を表示 */}
              {selectedUser.map((tag, index) => (
                <ManagerTag
                  item={tag}
                  key={index}
                  deleteTag={(val: string) => {
                    deleteTag(val);
                  }}
                />
              ))}
              <AdditionalTag click={() => handleSelectManager(2)} />
            </Box>
          </Box>
          <Box className={classes.table}>
            <LPsAdminTable
              allCheck={(val: boolean) => allCheck(val)}
              checkedLps={checkedLps}
              lps={lpsAdmin}
              handleChecked={handleChecked}
              clickLp={(id: number, approved: boolean) => {
                // eslint-disable-next-line no-unused-expressions
                !approved
                  ? navigate(`/adm/lps/${id}`)
                  : navigate(`/adm/lps/${id}/approved`);
                setCurrentLpsUrl();
              }}
              handleChangeSortKey={handleChangeSortKey}
              lpSearchCondition={lpSearchCondition}
              headerChecked={headerChecked}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={lpSearchCondition ? lpSearchCondition.page : 1}
                maxPage={lps ? Math.ceil(lps?.totalCount / rows) : 1}
                handleChangePage={(page: number) => {
                  setPage(page);
                  if (checkedSelect()) setOpenPageDialog(true);
                  else changePageFromPagenation(page);
                }}
                totalCount={lps?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
    </Box>
  );
};

export default LPsPage;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import Routes from 'Routes';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles/';
import { Theme } from 'styles';
import { Store } from 'core';
import { LoadingPanel } from 'components/atoms';

import { AuthProvider } from 'contexts/AuthContext';

// FIXME: Auth0のプロパティは環境変数化
const authCredential = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  namespace: 'https://localhost:3000',
};

function App() {
  return (
    <Provider store={Store.store}>
      <ThemeProvider theme={Theme.saladbar}>
        <LoadingPanel />
        <AuthProvider value={authCredential}>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
/* eslint-enable */

import React, { useEffect, useCallback, useState } from 'react';
import Dashboard from 'components/pages/Dashboard';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import { setLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';
import { clearDashboardAddTerm } from 'core/modules/dashboardAddTerm';

/**
 * Presenter
 */

const DashboardPageContainer = () => {
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const lpOwnSearchCondition = useSelector(
    (state: Modules.AppState) => state.lpOwnSearchCondition,
    shallowEqual,
  );
  // const addTerm = useSelector(
  //   (state: Modules.AppState) => state.dashboardAddTerm,
  //   shallowEqual,
  // );

  const [isOnce, setIsOnce] = useState<boolean>(false);

  const searchReaction = useCallback(
    (start: string, end: string, type: 'total' | 'ranking') => {
      if (!userInfo) return;
      dispatch(
        type === 'total'
          ? Usecases.dashboard.sendGetTotalReaction(
              token,
              userInfo.tenantId,
              start,
              end,
            )
          : type === 'ranking' &&
              Usecases.dashboard.sendGetReactionRanking(
                token,
                userInfo.tenantId,
                start,
                end,
              ),
      );
    },
    [userInfo, token, dispatch],
  );

  // LP一覧をするための条件初期設定
  useEffect(() => {
    if (!userInfo) return;
    if (!lpOwnSearchCondition) {
      dispatch(setLpOwnSearchCondition({ page: 1, row: undefined }));
    } 
    // else {
    //   dispatch(
    //     setLpOwnSearchCondition({
    //       ...lpOwnSearchCondition,
    //       row: undefined,
    //       sort_key: lpOwnSearchCondition.sort_key,
    //       sort_order: lpOwnSearchCondition.sort_order,
    //     }),
    //   );
    // }
  }, [lpOwnSearchCondition, dispatch, userInfo, token]);

  // LP一覧を取得

  useEffect(() => {
    if (!userInfo || !lpOwnSearchCondition || lpOwnSearchCondition.row) return;

    dispatch(
      Usecases.dashboard.sendGetDashboardLps(
        token,
        userInfo.tenantId,
        lpOwnSearchCondition,
      ),
    );
  }, [lpOwnSearchCondition, dispatch, userInfo, token]);

  // お知らせ一覧取得/承認状況件数/集計期間/累計リアクション件数/ランキング取得
  useEffect(() => {
    if (!userInfo || isOnce) return;
    dispatch(
      Usecases.notices.sendGetDashboardNotices(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.dashboard.sendGetApprovalStatus(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.dashboard.sendGetDashboardAddTerm(token, userInfo.tenantId),
    );
    
    setIsOnce(true);
    // eslint-disable-next-line consistent-return
  }, [userInfo, dispatch, token, isOnce]);

  useEffect(
    () =>
      // eslint-disable-next-line consistent-return
      () => {
        dispatch(clearDashboardAddTerm());
      },
    [dispatch],
  );

  return <Dashboard searchReaction={searchReaction} userInfo={userInfo} />;
};

export default DashboardPageContainer;

import React, { useEffect, useState } from 'react';
import {
  Box,
  CommonTextSwitch,
  HeadlineLabel,
  IconButton,
  SubHeadlineLabel,
  Typography,
} from 'components/atoms';
import { PreviewButton } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DesignTemplate, VariousSettings } from 'core/domain/variousSettings';
import {
  CircleArrowTopIcon,
  CircleArrowDownIcon,
  ErrorIcon,
} from 'assets/images';
import { LpView } from 'core/domain/lp';
import { convertInquiryToLpView } from 'libs/converter';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';

interface DesignTemplateItemProps {
  available: boolean;
  designTemplateId: number;
  displayOrder: number | null;
  handleAvailableChange: (templateId: number, checked: boolean) => void;
  handleOrderChange: (currOrder: number, type: 'up' | 'down') => void;
  isBottom: boolean;
  isTop: boolean;
  text: string;
}

interface VariousSettingsDesignTemplateAreaProps {
  designTemplate?: DesignTemplate[];
  error?: string;
  handleInputChange: (
    newDesignTemplates: DesignTemplate[],
    key: keyof VariousSettings,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        marginBottom: theme.spacing(3),
      },
    },
    itemRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'stretch',
      marginBottom: theme.spacing(3),
    },
    switchArea: {
      maxWidth: '80%',
      minWidth: '35%',
      '& > :first-child': {
        marginRight: theme.spacing(1),
      },
    },
    nameText: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    previewArea: {
      '& > :first-child': {
        marginRight: theme.spacing(1),
      },
      '& > :last-child': {
        marginLeft: theme.spacing(3),
      },
    },
    subLabelColor: {
      color: theme.palette.gray[800],
      wordBreak: 'keep-all',
    },
    sortBtn: {
      padding: 0,
      '&:first-child': {
        marginBottom: theme.spacing(1),
      },
      '&:disabled span svg g': {
        fill: theme.palette.gray[500],
        stroke: theme.palette.gray[400],
      },
      '&:disabled span svg path': {
        stroke: theme.palette.common.white,
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const DesignTemplateItem = (props: DesignTemplateItemProps) => {
  const {
    available,
    designTemplateId,
    displayOrder,
    handleAvailableChange,
    handleOrderChange,
    isBottom,
    isTop,
    text,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<LpView>();
  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
  );
  const lpSettings = useSelector((state: Modules.AppState) => state.lpSettings);

  const handleChange = () => {
    setChecked(!checked);
    handleAvailableChange(designTemplateId, !checked);
  };

  const handleUpBtn = () => {
    if (!displayOrder) return;
    handleOrderChange(displayOrder, 'up');
  };

  const handleDownBtn = () => {
    if (!displayOrder) return;
    handleOrderChange(displayOrder, 'down');
  };

  useEffect(() => {
    setChecked(available);
  }, [available]);

  useEffect(() => {
    setPreviewData(convertInquiryToLpView(lpSettings, inquiriesGreetText, inquiryForms));
  }, [lpSettings, inquiriesGreetText, inquiryForms]);

  return (
    <Box className={classes.itemRoot}>
      <Box className={classes.switchArea} display="flex" alignItems="center">
        <CommonTextSwitch
          checkedText="有効"
          uncheckedText="無効"
          checked={checked}
          handleChange={handleChange}
        />
        <Typography className={classes.nameText}>{text}</Typography>
      </Box>
      <Box className={classes.previewArea} display="flex" alignItems="center">
        <Typography className={classes.subLabelColor} variant="body2">
          プレビュー
        </Typography>
        <PreviewButton
          templateId={designTemplateId}
          previewData={previewData}
        />
        <Box display="flex" flexDirection="column">
          <IconButton
            className={classes.sortBtn}
            disabled={!available || isTop}
            onClick={handleUpBtn}
          >
            <CircleArrowTopIcon />
          </IconButton>
          <IconButton
            className={classes.sortBtn}
            disabled={!available || isBottom}
            onClick={handleDownBtn}
          >
            <CircleArrowDownIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const VariousSettingsDesignTemplateArea = (
  props: VariousSettingsDesignTemplateAreaProps,
) => {
  const { designTemplate, error, handleInputChange } = props;
  const classes = useStyles();
  const [availableDesignTemplateCount, setAvailableDesignTemplateCount] =
    useState<number>(0);

  const handleOrderChange = (currOrder: number, type: 'up' | 'down') => {
    const changedOrder = type === 'up' ? currOrder - 1 : currOrder + 1;
    if (!designTemplate) return;
    handleInputChange(
      designTemplate
        .map((template) => {
          if (template.displayOrder === currOrder) {
            return {
              ...template,
              displayOrder: changedOrder,
            };
          }
          if (template.displayOrder === changedOrder) {
            return {
              ...template,
              displayOrder: currOrder,
            };
          }

          return template;
        })
        .sort((a, b) => {
          if (!a.displayOrder) return 1;
          if (!b.displayOrder) return -1;

          return a.displayOrder - b.displayOrder;
        }),
      'designTemplate',
    );
  };

  const handleAvailableChange = (templateId: number, checked: boolean) => {
    if (!designTemplate) return;

    handleInputChange(
      designTemplate
        .map((template) => {
          if (template.designTemplateId !== templateId) return template;

          return {
            ...template,
            available: checked,
            displayOrder: checked ? availableDesignTemplateCount + 1 : null,
          };
        })
        // displayOrderをnullを考慮し、並べ替え
        .sort((a, b) => {
          if (!a.displayOrder) return 1;
          if (!b.displayOrder) return -1;

          return a.displayOrder - b.displayOrder;
        })
        // 並べ替え後、displayOrderを再取得
        .map((template, index) => {
          if (!template.available) return template;

          return {
            ...template,
            displayOrder: index + 1,
          };
        }),
      'designTemplate',
    );
  };

  useEffect(() => {
    if (!designTemplate) return;

    setAvailableDesignTemplateCount(
      designTemplate.filter((template) => template.available).length,
    );
  }, [designTemplate]);

  return (
    <Box className={classes.root} mt={8}>
      <HeadlineLabel text="デザインテンプレート設定" />
      {designTemplate ? (
        designTemplate.map((template) => (
          <DesignTemplateItem
            key={template.designTemplateId}
            available={template.available}
            designTemplateId={template.designTemplateId}
            displayOrder={template.displayOrder}
            handleAvailableChange={handleAvailableChange}
            handleOrderChange={handleOrderChange}
            isBottom={template.displayOrder === availableDesignTemplateCount}
            isTop={template.displayOrder === 1}
            text={template.designTemplateName}
          />
        ))
      ) : (
        <SubHeadlineLabel text="現在設定中のテンプレートはありません。" />
      )}
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default VariousSettingsDesignTemplateArea;

import React from 'react';
import { Box, HeadlineLabel, MandatoryLabel } from 'components/atoms';

interface MandatoryTitleLabelProps {
  mb?: boolean;
  title: string;
}

const MandatoryTitleLabel = (props: MandatoryTitleLabelProps) => {
  const { mb = false, title } = props;
  const margin = mb ? 2 : 0;

  return (
    <Box display="flex" alignItems="center" mb={margin}>
      <HeadlineLabel text={title} />
      <MandatoryLabel margin="0 0 0 8px" />
    </Box>
  );
};

export default MandatoryTitleLabel;

import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { clearNewExample, setNewExample } from 'core/modules/newExample';
import { MaterialImageRegisterRequest } from 'core/domain/material';
import ExampleRegisterEdit from 'components/pages/ExampleRegisterEdit';
import { clearExampleDetail } from 'core/modules/exampleDetail';
import { clearRelationProducts } from 'core/modules/relationProducts';
import { hooks } from 'libs';
import { convertNewExampleToLpView } from 'libs/converter';
import { CODE_MODE_EDIT } from 'constants/code';

/**
 * Presenter
 */

const ExampleRegisterEditPageContainer = () => {
  const dispatch = useDispatch();
  const { exampleId } = useParams<{ exampleId: string }>();
  const navigate = hooks.useNavigate();

  const exampleDetail = useSelector(
    (state: Modules.AppState) => state.exampleDetail,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const newExample = useSelector(
    (state: Modules.AppState) => state.newExample,
    shallowEqual,
  );
  const relationProducts = useSelector(
    (state: Modules.AppState) => state.relationProducts,
    shallowEqual,
  );

  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
    shallowEqual,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const lpSettings = useSelector(
    (state: Modules.AppState) => state.lpSettings,
    shallowEqual,
  );

  const token = hooks.useAuth().accessToken;

  // 導入事例詳細取得
  useEffect(() => {
    if (exampleId && userInfo) {
      dispatch(
        Usecases.examples.sendGetExampleDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(exampleId, 10),
          CODE_MODE_EDIT,
        ),
      );
    }

    return () => {
      dispatch(clearExampleDetail());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // 取得した導入事例詳細情報を登録用のデータに変換
  useEffect(() => {
    if (exampleDetail) {
      const productIds = exampleDetail.products
        ? exampleDetail.products.map((product) => product.productId)
        : [];
      const image = exampleDetail.exampleImage
        ? ({
            materialImageId: exampleDetail.exampleImage.materialImageId,
            materialImageType: exampleDetail.exampleImage.materialImageType,
            url: exampleDetail.exampleImage.url,
            thumbnailUrl: exampleDetail.exampleImage.thumbnailUrl,
          } as MaterialImageRegisterRequest)
        : undefined;

      dispatch(
        setNewExample({
          exampleName: exampleDetail.exampleName,
          customerName: exampleDetail.customerName,
          exampleContent: exampleDetail.exampleContent,
          contact: exampleDetail.contact,
          exampleProductIds: productIds,
          exampleImage: image,
          materialPeriod: exampleDetail.materialPeriod,
        }),
      );
    }

    return () => {
      dispatch(clearNewExample());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleDetail]);

  // 関連商材用に商材一覧取得
  useEffect(() => {
    if (!userInfo) return undefined;
    dispatch(Usecases.examples.sendGetProducts(token, userInfo.tenantId));

    return () => {
      dispatch(clearRelationProducts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  /**
   * 導入事例登録・更新処理
   */
  const handleExampleRegister = useCallback(
    async (draft: boolean) => {
      if (!newExample || !userInfo) return;

      if (exampleId) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await dispatch(
          Usecases.examples.sendPutExample(
            token,
            userInfo.tenantId,
            exampleId,
            newExample,
            draft,
          ),
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await dispatch(
          Usecases.examples.sendPostExample(
            token,
            userInfo.tenantId,
            newExample,
            draft,
          ),
        );
      }
      navigate.navigatePop();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newExample, exampleId, userInfo, error],
  );

  /**
   * LPプレビュー用データ変換
   */
  const previewData = useMemo(
    () => ({
      ...convertNewExampleToLpView(
        newExample,
        lpSettings,
        inquiriesGreetText,
        inquiryForms,
      ),
      isPreview: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newExample],
  );

  return (
    relationProducts && (
      <ExampleRegisterEdit
        newExample={newExample}
        relationProducts={relationProducts}
        handleExampleRegister={handleExampleRegister}
        exampleDetail={exampleDetail}
        previewData={previewData}
        error={error}
      />
    )
  );
};
export default ExampleRegisterEditPageContainer;

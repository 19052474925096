/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ExamplesQueryParameter } from 'core/domain/example'

const initialState = null

export function createInitialState(): ExamplesQueryParameter | null {
  return initialState
}

export const SET_EXAMPLE_QUERY_PARAMETER = 'example-query-parameter/set' as const
export const CLEAR_EXAMPLE_QUERY_PARAMETER = 'example-query-parameter/clear' as const

export function setExampleQueryParameter(data: ExamplesQueryParameter) {
  return {
    type: SET_EXAMPLE_QUERY_PARAMETER,
    payload: {
      data,
    },
  }
}

export function clearExampleQueryParameter() {
  return {
    type: CLEAR_EXAMPLE_QUERY_PARAMETER,
  }
}

type SetExampleQueryParameterAction = ReturnType<typeof setExampleQueryParameter>
type ClearExampleQueryParameterAction = ReturnType<typeof clearExampleQueryParameter>
type Action = SetExampleQueryParameterAction | ClearExampleQueryParameterAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_EXAMPLE_QUERY_PARAMETER:
      return action.payload.data
    case CLEAR_EXAMPLE_QUERY_PARAMETER:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

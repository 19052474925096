import { InquiryInfoRequest } from "core/domain/inquiries"

const initialState = null

export const createInitialState = (): InquiryInfoRequest | null => initialState

export const SET_INQUIRY_INFO_REQUEST = 'inquiry-info-request/set' as const
export const CLEAR_INQUIRY_INFO_REQUEST = 'inquiry-info-request/clear' as const

export const setInquiryInfoRequest = (data: InquiryInfoRequest) => ({
  type: SET_INQUIRY_INFO_REQUEST,
  payload: {
    data,
  },
})

export const clearInquiryInfoRequest = () => ({
  type: CLEAR_INQUIRY_INFO_REQUEST,
})

type SetInquiryInfoRequestAction = ReturnType<typeof setInquiryInfoRequest>
type ClearInquiryInfoRequestAction = ReturnType<typeof clearInquiryInfoRequest>
type Action = SetInquiryInfoRequestAction | ClearInquiryInfoRequestAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_INQUIRY_INFO_REQUEST:
      return action.payload.data
    case CLEAR_INQUIRY_INFO_REQUEST:
      return initialState
    default:
      return state
  }
}

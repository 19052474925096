import { useState } from 'react';
import {
  Box,
  Typography,
  ButtonLink,
  Dialog,
  PrimaryButton,
  SecondaryButton,
} from 'components/atoms';
import { Content, InquiryOverview, InquiryDetails } from 'components/organisms';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { InquirySupportsRegisterRequest } from 'core/domain/inquiries';
import { ArrowLeftIcon } from 'assets/images';
import { hooks } from 'libs';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TYPOGRAPHY } from 'constants/index';

/**
 * Interface
 */

export interface InquiryDetailPageProps {
  handleRegisterSupport: (request: InquirySupportsRegisterRequest) => void;
  handleDeleteInquiryDetail: () => void;
}
/**
 * Presenter
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   width: '100%',
    //   overflow: 'hidden',
    //   overflowX: 'scroll',
    // },
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },

    button: {
      width: 190,
      height: 46,
      display: 'inline',

      '&>button': {
        padding: theme.spacing(1),
      },
    },
    dialog: {
      width: 800,
      minHeight: 226,
      padding: '32px 24px 24px 24px',
      margin: 0,
    },
  }),
);

const InquiryDetailPage = (props: InquiryDetailPageProps) => {
  const { handleRegisterSupport, handleDeleteInquiryDetail } = props;
  const classes = useStyles({});

  // const inquiryDetail = useSelector(
  //   (state: Modules.AppState) => state.inquiryDetail,
  // );

  const inquirySupports = useSelector(
    (state: Modules.AppState) => state.inquirySupports,
  );

  const navigate = hooks.useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleInquiryDelete = () => {
    setDeleteDialogOpen(false);

    handleDeleteInquiryDetail();

    navigate.navigate(`/adm/inquiries`); // TODO:削除
  };

  return (
    <>
      <Box mt={5}>
        <Box mb={2}>
          <ButtonLink
            icon={<ArrowLeftIcon />}
            text="前の画面に戻る"
            iconPosition="start"
            click={() => navigate.navigatePop()}
          />
        </Box>
        <Content title="お問い合わせ詳細">
          <InquiryOverview handleDeleteInquiryDetail={handleDeleteDialogOpen} />
        </Content>

        <Content title="">
          <InquiryDetails
            inquirySupports={inquirySupports}
            handleRegisterSupport={handleRegisterSupport}
          />
        </Content>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        fullWidth
        maxWidth={false}
        PaperProps={{ style: { borderRadius: 16 } }}
        classes={{ paper: classes.dialog }}
        disableBackdropClick
        onBackdropClick={handleCancel}
        // handleSubmit={handleDeleteInquiryDetail}
      >
        <Box textAlign="center">
          <Typography variant="body2" className={classes.title}>
            <Box fontWeight="fontWeightBold">お問い合わせ削除</Box>
          </Typography>
          <Box padding={3}>このお問い合わせ情報を削除しますか?</Box>
        </Box>

        <Box
          mt={5}
          display="flex"
          justifyContent="space-around"
          alignContent="center"
          padding={1.5}
          flex-direction="row"
        >
          <Box className={classes.button} display="inline" padding={1.5}>
            <SecondaryButton text="キャンセル" click={() => handleCancel()} />
          </Box>

          <Box className={classes.button} display="inline" padding={1.5}>
            <PrimaryButton
              text="決定"
              click={() => {
                handleInquiryDelete();
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default InquiryDetailPage;

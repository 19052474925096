import React, { useEffect, useState } from 'react'
import { Box, CalendarText, SubHeadlineLabel } from 'components/atoms'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MandatoryHeadlineLabel } from 'components/molecules';

interface InquiryBirthdayFieldProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    formArea: {
      '& > :first-child': {
        width: '100%',
      }
    }
  }),
);

const InquiryBirthdayField = (props: InquiryBirthdayFieldProps) => {
  const { defaultValue, error, inquiryItemId, isMandatory = false, onChange } = props
  const classes = useStyles()
  const [value, setValue] = useState<string>(defaultValue || "")

  useEffect(() => {
    if (value) return

    setValue(defaultValue || "")
  }, [value, defaultValue])

  return (
    <Box className={`${classes.root} ${isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot}`} display="flex" alignItems="start">
      {
        isMandatory ? <MandatoryHeadlineLabel title="生年月日" /> : <SubHeadlineLabel text="生年月日"/>
      }
      <Box className={classes.formArea}>
        <CalendarText
          error={error}
          value={value}
          handleChangeDate={(v: string) => {
            setValue(v)
            onChange(inquiryItemId, "生年月日", v)
          }}
        />
      </Box>
    </Box>
  )
}

export default InquiryBirthdayField

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ApplyStatusLabel, Box } from 'components/atoms'
import { ReactionCountLabel } from 'components/molecules'
import DeleteIconButton from 'components/atoms/DeleteIconButton'

/**
 * Interface
 */
export interface StatusBarProps{
  status: number,
  approvalUsers?: Array<string>
  step?: number
  lpCount: number,
  likeCount?: number,
  contactCount?: number,
  downloadCount?: number,
  isDialog?:boolean // 商材インフォメーション子画面フラグ
  deletePermission?: {show:boolean,disabled:boolean} | undefined,
  handleDelete?: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 10,
      height: 68
    },
    statusLabel: {
      width: 142,
      maringLeft: theme.spacing(2),
      marginTop: '14px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: '14px'
    },
    box: {
      borderRightWidth: 2,
      borderRightStyle: 'solid',
      borderRightColor: '#DADAD7',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    deleteButton: {
      height: 36,
      width: 36
    },
    rightBox: {
      marginLeft: theme.spacing(2)
    },
    container: {
      height: 19
    }
  }),
)

export default function StatusBar(props:StatusBarProps) {
  const classes = useStyles({})
  const {
    status,
    step,
    approvalUsers,
    lpCount,
    contactCount,
    likeCount,
    downloadCount,
    isDialog,
    deletePermission,
    handleDelete,
  } = props

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" className={classes.root}>
      {!isDialog &&
      <Box className={classes.statusLabel}>
      <ApplyStatusLabel status={status} step={step} approvalUsers={approvalUsers} />
    </Box>
      }

      {deletePermission?.show &&
        <Box className={classes.deleteButton} >
          <DeleteIconButton disabled={deletePermission.disabled} handleDelete={handleDelete}/>
        </Box>
      }
      <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.container}>
        <Box className={classes.box}>
          <ReactionCountLabel title="利用されているランディングページ数" count={lpCount} />
        </Box>
        <Box className={classes.box} visibility={ likeCount !== undefined ? 'visible' : 'hidden' }>
          <ReactionCountLabel title="興味アリ獲得数" count={likeCount || 0} />
        </Box>
        <Box className={classes.box} visibility={ contactCount !== undefined ? 'visible' : 'hidden'}>
          <ReactionCountLabel title="お問い合わせ数" count={contactCount !== undefined ? contactCount : 0} />
        </Box>
        <Box className={classes.rightBox} visibility={ downloadCount !== undefined ? 'visible':'hidden'}>
          <ReactionCountLabel title="資料ダウンロード数" count={downloadCount || 0} />
        </Box>
      </Box>
    </Box>
  )
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { IconButton } from 'components/atoms'
import { SearchIcon } from 'assets/images'

/**
 * Interface
 */

 export interface SearchIconButtonProps{
   handleClick?:()=>void
 }

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      height: '28px',
      width: '28px',
      padding: 0,
    },
  }),
)

/**
 * Presenter
 */

export default function SearchIconButton(props:SearchIconButtonProps) {
  const classes = useStyles({})
  const { handleClick } = props

  return (
    <IconButton 
      onClick={handleClick}
      className={classes.icon}>
      <SearchIcon />
    </IconButton>
  )
}/* eslint-enable */

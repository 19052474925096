import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box } from 'components/atoms';
import { ErrorIcon } from 'assets/images';
import clsx from 'clsx';

/**
 * Interface
 */
export interface SendErrorMailAddressWarningLabelProps {
  text: string;
  icon?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      textAlign: 'center',
      verticalAlign: 'center',
    },
    validationIcon: {
      marginRight: theme.spacing(1),
      width: 14,
      height: 14,
    },
    errorIcon: {
      '& path': {
        fill: '#DB3218',
      },
    },
  }),
);

/**
 * Presenter
 */

const SendErrorMailAddressWarningLabel = (
  props: SendErrorMailAddressWarningLabelProps,
) => {
  const { text, icon } = props;
  const classes = useStyles({});

  return (
    <>
      <Box display="flex" alignItems="center">
        {icon && (
          <ErrorIcon
            className={clsx(classes.validationIcon, classes.errorIcon)}
          />
        )}
        <Box display="flex" alignItems="center" flexDirection="column">
          {text.split('\n').map((str) => (
            <Box marginRight="auto">
              <Typography
                className={classes.root}
                color="error"
                variant="caption"
                display="block"
              >
                {str}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SendErrorMailAddressWarningLabel;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ProductDetail } from 'core/domain/product'

const initialState: ProductDetail[] = []

export const SET_LP_PRODUCT_DETAILS = 'lp-product-details/set' as const
export const APPEND_LP_PRODUCT_DETAILS = 'lp-product-details/append' as const
export const CLEAR_LP_PRODUCT_DETAILS = 'lp-product-details/clear' as const

export function createInitialState(): Array<ProductDetail> {
  return initialState
}

export function setLpProductDetails(data: Array<ProductDetail>) {
  return {
    type: SET_LP_PRODUCT_DETAILS,
    payload: {
      data,
    },
  }
}

export function appendLpProductDetails(data: ProductDetail) {
  return {
    type: APPEND_LP_PRODUCT_DETAILS,
    payload: {
      data,
    },
  }
}

export function clearLpProductDetails() {
  return {
    type: CLEAR_LP_PRODUCT_DETAILS,
  }
}

type SetLpProductDetailsAction = ReturnType<typeof setLpProductDetails>
type AppendLpProductDetailsAction = ReturnType<typeof appendLpProductDetails>
type ClearLpProductDetailsAction = ReturnType<typeof clearLpProductDetails>
type Action = SetLpProductDetailsAction | AppendLpProductDetailsAction | ClearLpProductDetailsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_PRODUCT_DETAILS:
      return action.payload.data
    case APPEND_LP_PRODUCT_DETAILS:
      if (state.findIndex((s: ProductDetail) => s.productId === action.payload.data.productId) < 0) {
        return [
          ...state,
          action.payload.data,
        ]
      }

      return [...state]
    case CLEAR_LP_PRODUCT_DETAILS:
      return initialState
    default:
      return state
  }
}


/* eslint-enable */

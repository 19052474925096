import { useCallback, useEffect, useState } from 'react';
import NormalProductEdit from 'components/pages/NormalProductEdit';
import { useDispatch, useSelector } from 'react-redux';
import { clearProductDetail } from 'core/modules/productDetail';
import { Modules, Usecases } from 'core';
import { useParams } from 'react-router';
import { ExamplesQueryParameter } from 'core/domain/example';
import { clearExamples } from 'core/modules/examples';
import { clearExampleDetail } from 'core/modules/exampleDetail';
import { hooks } from 'libs';
import { clearProductExamples } from 'core/modules/productExamples';
import { CODE_MODE_REFERENCE } from 'constants/code';
import { clearProductComment } from 'core/modules/productComment';

/**
 * Presenter
 */

const NormalProductEditContainer = () => {
  const dispatch = useDispatch();
  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
  );
  const productExamples = useSelector(
    (state: Modules.AppState) => state.productExamples,
  );
  const examples = useSelector((state: Modules.AppState) => state.examples);
  const exampleDetail = useSelector(
    (state: Modules.AppState) => state.exampleDetail,
  );
  const newLp = useSelector((state: Modules.AppState) => state.newLp);
  const normalEditErrors = useSelector(
    (state: Modules.AppState) => state.normalEditErrors,
  );
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;
  const { productId } = useParams<{ productId: string }>();
  const [examplesParam, setExamplesParam] = useState<ExamplesQueryParameter>({
    free_word: '',
    sort_key: 'updatedDate',
    sort_order: 'desc',
    recommended: false,
    example_status: '5',
  } as ExamplesQueryParameter);
  const [exampleId, setExampleId] = useState(-1);
  const [productIndex, setProductIndex] = useState(-1);
  const myLibrary = useSelector((state: Modules.AppState) => state.myLibrary);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.settings.sendGetMainVisualImage(token, userInfo.tenantId),
    );
    // マイライブラリから選択
    dispatch(Usecases.myLibrary.sendGetMyLibrary(token, userInfo.tenantId));
  }, [token, userInfo, dispatch]);

  // 商材詳細情報、商材導入事例取得
  useEffect(() => {
    if (productId && userInfo) {
      dispatch(
        Usecases.products.sendGetProductDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(productId, 10),
          CODE_MODE_REFERENCE,
        ),
      );
      dispatch(
        Usecases.products.sendGetProductExamples(
          token,
          userInfo.tenantId,
          Number.parseInt(productId, 10),
        ),
      );
      let idx = -1;
      if (!newLp || !newLp?.products) return;

      idx = newLp.products.findIndex(
        (p) => p.productId === Number.parseInt(productId, 10),
      );
      setProductIndex(idx);
    }

    dispatch(clearProductDetail());
    dispatch(clearProductExamples());

    // newLp監視するとコメント1文字入力するたび再読み込みされる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, userInfo, token, dispatch]);

  // 導入事例一覧取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.examples.sendGetExamples(
        token,
        userInfo.tenantId,
        examplesParam,
      ),
    );

    dispatch(clearExamples());
  }, [dispatch, token, examplesParam, userInfo]);

  // 導入事例詳細情報取得
  useEffect(() => {
    if (exampleId !== -1 && userInfo) {
      dispatch(
        Usecases.examples.sendGetExampleDetail(
          token,
          userInfo.tenantId,
          exampleId,
          CODE_MODE_REFERENCE,
        ),
      );
    }

    dispatch(clearExampleDetail());
  }, [dispatch, token, exampleId, userInfo]);

  // 商材編集一言コメント取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetProductComment(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearProductComment());
    };
  }, [dispatch, productId, token, userInfo]);

  /**
   * 商材オススメ更新
   */
  const handleProductRecommendedUpdate = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductRecommended(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        !productDetail.recommended,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, token, productId, productDetail, userInfo]);

  /**
   * 導入事例オススメ更新
   */
  const handleExampleRecommendedUpdate = useCallback(async () => {
    if (!exampleDetail || !userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.examples.sendPatchExampleRecommended(
        token,
        userInfo.tenantId,
        exampleId,
        !exampleDetail.recommended,
      ),
    );
    dispatch(
      Usecases.examples.sendGetExampleDetail(
        token,
        userInfo.tenantId,
        exampleId,
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, token, exampleDetail, userInfo, exampleId]);

  /**
   * 商材一言コメント取得
   */
  const handleCopyComment = useCallback(async () => {
    if (!userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.lps.sendGetProductComment(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
      ),
    );
  }, [dispatch, token, userInfo, productId]);

  /**
   * 商材お気に入り更新
   */
  const handleClickFavorite = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductFavorite(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        !productDetail.favorite,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(productId, 10),
        CODE_MODE_REFERENCE,
      ),
    );
  }, [dispatch, token, productId, productDetail, userInfo]);

  return (
    <NormalProductEdit
      myLibraryImage={
        myLibrary?.myLibraryFiles ? myLibrary?.myLibraryFiles : null
      }
      myLibrary={myLibrary}
      normalEditErrors={normalEditErrors}
      productIndex={productIndex}
      productId={Number.parseInt(productId, 10)}
      productExamples={productExamples}
      copyComment={handleCopyComment}
      handleExampleRecommendedUpdate={handleExampleRecommendedUpdate}
      exampleDetail={exampleDetail}
      setExampleId={setExampleId}
      examples={examples}
      newLp={newLp}
      handleProductRecommendedUpdate={handleProductRecommendedUpdate}
      productDetail={productDetail}
      setExamplesParam={setExamplesParam}
      examplesParam={examplesParam}
      handleClickFavorite={handleClickFavorite}
    />
  );
};
export default NormalProductEditContainer;



import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  SecondaryButton,
  IconButton,
  HeadlineLabel,
  LoadingPanel,
} from 'components/atoms';
import { CloseWhiteIcon } from 'assets/images';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

/**
 * Interface
 */

export interface ImageCutPreviewProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      width: 600,
      minWidth: 600,
      margin: 0,
      overflow: 'visible',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      borderColor: theme.palette.green[800],
    },
    img: {
      margin:'auto',
      width: 500,
      height: 550,
      objectFit: 'contain',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.green[800],
      },
    },
    title: {
      marginLeft: 50,
    },
  }),
);

const ImageCutPreview = (props: ImageCutPreviewProps) => {
  const classes = useStyles({});
  const { open, handleCancel, handleSubmit, } = props;
  const imageCut = useSelector((state: Modules.AppState) => state.imageCut);
  const [cutImage,setCutImage] = useState('');

    // プレビュー画像セット
    useEffect(() => {
      if(!cutImage && imageCut?.data) setCutImage(imageCut?.data);
      // eslint-disable-next-line
    }, [imageCut]);

    // 表示画像の更新
    useEffect(() => {
      setCutImage('');
    }, [open]);

    // 閉じる
    const handleCancelClick = () => {
      handleCancel();
    };

    // 保存押下時
    const handleSubmitClick = () => {
      handleSubmit();
    };

    const previewTitle = '画像切り抜きプレビュー';

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box className={classes.title} >
      <HeadlineLabel text={previewTitle} />
      </Box>
      <Box textAlign="center">
        <Box>
        <img src={cutImage} className={classes.img} />
        {!cutImage && <LoadingPanel />}
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={0}>
            <Box width={166} height={48}>
              <SecondaryButton
                click={() => handleCancelClick()}
                text="キャンセル"
              />
            </Box>
            <Box ml={2} width={166} height={48}>
              <PrimaryButton
                click={() => handleSubmitClick()}
                text="保存"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
      <IconButton
        onClick={() => handleCancelClick()}
        className={classes.closeButton}
      >
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
}

export default ImageCutPreview;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


const initialState = true

export function createInitialState(): boolean {
  return initialState
}

export const SET_SIDEBAR_SHOWN = 'sidebar-shown/set' as const
export const CLEAR_SIDEBAR_SHOWN = 'sidebar-shown/clear' as const

export function setSidebarShown(sidebarShown: boolean) {
  return {
    type: SET_SIDEBAR_SHOWN,
    payload: {
      sidebarShown,
    },
  }
}

export function clearSidebarShown() {
  return {
    type: CLEAR_SIDEBAR_SHOWN,
  }
}

type SetSidebarShownAction = ReturnType<typeof setSidebarShown>
type ClearSidebarShownAction = ReturnType<typeof clearSidebarShown>
type Action = SetSidebarShownAction | ClearSidebarShownAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_SIDEBAR_SHOWN:
      return action.payload.sidebarShown
    case CLEAR_SIDEBAR_SHOWN:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  MultipleLinesTextField,
  SecondaryButton,
  PrimaryButton,
  Typography,
  LetterCountLabel,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { CloseIcon, CheckIcon } from 'assets/images';

/**
 * Interface
 */

export interface DeclineDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (message: string) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 806,
      minHeight: 276,
      padding: '32px 24px 24px 24px',
      margin: 0,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    actions: {
      padding: 0,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      },
    },
  }),
);

export default function DeclineDialog(props: DeclineDialogProps) {
  const classes = useStyles({});
  const { open, handleCancel, handleSubmit } = props;
  const [declineMessage, setDeclineMessage] = useState<string | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box>
        <Typography variant="body2" className={classes.title}>
          差し戻し理由を記入してください
        </Typography>
        <Box mt={4}>
          <MultipleLinesTextField
            error={errorMessage}
            rows={5}
            value={declineMessage}
            handleChangeText={(text) => setDeclineMessage(text)}
          />
          <LetterCountLabel
            onlyInputCount
            count={declineMessage ? `${declineMessage.length}` : '0'}
          />
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton
                icon={<CloseIcon className={classes.closeIcon} />}
                click={() => handleCancel()}
                text="キャンセル"
              />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton
                disabled={isSubmitting}
                icon={<CheckIcon />}
                click={() => {
                  setIsSubmitting((prev) => !prev);
                  setTimeout(() => {
                    setIsSubmitting((prev) => !prev);
                  }, 1000);
                  if (declineMessage && declineMessage.length > 1000) {
                    setErrorMessage('1000文字以内で入力してください。');

                    return;
                  }
                  handleSubmit(declineMessage || '');
                  setErrorMessage('');
                }}
                text="決定"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
/* eslint-enable */

  import React, { useCallback, useEffect, useState } from 'react';
import {
  ConfirmDialog,
  Content,
  VariousSettingsCertificationMarkArea,
  VariousSettingsCompanyLogoArea,
  VariousSettingsLpFaviconArea,
  VariousSettingsCopyrightFieldArea,
  VariousSettingsDesignTemplateArea,
  VariousSettingsInquiriesFieldArea,
  VariousSettingsLpEditSettingsArea,
  VariousSettingsLpMainImageArea,
  VariousSettingsLpTagManagerFieldArea,
  VariousSettingsPrivacyPolicyFieldArea,
  VariousSettingsRankingArea,
  SuccessDialog,
} from 'components/organisms';
import { useLocation } from 'react-router-dom';

import {
  AuthenticationMark,
  CompanyLogo,
  Favicon,
  DesignTemplate,
  TenantLpMainImage,
  VariousSettings,
} from 'core/domain/variousSettings';
import { setNewVariousSettings } from 'core/modules/newVariousSettings';
import {
  Box,
  MessageAlert,
  PrimaryButton,
  SecondaryButton,
} from 'components/atoms';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { AppError } from 'core/domain/appError';
import clsx from 'clsx';
import { removeIncorrectTagEntry } from 'libs/validation';
import validator from 'validator';

interface VariousSettingsPageProps {
  error: AppError | null;
  handleVariousSettingsRegister: (
    requestData: VariousSettings,
    type: 'post' | 'put',
  ) => void;
  newVariousSettings: VariousSettings | null;
  isRegister: boolean;
  setIsRegister: (isRegister: boolean) => void;
}

interface Validations {
  authenticationMark: { [key: number]: string } | undefined;
  tenantLpMainImage: string | undefined;
  designTemplate: string | undefined;
  companyLogo: string | undefined;
  favicon: string | undefined;
  copyright: string | undefined;
  inquiryTitle: string | undefined;
  inquiryGreetings: string | undefined;
  privacyPolicy: string | undefined;
  privacyPolicyCaption: string | undefined;
  privacyPolicyUrl: string | undefined;
  tokuisakiTitleOptions: string | undefined;
  tantoCompanyOptions: string | undefined;
  personalDataCollectText: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
  }),
);

const VariousSettingsPage = (props: VariousSettingsPageProps) => {
  const {
    error,
    handleVariousSettingsRegister,
    newVariousSettings,
    setIsRegister,
    isRegister,
  } = props;
  const location = useLocation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = hooks.useNavigate();
  const [initialNewVariousSettings, setInitialNewVariousSettings] = useState<
    VariousSettings | null | undefined
  >(undefined);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<Validations>();
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [nextPagePath, setNextPagePath] = useState<string>('');
  const [goAnotherPage, setGoAnotherPage] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const validationInputValue = (): boolean => {
    const validations: Validations = {
      authenticationMark: undefined,
      tenantLpMainImage: undefined,
      designTemplate: undefined,
      companyLogo: undefined,
      favicon: undefined,
      copyright: undefined,
      inquiryTitle: undefined,
      inquiryGreetings: undefined,
      privacyPolicy: undefined,
      privacyPolicyCaption: undefined,
      privacyPolicyUrl: undefined,
      tokuisakiTitleOptions: undefined,
      tantoCompanyOptions: undefined,
      personalDataCollectText: undefined,
    };

    if (
      newVariousSettings &&
      newVariousSettings.designTemplate.every((e) => !e.available)
    )
      validations.designTemplate = '1つ以上「有効」に設定してください';
    // 認証マークのURLエラー
    if (
      newVariousSettings &&
      newVariousSettings.authenticationMark.some(
        (e) => e.url && !validator.isURL(e.url),
      )
    ) {
      validations.authenticationMark =
        newVariousSettings.authenticationMark.reduce(
          (
            acc: { [key: number]: string },
            curr: AuthenticationMark,
            index: number,
          ) => {
            if (!curr.url || validator.isURL(curr.url)) return acc;
            Object.assign(acc, { [index]: 'URLの形式で入力してください' });

            return acc;
          },
          {},
        );
    }

    if (newVariousSettings && newVariousSettings?.tenantLpMainImage.length < 1)
      validations.tenantLpMainImage =
        'ランディングページメイン画像を入力してください';

    if (
      !newVariousSettings?.companyLogo.data &&
      !newVariousSettings?.companyLogo.url
    )
      validations.companyLogo = '企業ロゴを入力してください';

    if (!newVariousSettings?.favicon.data && !newVariousSettings?.favicon.url)
      validations.favicon =
        '公開ランディングページ用ファビコンを入力してください';

    if (!newVariousSettings?.copyright)
      validations.copyright = 'コピーライトを入力してください';

    if (!newVariousSettings?.inquiryTitle)
      validations.inquiryTitle = 'お問い合わせ見出しを入力してください';

    if (!newVariousSettings?.inquiryGreetings)
      validations.inquiryGreetings = 'お問い合わせ挨拶を入力してください';

    if (!newVariousSettings?.privacyPolicy)
      validations.privacyPolicy = '本文を入力してください';

    if (!newVariousSettings?.privacyPolicyCaption)
      validations.privacyPolicyCaption = 'リンクボタンを入力してください';

    if (!newVariousSettings?.privacyPolicyUrl) {
      validations.privacyPolicyUrl = 'URLを入力してください';
    } else if (
      newVariousSettings &&
      !validator.isURL(newVariousSettings?.privacyPolicyUrl || '')
    ) {
      validations.privacyPolicyUrl = 'URLの形式で入力してください。';
    }

    // 個人情報収集,有効時は必須
    if (!newVariousSettings?.personalDataCollectText && newVariousSettings?.personalDataCollectDisplayed)
      validations.personalDataCollectText = '本文を入力してください';

    if (
      !newVariousSettings?.tokuisakiTitleOptions ||
      !removeIncorrectTagEntry(newVariousSettings?.tokuisakiTitleOptions)
    )
      validations.tokuisakiTitleOptions = '得意先敬称 選択肢を入力してください';

    if (
      !newVariousSettings?.tantoCompanyOptions ||
      !removeIncorrectTagEntry(newVariousSettings?.tantoCompanyOptions)
    )
      validations.tantoCompanyOptions = '担当会社名 選択肢を入力してください';

    if (!Object.values(validations).every((e) => e === undefined)) {
      setValidationError(validations);
      setAlertOpen(true);

      return false;
    }

    return true;
  };

  const handleInputChange = useCallback(
    (
      v:
        | string
        | number
        | AuthenticationMark[]
        | CompanyLogo
        | Favicon
        | DesignTemplate[]
        | TenantLpMainImage[],
      key: keyof VariousSettings,
    ) => {
      dispatch(
        setNewVariousSettings({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...newVariousSettings!,
          [key]: v,
        }),
      );
    },
    [dispatch, newVariousSettings],
  );

  // 個人情報収集ON・OFF
  const checked = useCallback(
    (
      v:
      |boolean,
      key: keyof VariousSettings,
    ) => {
      dispatch(
        setNewVariousSettings({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...newVariousSettings!,
          [key]: v,
        }),
      );
    },
    [dispatch, newVariousSettings],
  );

  const considerSetDefaultAddTermValue = (
    isSetNecessary: boolean,
  ): VariousSettings => {
    if (isSetNecessary) {
      return { ...(newVariousSettings as VariousSettings), defaultAddTerm: 4 };
    }

    return { ...(newVariousSettings as VariousSettings) };
  };

  const handleRegister = () => {
    setValidationError(undefined);

    if (!validationInputValue()) return;
    const requestData = considerSetDefaultAddTermValue(
      !newVariousSettings?.defaultAddTerm,
    );
    const type = initialNewVariousSettings === null ? 'post' : 'put';
    handleVariousSettingsRegister(requestData, type);
    setSuccessDialogOpen(true);
    setIsRegister(true);
    setInitialNewVariousSettings(
      newVariousSettings ? { ...newVariousSettings } : null,
    );
  };

  // 初期値を代入
  useEffect(() => {
    if (
      initialNewVariousSettings === null ||
      initialNewVariousSettings ||
      !newVariousSettings
    )
      return;

    setInitialNewVariousSettings(
      newVariousSettings ? { ...newVariousSettings } : null,
    );
  }, [initialNewVariousSettings, newVariousSettings]);

  // 入力の変更を検知
  useEffect(() => {
    if (error) return undefined;

    const res = navigate.block((pathname: string) => {
      // 入力情報と保存済み情報が存在することを確認
      // ページ遷移が許可されていればblockしない

      // 遷移先が同じパスなら遷移自体キャンセル
      if (pathname === location.pathname) return undefined;

      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);

        return false;
      }

      if (
        (!newVariousSettings && !initialNewVariousSettings) ||
        isRegister ||
        goAnotherPage
      )
        return undefined;

      if (
        newVariousSettings &&
        JSON.stringify(newVariousSettings) !==
          JSON.stringify(initialNewVariousSettings)
      ) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => res();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    goAnotherPage,
    initialNewVariousSettings,
    isRegister,
    navigate,
    newVariousSettings,
    location.pathname,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  useEffect(() => {
    setAlertOpen(false);
    setIsRegister(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVariousSettings]);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: validationError,
      })}
    >
      {alertOpen && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}

      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          setSuccessDialogOpen(false);
        }}
      />

      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
      <Content title="各種設定">
        <>
          <VariousSettingsRankingArea
            handleInputChange={handleInputChange}
            isInit={initialNewVariousSettings === null}
            newVariousSettings={newVariousSettings}
          />
          <VariousSettingsLpMainImageArea
            error={validationError?.tenantLpMainImage}
            tenantLpMainImage={newVariousSettings?.tenantLpMainImage}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsDesignTemplateArea
            designTemplate={newVariousSettings?.designTemplate}
            error={validationError?.designTemplate}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsCompanyLogoArea
            error={validationError?.companyLogo}
            companyLogo={newVariousSettings?.companyLogo}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsLpFaviconArea
            error={validationError?.favicon}
            favicon={newVariousSettings?.favicon}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsLpTagManagerFieldArea
            bodyValue={newVariousSettings?.tagManagerBody || ''}
            handleInputChange={handleInputChange}
            headValue={newVariousSettings?.tagManagerHead || ''}
          />
          <VariousSettingsCertificationMarkArea
            authenticationMark={newVariousSettings?.authenticationMark}
            errors={validationError?.authenticationMark}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsCopyrightFieldArea
            error={validationError?.copyright}
            value={newVariousSettings?.copyright || ''}
            handleInputChange={handleInputChange}
          />
          <VariousSettingsInquiriesFieldArea
            error={validationError?.inquiryTitle}
            handleInputChange={handleInputChange}
            type="heading"
            value={newVariousSettings?.inquiryTitle || ''}
          />
          <VariousSettingsInquiriesFieldArea
            error={validationError?.inquiryGreetings}
            handleInputChange={handleInputChange}
            type="greeting"
            value={newVariousSettings?.inquiryGreetings || ''}
          />
          <VariousSettingsPrivacyPolicyFieldArea
            privacyPolicyError={validationError?.privacyPolicy}
            privacyPolicyCaptionError={validationError?.privacyPolicyCaption}
            privacyPolicyUrlError={validationError?.privacyPolicyUrl}
            personalDataCollectTextError={validationError?.personalDataCollectText}
            handleInputChange={handleInputChange}
            setChecked={checked}
            privacyPolicyCaptionValue={newVariousSettings?.privacyPolicyCaption || ''}
            privacyPolicyUrlValue={newVariousSettings?.privacyPolicyUrl || ''}
            privacyPolicyValue={newVariousSettings?.privacyPolicy || ''}
            personalDataCollectText={newVariousSettings?.personalDataCollectText || ''}
            personalDataCollectDisplayed={newVariousSettings?.personalDataCollectDisplayed || false}
          />
          <VariousSettingsLpEditSettingsArea
            tantoCompanyOptionsError={validationError?.tantoCompanyOptions}
            tokuisakiTitleOptionsError={validationError?.tokuisakiTitleOptions}
            handleInputChange={handleInputChange}
            tantoCompanyOptions={newVariousSettings?.tantoCompanyOptions || ''}
            tokuisakiTitleOptions={
              newVariousSettings?.tokuisakiTitleOptions || ''
            }
          />
          <Box
            mt={5}
            pt={3}
            display="flex"
            justifyContent="center"
            borderTop="1px solid #DCDCDA"
          >
            <Box m={1} width={332} height={48}>
              <SecondaryButton
                text="キャンセル"
                click={() => {
                  if (newVariousSettings && !initialNewVariousSettings)
                    setOpenExitConfirmDialog(true);
                  navigate.navigate('/adm/tenant/dashboard');
                }}
              />
            </Box>
            <Box m={1} width={332} height={48}>
              <PrimaryButton text="保存" click={handleRegister} />
            </Box>
          </Box>
        </>
      </Content>
    </Box>
  );
};

export default VariousSettingsPage;

import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { RankingLp, RankingProduct } from 'core/domain/dashboard';
import { PreviewButton, PreviewHeightFixedButton } from 'components/molecules';
import { LpView } from 'core/domain/lp';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import {
  GlitterCopperIcon,
  GlitterIcon,
  GlitterSilverIcon,
} from 'assets/images';
import { hooks } from 'libs';
import { convertProductDetailToLpView } from 'libs/converter';
import { clearProductDetail } from 'core/modules/productDetail';
import { CODE_MODE_REFERENCE } from 'constants/code';
import { clearLpDetail } from 'core/modules/lpDetail';
import { clearProductExamples } from 'core/modules/productExamples';

interface DashboardReactionCardItemProps {
  cardType?: 'own' | 'everyone';
  count?: number;
  icon?: React.ReactNode;
  content: RankingLp | RankingProduct;
  rankLabel?: string;
  title?: string;
}

interface PreviewAreaProps {
  cardType?: 'own' | 'everyone';
  contentId: number;
  contentType: 'lp' | 'product';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      borderStyle: 'solid',
      borderWidth: 3,
      marginRight: 8,
      padding: 8,
      position: 'relative',
    },
    count: {
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    parcent: {
      backgroundColor: theme.palette.green[700],
      marginLeft: 8,
      borderRadius: 5,
      padding: 5,
    },
    caption: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    box: {
      marginTop: 8,
      marginLeft: '100px',
      marginBottom: 10,
      padding: '0 8px',
    },
    typo: {
      fontSize: 28,
      fontWeight: 'bold',
      color: theme.palette.gray[900],
    },
    title: {
      whiteSpace: 'pre-line',
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
    },
    rankLabelArea: {
      borderBottom: '1px solid #DCDCDA',
      paddingBottom: 8,
    },
    rankLabel: {
      fontWeight: 'bold',
    },
    totalizationCount: {
      padding: theme.spacing(1),
      color: '#fff',
      borderRadius: 8,
    },
    lpTitle: {
      fontWeight: 'bold',
      marginTop: 10,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    text: {
      color: theme.palette.gray[800],
      marginTop: 10,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    productTitle: {
      fontWeight: 'bold',
      marginTop: 10,
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    previewLabel: {
      marginRight: theme.spacing(1),
    },
    ownPreviewLabelColor: {
      color: theme.palette.gray[800],
    },
    absoluteBottom: {
      marginBottom: 35,
    },
    reactionDetail: {
      backgroundColor: theme.palette.gray[400],
      padding: 8,
      borderRadius: '3%',
    },
  }),
);

const PreviewArea = (props: PreviewAreaProps) => {
  const { cardType = 'own', contentId, contentType } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const lpView = useSelector(
    (state: Modules.AppState) => state.lpView,
    shallowEqual,
  );
  const lpSettings = useSelector(
    (state: Modules.AppState) => state.lpSettings,
    shallowEqual,
  );
  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
    shallowEqual,
  );
  const productExamples = useSelector(
    (state: Modules.AppState) => state.productExamples,
    shallowEqual,
  );
  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
    shallowEqual,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const [previewData, setPreviewData] = useState<LpView>();
  const marginNum = cardType === 'own' ? 2 : 0;
  const position = cardType === 'own' ? 'absolute' : 'relative';
  const bottom = cardType === 'own' ? 10 : 0;

  const handleClick = () => {
    dispatch(clearLpDetail());
    dispatch(clearProductDetail());

    if (!token || !userInfo) return;
    dispatch(
      contentType === 'lp'
        ? // eslint-disable-next-line
          Usecases.lps.sendPostApprovedLpView(
            token,
            userInfo.tenantId,
            contentId,
            CODE_MODE_REFERENCE,
          )
        : Usecases.products.sendGetProductDetail(
            token,
            userInfo.tenantId,
            contentId,
            CODE_MODE_REFERENCE,
          ),
    );
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
  };

  const handleClose = () => {
    setPreviewData(undefined);
  };

  useEffect(() => {
    if (!userInfo || !productDetail) return;

    dispatch(clearProductExamples());
    dispatch(
      Usecases.products.sendGetProductExamples(
        token,
        userInfo.tenantId,
        productDetail.productId,
      ),
    );
  }, [dispatch, productDetail, token, userInfo]);

  useEffect(() => {
    if (!lpSettings) return;
    setPreviewData(
      (contentType === 'lp' && lpView) ||
        (contentType === 'product' &&
          productDetail &&
          productExamples && {
            ...convertProductDetailToLpView(
              productDetail,
              productExamples,
              lpSettings,
              inquiriesGreetText,
              inquiryForms,
            ),
            isPreview: true,
          }) ||
        undefined,
    );
  }, [
    contentType,
    inquiriesGreetText,
    inquiryForms,
    lpSettings,
    lpView,
    productDetail,
    productExamples,
  ]);

  return (
    <Box
      display="flex"
      alignItems="center"
      mt={marginNum}
      ml={marginNum}
      height={35}
      position={position}
      bottom={bottom}
    >
      <Typography
        className={`${classes.previewLabel} ${
          cardType === 'own' ? classes.ownPreviewLabelColor : ''
        }`}
        variant="body2"
      >
        プレビュー
      </Typography>
      {contentType === 'product' ? (
        <PreviewHeightFixedButton
          handleClick={handleClick}
          handleCloseClick={handleClose}
          previewData={previewData}
          templateId={Number(previewData?.designTemplateId)}
        />
      ) : (
        <PreviewButton
          handleClick={handleClick}
          handleCloseClick={handleClose}
          previewData={previewData}
          templateId={Number(previewData?.designTemplateId)}
        />
      )}
    </Box>
  );
};

const DashboardReactionCardItem = (props: DashboardReactionCardItemProps) => {
  const {
    cardType = 'own',
    count = 0,
    icon,
    content,
    rankLabel = '',
    title,
  } = props;
  const classes = useStyles();
  const colorByRank =
    (content.rank === 1 && '#D69B23') ||
    (content.rank === 2 && '#6C8A9B') ||
    (content.rank === 3 && '#956842') ||
    '';
  const starIcon =
    (content.rank === 1 && <GlitterIcon />) ||
    (content.rank === 2 && <GlitterSilverIcon />) ||
    (content.rank === 3 && <GlitterCopperIcon />) ||
    '';

  const formatComma = (x: number) => x.toLocaleString();

  const distinguishLpProductId = useMemo((): number => {
    if ('lpId' in content) return content.lpId;

    return ('productId' in content && content.productId) || 0;
  }, [content]);
  const distinguishedLpProductType = 'lpId' in content ? 'lp' : 'product';
  const distinguishedMinHeight = cardType === 'own' ? 360 : 180;
  const distinguishedBGColor = cardType === 'own' ? 'white' : '#F7F7F5';
  const distinguishedBorderColor = cardType === 'own' ? '#EDEDE6' : '#F7F7F5';
  const reactionDetailMinHeight = cardType === 'own' ? 232 : 180;

  return (
    <Box
      className={classes.root}
      minHeight={distinguishedMinHeight}
      bgcolor={distinguishedBGColor}
      borderColor={distinguishedBorderColor}
    >
      {cardType === 'own' && (
        <>
          <Box display="flex" padding="8px 8px 0 8px" height={60}>
            {icon}
            <Box pl={2} m={1}>
              <Typography className={classes.title}>{title}</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.box}
          >
            <Typography className={classes.typo} variant="body1">
              {formatComma(count)}
            </Typography>
          </Box>
        </>
      )}
      <Box
        className={classes.reactionDetail}
        minHeight={reactionDetailMinHeight}
      >
        <Box
          className={classes.rankLabelArea}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={`${classes.rankLabel}`}
            variant="body2"
            style={{ color: colorByRank }}
          >
            {starIcon}
            {` ${rankLabel}${content.rank}位`}
          </Typography>
          {cardType === 'everyone' && (
            <PreviewArea
              cardType="everyone"
              contentId={distinguishLpProductId}
              contentType={distinguishedLpProductType}
            />
          )}
          <Typography
            className={classes.totalizationCount}
            style={{ background: colorByRank }}
          >
            {content.totalizationCount.toLocaleString()}
          </Typography>
        </Box>
        {'title' in content && (
          <Tooltip title={content.title || ''} arrow placement="top">
            <Typography className={classes.lpTitle} display="block">
              {content.title}
            </Typography>
          </Tooltip>
        )}
        {'tokuisakiName' in content && (
          <Tooltip title={content.tokuisakiName || ''} arrow placement="top">
            <Typography className={classes.text} display="block">
              {content.tokuisakiName}
            </Typography>
          </Tooltip>
        )}
        {'lpTantoUser' in content && (
          <Tooltip title={content.lpTantoUser || ''} arrow placement="top">
            <Typography
              className={`${classes.text} ${
                cardType === 'own' ? classes.absoluteBottom : ''
              }`}
              display="block"
            >
              {content.lpTantoUser}
            </Typography>
          </Tooltip>
        )}
        {'productName' in content && (
          <Tooltip title={content.productName || ''} arrow placement="top">
            <Typography
              className={`${classes.productTitle} ${
                cardType === 'own' ? classes.absoluteBottom : ''
              }`}
              display="block"
            >
              {content.productName}
            </Typography>
          </Tooltip>
        )}
        {cardType === 'own' && (
          <PreviewArea
            cardType="own"
            contentId={distinguishLpProductId}
            contentType={distinguishedLpProductType}
          />
        )}
      </Box>
    </Box>
  );
};

export default DashboardReactionCardItem;

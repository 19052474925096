import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { setTokuisakiFixedPhraseTexts } from 'core/modules/tokuisakiFixedPhraseTexts';
import {
  InquiryForms,
  LpSettings,
  ProfileFixedPhraseTexts,
  TokuisakiFixedPhraseTexts,
  InquiriesGreetText,
  LpMainImages,
  TokuisakiTitles,
  TantoCompanies,
  AddTerm,
  DesignTemplates,
  NoticeDetails,
  InquiriesForm,
} from 'core/domain/settings';
import { setInquiriesGreetText } from 'core/modules/inquiriesGreetText';
import { setInquiryForms } from 'core/modules/inquiryForms';
import { setLpSettings } from 'core/modules/lpSettings';
import { setProfileFixedPhraseTexts } from 'core/modules/profileFixedPhraseTexts';
import { setLpMainVisualImages } from 'core/modules/lpMainVisualImages';
import { setTantoCompanies } from 'core/modules/tantoCompanies';
import { setTokuisakiTitles } from 'core/modules/tokuisakiTitles';
import { setAddTerm } from 'core/modules/addTerm';
import { setDesignTemplates } from 'core/modules/designTemplates';
import { setDesignAllTemplates } from 'core/modules/designAllTemplates';
import { VariousSettings } from 'core/domain/variousSettings';
// import { InquiryFormRequest } from 'core/domain/inquiries'
import { setVariousSettings } from 'core/modules/variousSettings';
import { convertObjectToGetQuery } from 'libs/converter';
import { setSettingNotices } from 'core/modules/settingNotices';
import { setInquiriesForm } from 'core/modules/inquiriesForm';

const didCallGetTokuisakiFixedPhraseTexts = (
  dispatch: Dispatch,
  data: TokuisakiFixedPhraseTexts,
) => {
  dispatch(setTokuisakiFixedPhraseTexts(data));
};

export const sendGetTokuisakiFixedPhraseTexts =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/fixed-phrase-texts/tokuisaki`,
      token,
      null,
      dispatch,
      didCallGetTokuisakiFixedPhraseTexts,
    );
  };

const didCallGetProfileFixedPhraseTexts = (
  dispath: Dispatch,
  data: ProfileFixedPhraseTexts,
) => {
  dispath(setProfileFixedPhraseTexts(data));
};

export const sendGetProfileFixedPhraseTexts =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/fixed-phrase-texts/profile`,
      token,
      null,
      dispatch,
      didCallGetProfileFixedPhraseTexts,
    );
  };

const didCallGetInquiriesGreetText = (
  dispath: Dispatch,
  data: InquiriesGreetText,
) => {
  dispath(setInquiriesGreetText(data));
};

export const sendGetInquiriesGreetText =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/inquiries/greet-text`,
      token,
      null,
      dispatch,
      didCallGetInquiriesGreetText,
    );
  };

const didCallLpSettings = (dispath: Dispatch, data: LpSettings) => {
  dispath(setLpSettings(data));
};

export const sendGetLpSettings =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps`,
      token,
      null,
      dispatch,
      didCallLpSettings,
    );
  };

const didCallGetInquiryForms = (dispath: Dispatch, data: InquiryForms) => {
  dispath(setInquiryForms(data.inquiryForms));
};

export const sendGetInquiryForms =
  (token: string | undefined, tenantId: string, mode: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/settings/inquiries/form`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallGetInquiryForms,
    );
  };

const didCallMainVisualImage = (dispath: Dispatch, data: LpMainImages) => {
  dispath(setLpMainVisualImages(data.lpMainImages));
};

export const sendGetMainVisualImage =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps/main-visual-image`,
      token,
      null,
      dispatch,
      didCallMainVisualImage,
    );
  };

const didCallTokuisakiTitles = (dispath: Dispatch, data: TokuisakiTitles) => {
  dispath(setTokuisakiTitles(data));
};

export const sendGetTokuisakiTitles =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps/tokuisaki-title`,
      token,
      null,
      dispatch,
      didCallTokuisakiTitles,
    );
  };

const didCallTantoCompanies = (dispath: Dispatch, data: TantoCompanies) => {
  dispath(setTantoCompanies(data));
};

export const sendGetTantoCompanies =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps/tanto-company`,
      token,
      null,
      dispatch,
      didCallTantoCompanies,
    );
  };

const didCallAddTerm = (dispath: Dispatch, data: AddTerm) => {
  dispath(setAddTerm(data));
};

export const sendGetAddTerm =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/dashboard/add-term`,
      token,
      null,
      dispatch,
      didCallAddTerm,
    );
  };

const didCallDesignTemplates = (dispath: Dispatch, data: DesignTemplates) => {
  dispath(setDesignTemplates(data.designTemplates));
};

const didCallDesignAllTemplates = (
  dispath: Dispatch,
  data: DesignTemplates,
) => {
  dispath(setDesignAllTemplates(data.designTemplates));
};

export const sendGetDesignAllTemplates =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps/design-template/all`,
      token,
      null,
      dispatch,
      didCallDesignAllTemplates,
    );
  };

export const sendGetDesignTemplates =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/lps/design-template`,
      token,
      null,
      dispatch,
      didCallDesignTemplates,
    );
  };

const didCallVariousSettings = (dispatch: Dispatch, data: VariousSettings) => {
  dispatch(setVariousSettings(data));
};

export const sendGetVariousSettings =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/systems`,
      token,
      null,
      dispatch,
      didCallVariousSettings,
    );
  };

export const sendPostVariousSettings =
  (token: string | undefined, tenantId: string, data: VariousSettings) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/settings/systems`,
      token,
      JSON.stringify(data),
      dispatch,
      didCallVariousSettings,
    );
  };

export const sendPutVariousSettings =
  (token: string | undefined, tenantId: string, data: VariousSettings) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/settings/systems`,
      token,
      JSON.stringify(data),
      dispatch,
      didCallVariousSettings,
    );
  };

const didSettingNotices = (dispatch: Dispatch, data: NoticeDetails) => {
  dispatch(setSettingNotices(data.notices));
};

interface SettingNoticesBody {
  page: number;
  row: number;
  freeWord?: string;
  sortKey?: string;
  sortOrder?: string;
  target?: number;
  status?: (1 | 2 | 3)[];
}

export const sendGetSettingNotices =
  (token: string | undefined, tenantId: string, body?: SettingNoticesBody) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/notices${
        body
          ? convertObjectToGetQuery(
              Object.freeze({
                ...body,
                status: body.status?.join(','),
              }),
            )
          : ''
      }`,
      token,
      null,
      dispatch,
      didSettingNotices,
    );
  };

/** updateDateを含めて取得 */
const didCallPostInquiriesForm = (dispath: Dispatch, data: InquiriesForm) => {
  dispath(setInquiriesForm(data));
};

export const sendPostInquiriesForm =
  (token: string | undefined, tenantId: string, mode: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/settings/inquiries/form`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallPostInquiriesForm,
    );
  };

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, SecondaryButton, Typography } from 'components/atoms'
import { CheckIcon, CloseIcon, ErrorIcon, CheckOutlineIcon } from 'assets/images'
import { TYPOGRAPHY } from 'constants/index'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { clearNormalEditSidebarShown, setNormalEditSidebarShown } from 'core/modules/normalEditSidebarShown'

/**
 * Interface
 */
export interface CompleteEditProps{
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>
}
/**
* Style
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: 'translate(0,10%)',
      padding: 0,
    },
    box: {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.gray[500],
    },
    bold: {
      fontWeight: 'bold',
    },
    title: {
      marginTop: theme.spacing(3),
    },
    subTitle: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300
    },
    pink: {
      color: theme.palette.pink[800],
      '& path': {
        fill: theme.palette.pink[800]
      }
    },
    green: {
      color: theme.palette.green[800],
      '& path': {
        fill: theme.palette.green[800],
      }
    },
    icon: {
      height: 36,
      width: 36,
    },
    closeIcon: {
      '& path': {
        fill: theme.palette.green[900]
      }
    }
  }),
)

/**
* Presenter
*/

export default function CompleteEdit(props:CompleteEditProps) {

  const classes = useStyles({})
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setNormalEditSidebarShown(false))

    return () => {
      dispatch(clearNormalEditSidebarShown())
    }
  }, [])
  const { setIsRegister } = props

  return (
    <Box className={classes.root} textAlign="center" width={600} mx="auto">
      <CheckOutlineIcon />
      <Typography variant="h3" className={clsx(classes.bold, classes.title)}>承認申請いたしました</Typography>
      <Box className={classes.box} mx="auto" mt="41px">
        <Typography variant="body2" className={clsx(classes.bold, classes.subTitle)}>今後の流れ</Typography>
        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
          <CheckIcon className={clsx(classes.icon, classes.green)} />
          <Box ml={2} textAlign="start">
          <Typography variant="body2" className={clsx(classes.bold, classes.subTitle, classes.green)}>承認されたら</Typography>
          <Typography variant="body2">ランディングページ詳細画面より「公開」を行い、公開URLを発行しましょう！</Typography>
          </Box>

          {/* TODO: メール配信機能で宛先を指定してURLをお知らせできます。」を追加（9月） */}
        </Box>
        <Box mt={2} display="flex" flexWrap="wrap" alignItems="center">
          <ErrorIcon className={clsx(classes.icon, classes.pink)} />
          <Box ml={2} textAlign="start">
          <Typography variant="body2" className={clsx(classes.bold, classes.subTitle, classes.pink)}>差し戻しされたら</Typography>
          <Typography variant="body2">差し戻し理由を確認し、ランディングページを再編集の上、再度承認申請を行いましょう。</Typography>
          </Box>

        </Box>
      </Box>
      <Box mx="auto" mt={4} width={332} height={48}>
        <SecondaryButton
          click={() => {
            setIsRegister(true)
          }} text="編集モードを終了する" icon={<CloseIcon className={classes.closeIcon} />} />
      </Box>
    </Box>
  )
}

/* eslint-enable */

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar } from 'components/atoms';
import {
  Content,
  MyLPsTable,
  MyLPSearchField,
  TagSelectDialog,
  LpProductDetailDialog,
} from 'components/organisms';
import { hooks } from 'libs';
import { Lps, LpSearchCondition, LpTags } from 'core/domain/lp';
import { useHistory } from 'react-router-dom';
import { UserInfoDetail } from 'core/domain/user';

/**
 * Interface
 */

export interface MyLPsPageProps {
  own: boolean;
  lps: Lps | null;
  rows: number;
  tags: LpTags | null;
  lpOwnSearchCondition: LpSearchCondition | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleCheckStatus?: (status: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeTag?: (tags: string) => void;
  handleClickLpProductInterestedCount: (lpId: number) => void;
  handleClickLpProductDocumentDownloadCount: (lpId: number) => void;

  openLpProductDetailDialog:
    | 'interested-count'
    | 'document-download-count'
    | undefined;
  handleClickLpProductClose: () => void;
  handleChangeSubordinatesSwitch?: (checked: boolean) => void;
  handleChangeApprovalTaskSwitch?: (checked: boolean) => void;
  deleteTag?: (val: string) => void;
  handleChangeLpTantoUser: (users: Array<UserInfoDetail>) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */
const MyLPsPage = (props: MyLPsPageProps) => {
  const classes = useStyles({});
  const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const { navigate } = hooks.useNavigate();
  const { setCurrentLpsUrl } = hooks.useLpsNavigation();
  const {
    own,
    lps,
    rows,
    tags,
    lpOwnSearchCondition,
    handleChangePage,
    handleChangeSortKey,
    handleCheckStatus,
    handleChangeFreeWord,
    handleChangeTag,
    handleChangeSubordinatesSwitch,
    handleChangeApprovalTaskSwitch,
    handleClickLpProductInterestedCount,
    handleClickLpProductDocumentDownloadCount,
    openLpProductDetailDialog,
    handleClickLpProductClose,
    deleteTag,
    handleChangeLpTantoUser,
  } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>(
    lpOwnSearchCondition && lpOwnSearchCondition?.tags
      ? lpOwnSearchCondition?.tags?.split(',')
      : [],
  );

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag]);
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);
  const [changeFlag, setChangeFlag] = useState<boolean>(own);
  const history = useHistory();

  useEffect(
    () =>
      history.listen((location) => {
        const nowOwn = location.pathname.includes('/own');
        if (changeFlag !== nowOwn) {
          setSelectedTags([]);
          setInitialSelectedTags([]);
          setChangeFlag(nowOwn);
        }
      }),
    [
      history,
      setSelectedTags,
      setInitialSelectedTags,
      changeFlag,
      setChangeFlag,
    ],
  );

  useEffect(() => {
    if (!lpOwnSearchCondition || !lpOwnSearchCondition.tags) {
      setSelectedTags([]);
      setInitialSelectedTags([]);

      return;
    }

    setSelectedTags(lpOwnSearchCondition.tags.split(','));
    setInitialSelectedTags(
      JSON.parse(JSON.stringify(lpOwnSearchCondition.tags.split(','))),
    );
    // eslint-disable-next-line
  }, [lpOwnSearchCondition?.tags]);

  return (
    <Box mt={5}>
      <Content
        title={
          own
            ? 'わたしのランディングページ一覧'
            : '部下のランディングページ一覧'
        }
      >
        <div>
          <MyLPSearchField
            own={own}
            searchCondition={lpOwnSearchCondition}
            handleCheckStatus={handleCheckStatus}
            handleChangeFreeWord={handleChangeFreeWord}
            addTags={() => setTagDialogVisible(true)}
            subordinatesSwitchValue={lpOwnSearchCondition?.subordinates}
            handleChangeSubordinatesSwitch={handleChangeSubordinatesSwitch}
            approvalTaskSwitchValue={lpOwnSearchCondition?.approval_task}
            handleChangeApprovalTaskSwitch={handleChangeApprovalTaskSwitch}
            deleteTag={(val: string) => {
              if (deleteTag) deleteTag(val);
            }}
            handleChangeLpTantoUser={handleChangeLpTantoUser}
          />
          <Box className={classes.table}>
            <MyLPsTable
              own={own}
              lps={lps}
              clickLp={(id: number, approved: boolean) => {
                if (!approved) {
                  navigate(`/adm/lps/${id}`);
                } else {
                  navigate(`/adm/lps/${id}/approved`);
                }
                setCurrentLpsUrl();
              }}
              handleClickLpProductInterestedCount={
                handleClickLpProductInterestedCount
              }
              handleClickLpProductDocumentDownloadCount={
                handleClickLpProductDocumentDownloadCount
              }
              handleChangeSortKey={handleChangeSortKey}
              searchCondition={lpOwnSearchCondition}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  lpOwnSearchCondition ? lpOwnSearchCondition.page : 1
                }
                maxPage={lps ? Math.ceil(lps?.totalCount / rows) : 1}
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={lps?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
      <TagSelectDialog
        handleCheck={handleTagCheck}
        tags={tags?.lpTags ? tags?.lpTags : undefined}
        usedTags={undefined}
        open={tagDialogVisible}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagDialogVisible(false);
        }}
        handleSubmit={(tags) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          if (handleChangeTag) handleChangeTag(tags.join(','));
          setTagDialogVisible(false);
        }}
        selectedTags={selectedTags}
        tagType="own"
        category="LP"
      />
      <LpProductDetailDialog
        open={openLpProductDetailDialog !== undefined}
        handleCancel={() => handleClickLpProductClose()}
        mode={openLpProductDetailDialog}
      />
    </Box>
  );
};

export default MyLPsPage;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { PreviousLPsURL } from 'core/domain/lp'

const initialState = null

export function createInitialState(): PreviousLPsURL | null {
  return initialState
}

export const SET_PREVIOUS_LPS_URL = 'previousLpsUrl/set' as const
export const CLEAR_PREVIOUS_LPS_URL = 'previousLpsUrl/clear' as const

export function setPreviousLpsUrl(data: PreviousLPsURL) {
  return {
    type: SET_PREVIOUS_LPS_URL,
    payload: {
      data,
    },
  }
}

export function clearPreviousLpsUrl() {
  return {
    type: CLEAR_PREVIOUS_LPS_URL,
  }
}

type setPreviousLpsUrlAction = ReturnType<typeof setPreviousLpsUrl>
type ClearPreviousLpsUrlAction = ReturnType<typeof clearPreviousLpsUrl>
type Action = setPreviousLpsUrlAction | ClearPreviousLpsUrlAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PREVIOUS_LPS_URL:
      return action.payload.data
    case CLEAR_PREVIOUS_LPS_URL:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import { useEffect } from 'react'
import NormalEditGreeting from 'components/pages/NormalEditGreeting'
import { Modules, Usecases } from 'core'
import { useDispatch, useSelector } from 'react-redux'
import { clearTantoCompanies } from 'core/modules/tantoCompanies'
import { hooks } from 'libs'

/**
 * Presenter
 */

const NormalMainVisualEditContainer = () => {

  const dispatch = useDispatch()
  const tantoCompanies = useSelector((state:Modules.AppState) => state.tantoCompanies)
  const normalEditErrors = useSelector((state:Modules.AppState) => state.normalEditErrors)
  const newLp = useSelector((state:Modules.AppState) => state.newLp)
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)
  const token = hooks.useAuth().accessToken
  const myLibrary = useSelector((state: Modules.AppState) => state.myLibrary)
  const lpMainVisualImages = useSelector((state: Modules.AppState) => state.lpMainVisualImages)
  // 担当会社名一覧取得
  useEffect(() => {
    if (!userInfo) return
    dispatch(Usecases.settings.sendGetTantoCompanies(token, userInfo.tenantId))
    dispatch(Usecases.settings.sendGetMainVisualImage(token, userInfo.tenantId))
    // マイライブラリから選択
    dispatch(Usecases.myLibrary.sendGetMyLibrary(token, userInfo.tenantId))
    dispatch(clearTantoCompanies())
    
  }, [token,userInfo,dispatch])
  

  return <NormalEditGreeting
    newLp={newLp}
    tantoCompanies={tantoCompanies}
    normalEditErrors={normalEditErrors}
    myLibrary={myLibrary}
    lpMainVisualImage={lpMainVisualImages}
    myLibraryImage={myLibrary?.myLibraryFiles?myLibrary?.myLibraryFiles:null}
    />
}
export default NormalMainVisualEditContainer;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import NotFound from 'components/pages/NotFound'

/**
 * Presenter
 */

export default function NotFoundPageContainer() {
  return <NotFound />
}
/* eslint-enable */

import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  SearchTextField,
  AdditionalTag,
  SearchProductTag,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { CustomersQueryParameter } from 'core/domain/customer';
import clsx from 'clsx';
/**
 * Interface
 */

export interface CustomersSearchFieldProps {
  handleChangeFreeWord?: (freeWord: string) => void;
  customersQueryParameter: CustomersQueryParameter | null;
  addTags?: () => void;
  deleteTag: (val:string) =>void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,

      color: theme.palette.gray[800],
    },
    tagTitle: {
      marginBottom: 'auto',
    },
  }),
);

const CustomersSearchField = (props: CustomersSearchFieldProps) => {
  const classes = useStyles({});
  const { handleChangeFreeWord, customersQueryParameter, addTags, deleteTag} = props;
  // タグ検索用
  const tags: string[] = useMemo(() => {
    if (!customersQueryParameter || !customersQueryParameter.tags) return [];

    return customersQueryParameter.tags.split(',');
  }, [customersQueryParameter]);

  return (
    <Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="caption">
          フリーワード検索
        </Typography>
        <Box className={classes.freeWord}>
          <SearchTextField
            label="検索ワードを入力"
            handleChangeText={handleChangeFreeWord}
            value={
              customersQueryParameter?.free_word
                ? customersQueryParameter.free_word
                : ''
            }
          />
        </Box>
        <Typography className={classes.description} variant="caption">
          登録されている情報全体からフリーワードで検索します。
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography
          className={clsx([classes.title, classes.tagTitle])}
          variant="overline"
        >
          タグ検索
        </Typography>
        <Box>
          {tags.map((tag, index,) => (
            <SearchProductTag
             item={tag}
             key={index}
             deleteTag={(val) => deleteTag(val)} />
          ))}
          <AdditionalTag click={addTags} />
        </Box>
      </Box>
    </Box>
  );
};
export default CustomersSearchField;
import { FreeWord } from 'core/domain/freeWord';

const initialState = null;

export const createInitialState = (): FreeWord | null => initialState;

export const SET_FREEWORD = 'freeWord/set' as const;
export const CLEAR_FREEWORD  = 'freeWord/clear' as const;

export const setFreeWord = (data: FreeWord) => ({
  type: SET_FREEWORD,
  payload: {
    data,
  },
});

export const clearFreeWord = () => ({
  type: CLEAR_FREEWORD,
});

type SetFreeWordAction = ReturnType<typeof setFreeWord>;
type ClearFreeWordAction = ReturnType<typeof clearFreeWord>;
type Action = SetFreeWordAction | ClearFreeWordAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_FREEWORD:
      return action.payload.data;
    case CLEAR_FREEWORD:
      return initialState;
    default:
      return state;
  }
};

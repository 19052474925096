/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Examples } from 'core/domain/example'

const initialState = null

export function createInitialState(): Examples | null {
  return initialState
}

export const SET_EXAMPLES = 'examples/set' as const
export const CLEAR_EXAMPLES = 'examples/clear' as const

export function setExamples(data: Examples) {
  return {
    type: SET_EXAMPLES,
    payload: {
      data,
    },
  }
}

export function clearExamples() {
  return {
    type: CLEAR_EXAMPLES,
  }
}

type SetExamplesAction = ReturnType<typeof setExamples>
type ClearExamplesAction = ReturnType<typeof clearExamples>
type Action = SetExamplesAction | ClearExamplesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_EXAMPLES:
      return action.payload.data
    case CLEAR_EXAMPLES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

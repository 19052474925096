/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from 'components/atoms';

/**
 * Interface
 */

export interface SubHeadlineLabelProps {
  text: string;
  require?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.gray[900],
      fontWeight: 'bold',
    },
    impText: {
      color: '#DB3218',
      fontSize: '11px',
      fontWeight: 'bold',
      marginLeft: '8px',
    },
  }),
);

/**
 * Presenter
 */

export default function SubHeadlineLabel(props: SubHeadlineLabelProps) {
  const classes = useStyles({});
  const { text, require } = props;

  return (
    <Typography variant="h4" className={classes.root}>
      {text}
      {require && <span className={classes.impText}>＊必須</span>}
    </Typography>
  );
} /* eslint-enable */

import React from 'react';
import { Box } from 'components/atoms';
import {
  LetterCountTextField,
  MandatoryTitleLabel,
} from 'components/molecules';
import { VariousSettings } from 'core/domain/variousSettings';

interface VariousSettingsInquiriesFieldAreaProps {
  error: string | undefined;
  handleInputChange: (v: string, key: keyof VariousSettings) => void;
  type: 'heading' | 'greeting';
  value: string;
}

const VariousSettingsInquiriesFieldArea = (
  props: VariousSettingsInquiriesFieldAreaProps,
) => {
  const { error, handleInputChange, type, value } = props;
  const mainText =
    type === 'heading' ? 'お問い合わせ見出し' : 'お問い合わせ挨拶';
  const mt = type === 'heading' ? 5 : 3;

  const handleChange = (v: string) => {
    handleInputChange(
      v,
      type === 'heading' ? 'inquiryTitle' : 'inquiryGreetings',
    );
  };

  return (
    <Box mt={mt}>
      <MandatoryTitleLabel mb title={mainText} />
      <LetterCountTextField
        error={error}
        handleChangeText={handleChange}
        label={mainText}
        limit={type === 'heading' ? '20' : '150'}
        maxLength={1000}
        multiline
        value={value}
      />
    </Box>
  );
};

export default VariousSettingsInquiriesFieldArea;

import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { setProductDetail } from 'core/modules/productDetail';
import { setProductTags } from 'core/modules/productTags';
import { setProductWorkflows } from 'core/modules/productWorkflows';
import { setProductExamples } from 'core/modules/productExamples';
import {
  ProductTags,
  ProductDetail,
  ProductRegisterRequest,
  ProductWorkflows,
  ProductExamples,
  Products,
  ProductsQueryParameter,
} from 'core/domain/product';
import { TagsRequest } from 'core/domain/tag';
import { setProducts } from 'core/modules/products';

const didCallPostProduct = (dispatch: Dispatch, data: ProductDetail) => {
  dispatch(setProductDetail(data));
};

export const sendPostProduct =
  (
    token: string | undefined,
    tenantId: string,
    product: ProductRegisterRequest,
    draft: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products`,
      token,
      JSON.stringify({ ...product, draft }),
      dispatch,
      didCallPostProduct,
    );
  };

const didCallGetProductDetailApi = (
  dispatch: Dispatch,
  data: ProductDetail,
) => {
  dispatch(setProductDetail(data));
};

export const sendGetProductDetail =
  (
    token: string | undefined,
    tenantId: string,
    productId: number,
    mode: number,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products/${productId}`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallGetProductDetailApi,
    );
  };

const didCallPutProduct = (dispatch: Dispatch, data: ProductDetail) => {
  dispatch(setProductDetail(data));
};

export const sendPutProducts =
  (
    token: string | undefined,
    tenantId: string,
    productId: string,
    product: ProductRegisterRequest,
    draft: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/products/${productId}`,
      token,
      JSON.stringify({ ...product, draft }),
      dispatch,
      didCallPutProduct,
    );
  };

const didCallGetProductTags = (dispatch: Dispatch, data: ProductTags) => {
  dispatch(setProductTags(data));
};

export const sendGetProductTags =
  (token: string | undefined, tenantId: string, tags: TagsRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/products/tags?tag_type=${tags.tagType || ''}&sort_key=${
        tags.sortKey || ''
      }`,
      token,
      null,
      dispatch,
      didCallGetProductTags,
    );
  };

export const sendPatchProductFavorite =
  (
    token: string | undefined,
    tenantId: string,
    productId: number,
    favorite: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/products/{product_id}/favorite
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/products/${productId}/favorite`,
      token,
      JSON.stringify({ favorite }),
      dispatch,
    );
  };

export const sendPatchProductRecommended =
  (
    token: string | undefined,
    tenantId: string,
    productId: number,
    recommended: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/products/{product_id}/recommended
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/products/${productId}/recommended`,
      token,
      JSON.stringify({ recommended }),
      dispatch,
    );
  };

const didCallGetProductWorkflowsApi = (
  dispatch: Dispatch,
  data: ProductWorkflows,
) => {
  dispatch(setProductWorkflows(data.productWorkflows));
};

export const sendGetProductWorkflows =
  (token: string | undefined, tenantId: string, productId: number) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/products/{product_id}/workflows
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/products/${productId}/workflows`,
      token,
      null,
      dispatch,
      didCallGetProductWorkflowsApi,
    );
  };

const didCallGetProductExamplesApi = (
  dispatch: Dispatch,
  data: ProductExamples,
) => {
  dispatch(setProductExamples(data.productExamples));
};

export const sendGetProductExamples =
  (token: string | undefined, tenantId: string, productId: number) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/products/{product_id}/examples
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/products/${productId}/examples`,
      token,
      null,
      dispatch,
      didCallGetProductExamplesApi,
    );
  };

const didCallPatchProductWorkflowStatusApi = (
  dispatch: Dispatch,
  data: ProductWorkflows,
) => {
  dispatch(setProductWorkflows(data.productWorkflows));
};

export const sendPatchProductWorkflowStatus =
  (
    token: string | undefined,
    tenantId: string,
    productId: number,
    workflowId: number,
    step: number,
    status: number,
    message?: string,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/products/{product_id}/workflows/{workflow_id}/status
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/products/${productId}/workflows/${workflowId}/status`,
      token,
      JSON.stringify({ step, status, message }),
      dispatch,
      didCallPatchProductWorkflowStatusApi,
    );
  };

const didCallGetProducts = (dispatch: Dispatch, data: Products) => {
  dispatch(setProducts(data));
};

export const sendGetProducts =
  (
    token: string | undefined,
    tenantId: string,
    param: ProductsQueryParameter,
  ) =>
  async (dispatch: Dispatch) => {
    const api = Api.createApi(param);
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/products?${api}`,
      token,
      null,
      dispatch,
      didCallGetProducts,
    );
  };

const didCallGetProductsByPage = (dispatch: Dispatch, data: Products) => {
  dispatch(setProducts(data));
};

export const sendGetProductsByPage =
  (
    token: string | undefined,
    tenantId: string,
    param: ProductsQueryParameter,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/products${api}`,
      token,
      null,
      dispatch,
      didCallGetProductsByPage,
    );
  };

const didCallPostProductTrash = (dispatch: Dispatch, data: ProductDetail) => {
  dispatch(setProductDetail(data));
};

export const sendPostProductTrash =
  (token: string | undefined, tenantId: string, productId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products/${productId}/trash`,
      token,
      null,
      dispatch,
      didCallPostProductTrash,
    );
  };

const didCallPostProductStop = (dispatch: Dispatch, data: ProductDetail) => {
  dispatch(setProductDetail(data));
};

export const sendPostProductStop =
  (token: string | undefined, tenantId: string, productId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products/${productId}/stop`,
      token,
      null,
      dispatch,
      didCallPostProductStop,
    );
  };

const didCallPostProductReopen = (dispatch: Dispatch, data: ProductDetail) => {
  dispatch(setProductDetail(data));
};

export const sendPostProductReopen =
  (token: string | undefined, tenantId: string, productId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products/${productId}/reopen`,
      token,
      null,
      dispatch,
      didCallPostProductReopen,
    );
  };

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ExampleDetail } from 'core/domain/example'

const initialState = null

export function createInitialState(): ExampleDetail | null {
  return initialState
}

export const SET_EXAMPLE_DETAIL = 'example-detail/set' as const
export const CLEAR_EXAMPLE_DETAIL = 'example-detail/clear' as const

export function setExampleDetail(data: ExampleDetail) {
  return {
    type: SET_EXAMPLE_DETAIL,
    payload: {
      data,
    },
  }
}

export function clearExampleDetail() {
  return {
    type: CLEAR_EXAMPLE_DETAIL,
  }
}

type SetExampleDetailAction = ReturnType<typeof setExampleDetail>
type ClearExampleDetailAction = ReturnType<typeof clearExampleDetail>
type Action = SetExampleDetailAction | ClearExampleDetailAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_EXAMPLE_DETAIL:
      return action.payload.data
    case CLEAR_EXAMPLE_DETAIL:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

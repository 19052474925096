/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ProductTags } from 'core/domain/product'

const initialState = null

export function createInitialState(): ProductTags | null {
  return initialState
}

export const SET_PRODUCT_TAGS = 'product-tags/set' as const
export const CLEAR_PRODUCT_TAGS = 'product-tags/clear' as const

export function setProductTags(data: ProductTags) {
  return {
    type: SET_PRODUCT_TAGS,
    payload: {
      data,
    },
  }
}

export function clearProductTags() {
  return {
    type: CLEAR_PRODUCT_TAGS,
  }
}

type SetProductTagsAction = ReturnType<typeof setProductTags>
type ClearProductTagsAction = ReturnType<typeof clearProductTags>
type Action = SetProductTagsAction | ClearProductTagsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCT_TAGS:
      return action.payload.data
    case CLEAR_PRODUCT_TAGS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { setManageUsersSearchCondition } from 'core/modules/manageUsersSearchCondition';
import { ManageUsersSortKey } from 'core/domain/user';
import { hooks } from 'libs';
import ManageUsers from 'components/pages/ManageUsers';
import { clearManageUsers } from 'core/modules/manageUsers';

import { fileToBase64 } from 'libs/file';

const pageRows = 10;

/**
 * Presenter
 */

const ManageUsersPageContainer = () => {
  const dispatch = useDispatch();
  // const { navigate } = hooks.useNavigate();

  const manageUsers = useSelector(
    (state: Modules.AppState) => state.manageUsers,
    shallowEqual,
  );
  const manageUsersSearchCondition = useSelector(
    (state: Modules.AppState) => state.manageUsersSearchCondition,
    shallowEqual,
  );

  const [
    isUsersStatusBatchChangeDialogModalOpen,
    setUsersStatusBatchChangeDialogOpen,
  ] = React.useState(false);

  const [
    isUsersStatusBatchChangeComfirmOpen,
    setUsersStatusBatchChangeComfirmOpen,
  ] = React.useState(false);

  const [csvUploadSuccessDialogOpen, setCsvUploadSuccessDialogOpen] =
    React.useState(false);

  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;

  const handleRegister = (data: string) => {
    if (!userInfo || !data) return;
    dispatch(Usecases.user.sendPutNewUsersCsv(token, userInfo.tenantId, data));

    dispatch(
      Usecases.user.sendGetManageUsers(
        token,
        userInfo.tenantId,
        manageUsersSearchCondition,
      ),
    );
  };

  const setCsvData = async (file: File) => {
    await fileToBase64(file).then((res) => {
      const newUsersCsvData = {
        data: res as string,
      };
      handleRegister(newUsersCsvData.data);
    });
  };

  const [fileDialogOpen, setFileDialogOpen] = useState(false);

  const handleFileSubmit = async (files: File[]) => {
    if (!files.length || !files[0]) return;

    await setCsvData(files[0]);
    setFileDialogOpen(false);
    setCsvUploadSuccessDialogOpen(true);
  };

  const onFileUploadDialog = () => {
    setFileDialogOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(clearManageUsers());
    },
    [dispatch],
  );

  // みんなのLP一覧取得/リクエストパラメーター初期値設定
  useEffect(() => {
    if (!manageUsersSearchCondition) {
      dispatch(setManageUsersSearchCondition({ page: 1, row: pageRows }));

      return;
    }

    if (!userInfo) return;
    dispatch(
      Usecases.user.sendGetManageUsers(
        token,
        userInfo.tenantId,
        manageUsersSearchCondition,
      ),
    );
  }, [manageUsersSearchCondition, userInfo, dispatch, token]);

  // 取得したLP数からページ数を計算しリクエストパラメーターに設定
  useEffect(() => {
    if (
      manageUsers &&
      manageUsers.users.length === 0 &&
      manageUsers.totalCount > 0
    ) {
      const latestPage = Math.ceil(manageUsers?.totalCount / pageRows);
      dispatch(
        setManageUsersSearchCondition({
          ...manageUsersSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [manageUsers, dispatch, manageUsersSearchCondition]);

  const handleOpenStatusBatchChange = () => {
    setUsersStatusBatchChangeDialogOpen(true);
  };

  const handleCloseStatusBatchChange = () => {
    setUsersStatusBatchChangeDialogOpen(false);
  };

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setManageUsersSearchCondition({
        ...manageUsersSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (manageUsersSearchCondition?.sort_key === sortKey) {
      order = manageUsersSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setManageUsersSearchCondition({
        ...manageUsersSearchCondition,
        sort_key: sortKey as ManageUsersSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setManageUsersSearchCondition({
        ...manageUsersSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  // ユーザ更新処理
  const handleUsersStatusBatchChange = async (available: boolean) => {
    if (!userInfo) return;

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.user.sendPostUsersUpdateStatus(token, userInfo.tenantId, {
        userIds: manageUsers
          ? manageUsers.users
              .filter((user) => {
                if (user.checked !== undefined && user.checked) return true;

                return false;
              })
              .map((user) => user.userId)
          : [],

        available,
      }),
    );
    handleCloseStatusBatchChange();
    window.location.reload();
  };

  return (
    <ManageUsers
      manageUsers={manageUsers}
      rows={pageRows}
      isUsersStatusBatchChangeDialogModalOpen={
        isUsersStatusBatchChangeDialogModalOpen
      }
      isComfirmOpen={isUsersStatusBatchChangeComfirmOpen}
      setComfirmOpen={setUsersStatusBatchChangeComfirmOpen}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleChangeFreeWord={handleChangeFreeWord}
      handleOpenStatusBatchChange={handleOpenStatusBatchChange}
      handleCloseStatusBatchChange={handleCloseStatusBatchChange}
      handleUsersStatusBatchChange={handleUsersStatusBatchChange}
      fileDialogOpen={fileDialogOpen}
      setFileDialogOpen={setFileDialogOpen}
      handleFileSubmit={handleFileSubmit}
      onFileUploadDialog={onFileUploadDialog}
      csvUploadSuccessDialogOpen={csvUploadSuccessDialogOpen}
      setCsvUploadSuccessDialogOpen={setCsvUploadSuccessDialogOpen}
    />
  );
};
export default ManageUsersPageContainer;

import React, { useState, useEffect, useCallback } from 'react';
import { Box, HeadlineLabel } from 'components/atoms';
import { TargetPeriodLabel } from 'components/molecules';
import { DashboardReactionCardArea } from 'components/organisms';
import { ReactionRange } from 'components/pages/LPDetail';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { validDate } from 'libs/date';
import { INVALID_DATE } from 'constants/text';

interface DashboardTotalReactionAreaProps {
  searchReaction: (
    start: string,
    end: string,
    type: 'total' | 'ranking',
  ) => void;
}

interface ValidationError {
  startReactionRange?: string;
  endReactionRange?: string;
}

const DashboardTotalReactionArea = (props: DashboardTotalReactionAreaProps) => {
  const { searchReaction } = props;
  const addTerm = useSelector(
    (state: Modules.AppState) => state.dashboardAddTerm,
  );
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [reactionRange, setReactionRange] = useState<ReactionRange>();

  const searchReactionByRange = useCallback(
    (start: string | undefined, end: string | undefined) => {
      const [strStart, strEnd] = [start || '', end || ''];
      const startDate = validDate(strStart);
      const endDate = validDate(strEnd);

      if (startDate !== undefined && endDate !== undefined) {
        setValidationError(undefined);
        searchReaction(strStart, strEnd, 'total');
      }

      setValidationError({
        startReactionRange: startDate !== undefined ? undefined : INVALID_DATE,
        endReactionRange: endDate !== undefined ? undefined : INVALID_DATE,
      });
    },
    [searchReaction],
  );

  const handleChangeStartDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      start: date.replaceAll('/', ''),
    });
  };

  const handleChangeEndDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      end: date.replaceAll('/', ''),
    });
  };

  useEffect(() => {
    if (reactionRange)
      searchReactionByRange(reactionRange.start, reactionRange.end);
  }, [reactionRange, searchReactionByRange]);

  useEffect(() => {
    if (reactionRange || !addTerm) return;

    setReactionRange({
      start: addTerm?.addTermFrom,
      end: addTerm?.addTermTo,
    });
  }, [reactionRange, addTerm]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mt={5} mb={2}>
        <HeadlineLabel text="これまでの累計リアクション数" />
        <TargetPeriodLabel
          start={reactionRange?.start}
          end={reactionRange?.end}
          startError={validationError?.startReactionRange}
          endError={validationError?.endReactionRange}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
        />
      </Box>
      <DashboardReactionCardArea />
    </Box>
  );
};

export default DashboardTotalReactionArea;

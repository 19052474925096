/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LPRegisterRequest } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LPRegisterRequest | null {
  return initialState
}

export const SET_NEW_LP = 'new-lp/set' as const
export const CLEAR_NEW_LP = 'new-lp/clear' as const

export function setNewLp(data: LPRegisterRequest) {
  return {
    type: SET_NEW_LP,
    payload: {
      data,
    },
  }
}

export function clearNewLp() {
  return {
    type: CLEAR_NEW_LP,
  }
}

type SetNewLpAction = ReturnType<typeof setNewLp>
type ClearNewLpAction = ReturnType<typeof clearNewLp>
type Action = SetNewLpAction | ClearNewLpAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NEW_LP:
      return action.payload.data
    case CLEAR_NEW_LP:
      return initialState
    default:
      return state
  }
}/* eslint-enable */

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  IconButton,
  Button,
  OneLineTextField,
  HeadlineLabel,
  Grid,
  Typography,
  SecondaryButton,
} from 'components/atoms';
import { CloseIcon, CloseWhiteIcon } from 'assets/images';
import { inquiryItemOptions } from 'core/domain/inquiries';
import { ConfirmDialog } from '..';

interface InquirySelectDialog {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (
    index: number | undefined,
    options: Array<inquiryItemOptions> | undefined,
  ) => void;
  selectedId?: number;
  formOptions: Array<inquiryItemOptions> | undefined;
  index: number | undefined;
}

interface NameErrors {
  [key: number]: string;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    scroll: {
      overflow: 'scroll',
      overflowY: 'auto',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    deleteOption: {
      '& span svg path': {
        fill: theme.palette.common.black,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      borderColor: theme.palette.green[800],
    },
    img: {
      width: 152,
      height: 152,
      objectFit: 'cover',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.green[800],
      },
    },
    button: {
      textAlign: 'center',
      background: theme.palette.common.white,
      borderColor: theme.palette.green[800],
      borderStyle: 'dashed',
      borderWidth: 2,
      color: theme.palette.green[800],
      height: 36,
      width: '100%',
      fontWeight: 'bold',
      boxShadow: 'none',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      '&:hover': {
        // backgroundColor: theme.palette.green[700],
        boxShadow: 'none',
      },
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
    errorBox:{
      position: 'relative',
    },
    errorText:{    
      position: 'absolute',
      bottom: 50,
      left: '35%',
    },
  }),
);

const InquirySelectDialog: React.FC<InquirySelectDialog> = (props) => {
  const classes = useStyles({});
  const { open, handleCancel, handleSubmit, formOptions, index } = props;
  const [options, setOptions] = useState<Array<inquiryItemOptions>>([]);
  const [nameError, setNameError] = useState<NameErrors | undefined>(undefined);
  const [duplicateError, setDuplicateError] = useState<null | string>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    if (formOptions === undefined) return;
    setOptions(formOptions);
  }, [formOptions]);

  const checkChangeValues = () => {
    if (JSON.stringify(formOptions) !== JSON.stringify(options)) {
      setConfirmDialogOpen(true);

      return;
    }

    handleCancel();
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogSubmit = () => {
    handleCancel();
  };

  const addOption = () =>
    setOptions([
      ...options,
      {
        displayOrder: options.length,
        displayOptionName: '',
      },
    ]);

  const handleChageText = (text: string, index: number) => {
    setOptions((prev) =>
      prev.map((option, idx) => {
        if (index !== idx) return option;

        return {
          ...option,
          displayOptionName: text,
        };
      }),
    );
  };

  const handleSave = () => {
    const duplicates = options.filter(
      (item, index, self) =>
        self.findIndex(
          (item2) => item.displayOptionName === item2.displayOptionName,
        ) !== index,
    );
    if (duplicates.length) {
      setDuplicateError('重複しています');
    } else {
      setDuplicateError(null);
    }
    if (
      options === undefined ||
      index === undefined ||
      nameError !== undefined ||
      duplicates.length
    )
      return;
    handleSubmit(index, options);
  };

  const deleteOption = (index: number) => {
    const filteredOptions = options.filter((_, idx) => index !== idx);
    const newOptions = filteredOptions.map((option, idx) => ({
      ...option,
      displayOrder: idx,
    }));

    setOptions(newOptions);
  };

  useEffect(() => {
    let errors: NameErrors | undefined = options.reduce(
      (result: NameErrors, option: inquiryItemOptions, index) => {
        if (!option.displayOptionName) {
          // パラメータ変更の警告を無効化
          // eslint-disable-next-line no-param-reassign
          result[index] = '選択肢を入力してください。';
        } else if (option.displayOptionName.length > 100) {
          // eslint-disable-next-line no-param-reassign
          result[index] = '100文字以内で入力してください。';
        } else if (nameError && nameError[index]) {
          // eslint-disable-next-line no-param-reassign
          delete result[index];
        }

        return result;
      },
      {},
    );

    // エラーが無い場合、エラー表示を消す
    if (Object.keys(errors).length < 1) {
      errors = undefined;
    }
    setNameError(errors);
    // nameErrorを更新するための処理なので、nameError自体は監視不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={checkChangeValues}
    >
      <ConfirmDialog
        buttonText="終了する"
        open={confirmDialogOpen}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={handleConfirmDialogClose}
        handleSubmit={handleConfirmDialogSubmit}
      />
      <Box textAlign="center">
        <HeadlineLabel text="選択肢編集" />
        <Grid container justify="center">
          <Grid
            item
            xs={8}
            style={{ overflow: 'auto', maxHeight: 'calc(100vh - 400px)' }}
          >
            {options.map((item, index) => (
              <Box
                p={1.5}
                display="flex"
                alignItems="flex-start"
                key={item.displayOrder}
              >
                <Box width="100%">
                  <OneLineTextField
                    handleChangeText={(text: string) =>
                      handleChageText(text, index)
                    }
                    value={item.displayOptionName}
                    error={nameError ? nameError[index] : undefined}
                  />
                </Box>
                <IconButton
                  className={classes.deleteOption}
                  onClick={() => deleteOption(index)}
                >
                  <CloseWhiteIcon />
                </IconButton>
              </Box>
            ))}
          </Grid>
          <Grid item xs={8}>
            <Button className={classes.button} onClick={addOption}>
              ＋選択肢を追加する
            </Button>
          </Grid>
        </Grid>

        <DialogActions className={classes.actions}>
          <Box width={332} height={48}>
            <SecondaryButton
              click={checkChangeValues}
              icon={<CloseIcon className={classes.closeIcon} />}
              text="キャンセル"
            />
          </Box>
          <Box className={classes.errorBox}>
            <Typography color="error" className={classes.errorText}>{duplicateError}</Typography>
            <Box width={332} height={48}>
              <PrimaryButton click={handleSave} text="保存" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
      <IconButton onClick={checkChangeValues} className={classes.closeButton}>
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
};

export default InquirySelectDialog;

import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { SortDownIcon } from "assets/images";
import { ApplyStatusLabel } from "components/atoms";
import { TYPOGRAPHY } from "constants/index";
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from "constants/typography";
import { SearchFilter, Trash, Trashes, TrashesSearchCondition } from "core/domain/trashes";
import { formatDateTimeSecISO8601 } from "libs/date";

/**
 * Interface
 */
export interface TrashesTableProps {
  trashes: Trashes | null;
  handleChecked: (id: number, val: boolean, status: number) => void;
  allCheck: (val: boolean) => void;
  handleChangeSortKey: (sortKey: string) => void;
  trashesSearchCondition: TrashesSearchCondition | null;
  checkedTrashes: Array<Trash>;
  headerChecked: boolean;
  searchFilters: SearchFilter[] | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1028,
      tableLayout: 'fixed',
      overflow: 'hidden',
    },
    status: {
      width: 96,
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      minWidth: 1028,
      width: 1028,

      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    checkbox: {
      width: 30,
      maxWidth: 30,
      marginRight: theme.spacing(1),
      '&, .MuiCheckbox-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      }
    },
    dataType: {
      '&>td, p': {
        width: 130,
      }
    },
    id: {
      '&>td, p': {
        width: 50,
      }
    },
    userName: {
      '&>td, p': {
        width: 110,
      }
    },
    date: {
      '&>td, p': {
        width: 110,
      }
    }
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
  maxWidth?: number;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '',
    sortKey: '',
    width: '35',
    maxWidth: 35,
  },
  {
    title: '種類',
    sortKey: 'dataType',
    width: '160',
    maxWidth: 160,
  },
  {
    title: 'ID',
    sortKey: 'dataId',
    width: '70',
    maxWidth: 70,
  },
  {
    title: 'ステータス',
    sortKey: 'dataStatus',
    width: '130',
    maxWidth: 130,
  },
  {
    title: 'タイトル',
    sortKey: 'title',
    width: '100%',
  },
  {
    title: 'ゴミ箱移動者',
    sortKey: 'trashUser',
    width: '135',
    maxWidth: 135,
  },
  {
    title: 'ゴミ箱移動日',
    sortKey: 'trashDate',
    width: '120',
    maxWidth: 120,
  },
];

const TrashesTable = (props: TrashesTableProps) => {
  const classes = useStyles({});
  const {
    trashes,
    trashesSearchCondition,
    handleChecked,
    allCheck,
    handleChangeSortKey,
    checkedTrashes,
    headerChecked,
    searchFilters,
  } = props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <colgroup>
          {TABLE_HEADER_ITEMS.map((item: TableHeaderItems, index) => (
            <col
              key={index}
              width={item.width}
              style={item.maxWidth ? { maxWidth: item.maxWidth } : undefined}
            />
          ))}
        </colgroup>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {index === 0 ? (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={headerChecked}
                        onChange={(e) => allCheck(e.target.checked)}
                      />
                    }
                    label=""
                    key={index}
                  />
                ) : (
                  <TableSortLabel
                    active
                    direction={trashesSearchCondition?.sort_order}
                    IconComponent={() =>
                      item.sortKey === trashesSearchCondition?.sort_key &&
                      trashesSearchCondition?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleChangeSortKey(item.sortKey)}
                  >
                    {item.title}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {trashes &&
            trashes.trashes.map((trash, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={Boolean(
                          checkedTrashes.filter(
                            (item) =>
                              item.dataId === trash.dataId &&
                              item.dataStatus === trash.dataStatus
                          ).length,
                        )}
                        onChange={(e) =>
                          handleChecked(
                            trash.dataId,
                            e.target.checked,
                            trash.dataStatus,
                          )
                        }
                      />
                    }
                    label=""
                    key={index}
                  />
                </TableCell>
                {/* 種類 */}
                <TableCell>
                  <Box className={classes.dataType}>
                    <Typography variant="body2">
                      {searchFilters && searchFilters.filter(
                        (item) => item.dataType === trash.dataType
                      )[0].label}
                    </Typography>
                  </Box>
                </TableCell>
                {/* ID */}
                <TableCell>
                  <Box className={classes.id}>
                    <Tooltip
                      title={trash.dataId ? trash.dataId.toString() : ''}
                      arrow
                      placement="top"
                    >
                      <Typography variant="body2">{trash.dataId}</Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                {/* ステータス */}
                <TableCell>
                  <Box className={classes.status}>
                    <ApplyStatusLabel status={trash.dataStatus} />
                  </Box>
                </TableCell>
                {/* タイトル */}
                <TableCell className={classes.overflow}>
                  <Tooltip
                    title={trash.title ? trash.title : ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {trash.title}
                    </Typography>
                  </Tooltip>
                </TableCell>
                {/* ゴミ箱移動者 */}
                <TableCell className={classes.overflow}>
                  <Box className={classes.userName}>
                    <Tooltip
                      title={
                        trash.trashedUser?.userName ?
                        trash.trashedUser.userName :
                        ''
                      }
                      arrow
                      placement="top"
                    >
                      <Typography variant="body2">
                        {trash.trashedUser.userName}
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                {/* ゴミ箱移動日 */}
                <TableCell>
                  <Box className={classes.date}>
                    <Typography variant="body2">
                      {formatDateTimeSecISO8601(
                        trash.trashedDate ? trash.trashedDate : '',
                      )}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TrashesTable;

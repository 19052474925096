/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpSettings } from 'core/domain/settings'

const initialState = null

export function createInitialState(): LpSettings | null {
  return initialState
}

export const SET_LP_SETTINGS = 'lp-settings/set' as const
export const CLEAR_LP_SETTINGS = 'lp-settings/clear' as const

export function setLpSettings(data: LpSettings) {
  return {
    type: SET_LP_SETTINGS,
    payload: {
      data,
    },
  }
}

export function clearLpSettings() {
  return {
    type: CLEAR_LP_SETTINGS,
  }
}

type SetLpSettingsAction = ReturnType<typeof setLpSettings>
type ClearLpSettingsAction = ReturnType<typeof clearLpSettings>
type Action = SetLpSettingsAction | ClearLpSettingsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_SETTINGS:
      return action.payload.data
    case CLEAR_LP_SETTINGS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, createMuiTheme, ThemeProvider, Theme } from '@material-ui/core/styles'
import { Box, Autocomplete, TextField, Typography } from 'components/atoms'
import { ErrorIcon } from 'assets/images'
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography'
import { COLORS, TYPOGRAPHY } from 'constants/index'
import clsx from 'clsx'

/**
 * Interface
 */
export interface ComboBoxProps{
  options:string[]
  width?: string
  placeholder?: string
  value?: string
  handleChangeText?: (text: string) => void
  disabled?:boolean
  error?: string
  mode?: 'TEXT' | 'PULLDOWN'
  maxLength?: number
  onChange?:(text:string) => void
}

/**
 * Style
 */

export const theme = createMuiTheme({
  overrides: {
    // For Container
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&:hover $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        '&$focused $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        "&&& $input": {
          padding: 0,
          fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
        },
        height: 36,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_800,
        },
      }
    },
  },
})


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
      backgroundColor: theme.palette.common.white,
			'&:hover': {
        backgroundColor: theme.palette.green[700]
      },
      '&, .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
      },
      width:(props:ComboBoxProps) => props.width,
    },
    errorRoot: {
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        backgroundColor: theme.palette.red[800]
      },
    },
    errorInput: {
      color: theme.palette.red[900],
      '&:focus': {
        backgroundColor: theme.palette.red[800]
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900]
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    field: {
      position: 'relative',
      margin: 0,
    },
    icon: {
      top: 6,
      right: theme.spacing(1),
      position: 'absolute',
      padding: 2,
    },
	}),
)

/**
 * Presenter
 */

export default function CommonComboBox(props:ComboBoxProps) {
	const classes = useStyles(props)
  const { options, placeholder, handleChangeText,onChange, value, disabled, error, mode, maxLength } = props

  const handleChangeValue = (value: string) => {
    if (!handleChangeText) return
    if (!options.includes(value)) return
    handleChangeText(value)
  }

	return (
		<ThemeProvider theme={theme}>
      <Box className={classes.field}>
        <Autocomplete
          disabled={disabled}
          className={classes.root}
          options={options}
          value={value || ''}
          onInputChange={(event, value) => handleChangeValue(value)}
          disableClearable={mode === 'TEXT'}
          forcePopupIcon={mode !== 'TEXT'}
          freeSolo={mode === 'TEXT'}
          renderInput={(params) => (
            <TextField
              error={!!error}
              onChange={(event)=>{
                if(!onChange) return
                onChange(event.target.value)
              }}
              placeholder={placeholder}
              {...params}
              variant="outlined"
              inputProps={{...params.inputProps,
                className: clsx({
                  [classes.errorInput]: !!error
                }),
                maxLength}}
              className={clsx({
                [classes.errorRoot]: !!error
              })}
            />
          )}
        />
      </Box>
      {error && <Box className={classes.error}>
        <ErrorIcon />
        <Typography className={classes.errorText}>{error}</Typography>
      </Box>}
		</ThemeProvider>
	)
}/* eslint-enable */

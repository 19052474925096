/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, LetterCountLabel, Dialog, DialogActions, DialogContentText, SupportTextLabel, OneLineTextField, SecondaryButton, PrimaryButton, Typography } from 'components/atoms'
import { MandatoryHeadlineLabel } from 'components/molecules'
import { TYPOGRAPHY } from 'constants/index'
import { useDispatch } from 'react-redux'
import { clearNewLp } from 'core/modules/newLp'
import { hooks } from 'libs'
import { NORMAL_EDIT_TITLE_NOT_ENTERED, NORMAL_EDIT_TITLE_OVER_CHAR } from 'constants/text'

/**
 * Interface
 */
export interface AdminTitleDialogProps{
  title?:string
  handleTextChange:(text:string) => void
  open:boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 854,
      height: 298,
      padding: '24px 24px 0 24px',
      margin: 0,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
    },
    actions: {
      padding: 0
    },
  }),
)

export default function AdminTitleDialog(props:AdminTitleDialogProps) {

  const classes = useStyles({})
  const { title,handleTextChange,open } = props
  const dispatch = useDispatch()
  const navigate = hooks.useNavigate()
  const [error, setError] = useState('')
  const [openDialog, setOpenDialog] = useState(open)

  const handleCancel = () => {
    setOpenDialog(false)
    dispatch(clearNewLp())
    navigate.navigatePop()
  }

  const handleSubmit = () => {
    if(!title?.length || title?.length < 1){
      setError(NORMAL_EDIT_TITLE_NOT_ENTERED)

      return
    }if(title.length > 100){
      setError(NORMAL_EDIT_TITLE_OVER_CHAR)

      return
    }
    setOpenDialog(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{style:{borderRadius: 16 }}}
      classes={{ paper: classes.dialog}}
      disableBackdropClick
      open={openDialog}
      onClose={handleCancel}>
      <Box>
        <Typography variant="body2" className={classes.title} >最初に管理用タイトルを設定してください</Typography>
          <DialogContentText style={{marginBottom:0}} component="div">
            <SupportTextLabel text="※後からも再編集可能です。" />
            <MandatoryHeadlineLabel title="管理用タイトル" />
          </DialogContentText>
          <OneLineTextField
            error={error}
            value={title}
            handleChangeText={(text)=> handleTextChange(text)}/>
          <LetterCountLabel limit="30" count={title ? `${title.length}` : '0'} />
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton click={handleCancel} text="キャンセルして前の画面に戻る" />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton click={()=>handleSubmit()} text="決定して編集に進む" />
            </Box>
          </Box>
          </DialogActions>
        </Box>
    </Dialog>
  )
}
/* eslint-enable */

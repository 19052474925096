/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { InquirySupports } from 'core/domain/inquiries'

const initialState = null

export function createInitialState(): InquirySupports | null {
  return initialState
}

export const SET_INQUIRY_SUPPORTS = 'inquiry-supports/set' as const
export const CLEAR_INQUIRY_SUPPORTS = 'inquiry-supports/clear' as const

export function setInquirySupports(data: InquirySupports) {
  return {
    type: SET_INQUIRY_SUPPORTS,
    payload: {
      data,
    },
  }
}

export function clearInquirySupports() {
  return {
    type: CLEAR_INQUIRY_SUPPORTS,
  }
}

type SetInquirySupportsAction = ReturnType<typeof setInquirySupports>
type ClearInquirySupportsAction = ReturnType<typeof clearInquirySupports>
type Action = SetInquirySupportsAction | ClearInquirySupportsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_INQUIRY_SUPPORTS:
      return action.payload.data
    case CLEAR_INQUIRY_SUPPORTS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

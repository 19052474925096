import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import LPDetail from 'components/pages/LPDetail';
import { setLpDetail, clearLpDetail } from 'core/modules/lpDetail';
import { clearLpReaction } from 'core/modules/lpReaction';
import { clearLpWorkflows } from 'core/modules/lpWorkflows';
import { clearLpInquiries } from 'core/modules/lpInquiries';
import createInquiryCsv from 'libs/csv';
import { clearLpProductDetails } from 'core/modules/lpProductDetails';
import { clearLpExampleDetails } from 'core/modules/lpExampleDetails';
import { hooks } from 'libs';
import {
  CODE_LP_STATUS_DECLINE,
  CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
  CODE_LP_STATUS_AWAITING_APPROVAL,
  CODE_MODE_REFERENCE,
} from 'constants/code';
import { LpOpenSettings } from 'core/domain/lp';
import { setLpOpenSettings } from 'core/modules/lpOpenSettings';

/**
 * Presenter
 */

const LPDetailPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { lpId } = useParams<{ lpId: string }>();
  const [isApproved, setIsApproved] = useState(
    useHistory().location.pathname.includes('approved'),
  );
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );
  const lpReaction = useSelector(
    (state: Modules.AppState) => state.lpReaction,
    shallowEqual,
  );
  const lpWorkflows = useSelector(
    (state: Modules.AppState) => state.lpWorkflows,
    shallowEqual,
  );
  const lpInquiries = useSelector(
    (state: Modules.AppState) => state.lpInquiries,
    shallowEqual,
  );
  const lpProductDetails = useSelector(
    (state: Modules.AppState) => state.lpProductDetails,
    shallowEqual,
  );
  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
    shallowEqual,
  );
  const addTerm = useSelector(
    (state: Modules.AppState) => state.addTerm,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  const token = hooks.useAuth().accessToken;
  const [, setInquiryFlag] = useState(false);
  const inquiryFlag = useRef(false);

  // メール送信一覧、ダイアログクローズ後更新用、ページの値
  const [mailHistoryPage, setmailHistoryPage] = useState<number>(1);

  useEffect(() => {
    dispatch(clearLpInquiries());

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ページ離脱時の変数初期化処理
  useEffect(
    () => () => {
      dispatch(clearLpProductDetails());

      // お問い合わせ新規登録ではlpDetail.productsを
      // 気になったテーマに設定しているため、初期化を回避する
      if (inquiryFlag.current) return;

      if (lpDetail) {
        dispatch(
          setLpDetail({
            ...lpDetail,
            products: [],
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // LPステータスがNULLの場合に「approved」のURLに遷移してほしいと要望あったため
  // 該当する場合は/adm/lps/${lpId}/approvedへnavigateReplaceする
  useEffect(() => {
    if (!userInfo || !lpId) return;

    if (!isApproved && lpDetail?.lpStatus === null) {
      navigate.navigateReplace(`/adm/lps/${lpId}/approved`);
      setIsApproved(true);
    }
  }, [isApproved, lpDetail?.lpStatus, lpId, userInfo, navigate]);

  useEffect(() => {
    if (!userInfo || !lpId) return;

    if (isApproved) {
      dispatch(
        Usecases.lps.sendPostApprovedLpView(
          token,
          userInfo?.tenantId,
          +lpId,
          CODE_MODE_REFERENCE,
        ),
      );
    } else {
      dispatch(
        Usecases.lps.sendPostLpView(
          token,
          userInfo?.tenantId,
          +lpId,
          CODE_MODE_REFERENCE,
        ),
      );
    }
  }, [dispatch, isApproved, lpId, token, userInfo, userInfo?.tenantId]);

  // LP詳細/LPリアクション/LPワークフロー情報/LP問い合わせ一覧取得
  useEffect(() => {
    if (!userInfo) return undefined;
    dispatch(
      Usecases.lps.sendGetLpDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
        isApproved,
        CODE_MODE_REFERENCE,
      ),
    );

    dispatch(
      Usecases.lps.sendGetLpWorkflows(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
      ),
    );
    dispatch(
      Usecases.lps.sendGetLpInquiries(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
      ),
    );

    return () => {
      setInquiryFlag((flg) => {
        if (!flg) dispatch(clearLpDetail);

        return flg;
      });
      dispatch(clearLpReaction());
      dispatch(clearLpWorkflows());
      dispatch(clearLpProductDetails());
      dispatch(clearLpExampleDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isApproved]);

  // 集計期間セット
  useEffect(()=>{
    if(!userInfo) return
      dispatch(
        Usecases.settings.sendGetAddTerm(token, userInfo.tenantId),
      );

    if (addTerm?.addTermFrom !== null || addTerm?.addTermTo !== null) {
      dispatch(
        Usecases.lps.sendGetLpReaction(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          addTerm?.addTermFrom,
          addTerm?.addTermTo,
        ),
      );
    } else {
      dispatch(
        Usecases.lps.sendGetLpReaction(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          undefined,
          undefined,
        ),
      );
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userInfo, isApproved])

  // LP詳細情報取得/承認済LPであればURLを変更する
  useEffect(() => {
    if (!userInfo || !lpWorkflows || !lpWorkflows[0] || !lpDetail) return;
    if (
      !isApproved &&
      lpWorkflows[0].workflowStatus === CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL
    ) {
      if (lpDetail.lpStatus > CODE_LP_STATUS_DECLINE) {
        navigate.navigateReplace(`/adm/lps/${lpId}/approved`);
        dispatch(
          Usecases.lps.sendGetLpDetail(
            token,
            userInfo.tenantId,
            Number.parseInt(lpId, 10),
            true,
            CODE_MODE_REFERENCE,
          ),
        );
      }
    } else {
      dispatch(
        Usecases.lps.sendGetLpDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          isApproved,
          CODE_MODE_REFERENCE,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isApproved, lpWorkflows]);

  /**
   * お気に入り更新処理
   */
  const handleFavoriteUpdate = useCallback(() => {
    if (!lpDetail || !userInfo) return;

    dispatch(
      Usecases.lps.sendPatchLpFavorite(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
        !lpDetail.favorite,
      ),
    );
    dispatch(
      setLpDetail({
        ...lpDetail,
        favorite: !lpDetail.favorite,
        favoriteCount: lpDetail.favoriteCount + (!lpDetail.favorite ? 1 : -1),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail, userInfo, isApproved]);

  /**
   * 期間に応じてリアクション取得
   */
  const searchReaction = useCallback(
    (start?: string, end?: string) => {
      if (!userInfo) return;
      dispatch(
        Usecases.lps.sendGetLpReaction(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          start !== '' ? start : undefined,
          end !== '' ? end : undefined,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo],
  );

  /**
   * 問い合わせ一覧取得
   */
  const inquiryTable = useCallback(
    (sortKey?: string, sortOrder?: string) => {
      if (!userInfo) return;
      dispatch(
        Usecases.lps.sendGetLpInquiries(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          sortKey,
          sortOrder,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo],
  );

  /**
   * LP備考・メモ更新
   */
  const handleNoteUpdate = useCallback(
    (note: string) => {
      if (!lpDetail || !userInfo) return;

      dispatch(
        Usecases.lps.sendPatchLpNote(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          note,
          isApproved,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lpDetail, isApproved, userInfo],
  );

  /**
   * 承認ステータス更新
   */
  const handleWorkflowStatusUpdate = useCallback(
    (step: number, status: number, message?: string) => {
      if (!lpDetail || !lpWorkflows || lpWorkflows.length === 0 || !userInfo)
        return;
      const workflow = lpWorkflows[0];
      dispatch(
        Usecases.lps.sendPatchLpWorkflowStatus(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          workflow.workflowId,
          step,
          status,
          message,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lpDetail, lpWorkflows, userInfo],
  );

  /**
   * 公開一時停止処理
   */
  const handleStop = useCallback(() => {
    if (!lpDetail || !userInfo) return;
    dispatch(
      Usecases.lps.sendPostLpStop(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail, userInfo, isApproved]);

  /**
   * 再公開処理
   */
  const handleReopen = useCallback(() => {
    if (!lpDetail || !userInfo) return;
    dispatch(
      Usecases.lps.sendPostLpReopen(
        token,
        userInfo.tenantId,
        Number.parseInt(lpId, 10),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail, userInfo, isApproved]);

  /**
   * 公開期限変更
   */
  const handleChangeOpenPeriod = useCallback(
    (date?: string, time?: string) => {
      if (!lpDetail || !userInfo) return;
      dispatch(
        Usecases.lps.sendPostLpOpen(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          date,
          time,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lpDetail, userInfo],
  );

  /**
   * CSVダウンロード処理
   */
  const handleCsvDl = useCallback(() => {
    if (!lpDetail || !lpInquiries || !lpInquiries.inquiries) return;
    const content = createInquiryCsv(
      lpInquiries.inquiries,
      lpDetail.lpId,
      lpDetail.title,
      lpDetail.createdUser.userName,
    );

    // downloadFileFromBase64(base64: content, fileName: 'お問い合わせ履歴.csv')

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, content], { type: 'text/csv' });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.target = '_blank';
    tempLink.setAttribute('download', 'お問い合わせ履歴.csv');
    tempLink.click();
  }, [lpInquiries, lpDetail]);

  /**
   * 商材に紐づく導入事例ID一覧
   */
  const exampleIds = useMemo(() => {
    if (!lpDetail || !lpDetail.products || lpDetail.products.length === 0)
      return [];
    let ids: Array<number> = [];
    lpDetail.products.forEach((product) => {
      if (product.exampleIds && product.exampleIds.length > 0) {
        ids = ids.concat(product.exampleIds);
      }
    });

    return ids;
  }, [lpDetail]);

  // 商材に紐づく導入事例詳細情報取得
  useEffect(() => {
    if (!userInfo) return;
    if (
      exampleIds &&
      exampleIds.length > 0 &&
      lpExampleDetails.length !== exampleIds.length
    ) {
      exampleIds.forEach((id) =>
        dispatch(
          Usecases.lps.sendGetExampleDetail(
            token,
            userInfo.tenantId,
            id,
            CODE_MODE_REFERENCE,
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleIds, userInfo]);

  // 商材詳細情報取得
  useEffect(() => {
    if (!userInfo) return;
    if (
      lpDetail &&
      lpDetail.products.length > 0 &&
      lpProductDetails.length !== lpDetail.products.length
    ) {
      lpDetail.products.forEach((product) =>
        dispatch(
          Usecases.lps.sendGetProductDetail(
            token,
            userInfo.tenantId,
            product.productId,
            CODE_MODE_REFERENCE,
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail, userInfo]);

  /**
   * 削除処理
   */
  const handleDelete = () => {
    if (!userInfo || !lpId) return;
    const anotherStatus = lpDetail?.anotherlpStatus;
    dispatch(Usecases.lps.sendPostLpTrash(token, userInfo.tenantId, lpId));
    if (anotherStatus && anotherStatus > CODE_LP_STATUS_AWAITING_APPROVAL) {
      navigate.navigateReplace(`/adm/lps/${lpId}/approved`);
    } else {
      navigate.navigatePop();
    }
  };

  const handleMailHistoryPageChange = useCallback(
    (page: number) => {
      if (!userInfo) return;
      dispatch(
        Usecases.lps.sendGetMailHistory(token, userInfo?.tenantId, lpId, page),
      );
      // ページ数を保持、メールダイアログ閉じた時更新するリクエストパラメータ用
      setmailHistoryPage(page);
    },
    [userInfo, token, dispatch, lpId],
  );

  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetMailHistory(token, userInfo?.tenantId, lpId, 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userInfo, lpId]);
  const handleInquiry = useCallback(() => {
    setInquiryFlag(true);
    inquiryFlag.current = true;
  }, []);

  /**
   * タグ・公開設定取得
   */
  useEffect(() => {
    if (!lpDetail) return;

    dispatch(setLpOpenSettings({
      lpTag: lpDetail.lpTag || '',
      mailSubject: lpDetail.mailSubject || '',
      mailText: lpDetail.mailText || '',
      mailSignature: lpDetail.mailSignature || '',
      passwordSetting: lpDetail.passwordSetting || 1,
      password: lpDetail.password || '',
    }))
  }, [dispatch, lpDetail])

  /**
   * タグ・公開設定更新
   */
   const handleLpOpenSettingsUpdate = useCallback(
    (lpOpenSettings: LpOpenSettings, tag: string) => {
      if (!lpDetail || !userInfo || !lpOpenSettings) return;

      const newOpenSettings = { ...lpOpenSettings };
      newOpenSettings.lpTag = tag;

      const getLpDetail = () => {
        if (!userInfo || !lpDetail) return;
        dispatch(
          Usecases.lps.sendGetLpDetail(
            token,
            userInfo?.tenantId,
            lpDetail?.lpId,
            isApproved,
            CODE_MODE_REFERENCE,
          )
        );
      };

      dispatch(
        Usecases.lps.sendPatchLpOpenSettings(
          token,
          userInfo.tenantId,
          lpDetail.lpId,
          newOpenSettings,
          getLpDetail,
        ),
      );
    }, [dispatch, isApproved, lpDetail, token, userInfo]
  );

  return (
    lpDetail &&
    lpWorkflows && (
      <LPDetail
        lpDetail={lpDetail}
        lpReaction={lpReaction}
        lpReactionRange={addTerm}
        handleFavoriteUpdate={handleFavoriteUpdate}
        searchReaction={searchReaction}
        lpWorkflows={lpWorkflows}
        lpInquiries={lpInquiries}
        inquiryTable={inquiryTable}
        handleNoteUpdate={handleNoteUpdate}
        handleWorkflowStatusUpdate={handleWorkflowStatusUpdate}
        handleStop={handleStop}
        handleReopen={handleReopen}
        handleChangeOpenPeriod={handleChangeOpenPeriod}
        handleCsvDl={handleCsvDl}
        handleInquiry={handleInquiry}
        handleDelete={handleDelete}
        handleMailHistoryPageChange={handleMailHistoryPageChange}
        mailHistoryPage={mailHistoryPage}
        handleLpOpenSettingsUpdate={handleLpOpenSettingsUpdate}
      />
    )
  );
};

export default LPDetailPageContainer;

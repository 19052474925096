import React, { useMemo, useState, useEffect } from 'react';
import { Modules, Usecases } from 'core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NoImage, ErrorIcon } from 'assets/images';
import {
  Button,
  Box,
  Typography,
  PrimaryButton,
  NormalEditBottomButton,
  NormalEditTopButton,
  HeadlineLabel,
  SubHeadlineLabel,
  ButtonLink,
  MultipleLinesTextField,
  InformationIconButton,
  LetterCountLabel,
} from 'components/atoms';
import {
  ConfirmDialog,
  ExampleInformationDialog,
  FileUploadDialog,
  MyLibraryImageSelectDialog,
  NormalEditExampleItem,
  ProductInformationDialog,
  SelectExampleDialog,
} from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import clsx from 'clsx';
import {
  LPRegisterRequest,
  LpRelationProduct,
  ProductSalesImage,
} from 'core/domain/lp';
import { ProductDetail } from 'core/domain/product';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MODE_REFERENCE,
} from 'constants/code';
import { fileToBase64 } from 'libs/file';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setNewLp } from 'core/modules/newLp';
import {
  ExampleDetail,
  Examples,
  ExamplesQueryParameter,
  ProductExample,
} from 'core/domain/example';
import { hooks } from 'libs';
import { useRouteMatch } from 'react-router';
import { NormalEditErrors, ProductsError } from 'core/domain/validationErrors';
import { setNormalEditErrors } from 'core/modules/normalEditErrors';
import { setLpExampleDetails } from 'core/modules/lpExampleDetails';

import {
  NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED,
  NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED,
} from 'constants/text';
import { MyLibrary, MyLibraryFiles } from 'core/domain/myLibrary';
// import { products } from 'core/usecases';

/**
 * Interface
 */
export interface NormalProductEditProps {
  newLp: LPRegisterRequest | null;
  productDetail: ProductDetail | null;
  productExamples: Array<ExampleDetail> | null;
  handleProductRecommendedUpdate: () => Promise<void>;
  handleExampleRecommendedUpdate: () => Promise<void>;
  setExamplesParam: React.Dispatch<
    React.SetStateAction<ExamplesQueryParameter>
  >;
  examplesParam: ExamplesQueryParameter;
  examples: Examples | null;
  setExampleId: React.Dispatch<React.SetStateAction<number>>;
  exampleDetail: ExampleDetail | null;
  copyComment: () => Promise<void>;
  productId: number;
  productIndex: number;
  normalEditErrors: NormalEditErrors | null;
  handleClickFavorite: () => void;
  myLibrary?: MyLibrary | null;
  myLibraryImage: Array<MyLibraryFiles> | null;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.gray[600],
      borderBottomStyle: 'solid',
      wordBreak: 'break-all',
    },
    imageBox: {
      position: 'relative',
    },
    img: {
      width: 162,
      height: 88,
      objectFit: 'contain',
      pointerEvents: 'none',
    },
    button: {
      textAlign: 'center',
      background: theme.palette.common.white,
      borderColor: theme.palette.green[800],
      borderStyle: 'dashed',
      borderWidth: 2,
      color: theme.palette.green[800],
      height: 36,
      width: '100%',
      fontWeight: 'bold',
      boxShadow: 'none',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        boxShadow: 'none',
      },
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(2),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    bold: {
      fontWeight: 'bold',
    },
    copy: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '20px',
      whiteSpace: 'pre-wrap',
    },
    subcopy: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    icon: {
      width: 16,
      height: 16,
    },
    lineheight: {
      lineHeight: '20px',
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    red: {
      color: theme.palette.red[900],
    },
  }),
);

/**
 * Presenter
 */

type Order = 'up' | 'down';
const NormalProductEdit = (props: NormalProductEditProps) => {
  const classes = useStyles({});
  const [openProductInformationDialog, setOpenProductInformationDialog] =
    useState(false);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [openExampleInformationDialog, setOpenExampleInformationDialog] =
    useState(false);
  const [openSelectExampleDialog, setOpenSelectExampleDialog] = useState(false);
  const [selectedExamples, setSelectedExamples] = useState<
    ProductExample[] | undefined
  >([]);
  const [openExitConfirmDialog, setOpenExitConfirmDialog] =
    useState<boolean>(false);

  const [beforeSelectExamples, setBeforeSelectExamples] = useState<
    ProductExample[] | undefined
  >([]);

  const {
    productDetail,
    handleProductRecommendedUpdate,
    handleExampleRecommendedUpdate,
    examples,
    setExamplesParam,
    setExampleId,
    copyComment,
    exampleDetail,
    productId,
    examplesParam,
    productIndex,
    normalEditErrors,
    handleClickFavorite,
    productExamples,
    myLibraryImage,
    myLibrary,
  } = props;
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { url } = useRouteMatch();
  const token = hooks.useAuth().accessToken;

  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
    shallowEqual,
  );

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const newLp = useSelector(
    (state: Modules.AppState) => state.newLp,
    shallowEqual,
  );
  const productComment = useSelector(
    (state: Modules.AppState) => state.productComment,
  );

  const isDuplicatedLP = useSelector(
    (state: Modules.AppState) => state.isDuplicatedLP,
    shallowEqual,
  );
  // マイライブラリから選択
  const [selectFromMyLibraryDisabled, setSelectFromMyLibraryDisabled] =
    useState(false);
  const [myLibraryDialogOpen, setMyLibraryDialogOpen] = useState(false);
  // マイライブラリから選択選択中画像保持
  const [myLibrarySelectedId, setMyLibrarySelectedId] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (
      !newLp ||
      !newLp.products ||
      !newLp?.products[productIndex] ||
      !newLp?.products[productIndex].productSalesImage
    ) {
      setMyLibrarySelectedId(undefined);

      return;
    }

    if (newLp.products[productIndex].productSalesImage?.lpProductImageId) {
      setMyLibrarySelectedId(
        newLp.products[productIndex].productSalesImage?.lpProductImageId,
      );
    }

    // eslint-disable-next-line
  }, [newLp?.products, productIndex]);

  // const memoNewLP = useRef(newLp);

  // const newProducts: LpRelationProduct[] = useMemo(
  //   () =>
  //     memoNewLP.current?.products
  //       ? memoNewLP.current.products.map((product) => ({
  //           ...product,
  //           productSalesImage: isDuplicatedLP
  //             ? undefined
  //             : product.productSalesImage,
  //         }))
  //       : [],
  //   [memoNewLP, isDuplicatedLP],
  // );

  // useEffect(() => {
  //   // LPページ流用作成時はproductSalesImageをundefinedにする

  //   dispatch(
  //     setNewLp({
  //       ...memoNewLP,
  //       products: newProducts,
  //     }),
  //   );
  // }, [dispatch, isDuplicatedLP, newProducts, memoNewLP]);

  useEffect(() => {
    if (myLibrary?.myLibraryFiles?.length) {
      setSelectFromMyLibraryDisabled(false);
    } else {
      setSelectFromMyLibraryDisabled(true);
    }
  }, [myLibrary]);

  useEffect(() => {
    setSelectedExamples(undefined);
  }, [productId]);

  useEffect(() => {
    if (!newLp || !examples) return;
    const product = newLp.products?.find(
      (product) => product.productId === productId,
    );
    const newExamples: Array<ProductExample> = [];
    product?.exampleIds?.forEach((id) => {
      const relationExample = lpExampleDetails.find(
        (example) => example.exampleMaterialId === id,
      );

      if (relationExample) {
        const example: ProductExample = {
          exampleMaterialId: relationExample.exampleMaterialId,
          exampleName: relationExample.exampleName,
          customerName: relationExample.customerName,
          createdDate: relationExample.createdDate,
          updatedDate: relationExample.updatedDate,
          exampleReaction: relationExample.exampleReaction,
          exampleCreatedUser: relationExample.exampleCreatedUser,
          exampleStatus: relationExample.materialPeriod.materialStatus,
          contact: relationExample.contact,
          exampleImage: relationExample.exampleImage,
        };
        newExamples.push(example);
      }
    });
    if (newExamples.length > 0) setSelectedExamples(newExamples);

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [productId, newLp, examples]);

  // 商材のバリデーション
  const checkErrors = () => {
    const productError: ProductsError = {
      comment: undefined,
      productId,
      productImage: undefined,
    };

    let idx = -1;
    normalEditErrors?.products?.map((item, index) => {
      if (item.productId === productId) idx = index;
    });

    if (newLp?.products && newLp.products[productIndex]) {
      const overIndex: string | undefined =
        newLp.products[productIndex].comment;

      if (
        newLp.products[productIndex].comment &&
        newLp.products[productIndex].comment !== '' &&
        (newLp.products[productIndex].productSalesImage?.lpProductImageData ||
          newLp.products[productIndex].productSalesImage?.lpProductImageId ||
          newLp.products[productIndex].productSalesImage?.url)
      ) {
        // 正常
        productError.productImage = undefined;
        productError.comment = undefined;
      } else if (
        newLp.products[productIndex].comment &&
        newLp.products[productIndex].comment !== '' &&
        !newLp.products[productIndex].productSalesImage?.lpProductImageData &&
        !newLp.products[productIndex].productSalesImage?.lpProductImageId &&
        !newLp.products[productIndex].productSalesImage?.url
      ) {
        // 画像なし
        productError.productImage = NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED;
      } else if (
        (!newLp.products[productIndex].comment ||
          newLp.products[productIndex].comment === '') &&
        (newLp.products[productIndex].productSalesImage?.lpProductImageData ||
          newLp.products[productIndex].productSalesImage?.lpProductImageId ||
          newLp.products[productIndex].productSalesImage?.url)
      ) {
        // コメントなし
        productError.comment = NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED;
      }
      if (
        newLp.products[productIndex].comment &&
        overIndex &&
        overIndex.length > 5000 &&
        (newLp.products[productIndex].productSalesImage?.lpProductImageData ||
          newLp.products[productIndex].productSalesImage?.lpProductImageId ||
          newLp.products[productIndex].productSalesImage?.url)
      ) {
        // コメント5000文字以上
        productError.comment = NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR;
      }
    }

    if (
      normalEditErrors &&
      normalEditErrors?.products &&
      normalEditErrors.products[idx]
    ) {
      normalEditErrors.products[idx] = productError;
    } else {
      normalEditErrors?.products?.push(productError);
    }

    dispatch(setNormalEditErrors({ ...normalEditErrors }));
  };

  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [newLp?.products, productIndex]);

  const getCommentError = (): string | undefined => {
    if (!normalEditErrors || !normalEditErrors.products) return undefined;
    const error = normalEditErrors.products.find(
      (productError) => productError.productId === productId,
    );
    if (error) {
      return error.comment;
    }

    return undefined;
  };

  const getImageError = (): string | undefined => {
    if (!normalEditErrors || !normalEditErrors.products) return undefined;
    const error = normalEditErrors.products.find(
      (productError) => productError.productId === productId,
    );
    if (error) {
      return error.productImage;
    }

    return undefined;
  };

  const reorderList = (item: ProductExample, order: Order) => {
    if (!selectedExamples) return;
    const index = selectedExamples.indexOf(item);
    if (index < 0) return;

    const newList = selectedExamples ? [...selectedExamples] : [];
    let targetIndex = 0;
    if (order === 'up') {
      targetIndex = index - 1;
      newList.splice(targetIndex, 2, newList[index], newList[targetIndex]);
    } else {
      targetIndex = index + 1;
      newList.splice(index, 2, newList[targetIndex], newList[index]);
    }

    setSelectedExamples(newList);

    // LPページ流用作成時はproductSalesImageをundefinedにする
    const newProducts: LpRelationProduct[] = newLp?.products
      ? newLp.products.map((product) => ({
          ...product,
          productSalesImage: isDuplicatedLP
            ? undefined
            : // {
              //   lpProductImageId: undefined,
              //   lpProductImageData: undefined,
              //   url: undefined,
              // }
              undefined, // product.productSalesImage,
        }))
      : [];

    // const newProducts: LpRelationProduct[] = newLp?.products
    //   ? [...newLp.products]
    //   : [];

    newProducts.forEach((item) => {
      const productItem = item;
      if (productItem.productId === productId) {
        productItem.exampleIds = newList.map((e) => e.exampleMaterialId);
      }
    });

    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );
  };

  const img = useMemo(() => {
    if (
      !productDetail ||
      !productDetail.productImages ||
      !productDetail.productImages.length ||
      !productDetail.productImages[0]
    )
      return <img src={NoImage} className={classes.img} />;
    switch (productDetail?.productImages[0].materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        return (
          <img
            src={productDetail.productImages[0].url}
            className={classes.img}
          />
        );
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return (
          <video
            src={productDetail.productImages[0].url}
            className={classes.img}
            poster={
              productDetail.productImages[0].thumbnailUrl
                ? productDetail.productImages[0].thumbnailUrl
                : undefined
            }
          />
        );
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return (
          <img
            src={
              productDetail.productImages[0].thumbnailUrl
                ? productDetail.productImages[0].thumbnailUrl
                : NoImage
            }
            className={classes.img}
          />
        );
      default:
        return <img src={NoImage} className={classes.img} />;
    }
  }, [productDetail, classes.img]);

  const salesImage = useMemo(() => {
    if (
      !newLp ||
      !newLp.products ||
      !newLp?.products[productIndex] ||
      !newLp?.products[productIndex].productSalesImage
    ) {
      return <img src={NoImage} className={classes.img} />;
    }

    if (newLp.products[productIndex].productSalesImage?.lpProductImageData) {
      return (
        <img
          src={
            newLp.products[productIndex].productSalesImage?.lpProductImageData
          }
          className={classes.img}
        />
      );
    }
    if (newLp.products[productIndex].productSalesImage?.url) {
      return (
        <img
          src={newLp.products[productIndex].productSalesImage?.url}
          className={classes.img}
        />
      );
    }

    return <img src={NoImage} className={classes.img} />;
  }, [newLp, productIndex, classes.img]);

  // マイライブラリから選択
  const handleFileSubmit = (ids?: number) => {
    const newProducts = newLp?.products ? [...newLp?.products] : [];

    if (!ids) {
      dispatch(
        setNewLp({
          ...newLp,
          tantoImageData: {
            lpTantoImageId: undefined,
            url: undefined,
          },
        }),
      );
    }

    const image = myLibraryImage?.find((image) => image.fileId === ids);
    const imageData = {
      lpProductImageId: image?.fileId,
      lpProductImageData: undefined,
      url: image?.url,
    };

    if (newProducts[productIndex])
      newProducts[productIndex].productSalesImage = imageData;
    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );
  };

  const handleSalesImageSubmit = async (files: File[]) => {
    if (!files || !files.length || !files[0]) return;
    const newProducts = newLp?.products ? [...newLp?.products] : [];

    await fileToBase64(files[0]).then((res) => {
      const imageData = {
        lpProductImageId:
          productIndex !== -1
            ? newProducts[productIndex].productSalesImage?.lpProductImageId
            : undefined,
        lpProductImageData: res as string,
        url: undefined,
      } as ProductSalesImage;
      if (newProducts[productIndex]) {
        newProducts[productIndex].productSalesImage = imageData;
      }
      dispatch(
        setNewLp({
          ...newLp,
          products: newProducts,
        }),
      );
    });
    setOpenFileUploadDialog(false);
  };

  const handleTextChange = (text: string) => {
    const newProducts: LpRelationProduct[] = newLp?.products
      ? [...newLp.products]
      : [];

    if (newProducts[productIndex]) {
      newProducts[productIndex].comment = text;
    }
    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );
  };

  const handleSubmitExamples = () => {
    if (!selectedExamples) return;

    const newProducts: LpRelationProduct[] = newLp?.products
      ? [...newLp.products]
      : [];

    newProducts.forEach((item) => {
      const productItem = item;
      if (productItem.productId === productId) {
        productItem.exampleIds = selectedExamples.map(
          (e) => e.exampleMaterialId,
        );
      }
    });

    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );
  };

  const handleRemoveExample = (item: ProductExample) => {
    const newExamples = selectedExamples ? [...selectedExamples] : [];
    const i = newExamples.indexOf(item);
    if (i === -1) return;

    newExamples.splice(i, 1);
    setSelectedExamples([...newExamples]);
    const ids = newExamples.map((e) => e.exampleMaterialId);
    const newProducts: LpRelationProduct[] = newLp?.products
      ? [...newLp.products]
      : [];

    newProducts.forEach((p) => {
      const product = p;
      if (product.productId === productId) {
        product.exampleIds = ids;
      }
    });
    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );

    if (lpExampleDetails) {
      const newLpExampleDetails = [...lpExampleDetails].filter(
        (example) => example.exampleMaterialId !== item.exampleMaterialId,
      );
      dispatch(setLpExampleDetails(newLpExampleDetails));
    }
  };

  const handleAddExample = (id: number) => {
    const ex = examples?.examples.find((e) => e.exampleMaterialId === id);
    if (!ex) return;

    const newExamples: ProductExample[] = selectedExamples
      ? [...selectedExamples]
      : [];
    newExamples.push(ex);

    setSelectedExamples(newExamples);

    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetExampleDetail(
        token,
        userInfo.tenantId,
        ex.exampleMaterialId,
        CODE_MODE_REFERENCE,
      ),
    );
  };

  const goBack = useMemo(() => {
    const paths = url.split('/');
    for (let i = 0; i < 3; i += 1) {
      paths.pop();
    }
    if (newLp?.products) {
      if (newLp.products[0].productId === productId) {
        // 一番上
        return (
          <NormalEditTopButton
            click={() => navigate.navigate(`${paths.join('/')}/greeting`)}
            text="挨拶の編集"
          />
        );
      }
      let index = 0;
      newLp.products.forEach((p, i) => {
        if (p.productId === productId) {
          index = i;
        }
      });
      if (newLp.products[index - 1]) {
        const id = newLp.products[index - 1].productId;

        return (
          <NormalEditTopButton
            click={() =>
              navigate.navigate(`${paths.join('/')}/product/${id}/edit`)
            }
            text={newLp.products[index - 1].productName}
          />
        );
      }
    }

    return <></>;
  }, [navigate, newLp, url, productId]);

  const goNext = useMemo(() => {
    const paths = url.split('/');
    for (let i = 0; i < 3; i += 1) {
      paths.pop();
    }
    if (newLp?.products) {
      let index = newLp.products.length - 1;
      let name = '';
      let id = 0;
      if (newLp.products[index].productId === productId) {
        return (
          <NormalEditBottomButton
            click={() => navigate.navigate(`${paths.join('/')}/lp-setting`)}
            text="ランディングページ基本設定"
          />
        );
      }
      // 次の商材
      newLp.products.forEach((p, i) => {
        if (p.productId === productId) {
          index = i;
        }
      });
      if (newLp.products[index + 1].productId) {
        id = newLp.products[index + 1].productId;
        name = newLp.products[index + 1].productName;

        return (
          <NormalEditBottomButton
            click={() =>
              navigate.navigate(`${paths.join('/')}/product/${id}/edit`)
            }
            text={name}
          />
        );
      }
    }

    return <></>;
  }, [navigate, newLp, url, productId]);

  const relationExamples = useMemo(() => {
    if (!examples || !examples.examples || !productExamples) return [];
    const ids = productExamples.map(
      (productExamples) => productExamples.exampleMaterialId,
    );

    return examples.examples.filter((example) =>
      ids.includes(example.exampleMaterialId),
    );
  }, [examples, productExamples]);

  const statusLabel = useMemo(() => {
    if (!productDetail) return <></>;
    switch (productDetail.productPeriod.materialStatus) {
      case CODE_MATERIAL_STATUS_EXPIRE:
        return <span>【利用期間外】</span>;
      case CODE_MATERIAL_STATUS_END:
        return <span>【公開終了】</span>;
      default:
        return <></>;
    }
  }, [productDetail]);

  const isColorRed = () => {
    if (!productDetail) return false;

    return (
      productDetail.productPeriod.materialStatus >=
      CODE_MATERIAL_STATUS_SUSPENSION
    );
  };

  const addProductComment = () => {
    if (!newLp || !newLp.products) return;
    dispatch(
      setNewLp({
        ...newLp,
        products: [
          ...newLp.products.map((product, index) => {
            if (index !== productIndex) return product;

            return {
              ...product,
              comment: productComment?.comment || '',
            };
          }),
        ],
      }),
    );
  };

  const handleCopyClick = async () => {
    if (newLp && newLp.products && !newLp.products[productIndex].comment) {
      await copyComment();
      addProductComment();

      return;
    }
    setOpenExitConfirmDialog(true);
  };

  const handleCloseExitConfirmDialog = () => {
    setOpenExitConfirmDialog(false);
  };

  const handleSubmitExitConfirmDialog = async () => {
    await copyComment();
    addProductComment();

    handleCloseExitConfirmDialog();
  };

  const handleClearProductTantoImage = () => {
    const newProducts = newLp?.products ? [...newLp?.products] : [];

    const imageData: ProductSalesImage = {
      lpProductImageId: undefined,
      lpProductImageData: undefined,
      url: undefined,
    };

    if (newProducts[productIndex])
      newProducts[productIndex].productSalesImage = imageData;

    dispatch(
      setNewLp({
        ...newLp,
        products: newProducts,
      }),
    );
  };

  return (
    <Box width={696} mx="auto">
      <ConfirmDialog
        buttonText="OK"
        open={openExitConfirmDialog}
        title="上書きします"
        text="内容を上書きしても"
        handleCancel={handleCloseExitConfirmDialog}
        handleSubmit={handleSubmitExitConfirmDialog}
      />
      <SelectExampleDialog
        requestParam={examplesParam}
        setRequestParam={setExamplesParam}
        handleSubmit={() => {
          handleSubmitExamples();
          setOpenSelectExampleDialog(false);
        }}
        handleInformationClick={(index) => {
          setExampleId(index);
          setOpenExampleInformationDialog(true);
        }}
        handleAddExample={handleAddExample}
        selectedExamples={selectedExamples}
        examples={relationExamples}
        open={openSelectExampleDialog}
        onClose={() => {
          setOpenSelectExampleDialog(false);
          setSelectedExamples(beforeSelectExamples);
        }}
      />
      <FileUploadDialog
        fileType="image"
        isProduct={false}
        onClose={() => setOpenFileUploadDialog(false)}
        open={openFileUploadDialog}
        handleSubmit={(files) => handleSalesImageSubmit(files)}
      />
      {productDetail && (
        <ProductInformationDialog
          onClose={() => setOpenProductInformationDialog(false)}
          open={openProductInformationDialog}
          handleRecommendedUpdate={handleProductRecommendedUpdate}
          productDetail={productDetail}
          handleClickFavorite={handleClickFavorite}
        />
      )}
      {exampleDetail && (
        <ExampleInformationDialog
          onClose={() => setOpenExampleInformationDialog(false)}
          open={openExampleInformationDialog}
          handleRecommendedUpdate={handleExampleRecommendedUpdate}
          exampleDetail={exampleDetail}
        />
      )}
      {/* マイライブラリから選択 */}
      <MyLibraryImageSelectDialog
        myLibraryImages={myLibraryImage}
        selectedId={myLibrarySelectedId}
        open={myLibraryDialogOpen}
        handleCancel={() => setMyLibraryDialogOpen(false)}
        handleSubmit={(id?: number) => {
          setMyLibraryDialogOpen(false);
          handleFileSubmit(id);
        }}
      />
      <Box mt={6} mb={2}>
        <HeadlineLabel text="商材編集" />
      </Box>
      <Box pb={3} display="flex" className={classes.box}>
        <Box className={classes.imageBox}>
          <InformationIconButton
            handleClick={() => setOpenProductInformationDialog(true)}
          />
          {img}
        </Box>
        <Box ml={2}>
          <Typography
            className={clsx(classes.bold, classes.copy)}
            variant="body2"
          >
            {productDetail?.catchCopy}
          </Typography>
          <Typography className={clsx(classes.bold)} variant="body2">
            {productDetail?.productId}
          </Typography>
          <Typography
            className={clsx(
              classes.bold,
              classes.lineheight,
              isColorRed() && classes.red,
            )}
            variant="body2"
          >
            {statusLabel}
            {productDetail?.productName}
          </Typography>
          <Typography
            className={clsx(classes.copy, classes.subcopy)}
            variant="body2"
          >
            {productDetail?.subCopy}
          </Typography>
          <Typography className={clsx(classes.copy)} variant="body2">
            {productDetail?.productContent}
          </Typography>
        </Box>
      </Box>
      <Box mb={3} pb={3} display="flex" className={classes.box}>
        <Box mr="20px">
          <SubHeadlineLabel text="営業写真" />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {salesImage}
            <Box>
              <Box width={182} height={27} ml={1} mb={1}>
                <PrimaryButton
                  click={() => setMyLibraryDialogOpen(true)}
                  disabled={selectFromMyLibraryDisabled}
                  fontSize="13px"
                  text="マイライブラリから選択"
                />
              </Box>
              <Box width={182} height={27} ml={1} mb={1}>
                <PrimaryButton
                  click={() => setOpenFileUploadDialog(true)}
                  fontSize="13px"
                  text="画像をアップロード"
                />
              </Box>
              <Box width={182} height={27} ml={1}>
                <PrimaryButton
                  fontSize="13px"
                  text="画像をクリア"
                  click={() => handleClearProductTantoImage()}
                />
              </Box>
            </Box>
          </Box>
          {getImageError() && (
            <Box className={classes.error}>
              <ErrorIcon />
              <Typography className={classes.errorText}>
                {getImageError()}
              </Typography>
            </Box>
          )}
        </Box>
        <Box width={317}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <SubHeadlineLabel text="一言コメント" />
            </Box>
            <Box mt={3}>
              <ButtonLink
                fontSize="13px"
                text="以前の入力履歴からコピー"
                click={handleCopyClick}
              />
            </Box>
          </Box>
          <MultipleLinesTextField
            error={getCommentError()}
            value={
              newLp &&
              newLp.products &&
              newLp.products[productIndex] &&
              newLp.products[productIndex].comment
                ? newLp.products[productIndex].comment
                : ''
            }
            handleChangeText={(text) => handleTextChange(text)}
            rows={6}
          />
          <LetterCountLabel
            limit="200"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            count={
              newLp &&
              newLp.products &&
              newLp.products[productIndex] &&
              newLp.products[productIndex].comment
                ? String(newLp.products[productIndex].comment?.length)
                : '0'
            }
          />
        </Box>
      </Box>
      <Box mb={1}>
        <HeadlineLabel text="事例選択" />
      </Box>
      {selectedExamples &&
        selectedExamples.map((item, index) => (
          <NormalEditExampleItem
            clickInformation={() => {
              setExampleId(item.exampleMaterialId);
              setOpenExampleInformationDialog(true);
            }}
            removeExample={() => handleRemoveExample(item)}
            isTop={index === 0 && true}
            isBottom={index === selectedExamples.length - 1 && true}
            clickUp={() => reorderList(item, 'up')}
            clickDown={() => reorderList(item, 'down')}
            key={index}
            example={item}
          />
        ))}
      <Button
        className={classes.button}
        onClick={() => {
          setOpenSelectExampleDialog(true);
          setBeforeSelectExamples(selectedExamples);
        }}
      >
        ＋事例を選択する
      </Button>
      {goBack}
      {goNext}
    </Box>
  );
};
export default NormalProductEdit;

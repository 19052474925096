/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Link } from 'components/atoms'
import { Typography } from '@material-ui/core'
import { ArrowBackIcon } from 'assets/images'
import { TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */

export interface NormalEditBottomButtom{
  text: string,
  click?: () => void  
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.gray[700],
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderRadius: '0 0 8px 8px',
      height: 35,
      width: 518,
      position: 'fixed',
      top: 45,
      left: '43%',
      backgroundColor: theme.palette.gray[500],
      '&:hover':{
        '& svg path': {
          fill: theme.palette.green[900],
        },
        '& a':{
          cursor: 'pointer',
          color: theme.palette.green[900],
          textDecoration: 'none',
        }
      },
    },
    link: {
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: 'auto',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      maxWidth: 450,
    },
    arrow: {
      marginLeft: 'auto',
      marginRight: 2,
    }
  }),
)

/**
 * Presenter
 */

export default function NormalEditBottomButton(props: NormalEditBottomButtom) {

  const classes = useStyles(props)
  const { text, click } = props

  const fullText = `${text}に進む`

  return (
      <Box  className={classes.root} display="flex" flexWrap="wrap" alignItems="center">
        <ArrowBackIcon className={classes.arrow}/>
        <Link className={classes.link} onClick={click}>
          <Typography component="span" variant="body2">{fullText}</Typography>
        </Link>
      </Box>
    )
}
/* eslint-enable */

import React, { useCallback, useReducer, useState } from 'react';
import { Box, PrimaryButton, Typography } from 'components/atoms';
import { MandatoryTitleLabel } from 'components/molecules';
import { FileUploadDialog } from 'components/organisms';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  TenantLpMainImage,
  VariousSettings,
} from 'core/domain/variousSettings';
import { fileToBase64 } from 'libs/file';
import { ErrorIcon } from 'assets/images';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import ImageCardItem from '../ImageCardItem';

interface VariousSettingsLpMainImageAreaProps {
  error: string | undefined;
  tenantLpMainImage: TenantLpMainImage[] | undefined;
  handleInputChange: (
    v: TenantLpMainImage[],
    key: keyof VariousSettings,
  ) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    titleArea: {
      '& > :nth-child(2)': {
        width: 160,
        height: 35,
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const VariousSettingsLpMainImageArea = (
  props: VariousSettingsLpMainImageAreaProps,
) => {
  const { error, tenantLpMainImage, handleInputChange } = props;
  const classes = useStyles();
  const [upDateFileDialogOpen, setUpdateFileDialogOpen] =
    useState<boolean>(false);
  const [selectedDisplayOrder, handleDisplayOrderChange] = useReducer(
    (_: number, curr: number) => curr,
    0,
  );

  const handleAddFileList = () => {
    const imageList = tenantLpMainImage ? [...tenantLpMainImage] : [];
    const newImageList = [
      ...imageList,
      {
        displayOrder: imageList.length + 1,
      },
    ];
    handleInputChange(newImageList, 'tenantLpMainImage');
  };

  const handleUpdateFileDialogClose = () => {
    setUpdateFileDialogOpen(false);
  };

  const handleUpdateFileClick = (currOrder: number) => {
    setUpdateFileDialogOpen(true);
    handleDisplayOrderChange(currOrder);
  };

  const dispatchImage = (existingImages: TenantLpMainImage[], data: string) => {
    const imageDataList = [
      ...existingImages,
      {
        ...tenantLpMainImage?.filter(
          (mark) => mark.displayOrder === selectedDisplayOrder,
        )[0],
        data,
      },
    ];

    handleInputChange(
      imageDataList as TenantLpMainImage[],
      'tenantLpMainImage',
    );
  };

  const updateFileData = (files: File[]) => {
    if (!tenantLpMainImage) return;
    const images = tenantLpMainImage.filter(
      (mark) => mark.displayOrder !== selectedDisplayOrder,
    );
    files.map((file) => {
      fileToBase64(file)
        .then((res) => {
          dispatchImage(images, res as string);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    });
  };

  const handleUpdateFileSubmit = (files: File[]) => {
    if (!files.length || !files[0]) return;

    updateFileData(files);
    setUpdateFileDialogOpen(false);
  };

  const handleOrderChange = useCallback(
    (currOrder: number, type: 'up' | 'down') => {
      const changedOrder = type === 'up' ? currOrder - 1 : currOrder + 1;

      if (!tenantLpMainImage) return;
      handleInputChange(
        tenantLpMainImage.map((mark) => {
          if (mark.displayOrder === currOrder) {
            return {
              ...mark,
              displayOrder: changedOrder,
            };
          }
          if (mark.displayOrder === changedOrder) {
            return {
              ...mark,
              displayOrder: currOrder,
            };
          }

          return mark;
        }),
        'tenantLpMainImage',
      );
    },
    [tenantLpMainImage, handleInputChange],
  );

  const handleFileDelete = useCallback(
    (deleteId: number) => {
      const deletedTenantLpMainImage =
        tenantLpMainImage?.filter((mark) => mark.displayOrder !== deleteId) ||
        [];

      handleInputChange(
        deletedTenantLpMainImage.map((image, i) => ({
          ...image,
          displayOrder: i + 1,
        })),
        'tenantLpMainImage',
      );
    },
    [tenantLpMainImage, handleInputChange],
  );

  return (
    <Box mt={5}>
      <FileUploadDialog
        open={upDateFileDialogOpen}
        onClose={handleUpdateFileDialogClose}
        fileType="image"
        handleSubmit={handleUpdateFileSubmit}
      />
      <Box
        className={classes.titleArea}
        display="flex"
        justifyContent="space-between"
      >
        <MandatoryTitleLabel mb title="ランディングページメイン画像" />
        <PrimaryButton text="メイン画像を追加" click={handleAddFileList} />
      </Box>
      {tenantLpMainImage &&
        tenantLpMainImage
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((mark) => (
            <ImageCardItem
              key={mark.displayOrder}
              data={mark.data || mark.url}
              displayOrder={mark.displayOrder}
              handleFileDelete={handleFileDelete}
              handleOrderChange={handleOrderChange}
              handleUpdateFileClick={handleUpdateFileClick}
              isBottom={mark.displayOrder === tenantLpMainImage.length}
              isTop={mark.displayOrder === 1}
            />
          ))}
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default VariousSettingsLpMainImageArea;

import { Box, createStyles, makeStyles, RadioGroup, Theme, Typography, } from '@material-ui/core';
import { ErrorIcon } from 'assets/images';
import { InquiryRadioButton, SubHeadlineLabel } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { inquiryItemOptions } from 'core/domain/inquiries';
import React, { ChangeEvent, useEffect, useState } from 'react';

interface InquiryRadioButtonProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
  title: string;
  options: inquiryItemOptions[] | undefined;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    radioButton: {
      '& .MuiIconButton-label': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const InquiryRadioButtonField = (props: InquiryRadioButtonProps) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
    title,
    options,
    disabled,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState<string|undefined>("");

  useEffect(() => {
    if (value) return;
    setValue(defaultValue || "")
  }, [defaultValue, options, value])

  return (
    <Box
      className={`${classes.root} ${
        isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
      }`}
      display="flex"
      alignItems="center"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title={title} />
      ) : (
        <SubHeadlineLabel text={title} />
      )}
      <Box>
        <RadioGroup
          className={classes.radioButton}
          aria-label={title}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>,v: string) => {
            setValue(v);
            onChange(inquiryItemId, title, v);
          }}
        >
          {options?.map((option) => (
            <InquiryRadioButton
              key={option.displayOrder}
              value={option.displayOptionName}
              label={option.displayOptionName}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
        {error && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>{error}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default InquiryRadioButtonField;

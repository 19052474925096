import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar, PrimaryButton } from 'components/atoms';
import { Content, ManageNoticesTable } from 'components/organisms';
import { hooks } from 'libs';
import { NoticeDetails, NoticesSearchCondition } from 'core/domain/notices';
import { PlusIcon } from 'assets/images';

/**
 * Interface
 */

export interface ManageNoticesPageProps {
  notices: NoticeDetails | null;
  rows: number;
  noticesSearchCondition: NoticesSearchCondition | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
}

/**
 * Style
 */

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */

const manageNotices = (props: ManageNoticesPageProps) => {
  const classes = UseStyles({});
  // const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const { navigate } = hooks.useNavigate();
  const {
    notices,
    rows,
    noticesSearchCondition,
    handleChangePage,
    handleChangeSortKey,
  } = props;

  const newNoticeButton = () => (
    <Box mb={1} width={200} height={36}>
      <PrimaryButton
        text="新規お知らせ"
        click={() => {
          navigate(`/adm/notices/register`);
        }}
        icon={<PlusIcon />}
      />
    </Box>
  );

  return (
    <Box mt={5}>
      <Content title="お知らせ管理" sideItem={newNoticeButton}>
        <>
          <Box className={classes.table}>
            <ManageNoticesTable
              clickManageNotice={(id: number) => {
                navigate(`/adm/notices/${id}/edit`);
                // setCurrentNoticeUrl();
              }}
              handleChangeSortKey={handleChangeSortKey}
              // handleClickFavorite={handleClickFavorite}
              noticesSearchCondition={noticesSearchCondition}
            />

            <Box mt={5}>
              <PaginationBar
                currentPage={
                  noticesSearchCondition ? noticesSearchCondition.page : 1
                }
                maxPage={
                  notices?.totalCount
                    ? Math.ceil(notices?.totalCount / rows)
                    : 1
                }
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={notices?.totalCount}
              />
            </Box>
          </Box>
        </>
      </Content>
    </Box>
  );
};

export default manageNotices;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Tooltip } from 'components/atoms';
import { ThumbsUpIcon } from 'assets/images';

/**
 * Interface
 */
export interface RecommendButtonProps {
  recommended: boolean;
  click: () => void;
  disabled: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
    button: {
      fontWeight: 'bold',
      borderRadius: 30,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 12,
      height: 26,
      width: 82,
    },
    caption: {
      marginLeft: 2,
    },
    buttonRecommended: {
      backgroundColor: theme.palette.yellow[800],
      color: theme.palette.text.secondary,
      '& path': {
        fill: theme.palette.text.secondary,
      },
      '&:hover': {
        backgroundColor: theme.palette.yellow[900],
      },
    },
    buttonNotRecommended: {
      color: theme.palette.gray[800],
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#DCDCDA',
    },
  }),
);

/**
 * Presenter
 */
const RecommendButton = (props: RecommendButtonProps) => {
  const classes = useStyles({});
  const { recommended, click, disabled } = props;

  const buttonClass = `${classes.button} ${
    recommended ? classes.buttonRecommended : classes.buttonNotRecommended
  }`;

  return (
    <Tooltip
      title={recommended ? 'オススメを解除する' : 'オススメ登録する'}
      placement="top"
      arrow
    >
      <span style={disabled ? { pointerEvents: 'none' } : {}}>
        <Button
          disabled={disabled}
          className={buttonClass}
          onClick={() => click()}
        >
          <ThumbsUpIcon />
          <span className={classes.caption}>オススメ</span>
        </Button>
      </span>
    </Tooltip>
  );
};

export default RecommendButton;

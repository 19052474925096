import { Box, HeadlineLabel, SubHeadlineLabel } from 'components/atoms';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface DisplayLPNameAreaProps {
  name: string | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
        margin: 0,
      },
    },
  }),
);

const DisplayLPNameArea = (props: DisplayLPNameAreaProps) => {
  const { name } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <SubHeadlineLabel text="ランディングページ名称" />
      <HeadlineLabel text={name || ''} />
    </Box>
  );
};

export default DisplayLPNameArea;

import { CustomerMailAddress } from 'core/domain/customer'

const initialState = null

export const createInitialState = (): CustomerMailAddress | null => initialState

export const SET_CUSTOMER_MAIL_ADDRESS = 'customer-mail-address/set' as const
export const CLEAR_CUSTOMER_MAIL_ADDRESS = 'customer-mail-address' as const

export const setCustomerMailAddress = (data: CustomerMailAddress) => ({
  type: SET_CUSTOMER_MAIL_ADDRESS,
  payload: {
    data,
  },
})

export const clearCustomerMailAddress = () => ({
  type: CLEAR_CUSTOMER_MAIL_ADDRESS,
})

type SetCustomerMailAddressAction = ReturnType<typeof setCustomerMailAddress>
type ClearCustomerMailAddressAction = ReturnType<typeof clearCustomerMailAddress>
type Action = SetCustomerMailAddressAction | ClearCustomerMailAddressAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMER_MAIL_ADDRESS:
      return action.payload.data
    case CLEAR_CUSTOMER_MAIL_ADDRESS:
      return initialState
    default:
      return state
  }
}

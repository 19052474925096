import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ErrorText,
  MandatoryLabel,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_200,
  SALAD_BAR_DESKTOP_FONT_SIZE_300,
} from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CheckItems } from 'containers/UserRegisterPageContainer';

interface UserAuthSettingsProps {
  auth: {
    landingPage: Partial<CheckItems>;
    productIntroductionExample: Partial<CheckItems>;
    tenantAdministrator: Partial<CheckItems>;
  };
  error?: string;
  handleAuthChange: (
    v: '作成者' | '承認者' | '',
    key: 'landingPage' | 'productIntroductionExample' | 'tenantAdministrator',
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleArea: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
    },
    formArea: {
      marginTop: theme.spacing(3),
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        width: 190,
      },
    },
    settingTitle: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
      fontWeight: 'bold',
    },
    tenantLabelText: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      color: 'red',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
  }),
);

const AUTH_SETTINGS = [
  {
    title: 'ランディングページ',
    accessor: 'landingPage' as const,
    labels: ['作成者', '承認者'],
  },
  {
    title: '商材・導入事例',
    accessor: 'productIntroductionExample' as const,
    labels: ['作成者', '承認者'],
  },
  {
    title: 'テナント管理者',
    accessor: 'tenantAdministrator' as const,
    labels: [''],
  },
];

const UserAuthSettings = (props: UserAuthSettingsProps) => {
  const { auth, error, handleAuthChange } = props;
  const classes = useStyles();

  const findIsChecked = (
    currValue: Partial<CheckItems>,
    type: '作成者' | '承認者' | '',
  ): boolean => {
    if (!type) return !!currValue.admin;
    if (type === '作成者') return !!currValue.author;

    return !!currValue.approve;
  };

  return (
    <Box>
      <Box
        className={classes.titleArea}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        <Typography className={classes.title} variant="caption">
          権限設定
        </Typography>
        <MandatoryLabel margin="0 0 0 8px" />
      </Box>
      <Box className={classes.formArea}>
        {AUTH_SETTINGS.map((setting) => (
          <Box key={setting.title} className={classes.form}>
            <Typography
              className={classes.settingTitle}
              variant="body1"
              component="p"
            >
              {setting.title}
            </Typography>
            <FormGroup row>
              {setting.labels.map((label) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={findIsChecked(
                        auth[setting.accessor],
                        label as '作成者' | '承認者' | '',
                      )}
                      onChange={(e) =>
                        handleAuthChange(
                          e.target.value as '作成者' | '承認者' | '',
                          setting.accessor,
                        )
                      }
                      value={label}
                    />
                  }
                  label={
                    label || (
                      <Typography
                        className={classes.tenantLabelText}
                        variant="caption"
                      >
                        ※上記を含む全ての権限を持ちます
                      </Typography>
                    )
                  }
                  key={label}
                />
              ))}
            </FormGroup>
          </Box>
        ))}
        {error && <ErrorText error={error} />}
      </Box>
    </Box>
  );
};

export default UserAuthSettings;

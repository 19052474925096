/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { User } from 'core/domain/user'

const initialState = null

export function createInitialState(): User | null {
  return initialState
}

export const SET_USER_INFO = 'user-info/set' as const
export const CLEAR_USER_INFO = 'user-info/clear' as const

export function setUserInfo(data: User) {
  return {
    type: SET_USER_INFO,
    payload: {
      data,
    }
  }
}

export function clearUserInfo() {
  return {
    type: CLEAR_USER_INFO
  }
}

type SetUserInfo = ReturnType<typeof setUserInfo>
type ClearUserInfo = ReturnType<typeof clearUserInfo>
type Action = SetUserInfo | ClearUserInfo

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_USER_INFO:
      return action.payload.data
    case CLEAR_USER_INFO:
      return initialState
    default:
      return state
  }
}/* eslint-enable */

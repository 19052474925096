/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { DesignTemplate } from 'core/domain/settings'

const initialState = null

export function createInitialState(): Array<DesignTemplate> | null {
  return initialState
}

export const SET_DESIGN_TEMPLATES = 'design-template/set' as const
export const CLEAR_DESIGN_TEMPLATES = 'design-template/clear' as const

export function setDesignTemplates(data: Array<DesignTemplate>) {
  return {
    type: SET_DESIGN_TEMPLATES,
    payload: {
      data,
    },
  }
}

export function clearDesignTemplates() {
  return {
    type: CLEAR_DESIGN_TEMPLATES,
  }
}

type SetDesignTemplatesAction = ReturnType<typeof setDesignTemplates>
type ClearDesignTemplatesAction = ReturnType<typeof clearDesignTemplates>
type Action = SetDesignTemplatesAction | ClearDesignTemplatesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_DESIGN_TEMPLATES:
      return action.payload.data
    case CLEAR_DESIGN_TEMPLATES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

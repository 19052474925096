import React from 'react';
import {
  SubHeadlineLabel,
  CalendarText,
  TimePicker,
  SupportTextLabel,
  Box,
} from 'components/atoms';

/**
 * Interface
 */

export interface DateTimePickerProps {
  subtitle: string;
  supportText?: string;
  width?: string;
  date?: string;
  time?: string;
  dateError?: string;
  timeError?: string;
  handleChangeDate?: (date: string) => void;
  handleChangeTime?: (time: string) => void;
}

/**
 * Presenter
 */

const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    dateError,
    timeError,
    subtitle,
    supportText,
    width,
    date,
    time,
    handleChangeDate,
    handleChangeTime,
  } = props;
      
  return (
    <Box>
      <SubHeadlineLabel text={subtitle} />
      <Box display="flex" flexDirection="row">
        <Box mr={1}>
          <CalendarText
            error={dateError}
            width={width}
            value={date}
            handleChangeDate={handleChangeDate}
          />
        </Box>
        <Box ml={1}>
          <TimePicker
            error={timeError}
            width={width}
            value={time}
            handleChangeTime={handleChangeTime}
          />
        </Box>
      </Box>
      {supportText && <SupportTextLabel text={supportText} />}
    </Box>
  );
};
export default DateTimePicker;

import React from 'react';
import { Box, CommonTextSwitch, HeadlineLabel, OneLineTextField, MultipleLinesTextField } from 'components/atoms';
import {
  LetterCountTextField,
  MandatoryHeadlineLabel,
} from 'components/molecules';
import { VariousSettings } from 'core/domain/variousSettings';

interface VariousSettingsPrivacyPolicyFieldAreaProps {
  handleInputChange: (v: string, key: keyof VariousSettings) => void;
  privacyPolicyCaptionError: string | undefined;
  privacyPolicyCaptionValue: string;
  privacyPolicyUrlError: string | undefined;
  privacyPolicyUrlValue: string;
  privacyPolicyError: string | undefined;
  privacyPolicyValue: string;
  personalDataCollectText: string;
  personalDataCollectTextError: string | undefined;
  personalDataCollectDisplayed: boolean;
  setChecked:(v:boolean, key: keyof VariousSettings) => void;
}

const VariousSettingsPrivacyPolicyFieldArea = (
  props: VariousSettingsPrivacyPolicyFieldAreaProps,
) => {
  const {
    handleInputChange,
    privacyPolicyCaptionError,
    privacyPolicyCaptionValue,
    privacyPolicyUrlError,
    privacyPolicyUrlValue,
    privacyPolicyError,
    privacyPolicyValue,
    personalDataCollectText,
    personalDataCollectTextError,
    personalDataCollectDisplayed,
    setChecked,
  } = props;

  const handlePrivacyPolicyChange = (v: string) => {
    handleInputChange(v, 'privacyPolicy');
  };

  const handlePrivacyPolicyCaptionChange = (v: string) => {
    handleInputChange(v, 'privacyPolicyCaption');
  };

  const handlePrivacyPolicyUrlChange = (v: string) => {
    handleInputChange(v, 'privacyPolicyUrl');
  };

  // 個人情報本文
  const handlepersonalDataCollectChange = (v: string) => {
    handleInputChange(v, 'personalDataCollectText');
  };

  // 個人情報トグルスイッチ
  const handleDisplayedChange = (v: boolean) => {
    setChecked(v, 'personalDataCollectDisplayed');
  };

  return (
    <Box mt={5}>
      <HeadlineLabel text="個人情報の取り扱いについて" />
      <MandatoryHeadlineLabel title="本文" />
      <LetterCountTextField
        error={privacyPolicyError}
        handleChangeText={handlePrivacyPolicyChange}
        label="本文"
        limit="150"
        maxLength={1000}
        multiline
        value={privacyPolicyValue}
      />
      <MandatoryHeadlineLabel title="リンクボタン" />
      <LetterCountTextField
        error={privacyPolicyCaptionError}
        handleChangeText={handlePrivacyPolicyCaptionChange}
        label="リンクボタン"
        limit="15"
        maxLength={100}
        multiline
        value={privacyPolicyCaptionValue}
      />
      <MandatoryHeadlineLabel title="URL" />
      <OneLineTextField
        error={privacyPolicyUrlError}
        handleChangeText={handlePrivacyPolicyUrlChange}
        label="URLを入力"
        maxLength={1000}
        value={privacyPolicyUrlValue}
      />
      <Box mt={3}>
      <HeadlineLabel text="個人情報収集のポップアップ" />
      <Box display="flex" alignItems="center" style={{marginTop:20}}>
      <div style={{marginRight:20,fontWeight: 'bold',}}>ポップアップ表示</div>
      <CommonTextSwitch
          checkedText="有効"
          uncheckedText="無効"
          checked={personalDataCollectDisplayed}
          handleChange={handleDisplayedChange}
        />  
      </Box>
      <MandatoryHeadlineLabel title="本文" />
      <MultipleLinesTextField
        error={personalDataCollectTextError}
        handleChangeText={handlepersonalDataCollectChange}
        label="本文を入力"
        maxLength={1000}
        value={personalDataCollectText}
        rows={3}
      />
      </Box>
    </Box>
  );
};

export default VariousSettingsPrivacyPolicyFieldArea;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { CODE_MATERIAL_STATUS_SUSPENSION } from 'constants/code';
import {
  DATE_NOT_ENTERED,
  INVALID_DATE,
  NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR,
  NORMAL_EDIT_GREET_TEXT_OVER_CHAR,
  NORMAL_EDIT_HEADER_OVER_CHAR,
  NORMAL_EDIT_LP_TAG_OVER_CHAR,
  NORMAL_EDIT_PAGE_TITLE_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
  NORMAL_EDIT_TANTO_NAME_OVER_CHAR,
  NORMAL_EDIT_TITLE_NOT_ENTERED,
  NORMAL_EDIT_TITLE_OVER_CHAR,
  NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR,
  PAST_DATE_SELECTED,
  TIME_NOT_ENTERED,
  NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR,
  NORMAL_EDIT_MAIL_TEXT_OVER_CHAR,
  NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR,
} from 'constants/text';
import { Modules } from 'core';
import { NormalEditErrors, ProductsError } from 'core/domain/validationErrors';
import {
  clearNormalEditErrors,
  setNormalEditErrors,
} from 'core/modules/normalEditErrors';
import { checkPastDay, validDate } from 'libs/date';
import { removeIncorrectTagEntry } from 'libs/validation';
import { useDispatch, useSelector } from 'react-redux';

export const useNormalEditValidation = () => {
  const normalEditErrors = useSelector(
    (state: Modules.AppState) => state.normalEditErrors,
  );
  const newLp = useSelector((state: Modules.AppState) => state.newLp);
  const lpProductDetails = useSelector(
    (state: Modules.AppState) => state.lpProductDetails,
  );
  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
  );
  const dispatch = useDispatch();

  const checkTagsValidation = (tag: string): boolean => {
    const newTag = removeIncorrectTagEntry(tag);
    const newTags = newTag.split(',');
    if (newTag.length > 5000 || newTags.some((e) => e.length > 100))
      return true;

    return false;
  };

  const hasErrorsDraft = () => {
    const errors: NormalEditErrors = {};

    if (!newLp?.header || (newLp?.header && newLp.header.length <= 100)) {
      errors.header = undefined;
    } else if (newLp.header.length > 100) {
      errors.header = NORMAL_EDIT_HEADER_OVER_CHAR;
    }

    if (
      !newLp?.beginningText ||
      (newLp?.beginningText && newLp.beginningText.length <= 100)
    ) {
      errors.beginningText = undefined;
    } else if (newLp.beginningText.length > 100) {
      errors.beginningText = NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR;
    }

    if (
      !newLp?.greetText ||
      (newLp?.greetText && newLp.greetText.length <= 1000)
    ) {
      errors.greetText = undefined;
    } else if (newLp.greetText.length > 1000) {
      errors.greetText = NORMAL_EDIT_GREET_TEXT_OVER_CHAR;
    }

    if (
      !newLp?.pageTitle ||
      (newLp?.pageTitle && newLp.pageTitle.length <= 100)
    ) {
      errors.pageTitle = undefined;
    } else if (newLp.pageTitle.length > 100) {
      errors.pageTitle = NORMAL_EDIT_PAGE_TITLE_OVER_CHAR;
    }

    if (
      !newLp?.tokuisakiName ||
      (newLp?.tokuisakiName && newLp.tokuisakiName.length <= 100)
    ) {
      errors.tokuisakiName = undefined;
    } else if (newLp.tokuisakiName.length > 100) {
      errors.tokuisakiName = NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR;
    }

    if (
      !newLp?.tantoName ||
      (newLp?.tantoName && newLp.tantoName.length <= 100)
    ) {
      errors.tantoName = undefined;
    } else if (newLp.tantoName?.length > 100) {
      errors.tantoName = NORMAL_EDIT_TANTO_NAME_OVER_CHAR;
    }

    if (newLp?.title && newLp.title.length <= 100) {
      errors.title = undefined;
    } else if (!newLp || !newLp.title) {
      errors.title = NORMAL_EDIT_TITLE_NOT_ENTERED;
    } else if (newLp.title.length > 100) {
      errors.title = NORMAL_EDIT_TITLE_OVER_CHAR;
    }

    if (!newLp?.lpTag || (newLp?.lpTag && newLp.lpTag.length <= 100)) {
      errors.lpTag = undefined;
    } else if (newLp?.lpTag && checkTagsValidation(newLp.lpTag)) {
      errors.lpTag = NORMAL_EDIT_LP_TAG_OVER_CHAR;
    }

    if (newLp?.products) {
      const productsError: Array<ProductsError> = [];
      newLp.products.map((product) => {
        if (product.comment && product.comment.length > 5000) {
          const productError = {
            productId: product.productId,
            comment: NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
          };
          productsError.push(productError);
        }
      });
      if (productsError.length > 0) {
        errors.products = productsError;
      } else {
        errors.products = undefined;
      }
    }

    if (newLp?.openPeriod) {
      if (
        newLp.openPeriod.openPeriodDate &&
        !validDate(newLp.openPeriod.openPeriodDate)
      ) {
        errors.openPeriodDate = INVALID_DATE;
      } else if (
        checkPastDay(
          newLp.openPeriod.openPeriodDate,
          newLp.openPeriod.openPeriodTime,
        )
      ) {
        errors.openPeriodDate = PAST_DATE_SELECTED;
      } else if (
        newLp?.openPeriod &&
        !newLp.openPeriod.openPeriodDate &&
        newLp.openPeriod.openPeriodTime
      ) {
        errors.openPeriodDate = DATE_NOT_ENTERED;
      } else if (
        newLp?.openPeriod &&
        newLp.openPeriod.openPeriodDate &&
        !newLp.openPeriod.openPeriodTime
      ) {
        errors.openPeriodTime = TIME_NOT_ENTERED;
      } else {
        errors.openPeriodDate = undefined;
        errors.openPeriodTime = undefined;
      }
    }

    if (newLp?.mailSubject && newLp?.mailSubject.length > 500)
      errors.mailSubject = NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR;
    if (newLp?.mailText && newLp.mailText.length > 5000)
      errors.mailText = NORMAL_EDIT_MAIL_TEXT_OVER_CHAR;
    if (newLp?.mailSignature && newLp.mailSignature.length > 5000)
      errors.mailSignature = NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR;

    const invalidMaterial = hasInvalidMaterials();
    if (invalidMaterial) {
      errors.material = `公開終了・利用が停止されている${invalidMaterial}がランディングページに含まれています。削除してください。`;
    } else {
      errors.material = undefined;
    }

    if (
      errors.title ||
      errors.beginningText ||
      errors.greetText ||
      errors.header ||
      errors.lpTag ||
      errors.pageTitle ||
      errors.products ||
      errors.tantoCompany ||
      errors.tantoName ||
      errors.tokuisakiTitle ||
      errors.tokuisakiName ||
      errors.openPeriodDate ||
      errors.openPeriodTime ||
      errors.material ||
      errors.mailSubject ||
      errors.mailText ||
      errors.mailSignature
    ) {
      dispatch(setNormalEditErrors(errors));

      return errors;
    }
    dispatch(clearNormalEditErrors());

    return null;
  };

  const hasInvalidMaterials = () => {
    const invalidProduct = lpProductDetails.find(
      (p) => p.productPeriod.materialStatus >= CODE_MATERIAL_STATUS_SUSPENSION,
    );
    const invalidExample = lpExampleDetails.find(
      (e) => e.materialPeriod.materialStatus >= CODE_MATERIAL_STATUS_SUSPENSION,
    );
    if (newLp && newLp.products && (invalidProduct || invalidExample)) {
      const invalidProductIndex = newLp.products.findIndex(
        (p) => p.productId === invalidProduct?.productId,
      );
      let invalidExampleIndex = -1;
      newLp.products.forEach((p) => {
        p.exampleIds?.findIndex((id) => {
          if (id === invalidExample?.exampleMaterialId)
            invalidExampleIndex = id;
        });
      });
      if (invalidProductIndex > -1) {
        return '商材';
      }
      if (invalidExampleIndex > -1) {
        return '導入事例';
      }
    }
  };

  return {
    hasErrorsDraft,
    hasInvalidMaterials,
  };
};

/* eslint-enable */

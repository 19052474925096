import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  SUPPORT_STATUS_NEW,
  NOTICE_UNREAD,
  NOTICE_READED,
} from 'constants/text';

/**
 * Interface
 */

export interface NoticeUnreadLabelProps {
  status: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    orange: {
      borderColor: '#D87F24',
      color: '#D87F24',
    },
    blue: {
      borderColor: '#3D9FCB',
      color: '#3D9FCB',
    },
  }),
);

interface StatusType {
  status: boolean;
  label: string;
}

const STATUS_TYPES: Array<StatusType> = [
  {
    status: true,
    label: NOTICE_UNREAD,
  },
  {
    status: false,
    label: NOTICE_READED,
  },
];

/**
 * Presenter
 */

const NoticeUnreadLabel = (props: NoticeUnreadLabelProps) => {
  const classes = useStyles({});
  const { status } = props;

  const decideClass = useMemo(() => {
    let tmpClass = classes.orange;

    if (!status) {
      tmpClass = classes.blue;
    } else {
      tmpClass = classes.orange;
    }

    return tmpClass;
  }, [status, classes.blue, classes.orange]);

  const statusLabel = useMemo(() => {
    const targetStatus = STATUS_TYPES.find((type) => type.status === status);
    if (!targetStatus) return SUPPORT_STATUS_NEW;

    return targetStatus.label;
  }, [status]);

  return (
    <Typography
      className={`${classes.root} ${decideClass}`}
      variant="caption"
      display="block"
    >
      {statusLabel}
    </Typography>
  );
};
export default NoticeUnreadLabel;

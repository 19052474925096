import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, FormHelperText, FormControl, Typography } from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
} from 'constants/typography';
import { ErrorIcon } from 'assets/images';

interface InquirySelectBoxProps {
  className?: string;
  defaultValue?: string | number | undefined;
  error?: string | undefined;
  label: string;
  options: {
    value: string | number;
    label: string;
  }[];
  supportText?: string;
  handleChange?: (v: string) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectBox: {
      width: 320,
      border: '1px solid #ccc',
      borderRadius: 4,
      padding: '8px',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
    },
    defaultSelect: {
      color: theme.palette.gray[900],
    },
    errorRoot: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.red[800],
      },
    },
    errorInput: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      '&:focus': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.red[800],
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const InquirySelectBox = (props: InquirySelectBoxProps) => {
  const {
    className = '',
    defaultValue,
    error,
    label,
    options,
    supportText,
    handleChange = (f) => f,
    disabled,
  } = props;
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false);
  const [defValue, setDefValue] = useState(defaultValue);

  useEffect(() => {
    setDefValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <FormControl className={`${classes.formControl} ${className}`}>
        <select
          defaultValue={defValue}
          onChange={(e) => {
            handleChange(e.target.value);
            setIsSelected(true);
          }}
          className={`${classes.selectBox} ${
            isSelected || defaultValue ? '' : classes.defaultSelect
          } ${error ? classes.errorRoot : ''}`}
          value={defValue}
          disabled={disabled}
        >
          <option hidden>{label}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {supportText && <FormHelperText>{supportText}</FormHelperText>}
      </FormControl>
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </>
  );
};

export default InquirySelectBox;

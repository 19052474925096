import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CheckIcon, CloseWhiteIcon, CloseIcon } from 'assets/images';
import {
  Dialog,
  Box,
  PrimaryButton,
  IconButton,
  SecondaryButton,
  DialogActions,
  HeadlineLabel,
  Typography,
  SubHeadlineLabel,
  FormControl,
  RadioGroup,
  CommonRadioButton,
  FormGroup,
} from 'components/atoms';
import clsx from 'clsx';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { TagSelectCheckBox } from 'components/molecules';
import { TagsRequest, TagType, Tags } from 'core/domain/tag';

import { setProductTagsRequest } from 'core/modules/productTagsRequest';
import { setLpTagsRequest } from 'core/modules/lpTagsRequest';
import { setCustomerTagsRequest } from 'core/modules/customerTagsRequest';

/**
 * Interface
 */
export type TagCategory = 'LP' | 'Product' | 'Customer';
export interface TagSelectDialogProps {
  open: boolean;
  onClose: () => void;
  tags?: Array<Tags>;
  usedTags?: Array<Tags>;
  handleSubmit: (a: string[]) => void;
  handleCheck?: (tag: string, flg: boolean) => void;
  selectedTags?: string[];
  tagType: TagType;
  category: TagCategory;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: 1024,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    bottomBox: {
      // eslint-disable-next-line
      backgroundColor: theme.palette.gray[500],
      borderTopWidth: 2,
      borderTopStyle: 'solid',
      borderRadius: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      // eslint-disable-next-line
      borderTopColor: theme.palette.gray[600],
      height: 215,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
    forms: {
      justifyContent: 'start',
      width: '100%',
    },
    tag: {
      maxHeight: 300,
    },
    scroll: {
      overflowY: 'auto',
    },
    usedTag: {
      height: 60,
    },
  }),
);

/**
 * Presenter
 */
const TagSelectDialog = (props: TagSelectDialogProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const {
    open,
    onClose,
    tags,
    usedTags,
    handleSubmit,
    handleCheck,
    selectedTags,
    tagType,
    category,
  } = props;
  const [value, setValue] = useState('tag');

  const handleSortTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    const tagsRequest = {
      tagType,
      sortKey: e.target.value,
    } as TagsRequest;
    if (category === 'LP') {
      dispatch(setLpTagsRequest(tagsRequest));
    } else if (category === 'Product') {
      dispatch(setProductTagsRequest(tagsRequest));
    } else if (category === 'Customer') {
      dispatch(setCustomerTagsRequest(tagsRequest));
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={onClose}
    >
      <Box>
        <Box mt={3} ml={3} mb={3}>
          <HeadlineLabel text="タグ選択" />
          <Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
            <Typography
              className={clsx([classes.title, classes.tagTitle])}
              variant="overline"
            >
              並び順
            </Typography>
            <Box>
              <FormControl>
                <RadioGroup
                  onChange={handleSortTypeChange}
                  value={value}
                  aria-label="order"
                  row
                >
                  <CommonRadioButton value="tag" label="名前順" />
                  <CommonRadioButton value="usedCount" label="利用数順" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box height={292}>
            <FormGroup
              className={clsx(classes.tag, classes.forms, classes.scroll)}
              row
            >
              {tags?.map((tag, index) => {
                const t = selectedTags?.findIndex((item) => item === tag.tag);

                return (
                  <TagSelectCheckBox
                    checked={t !== -1}
                    handleCheck={handleCheck}
                    count={tag.usedCount}
                    name={tag.tag}
                    key={index}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </Box>
        <Box className={`${usedTags ? classes.bottomBox : ''}`}>
          {usedTags && (
            <Box ml={4}>
              <SubHeadlineLabel text="最近あなたが使ったタグ" />
              <FormGroup
                className={clsx(classes.forms, classes.scroll, classes.usedTag)}
                row
              >
                {usedTags?.map((tag, index) => {
                  const t = selectedTags?.findIndex((item) => item === tag.tag);

                  return (
                    <TagSelectCheckBox
                      checked={t !== -1}
                      handleCheck={handleCheck}
                      count={tag.usedCount}
                      name={tag.tag}
                      key={index}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          )}
          <DialogActions>
            <Box
              width={680}
              mb={3}
              mt={3}
              mx="auto"
              display="flex"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box mr={2} width={332} height={48}>
                <SecondaryButton
                  click={onClose}
                  icon={<CloseIcon className={classes.closeIcon} />}
                  text="キャンセル"
                />
              </Box>
              <Box width={332} height={48}>
                <PrimaryButton
                  click={() => {
                    if (!handleSubmit || !selectedTags) return;
                    handleSubmit(selectedTags);
                  }}
                  icon={<CheckIcon />}
                  text="決定"
                />
              </Box>
            </Box>
            <IconButton onClick={onClose} className={classes.closeButton}>
              <CloseWhiteIcon />
            </IconButton>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TagSelectDialog;

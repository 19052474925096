import { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  Typography,
  SearchTextField,
  IconButton,
  Collapse,
  FormControl,
  RadioGroup,
  CommonRadioButton,
  CommonTextSwitch,
  Divider,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
} from 'constants/typography';
import { CheckIcon, CloseIcon, CollapseIcon, ExpandIcon } from 'assets/images';
import clsx from 'clsx';
import { NormalEditExampleCard } from 'components/organisms';
import { ExamplesQueryParameter, ProductExample } from 'core/domain/example';

/**
 * Interface
 */
export interface SelectExampleDialogProps {
  examples: Array<ProductExample> | null;
  handleInformationClick: (index: number) => void;
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  selectedExamples: ProductExample[] | undefined;
  handleAddExample: (id: number) => void;
  setRequestParam: React.Dispatch<React.SetStateAction<ExamplesQueryParameter>>;
  requestParam: ExamplesQueryParameter;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 1028,
      margin: 0,
      overflowY: 'unset',
    },
    actions: {
      padding: 0,
      margin: theme.spacing(2),
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line
      color: theme.palette.gray[800],
    },
    icon: {
      width: 32,
      height: 32,
      padding: 0,
      '&:hover': {
        '& #Icon_ionic-ios-arrow-back': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        '& #Icon_ionic-ios-arrow-back-2': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        // eslint-disable-next-line
        backgroundColor: theme.palette.green[700],
        borderColor: '#D9EFDB',
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    switch: {
      marginLeft: theme.spacing(6) + 3,
      marginRight: theme.spacing(1),
    },
    collapse: {
      marginBottom: theme.spacing(1),
    },
    scroll: {
      overflowX: 'auto',
    },
    innerBox: {
      flexBasis: 'calc(100%/5)',
      maxWidth: 'calc(100%/5)',
      marginTop: 10,
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
  }),
);

const SelectExampleDialog = (props: SelectExampleDialogProps) => {
  const [collapse, setCollapse] = useState(true);
  const classes = useStyles({});
  const {
    examples,
    handleInformationClick,
    open,
    onClose,
    handleSubmit,
    selectedExamples,
    handleAddExample,
    setRequestParam,
    requestParam,
  } = props;

  const handleChangeRequestParam = (key: string, value: string) => {
    setRequestParam({
      ...requestParam,
      [key]: value,
    });
  };

  const handleCloseDialog = () => {
    setRequestParam({
      free_word: '',
      sort_key: 'updatedDate',
      sort_order: 'desc',
      recommended: false,
      example_status: '5',
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={onClose}
    >
      <Box mt={2} mr={3} ml={3}>
        <Box className={classes.section}>
          <Typography className={classes.title} variant="caption">
            フリーワード検索
          </Typography>
          <Box className={classes.freeWord}>
            <SearchTextField
              value={requestParam.free_word}
              handleChangeText={(text) =>
                handleChangeRequestParam('free_word', text)
              }
              label="検索ワードを入力"
            />
          </Box>
          <Typography className={classes.description} variant="caption">
            登録されている情報全体からフリーワードで検索します。
          </Typography>
          <IconButton
            onClick={() => setCollapse(!collapse)}
            style={
              !collapse ? { visibility: 'visible' } : { visibility: 'hidden' }
            }
            className={classes.icon}
          >
            <ExpandIcon />
          </IconButton>
        </Box>
        <Collapse className={classes.collapse} in={collapse} timeout="auto">
          <Box className={classes.section}>
            <Typography
              className={clsx([classes.title, classes.tagTitle])}
              variant="overline"
            >
              並び順
            </Typography>
            <Box>
              <FormControl>
                <RadioGroup
                  value={requestParam?.sort_key}
                  onChange={(e) =>
                    handleChangeRequestParam('sort_key', e.target.value)
                  }
                  aria-label="order"
                  row
                >
                  <CommonRadioButton value="updatedDate" label="新着順" />
                  <CommonRadioButton
                    value="useLpCount"
                    label="ランディングページ利用数順"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box mb={1} display="flex">
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              flexGrow={1}
            >
              <Typography
                className={classes.switch}
                component="span"
                variant="caption"
              >
                オススメ優先
              </Typography>
              <CommonTextSwitch
                handleChange={(checked) =>
                  setRequestParam({
                    ...requestParam,
                    recommended: checked,
                  })
                }
                checked={requestParam.recommended}
                checkedText="有効"
                uncheckedText="無効"
              />
            </Box>
            <IconButton
              onClick={() => setCollapse(!collapse)}
              className={classes.icon}
            >
              <CollapseIcon />
            </IconButton>
          </Box>
          <Divider />
        </Collapse>
      </Box>
      <Box
        ml={3}
        height={386}
        className={classes.scroll}
        display="flex"
        flexWrap="wrap"
        justifyContent="spaceBetween"
        pb={2}
      >
        {examples &&
          examples.map((item, index) => {
            let selected = false;
            if (selectedExamples) {
              selected = !!selectedExamples.find(
                (example) =>
                  example.exampleMaterialId === item.exampleMaterialId,
              );
            }

            return (
              <Box key={index} className={classes.innerBox}>
                <NormalEditExampleCard
                  handleAdd={() => handleAddExample(item.exampleMaterialId)}
                  selected={selected}
                  handleInformationClick={() =>
                    handleInformationClick(item.exampleMaterialId)
                  }
                  id={item.exampleMaterialId}
                  company={item.customerName}
                  text={item.exampleName}
                  useLpCount={item.exampleReaction?.useLpCount}
                  exampleImage={item.exampleImage}
                />
              </Box>
            );
          })}
      </Box>
      <Divider />
      <DialogActions className={classes.actions}>
        <Box width={332} height={48} mx="auto">
          <PrimaryButton
            click={() => {
              handleSubmit();
              handleCloseDialog();
            }}
            text="決定"
            icon={<CheckIcon />}
          />
        </Box>
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            onClose();
            handleCloseDialog();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default SelectExampleDialog;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box } from 'components/atoms'
import { LPCreateMode, Content } from 'components/organisms'
// 6月スコープ外のためコメントアウト
// import LpCreateSimpleIcon from 'assets/images/icn_lp_create_simple.svg'
import LpCreateNormalIcon from 'assets/images/icn_lp_create_normal.svg'
import LpCreateCopyIcon from 'assets/images/icn_lp_create_copy.svg'

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      '&>div:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
)

interface CreateMode {
  title: string,
  iconPath: string
  description: string
  actionText: string
  to?: string
}

const CREATE_MODES: Array<CreateMode> = [
  // 6月スコープ外のためコメントアウト
  // {
  //   title: '簡単作成',
  //   iconPath: LpCreateSimpleIcon,
  //   description: '簡単な質問に対して順番に答えるだけで基本的なランディングページの雛形を作成できます。\n後で通常作成に切り替えることも可能です。',
  //   actionText: '簡単作成を開始',
  //   to: undefined, // TODO: 簡単作成は、６月スコープ外のため遷移先はなし
  // },
  {
    title: '通常作成',
    iconPath: LpCreateNormalIcon,
    description: '紹介したい商材や事例を組み合わせて、自分なりのメッセージを入れカスタムをすることで、お客さまに対して独自のランディングページを作成できます。',
    actionText: '通常作成を開始',
    to: '/adm/lps/register'
  },
  {
    title: '流用作成',
    iconPath: LpCreateCopyIcon,
    description: '閲覧数や問い合わせ数の多いランディングページをベースに、自分なりにアレンジして作成できます。まずは「みんなのランディングページ一覧」から流用したいランディングページを探してみてください。',
    actionText: 'みんなのランディングページ一覧から探す',
    to: '/adm/lps',
  },
]

/**
 * Presenter
 */

export default function LPCreateModeSelection() {
  const classes = useStyles({})

  return (
    <Box mt={5}>
      <Content title="新規ランディングページ作成">
        <Box className={classes.content}>
          {CREATE_MODES.map((mode, index) =>
            <LPCreateMode
              title={mode.title}
              iconPath={mode.iconPath}
              description={mode.description}
              actionText={mode.actionText}
              to={mode.to}
              key={index}
            />
          )}
        </Box>
      </Content>
    </Box>
  )
}
/* eslint-enable */

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Tooltip } from 'components/atoms';
import { DastIcon } from 'assets/images';

/**
 * Interface
 */
export interface DeleteIconButtonProps {
  disabled?: boolean;
  handleDelete?: () => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      borderWidth: '1px',
      borderColor: theme.palette.gray[800],
      borderStyle: 'solid',
      backgroundColor: theme.palette.text.secondary,
      padding: 0,
      width: 35,
      height: 35,
      '&:hover': {
        background: theme.palette.green[800],
        '& path': {
          fill: theme.palette.text.secondary,
        },
      },
    },
  }),
);

/**
 * Presenter
 */
const DeleteIconButton = (props: DeleteIconButtonProps) => {
  const classes = useStyles({});
  const { disabled, handleDelete } = props;

  return (
    <Tooltip title="ゴミ箱に移動する" placement="top" arrow>
      <span style={disabled ? { pointerEvents: 'none' } : {}}>
        <IconButton
          disabled={disabled}
          className={classes.iconButton}
          onClick={() => !disabled && handleDelete && handleDelete()}
        >
          <DastIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DeleteIconButton;

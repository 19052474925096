/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CheckIcon,
  CloseWhiteIcon,
  CloseIcon,
  UploadBoxIcon,
  ErrorIcon,
} from 'assets/images';
import {
  ListItem,
  Dialog,
  Box,
  PrimaryButton,
  IconButton,
  SecondaryButton,
  DialogActions,
  HeadlineLabel,
  Typography,
  List,
  Tooltip,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import {
  EXTENSION_GIF,
  EXTENSION_PNG,
  EXTENSION_JPEG,
  EXTENSION_JPG,
} from 'constants/mimeType';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { MaterialImageRegisterRequest } from 'core/domain/material';

/**
 * Interface
 */
export interface ThumbnailUploadDialogProps {
  open: boolean;
  materialImage: MaterialImageRegisterRequest | undefined;
  onClose: () => void;
  handleSubmit?: (
    files: File[],
    materialImage: MaterialImageRegisterRequest | undefined,
  ) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(3),
      width: 632,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: 'max(-9%, -50px)',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    uploadBox: {
      position: 'relative',
      width: 632,
      marginTop: theme.spacing(3),
      textAlign: 'center',
      background: theme.palette.common.white,
      borderColor: theme.palette.gray[700],
      borderStyle: 'dashed',
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.gray[400],
    },
    dragOver: {
      backgroundColor: theme.palette.green[700],
      borderColor: theme.palette.green[800],
      boxShadow: 'none',
    },
    text: {
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
      lineHeight: '15px',
    },
    subText: {
      color: theme.palette.gray[800],
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    helperText: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
    },
    fileCloseButton: {
      width: 20,
      height: 20,
      padding: 3,
    },
    listItem: {
      padding: 0,
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    fileNameArea: {
      overflow: 'hidden',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'spaceBetween',
      width: '100%',
    },
    fileNameList: {
      overflow: 'auto',
      height: 'calc(100vh - 550px)',
    },
    fileName: {
      width: 'calc(100% - 30px)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

/**
 * Presenter
 */

const ThumbnailUploadDialog = (props: ThumbnailUploadDialogProps) => {
  const classes = useStyles({});
  const { open, materialImage, onClose, handleSubmit } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setFiles(acceptedFiles);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: `${EXTENSION_GIF},${EXTENSION_PNG},${EXTENSION_JPEG},${EXTENSION_JPG}`,
    maxFiles: 1,
    minSize: 1,
    maxSize: 1000 * 1000 * 10,
  });

  const [files, setFiles] = useState(acceptedFiles);
  const handleRemove = (name: string) => {
    const newFiles = files.filter((file) => file.name !== name);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (!open) {
      fileRejections.length = 0;
    }
  }, [fileRejections, open]);

  const formatErrorMessage = useMemo(() => {
    if (!fileRejections || !fileRejections.length || !fileRejections[0])
      return '';
    let msg = '';
    fileRejections.map((file) => {
      file.errors.map((error) => {
        if (error.code === 'file-too-large') {
          msg = 'サイズが10.0MB以内のファイルを選択してください。';
        } else if (error.code === 'file-too-small') {
          msg = '0Bのファイルはアップロードできません。';
        } else if (error.code === 'file-invalid-type') {
          msg = `png、gif、jpeg形式のファイルを選択してください。`;
        }
      });
    });

    return msg;
  }, [fileRejections]);

  const onClick = () => {
    setIsSubmitting((prev) => !prev);

    // eslint-disable-next-line no-unused-expressions
    handleSubmit && handleSubmit(files, materialImage);
    setFiles([]);
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  const handleOnClose = () => {
    setFiles([]);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={handleOnClose}
    >
      <Box>
        <HeadlineLabel text='サムネイルをアップロード' />
        <Box
          display="flex"
          {...getRootProps({ className: classes.uploadBox })}
          className={clsx(classes.uploadBox, isDragActive && classes.dragOver)}
        >
          <input {...getInputProps()} />
          <Box m="39px" width="325px" mx="auto">
            <UploadBoxIcon />
            <Typography variant="body2" className={classes.text}>
              ファイルをここにドラッグ＆ドロップしてください
            </Typography>
            <Typography variant="body2" className={classes.subText}>
              または
            </Typography>
            <Box width={130} height={36} mx="auto">
              <SecondaryButton text="ファイルを選択" />
            </Box>
          </Box>
        </Box>
        <Typography className={clsx(classes.subText, classes.helperText)}>
          添付ファイルのサイズは10.0MB以内でお願いします。
        </Typography>
        <List className={classes.fileNameList}>
          {files.map((file, index) => (
            <ListItem key={index} className={classes.listItem}>
              <Box className={classes.fileNameArea}>
                <Tooltip title={file.name} arrow placement="top">
                  <Typography variant="body2" className={classes.fileName}>
                    {file.name}
                  </Typography>
                </Tooltip>
                <IconButton
                  className={classes.fileCloseButton}
                  onClick={() => handleRemove(file.name)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
        {formatErrorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {formatErrorMessage}
            </Typography>
          </Box>
        )}
      </Box>
      <DialogActions>
        <Box width={332} height={48}>
          <SecondaryButton
            click={handleOnClose}
            text="キャンセル"
            icon={<CloseIcon className={classes.closeIcon} />}
          />
        </Box>
        <Box width={332} height={48} mx="auto">
          <PrimaryButton
            disabled={isSubmitting}
            icon={<CheckIcon />}
            text="決定"
            click={onClick}
          />
        </Box>
        <IconButton onClick={handleOnClose} className={classes.closeButton}>
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default ThumbnailUploadDialog;
/* eslint-enable */
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, ListItem, ApplyHistoryLabel, Typography } from 'components/atoms'
import { formatDateTimeISO8601 } from 'libs/date'

/**
 * Interface
 */

export interface ApplyHistoryListItemProps {
  date: string
  status: number
  message?: string
  name?: string
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      color: theme.palette.gray[800],
      marginRight: 8
    },
    name: {
      color: theme.palette.gray[800],
      marginLeft: 32,
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
  }),
)

export default function ApplyHistoryListItem(props: ApplyHistoryListItemProps) {
  const classes = useStyles({})
  const { date, status, message, name } = props

  return (
    <ListItem>
      <Box width='100%'>
        <Box display="flex" flexWrap="wrap" alignItems="center" mb={1}>
          <Typography variant="caption" className={classes.date}>
            {formatDateTimeISO8601(date)}
          </Typography>
          <ApplyHistoryLabel status={status} />
          <Typography variant="caption" className={classes.name}>
            {name}
          </Typography>
        </Box>
        {message &&
          <Typography variant="body2" className={classes.description}>
            {message}
          </Typography>
        }
      </Box>
    </ListItem>
  )
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { TagsRequest } from 'core/domain/tag'

const initialState:TagsRequest = {
  tagType: 'edit',
  sortKey: 'tag'
}

export function createInitialState(): TagsRequest {
  return initialState
}

export const SET_LP_TAGS_REQUEST = 'lp-tags-request/set' as const
export const CLEAR_LP_TAGS_REQUEST = 'lp-tags-request/clear' as const

export function setLpTagsRequest(data: TagsRequest) {
  return {
    type: SET_LP_TAGS_REQUEST,
    payload: {
      data,
    },
  }
}

export function clearLpTagsRequest() {
  return {
    type: CLEAR_LP_TAGS_REQUEST,
  }
}

type SetLpTagsRequestAction = ReturnType<typeof setLpTagsRequest>
type ClearLpTagsRequestAction = ReturnType<typeof clearLpTagsRequest>
type Action = SetLpTagsRequestAction | ClearLpTagsRequestAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_TAGS_REQUEST:
      return action.payload.data
    case CLEAR_LP_TAGS_REQUEST:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Link } from 'components/atoms';
import { Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';

/**
 * Interface
 */

type IconPosition = 'start' | 'end';

export interface ButtonLinkProps {
  text: string;
  icon?: React.ReactNode;
  iconPosition?: IconPosition;
  tooltipText?: string;
  click?: () => void;
  fontSize?: string;
  disabled?: boolean;
  alignRight?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    link: {
      color: theme.palette.green[800],
      cursor: 'pointer',
      marginRight: 4,
      wordBreak: 'break-all',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
    text: {
      fontSize: (props: ButtonLinkProps) => props.fontSize,
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

/**
 * Presenter
 */

export default function ButtonLink(props: ButtonLinkProps) {
  const classes = useStyles(props);
  const { text, icon, iconPosition, tooltipText, click, disabled, alignRight } =
    props;

  const link = (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      className={classes.root}
      marginLeft={alignRight && 'auto'}
      marginRight={alignRight && 0}
    >
      {iconPosition === 'start' && <>{icon}</>}
      <Link
        className={clsx(classes.link, { [classes.disabled]: disabled })}
        onClick={click}
      >
        <Typography component="span" variant="body2" className={classes.text}>
          {text}
        </Typography>
      </Link>
      {iconPosition === 'end' && <>{icon}</>}
    </Box>
  );

  return tooltipText ? (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      className={classes.root}
      marginLeft={alignRight && 'auto'}
      marginRight={alignRight && 0}
    >
      <Tooltip title={tooltipText} arrow placement="top">
        {link}
      </Tooltip>
    </Box>
  ) : (
    link
  );
}
/* eslint-enable */

import { Box, SubHeadlineLabel, OneLineTextField } from 'components/atoms';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MandatoryHeadlineLabel } from 'components/molecules';

interface CustomerItemsFieldProps {
  title: string;
  value: string;
  handleChange: (v: string) => void;
  handleBlur?: (v: string) => void;
  error?: string;
  isMandatory?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 340,
    },
  }),
);

const CustomerItemsField = (props: CustomerItemsFieldProps) => {
  const {
    title,
    value,
    handleChange,
    handleBlur,
    error,
    isMandatory = false,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isMandatory ? (
        <MandatoryHeadlineLabel title={title} />
      ) : (
        <SubHeadlineLabel text={title} />
      )}
      <OneLineTextField
        label={`${title}を入力`}
        value={value}
        handleChangeText={handleChange}
        handleBlur={handleBlur}
        error={error}
      />
    </Box>
  );
};

export default CustomerItemsField;

import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  SearchTextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
} from 'constants/typography';
import { ExamplesQueryParameter } from 'core/domain/example';
import {
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
} from 'constants/code';
import { useExamplesPermission } from 'libs/hooks';
// import exp from 'constants';

/**
 * Interface
 */

export interface ExampleSearchFieldProps {
  handleChangeFreeWord?: (freeWord: string) => void;
  exampleQueryParameter: ExamplesQueryParameter | null;
  handleCheckStatus?: (status: number) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line
      color: theme.palette.gray[800],
    },
    status: {
      marginLeft: `${theme.spacing(1) / 2}px`,
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line
        color: `${theme.palette.green[800]} !important`,
      },
    },
  }),
);

interface StatusFilter {
  label: string;
  status: number;
}

const STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: '下書き',
    status: CODE_MATERIAL_STATUS_DRAFT,
  },
  {
    label: '差し戻し',
    status: CODE_MATERIAL_STATUS_DECLINE,
  },
  {
    label: '申請中',
    status: CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  },

  {
    label: '公開中',
    status: CODE_MATERIAL_STATUS_RELEASED,
  },
  {
    label: '公開一時停止',
    status: CODE_MATERIAL_STATUS_SUSPENSION,
  },
  {
    label: '公開終了',
    status: CODE_MATERIAL_STATUS_END,
  },
  {
    label: '利用期間外',
    status: CODE_MATERIAL_STATUS_EXPIRE,
  },
];

const ExampleSearchField = (props: ExampleSearchFieldProps) => {
  const classes = useStyles({});
  const { handleChangeFreeWord, exampleQueryParameter, handleCheckStatus } =
    props;
  const { isMaterialAuthorOrApprover } = useExamplesPermission();

  const statusCheck = useMemo(() => {
    if (!exampleQueryParameter || !exampleQueryParameter.example_status)
      return [];

    return exampleQueryParameter.example_status.split(',');
  }, [exampleQueryParameter]);

  return (
    <Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="caption">
          フリーワード検索
        </Typography>
        <Box className={classes.freeWord}>
          <SearchTextField
            value={
              exampleQueryParameter?.free_word
                ? exampleQueryParameter.free_word
                : ''
            }
            label="検索ワードを入力"
            handleChangeText={handleChangeFreeWord}
          />
        </Box>
        <Typography className={classes.description} variant="caption">
          登録されている情報全体からフリーワードで検索します。
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="overline">
          ステータス
        </Typography>
        <Box className={classes.status}>
          <FormGroup row>
            {STATUS_FILTERS.map((status, index) => {
              if (
                !isMaterialAuthorOrApprover &&
                status.status <= CODE_MATERIAL_STATUS_APPROVAL
              )
                return undefined;

              return (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={statusCheck?.includes(`${status.status}`)}
                      onClick={() =>
                        handleCheckStatus && handleCheckStatus(status.status)
                      }
                    />
                  }
                  label={status.label}
                  key={index}
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default ExampleSearchField;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { InquiriesQueryParameter } from 'core/domain/inquiries'

const initialState = null

export function createInitialState(): InquiriesQueryParameter | null {
  return initialState
}

export const SET_INQUIRIES_QUERY_PARAMETER = 'inquiries-query-parameter/set' as const
export const CLEAR_INQUIRIES_QUERY_PARAMETER = 'inquiries-query-parameter/clear' as const

export function setInquiriesQueryParameter(data: InquiriesQueryParameter) {
  return {
    type: SET_INQUIRIES_QUERY_PARAMETER,
    payload: {
      data,
    },
  }
}

export function clearInquiriesQueryParameter() {
  return {
    type: CLEAR_INQUIRIES_QUERY_PARAMETER,
  }
}

type SetInquiriesQueryParameterAction = ReturnType<typeof setInquiriesQueryParameter>
type ClearInquiriesQueryParameterAction = ReturnType<typeof clearInquiriesQueryParameter>
type Action = SetInquiriesQueryParameterAction | ClearInquiriesQueryParameterAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_INQUIRIES_QUERY_PARAMETER:
      return action.payload.data
    case CLEAR_INQUIRIES_QUERY_PARAMETER:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

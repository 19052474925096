import React, { useState, useEffect } from 'react';
import Account from 'components/pages/Account';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import {
  ProfileImageRegisterRequest,
  UserRegisterRequest,
} from 'core/domain/user';
import { setUserInfoReq } from 'core/modules/userRegReq';

import { hooks } from 'libs';
import { useAccountPermission } from 'libs/hooks';

/**
 * Presenter
 */

const AccountSettingsPageContainer = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const newUser = useSelector(
    (state: Modules.AppState) => state.userInfoReq,
    shallowEqual,
  );
  const materialApproverInfo = useSelector(
    (state: Modules.AppState) => state.materialApproverInfo,
    shallowEqual,
  );
  const lpApproverInfo = useSelector(
    (state: Modules.AppState) => state.lpApproverInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  const { setLpApproverPermission, setMaterialApproverpermission } =
    useAccountPermission();

  const [goAnotherPage, setGoAnotherPage] = useState(false);

  const [initialNewUser, setInitialNewUser] = useState<
    UserRegisterRequest | undefined
  >(undefined);

  const [isLpInfoGet, setIsLpGet] = useState(false);
  const [isApprobalInfoGet, setIsApprobalInfoGet] = useState(false);
  const [isInitialize, setIsInitialize] = useState(false);

  useEffect(() => {
    if (userInfo) {
      const image = userInfo.profileImage
        ? ({
            profileImageId: userInfo.profileImage.profileImageId,
            data: undefined,
            url: userInfo.profileImage.url,
          } as ProfileImageRegisterRequest)
        : undefined;

      // LP管理者権限ユーザー一覧取得/素材管理者権限ユーザー一覧取得
      dispatch(Usecases.user.sendGetLPApproversInfo(token, userInfo.tenantId));
      dispatch(
        Usecases.user.sendGetMaterialApproversInfo(token, userInfo.tenantId),
      );

      // ユーザー情報更新用にユーザー情報のデータを変換
      dispatch(
        setUserInfoReq({
          userId: userInfo.userId,
          profileImage: image,
          displayName: userInfo.displayName,
          deptName: userInfo.deptName,
          lpApprovalUsers: { ...userInfo.lpApprovalUsers },
          materialApprovalUsers: { ...userInfo.materialApprovalUsers },
          lpNoticeApply: userInfo.lpNoticeApply,
          lpNoticeRemand: userInfo.lpNoticeRemand,
          lpNoticeApproved: userInfo.lpNoticeApproved,
          lpNoticeDocumentRequest: userInfo.lpNoticeDocumentRequest,
        }),
      );

      setIsLpGet(true);
    }
  }, [userInfo, dispatch, token]);

  // 以前具材/LP作成者権限があり、承認者を設定。現在作成者権限がなく、設定していた承認者の承認者権限がなくなっている場合
  // 登録時サーバーエラーとなるのを回避するためundefinedを設定
  useEffect(() => {
    if (!userInfo) return;

    const lpApprovalUsers = { ...userInfo.lpApprovalUsers };
    const materialApprovalUsers = { ...userInfo.materialApprovalUsers };
    if (userInfo.lpApprovalUsers && !setLpApproverPermission) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(userInfo.lpApprovalUsers)) {
        const user = lpApproverInfo?.lpAdminUsers.find(
          (approver) => approver.userId === value,
        );
        const step = key.replace(/[^0-9]/g, '');
        if (!user && value) {
          switch (step) {
            case '1':
              lpApprovalUsers.step1ApprovalUserId = undefined;
              break;
            case '2':
              lpApprovalUsers.step2ApprovalUserId = undefined;
              break;
            case '3':
              lpApprovalUsers.step3ApprovalUserId = undefined;
              break;

            default:
              break;
          }
        }
      }
    }
    if (userInfo.materialApprovalUsers && !setMaterialApproverpermission) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(
        userInfo.materialApprovalUsers,
      )) {
        const user = materialApproverInfo?.materialAdminUsers.find(
          (approver) => approver.userId === value,
        );
        const step = key.replace(/[^0-9]/g, '');
        if (!user && value) {
          switch (step) {
            case '1':
              materialApprovalUsers.step1ApprovalUserId = undefined;
              break;
            case '2':
              materialApprovalUsers.step2ApprovalUserId = undefined;
              break;
            case '3':
              materialApprovalUsers.step3ApprovalUserId = undefined;
              break;

            default:
              break;
          }
        }
      }
    }

    dispatch(
      setUserInfoReq({
        /** ユーザID */
        userId: userInfo?.userId,
        profileImage: userInfo?.profileImage,
        displayName: userInfo?.displayName,
        deptName: userInfo?.deptName,
        lpNoticeApply: userInfo?.lpNoticeApply,
        lpNoticeRemand: userInfo?.lpNoticeRemand,
        lpNoticeApproved: userInfo?.lpNoticeApproved,
        lpNoticeDocumentRequest: userInfo?.lpNoticeDocumentRequest,
        // 下記2つをアップデート
        lpApprovalUsers,
        materialApprovalUsers,
      }),
    );
    setIsApprobalInfoGet(true);
  }, [
    lpApproverInfo,
    materialApproverInfo,
    dispatch,
    userInfo,
    setLpApproverPermission,
    setMaterialApproverpermission,
  ]);

  useEffect(() => {
    if (isLpInfoGet && isApprobalInfoGet && newUser && !isInitialize) {
      setInitialNewUser(newUser);
      setIsInitialize(true);
    }
  }, [isLpInfoGet, isApprobalInfoGet, isInitialize, newUser]);

  /**
   * キャンセル押下時の処理
   */
  const handleCancel = () => {
    if (!userInfo) return;
    const image = userInfo.profileImage
      ? ({
          profileImageId: userInfo.profileImage.profileImageId,
          data: undefined,
          url: userInfo.profileImage.url,
        } as ProfileImageRegisterRequest)
      : undefined;

    // 編集前のデータに戻す
    dispatch(
      setUserInfoReq({
        userId: userInfo.userId,
        profileImage: image,
        displayName: userInfo.displayName,
        deptName: userInfo.deptName,
        lpApprovalUsers: { ...userInfo.lpApprovalUsers },
        materialApprovalUsers: { ...userInfo.materialApprovalUsers },
        lpNoticeApply: userInfo.lpNoticeApply,
        lpNoticeRemand: userInfo.lpNoticeRemand,
        lpNoticeApproved: userInfo.lpNoticeApproved,
        lpNoticeDocumentRequest: userInfo.lpNoticeDocumentRequest,
      }),
    );
  };

  /**
   *  ユーザー情報更新
   */
  const handleRegister = () => {
    if (!userInfo || !newUser) return;
    dispatch(
      Usecases.user.sendPutUsersProfile(token, userInfo.tenantId, newUser),
    );
  };

  return (
    <Account
      materialApproverInfo={materialApproverInfo}
      lpApproverInfo={lpApproverInfo}
      handleCancel={handleCancel}
      handleRegister={handleRegister}
      goAnotherPage={goAnotherPage}
      setGoAnotherPage={setGoAnotherPage}
      initialNewUser={initialNewUser}
      setInitialNewUser={setInitialNewUser}
    />
  );
};

export default AccountSettingsPageContainer;

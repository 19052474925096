/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Theme, withStyles } from '@material-ui/core/styles'
import { Box, Switch } from 'components/atoms'

/**
 * Interface
 */

export interface CommonSwitchProps {
  value?: boolean
  handleChangeSwitch?: (checked: boolean) => void
  checked? :boolean
}

/**
 * Style
 */

const CustomSwitch = withStyles((theme: Theme) => ({
  root: {
    width: 36,
    height: 23,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    height: '90%',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 11,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export default function CommonSwitch(props: CommonSwitchProps) {
  const { value, handleChangeSwitch, checked } = props

  return (
    <Box>
      <CustomSwitch checked={!!checked} value={value} onChange={(e, checked) => handleChangeSwitch && handleChangeSwitch(checked)} />
    </Box>
  )
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useMemo } from 'react';
import format from 'date-fns/format';
import {
  makeStyles,
  createStyles,
  createMuiTheme,
  Theme,
} from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateFnsUtils,
  JaLocale,
  Box,
  Typography,
} from 'components/atoms';
import { ThemeProvider } from '@material-ui/styles';
import {
  ArrowIcon,
  ArrowLeftIcon,
  CalendarIcon,
  ErrorIcon,
} from 'assets/images';
import { COLORS } from 'constants/index';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
} from 'constants/typography';

import { validDate } from 'libs/date';
import clsx from 'clsx';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
/**
 * Interface
 */
export interface CalendarTextProps {
  width?: string;
  value?: string;
  error?: string;
  handleChangeDate?: (date: string) => void;
}

/**
 * Style
 */

const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_GREEN_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&:hover $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        '&$focused $notchedOutline': {
          borderColor: `${COLORS.SALAD_BAR_RED_900} !important`,
          borderWidth: 2,
          borderStyle: 'solid',
        },
        height: 36,
        padding: `0 !important`,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.SALAD_BAR_GREEN_900,
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: COLORS.SALAD_BAR_BLACK,
        '&:hover': {
          backgroundColor: COLORS.SALAD_BAR_GREEN_700,
          borderRadius: 8,
        },
      },
      daySelected: {
        backgroundColor: COLORS.SALAD_BAR_GREEN_700,
        color: COLORS.SALAD_BAR_GREEN_900,
        borderRadius: 8,
        '&:hover': {
          backgroundColor: COLORS.SALAD_BAR_GREEN_900,
          color: COLORS.SALAD_BAR_WHITE,
        },
      },
      dayDisabled: {
        //
      },
      current: {
        backgroundColor: COLORS.SALAD_BAR_GREEN_700,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: COLORS.SALAD_BAR_GREEN_800,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.SALAD_BAR_GREEN_900,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      borderWidth: '1px',
    },
    root: {
      margin: 0,
      width: (props: CalendarTextProps) => props.width,
      height: 36,
      backgroundColor: theme.palette.common.white,
      '&, .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.green[700],
      },
    },
    input: {
      height: 20,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      backgroundColor: 'inherit !important',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:focus:not($disabled):not($error)': {
        backgroundColor: theme.palette.green[700],
      },
    },
    errorRoot: {
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        backgroundColor: theme.palette.red[800],
      },
    },
    errorInput: {
      color: theme.palette.red[900],
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年MM月', { locale: this.locale });
  }

  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

const CustomToolBar = (props: ToolbarComponentProps) => {
  const { date, isLandscape, openView, setOpenView } = props;

  const handleChangeViewClick =
    (view: 'date' | 'year' | 'month' | 'hours' | 'minutes' | 'seconds') =>
    () => {
      setOpenView(view);
    };

  return (
    <PickerToolbar title="date" isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h5"
        label={date ? `${date.getFullYear().toString()}年` : ''}
        selected={openView === 'year'}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('month')}
        variant="h5"
        selected={openView === 'month'}
        label={date ? `${date.getMonth() + 1}月` : ''}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h5"
        selected={openView === 'date'}
        label={date ? `${date.getDate()}日` : ''}
      />
    </PickerToolbar>
  );
};

/**
 * Presenter
 */

export default function CalendarText(props: CalendarTextProps) {
  const classes = useStyles(props);
  const { value, error, handleChangeDate } = props;

  const dateValue = useMemo(() => {
    if (!value) return value;
    const date = validDate(value);

    return date || value;
  }, [value]);

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={ExtendedUtils} locale={JaLocale}>
        <KeyboardDatePicker
          helperText=""
          error={!!error}
          className={clsx(classes.root, {
            [classes.errorRoot]: error,
          })}
          autoOk
          allowKeyboardControl={false}
          inputProps={{
            className: clsx(classes.input, {
              [classes.errorInput]: error,
            }),
          }}
          variant="inline"
          inputVariant="outlined"
          format="yyyy/MM/dd"
          value={dateValue || null}
          onChange={(date, value) =>
            handleChangeDate && handleChangeDate(value || '')
          }
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          keyboardIcon={<CalendarIcon />}
          leftArrowIcon={<ArrowLeftIcon />}
          rightArrowIcon={<ArrowIcon />}
          placeholder="日付を選択してください"
          views={['year', 'month', 'date']}
          ToolbarComponent={CustomToolBar}
          type="tel"
        />
      </MuiPickersUtilsProvider>
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </ThemeProvider>
  );
} /* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Box, PrimaryButton, Typography } from 'components/atoms'
import { hooks } from 'libs'

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.text.secondary,
      borderRadius: 12,
      width: '100%',
      height: 417,
      minWidth: 252,
      bottom: 24,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.gray[400],
      padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    },
    title: {
      fontWeight: 'bold',
    },
    icon: {
      marginTop: theme.spacing(2),
      display: 'block',
    },
    description: {
      marginTop: theme.spacing(3),
      marginBottom: 'auto',
      whiteSpace: 'pre-line',
    },
    button: {
      width: 190,
      height: 46,
      '&>button': {
        padding: theme.spacing(1),
      },
    },
  }),
)

/**
 * Interface
 */

export interface LpCreateModeProps {
  title: string
  iconPath: string
  description: string
  actionText: string
  to?: string
}

export default function LPCreateMode(props: LpCreateModeProps) {
  const { title, iconPath, description, actionText, to } = props
  const classes = useStyles({})
  const { navigate } = hooks.useNavigate()
  const { lpNewCreatePermission } = hooks.useLpCreateModeSelectionPermission()



  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.title}>{title}</Typography>
      <img src={iconPath} className={classes.icon} />
      <Typography variant="caption" className={classes.description}>{description}</Typography>
      <Box className={classes.button}>
        <PrimaryButton
          text={actionText}
          click={() => to && navigate(to)}
          disabled={!!((!to || lpNewCreatePermission?.disabled))}
        />
      </Box>
    </Box>
  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  SecondaryButton,
  IconButton,
} from 'components/atoms';
import { CloseWhiteIcon } from 'assets/images';
import { LpMainImage } from 'core/domain/settings';
import clsx from 'clsx';

/**
 * Interface
 */

export interface ImageSelectDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (id?: number) => void;
  lpMainVisualImages: Array<LpMainImage> | null;
  selectedId?: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    scroll: {
      overflow: 'hidden',
      overflowY: 'auto',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      borderColor: theme.palette.green[800],
    },
    img: {
      width: 152,
      height: 152,
      objectFit: 'contain',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.green[800],
      },
    },
  }),
);

export default function ImageSelectDialog(props: ImageSelectDialogProps) {
  const classes = useStyles({});
  const { open, handleCancel, handleSubmit, lpMainVisualImages, selectedId } =
    props;
  const [selectedImage, setSelectedImage] = useState<number | undefined>(
    selectedId,
  );

  const handleSelectImage = (id: number) => {
    if (selectedImage === id) {
      setSelectedImage(undefined);
    } else {
      setSelectedImage(id);
    }
  };

  const images = useMemo(() => {
    if (!lpMainVisualImages) return;

    return lpMainVisualImages.map((image, index) => (
      <Box
        className={classes.ImageCard}
        key={index}
        onClick={() => handleSelectImage(image.lpMainImageId)}
      >
        <img
          className={clsx(classes.img, {
            [classes.selectedImg]: selectedImage === image.lpMainImageId,
          })}
          src={image.url}
        />
      </Box>
    ));
  }, [lpMainVisualImages, selectedImage]);

  const handleCancelClick = () => {
    setSelectedImage(selectedId);
    handleCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box textAlign="center">
        <Box
          className={classes.scroll}
          p={1}
          height={386}
          display="flex"
          flexWrap="wrap"
          justifyContent="spaceBetween"
          pb={2}
        >
          {images}
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={0}>
            <Box width={332} height={48}>
              <SecondaryButton
                click={() => handleCancelClick()}
                text="キャンセル"
              />
            </Box>
            <Box ml={2} width={332} height={48}>
              <PrimaryButton
                click={() => handleSubmit(selectedImage)}
                text="選択する"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
      <IconButton
        onClick={() => handleCancelClick()}
        className={classes.closeButton}
      >
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
}
/* eslint-enable */

import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { setNotices } from 'core/modules/notices';
import { setDashboardNotices } from 'core/modules/dashboardNotices';
import { setTenantDashboardNotices } from 'core/modules/tenantDashboardNotices';
import { setHeaderNotices } from 'core/modules/headerNotices';
import { setNoticeDetail } from 'core/modules/noticeDetail';
import { setNoticeDetails } from 'core/modules/noticeDetails';
import {
  Notices,
  NoticeDetail,
  NoticeDetails,
  NoticesQueryParameter,
  NoticesDetail,
  NoticesTargetType,
} from 'core/domain/notices';
import { setNoticesDetail } from 'core/modules/noticesDetail';

const didCallGetNotices = (dispatch: Dispatch, data: Notices) => {
  dispatch(setNotices(data));
};

export const sendGetNotices =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/notices
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices?own=true & sort_key = publishStartDateTime & sort_order = desc`,
      token,
      null,
      dispatch,
      didCallGetNotices,
    );
  };

const didCallGetDashboardNotices = (dispatch: Dispatch, data: Notices) => {
  dispatch(setDashboardNotices(data));
};

export const sendGetDashboardNotices =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/notices
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices?target=${NoticesTargetType.TenantAndSercice}`,
      token,
      null,
      dispatch,
      didCallGetDashboardNotices,
    );
  };

const didCallGetTenantDashboardNotices = (
  dispatch: Dispatch,
  data: Notices,
) => {
  dispatch(setTenantDashboardNotices(data));
};

export const sendGetTenantDashboardNotices =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/notices
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices?target=${NoticesTargetType.serviceNotices}`,
      token,
      null,
      dispatch,
      didCallGetTenantDashboardNotices,
    );
  };

const didCallGetHeaderNotices = (dispatch: Dispatch, data: Notices) => {
  dispatch(setHeaderNotices(data));
};

export const sendGetHeaderNotices =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/notices
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices?own=true&target=${NoticesTargetType.personalNotices}`,
      token,
      null,
      dispatch,
      didCallGetHeaderNotices,
    );
  };

export const sendGetNoticesTable =
  (token: string | undefined, tenantId: string, param: NoticesQueryParameter) =>
  async (dispatch: Dispatch) => {
    const api = Api.createApi(param);
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices?${api}`,
      token,
      null,
      dispatch,
      didCallGetNotices,
    );
  };

const didCallGetProductDetail = (dispatch: Dispatch, data: NoticeDetail) => {
  dispatch(setNoticeDetail(data));
};

export const didCallGetNoticeDetailsApi = (
  dispatch: Dispatch,
  data: NoticeDetails,
) => {
  dispatch(setNoticeDetails(data));
};

export const sendGetNoticeDetails =
  (
    token: string | undefined,
    tenantId: string,
    noticesQueryParameter: NoticesQueryParameter,
  ) =>
  async (dispatch: Dispatch) => {
    // /{tenant_id}/settings/notices

    const api = noticesQueryParameter
      ? `?${Api.createApi(noticesQueryParameter)}`
      : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/notices${api}`,
      token,
      null,
      dispatch,
      didCallGetNoticeDetailsApi,
    );
  };

export const sendGetNoticeDetail =
  (token: string | undefined, tenantId: string, noticeId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/notices/${noticeId}`,
      token,
      null,
      dispatch,
      didCallGetProductDetail,
    );
  };

export const postNoticeDetail =
  (
    token: string | undefined,
    tenantId: string,
    noticeId: string,
    body: NoticeDetail,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/settings/notices`,
      token,
      JSON.stringify(body),
      dispatch,
      didCallGetProductDetail,
    );
  };
export const putNoticeDetail =
  (
    token: string | undefined,
    tenantId: string,
    noticeId: string,
    body: NoticeDetail,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/settings/notices/${noticeId}`,
      token,
      JSON.stringify(body),
      dispatch,
      didCallGetProductDetail,
    );
  };

// お知らせ詳細取得APIへ

const didCallGetNoticeDetailApi = (dispatch: Dispatch, data: NoticesDetail) => {
  dispatch(setNoticesDetail(data));
};

export const sendGetNoticesDetail =
  (token: string | undefined, tenantId: string, noticeId: number) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/notices
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/notices/${noticeId}`,
      token,
      null,
      dispatch,
      didCallGetNoticeDetailApi,
    );
  };

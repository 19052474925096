/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from 'components/atoms';

/**
 * Interface
 */

export interface SubHeadlineLabelProps {
  text: string;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.gray[900],
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 4,
    },
  }),
);

/**
 * Presenter
 */

export default function SubHeadlineLabel(props: SubHeadlineLabelProps) {
  const classes = useStyles({});
  const { text } = props;

  return (
    <Typography variant="body2" className={classes.root}>
      {text}
    </Typography>
  );
} /* eslint-enable */

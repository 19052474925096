/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ExampleDetail } from 'core/domain/example'

const initialState: Array<ExampleDetail> = []

export const SET_LP_EXAMPLE_DETAILS = 'lp-example-detials/set' as const
export const APPEND_LP_EXAMPLE_DETAILS = 'lp-example-detials/append' as const
export const CLEAR_LP_EXAMPLE_DETAILS = 'lp-example-detials/clear' as const

export function createInitialState(): Array<ExampleDetail> {
  return initialState
}

export function setLpExampleDetails(data: Array<ExampleDetail>) {
  return {
    type: SET_LP_EXAMPLE_DETAILS,
    payload: {
      data,
    },
  }
}

export function appendLpExampleDetails(data: ExampleDetail) {
  return {
    type: APPEND_LP_EXAMPLE_DETAILS,
    payload: {
      data,
    },
  }
}

export function clearLpExampleDetails() {
  return {
    type: CLEAR_LP_EXAMPLE_DETAILS,
  }
}

type SetLpExampleDetailsAction = ReturnType<typeof setLpExampleDetails>
type AppendLpExampleDetailsAction = ReturnType<typeof appendLpExampleDetails>
type ClearLpExampleDetailsAction = ReturnType<typeof clearLpExampleDetails>
type Action = SetLpExampleDetailsAction | AppendLpExampleDetailsAction | ClearLpExampleDetailsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_EXAMPLE_DETAILS:
      return action.payload.data
    case APPEND_LP_EXAMPLE_DETAILS:
      if (state.findIndex((s: ExampleDetail) => s.exampleMaterialId === action.payload.data.exampleMaterialId) < 0) {
        return [
          ...state,
          action.payload.data,
        ]
      }

      return [...state]
    case CLEAR_LP_EXAMPLE_DETAILS:
      return initialState
    default:
      return state
  }
}


/* eslint-enable */

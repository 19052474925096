import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  MessageAlert,
  List,
  Box,
  ButtonLink,
  SubHeadlineLabel,
  MultipleLinesTextField,
  SupportTextLabel,
  LetterCountLabel,
  HeadlineLabel,
  PrimaryButton,
  SecondaryButton,
  OneLineTextField,
  Divider,
  Typography,
} from 'components/atoms';
import {
  Content,
  DateTimePicker,
  PageControlBox,
  FileUploadDialog,
  VideoEmbedDialog,
  TagSelectDialog,
  MediaCard,
  ConfirmDialog,
  NoticeDialog,
  ThumbnailUploadDialog,
} from 'components/organisms';
import {
  DownloadDocumentRow,
  MandatoryHeadlineLabel,
} from 'components/molecules';
import {
  PDFIcon,
  ImageIcon,
  MovieIcon,
  UploadIcon,
  URLLinkIcon,
  ErrorIcon,
} from 'assets/images';
import {
  ProductDetail,
  ProductRegisterRequest,
  ProductTags,
} from 'core/domain/product';
import { setNewProduct } from 'core/modules/newProduct';
import {
  validDate,
  formateTimeHHMMColon,
  checkPastDay,
  checkWithin5Days,
} from 'libs/date';
import { OpenDialogType } from 'components/organisms/FileUploadDialog';
import { fileToBase64 } from 'libs/file';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_STATUS_END,
} from 'constants/code';
import {
  DATE_NOT_ENTERED,
  DIALOG_NO_APPROVAL_USER,
  END_OF_OPEN_CAUTION,
  INVALID_DATE,
  TIME_NOT_ENTERED,
} from 'constants/text';
import { MaterialImageRegisterRequest } from 'core/domain/material';
import { Order } from 'components/organisms/MediaCard';
import clsx from 'clsx';
import { hooks } from 'libs';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { LpView } from 'core/domain/lp';
import { downloadFileFromBase64, downloadFileFromUrl } from 'libs/downloader';

import { removeIncorrectTagEntry } from 'libs/validation';
import validator from 'validator';
import { AppError } from 'core/domain/appError';
import { Modules } from 'core';
import { setError } from 'core/modules/error';
import { useParams } from 'react-router';

/**
 * Interface
 */
export interface ProductregisterEditProps {
  newProduct: ProductRegisterRequest | null;
  productTags: ProductTags | null;
  handleProductRegister: (draft: boolean, tag: string) => void;
  productDetail: ProductDetail | null;
  previewData?: LpView;
  error: AppError | null;
}

interface ValidationError {
  productName?: string;
  catchCopy?: string;
  productContent?: string;
  workflowMessage?: string;
  subCopy?: string;
  contact?: string;
  externalSiteName?: string;
  externalSiteUrl?: string;
  productImages?: string;
  openPeriodDate?: string;
  openPeriodTime?: string;
  usePeriodDate?: string;
  usePeriodTime?: string;
  productTag?: string;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    movieButton: {
      marginLeft: 8,
      marginRight: 8,
    },
    container: {
      marginTop: 24,
      marginBottom: 24,
    },
    divider: {
      marginTop: 48,
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    docs: {
      overflow: 'hidden',
    },
    caution: {
      color: theme.palette.red[900],
      marginTop: theme.spacing(3),
      textAlign: 'left',
      border: '1px solid #DB3218',
      whiteSpace: 'pre-line',
      width: '580px',
      padding: '10px 15px',
    },
  }),
);

/**
 * Presenter
 */

const ProductRegisterEdit = (props: ProductregisterEditProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { hasMaterialApprovalUser } = hooks.useApproval();
  const {
    newProduct,
    productTags,
    productDetail,
    handleProductRegister,
    previewData,
    error,
  } = props;
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [fileDialogType, setFileDialogType] =
    useState<OpenDialogType>('hidden');
  const [videoEmbedDialogOpen, setVideoEmbedDialogOpen] = useState(false);
  const [thumbnailDialogOpen, setThumbnailDialogOpen] = useState(false);
  const [tagSelectDialogOpen, setTagSelectDialogOpen] = useState(false);
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [mediaId, setMediaId] = useState(-1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [mediaIndex, setMediaIndex] = useState(-1);
  const [isAddNewItem, setIsAddNewItem] = useState(false);
  const [openNoApprovalUserDialog, setOpenNoApprovalUserDialog] =
    useState(false);
  const [openEndConfirmDialog, setOpenEndConfirmDialog] = useState(false);
  const [openExpireConfirmDialog, setOpenExpireConfirmDialog] = useState(false);
  const [productImageThumbnails, setProductImageThumbnails] = useState<
    string[]
  >([]);
  const [draft, setDraft] = useState(false);
  const [initialNewProduct, setInitialNewProduct] = useState<
    ProductRegisterRequest | null | undefined
  >(undefined);
  const [nextPagePath, setNextPagePath] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const { productId } = useParams<{ productId: string }>();

  useEffect(() => {
    if (fileDialogType !== 'hidden') {
      setFileDialogOpen(true);
    } else {
      setFileDialogOpen(false);
    }
  }, [fileDialogType]);

  useEffect(() => {
    if (!productDetail || !productDetail.productTag) return;
    const tags = productDetail.productTag.split(',');
    setSelectedTags([...tags]);
  }, [productDetail]);

  useEffect(() => {
    if (!productDetail || !productDetail.productImages) return;
    const thumbnails = productDetail.productImages.map((p) => p.thumbnailUrl);
    setProductImageThumbnails(thumbnails);
  }, [productDetail]);

  useEffect(() => {
    if (error) return;
    if (userInfo && !userInfo.tenantAdmin && !userInfo.materialAuthor) {
      dispatch(
        setError({
          title: '以下のエラーにより、処理を中止しました。',
          error: ['権限がありません。'],
        }),
      );

      return;
    }

    const res = navigate.block((pathname: string) => {
      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);

        return false;
      }

      if ((!newProduct && !initialNewProduct) || isRegister || goAnotherPage)
        return undefined;

      if (newProduct && initialNewProduct) {
        if (JSON.stringify(newProduct) !== JSON.stringify(initialNewProduct)) {
          setOpenExitConfirmDialog(true);

          return false;
        }
      }

      if (newProduct && !initialNewProduct) {
        if (productDetail) return undefined;
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      res();
    };
  }, [
    newProduct,
    isRegister,
    goAnotherPage,
    error,
    navigate,
    initialNewProduct,
    productDetail,
    userInfo,
    dispatch,
  ]);

  useEffect(() => {
    if ((initialNewProduct === null && !productId) || initialNewProduct) return;
    setInitialNewProduct(newProduct ? { ...newProduct } : null);
  }, [initialNewProduct, newProduct, productId]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  const dispatchImage = (
    imageType: number,
    images?: MaterialImageRegisterRequest[],
    data?: string,
    url?: string,
    thumbnailData?: string,
  ) => {
    const imageData = {
      materialImageId:
        images && images[mediaId]?.materialImageId
          ? images[mediaId].materialImageId
          : undefined,
      materialImageType: imageType,
      data,
      url,
      thumbnailData,
    };

    // eslint-disable-next-line no-unused-expressions, no-param-reassign
    mediaId !== -1 && images
      ? // eslint-disable-next-line no-unused-expressions, no-param-reassign
        (images[mediaId] = imageData)
      : images?.push(imageData);

    dispatch(
      setNewProduct({
        ...newProduct,
        productImages: images,
      }),
    );
  };

  const setFileData = (imageType: number, files: File[], url = '') => {
    const images = newProduct?.productImages
      ? [...newProduct.productImages]
      : [];
    if (url && !files.length && !files[0]) {
      dispatchImage(imageType, images, '', url);

      return;
    }

    files.map((file) => {
      void fileToBase64(file).then((res) => {
        dispatchImage(imageType, images, res as string, url);
      });
    });
    setMediaId(-1);
  };

  const setDocsData = (files: File[]) => {
    const docs = newProduct?.productDocuments
      ? [...newProduct.productDocuments]
      : [];
    files.map((file) => {
      void fileToBase64(file).then((res) => {
        const fileData = {
          productDocumentId: undefined,
          productDocumentName: file.name,
          data: res as string,
        };
        docs.push(fileData);
        dispatch(
          setNewProduct({
            ...newProduct,
            productDocuments: docs,
          }),
        );
      });
    });
  };

  const handleChangeProductName = (productName: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        productName,
      }),
    );
  };

  const handleProductTag = (selectedTags: string[]) => {
    const productTag = selectedTags.join(',');
    dispatch(
      setNewProduct({
        ...newProduct,
        productTag,
      }),
    );
  };

  const handleTagChange = (tag: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        productTag: tag,
      }),
    );
    const tags = tag.split(',');
    setSelectedTags([...tags]);
  };

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      // eslint-disable-next-line no-self-compare
      setSelectedTags(selectedTags.filter((tag) => tag !== tag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag].filter((tag) => !!tag));
  };

  const handleChangeContact = (contact: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        contact,
      }),
    );
  };

  const handleChangeCatchCopy = (catchCopy: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        catchCopy,
      }),
    );
  };

  const handleChangeSubCopy = (subCopy: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        subCopy,
      }),
    );
  };

  const handleChangeProductContent = (productContent: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        productContent,
      }),
    );
  };

  const handleChangeExternalSiteName = (externalSiteName: string) => {
    const site = {
      ...newProduct?.productExternalSite,
      externalSiteName,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productExternalSite: site,
      }),
    );
  };

  const handleChangeExternalSiteUrl = (externalSiteUrl: string) => {
    const site = {
      ...newProduct?.productExternalSite,
      externalSiteUrl,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productExternalSite: site,
      }),
    );
  };

  const handleChangeOpenPeriodDate = (openPeriodDate: string) => {
    const period = {
      ...newProduct?.productPeriod,
      openPeriodDate:
        openPeriodDate.length !== 0
          ? openPeriodDate.replaceAll('/', '')
          : undefined,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productPeriod: period,
      }),
    );
  };

  const handleChangeOpenPeriodTime = (openPeriodTime: string) => {
    const period = {
      ...newProduct?.productPeriod,
      openPeriodTime:
        openPeriodTime.length !== 0
          ? openPeriodTime.replace(':', '')
          : undefined,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productPeriod: period,
      }),
    );
  };

  const handleChangeUsePeriodDate = (usePeriodDate: string) => {
    const period = {
      ...newProduct?.productPeriod,
      usePeriodDate:
        usePeriodDate.length !== 0
          ? usePeriodDate.replaceAll('/', '')
          : undefined,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productPeriod: period,
      }),
    );
  };

  const handleChangeUsePeriodTime = (usePeriodTime: string) => {
    const period = {
      ...newProduct?.productPeriod,
      usePeriodTime:
        usePeriodTime.length !== 0 ? usePeriodTime.replace(':', '') : undefined,
    };
    dispatch(
      setNewProduct({
        ...newProduct,
        productPeriod: period,
      }),
    );
  };

  const handleChangeWorkflowMessage = (workflowMessage: string) => {
    dispatch(
      setNewProduct({
        ...newProduct,
        workflowMessage,
      }),
    );
  };

  const onFileUploadDialog = () => {
    setFileDialogType('hidden');
  };

  const onVideoEmbedDialogClose = () => {
    setVideoEmbedDialogOpen(false);
  };

  const onThumbnailDialogClose = () => {
    setThumbnailDialogOpen(false);
  };

  const handleFileSubmit = (files: File[]) => {
    if (!files.length || !files[0]) return;

    setFileDialogOpen(false);
    switch (fileDialogType) {
      case 'document':
        setDocsData(files);
        break;
      case 'image':
        setFileData(CODE_MATERIAL_IMAGE_TYPE_IMAGE, files);
        break;
      case 'movie':
        setFileData(CODE_MATERIAL_IMAGE_TYPE_VIDEO, files);
        break;
      case 'hidden':
        break;
      default:
        break;
    }

    setFileDialogType('hidden');
  };

  const handleLinkSubmit = (files: File[], url: string) => {
    setFileData(CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK, files, url);

    setVideoEmbedDialogOpen(false);
  };

  // 動画用サムネ
  const handleThumbnailSubmit = (
    thumbnailData: File[],
    image: MaterialImageRegisterRequest | undefined,
  ) => {
    if (!thumbnailData || !image) return;

    const images = newProduct?.productImages
      ? [...newProduct.productImages]
      : [];

    thumbnailData.map((data) => {
      void fileToBase64(data).then((res) => {
        dispatchImage(
          CODE_MATERIAL_IMAGE_TYPE_VIDEO,
          images,
          image.data,
          '',
          res as string,
        );
      });
    });

    setThumbnailDialogOpen(false);
  };

  const removeDoc = (index: number) => {
    if (newProduct?.productDocuments) {
      const docs = [...newProduct?.productDocuments];
      docs.splice(index, 1);
      dispatch(
        setNewProduct({
          ...newProduct,
          productDocuments: docs,
        }),
      );
    }
  };

  const downloadDoc = (index: number) => {
    if (newProduct?.productDocuments) {
      const docs = newProduct?.productDocuments[index];
      if (!docs) return;

      if (docs.data) {
        void downloadFileFromBase64(docs.data, docs.productDocumentName);
      } else if (docs.url) {
        void downloadFileFromUrl(docs.url, docs.productDocumentName);
      }
    }
  };

  const handleChangeMedia = (index: number, imageType?: number) => {
    setMediaIndex(index);
    switch (imageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        setFileDialogType('image');
        break;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        setFileDialogType('movie');
        break;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        setVideoEmbedDialogOpen(true);
        break;
      default:
        break;
    }
    setMediaId(index);
  };

  const handleDeleteMedia = (index: number) => {
    const newThumbnails = [...productImageThumbnails];

    let list: Array<MaterialImageRegisterRequest> = [];
    if (newProduct?.productImages) {
      list = [...newProduct?.productImages];
      list.splice(index, 1);
      // 削除したデータのサムネイルも削除
      newThumbnails.splice(index, 1);
      setProductImageThumbnails(newThumbnails);

      dispatch(
        setNewProduct({
          ...newProduct,
          productImages: list,
        }),
      );
    }
  };

  // 動画サムネ用ダイアログ
  const handleChangeThumbnail = (index: number) => {
    setMediaIndex(index);
    setThumbnailDialogOpen(true);
    setMediaId(index);
  };

  const handleReorderMedia = (
    image: MaterialImageRegisterRequest,
    order: Order,
  ) => {
    if (!newProduct?.productImages) return;

    const index = newProduct?.productImages.indexOf(image);
    if (index === -1) return;

    let targetIndex = 0;
    const newList = [...newProduct.productImages];
    const newThumbnails = [...productImageThumbnails];
    if (order === 'up') {
      targetIndex = index - 1;
      newList.splice(targetIndex, 2, newList[index], newList[targetIndex]);
      newThumbnails.splice(
        targetIndex,
        2,
        newThumbnails[index],
        newThumbnails[targetIndex],
      );
    } else {
      targetIndex = index + 1;
      newList.splice(index, 2, newList[targetIndex], newList[index]);
      newThumbnails.splice(
        index,
        2,
        newThumbnails[targetIndex],
        newThumbnails[index],
      );
    }
    dispatch(
      setNewProduct({
        ...newProduct,
        productImages: newList,
      }),
    );
    setProductImageThumbnails(newThumbnails);
  };

  const enableDraft = useMemo(() => {
    if (!productDetail) return true;

    return (
      productDetail.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_DRAFT ||
      productDetail.productPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_DECLINE
    );
  }, [productDetail]);

  const submitButtonText = useMemo(() => {
    let text = '承認を申請する';
    if (!productDetail) return text;

    if (
      productDetail.productPeriod.materialStatus >=
      CODE_MATERIAL_STATUS_AWAITING_APPROVAL
    ) {
      text = '保存する';
    }

    return text;
  }, [productDetail]);

  const clearValidation = () => {
    setValidationError(undefined);
  };

  const registerIfValidParams = (
    draft: boolean,
    register: (draft: boolean, tag: string) => void,
  ) => {
    clearValidation();

    const validation: ValidationError = {
      productName: undefined,
      catchCopy: undefined,
      productContent: undefined,
      workflowMessage: undefined,
      subCopy: undefined,
      contact: undefined,
      externalSiteName: undefined,
      externalSiteUrl: undefined,
      productImages: undefined,
      openPeriodDate: undefined,
      usePeriodDate: undefined,
      productTag: undefined,
    };

    // 必須チェック
    // 商材名称
    if (!newProduct || !newProduct?.productName)
      validation.productName = '商材名称を入力してください。';
    // キャッチコピー
    if (!draft && (!newProduct || !newProduct?.catchCopy))
      validation.catchCopy = 'キャッチコピーを入力してください。';
    // 商材説明
    if (!draft && (!newProduct || !newProduct?.productContent))
      validation.productContent = '商材説明を入力してください。';
    // イメージ画像・動画
    if (
      !draft &&
      (!newProduct ||
        !newProduct?.productImages?.length ||
        !newProduct?.productImages[0])
    )
      validation.productImages = 'ファイルを選択してください。';

    // 文字数
    // キャッチコピー
    if (newProduct) {
      // 商材名称 100
      if (newProduct.productName && newProduct.productName.length > 100)
        validation.productName = '商材名称は100文字以内で入力してください。';
      // キャッチコピー 1000
      if (newProduct.catchCopy && newProduct.catchCopy.length > 1000)
        validation.catchCopy =
          'キャッチコピーは1000文字以内で入力してください。';
      // サブコピー 1000
      if (newProduct.subCopy && newProduct.subCopy.length > 1000)
        validation.subCopy = 'サブコピーは1000文字以内で入力してください。';
      // 商材説明 1000
      if (newProduct.productContent && newProduct.productContent.length > 1000)
        validation.productContent =
          '商材説明は1000文字以内で入力してください。';
      // 承認申請時の申し送り事項 1000
      if (
        newProduct.workflowMessage &&
        newProduct.workflowMessage.length > 1000
      )
        validation.workflowMessage =
          '承認時の申し送り事項は1000文字以内で入力してください。';
      // 問い合わせ先 100
      if (newProduct.contact && newProduct.contact.length > 100)
        validation.contact = 'お問い合わせ先は100文字以内で入力してください。';
      // 外部サイトへのリンク項目名 100
      if (
        newProduct.productExternalSite?.externalSiteName &&
        newProduct.productExternalSite.externalSiteName.length > 100
      )
        validation.externalSiteName =
          '外部サイトへのリンク項目名は100文字以内で入力してください。';
      // 外部サイトへのリンクURL 1000
      if (
        newProduct.productExternalSite?.externalSiteUrl &&
        newProduct.productExternalSite.externalSiteUrl.length > 1000
      )
        validation.externalSiteUrl =
          '外部サイトへのリンクURLは1000文字以内で入力してください。';
    }

    // フォーマットチェック
    if (newProduct) {
      // 利用期限日
      if (
        newProduct.productPeriod &&
        newProduct.productPeriod.usePeriodDate &&
        !validDate(newProduct.productPeriod.usePeriodDate)
      )
        validation.usePeriodDate = INVALID_DATE;
      if (
        newProduct.productPeriod &&
        !newProduct.productPeriod.usePeriodDate &&
        newProduct.productPeriod.usePeriodTime
      )
        validation.usePeriodDate = DATE_NOT_ENTERED;
      if (
        newProduct.productPeriod &&
        newProduct.productPeriod.usePeriodDate &&
        !newProduct.productPeriod.usePeriodTime
      )
        validation.usePeriodTime = TIME_NOT_ENTERED;
      // 公開日
      if (
        newProduct.productPeriod &&
        newProduct.productPeriod.openPeriodDate &&
        !validDate(newProduct.productPeriod.openPeriodDate)
      )
        validation.openPeriodDate = INVALID_DATE;
      if (
        newProduct.productPeriod &&
        !newProduct.productPeriod.openPeriodDate &&
        newProduct.productPeriod.openPeriodTime
      )
        validation.openPeriodDate = DATE_NOT_ENTERED;
      if (
        newProduct.productPeriod &&
        newProduct.productPeriod.openPeriodDate &&
        !newProduct.productPeriod.openPeriodTime
      )
        validation.openPeriodTime = TIME_NOT_ENTERED;
      if (
        newProduct.productExternalSite &&
        newProduct.productExternalSite.externalSiteUrl &&
        !validator.isURL(newProduct.productExternalSite.externalSiteUrl)
      )
        validation.externalSiteUrl = 'URLの形式で入力してください。';
    }

    // [新規登録・下書き・差し戻し・承認申請時]過去日チェック
    if (
      newProduct &&
      newProduct.productPeriod &&
      (!productDetail ||
        (productDetail &&
          productDetail.productPeriod.materialStatus ===
            CODE_MATERIAL_STATUS_DRAFT) ||
        (productDetail &&
          productDetail.productPeriod.materialStatus ===
            CODE_MATERIAL_STATUS_DECLINE))
    ) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        checkWithin5Days(
          newProduct?.productPeriod?.openPeriodDate,
          newProduct?.productPeriod?.openPeriodTime,
        )
      )
        validation.openPeriodDate = '5日以内の期限は設定できません。';
      if (
        checkPastDay(
          newProduct?.productPeriod?.openPeriodDate,
          newProduct?.productPeriod?.openPeriodTime,
        )
      )
        validation.openPeriodDate =
          '公開期限が過去の日時です。未来の日時を指定してください。';
      if (
        checkPastDay(
          newProduct?.productPeriod?.usePeriodDate,
          newProduct?.productPeriod?.usePeriodTime,
        )
      )
        validation.usePeriodDate =
          '利用期限が過去の日時です。未来の日時を指定してください。';
    }

    // 公開中,公開終了、一時停止中、利用期間外過去日チェック
    if (
      productDetail &&
      [
        CODE_MATERIAL_STATUS_RELEASED,
        CODE_MATERIAL_STATUS_SUSPENSION,
        CODE_MATERIAL_STATUS_EXPIRE,
        CODE_MATERIAL_STATUS_END,
      ].some((e) => e === productDetail.productPeriod.materialStatus)
    ) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        checkWithin5Days(
          newProduct?.productPeriod?.openPeriodDate,
          newProduct?.productPeriod?.openPeriodTime,
        )
      )
        validation.openPeriodDate = '5日以内の期限は設定できません。';
    }

    let newTag = '';

    // タグからカンマ、重複、スペース削除
    if (newProduct && newProduct.productTag) {
      newTag = removeIncorrectTagEntry(newProduct.productTag);
      if (
        newTag.replaceAll(',', '').length > 5000 ||
        newTag.split(',').some((e) => e.length > 100)
      ) {
        validation.productTag =
          '商材タグは、タグ1つにつき100文字以内、全体で5000文字以内で入力してください。';
      } else {
        dispatch(
          setNewProduct({
            ...newProduct,
            productTag: newTag,
          }),
        );
      }
    }

    if (
      validation.productName ||
      validation.catchCopy ||
      validation.productContent ||
      validation.workflowMessage ||
      validation.externalSiteName ||
      validation.externalSiteUrl ||
      validation.usePeriodDate ||
      validation.usePeriodTime ||
      validation.openPeriodDate ||
      validation.openPeriodTime ||
      validation.productTag ||
      validation.contact ||
      validation.subCopy ||
      validation.productContent ||
      validation.productImages
    ) {
      // バリデーションエラー
      setValidationError(validation);
    } else if (
      productDetail &&
      productDetail.productPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_APPROVAL &&
      checkPastDay(
        newProduct?.productPeriod?.usePeriodDate,
        newProduct?.productPeriod?.usePeriodTime,
      )
    ) {
      // 利用期間外にする
      setOpenExpireConfirmDialog(true);
      setDraft(draft);
    } else if (
      productDetail &&
      productDetail.productPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_APPROVAL &&
      checkPastDay(
        newProduct?.productPeriod?.openPeriodDate,
        newProduct?.productPeriod?.openPeriodTime,
      )
    ) {
      // 公開終了にする
      setOpenEndConfirmDialog(true);
      setDraft(draft);
    } else if (
      productDetail &&
      productDetail?.productPeriod?.materialStatus <
        CODE_MATERIAL_STATUS_RELEASED &&
      !draft &&
      !hasMaterialApprovalUser()
    ) {
      // 承認者未設定の場合ダイアログ表示
      setOpenNoApprovalUserDialog(true);
    } else if (
      !draft &&
      productDetail &&
      productDetail.productPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_RELEASED
    ) {
      setIsRegister(true);
      register(true, newTag);
    } else {
      setIsRegister(true);
      register(draft, newTag);
    }
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  const register = (draft: boolean) => {
    setIsSubmitting((prev) => !prev);
    registerIfValidParams(draft, handleProductRegister);
  };

  const handleProductRegisterWrapper = (isExpire?: boolean) => {
    if (!handleProductRegister) return;
    setInitialNewProduct(undefined);
    handleProductRegister(
      isExpire || draft,
      // 利用期間外のときタグを消さない
      isExpire ? newProduct?.productTag || '' : '',
    );
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  // 関連資料DL制御
  const [productDocumentDisabled, setProductDocumentDisabled] = useState<
    number | null
  >(null);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: validationError,
      })}
    >
      {validationError && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}
      <NoticeDialog
        open={openNoApprovalUserDialog}
        handleClose={() => setOpenNoApprovalUserDialog(false)}
        title={DIALOG_NO_APPROVAL_USER}
      />
      <FileUploadDialog
        handleSubmit={handleFileSubmit}
        onClose={onFileUploadDialog}
        open={fileDialogOpen}
        isProduct
        fileType={fileDialogType}
      />
      <VideoEmbedDialog
        url={
          newProduct?.productImages &&
          newProduct?.productImages[mediaIndex] &&
          newProduct?.productImages[mediaIndex].url
        }
        open={videoEmbedDialogOpen}
        onClose={onVideoEmbedDialogClose}
        handleSubmit={handleLinkSubmit}
        isAddNewItem={isAddNewItem}
      />
      <ThumbnailUploadDialog
        materialImage={
          newProduct?.productImages && newProduct?.productImages[mediaIndex]
        }
        open={thumbnailDialogOpen}
        onClose={onThumbnailDialogClose}
        handleSubmit={handleThumbnailSubmit}
      />
      <TagSelectDialog
        tagType="edit"
        category="Product"
        handleCheck={handleTagCheck}
        tags={productTags?.productTags ? productTags?.productTags : undefined}
        usedTags={
          productTags?.usedProductTags
            ? productTags?.usedProductTags
            : undefined
        }
        open={tagSelectDialogOpen}
        selectedTags={selectedTags}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagSelectDialogOpen(false);
        }}
        handleSubmit={(selectedTags: Array<string>) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          handleProductTag(selectedTags);
          setTagSelectDialogOpen(false);
        }}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
      <ConfirmDialog
        showIcon
        buttonText="公開終了する"
        open={openEndConfirmDialog}
        title="公開終了"
        text="公開終了になります。"
        handleCancel={() => setOpenEndConfirmDialog(false)}
        handleSubmit={() => {
          setOpenEndConfirmDialog(false);
          handleProductRegisterWrapper();
          setDraft(false);
        }}
      />
      <ConfirmDialog
        showIcon
        buttonText="利用期間外にする"
        open={openExpireConfirmDialog}
        title="利用期間外"
        text="利用期間外になります。"
        handleCancel={() => setOpenExpireConfirmDialog(false)}
        handleSubmit={() => {
          setIsRegister(true);
          setOpenExpireConfirmDialog(false);
          // 公開期限日過去日チェック
          if (
            productDetail &&
            productDetail.productPeriod.materialStatus >=
              CODE_MATERIAL_STATUS_APPROVAL &&
            checkPastDay(
              newProduct?.productPeriod?.openPeriodDate,
              newProduct?.productPeriod?.openPeriodTime,
            )
          ) {
            setOpenEndConfirmDialog(true);
          } else {
            handleProductRegisterWrapper(true);
            setDraft(false);
          }
        }}
      />
      <Content title="商材登録・編集">
        <div>
          <MandatoryHeadlineLabel title="商材名称" />
          <OneLineTextField
            error={validationError?.productName}
            value={newProduct?.productName}
            handleChangeText={(text) => handleChangeProductName(text)}
            label="商材名称を入力"
          />
          <LetterCountLabel
            limit="20"
            count={
              newProduct?.productName
                ? `${newProduct?.productName.length}`
                : '0'
            }
          />
          <Box display="flex">
            <Box flexGrow={1}>
              <SubHeadlineLabel text="商材タグ" />
            </Box>
            <Box mt={3}>
              <ButtonLink
                click={() => setTagSelectDialogOpen(true)}
                text="＋リストから追加する"
              />
            </Box>
          </Box>
          <OneLineTextField
            error={validationError?.productTag}
            handleChangeText={(text) => handleTagChange(text)}
            value={newProduct?.productTag}
            label="商材タグを入力"
          />
          <SupportTextLabel text="複数登録の場合はカンマ（,)で区切ってください" />
          <SubHeadlineLabel text="お問い合わせ先" />
          <OneLineTextField
            error={validationError?.contact}
            value={newProduct?.contact}
            handleChangeText={(text) => handleChangeContact(text)}
            label="お問い合わせ先を入力"
          />
          <SubHeadlineLabel text="資料" />
          <Box mb={1} width={200} height={36}>
            <PrimaryButton
              click={() => setFileDialogType('document')}
              text="資料をアップロード"
              icon={<UploadIcon />}
            />
          </Box>
          <List className={classes.docs}>
            {newProduct?.productDocuments &&
              newProduct.productDocuments.map((doc, index) => (
                <DownloadDocumentRow
                  key={index}
                  clickFile={() => {
                    setProductDocumentDisabled(index);
                    downloadDoc(index);
                    setTimeout(() => setProductDocumentDisabled(null), 1000);
                  }}
                  clickClose={removeDoc}
                  name={doc.productDocumentName}
                  index={index}
                  icon={<PDFIcon />}
                  disabled={productDocumentDisabled === index}
                />
              ))}
          </List>
          <MandatoryHeadlineLabel title="キャッチコピー" />
          <MultipleLinesTextField
            error={validationError?.catchCopy}
            value={newProduct?.catchCopy}
            handleChangeText={(text) => handleChangeCatchCopy(text)}
            rows={5}
          />
          <LetterCountLabel
            limit="20"
            count={
              newProduct?.catchCopy ? `${newProduct?.catchCopy.length}` : '0'
            }
          />
          <SubHeadlineLabel text="サブコピー" />
          <MultipleLinesTextField
            error={validationError?.subCopy}
            value={newProduct?.subCopy}
            handleChangeText={(text) => handleChangeSubCopy(text)}
            rows={5}
          />
          <LetterCountLabel
            limit="20"
            count={newProduct?.subCopy ? `${newProduct?.subCopy.length}` : '0'}
          />
          <MandatoryHeadlineLabel title="商材説明" />
          <MultipleLinesTextField
            error={validationError?.productContent}
            value={newProduct?.productContent}
            handleChangeText={(text) => handleChangeProductContent(text)}
            rows={5}
          />
          <LetterCountLabel
            limit="150"
            count={
              newProduct?.productContent
                ? `${newProduct?.productContent.length}`
                : '0'
            }
          />
          <HeadlineLabel text="外部サイトへのリンク" />
          <SubHeadlineLabel text="項目名" />
          <OneLineTextField
            error={validationError?.externalSiteName}
            value={newProduct?.productExternalSite?.externalSiteName}
            handleChangeText={(text) => handleChangeExternalSiteName(text)}
          />
          <LetterCountLabel
            limit="25"
            count={
              newProduct?.productExternalSite?.externalSiteName
                ? `${newProduct?.productExternalSite.externalSiteName.length}`
                : '0'
            }
          />
          <SubHeadlineLabel text="URL" />
          <OneLineTextField
            error={validationError?.externalSiteUrl}
            value={newProduct?.productExternalSite?.externalSiteUrl}
            handleChangeText={(text) => handleChangeExternalSiteUrl(text)}
          />
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            className={classes.container}
            height={36}
          >
            <Box flexGrow={1}>
              <MandatoryHeadlineLabel title="イメージ画像・動画" />
            </Box>
            <Box width={218}>
              <PrimaryButton
                click={() => setFileDialogType('image')}
                text="新規画像をアップロード"
                icon={<ImageIcon />}
              />
            </Box>
            <Box className={classes.movieButton} width={218}>
              <PrimaryButton
                click={() => setFileDialogType('movie')}
                text="新規動画をアップロード"
                icon={<MovieIcon />}
              />
            </Box>
            <Box width={204}>
              <PrimaryButton
                click={() => {
                  setIsAddNewItem(true);
                  setVideoEmbedDialogOpen(true);
                }}
                text="新規動画URL埋め込み"
                icon={<URLLinkIcon />}
              />
            </Box>
          </Box>
          {newProduct?.productImages &&
            newProduct.productImages.map((productImage, index) => (
              <MediaCard
                handleSort={(order) => handleReorderMedia(productImage, order)}
                isTop={index === 0 && true}
                isBottom={
                  index + 1 === newProduct.productImages?.length && true
                }
                sortable
                url={productImage?.url}
                materialImageType={productImage?.materialImageType}
                base64={productImage?.data}
                thumbnailUrl={productImageThumbnails[index]}
                handleChangeMedia={() => {
                  handleChangeMedia(
                    index,
                    productImage && productImage?.materialImageType,
                  );
                  setIsAddNewItem(false);
                }}
                handleDeleteMedia={() => handleDeleteMedia(index)}
                key={index}
                thumbnailData={productImage.thumbnailData}
                handleChangeThumbnail={() => handleChangeThumbnail(index)}
              />
            ))}
          {validationError?.productImages && (
            <Box className={classes.error}>
              <ErrorIcon />
              <Typography className={classes.errorText}>
                {validationError.productImages}
              </Typography>
            </Box>
          )}
          <DateTimePicker
            dateError={validationError?.usePeriodDate}
            timeError={validationError?.usePeriodTime}
            subtitle="利用期限"
            supportText="ランディングページの新規作成・編集にて組み込める期限"
            width="332px"
            date={
              newProduct?.productPeriod?.usePeriodDate
                ? newProduct?.productPeriod?.usePeriodDate
                : undefined
            }
            time={
              newProduct?.productPeriod?.usePeriodTime
                ? formateTimeHHMMColon(newProduct?.productPeriod?.usePeriodTime)
                : undefined
            }
            handleChangeDate={(date) => handleChangeUsePeriodDate(date)}
            handleChangeTime={(time) => handleChangeUsePeriodTime(time)}
          />
          <DateTimePicker
            dateError={validationError?.openPeriodDate}
            timeError={validationError?.openPeriodTime}
            subtitle="公開期限"
            supportText="公開中のランディングページに組み込んでいる場合、その情報が表示される期限　※5日以内の期限設定はできません"
            width="332px"
            date={
              newProduct?.productPeriod?.openPeriodDate
                ? newProduct?.productPeriod?.openPeriodDate
                : undefined
            }
            time={
              newProduct?.productPeriod?.openPeriodTime
                ? formateTimeHHMMColon(
                    newProduct?.productPeriod?.openPeriodTime,
                  )
                : undefined
            }
            handleChangeDate={(date) => handleChangeOpenPeriodDate(date)}
            handleChangeTime={(time) => handleChangeOpenPeriodTime(time)}
          />
          <br />
          <SupportTextLabel text="※期限制限をしない場合は空欄のままにしてください。後から編集可能です" />
          <Typography variant="body2" className={classes.caution}>
            {END_OF_OPEN_CAUTION}
          </Typography>
          <br />
          <SubHeadlineLabel text="承認申請時の申し送り事項" />
          <MultipleLinesTextField
            error={validationError?.workflowMessage}
            value={newProduct?.workflowMessage}
            handleChangeText={(text) => handleChangeWorkflowMessage(text)}
            rows={7}
          />
          <Divider className={classes.divider} />
          <Box
            display="flex"
            justifyContent="center"
            className={classes.container}
          >
            <Box m={1} width={332} height={48}>
              <SecondaryButton
                disabled={!enableDraft}
                click={() => register(true)}
                text="下書き保存"
              />
            </Box>
            <Box m={1} width={332} height={48}>
              <PrimaryButton
                disabled={isSubmitting}
                click={() => register(false)}
                text={submitButtonText}
              />
            </Box>
          </Box>
          <PageControlBox
            enableDraft={enableDraft}
            handleDraftSave={() => register(true)}
            handleEnd={() => {
              if (newProduct) {
                setOpenExitConfirmDialog(true);
              }
              navigate.navigateReplace('/adm/products');
            }}
            previewData={previewData}
            isProductOrExample
          />
        </div>
      </Content>
    </Box>
  );
};
export default ProductRegisterEdit;

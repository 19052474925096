import { useState, useEffect } from 'react';
import UserRegister from 'components/pages/UsersRegister';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { hooks } from 'libs';
import { useParams } from 'react-router-dom';
import {
  clearUserInfoDetailReq,
  setUserInfoDetailReq,
} from 'core/modules/userInfoDetailReq';
import { UserInfoDetailRequest } from 'core/domain/user';

export interface CheckItems {
  author: boolean;
  approve: boolean;
  admin: boolean;
}

export interface UserInfoType {
  userId: number | null;
  name: string;
  status: boolean;
  userType: string;
  email: string;
  auth: {
    landingPage: Partial<CheckItems>;
    productIntroductionExample: Partial<CheckItems>;
    tenantAdministrator: Partial<CheckItems>;
  };
  notes: {
    note1: string;
    note2: string;
    note3: string;
  };
}

const UserRegisterPageContainer = () => {
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const userInfoDetail = useSelector(
    (state: Modules.AppState) => state.userInfoDetail,
    shallowEqual,
  );
  const tenantId = useSelector(
    (state: Modules.AppState) => state.userInfo?.tenantId,
    shallowEqual,
  );
  const newUserInfoDetail = useSelector(
    (state: Modules.AppState) => state.userInfoDetailRegReq,
    shallowEqual,
  );
  const { userId } = useParams<{ userId: string }>();
  const [count, setCount] = useState(0);

  const initialUserInfoDetailValue = {
    userId: null,
    userName: '',
    mailAddress: '',
    lpAuthor: true,
    note2: '',
    lpApprover: false,
    materialAuthor: false,
    materialApprover: false,
    userType: '',
    note1: '',
    note3: '',
    tenantAdmin: false,
    available: true,
  };

  const [initialUserInfoDetail, setInitialUserInfoDetail] =
    useState<UserInfoDetailRequest>(initialUserInfoDetailValue);

  const handleCancel = () => {
    if (userInfoDetail?.userId === undefined) return;
    dispatch(setUserInfoDetailReq({ ...userInfoDetail }));
  };

  const initializeUserInfoDetail = () => {
    setInitialUserInfoDetail(initialUserInfoDetailValue);
    dispatch(setUserInfoDetailReq(initialUserInfoDetailValue));
  };

  useEffect(() => {
    if (!userId || !tenantId) return;
    if (count === 0)
      dispatch(Usecases.user.sendGetUserInfoDetail(token, tenantId, userId));
    if (userInfoDetail) {
      const body: UserInfoDetailRequest = {
        userId: userInfoDetail.userId,
        userName: userInfoDetail.userName,
        mailAddress: userInfoDetail.mailAddress,
        userType: userInfoDetail.userType,
        lpAuthor: userInfoDetail.lpAuthor,
        lpApprover: userInfoDetail.lpApprover,
        materialAuthor: userInfoDetail.materialAuthor,
        materialApprover: userInfoDetail.materialApprover,
        tenantAdmin: userInfoDetail.tenantAdmin,
        note1: userInfoDetail.note1,
        note2: userInfoDetail.note2,
        note3: userInfoDetail.note3,
        available: userInfoDetail.available,
      };
      dispatch(setUserInfoDetailReq({ ...body }));
      setInitialUserInfoDetail({ ...body });
    }

    setCount(1);
  }, [userId, dispatch, token, userInfoDetail, tenantId, count]);

  useEffect(
    () => () => {
      dispatch(clearUserInfoDetailReq());
    },
    [dispatch],
  );

  return (
    <UserRegister
      newUserInfoDetail={newUserInfoDetail}
      initialUserInfoDetail={initialUserInfoDetail}
      handleCancel={handleCancel}
      handleInitialize={initializeUserInfoDetail}
    />
  );
};

export default UserRegisterPageContainer;

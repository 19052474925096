/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Inquiries } from 'core/domain/inquiries'

const initialState = null

export function createInitialState(): Inquiries | null {
  return initialState
}

export const SET_INQUIRIES = 'inquiries/set' as const
export const CLEAR_INQUIRIES = 'inquiries/clear' as const

export function setInquiries(data: Inquiries) {
  return {
    type: SET_INQUIRIES,
    payload: {
      data,
    },
  }
}

export function clearInquiries() {
  return {
    type: CLEAR_INQUIRIES,
  }
}

type SetInquiriesAction = ReturnType<typeof setInquiries>
type ClearInquiriesDetailAction = ReturnType<typeof clearInquiries>
type Action = SetInquiriesAction | ClearInquiriesDetailAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_INQUIRIES:
      return action.payload.data
    case CLEAR_INQUIRIES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import InquiryEditPage from 'components/pages/InquiryEdit';
import { Usecases, Modules } from 'core';
import { hooks } from 'libs';
import { InquirySupportsRegisterRequest } from 'core/domain/inquiries';
import { clearInquiryDetail } from 'core/modules/inquiryDetail';
import { clearInquirySupports } from 'core/modules/inquirySupports';
import { RouteComponentProps } from 'react-router';

/**
 * Presenter
 */

interface InquiryEditProps extends RouteComponentProps {
  // history: H.History<S>;
  location: {
    state: number;
    hash: string;
    key: string;
    pathname: string;
    search: string;
  };
  // match: match<Params>;
  // staticContext?: C;
}

const InquiryEditPageContainer = (props: InquiryEditProps | null) => {
  // const navigate = hooks.useNavigate();

  const inquiryId = props !== null ? props.location.state : null;

  const dispatch = useDispatch();
  const InquiryDetail = useSelector(
    (state: Modules.AppState) => state.inquiryDetail,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const inquirySupports = useSelector(
    (state: Modules.AppState) => state.inquirySupports,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  // const { inquiryId } = useParams<{ inquiryId: string }>();

  // お問い合わせ詳細/お問い合わせ対応一覧取得
  useEffect(() => {
    if (!userInfo || !inquiryId) return;
    dispatch(
      Usecases.inquiries.sendGetInquiryDetail(
        token,
        userInfo.tenantId,
        inquiryId,
        // Number.parseInt(inquiryId),
      ),
    );
    dispatch(
      Usecases.inquiries.sendGetInquirySupports(
        token,
        userInfo.tenantId,
        inquiryId,
        // Number.parseInt(inquiryId),
      ),
    );

    return () => {
      dispatch(clearInquiryDetail());
      dispatch(clearInquirySupports());
    };
  }, [dispatch, token, userInfo, inquiryId]);

  // お問い合わせ一覧取得
  useEffect(() => {
    if (!userInfo || !InquiryDetail) return;
    dispatch(Usecases.inquiries.sendGetInquiries(token, userInfo.tenantId));
  }, [dispatch, token, userInfo, InquiryDetail]);

  /**
   * お問い合わせ対応登録・更新処理
   * @param request リクエストパラメーター
   * @returns
   */
  const handleRegisterSupport = (request: InquirySupportsRegisterRequest) => {
    if (!userInfo || !InquiryDetail) return;
    if (request.inquirySupportId) {
      dispatch(
        Usecases.inquiries.sendPutInquirySupport(
          token,
          userInfo.tenantId,
          InquiryDetail?.inquiryId,
          request.inquirySupportId,
          request,
        ),
      );
    } else {
      dispatch(
        Usecases.inquiries.sendPostInquirySupport(
          token,
          userInfo.tenantId,
          InquiryDetail?.inquiryId,
          request,
        ),
      );
    }
  };

  return (
    <InquiryEditPage
      inquiryDetail={InquiryDetail}
      inquirySupports={inquirySupports}
      handleRegisterSupport={handleRegisterSupport}
    />
  );
};

export default InquiryEditPageContainer;

/* eslint-enable */

import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { Usage } from '../domain/dashborad';
import { setUsage } from '../modules/usage';
import { setUsagesHistory } from '../modules/usages';
import { UsagesHistory} from '../domain/dashboard';

const didCallGetUsages = (dispatch: Dispatch, data: Usage) =>
  dispatch(setUsage(data));

export const sendGetUsages =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/usages`,
      token,
      null,
      dispatch,
      didCallGetUsages,
    );
  };


const didCallGetUsagesHistory = (dispatch: Dispatch, data: UsagesHistory) =>
  dispatch(setUsagesHistory(data.usages));
/** 利用履歴取得 */
export const sendGetUsagesHistory =
  (token: string | undefined, tenantId: string, selectYear: string) =>
  async (dispatch: Dispatch) => {
    const param = {
      year: selectYear,
    };
    const api = param ? `?${Api.createApi(param)}` : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/dashboards/usages/history${api}`,
      token,
      null,
      dispatch,
      didCallGetUsagesHistory,
    );
  };

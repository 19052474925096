import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from 'components/atoms';

/**
 * Interface
 */

export interface TotalCountTextProps {
  totalCount?: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '13px',
      color: theme.palette.gray[900],
      textAlign: 'end',
      verticalAlign: 'center',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        1,
      )}px 0`,
    },
    count: {
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    limit: {
      color: theme.palette.gray[800],
    },
  }),
);

/**
 * Presenter
 */

const TotalCountText = (props: TotalCountTextProps) => {
  const { totalCount } = props;
  const classes = useStyles({});

  return (
    <Typography className={classes.root} variant="caption" display="block">
      <span className={classes.limit}>全{totalCount}件</span>
    </Typography>
  );
};
export default TotalCountText;

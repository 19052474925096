import { useState, useMemo, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, PrimaryButton, SecondaryButton, IconButton } from 'components/atoms'
import { CloseWhiteIcon } from 'assets/images'
import { MyLibraryFiles } from 'core/domain/myLibrary'
import clsx from 'clsx'

/**
 * Interface
 */

export interface myLibraryImageSelectDialogProps {
  open: boolean
  handleCancel: () => void
  handleSubmit: (id?: number) => void
  myLibraryImages: Array<MyLibraryFiles> | null
  selectedId?: number
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    scroll: {
      overflow: 'hidden',
      overflowY: 'auto',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      borderColor: theme.palette.green[800],
    },
    img: {
      width: 152,
      height: 152,
      objectFit: 'contain',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        borderColor: theme.palette.green[800],
      },
    }
  }),
)

const MyLibraryImageSelectDialog = (props: myLibraryImageSelectDialogProps) => {
  const classes = useStyles({})
  const { open, handleCancel, handleSubmit, myLibraryImages, selectedId } = props
  const [selectedImage, setSelectedImage] = useState<number | undefined>(undefined)


  useEffect(()=>{

    setSelectedImage(selectedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const handleSelectImage = (id: number|undefined) => {
    if (selectedImage === id) {
      setSelectedImage(undefined)
    } else {
      setSelectedImage(id)
    }
  }

  const images = useMemo(() => {
    if (!myLibraryImages) return
    
    // eslint-disable-next-line consistent-return
    return myLibraryImages.map((image, index) =>
      <Box className={classes.ImageCard} key={index} onClick={() => handleSelectImage(image.fileId)}>
        <img
          className={clsx(classes.img, { [classes.selectedImg]: selectedImage === image.fileId })}
          src={image.url} />
      </Box>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLibraryImages, selectedImage])

  const handleCancelClick = () => {
    setSelectedImage(selectedId)
    handleCancel()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}>
      <Box textAlign="center">
        <Box className={classes.scroll} p={1} height={386} display="flex" flexWrap="wrap" justifyContent="spaceBetween" pb={2}>
          {images}
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={0}>
            <Box width={332} height={48}>
              <SecondaryButton click={() => handleCancelClick()} text="キャンセル" />
            </Box>
            <Box ml={2} width={332} height={48}>
              <PrimaryButton click={() => handleSubmit(selectedImage)} text="選択する" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
      <IconButton onClick={() => handleCancelClick()} className={classes.closeButton}>
        <CloseWhiteIcon/>
      </IconButton>
    </Dialog>
  )
}
export default MyLibraryImageSelectDialog;

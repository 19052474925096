import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Box } from 'components/atoms';

import {
  SALAD_BAR_GREEN_900,
  SALAD_BAR_PINK_800,
  SALAD_BAR_GREEN_700,
} from 'constants/color';
import {
  NOTICE_TYPE_IMPORTANT,
  NOTICE_TYPE_COMMON,
  NOTICE_TYPE_OVERALL,
} from 'constants/text';

/**
 * Interface
 */

export interface NoticeStatusLabelProps {
  status: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '20px',
      borderWidth: '2px',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.text.secondary,
      // width: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'default',
      },
    },
    orange: {
      borderColor: '#D87F24',
      color: '#D87F24',
    },
    blue: {
      borderColor: '#3D9FCB',
      color: '#3D9FCB',
    },
    red: {
      borderColor: '#aa0000',
      color: '#aa0000',
    },

    buttonDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    btnRed: {
      color: 'white',
      backgroundColor: SALAD_BAR_PINK_800,
      maxHeight: '24px',
      width: '56px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnGreen: {
      backgroundColor: SALAD_BAR_GREEN_700,
      maxHeight: '24px',
      width: '164px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    readText: {
      color: '#82827E',
    },
  }),
);

interface StatusType {
  status: number;
  label: string;
}

// 1：重要／2：通常／3：サービスのお知らせ
const STATUS_TYPES: Array<StatusType> = [
  {
    status: 1,
    label: NOTICE_TYPE_IMPORTANT,
  },
  {
    status: 2,
    label: NOTICE_TYPE_COMMON,
  },
  {
    status: 3,
    label: NOTICE_TYPE_OVERALL,
  },
];

/**
 * Presenter
 */

const NoticeStatusLabel = (props: NoticeStatusLabelProps) => {
  const classes = useStyles({});
  const { status } = props;

  return (
    <Box className={classes.buttonDiv}>
      {status === 1 && <Box className={classes.btnRed}>重要</Box>}
      {status === 3 && (
        <Box
          className={classes.btnGreen}
          style={{ color: SALAD_BAR_GREEN_900 }}
        >
          サービスからのお知らせ
        </Box>
      )}
    </Box>

    // <Typography
    //   className={`${classes.root} ${decideClass}`}
    //   variant="caption"
    //   display="block"
    // >
    //   {statusLabel}
    // </Typography>
  );
};
export default NoticeStatusLabel;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { MaterialWorkflow } from 'core/domain/material'

const initialState = null

export function createInitialState(): Array<MaterialWorkflow> | null {
  return initialState
}

export const SET_PRODUCT_WORKFLOWS = 'product-workflows/set' as const
export const CLEAR_PRODUCT_WORKFLOWS = 'product-workflows/clear' as const

export function setProductWorkflows(data: Array<MaterialWorkflow>) {
  return {
    type: SET_PRODUCT_WORKFLOWS,
    payload: {
      data,
    },
  }
}

export function clearProductWorkflows() {
  return {
    type: CLEAR_PRODUCT_WORKFLOWS,
  }
}

type SetProductWorkflowsAction = ReturnType<typeof setProductWorkflows>
type ClearProductWorkflowsAction = ReturnType<typeof clearProductWorkflows>
type Action = SetProductWorkflowsAction | ClearProductWorkflowsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCT_WORKFLOWS:
      return action.payload.data.reverse()
    case CLEAR_PRODUCT_WORKFLOWS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useState, useMemo, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { NoImage } from 'assets/images'
import { CommonComboBox, Box, FormControl, RadioGroup, Typography, MultipleLinesTextField, LetterCountLabel, PrimaryButton, NormalEditBottomButton, CommonRadioButton, ErrorText } from 'components/atoms'
import { MandatoryHeadlineLabel } from 'components/molecules'
import { AdminTitleDialog, ImageSelectDialog } from 'components/organisms'
import { LpDetail, LPRegisterRequest } from 'core/domain/lp'
import { setNewLp } from 'core/modules/newLp'
import { useDispatch } from 'react-redux'
import { TokuisakiTitles, LpMainImage } from 'core/domain/settings'
import { NormalEditErrors } from 'core/domain/validationErrors'
import { setNormalEditErrors } from 'core/modules/normalEditErrors'

export interface NormalMainVisualEditProps {
  goNext?: () => void
  goBack?: () => void
  newLp: LPRegisterRequest | null
  lpDetail: LpDetail | null
  tokuisakiTitles: TokuisakiTitles | null
  normalEditErrors: NormalEditErrors | null
  lpMainVisualImages: Array<LpMainImage> | null
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 59,
    },
    company: {
      color: theme.palette.green[800],
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3)
    },
    mainImage: {
      maxWidth: 500,
    },
  })
)

/**
 * Presenter
 */

export default function NormalMainVisualEdit(props: NormalMainVisualEditProps) {

  const classes = useStyles({})
  const { goNext, newLp, tokuisakiTitles, normalEditErrors, lpMainVisualImages } = props
  const [dialogOpen, setDialogOpen] = useState(false)
  const { lpId } = useParams<{ lpId: string }>()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!newLp) return
    if (newLp && (newLp.tokuisakiDisplayed === null || newLp.tokuisakiDisplayed === undefined)) {
      dispatch(setNewLp({
        ...newLp,
        tokuisakiDisplayed: false,
      }))
    }
  }, [newLp])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setNewLp({
      ...newLp,
      tokuisakiDisplayed:event.target.value === 'yes'
    }))
    if((event.target.value === 'yes' && newLp?.tokuisakiName)
      || event.target.value==='no'){
      dispatch(setNormalEditErrors({
        ...normalEditErrors,
        tokuisakiTitle:undefined
      }))
    }
  }

  const handleTextChange = (key: string, value: string) => {
    dispatch(setNewLp({
      ...newLp,
      [key]: value,
    }))
    dispatch(setNormalEditErrors({
      ...normalEditErrors,
      [key]:undefined
    }))
  }

  const handleSelectMainImage = (id?: number) => {
    if (!id) {
      dispatch(setNewLp({
        ...newLp,
        lpMainImageData: {
          lpMainImageId: undefined,
          url: undefined,
        }
      }))
    } else {
      const image = lpMainVisualImages?.find(image => image.lpMainImageId === id)
      dispatch(setNewLp({
        ...newLp,
        lpMainImageData: {
          lpMainImageId: image?.lpMainImageId,
          url: image?.url,
        }
      }))
    }

    if(normalEditErrors?.lpMainImageData){
      dispatch(setNormalEditErrors({
        ...normalEditErrors,
        lpMainImageData:undefined
      }))
    }

    setDialogOpen(false)
  }

  const mainImageData = useMemo(() => {
    if (!newLp || !newLp.lpMainImageData) return undefined

    return newLp.lpMainImageData.url
  }, [newLp])

  const handleNext = () => {
    goNext&&goNext()
  }

  return (
    <Box className={classes.root} width={696} height={435} mx="auto">
      <AdminTitleDialog
        open={!!(!lpId && !newLp?.title)}
        title={newLp?.title}
        handleTextChange={(text) => handleTextChange('title', text)} />
      <ImageSelectDialog
        lpMainVisualImages={lpMainVisualImages}
        selectedId={newLp?.lpMainImageData?.lpMainImageId}
        open={dialogOpen}
        handleCancel={() => setDialogOpen(false)}
        handleSubmit={(id?: number) => handleSelectMainImage(id)}
      />
      <MandatoryHeadlineLabel title="得意先名" />
      <FormControl>
        <RadioGroup aria-label="company" value={newLp?.tokuisakiDisplayed ? 'yes':'no'} onChange={handleChange}>
          <CommonRadioButton value="yes" label="表示あり" />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography className={classes.company} variant="caption">{newLp?.tokuisakiName ? newLp.tokuisakiName : '得意先名'}</Typography>
            <CommonComboBox
              value={newLp?.tokuisakiTitle}
              width="332px"
              options={tokuisakiTitles?.tokuisakiTitles ? tokuisakiTitles.tokuisakiTitles : []}
              disabled={!newLp?.tokuisakiDisplayed}
              handleChangeText={(text) => handleTextChange('tokuisakiTitle', text)} />
          </Box>
          {normalEditErrors?.tokuisakiTitle && <ErrorText error={normalEditErrors.tokuisakiTitle} />}
          <CommonRadioButton value="no" label="非表示" />
        </RadioGroup>
      </FormControl>
      <MandatoryHeadlineLabel title="見出し" />
      <MultipleLinesTextField
        error={normalEditErrors?.header}
        value={newLp?.header}
        handleChangeText={(text) => handleTextChange('header', text)}
        rows={2} />
      <LetterCountLabel limit="30" count={newLp?.header ? `${newLp?.header.length}` : '0'} />
      <MandatoryHeadlineLabel title="メインビジュアル" />
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <img className={classes.mainImage} src={mainImageData || NoImage} />
        <Box width={162} ml={1}>
          <Box height={27} mt={1} mb={1}>
            <PrimaryButton text="選択" click={() => setDialogOpen(true)}/>
          </Box>
        </Box>
      </Box>
      {normalEditErrors?.lpMainImageData&&<ErrorText error={normalEditErrors?.lpMainImageData}/>}
      <NormalEditBottomButton click={() => handleNext()} text="挨拶の編集" />
    </Box>
  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { NormalEditErrors } from 'core/domain/validationErrors'

const initialState = null 

export function createInitialState(): NormalEditErrors | null {
  return initialState
}

export const SET_NORMAL_EDIT_ERRORS = 'normal-edit-errors/set' as const
export const CLEAR_NORMAL_EDIT_ERRORS = 'normal-edit-errors/clear' as const

export function setNormalEditErrors(data:NormalEditErrors){
  return {
    type: SET_NORMAL_EDIT_ERRORS,
    payload: {
      data,
    },
  }
}

export function clearNormalEditErrors(){
  return {
    type: CLEAR_NORMAL_EDIT_ERRORS,
  }
}

type SetNormalEditErrorsAction = ReturnType<typeof setNormalEditErrors>
type ClearNormalEditErrorsAction = ReturnType<typeof clearNormalEditErrors>
type Action = SetNormalEditErrorsAction | ClearNormalEditErrorsAction

export function reducer(state = createInitialState(), action: Action){
  switch (action.type) {
    case SET_NORMAL_EDIT_ERRORS:
      return action.payload.data
    case CLEAR_NORMAL_EDIT_ERRORS:
      return initialState
    default: 
      return state
  }
}/* eslint-enable */

import { useCallback, useEffect } from 'react';
import CustomerRegister from 'components/pages/CustomerRegister';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modules, Usecases } from 'core';
import { clearCustomerDetail } from 'core/modules/customerDetail';
import { clearNewCustomer, setNewCustomer } from 'core/modules/newCustomer';
import { clearCustomerTags } from 'core/modules/customerTags';
import { hooks } from 'libs';
import {
  clearCustomerTagsRequest,
  setCustomerTagsRequest,
} from 'core/modules/customerTagsRequest';
import { clearCustomerMailAddress } from 'core/modules/customerMailAddress';

const CustomerRegisterPageContainer = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams<{ customerId: string | undefined }>();
  const token = hooks.useAuth().accessToken;
  const customerDetail = useSelector(
    (state: Modules.AppState) => state.customerDetail,
    shallowEqual,
  );
  const newCustomer = useSelector(
    (state: Modules.AppState) => state.newCustomer,
    shallowEqual,
  );
  const customerTags = useSelector(
    (state: Modules.AppState) => state.customerTags,
    shallowEqual,
  );
  const customerMailAddress = useSelector(
    (state: Modules.AppState) => state.customerMailAddress,
    shallowEqual,
  );
  const customerTagsRequest = useSelector(
    (state: Modules.AppState) => state.customerTagsRequest,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );

  useEffect(() => {
    // 顧客更新後に新規作成するとデータが残るため初期化する
    dispatch(clearCustomerDetail());
    // eslint-disable-next-line
  }, []);

  const handleCustomerRegister = useCallback(
    (newTag?: string) => {
      if (!userInfo || !newCustomer) return;

      // タグを設定する場合、リアルタイムに値を設定
      if (customerId) {
        dispatch(
          Usecases.customer.sendPutCustomerDetail(
            token,
            userInfo.tenantId,
            customerId,
            {
              ...newCustomer,
              customerTag: newTag || '',
            },
          ),
        );

        return;
      }
      dispatch(
        Usecases.customer.sendPostCustomerDetail(token, userInfo.tenantId, {
          ...newCustomer,
          customerTag: newTag || '',
        }),
      );
    },
    [customerId, dispatch, newCustomer, token, userInfo],
  );

  // 編集時、顧客情報取得
  useEffect(() => {
    if (customerId && userInfo) {
      dispatch(
        Usecases.customer.sendGetCustomerDetail(
          token,
          userInfo.tenantId,
          customerId,
        ),
      );
    }

    return () => {
      dispatch(clearCustomerDetail());
    };
  }, [customerId, userInfo, dispatch, token]);

  // 顧客タグのリクエストパラムセット
  useEffect(() => {
    dispatch(
      setCustomerTagsRequest({
        tagType: 'edit',
        sortKey: 'tag',
      }),
    );

    return () => {
      dispatch(clearCustomerTagsRequest());
    };
  }, [dispatch]);

  // 編集時、リクエスト用の顧客入力値に現在の顧客情報を設定
  useEffect(() => {
    if (customerDetail) {
      dispatch(
        setNewCustomer({
          customerId: customerDetail.customerId,
          companyName: customerDetail.companyName || '',
          deptName: customerDetail.deptName || '',
          jobTitle: customerDetail.jobTitle || '',
          customerName: customerDetail.customerName,
          mailAddress: customerDetail.mailAddress,
          customerTag: customerDetail.customerTag || '',
        }),
      );
    }

    return () => {
      dispatch(clearNewCustomer());
    };
  }, [dispatch, customerDetail]);

  // 顧客タグを取得
  useEffect(() => {
    if (
      !userInfo ||
      !customerTagsRequest.sortKey ||
      !customerTagsRequest.tagType
    )
      return;

    dispatch(
      Usecases.customer.sendGetCustomerTags(
        token,
        userInfo.tenantId,
        customerTagsRequest,
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearCustomerTags());
    };
  }, [userInfo, dispatch, token, customerTagsRequest]);

  // 顧客のメールアドレスを取得
  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      Usecases.customer.sendGetCustomerMailAddress(
        token,
        userInfo.tenantId,
        customerId,
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearCustomerMailAddress());
    };
  }, [userInfo, dispatch, token, customerId]);

  return (
    <CustomerRegister
      customerDetail={customerDetail}
      newCustomer={newCustomer}
      customerTags={customerTags}
      customerMailAddress={customerMailAddress}
      handleCustomerRegister={handleCustomerRegister}
      error={error}
    />
  );
};

export default CustomerRegisterPageContainer;

import React from 'react';

import {
  makeStyles,
  createStyles,
  Theme,
  // useTheme,
} from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  Box,
  Dialog,
  // DialogActions,
  SecondaryButton,
  // PrimaryButton,
  Typography,
  // DialogTitle,
  // Card,
  // Grid,
  // CommonComboBox,
  // SearchTextField,
  // Checkbox,
  // FormControlLabel,
  // OneLineTextField,
  // IconButton,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  // TableSortLabel,
  // ButtonLink,
  // WorkflowStatusLabel,
  // OpenStatusLabel,
} from 'components/atoms';
// import { SortDownIcon } from 'assets/images';

// import { ConfirmDialog, DateTimePicker } from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import { CloseIcon } from 'assets/images';
import { useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
} from 'constants/typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import { checkPastDay, validDate } from 'libs/date';
// import { MailSender } from 'core/domain/lp';
import _ from 'lodash';

export interface LpProductDetailDialogProps {
  mode: 'interested-count' | 'document-download-count' | undefined;

  // /{tenant_id}/lps/{lp_id}/interested-count ランディングページ商材別興味アリ件数取得API
  // /{tenant_id}/lps/{lp_id}/document-download-count ランディングページ商材別商材資料ダウンロード件数取得API

  open: boolean;
  handleCancel: () => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: 1206,
      padding: '24px',
      margin: 0,
      position: 'relative',
    },
    card: {
      backgroundColor: theme.palette.gray[400],
      height: 'calc(100vh - 266px)',
    },
    cardContent: {
      margin: theme.spacing(3),
    },
    closeButton: {
      position: 'fixed',
      left: '96%',
      top: '0%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    confirmBtn: {
      height: '48px',
      width: '332px',
      margin: '40px auto 20px auto',
    },
    searchComboBox: {
      maxWidth: '45%',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
        padding: 6,
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        // transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    warning: {
      margin: '5px',
      border: '2px solid red',
    },
    warningContent: {
      width: '75%',
      margin: '5px auto',
    },
    gridItem: {
      height: '100%',
      margin: 'auto',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    confirmTitle: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    listBox: {
      height: 'calc(100vh - 540px)',
    },
    listLabel: {
      width: '33%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:nth-child(1)': {
        paddingLeft: '12px',
      },
      '&:nth-child(2)': {
        paddingRight: '10px',
      },
      '& > label': {
        width: '100%',
        '& > span:nth-child(2)': {
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
      '& > p': {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
    },
    sendListLabel: {
      width: '33%',
      margin: '8px 0',
      '&:not(:last-child)': {
        paddingRight: '8px',
      },
      '& > p': {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
    dialogTitle: {
      fontWeight: 'bold',
    },
    tableBox: {
      paddingLeft: 5,
      paddingRight: 5,
    },

    root: {
      width: '100%',
      maxHeight: 720,
      // overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 600,
      // width: 1366,

      // maxHeight: 720,
      // tableLayout: 'fixed',
    },

    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        maxWidth: 300,

        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(1)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerCell: {
      marginLeft: 20,
      marginRight: 20,
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        maxWidth: 450,

        padding: `0 ${theme.spacing(1)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    status: {
      width: 96,
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 550,
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const INTERESTED_COUNT_TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '商材名称',
    sortKey: '',
  },
  {
    title: '興味アリ獲得数',
    sortKey: '',
  },
];

const DOCUMENT_DOWNLOAD_COUNT_TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '商材名称',
    sortKey: '',
  },
  {
    title: '資料ダウンロード数',
    sortKey: '',
  },
];

const LpProductDetailDialog = (props: LpProductDetailDialogProps) => {
  const { open, handleCancel, mode } = props;
  const headerItems =
    mode === 'interested-count'
      ? INTERESTED_COUNT_TABLE_HEADER_ITEMS
      : DOCUMENT_DOWNLOAD_COUNT_TABLE_HEADER_ITEMS;

  const lpProductDetailCounts = useSelector(
    (state: Modules.AppState) => state.lpProductDetailCounts,
    shallowEqual,
  );

  // const lpProductDetails = {
  //   products: [
  //     {
  //       productId: 1,
  //       productName: 'No.1',
  //       count: 10,
  //     },
  //     {
  //       productId: 2,
  //       productName: 'No.2',
  //       count: 20,
  //     },
  //   ],
  // };

  const classes = useStyles({});
  // const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      // disableBackdropClick
      open={open}
      // onClose={checkChangeValues}
    >
      <Box className={classes.dialogTitle}>
        <Typography variant="h3">{`${headerItems[1].title} 内訳`}</Typography>
      </Box>

      <Box mt={2} className={classes.tableBox}>
        <TableContainer className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.header}>
              <TableRow>
                {/* {headerItems.map((item, index) => (
                  <TableCell key={index}>
                    <Box className={classes.headerCell}>{item.title}</Box>
                  </TableCell>
                ))} */}
                <TableCell>
                  <Box className={classes.headerCell}>
                    {headerItems[0].title}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box className={classes.headerCell}>
                    {headerItems[1].title}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.body}>
              {lpProductDetailCounts?.products &&
                lpProductDetailCounts?.products.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className={clsx(classes.midCell, classes.overflow)}
                    >
                      <Box paddingLeft={4}>
                        <Tooltip title={product.productName || ''}>
                          <Typography variant="body2">
                            {product.productName}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    {/* <TableCell>{product.productName}</TableCell> */}
                    <TableCell align="right">
                      <Box paddingRight={2}>
                        {product.count.toLocaleString()}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" justifyContent="Center" width="100%">
        <Box width={144} height={36} mt={4}>
          <SecondaryButton
            click={handleCancel}
            icon={<CloseIcon className={classes.closeIcon} />}
            text="閉じる"
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default LpProductDetailDialog;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, SecondaryButton, Typography } from 'components/atoms'
import { TYPOGRAPHY } from 'constants/index'
import { CloseIcon } from 'assets/images'

/**
 * Interface
 */

export interface NoticeDialogProps {
  open: boolean
  title: string
  handleClose: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 680,
      padding: '32px 24px 0 24px',
      margin: 0,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    actions: {
      padding: 0
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      }
    },
  }),
)

export default function NoticeDialog(props: NoticeDialogProps) {
  const classes = useStyles({})
  const { title, open, handleClose } = props

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{style:{borderRadius: 16 }}}
      classes={{ paper: classes.dialog}}
      disableBackdropClick
      open={open}
      onClose={() => handleClose()}>
      <Box>
        <Typography variant="body2" className={classes.title} >{title}</Typography>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4} mb={3}>
            <Box width={102} height={36}>
              <SecondaryButton icon={<CloseIcon className={classes.closeIcon} />} click={() => handleClose()} text="閉じる" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  LetterCountLabel,
  MultipleLinesTextField,
  SubHeadlineLabel,
  TertiaryButton,
  Typography,
} from 'components/atoms';
import { PreviewButton } from 'components/molecules';
import { CheckIcon, ErrorOutlineIcon } from 'assets/images';
import { LPRegisterRequest } from 'core/domain/lp';
import { Modules } from 'core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NormalEditErrors, ProductsError } from 'core/domain/validationErrors';
import {
  clearNormalEditErrors,
  setNormalEditErrors,
} from 'core/modules/normalEditErrors';
import { checkPastDay, validDate } from 'libs/date';
import { NoticeDialog } from 'components/organisms';
import {
  DIALOG_NO_APPROVAL_USER,
  NORMAL_EDIT_BEGINNING_TEXT_NOT_ENTERED,
  NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR,
  NORMAL_EDIT_GREET_TEXT_NOT_ENTERED,
  NORMAL_EDIT_GREET_TEXT_OVER_CHAR,
  NORMAL_EDIT_HEADER_NOT_ENTERED,
  NORMAL_EDIT_HEADER_OVER_CHAR,
  NORMAL_EDIT_LP_TAG_OVER_CHAR,
  NORMAL_EDIT_MAIN_IMAGE_NOT_SELECTED,
  NORMAL_EDIT_PAGE_TITLE_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED,
  NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
  NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED,
  NORMAL_EDIT_TANTO_COMPANY_NOT_SELECTED,
  NORMAL_EDIT_TANTO_NAME_NOT_ENTERED,
  NORMAL_EDIT_TANTO_NAME_OVER_CHAR,
  NORMAL_EDIT_PAGE_TITLE_NOT_ENTERED,
  NORMAL_EDIT_TITLE_NOT_ENTERED,
  NORMAL_EDIT_TITLE_OVER_CHAR,
  NORMAL_EDIT_TOKUISAKI_NAME_NOT_ENTERED,
  NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR,
  NORMAL_EDIT_TOKUISAKI_TITLE_NOT_SELECTED,
  INVALID_DATE,
  DATE_NOT_ENTERED,
  TIME_NOT_ENTERED,
  PAST_DATE_SELECTED,
  NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR,
  NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED,
  NORMAL_EDIT_MAIL_TEXT_OVER_CHAR,
  NORMAL_EDIT_PASSWORD_NOT_ENTERED,
  NORMAL_EDIT_PASSWORD_OVER_CHAR,
  NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED,
  NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR,
} from 'constants/text';
import { hooks } from 'libs';
import { removeIncorrectTagEntry } from 'libs/validation';

/**
 * Interface
 */
export interface NormalEditApplyRequestProps {
  newLp: LPRegisterRequest | null;
  normalEditErrors: NormalEditErrors | null;
  handleSubmitApply: (workflow: string) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(4),
    },
    preview: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
      fontSize: '12px',
      marginRight: theme.spacing(1),
    },
    error: {
      borderWidth: 2,
      borderColor: theme.palette.pink[900],
      borderStyle: 'solid',
      color: theme.palette.pink[900],
      backgroundColor: theme.palette.common.white,
    },
    errorTitle: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
    },
  }),
);

/**
 * Presenter
 */

export default function NormalEditApplyRequest(
  props: NormalEditApplyRequestProps,
) {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { newLp, normalEditErrors, handleSubmitApply } = props;
  const { hasLpApprovalUser } = hooks.useApproval();
  const [workflow, setWorkflow] = useState('');
  const [workflowError, setWorkflowError] = useState('');
  const [openNoApprovalUserDialog, setOpenNoApprovalUserDialog] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const lpView = useSelector((state: Modules.AppState) => state.lpView);
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );

  const { lpId } = useParams<{ lpId: string }>();

  useEffect(() => {
    checkErrors();
  }, [newLp]);

  const checkTagsValidation = (tag: string): boolean => {
    const newTag = removeIncorrectTagEntry(tag);
    const newTags = newTag.split(',');
    if (newTag.length > 5000 || newTags.some((e) => e.length > 100))
      return true;

    return false;
  };

  const checkErrors = () => {
    const errors: NormalEditErrors = {
      ...normalEditErrors,
    };

    if (newLp?.header && newLp.header.length <= 100) {
      errors.header = undefined;
    } else if (!newLp || !newLp?.header) {
      errors.header = NORMAL_EDIT_HEADER_NOT_ENTERED;
    } else if (newLp.header.length > 100) {
      errors.header = NORMAL_EDIT_HEADER_OVER_CHAR;
    }

    if (
      !newLp?.lpMainImageData?.lpMainImageId &&
      !newLp?.lpMainImageData?.url
    ) {
      errors.lpMainImageData = NORMAL_EDIT_MAIN_IMAGE_NOT_SELECTED;
    } else {
      errors.lpMainImageData = undefined;
    }

    if (newLp?.tokuisakiDisplayed && !newLp?.tokuisakiTitle) {
      errors.tokuisakiTitle = NORMAL_EDIT_TOKUISAKI_TITLE_NOT_SELECTED;
    } else {
      errors.tokuisakiTitle = undefined;
    }

    if (newLp?.beginningText && newLp.beginningText.length <= 100) {
      errors.beginningText = undefined;
    } else if (!newLp || !newLp?.beginningText) {
      errors.beginningText = NORMAL_EDIT_BEGINNING_TEXT_NOT_ENTERED;
    } else if (newLp.beginningText.length > 100) {
      errors.beginningText = NORMAL_EDIT_BEGINNING_TEXT_OVER_CHAR;
    }

    if (newLp?.greetText && newLp.greetText.length <= 1000) {
      errors.greetText = undefined;
    } else if (!newLp || !newLp.greetText?.length) {
      errors.greetText = NORMAL_EDIT_GREET_TEXT_NOT_ENTERED;
    } else if (newLp.greetText.length > 1000) {
      errors.greetText = NORMAL_EDIT_GREET_TEXT_OVER_CHAR;
    }

    if (
      !newLp?.profileDisplayed ||
      (newLp?.profileDisplayed && newLp.tantoCompany)
    ) {
      errors.tantoCompany = undefined;
    } else if (newLp?.profileDisplayed && !newLp.tantoCompany) {
      errors.tantoCompany = NORMAL_EDIT_TANTO_COMPANY_NOT_SELECTED;
    }

    if (newLp?.pageTitle && newLp.pageTitle.length <= 100) {
      errors.pageTitle = undefined;
    } else if (!newLp || !newLp.pageTitle) {
      errors.pageTitle = NORMAL_EDIT_PAGE_TITLE_NOT_ENTERED;
    } else if (newLp.pageTitle.length > 100) {
      errors.pageTitle = NORMAL_EDIT_PAGE_TITLE_OVER_CHAR;
    }

    if (newLp?.tokuisakiName && newLp.tokuisakiName.length <= 100) {
      errors.tokuisakiName = undefined;
    } else if (!newLp || !newLp.tokuisakiName) {
      errors.tokuisakiName = NORMAL_EDIT_TOKUISAKI_NAME_NOT_ENTERED;
    } else if (newLp.tokuisakiName.length > 100) {
      errors.tokuisakiName = NORMAL_EDIT_TOKUISAKI_NAME_OVER_CHAR;
    }

    if (newLp?.tantoName && newLp.tantoName.length <= 100) {
      errors.tantoName = undefined;
    } else if (!newLp || !newLp.tantoName) {
      errors.tantoName = NORMAL_EDIT_TANTO_NAME_NOT_ENTERED;
    } else if (newLp.tantoName?.length > 100) {
      errors.tantoName = NORMAL_EDIT_TANTO_NAME_OVER_CHAR;
    }

    if (newLp?.title && newLp.title.length <= 100) {
      errors.title = undefined;
    } else if (!newLp || !newLp.title) {
      errors.title = NORMAL_EDIT_TITLE_NOT_ENTERED;
    } else if (newLp.title.length > 100) {
      errors.title = NORMAL_EDIT_TITLE_OVER_CHAR;
    }

    if (newLp?.lpTag && checkTagsValidation(newLp.lpTag)) {
      errors.lpTag = NORMAL_EDIT_LP_TAG_OVER_CHAR;
    } else {
      errors.lpTag = undefined;
    }

    if (newLp?.products) {
      const productsError: Array<ProductsError> = [];
      newLp.products.map((product) => {
        if (
          (!product.comment || product.comment == '') &&
          (product.productSalesImage?.lpProductImageData ||
            product.productSalesImage?.lpProductImageId ||
            product.productSalesImage?.url)
        ) {
          const productError = {
            productId: product.productId,
            comment: NORMAL_EDIT_PRODUCT_COMMENT_NOT_ENTERED,
          };
          productsError.push(productError);
        } else if (
          product.comment &&
          product.comment !== '' &&
          !product.productSalesImage?.lpProductImageData &&
          !product.productSalesImage?.lpProductImageId &&
          !product.productSalesImage?.url
        ) {
          const productError = {
            productId: product.productId,
            productImage: NORMAL_EDIT_PRODUCT_IMAGE_NOT_SELECTED,
          };
          productsError.push(productError);
        } else if (product.comment && product.comment.length > 5000) {
          const productError = {
            productId: product.productId,
            comment: NORMAL_EDIT_PRODUCT_COMMENT_OVER_CHAR,
          };
          productsError.push(productError);
        }
      });
      if (productsError.length > 0) {
        errors.products = productsError;
      } else {
        errors.products = undefined;
      }
    }

    if (newLp?.openPeriod) {
      if (
        newLp.openPeriod.openPeriodDate &&
        !validDate(newLp.openPeriod.openPeriodDate)
      ) {
        errors.openPeriodDate = INVALID_DATE;
      } else if (
        newLp?.openPeriod &&
        !newLp.openPeriod.openPeriodDate &&
        newLp.openPeriod.openPeriodTime
      ) {
        errors.openPeriodDate = DATE_NOT_ENTERED;
      } else if (
        newLp?.openPeriod &&
        newLp.openPeriod.openPeriodDate &&
        !newLp.openPeriod.openPeriodTime
      ) {
        errors.openPeriodTime = TIME_NOT_ENTERED;
      } else if (
        checkPastDay(
          newLp.openPeriod.openPeriodDate,
          newLp.openPeriod.openPeriodTime,
        )
      ) {
        errors.openPeriodDate = PAST_DATE_SELECTED;
      } else {
        errors.openPeriodDate = undefined;
        errors.openPeriodTime = undefined;
      }
    }

    // 公開設定 メールタイトル
    if (newLp?.mailSubject && newLp.mailSubject.length <= 500) {
      errors.mailSubject = undefined;
    } else if (!newLp || !newLp.mailSubject) {
      errors.mailSubject = NORMAL_EDIT_MAIL_SUBJECT_NOT_ENTERED;
    } else if (newLp.mailSubject.length > 500) {
      errors.mailSubject = NORMAL_EDIT_MAIL_SUBJECT_OVER_CHAR;
    }

    // 公開設定 メール本文
    if (newLp?.mailText && newLp.mailText.length <= 5000) {
      errors.mailText = undefined;
    } else if (!newLp || !newLp.mailText) {
      errors.mailText = NORMAL_EDIT_MAIL_TEXT_NOT_ENTERED;
    } else if (newLp.mailText.length > 5000) {
      errors.mailText = NORMAL_EDIT_MAIL_TEXT_OVER_CHAR;
    }

    // 公開設定 メール署名
    if (newLp?.mailSignature && newLp.mailSignature.length <= 5000) {
      errors.mailSignature = undefined;
    } else if (newLp?.mailSignature && newLp.mailSignature.length > 5000) {
      errors.mailSignature = NORMAL_EDIT_MAIL_SIGNATURE_OVER_CHAR;
    }

    // 公開設定 パスワード
    if (
      newLp?.passwordSetting === 1 ||
      (newLp?.passwordSetting &&
        newLp?.password &&
        newLp.password.length <= 100 &&
        newLp.password.length >= 8 &&
        /^[0-9a-zA-Z]+$/.test(newLp?.password))
    ) {
      errors.password = undefined;
      // 8文字未満
    } else if (
      newLp?.password &&
      newLp?.passwordSetting !== 1 &&
      (newLp.password.length < 8 || !/^[0-9a-zA-Z]+$/.test(newLp?.password))
    ) {
      errors.password = NORMAL_EDIT_PASSWORD_NOT_EIGHT_ENTERED;
      // 未入力
    } else if (!newLp || (!newLp.password && newLp.passwordSetting !== 1)) {
      errors.password = NORMAL_EDIT_PASSWORD_NOT_ENTERED;
    }
    if (
      errors.beginningText ||
      errors.greetText ||
      errors.header ||
      errors.lpMainImageData ||
      errors.lpTag ||
      errors.pageTitle ||
      errors.products ||
      errors.tantoCompany ||
      errors.tantoName ||
      errors.tokuisakiTitle ||
      errors.title ||
      errors.tokuisakiName ||
      errors.material ||
      errors.openPeriodDate ||
      errors.openPeriodTime ||
      errors.mailSubject ||
      errors.mailText ||
      errors.mailSignature ||
      errors.password
    ) {
      dispatch(setNormalEditErrors(errors));
    } else {
      dispatch(clearNormalEditErrors());
    }
  };

  const handleTextChange = (text: string) => {
    setWorkflow(text);
  };

  return (
    <Box width={696} height={570} mx="auto" mt={8}>
      <NoticeDialog
        open={openNoApprovalUserDialog}
        handleClose={() => setOpenNoApprovalUserDialog(false)}
        title={DIALOG_NO_APPROVAL_USER}
      />
      <Box textAlign="center">
        <Typography className={classes.title} variant="h3">
          承認申請
        </Typography>
        <Typography variant="body2">
          作成したランディングページの公開承認を申請します。
          <br />
          プレビューでお客さまにご提示する内容として間違いがないか確認の上、申請してください。
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mx="auto"
        width={144}
        mt={4}
        mb={4}
      >
        <Typography className={classes.preview} variant="body2">
          プレビュー
        </Typography>
        <PreviewButton
          previewData={lpView || undefined}
          templateId={newLp?.designTemplateId}
        />
      </Box>
      {(lpDetail || !lpId) && normalEditErrors && (
        <Box className={classes.error} p={2}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <ErrorOutlineIcon />
            <Typography variant="body2" className={classes.errorTitle}>
              エラー
            </Typography>
          </Box>
          <Typography variant="body2">
            エラーアイコンがある編集項目を修正の上、
            <br />
            再度「保存して承認申請へ進む」ボタンを押してください
          </Typography>
        </Box>
      )}
      <SubHeadlineLabel text="申請申し送り（任意）" />
      <MultipleLinesTextField
        error={workflowError}
        value={workflow}
        handleChangeText={(text) => handleTextChange(text)}
        rows={4}
      />
      <LetterCountLabel
        onlyInputCount
        count={workflow ? `${workflow.length}` : '0'}
      />
      <Box width={332} height={48} mx="auto" mt={4}>
        <TertiaryButton
          disabled={!!normalEditErrors || isSubmitting}
          click={() => {
            setIsSubmitting((prev) => !prev);
            if (workflow && workflow.length > 1000) {
              setWorkflowError(
                '申請申し送りは1000文字以内で入力してください。',
              );
            } else if (!hasLpApprovalUser()) {
              setOpenNoApprovalUserDialog(true);
            } else {
              handleSubmitApply(workflow);
            }
            setTimeout(() => {
              setIsSubmitting((prev) => !prev);
            }, 1000);
          }}
          text="承認を申請する"
          icon={<CheckIcon />}
        />
      </Box>
    </Box>
  );
} /* eslint-enable */

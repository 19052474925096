import {
  CustomerDetail,
  CustomerMailAddress,
  CustomerRegisterRequest,
  CustomerTags,
  Customers,
  CustomersQueryParameter,
  DeleteCustomers,
} from 'core/domain/customer';
import { TagsRequest } from 'core/domain/tag';
import { setCustomerDetail } from 'core/modules/customerDetail';
import { setCustomerMailAddress } from 'core/modules/customerMailAddress';
import { setCustomers } from 'core/modules/customers';
import { setCustomerTags } from 'core/modules/customerTags';
import * as Api from 'libs/api';
import { Dispatch } from 'redux';

const didCallGetCustomerDetailApi = (
  dispatch: Dispatch,
  data: CustomerDetail,
) => {
  dispatch(setCustomerDetail(data));
};

export const sendGetCustomerDetail =
  (token: string | undefined, tenantId: string, customerId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/customers/${customerId}`,
      token,
      null,
      dispatch,
      didCallGetCustomerDetailApi,
    );
  };

const didCallGetCustomerTags = (dispatch: Dispatch, data: CustomerTags) => {
  dispatch(setCustomerTags(data));
};

export const sendGetCustomerTags =
  (token: string | undefined, tenantId: string, tags: TagsRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/customers/tags?tag_type=${tags.tagType || ''}&sort_key=${
        tags.sortKey || ''
      }`,
      token,
      null,
      dispatch,
      didCallGetCustomerTags,
    );
  };

const didCallGetCustomerMailAddress = (
  dispatch: Dispatch,
  data: CustomerMailAddress,
) => {
  dispatch(setCustomerMailAddress(data));
};

export const sendGetCustomerMailAddress =
  (token: string | undefined, tenantId: string, customerId?: string) =>
  async (dispatch: Dispatch) => {
    const query = customerId ? `?customer_id=${customerId}` : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/customers/mail-address${query}`,
      token,
      null,
      dispatch,
      didCallGetCustomerMailAddress,
    );
  };

const didCallPostCustomerDetail = (
  dispatch: Dispatch,
  data: CustomerDetail,
) => {
  dispatch(setCustomerDetail(data));
};

export const sendPostCustomerDetail =
  (
    token: string | undefined,
    tenantId: string,
    customer: CustomerRegisterRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/customers`,
      token,
      JSON.stringify(customer),
      dispatch,
      didCallPostCustomerDetail,
    );
  };

const didCallPutCustomerDetail = (dispatch: Dispatch, data: CustomerDetail) => {
  dispatch(setCustomerDetail(data));
};

export const sendPutCustomerDetail =
  (
    token: string | undefined,
    tenantId: string,
    customerId: string,
    customer: CustomerRegisterRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/customers/${customerId}`,
      token,
      JSON.stringify(customer),
      dispatch,
      didCallPutCustomerDetail,
    );
  };

// 顧客一覧取得

const didCallGetCustomersApi = (dispatch: Dispatch, data: Customers) => {
  dispatch(setCustomers(data));
};

export const sendGetCustomers =
  (
    token: string | undefined,
    tenantId: string,
    param: CustomersQueryParameter | null,
  ) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/customers
    const api = Api.createApi(param);
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/customers?${api}`,
      token,
      null,
      dispatch,
      didCallGetCustomersApi,
    );
  };

// 顧客削除
const didCallDeleteCustomers = (dispatch: Dispatch, data: Customers) => {
  dispatch(setCustomers(data));
};

export const sendDeleteCustomers =
  (token: string | undefined, tenantId: string, customerIds: DeleteCustomers) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/customers`,
      token,
      JSON.stringify(customerIds),
      dispatch,
      didCallDeleteCustomers,
    );
  };

// 一括登録

const didPostCustomersUploadStatus = (dispatch: Dispatch, data: Customers) => {
  dispatch(setCustomers(data));
};
export const sendPutNewCustomersCsv =
  (token: string | undefined, tenantId: string, data: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/customers/upload`,
      token,
      JSON.stringify({ data }),
      dispatch,
      didPostCustomersUploadStatus,
    );
  };

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  OpenStatusLabel,
  WorkflowStatusLabel,
} from 'components/atoms';
import FavoriteGrayIcon from 'assets/images/icn_favorite_gray.svg';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { LpAdmin, LpsAdmin, LpSearchCondition } from 'core/domain/lp';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';
import { formatDateISO8601 } from 'libs/date';

/**
 * Interface
 */
export interface LpsTableProps {
  lps: LpsAdmin | null;
  clickLp?: (id: number, approved: boolean) => void;
  handleChangeSortKey: (sortKey: string) => void;
  lpSearchCondition: LpSearchCondition | null;
  handleChecked: (id: number, val: boolean, status: number) => void;
  allCheck: (val: boolean) => void;
  checkedLps: Array<LpAdmin>;
  headerChecked: boolean;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1566,
      tableLayout: 'fixed',
    },
    status: {
      width: 96,
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
  width: string;
  minWidth?: number;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '',
    sortKey: '',
    width: '3%',
  },
  {
    title: '公開\nステータス',
    sortKey: 'open',
    width: '8%',
  },
  {
    title: '承認\nステータス',
    sortKey: 'workflow',
    width: '8%',
  },
  {
    title: 'ランディングページ名',
    sortKey: 'title',
    width: '12%',
    minWidth: 167,
  },
  {
    title: '得意先名',
    sortKey: 'tokuisakiName',
    width: '12%',
  },
  {
    title: '担当',
    sortKey: 'lpTantoUser',
    width: '8%',
  },
  {
    title: 'オリジナル\n担当者',
    sortKey: 'createdUser',
    width: '10%',
  },
  {
    title: 'ページ\n閲覧数',
    sortKey: 'accessCount',
    width: '7%',
    minWidth: 88,
  },
  {
    title: '興味アリ\n獲得数',
    sortKey: 'interestedCount',
    width: '7%',
    minWidth: 93,
  },
  {
    title: '資料\nダウンロード数',
    sortKey: 'downloadCount',
    width: '10%',
    minWidth: 148,
  },
  {
    title: 'お問い合わせ数',
    sortKey: 'inquiryCount',
    width: '9%',
    minWidth: 148,
  },
  {
    title: '公開日',
    sortKey: 'openDate',
    width: '6%',
  },
];

const LPsAdminTable = (props: LpsTableProps) => {
  const classes = useStyles({});
  const {
    lps,
    clickLp,
    lpSearchCondition,
    handleChecked,
    handleChangeSortKey,
    allCheck,
    checkedLps,
    headerChecked,
  } = props;

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <colgroup>
          {TABLE_HEADER_ITEMS.map((item: TableHeaderItems, index) => (
            <col
              key={index}
              width={item.width}
              style={item.minWidth ? { minWidth: item.minWidth } : undefined}
            />
          ))}
        </colgroup>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                {index === 0 ? (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={headerChecked}
                        onChange={(e) => allCheck(e.target.checked)}
                      />
                    }
                    label=""
                    key={index}
                  />
                ) : (
                  <TableSortLabel
                    active
                    direction={lpSearchCondition?.sort_order}
                    IconComponent={() =>
                      item.sortKey === lpSearchCondition?.sort_key &&
                      lpSearchCondition?.sort_order === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleChangeSortKey(item.sortKey)}
                  >
                    {item.title !== 'お気に入り' ? (
                      item.title
                    ) : (
                      <img
                        className={classes.headerFavorite}
                        src={FavoriteGrayIcon}
                      />
                    )}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {lps &&
            lps.lps.map((lp, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={Boolean(
                          checkedLps.filter(
                            (item) =>
                              item.lpId === lp.lpId &&
                              item.openStatus === lp.openStatus,
                          ).length,
                        )}
                        onChange={(e) =>
                          handleChecked(
                            lp.lpId,
                            e.target.checked,
                            lp.openStatus,
                          )
                        }
                      />
                    }
                    label=""
                    key={index}
                  />
                </TableCell>
                <TableCell>
                  <Box className={classes.status}>
                    <OpenStatusLabel status={lp.openStatus} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className={classes.status}>
                    <WorkflowStatusLabel status={lp.workflowStatus} />
                  </Box>
                </TableCell>
                <TableCell className={classes.overflow}>
                  <ButtonLink
                    text={lp.title}
                    tooltipText={lp.title}
                    click={() => clickLp && clickLp(lp.lpId, lp.approved)}
                  />
                </TableCell>
                <TableCell className={classes.overflow}>
                  <Tooltip
                    title={lp.tokuisakiName ? lp.tokuisakiName : ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">{lp.tokuisakiName}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.overflow}>
                  <Tooltip
                    title={lp.lpTantoUserName ? lp.lpTantoUserName : ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {lp.lpTantoUserName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.overflow}>
                  <Tooltip
                    title={
                      lp.lpCreatedUser?.userName
                        ? lp.lpCreatedUser.userName
                        : ''
                    }
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {lp.lpCreatedUser?.userName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={lp.lpReaction
                      ? Number(lp.lpReaction.accessCount).toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {Number(lp.lpReaction?.accessCount).toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={lp.lpReaction
                      ? Number(lp.lpReaction.interestedCount).toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {Number(lp.lpReaction?.interestedCount).toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={lp.lpReaction
                      ? Number(lp.lpReaction.downloadCount).toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {Number(lp.lpReaction?.downloadCount).toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={lp.lpReaction
                      ? Number(lp.lpReaction.inquiryCount).toLocaleString()
                      : '-'}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {Number(lp.lpReaction?.inquiryCount).toLocaleString()}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {formatDateISO8601(
                      lp.openDate ? lp.openDate.split('.')[0] : '',
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LPsAdminTable;

import { NoticesQueryParameter } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticesQueryParameter | null =>
  initialState;

export const SET_NOTICES_QUERY_PARAMETER = 'noticesQueryParameter/set' as const;
export const CLEAR_NOTICES_QUERY_PARAMETER =
  'noticesQueryParameter/clear' as const;

export const setNoticesQueryParameter = (data: NoticesQueryParameter) => ({
  type: SET_NOTICES_QUERY_PARAMETER,
  payload: {
    data,
  },
});

export const clearNoticesQueryParameter = () => ({
  type: CLEAR_NOTICES_QUERY_PARAMETER,
});

type SetNoticesQueryParameterAction = ReturnType<
  typeof setNoticesQueryParameter
>;
type ClearNoticesQueryParameterAction = ReturnType<
  typeof clearNoticesQueryParameter
>;
type Action = SetNoticesQueryParameterAction | ClearNoticesQueryParameterAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NOTICES_QUERY_PARAMETER:
      return action.payload.data;
    case CLEAR_NOTICES_QUERY_PARAMETER:
      return initialState;
    default:
      return state;
  }
};

import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Box,
  Typography,
  SearchTextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  AdditionalTag,
  FormGroup,
  Checkbox,
  SearchProductTag,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { ProductsQueryParameter } from 'core/domain/product';
import {
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
} from 'constants/code';
import { useProductsPermission } from 'libs/hooks';
import { TargetPeriodLabel } from 'components/molecules';
import { ProductSearchValidationError } from 'containers/ProductsPageContainer';

/**
 * Interface
 */
export interface ProductSearchFieldProps {
  searchCondition: ProductsQueryParameter | null;
  handleCheckFavorite?: (status: string) => void;
  handleCheckStatus?: (status: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  addTags?: () => void;
  searchValidateError?: ProductSearchValidationError;
  handleChangeAddTerm: (date: string, isFrom: boolean) => void;
  deleteTag:(val:string) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&>div:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.gray[800],
    },
    radios: {
      '& .MuiFormControlLabel-label': {
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      },
    },
    radio: {
      color: theme.palette.gray[800],
    },
    status: {
      marginLeft: `${theme.spacing(1) / 2}px`,
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
  }),
);

interface StatusFilter {
  label: string;
  status: number;
}

const STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: '下書き',
    status: CODE_MATERIAL_STATUS_DRAFT,
  },
  {
    label: '差し戻し',
    status: CODE_MATERIAL_STATUS_DECLINE,
  },
  {
    label: '申請中',
    status: CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  },

  {
    label: '公開中',
    status: CODE_MATERIAL_STATUS_RELEASED,
  },
  {
    label: '公開一時停止',
    status: CODE_MATERIAL_STATUS_SUSPENSION,
  },
  {
    label: '公開終了',
    status: CODE_MATERIAL_STATUS_END,
  },
  {
    label: '利用期間外',
    status: CODE_MATERIAL_STATUS_EXPIRE,
  },
];

const ProductSearchField = (props: ProductSearchFieldProps) => {
  const classes = useStyles({});
  const {
    searchCondition,
    handleCheckStatus,
    handleCheckFavorite,
    handleChangeFreeWord,
    addTags,
    searchValidateError,
    handleChangeAddTerm,
    deleteTag,
  } = props;
  const { isMaterialAuthorOrApprover } = useProductsPermission();

  const tags = useMemo(() => {
    if (!searchCondition?.tags) return [];

    return searchCondition.tags.split(',');
  }, [searchCondition?.tags]);

  const handleChangeAddTermFrom = (date: string) => {
    handleChangeAddTerm(date.replaceAll('/', ''), true);
  };

  const handleChangeAddTermTo = (date: string) => {
    handleChangeAddTerm(date.replaceAll('/', ''), false);
  };

  const statusCheck = useMemo(() => {
    if (!searchCondition?.product_status) return [];

    return searchCondition.product_status.split(',');
  }, [searchCondition?.product_status]);

  return (
    <Box className={classes.root}>
      <Box className={classes.sectionTop}>
        <Box className={classes.section}>
          <Typography className={classes.title} variant="caption">
            フリーワード検索
          </Typography>
          <Box className={classes.freeWord}>
            <SearchTextField
              value={
                searchCondition?.free_word ? searchCondition?.free_word : ''
              }
              label="検索ワードを入力"
              handleChangeText={handleChangeFreeWord}
            />
          </Box>
          <Typography className={classes.description} variant="caption">
            登録されている情報全体からフリーワードで検索します。
          </Typography>
        </Box>
        <TargetPeriodLabel
          start={searchCondition?.add_term_from}
          end={searchCondition?.add_term_to}
          startError={searchValidateError?.addTermFrom}
          endError={searchValidateError?.addTermTo}
          handleChangeStartDate={(date: string) =>
            handleChangeAddTermFrom(date)
          }
          handleChangeEndDate={(date: string) => handleChangeAddTermTo(date)}
        />
      </Box>
      <Box className={classes.section}>
        <Typography
          className={clsx([classes.title, classes.tagTitle])}
          variant="overline"
        >
          タグ検索
        </Typography>
        <Box>
          {tags.map((tag, index) => (
            <SearchProductTag item={tag} key={index} 
            deleteTag={
              (val:string)=>{
                deleteTag(val)
              }
            }/>
          ))}
          <AdditionalTag click={addTags} />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="overline">
          お気に入り
        </Typography>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radios}
              aria-label="status"
              value={
                searchCondition && searchCondition.status
                  ? searchCondition.status
                  : 'all'
              }
              row
              onChange={(e, value) =>
                handleCheckFavorite && handleCheckFavorite(value)
              }
            >
              <FormControlLabel
                value="all"
                control={<Radio className={classes.radio} size="small" />}
                label="すべて"
              />
              <FormControlLabel
                value="favorite"
                control={<Radio className={classes.radio} size="small" />}
                label="お気に入り"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="overline">
          ステータス
        </Typography>
        <Box className={classes.status}>
          <FormGroup row>
            {STATUS_FILTERS.map((status, index) => {
              if (
                !isMaterialAuthorOrApprover &&
                status.status <= CODE_MATERIAL_STATUS_APPROVAL
              )
                return undefined;

              return (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={statusCheck?.includes(`${status.status}`)}
                      onClick={() =>
                        handleCheckStatus && handleCheckStatus(status.status)
                      }
                    />
                  }
                  label={status.label}
                  key={index}
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductSearchField;
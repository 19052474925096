/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles} from '@material-ui/core/styles'
import  HeaderUserSelect  from '../HeaderUserSelect'


/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    headerMenu: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
    },
  }),
)

/**
 * Presenter
 */

export default function HeaderBarUser() {
  const classes = useStyles({})

  return (
            <div className={classes.headerMenu}>
            <HeaderUserSelect />
            </div>
  )
}
/* eslint-enable */

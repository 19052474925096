/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import createAppReducer, { createInitialState } from 'core/modules'
import { composeWithDevTools } from 'redux-devtools-extension'

export const store = createStore(createAppReducer(), createInitialState(), composeWithDevTools(applyMiddleware(thunk)))
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CheckIcon,
  CloseWhiteIcon,
  CloseIcon,
  UploadBoxIcon,
  ErrorIcon,
} from 'assets/images';
import {
  ListItem,
  Dialog,
  Box,
  PrimaryButton,
  IconButton,
  SecondaryButton,
  DialogActions,
  HeadlineLabel,
  Typography,
  List,
  OneLineTextField,
  SubHeadlineLabel,
  Tooltip,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import {
  EXTENSION_GIF,
  EXTENSION_PNG,
  EXTENSION_JPEG,
  EXTENSION_JPG,
} from 'constants/mimeType';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import validator from 'validator';

/**
 * Interface
 */
export interface VideoEmbedDialogProps {
  open: boolean;
  url?: string;
  onClose: () => void;
  handleSubmit?: (files: File[], url: string) => void;
  isAddNewItem?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(3),
      width: 632,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    uploadBox: {
      width: 632,
      textAlign: 'center',
      background: theme.palette.common.white,
      borderColor: theme.palette.gray[700],
      borderStyle: 'dashed',
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.gray[400],
    },
    dragOver: {
      backgroundColor: theme.palette.green[700],
      borderColor: theme.palette.green[800],
      boxShadow: 'none',
    },
    text: {
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
      lineHeight: '15px',
    },
    subText: {
      color: theme.palette.gray[800],
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    helperText: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
    },
    fileCloseButton: {
      width: 20,
      height: 20,
      padding: 3,
    },
    listItem: {
      padding: 0,
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    fileNameArea: {
      overflow: 'hidden',
    },
    fileName: {
      width: 604,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

/**
 * Presenter
 */

export default function VideoEmbedDialog(props: VideoEmbedDialogProps) {
  const classes = useStyles({});
  const { open, url, onClose, handleSubmit, isAddNewItem } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [errorURLMessage, setErrorURLMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setErrorMessage('');
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: `${EXTENSION_GIF},${EXTENSION_PNG},${EXTENSION_JPEG},${EXTENSION_JPG}`,
    maxFiles: 1,
    maxSize: 1000 * 1000 * 10,
  });

  const [files, setFiles] = useState(acceptedFiles);
  const [videoUrl, setVideoUrl] = useState(url);
  const handleRemove = (name: string) => {
    const newFiles = files.filter((file) => file.name !== name);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (isAddNewItem) {
      setVideoUrl('');
    } else {
      setVideoUrl(url);
    }
  }, [url, open]);

  useEffect(() => {
    if (!open) {
      setErrorMessage('');
      fileRejections.length = 0;
    }
  }, [open]);

  useEffect(() => {
    setIsSubmitting(false);
  }, [files, videoUrl]);

  const formatErrorMessage = useMemo(() => {
    if (!fileRejections || !fileRejections.length || !fileRejections[0])
      return '';
    let msg = '';
    fileRejections.map((file) => {
      file.errors.map((error) => {
        if (error.code === 'file-too-large') {
          msg = 'サイズが10.0MB以内のファイルを選択してください。';
        } else if (error.code === 'file-invalid-type') {
          msg = `png、gif、jpeg形式のファイルを選択してください。`;
        }
      });
    });

    return msg;
  }, [fileRejections, open]);

  const getParameterByName = (name: string, url: string) => {
    const queryString = url.split('?');
    if (queryString.length >= 2) {
      const params = queryString[1].split('&');
      for (let i = 0; i < params.length; i++) {
        const eachParams = params[i].split('=');
        if (eachParams[0] == name) return eachParams[1];
      }
    }

    return null;
  };

  const onClick = () => {
    setIsSubmitting((prev) => !prev);
    let replaceUrl = null;
    if (!videoUrl) {
      setErrorMessage('動画のURLを入力してください。');

      return;
    }
    if (videoUrl && videoUrl.length > 1000) {
      setErrorURLMessage('動画のURLは1000文字以内で入力してください。');

      return;
    }
    if (videoUrl && !validator.isURL(videoUrl)) {
      setErrorURLMessage('URLの形式で入力してください。');

      return;
    }
    if (
      videoUrl &&
      (videoUrl.startsWith('https://youtu.be/') ||
        videoUrl.startsWith('https://www.youtube.com/watch'))
    ) {
      let youTubeId = null;

      if (videoUrl.match(/\?/) != null) {
        // URLからID(パラメーター名vの値)の部分を抽出
        youTubeId = getParameterByName('v', videoUrl)
          ? getParameterByName('v', videoUrl)
          : null;
      } else if (videoUrl.match(/(youtube\.com\/watch|youtu\.be)/) != null) {
        // URLにクエリが無く、動画のURLかショートコードの場合
        // URLからIDの部分を抽出
        const regex = new RegExp(
          /(?:https?):\/\/(?:www\.)?(?:youtube\.com\/watch|youtu\.be)\/(.+)/g,
        );
        const splitUrl = regex.exec(videoUrl);
        youTubeId = splitUrl && splitUrl.length > 1 ? splitUrl[1] : null;
      }

      if (youTubeId) {
        replaceUrl = `https://www.youtube.com/embed/${youTubeId}`;
        setVideoUrl(replaceUrl);
      }
    }
    handleSubmit && handleSubmit(files, replaceUrl || videoUrl || '');
    setFiles([]);
    setVideoUrl('');
    setErrorMessage('');
    setErrorURLMessage('');
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  const handleOnClose = () => {
    setFiles([]);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={handleOnClose}
    >
      <Box>
        <HeadlineLabel text="新規動画URL埋め込み" />
        <Box mt={3}>
          <OneLineTextField
            error={errorURLMessage}
            label="動画のURLを入力"
            value={videoUrl}
            handleChangeText={(text) => {
              setVideoUrl(text);
            }}
          />
        </Box>
        <SubHeadlineLabel text="ビデオサムネイルを追加" />
        <Box
          {...getRootProps({ className: classes.uploadBox })}
          className={clsx(classes.uploadBox, isDragActive && classes.dragOver)}
        >
          <input {...getInputProps()} />
          <Box m="39px" width="325px" mx="auto">
            <UploadBoxIcon />
            <Typography variant="body2" className={classes.text}>
              ファイルをここにドラッグ＆ドロップしてください
            </Typography>
            <Typography variant="body2" className={classes.subText}>
              または
            </Typography>
            <Box width={130} height={36} mx="auto">
              <SecondaryButton text="ファイルを選択" />
            </Box>
          </Box>
        </Box>
        <Typography className={clsx(classes.subText, classes.helperText)}>
          添付ファイルのサイズは10.0MB以内でお願いします。
        </Typography>
        {formatErrorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {formatErrorMessage}
            </Typography>
          </Box>
        )}
        {errorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {errorMessage}
            </Typography>
          </Box>
        )}
        <List>
          {files.map((file, index) => (
            <ListItem key={index} className={classes.listItem}>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                className={classes.fileNameArea}
              >
                <Tooltip title={file.name} arrow placement="top">
                  <Typography variant="body2" className={classes.fileName}>
                    {file.name}
                  </Typography>
                </Tooltip>
                <IconButton
                  className={classes.fileCloseButton}
                  onClick={() => handleRemove(file.name)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      <DialogActions>
        <Box width={332} height={48}>
          <SecondaryButton
            click={handleOnClose}
            text="キャンセル"
            icon={<CloseIcon className={classes.closeIcon} />}
          />
        </Box>
        <Box width={332} height={48} mx="auto">
          <PrimaryButton
            disabled={isSubmitting}
            icon={<CheckIcon />}
            text="決定"
            click={onClick}
          />
        </Box>
        <IconButton onClick={handleOnClose} className={classes.closeButton}>
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
} /* eslint-enable */

import { useEffect, useState, useCallback } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  ArrowLeftIcon,
  CheckIcon,
  EditIcon,
  ReturnIcon,
} from 'assets/images';
import {
  Box,
  Grid,
  PrimaryButton,
  SecondaryButton,
  ButtonLink,
  MessageAlert,
  TextTypeButton,
} from 'components/atoms';
import {
  Content,
  ProductDetailList,
  ApplyHistoryList,
  ExampleList,
  NoticeDialog,
  DeclineDialog,
  ApplyDialog,
  AppliedDialog,
  ConfirmDialog,
} from 'components/organisms';
import { hooks } from 'libs';
import { ProductDetail } from 'core/domain/product';
import { MaterialWorkflow } from 'core/domain/material';
import { ExampleDetail } from 'core/domain/example';
import {
  CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
  CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
} from 'constants/code';
import { LpView } from 'core/domain/lp';
import clsx from 'clsx';

/**
 * Interface
 */
export interface ProductDetailProps {
  productDetail: ProductDetail;
  productWorkflows: Array<MaterialWorkflow> | null;
  productExamples: Array<ExampleDetail> | null;
  handleRecommendedUpdate: () => void;
  handleWorkflowStatusUpdate: (
    step: number,
    status: number,
    message?: string,
  ) => void;
  handleClickFavorite: () => void;
  previewData?: LpView;
  handleDelete?: () => void;
  handleStop: () => void;
  handleReopen: () => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      backgroundColor: theme.palette.gray[400],
    },
    buttonContainer: {
      height: '72px',
      fontSize: '18px',
      marginTop: 80,
      marginBottom: 96,
    },
    button: {
      height: '48px',
      width: '332px',
      margin: '40px auto',
    },
    goBackLink: {
      marginBottom: theme.spacing(2),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    stopButton: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      backgroundColor: theme.palette.gray[500],
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(7),
    },
  }),
);

interface ProductModalVisible {
  noticeDialog: boolean;
  declineDialog: boolean;
  applyDialog: boolean;
  appliedDialog: boolean;
  moveToTashDialog: boolean;
}

/**
 * Presenter
 */
const ProductDetailPage = (props: ProductDetailProps) => {
  const classes = useStyles({});
  const navigate = hooks.useNavigate();
  const {
    alertMessage,
    declinePermission,
    approvalPermission,
    editPermission,
    suspensionPermission,
    reopenPermission,
    step,
    applyHistoryPermission,
  } = hooks.useProductDetailPermission();
  const {
    productDetail,
    productWorkflows,
    handleRecommendedUpdate,
    productExamples,
    handleWorkflowStatusUpdate,
    handleClickFavorite,
    previewData,
    handleDelete,
    handleStop,
    handleReopen,
  } = props;
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState<ProductModalVisible>({
    noticeDialog: false,
    declineDialog: false,
    applyDialog: false,
    appliedDialog: false,
    moveToTashDialog: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (currentStep && step < currentStep) {
      // 承認フローが巻き戻った場合（ = 差し戻しが実行された場合）は、
      // 差し戻し完了ダイアログを表示
      setModalVisible({
        ...modalVisible,
        noticeDialog: true,
      });
    } else if (currentStep && step > currentStep) {
      // 承認フローが進んだ場合（ = 承認が実行された場合）は、
      // 承認完了ダイアログを表示
      setModalVisible({
        ...modalVisible,
        appliedDialog: true,
      });
    }

    setCurrentStep(step);
  }, [currentStep, modalVisible, step]);

  const closeDialog = useCallback(() => {
    setModalVisible({
      noticeDialog: false,
      declineDialog: false,
      applyDialog: false,
      appliedDialog: false,
      moveToTashDialog: false,
    });
  }, []);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, { [classes.alertVisible]: alertMessage })}
    >
      {alertMessage && (
        <Box className={classes.messageAlert}>
          <MessageAlert
            text={alertMessage.text}
            severity={alertMessage.severity}
          />
        </Box>
      )}
      <Box
        className={classes.goBackLink}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        <ButtonLink
          icon={<ArrowLeftIcon />}
          text="前の画面に戻る"
          iconPosition="start"
          click={() => navigate.navigatePop()}
        />
      </Box>
      <Content title="商材詳細情報">
        <ProductDetailList
          productDetail={productDetail}
          handleRecommendedUpdate={handleRecommendedUpdate}
          handleClickFavorite={handleClickFavorite}
          previewData={previewData}
          handleDelete={() =>
            setModalVisible({
              ...modalVisible,
              moveToTashDialog: true,
            })
          }
        />
      </Content>
      <Grid
        container
        spacing={2}
        justify="center"
        className={classes.buttonContainer}
      >
        {declinePermission?.show && (
          <Grid item xs={5}>
            <SecondaryButton
              disabled={declinePermission.disabled || isSubmitting}
              text="商材を差し戻す"
              icon={<ReturnIcon />}
              click={() => {
                setModalVisible({
                  ...modalVisible,
                  declineDialog: true,
                });
              }}
            />
          </Grid>
        )}
        {approvalPermission?.show && (
          <Grid item xs={5}>
            <PrimaryButton
              text="商材を公開承認する"
              icon={<CheckIcon />}
              disabled={approvalPermission.disabled || isSubmitting}
              click={() => {
                setModalVisible({
                  ...modalVisible,
                  applyDialog: true,
                });
              }}
            />
          </Grid>
        )}
      </Grid>
      <Content title="導入事例">
        <ExampleList
          productExamples={productExamples}
          click={(id) => navigate.navigate(`/adm/examples/${id}`)}
        />
      </Content>
      <Box mt={5} />
      {applyHistoryPermission && (
        <Content title="承認申請履歴">
          {productWorkflows ? (
            <ApplyHistoryList pagination exampleWorkflows={productWorkflows} />
          ) : (
            <></>
          )}
        </Content>
      )}
      <Box className={classes.button}>
        {editPermission?.show && (
          <PrimaryButton
            disabled={editPermission.disabled}
            text="商材内容を編集する"
            icon={<EditIcon />}
            click={() =>
              navigate.navigate(`/adm/products/${productDetail.productId}/edit`)
            }
          />
        )}
      </Box>
      {(suspensionPermission?.show || reopenPermission?.show) && (
        <Box m="auto" width={332} height={48}>
        {suspensionPermission?.show && (
          <TextTypeButton
            fontSize="18"
            disabled={suspensionPermission?.disabled}
            text="公開を一時停止する"
            click={() => handleStop()}
          />
        )}
        {reopenPermission?.show && (
          <TextTypeButton
            fontSize="18"
            disabled={reopenPermission?.disabled}
            text="公開を再開する"
            click={() => handleReopen()}
          />
        )}
        </Box>
      )}
      <NoticeDialog
        title="商材を差し戻しました"
        open={modalVisible.noticeDialog}
        handleClose={() => closeDialog()}
      />
      <DeclineDialog
        open={modalVisible.declineDialog}
        handleCancel={() => closeDialog()}
        handleSubmit={(message) => {
          setIsSubmitting((prev) => !prev);
          handleWorkflowStatusUpdate(
            step,
            CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
            message,
          );
          setTimeout(() => {
            setIsSubmitting((prev) => !prev);
          }, 1000);
          closeDialog();
        }}
      />
      <ApplyDialog
        title="商材を承認しますか？"
        contentTitle={productDetail.productName}
        open={modalVisible.applyDialog}
        handleCancel={() => closeDialog()}
        handleApply={() => {
          setIsSubmitting((prev) => !prev);
          handleWorkflowStatusUpdate(
            step,
            CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
          );
          setTimeout(() => {
            setIsSubmitting((prev) => !prev);
          }, 1000);
          closeDialog();
        }}
        previewData={previewData}
      />
      {productWorkflows && productWorkflows.length > 0 && (
        <AppliedDialog
          workflow={productWorkflows[0]}
          open={modalVisible.appliedDialog}
          handleClose={() => closeDialog()}
        />
      )}
      <ConfirmDialog
        buttonText="ゴミ箱に移動する"
        open={modalVisible.moveToTashDialog}
        // eslint-disable-next-line react/jsx-curly-brace-presence
        title=""
        text="ゴミ箱に移動します。"
        handleCancel={() => closeDialog()}
        handleSubmit={() => {
          if (handleDelete) handleDelete();
          closeDialog();
        }}
      />
    </Box>
  );
};

export default ProductDetailPage;

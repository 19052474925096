/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  MessageAlert,
  PrimaryButton,
  SecondaryButton,
} from 'components/atoms';
import {
  Content,
  ConfirmDialog,
  InquiryLabelArea,
  FirstInquiryField,
  InquiryItemsInputField,
  InquiryMailAddressField,
  InquiryPullDownField,
  InquiryInterestedThemeFields,
  InquirySendingMaterialsField,
  InquiryBirthdayField,
  InquiryRadioButtonField,
  InquiryCheckBoxField,
  SuccessDialog,
} from 'components/organisms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';
import { InquiryDetail, InquiryInfoRequest } from 'core/domain/inquiries';
import {
  clearInquiryInfoRequest,
  setInquiryInfoRequest,
} from 'core/modules/InquiryInfoRequest';
import { useParams } from 'react-router-dom';
import { hooks } from 'libs';
import { AppError } from 'core/domain/appError';
import { LpDetail } from 'core/domain/lp';
import clsx from 'clsx';
import { clearInquiryDetail } from 'core/modules/inquiryDetail';
import { clearInquiryForms } from 'core/modules/inquiryFormsItem';
import { clearLpDetail } from 'core/modules/lpDetail';
import { setError } from 'core/modules/error';
import _ from 'lodash';
import { InquiriesForm } from 'core/domain/settings';

interface InquiryRegisterProps {
  error: AppError | null;
  handleInquiryRegister: () => void;
  inquiryDetail: InquiryDetail | null;
  inquiryInfoRequest: InquiryInfoRequest | null;
  lpDetail: LpDetail | null;
  lpName: string | undefined;
  inquiriesForm: InquiriesForm | null;
}

interface Validations {
  inquiryDate: string | undefined;
  inquiryTime: string | undefined;
  inquiryType: string | undefined;
  [key: number]: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    items: {
      '& > :nth-child(n)': {
        borderBottom: '1px solid #ddd',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    buttonArea: {
      marginTop: theme.spacing(2),
    },
  }),
);

const InquiryRegister = (props: InquiryRegisterProps) => {
  const {
    error,
    handleInquiryRegister,
    inquiryDetail,
    inquiryInfoRequest,
    lpDetail,
    lpName,
    inquiriesForm,
  } = props;
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { inquiryId } = useParams<{ inquiryId: string }>();
  const classes = useStyles();
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const [initialInquiryInfoRequest, setInitialInquiryInfoRequest] = useState<
    InquiryInfoRequest | null | undefined
  >(undefined);
  const [validationError, setValidationError] = useState<Validations>();
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [goAnotherPage, setGoAnotherPage] = useState<boolean>(false);
  const [nextPagePath, setNextPagePath] = useState<string>('');
  const [secondTimesOnwords, setSecondTimesOnwords] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorText, setErrorText] = useState<string>('');
  const [errorDateText, setErrorDateText] = useState<string>('');

  const getFormAnswer = (id: number) => {
    if (!inquiryInfoRequest || !inquiryInfoRequest.inquiryFormAnswers)
      return undefined;
    let value = inquiryInfoRequest.inquiryFormAnswers.find(
      (answer) => answer.inquiryItemId === id,
    );

    if (!value) {
      value = inquiryDetail?.others?.find(
        (answer) => answer.inquiryItemId === id,
      );
    }

    return value;
  };

  const validationInputValue = () => {
    const requiredForms = inquiryForms?.filter((form) => form.required);
    // eslint-disable-next-line
    const validations: Validations = requiredForms!.reduce(
      (acc: Validations, curr) => {
        acc[curr.inquiryItemId] = undefined;

        return acc;
      },
      {
        inquiryDate: undefined,
        inquiryTime: undefined,
        inquiryType: undefined,
      },
    );

    if (!inquiryInfoRequest?.inquiryType)
      validations.inquiryType = '問い合わせ方法を入力してください';
    if (
      !inquiryInfoRequest?.inquiryDate ||
      !inquiryInfoRequest?.inquiryTime ||
      errorDateText ||
      errorText
    )
      // eslint-disable-next-line
      validations.inquiryDate = '日付・時刻を入力してください';

    requiredForms!.map((form) => {
      const formAnswer = getFormAnswer(form.inquiryItemId);
      if (form.inquiryItemId !== 15 && !formAnswer?.value) {
        validations[
          form.inquiryItemId
        ] = `${form.inquiryItemName}を入力してください`;
      }
      if (form.inquiryItemId === 15 && !formAnswer?.interested) {
        validations[
          form.inquiryItemId
        ] = `${form.inquiryItemName}を入力してください`;
      }
      if (
        formAnswer?.interested?.some((e) => e.name === 'その他' && !e.detail)
      ) {
        validations[form.inquiryItemId] =
          'その他を選択した場合は、詳細を入力してください';
      }
    });

    // 入力文字数チェック
    inquiryForms!.map((form) => {
      const formAnswer = getFormAnswer(form.inquiryItemId);
      if (
        (form.inquiryItemName === '貴社名' ||
          form.inquiryItemName === '部署名' ||
          form.inquiryItemName === 'お名前' ||
          form.inquiryItemName === '役職' ||
          form.inquiryItemName === '業種') &&
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 100
      )
        validations[form.inquiryItemId] = '100文字以内で入力してください。';
      if (
        form.inquiryItemName === '電話番号' &&
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 20
      )
        validations[form.inquiryItemId] = '20文字以内で入力してください。';
      if (
        form.inquiryItemName === '郵便番号' &&
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 7
      )
        validations[form.inquiryItemId] = '7文字以内で入力してください。';
      if (
        (form.inquiryItemName === '住所' ||
          (form.inquiryItemId > 17 && form.inputType === 1)) && // 自由項目かつテキストボックス
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 500
      )
        validations[form.inquiryItemId] = '500文字以内で入力してください。';
      if (
        form.inquiryItemName === '年齢' &&
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 3
      )
        validations[form.inquiryItemId] = '3文字以内で入力してください。';
      if (
        formAnswer?.interested?.some(
          (e) => e.name === 'その他' && e.detail && e.detail?.length > 500,
        )
      )
        validations[form.inquiryItemId] = '500文字以内で入力してください。';
      if (
        form.inquiryItemName.includes('ご相談') &&
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 1000
      )
        validations[form.inquiryItemId] = '1000文字以内で入力してください。';
      if (
        form.inquiryItemId > 17 &&
        form.inputType === 2 && // 自由項目かつテキストエリア
        formAnswer &&
        formAnswer.value &&
        formAnswer.value.length > 1000
      )
        validations[form.inquiryItemId] = '1000文字以内で入力してください。';
    });

    if (!Object.values(validations).every((e) => e === undefined)) {
      setAlertOpen(true);
      setValidationError(validations);

      return false;
    }

    return true;
  };

  const handleFirstInquiryChange = (
    type: 'inquiryDate' | 'inquiryType',
    value: string,
  ) => {
    if (type === 'inquiryType') {
      dispatch(
        setInquiryInfoRequest({
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          ...inquiryInfoRequest!,
          inquiryType: +value,
        }),
      );

      return;
    }
    const date = value.split('T')[0].replaceAll('-', '');
    const time = value.split('T')[1].replace(':', '');
    dispatch(
      setInquiryInfoRequest({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        ...inquiryInfoRequest!,
        inquiryDate: date,
        inquiryTime: time,
      }),
    );
  };

  const handleNormalInputChange = (
    inquiryItemId: number,
    inquiryItemName: string,
    value: string,
  ) => {
    dispatch(
      setInquiryInfoRequest({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        ...inquiryInfoRequest!,
        inquiryFormAnswers: inquiryInfoRequest?.inquiryFormAnswers
          ? [
              ...inquiryInfoRequest.inquiryFormAnswers.filter(
                (answer) => answer.inquiryItemId !== inquiryItemId,
              ),
              {
                inquiryItemId,
                inquiryItemName,
                value,
              },
            ]
          : [
              {
                inquiryItemId,
                inquiryItemName,
                value,
              },
            ],
      }),
    );
  };

  const handleInterestedThemeChange = (
    inquiryItemId: number,
    inquiryItemName: string,
    themeInfo: {
      id: string | null;
      name: string;
      detail?: string;
    },
  ) => {
    const currFormAnswer = getFormAnswer(inquiryItemId);

    dispatch(
      setInquiryInfoRequest({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        ...inquiryInfoRequest!,
        inquiryFormAnswers: inquiryInfoRequest?.inquiryFormAnswers
          ? [
              ...inquiryInfoRequest.inquiryFormAnswers.filter(
                (answer) => answer.inquiryItemId !== inquiryItemId,
              ),
              {
                inquiryItemId,
                inquiryItemName,
                interested: currFormAnswer?.interested
                  ? [
                      ...currFormAnswer.interested.filter(
                        (item) => themeInfo.id !== item.id,
                      ),
                      themeInfo,
                    ]
                  : [themeInfo],
              },
            ]
          : [
              {
                inquiryItemId,
                inquiryItemName,
                interested: [themeInfo],
              },
            ],
      }),
    );
  };

  const handleInterestedThemeRemove = (
    inquiryItemId: number,
    inquiryItemName: string,
    themeId: string,
  ) => {
    if (!inquiryInfoRequest) return;
    const currFormAnswer = getFormAnswer(inquiryItemId);

    dispatch(
      setInquiryInfoRequest({
        ...inquiryInfoRequest,
        inquiryFormAnswers: [
          ...inquiryInfoRequest.inquiryFormAnswers.filter(
            (answer) => answer.inquiryItemId !== inquiryItemId,
          ),
          {
            inquiryItemId,
            inquiryItemName,
            interested: currFormAnswer?.interested?.filter(
              (item) => themeId ? item.id !== themeId : item.id ,
            ),
          },
        ],
      }),
    );
  };

  const handleCloseSuccessDialog = () => {
    setGoAnotherPage(true);

    if (lpDetail?.lpId && !inquiryId) {
      // 登録時LP詳細へ
      navigate.navigateReplace(`/adm/lps/${lpDetail?.lpId}/approved`);
    } else if (inquiryId) {
      // 編集時
      navigate.navigateReplace(`/adm/inquiries/${inquiryId}`);
    }

    // 新規登録後に再度新規登録するときにデータが残るため値をクリア
    dispatch(clearInquiryInfoRequest());
    dispatch(clearInquiryDetail());
    setInitialInquiryInfoRequest(null);
    dispatch(clearInquiryForms());
    dispatch(clearLpDetail());
    setSuccessDialogOpen(false);
    setIsRegister(true);
  };

  const handleRegister = () => {
    setValidationError(undefined);

    if (!validationInputValue()) return;

    handleInquiryRegister();

    setSuccessDialogOpen(true);
  };

  useEffect(() => {
    if (
      (initialInquiryInfoRequest === null && !inquiryId) ||
      initialInquiryInfoRequest
    )
      return;

    if (inquiryInfoRequest && inquiryDetail) {
      const tmpInquiry = _.cloneDeep(inquiryInfoRequest);
      inquiryDetail.others?.map((item) => {
        tmpInquiry.inquiryFormAnswers.push({
          inquiryItemId: item.inquiryItemId,
          inquiryItemName: item.inquiryItemName,
          value: item.value,
        });
      });
      const interestedItems = inquiryDetail.products.filter(
        (item) => item.interested,
      );
      const interested: { id: string | null; name: string; detail?: string }[] =
        [];
      if (interestedItems) {
        interestedItems.map((item) => {
          if (item.productId) {
            interested.push({
              id: item.productId.toString(),
              name: item.productName,
            });
          } else {
            interested.push({
              id: null,
              name: 'その他',
              detail: item.productName,
            });
          }
        });
      }
      const theme = inquiryForms?.find(
        (item) => item.inquiryItemName === '気になったテーマ',
      );
      if (theme) {
        tmpInquiry.inquiryFormAnswers.push({
          inquiryItemId: theme.inquiryItemId,
          inquiryItemName: theme.inquiryItemName,
          // eslint-disable-next-line object-shorthand
          interested: interested,
        });
      }
      setInitialInquiryInfoRequest(tmpInquiry ? { ...tmpInquiry } : null);
      dispatch(setInquiryInfoRequest(tmpInquiry));
    }
  }, [
    dispatch,
    initialInquiryInfoRequest,
    inquiryDetail,
    inquiryForms,
    inquiryId,
    inquiryInfoRequest,
    lpDetail,
  ]);

  useEffect(() => {
    if (!lpDetail?.lpId && !inquiryId) return;
    const res = navigate.block((pathname: string): false | undefined => {
      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);

        return undefined;
      }
      if (
        (!inquiryInfoRequest && !initialInquiryInfoRequest) ||
        isRegister ||
        goAnotherPage
      )
        return undefined;

      if (
        inquiryInfoRequest?.inquiryFormAnswers &&
        inquiryInfoRequest?.inquiryFormAnswers.every(
          (e) => e.value !== '' && e.interested?.length === 0,
        )
      )
        return undefined;

      if (
        inquiryInfoRequest &&
        initialInquiryInfoRequest &&
        JSON.stringify(inquiryInfoRequest) !==
          JSON.stringify(initialInquiryInfoRequest)
      ) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      if (!initialInquiryInfoRequest && inquiryInfoRequest) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => res();
    // eslint-disable-next-line
  }, [
    inquiryInfoRequest,
    isRegister,
    goAnotherPage,
    error,
    navigate,
    initialInquiryInfoRequest,
    inquiryDetail,
    lpDetail?.lpId,
  ]);
  // URLから直接新規登録に遷移禁止
  useEffect(() => {
    if (inquiryId || lpDetail?.lpId || isRegister) {
      return;
    }
    
    dispatch(setError({
      title: '正しくページを表示できませんでした。',
      error: ['再度アクセスしなおしてください。']
    }));
  }, [dispatch, inquiryDetail, inquiryId, isRegister, lpDetail?.lpId]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  useEffect(() => {
    setAlertOpen(false);
  }, [inquiryInfoRequest]);

  useEffect(() => {
    if (isRegister && !inquiryId && inquiryDetail) {
      navigate.navigateReplace(`/adm/inquiries/${inquiryDetail.inquiryId}`);
    }
  }, [isRegister, inquiryId, navigate, inquiryDetail]);

  useEffect(() => {
    // お問い合わせ編集
    if (inquiryInfoRequest && inquiryDetail) {
      setSecondTimesOnwords(true);
    }
  }, [inquiryDetail, inquiryForms, inquiryInfoRequest, lpDetail]);

  // お問い合わせの更新日時とお問い合わせフォームの更新日時の比較
  useEffect(() => {
    if (error || !inquiryDetail || !inquiriesForm || !inquiriesForm.updateDate)
      return;

    // お問い合わせ最終更新日時
    const inqUpdateDate =
      inquiryDetail.updateDate.split('T')[0].replaceAll('-', '');
    const inqUpdateTime =
      inquiryDetail.updateDate.split('T')[1].replaceAll(':', '').replaceAll('Z', '');
    const inqUpdateDateTime = parseInt(`${inqUpdateDate}${inqUpdateTime}`, 10);
    // お問い合わせフォーム更新日時
    const formUpdateDate =
      inquiriesForm.updateDate.split('T')[0].replaceAll('-', '');
    const formUpdateTime =
      inquiriesForm.updateDate.split('T')[1].replaceAll(':', '').replaceAll('Z', '');
    const formUpdateDateTime = parseInt(`${formUpdateDate}${formUpdateTime}`, 10);

    // 編集を許可せずダッシュボードに遷移
    if (inqUpdateDateTime < formUpdateDateTime) {
      dispatch(
        setError({
          title: 'フォームが変更されたため、編集できません',
          error: [],
        }),
      );
    }
  }, [dispatch, error, inquiryDetail, inquiriesForm]);

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: validationError,
      })}
    >
      {alertOpen && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}

      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => {
          handleCloseSuccessDialog();
        }}
      />

      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
      <Content title="お問い合わせ登録・編集">
        <>
          <Box className={classes.items}>
            <InquiryLabelArea name={lpName} />
            <FirstInquiryField
              defaultValue={
                inquiryInfoRequest
                  ? {
                      inquiryDate: inquiryInfoRequest.inquiryDate,
                      inquiryTime: inquiryInfoRequest.inquiryTime,
                      inquiryType: inquiryInfoRequest.inquiryType,
                    }
                  : undefined
              }
              error={
                validationError?.inquiryDate ||
                validationError?.inquiryType ||
                validationError?.inquiryTime
              }
              onChange={handleFirstInquiryChange}
              setErrorText={setErrorText}
              errorText={errorText}
              setErrorDateText={setErrorDateText}
              errorDateText={errorDateText}
            />
            {inquiryForms &&
              inquiryForms.map((form) => {
                if (
                  form.inputType === 1 &&
                  form.inquiryItemName === 'メールアドレス'
                ) {
                  return (
                    form.displayed && (
                      <InquiryMailAddressField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                        secondTimesOnwords={secondTimesOnwords}
                      />
                    )
                  );
                }
                if (form.inputType === 2) {
                  return (
                    form.displayed && (
                      <InquiryItemsInputField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        multiline
                        onChange={handleNormalInputChange}
                        title={form.inquiryItemName}
                        disabled={
                          form.inquiryItemType === 2
                            ? secondTimesOnwords
                            : false
                        }
                      />
                    )
                  );
                }
                if (form.inputType === 3) {
                  return (
                    form.displayed && (
                      <InquiryRadioButtonField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                        title={form.inquiryItemName}
                        options={form.inquiryItemOptions}
                        disabled={
                          form.inquiryItemType === 2
                            ? secondTimesOnwords
                            : false
                        }
                      />
                    )
                  );
                }
                if (
                  form.inputType === 4 &&
                  form.inquiryItemName === '気になったテーマ'
                ) {
                  return (
                    form.displayed && (
                      <InquiryInterestedThemeFields
                        key={form.inquiryItemId}
                        defaultValue={inquiryDetail?.products}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleInterestedThemeChange}
                        onRemove={handleInterestedThemeRemove}
                        products={
                          inquiryDetail?.products
                            ? inquiryDetail.products
                            : lpDetail?.products
                        }
                      />
                    )
                  );
                }
                if (
                  form.inputType === 4 &&
                  form.inquiryItemName === '資料送付'
                ) {
                  return (
                    form.displayed && (
                      <InquirySendingMaterialsField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                      />
                    )
                  );
                }
                if (form.inputType === 4) {
                  return (
                    form.displayed && (
                      <InquiryCheckBoxField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                        title={form.inquiryItemName}
                        options={form.inquiryItemOptions}
                        disabled={
                          form.inquiryItemType === 2
                            ? secondTimesOnwords
                            : false
                        }
                      />
                    )
                  );
                }
                if (form.inputType === 5) {
                  return (
                    form.displayed && (
                      <InquiryPullDownField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                        title={form.inquiryItemName}
                        options={form.inquiryItemOptions}
                        disabled={
                          form.inquiryItemType === 2
                            ? secondTimesOnwords
                            : false
                        }
                      />
                    )
                  );
                }
                if (form.inputType === 8) {
                  return (
                    form.displayed && (
                      <InquiryBirthdayField
                        key={form.inquiryItemId}
                        defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                        error={
                          validationError && validationError[form.inquiryItemId]
                        }
                        inquiryItemId={form.inquiryItemId}
                        isMandatory={form.required}
                        onChange={handleNormalInputChange}
                      />
                    )
                  );
                }

                return (
                  form.displayed && (
                    <InquiryItemsInputField
                      key={form.inquiryItemId}
                      defaultValue={getFormAnswer(form.inquiryItemId)?.value}
                      error={
                        validationError && validationError[form.inquiryItemId]
                      }
                      inquiryItemId={form.inquiryItemId}
                      isMandatory={form.required}
                      onChange={handleNormalInputChange}
                      title={form.inquiryItemName}
                      disabled={
                        form.inquiryItemType === 2 ? secondTimesOnwords : false
                      }
                    />
                  )
                );
              })}
          </Box>
          <Grid
            className={classes.buttonArea}
            container
            spacing={2}
            justify="center"
          >
            <Grid item xs={4}>
              <SecondaryButton
                text="キャンセル"
                click={() => {
                  if (inquiryInfoRequest) setOpenExitConfirmDialog(true);

                  if (inquiryId && !lpDetail?.lpId) {
                    // 更新時
                    navigate.navigateReplace(`/adm/inquiries/${inquiryId}`);
                  } else if (!inquiryId && lpDetail?.lpId) {
                    // 登録時はLP詳細へ
                    navigate.navigateReplace(
                      `/adm/lps/${lpDetail.lpId}/approved`,
                    );
                  } else {
                    navigate.navigateReplace(`/adm/inquiries/${inquiryId}`);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton
                text="お問い合わせ内容を登録する"
                click={() => {
                  handleRegister();
                }}
              />
            </Grid>
          </Grid>
        </>
      </Content>
    </Box>
  );
};

export default InquiryRegister;

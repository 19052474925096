import { useEffect, useState } from 'react';
import CustomersPage from 'components/pages/Customers';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { setCustomersQueryParameter } from 'core/modules/customersQueryParameter';
import { CustomersSortKey } from 'core/domain/customer';
import { hooks } from 'libs';
import { setCustomerTagsRequest } from 'core/modules/customerTagsRequest';
import { clearDeleteCustomers } from 'core/modules/deleteCustomers';
import { fileToBase64 } from 'libs/file';
import { setCustomers } from 'core/modules/customers';

// 表示行数
const pageRows = 10;

/**
 * Presenter
 */

const CustomersContainer = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const customersQueryParameter = useSelector(
    (state: Modules.AppState) => state.customersQueryParameter,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  // 顧客一覧
  const customers = useSelector(
    (state: Modules.AppState) => state.customers,
    shallowEqual,
  );
  // 顧客削除
  const customerIds = useSelector(
    (state: Modules.AppState) => state.deleteCustomers,
    shallowEqual,
  );
  // タグ
  const customerTag = useSelector(
    (state: Modules.AppState) => state.customerTags,
    shallowEqual,
  );
  const customerTagRequest = useSelector(
    (state: Modules.AppState) => state.customerTagsRequest,
    shallowEqual,
  );

  const [csvUploadSuccessDialogOpen, setCsvUploadSuccessDialogOpen] =
    useState(false);

  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  // タグリクエストパラメータ初期値設定

  useEffect(() => {
    dispatch(setCustomerTagsRequest({ tagType: 'list', sortKey: 'tag' }));
  }, [dispatch]);

  // 顧客一覧取得
  useEffect(() => {
    if (!customersQueryParameter) {
      dispatch(
        setCustomersQueryParameter({
          page: 1,
          row: pageRows,
        }),
      );

      return;
    }

    if (!userInfo) return;
    dispatch(
      Usecases.customer.sendGetCustomers(
        token,
        userInfo.tenantId,
        customersQueryParameter,
      ),
    );
  }, [customersQueryParameter, userInfo, token, dispatch]);

  // 現在のページを取得しリクエストパラメーターに設定
  useEffect(() => {
    if (
      customers &&
      customers.customers &&
      customers.customers.length === 0 &&
      customers.totalCount > 0
    ) {
      const latestPage = Math.ceil(customers?.totalCount / pageRows);
      dispatch(
        setCustomersQueryParameter({
          ...customersQueryParameter,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [dispatch, customers, customersQueryParameter]);

  // タグ

  useEffect(() => {
    if (!userInfo || !customerTagRequest.sortKey || !customerTagRequest.tagType)
      return;
    dispatch(
      Usecases.customer.sendGetCustomerTags(
        token,
        userInfo.tenantId,
        customerTagRequest,
      ),
    );
  }, [token, userInfo, customerTagRequest, dispatch]);

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (customersQueryParameter?.sort_key === (sortKey as CustomersSortKey)) {
      order = customersQueryParameter.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setCustomersQueryParameter({
        ...customersQueryParameter,
        sort_key: sortKey as CustomersSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );

    // チェック状態をクリア
    dispatch(clearDeleteCustomers());
  };

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page?: number) => {
    dispatch(
      setCustomersQueryParameter({
        ...customersQueryParameter,

        page,
        row: pageRows,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setCustomersQueryParameter({
        ...customersQueryParameter,
        free_word: freeWord,
      }),
    );

    // チェック状態をクリア
    dispatch(clearDeleteCustomers());
  };

  /**
   * タグ検索値変更時、リクエストパラメーターに設定
   * @param tags
   */
  const handleChangeTag = (tags: string) => {
    dispatch(
      setCustomersQueryParameter({
        ...customersQueryParameter,
        tags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );

    // チェック状態をクリア
    dispatch(clearDeleteCustomers());
  };

  // チェックされた顧客を削除
  const handleDelete = () => {
    if (!userInfo || !customerIds || !customerIds.customerIds || !customers)
      return;

    // 削除APIへ
    dispatch(
      Usecases.customer.sendDeleteCustomers(
        token,
        userInfo.tenantId,
        customerIds,
      ),
    );
    dispatch(
      setCustomers({
        customers: customers.customers.filter((customer) =>
          customerIds.customerIds?.every((e) => e !== customer.customerId),
        ),
        totalCount: customers.totalCount - customerIds.customerIds.length,
      }),
    );
    dispatch(clearDeleteCustomers());
    // リクエストパラメータをセット
    dispatch(setCustomersQueryParameter({ ...customersQueryParameter }));
  };

  // csv upload

  const handleRegister = (newCustomersCsv: string) => {
    if (!userInfo || !newCustomersCsv) return;
    dispatch(
      Usecases.customer.sendPutNewCustomersCsv(
        token,
        userInfo.tenantId,
        // この時点で先頭についている"data:"の文字列を除去しないと登録されない
        newCustomersCsv.split('').splice(5).join('').toString(),
      ),
    );
    // 顧客一覧再取得
    dispatch(
      Usecases.customer.sendGetCustomers(
        token,
        userInfo.tenantId,
        customersQueryParameter,
      ),
    );
  };
  // 一括登録ファイル
  const setCsvData = async (file: File) => {
    await fileToBase64(file).then((res) => {
      const newCustomerCsvData = {
        data: res as string,
      };
      handleRegister(newCustomerCsvData.data);
    });
  };

  const [fileDialogOpen, setFileDialogOpen] = useState(false);

  const handleFileSubmit = async (files: File[]) => {
    if (!files.length || !files[0]) return;

    await setCsvData(files[0]);
    setFileDialogOpen(false);
    setCsvUploadSuccessDialogOpen(true);
  };

  const onFileUploadDialog = () => {
    setFileDialogOpen(false);
  };
  
  const deleteTag = (val:string) => {
    // customersQueryParameterからvalの値のタグを除去してset
    if (!customersQueryParameter || !customersQueryParameter.tags) return ;

    const newTags = customersQueryParameter.tags.split(',').filter((tag) => tag !==val);
    
    dispatch(setCustomersQueryParameter({ 
        ...customersQueryParameter,
        tags:newTags.join(','),
        free_word: freeWord?.freeWord,
    }));
  }


  return (
    <CustomersPage
      customers={customers}
      rows={pageRows}
      customersQueryParameter={customersQueryParameter}
      handleChangeSortKey={handleChangeSortKey}
      handleChangePage={handleChangePage}
      handleChangeFreeWord={handleChangeFreeWord}
      handleDelete={handleDelete}
      handleChangeTag={handleChangeTag}
      tags={customerTag}
      fileDialogOpen={fileDialogOpen}
      setFileDialogOpen={setFileDialogOpen}
      handleFileSubmit={handleFileSubmit}
      onFileUploadDialog={onFileUploadDialog}
      csvUploadSuccessDialogOpen={csvUploadSuccessDialogOpen}
      setCsvUploadSuccessDialogOpen={setCsvUploadSuccessDialogOpen}
      deleteTag={deleteTag}
    />
  );
};
export default CustomersContainer;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpInquiries } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpInquiries | null {
  return initialState
}

export const SET_LP_INQUIRIES = 'lp-inquiries/set' as const
export const CLEAR_LP_INQUIRIES = 'lp-inquiries/clear' as const

export function setLpInquiries(data: LpInquiries) {
  return {
    type: SET_LP_INQUIRIES,
    payload: {
      data,
    },
  }
}

export function clearLpInquiries() {
  return {
    type: CLEAR_LP_INQUIRIES,
  }
}

type SetLpInquiriesAction = ReturnType<typeof setLpInquiries>
type ClearLpInquiriesAction = ReturnType<typeof clearLpInquiries>
type Action = SetLpInquiriesAction | ClearLpInquiriesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_INQUIRIES:
      return action.payload.data
    case CLEAR_LP_INQUIRIES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

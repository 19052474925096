import { TrashesSearchCondition } from 'core/domain/trashes';

const initialState = null;

export const createInitialState = (): TrashesSearchCondition | null => initialState;

export const SET_TRASHES_SEARCH_CONDITION = 'trashes-search-condition/set' as const;
export const CLEAR_TRASHES_SEARCH_CONDITION = 'trashes-search-condition/clear' as const;

export const setTrashesSearchCondition = (data: TrashesSearchCondition) => ({
    type: SET_TRASHES_SEARCH_CONDITION,
    payload: {
      data,
    },
  });

export const clearTrashesSearchCondition = () => ({
    type: CLEAR_TRASHES_SEARCH_CONDITION,
  });

type SetTrashesSearchConditionAction = ReturnType<typeof setTrashesSearchCondition>;
type ClearTrashesSearchConditionAction = ReturnType<typeof clearTrashesSearchCondition>;
type Action = SetTrashesSearchConditionAction | ClearTrashesSearchConditionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TRASHES_SEARCH_CONDITION:
      return action.payload.data;
    case CLEAR_TRASHES_SEARCH_CONDITION:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


export function fileToBase64(file:File){
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = function(event){
      resolve(event.target?.result)
    }
    reader.readAsDataURL(file)
  })
}/* eslint-enable */

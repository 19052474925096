/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { InformationIcon } from 'assets/images';
import clsx from 'clsx';

/**
 * Interface
 */

export interface ConfirmDialogProps {
  open: boolean;
  title: string;
  text: string;
  handleCancel: () => void;
  handleSubmit: () => void;
  showIcon?: boolean;
  buttonText: string;
  disableConfirmText?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(6),
      width: 854,
      margin: 0,
    },
    title: {
      color: theme.palette.pink[900],
    },
    text: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_600,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
    },
    icon: {
      '& g path': {
        fill: theme.palette.pink[900],
      },
      height: 64,
      width: 64,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  }),
);

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const classes = useStyles({});
  const {
    title,
    text,
    open,
    handleCancel,
    handleSubmit,
    showIcon,
    buttonText,
    disableConfirmText,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box textAlign="center">
        {showIcon && (
          <>
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.title)}
            >
              {title}
            </Typography>
            <InformationIcon className={classes.icon} />
          </>
        )}
        <Typography variant="body2" className={classes.text}>
          {text}
        </Typography>
        {!disableConfirmText && (
          <Typography variant="body2" className={classes.text}>
            よろしいですか？
          </Typography>
        )}
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton click={() => handleCancel()} text="キャンセル" />
            </Box>
            <Box ml={2} width={332} height={48}>
              <PrimaryButton click={() => handleSubmit()} text={buttonText} />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
/* eslint-enable */

import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Inquiry } from 'core/domain/inquiries';
import { formatDateTimeISO8601, formateDateToJpString } from 'libs/date';
import { hooks } from 'libs';

/**
 * Interface
 */

export interface CustomerBoxProps {
  inquiry: Inquiry;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '375px',
      position: 'relative',
      borderColor: 'rgba(37, 36, 30, 0.08)',
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: 'solid',
      padding: `${theme.spacing(2)}px 0`,
    },
    content: {
      width: '343px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      left: '4%',
      top: '13%',
    },
    head: {
      width: '343px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headText: {
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#262622',
    },
    date: {
      fontSize: '12px',
      color: '#262622',
      margin: `${theme.spacing(1)}px 0`,
    },
    text: {
      fontSize: '13px',
      color: '#82827E',
      width: '343px',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
    },
    readText: {
      color: '#82827E',
    },
  }),
);

/**
 * Presenter
 */

const CustomerBox = (props: CustomerBoxProps) => {
  const classes = useStyles({});
  const { navigate } = hooks.useNavigate();
  const { inquiry } = props;

  const message = useMemo(() => {
    if (!inquiry.inquiryDetail) return '';

    return inquiry.inquiryDetail.substring(0, 100);
  }, [inquiry.inquiryDetail]);

  return (
    <Box
      className={classes.root}
      onClick={() => navigate(`/adm/inquiries/${inquiry.inquiryId}`)}
    >
      <Box className={classes.content}>
        <Box className={classes.head}>
          <Typography
            className={classes.headText}
          >{`「${inquiry.lpName}」 へお問い合わせが届きました`}</Typography>
        </Box>
        <Typography className={classes.date}>
          {`${formateDateToJpString(
            formatDateTimeISO8601(inquiry.inquiryDate).substring(
              0,
              inquiry.inquiryDate.length - 9,
            ),
          )} ${formatDateTimeISO8601(inquiry.inquiryDate).substring(
            11,
            inquiry.inquiryDate.length,
          )}`}
        </Typography>
        <Typography display="block" className={classes.text}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};
export default CustomerBox;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpView } from 'core/domain/lp'

const initialState = null

export function createInitialState(): LpView | null {
  return initialState
}

export const SET_LP_VIEW = 'lp-view/set' as const
export const CLEAR_LP_VIEW = 'lp-view/clear' as const

export function setLpView(data: LpView) {
  return {
    type: SET_LP_VIEW,
    payload: {
      data,
    },
  }
}

export function clearLpView() {
  return {
    type: CLEAR_LP_VIEW,
  }
}

type SetLpViewAction = ReturnType<typeof setLpView>
type ClearLpViewAction = ReturnType<typeof clearLpView>
type Action = SetLpViewAction | ClearLpViewAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_VIEW:
      return action.payload.data
    case CLEAR_LP_VIEW:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React from 'react';
import { Grid } from 'components/atoms';
import { CVIcon, DLIcon, GoodIcon, PVIcon } from 'assets/images';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { DashboardReactionCardItem } from 'components/organisms';

interface CardItem {
  title: string;
  icon: React.ReactNode;
  rankLabel: string;
  countAccessor:
    | 'lpAccessCount'
    | 'interestedCount'
    | 'productDocumentDownloadCount'
    | 'inquiryCount';
  firstRankAccessor:
    | 'lpAccessRankingFirst'
    | 'interestedRankingFirst'
    | 'productDocumentDownloadRankingFirst'
    | 'inquiryRankingFirst';
}

const CARD_ITEMS: CardItem[] = [
  {
    title: 'ランディングページ\nページ閲覧数累計',
    icon: <PVIcon />,
    rankLabel: 'ページ閲覧数',
    countAccessor: 'lpAccessCount',
    firstRankAccessor: 'lpAccessRankingFirst',
  },
  {
    title: 'ランディングページ\nお問い合わせ数累計',
    icon: <CVIcon />,
    rankLabel: 'お問い合わせ数',
    countAccessor: 'inquiryCount',
    firstRankAccessor: 'inquiryRankingFirst',
  },
  {
    title: '商材興味アリ獲得数累計',
    icon: <GoodIcon />,
    rankLabel: '興味アリ獲得数',
    countAccessor: 'interestedCount',
    firstRankAccessor: 'interestedRankingFirst',
  },
  {
    title: '商材資料ダウンロード数累計',
    icon: <DLIcon />,
    rankLabel: '資料ダウンロード数',
    countAccessor: 'productDocumentDownloadCount',
    firstRankAccessor: 'productDocumentDownloadRankingFirst',
  },
];

const DashboardReactionCardArea = () => {
  const totalReaction = useSelector(
    (state: Modules.AppState) => state.totalReaction,
  );

  return (
    <Grid container>
      {totalReaction &&
        CARD_ITEMS.map((item) => {
          if (!totalReaction[item.firstRankAccessor]) return null;

          return (
            <Grid item xs={3} key={item.title}>
              <DashboardReactionCardItem
                count={totalReaction[item.countAccessor]}
                icon={item.icon}
                content={totalReaction[item.firstRankAccessor]}
                rankLabel={item.rankLabel}
                title={item.title}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DashboardReactionCardArea;

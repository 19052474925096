import { AddTerm } from 'core/domain/settings';

const initialState = null;

export const createInitialState = (): AddTerm | null => initialState;

export const SET_ADD_TERM = 'add-term/set' as const;
export const CLEAR_ADD_TERM = 'add-term/clear' as const;

export const setAddTerm = (data: AddTerm) => ({
  type: SET_ADD_TERM,
  payload: {
    data,
  },
});

export const clearAddTerm = () => ({
  type: CLEAR_ADD_TERM,
});

type SetAddTermAction = ReturnType<typeof setAddTerm>;
type ClearAddTermAction = ReturnType<typeof clearAddTerm>;
type Action = SetAddTermAction | ClearAddTermAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_ADD_TERM:
      return action.payload.data;
    case CLEAR_ADD_TERM:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { ClipboardIcon } from 'assets/images'
import { IconButton, Tooltip } from 'components/atoms'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import CopyToClipBoard from "react-copy-to-clipboard"

/**
 * Interface
 */

export interface CopyClipboardButtonProps {
  text: string
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.gray[800],
      borderWidth: 1,
      borderStyle: 'solid',
      textAlign: 'center',
      height: '35px',
      width: '35px',
      padding: 0,
      '&:hover': {
        background: theme.palette.green[800],
        '& path':{
          stroke: "#FFFFFF"
        }
      },
    },
  }),
)

/**
 * Presenter
 */

export default function CopyClipboardButton(props: CopyClipboardButtonProps) {

  const classes = useStyles({})
  const { text } = props

  return (
    <Tooltip title="URLをクリップボードにコピーする" placement="top" arrow className={classes.root}>
      <CopyToClipBoard text={text}>
        <IconButton>
          <ClipboardIcon />
        </IconButton>
      </CopyToClipBoard>
    </Tooltip>
  )
}/* eslint-enable */

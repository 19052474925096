import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { SearchTextField } from "components/atoms";
import { 
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
  SALAD_BAR_DESKTOP_FONT_SIZE_75
} from "constants/typography";
import { SearchFilter, TrashesSearchCondition } from "core/domain/trashes";
import { useMemo } from "react";

/**
 * Interfase
 */
export interface TrashesSearchFieldProps {
  trashesSearchCondition: TrashesSearchCondition | null;
  handleCheckStatus: (status: number) => void;
  handleChangeFreeWord: (freeWord: string) => void;
  searchFilters: SearchFilter[] | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      '&>div:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.gray[800],
    },
    status: {
      marginLeft: `${theme.spacing(1) / 2}px`,
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    switch: {
      marginLeft: 'auto',
      display: 'flex',
    },
    switchDescription: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    marginRight: {
      marginRight: theme.spacing(3),
    },
  })
)

const TrashesSearchField = (props: TrashesSearchFieldProps) => {
  const classes = useStyles({});
  const {
    trashesSearchCondition,
    handleCheckStatus,
    handleChangeFreeWord,
    searchFilters,
  } = props;

  const searchCheck = useMemo(() => {
    if (!trashesSearchCondition || !trashesSearchCondition.data_type) return [];

    return trashesSearchCondition.data_type.split(',');
  }, [trashesSearchCondition])

  return (
    <Box className={classes.root}>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="caption">
          フリーワード検索
        </Typography>
        <Box className={classes.freeWord}>
          <SearchTextField
            label="検索ワードを入力"
            handleChangeText={handleChangeFreeWord}
            value={
              trashesSearchCondition?.free_word ?
              trashesSearchCondition.free_word :
              ''
            }
            maxLength={100}
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="overline">
          絞り込み
        </Typography>
        <Box className={classes.status}>
          <FormGroup row>
            {searchFilters && searchFilters.map((search, index) => (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={searchCheck.includes(`${search.dataType}`)}
                    onClick={() =>
                      handleCheckStatus && handleCheckStatus(search.dataType)
                    }
                  />
                }
                label={search.label}
                key={index}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
    </Box>
  )
};
export default TrashesSearchField;
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Inquiries } from 'core/domain/inquiries'

const initialState = null

export function createInitialState(): Inquiries | null {
  return initialState
}

export const SET_ALL_INQUIRIES = 'all-inquiries/set' as const
export const CLEAR_ALL_INQUIRIES = 'all-inquiries/clear' as const

export function setAllInquiries(data: Inquiries) {
  return {
    type: SET_ALL_INQUIRIES,
    payload: {
      data,
    },
  }
}

export function clearAllInquiries() {
  return {
    type: CLEAR_ALL_INQUIRIES,
  }
}

type SetAllInquiriesAction = ReturnType<typeof setAllInquiries>
type ClearAllInquiriesDetailAction = ReturnType<typeof clearAllInquiries>
type Action = SetAllInquiriesAction | ClearAllInquiriesDetailAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ALL_INQUIRIES:
      return action.payload.data
    case CLEAR_ALL_INQUIRIES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Redirect } from 'react-router-dom'
import { CenterAlignmentTemplate } from 'components/templates'

/**
 * Interface
 */

export interface CallbackPageProps {
  authenticated: boolean
  error: Error | string | undefined
}

export default function CallbackPage(props: CallbackPageProps) {
  if (props.error) {
    return <Redirect to="/login" push />
  }
  if (props.authenticated) {
    return <Redirect to="/adm/dashboard" push />
  }
 
    return <CenterAlignmentTemplate>Loading...</CenterAlignmentTemplate>
  
}
/* eslint-enable */

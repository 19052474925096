interface PulldownOptions {
  authority: {
    value: string;
    label: string;
  }[];
}

export const PULLDOWN_OPTIONS: PulldownOptions = {
  authority: [
    {
      value: '',
      label: '',
    },
    {
      value: 'lpAuthor',
      label: 'LP作成者',
    },
    {
      value: 'lpApprover',
      label: 'LP承認者',
    },
    {
      value: 'materialAuthor',
      label: '商材・事例作成者',
    },
    {
      value: 'materialApprover',
      label: '商材・事例承認者',
    },
    {
      value: 'tenantAdmin',
      label: '管理者',
    },
  ],
};

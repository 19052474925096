/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { useSelector } from 'react-redux'
import { Modules, Selectors } from 'core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CircularProgress, Backdrop } from 'components/atoms'

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      zIndex: theme.zIndex.drawer + 2,
    },
  }),
)

export default function LoadingPanel() {
  const classes = useStyles({})
  const needsToShow = useSelector((state: Modules.AppState) => Selectors.Network.isCommunicating(state))

  return (
    <Backdrop className={classes.root} open={needsToShow} >
      <CircularProgress size={64} />
    </Backdrop>
  )
}
/* eslint-enable */

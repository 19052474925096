/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from 'components/atoms'

/**
 * Interface
 */
export interface MandatoryLabelProps{
  margin? : string
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      fontSize: 11,
      color: theme.palette.pink[900],
      fontWeight: 'bold',
      margin: (props:MandatoryLabelProps) => props.margin
    },
  }),
)

/**
 * Presenter
 */

export default function MandatoryLabel(props:MandatoryLabelProps) {

  const classes = useStyles(props)

  return (
    <Typography className={classes.root} variant="caption" display="block">
      ＊必須
    </Typography>
  )

}/* eslint-enable */

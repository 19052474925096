import { NoticeDetail } from 'core/domain/notices';

const initialState = null;

export const SET_NOTICE = 'notice-detail/set' as const;
export const CLEAR_NOTICE = 'notice-detail/clear' as const;

export const createInitialState = (): NoticeDetail | null => initialState;

export const setNoticeDetail = (data: NoticeDetail) => ({
  type: SET_NOTICE,
  payload: {
    data,
  },
});

export const clearNoticeDetail = () => ({ type: CLEAR_NOTICE });
export const clearNoticeDetails = () => ({ type: CLEAR_NOTICE });

type SetNoticeDetailAction = ReturnType<typeof setNoticeDetail>;
type ClearNoticeDetailAction = ReturnType<typeof clearNoticeDetail>;
type Action = SetNoticeDetailAction | ClearNoticeDetailAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NOTICE:
      return action.payload.data;
    case CLEAR_NOTICE:
      return initialState;
    default:
      return state;
  }
};

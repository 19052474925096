/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Products } from 'core/domain/product'

const initialState = null

export function createInitialState(): Products | null {
  return initialState
}

export const SET_RELATION_PRODUCTS = 'relation-products/set' as const
export const CLEAR_RELATION_PRODUCTS = 'relation-products/clear' as const

export function setRelationProducts(data: Products) {
  return {
    type: SET_RELATION_PRODUCTS,
    payload: {
      data,
    },
  }
}

export function clearRelationProducts() {
  return {
    type: CLEAR_RELATION_PRODUCTS,
  }
}

type SetRelationProductsAction = ReturnType<typeof setRelationProducts>
type ClearRelationProductsAction = ReturnType<typeof clearRelationProducts>
type Action = SetRelationProductsAction | ClearRelationProductsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_RELATION_PRODUCTS:
      return action.payload.data
    case CLEAR_RELATION_PRODUCTS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

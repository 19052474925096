import { UserTags } from 'core/domain/user'

const initialState = null

export const createInitialState = (): UserTags | null => initialState

export const SET_USER_TAGS = 'user-tags/set' as const
export const CLEAR_USER_TAGS = 'user-tags/clear' as const

export const setUserTags = (data: UserTags) => ({
  type: SET_USER_TAGS,
  payload: {
    data,
  }
})

export const clearUserTags = () => ({
  type: CLEAR_USER_TAGS,
})

type SetUserTagsAction = ReturnType<typeof setUserTags>
type ClearUserTagsAction = ReturnType<typeof clearUserTags>
type Action = SetUserTagsAction | ClearUserTagsAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USER_TAGS:
      return action.payload.data
    case CLEAR_USER_TAGS:
      return initialState
    default:
      return state
  }
}

/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  CheckIcon,
  CloseWhiteIcon,
  CloseIcon,
  UploadBoxIcon,
  ErrorIcon,
} from 'assets/images';
import {
  LinearProgress,
  ListItem,
  Dialog,
  Box,
  PrimaryButton,
  IconButton,
  SecondaryButton,
  DialogActions,
  HeadlineLabel,
  Typography,
  List,
  Tooltip,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import {
  EXTENSION_GIF,
  EXTENSION_PNG,
  EXTENSION_JPEG,
  EXTENSION_JPG,
  EXTENSION_ICO,
  EXTENSION_MP4,
  EXTENSION_PDF,
  EXTENSION_CSV,
} from 'constants/mimeType';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';

export type OpenDialogType =
  | 'image'
  | 'favicon'
  | 'movie'
  | 'document'
  | 'csv'
  | 'hidden';
/**
 * Interface
 */
export interface FileUploadDialogProps {
  fileType: OpenDialogType;
  isProduct?: boolean;
  open: boolean;
  onClose: () => void;
  handleSubmit: (files: File[]) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(3),
      width: 632,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: 'max(-9%, -50px)',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    uploadBox: {
      position: 'relative',
      width: 632,
      marginTop: theme.spacing(3),
      textAlign: 'center',
      background: theme.palette.common.white,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      borderColor: theme.palette.gray[700],
      borderStyle: 'dashed',
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.gray[400],
    },
    dragOver: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.green[700],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      borderColor: theme.palette.green[800],
      boxShadow: 'none',
    },
    text: {
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
      lineHeight: '15px',
    },
    subText: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.gray[800],
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    helperText: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
    },
    fileCloseButton: {
      width: 20,
      height: 20,
      padding: 3,
    },
    listItem: {
      padding: 0,
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
    progress: {
      position: 'absolute',
      width: 130,
      height: 15,
      borderRadius: theme.spacing(2),
      right: theme.spacing(2),
      bottom: 70,
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#207EEB',
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.gray[600],
    },
    fileNameArea: {
      overflow: 'hidden',
    },
    fileNameList: {
      overflow: 'auto',
      height: 'calc(100vh - 550px)',
    },
    fileName: {
      width: 'calc(100% - 30px)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

/**
 * Presenter
 */

const FileUploadDialog = (props: FileUploadDialogProps) => {
  const { fileType, isProduct, open, onClose, handleSubmit } = props;
  const classes = useStyles({});

  const [mimeType, setMimeType] = useState('');
  const [maxFiles, setMaxFiles] = useState(0);
  const [noFileErrorMessage, setNoFileErrorMessage] = useState('');
  const [longFileNameErrorMessage, setLongFileNameErrorMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  // ファイル最大サイズ
  const [maxFileSize,setMaxFileSize] = useState(1024 * 1024 * 10);

  const onDropAccepted = useCallback((files: File[]) => {
    // プログレスバーを表示する処理
    setNoFileErrorMessage('');
    setLongFileNameErrorMessage('');
    setFiles(files);
    const reader = new FileReader();
    reader.onprogress = (e) => {
      if (e.lengthComputable) {
        const percent = (e.loaded * 100) / e.total;
        setProgress(percent);
      }
    };
    reader.onloadend = () => {
      const timer = setTimeout(() => {
        setProgress(0);
        clearTimeout(timer);
      }, 500);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDropAccepted,
      accept: mimeType,
      maxFiles,
      minSize: 1,
      // mp4,100MB,その他10Mb
      maxSize: maxFileSize,
    });

  useEffect(() => {

    setMaxFileSize(1024 * 1024 * 10);

    switch (fileType) {
      case 'image':
        setTitle('画像');
        setMimeType(
          `${EXTENSION_GIF},${EXTENSION_PNG},${EXTENSION_JPEG},${EXTENSION_JPG}`,
        );
        break;
      case 'favicon':
        setTitle('画像');
        setMimeType(
          `${EXTENSION_GIF},${EXTENSION_PNG},${EXTENSION_JPEG},${EXTENSION_JPG},${EXTENSION_ICO}`,
        );
        break;
      case 'movie':
        setTitle('動画');
        setMimeType(`${EXTENSION_MP4}`);
        // 動画最大100MB
        setMaxFileSize(1024 * 1024 * 100);
        break;
      case 'document':
        setTitle('資料');
        setMimeType(`${EXTENSION_PDF}`);
        break;
      case 'csv':
        setTitle('csv');
        setMimeType(`${EXTENSION_CSV}`);
        break;
      case 'hidden':
        break;
      default:
        break;
    }

    // 営業画像、導入事例は単独ファイルのみアップロード可
    // 商材の画像・動画は複数アップロード可
    if (isProduct) setMaxFiles(0);
    else setMaxFiles(1);
  }, [setMaxFiles, fileType, isProduct]);

  useEffect(() => {
    if (!open) {
      setNoFileErrorMessage('');
      setLongFileNameErrorMessage('');
      fileRejections.length = 0;
    }
  }, [open, fileRejections]);

  useEffect(() => {
    if (fileType === 'document' && files.length > 0) {
      if (files[0].name.length > 100) {
        setLongFileNameErrorMessage(
          'ファイル名が拡張子を含み100文字以内のファイルを選択してください。',
        );
        setFiles([]);
      }
    }
  }, [files, fileType]);

  const formatErrorMessage = useMemo(() => {
    if (!fileRejections || !fileRejections.length || !fileRejections[0])
      return '';
    let msg = '';
    fileRejections.map((file) => {
      file.errors.map((error) => {
        if (error.code === 'file-too-large' && fileType !== 'movie') {
          msg = 'サイズが10.0MB以内のファイルを選択してください。';
        } else if (error.code === 'file-too-large' && fileType === 'movie') {
          // mp4ファイルサイズ
          msg = 'サイズが100.0MB以内のファイルを選択してください。';
        } else if (error.code === 'file-too-small') {
          msg = '0Bのファイルはアップロードできません。';
        } else if (error.code === 'file-invalid-type') {
          if (fileType === 'image') {
            msg = 'gif、png、jpeg形式のファイルを選択してください。';
          } else if (fileType === 'favicon') {
            msg = `gif、png、jpeg、ico形式のファイルを選択してください。`;
          } else if (fileType === 'movie') {
            msg = `mp4形式のファイルを選択してください。`;
          } else if (fileType === 'document') {
            msg = `pdf形式のファイルを選択してください。`;
          } else if (fileType === 'csv') {
            msg = `csv形式のファイルを選択してください。`;
          }
        }
      });
    });

    return msg;
  }, [fileRejections, fileType]);

  const handleRemove = (name: string) => {
    const newFiles = files.filter((file) => file.name !== name);
    setFiles(newFiles);
  };

  const onClick = () => {
    setIsSubmitting((prev) => !prev);
    if (!files.length || !files[0]) {
      setNoFileErrorMessage('ファイルを選択してください。');

      return;
    }
    handleSubmit(files);
    setFiles([]);
    setNoFileErrorMessage('');
    setLongFileNameErrorMessage('');
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  const handleOnClose = () => {
    setFiles([]);
    onClose();
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [files]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={handleOnClose}
    >
      <Box>
        <HeadlineLabel text={`新規${title}をアップロード`} />
        <Box
          display="flex"
          {...getRootProps({ className: classes.uploadBox })}
          className={clsx(classes.uploadBox, isDragActive && classes.dragOver)}
        >
          <input {...getInputProps()} />
          <Box m="39px" width="325px" mx="auto">
            <UploadBoxIcon />
            <Typography variant="body2" className={classes.text}>
              ファイルをここにドラッグ＆ドロップしてください
            </Typography>
            <Typography variant="body2" className={classes.subText}>
              または
            </Typography>
            <Box width={130} height={36} mx="auto">
              <SecondaryButton text="ファイルを選択" />
            </Box>
          </Box>
          {progress > 0 && (
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={progress}
            />
          )}
        </Box>
        {/* 動画以外 */}
        {fileType !== 'movie' && 
        <Typography className={clsx(classes.subText, classes.helperText)}>
          添付ファイルのサイズは10.0MB以内でお願いします。
        </Typography>}
        {/* 動画 */}
        {fileType === 'movie' && 
        <Typography className={clsx(classes.subText, classes.helperText)}>
          添付ファイルのサイズは100.0MB以内でお願いします。
        </Typography>}
        <List className={classes.fileNameList}>
          {files.map((file, index) => (
            <ListItem key={index} className={classes.listItem}>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="spaceBetween"
                width="100%"
              >
                <Tooltip title={file.name} arrow placement="top">
                  <Typography variant="body2" className={classes.fileName}>
                    {file.name}
                  </Typography>
                </Tooltip>
                <IconButton
                  className={classes.fileCloseButton}
                  onClick={() => handleRemove(file.name)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
        {formatErrorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {formatErrorMessage}
            </Typography>
          </Box>
        )}
        {noFileErrorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {noFileErrorMessage}
            </Typography>
          </Box>
        )}
        {longFileNameErrorMessage && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>
              {longFileNameErrorMessage}
            </Typography>
          </Box>
        )}
      </Box>
      <DialogActions>
        <Box width={332} height={48}>
          <SecondaryButton
            click={handleOnClose}
            icon={<CloseIcon className={classes.closeIcon} />}
            text="キャンセル"
          />
        </Box>
        <Box width={332} height={48} mx="auto">
          <PrimaryButton
            disabled={isSubmitting}
            click={onClick}
            icon={<CheckIcon />}
            text="決定"
          />
        </Box>
        <IconButton onClick={handleOnClose} className={classes.closeButton}>
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
export default FileUploadDialog;

/* eslint-enable */

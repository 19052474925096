import { Box, Typography, OneLineTextField } from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_300 } from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface UserNotesFieldProps {
  notes: {
    note1: string;
    note2: string;
    note3: string;
  };
  handleNoteChange: (v: string, key: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
    },
    formArea: {
      marginTop: theme.spacing(3),
      '& > :nth-child(n)': {
        marginBottom: theme.spacing(2),
      },
      '& > :last-child': {
        marginBottom: 0,
      },
    },
  }),
);

const UserNotesFields = (props: UserNotesFieldProps) => {
  const { notes, handleNoteChange } = props;
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title} variant="caption">
        備考
      </Typography>
      <Box className={classes.formArea}>
        <OneLineTextField
          label="備考を入力"
          maxLength={100}
          value={notes.note1}
          handleChangeText={(v) => handleNoteChange(v, 'note1')}
        />
        <OneLineTextField
          label="備考を入力"
          maxLength={100}
          value={notes.note2}
          handleChangeText={(v) => handleNoteChange(v, 'note2')}
        />
        <OneLineTextField
          label="備考を入力"
          maxLength={100}
          value={notes.note3}
          handleChangeText={(v) => handleNoteChange(v, 'note3')}
        />
      </Box>
    </>
  );
};

export default UserNotesFields;

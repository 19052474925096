import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MultipleLinesTextField,
  SubHeadlineLabel,
  Typography,
} from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_200,
} from 'constants/typography';
import { ErrorIcon } from 'assets/images';
import { LpRelationProduct } from 'core/domain/lp';
import { InquiryProduct } from 'core/domain/inquiries';
import clsx from 'clsx';

interface InquiryInterestedThemeFieldsProps {
  defaultValue?: InquiryProduct[];
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (
    inquiryItemId: number,
    inquiryItemName: string,
    themeInfo: {
      id: string | null;
      name: string;
      detail?: string;
    },
  ) => void;
  onRemove: (
    inquiryItemId: number,
    inquiryItemName: string,
    themeName: string,
  ) => void;
  products: LpRelationProduct[] | InquiryProduct[] | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: '2px solid #ddd',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(3),
      '& > :first-child': {
        width: '19%',
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    titleText: {
      fontWeight: 'bold',
      color: '#7A7A7A',
    },
    labelText: {},
    formLabel: {
      display: 'block',
      marginBottom: theme.spacing(1),
      '& .MuiButtonBase-root': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_200,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkbox: {
      width: '19%',
      '& .MuiIconButton-label': {
        marginLeft: theme.spacing(3),
        justifyContent: 'flex-start',
      },
    },
    multiField: {
      '& > :first-child': {
        marginLeft: '20%',
        width: 520,
      },
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const InquiryInterestedThemeFields = (
  props: InquiryInterestedThemeFieldsProps,
) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
    onRemove,
    products,
  } = props;
  const classes = useStyles();
  const [checkedArray, setCheckedArray] = useState<string[]>([]);
  const [othersText, setOthersText] = useState<string>('');
  const [firstTime, setFirstTime] = useState(false);

  const handleChangeCheckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id?: string,
  ) => {
    if (checkedArray?.includes(e.target.value) ||
        (id && checkedArray?.includes(id))) {
      onRemove(inquiryItemId, '気になったテーマ', id?.toString() || '');
      setCheckedArray((prev) =>
        prev?.filter((item) => 
          (item !== e.target.value &&
            item !== id?.toString())),
      );

      return;
    }
    onChange(
      inquiryItemId,
      '気になったテーマ',
      id
        ? {
            id,
            name: e.target.value,
          }
        : {
            id: null,
            name: e.target.value,
            detail: othersText,
          },
    );
    if (e.target.value === 'その他') {
      setCheckedArray((prev) =>
        prev ? [...prev, e.target.value] : [e.target.value],
      );
    } else {
      setCheckedArray((prev) =>
        prev ? [...prev, id?.toString() || ''] : [id?.toString() || ''],
      );
    }
  };

  const handleChangeOthersText = (v: string) => {
    setOthersText(v);
    if (checkedArray.includes('その他')) {
      onChange(inquiryItemId, '気になったテーマ', {
        id: null,
        name: 'その他',
        detail: v,
      });
    }
  };

  useEffect(() => {
    if (!defaultValue || firstTime) return;
    setFirstTime(true);

    const value = defaultValue.find((item) => !item.productId);
    if (value) {
      setOthersText(value.productName || '');
    }

    const interested = defaultValue.filter(
      (item) => item.interested && item.productName,
    );
    if (interested.length === 0) return;
    setCheckedArray(
      (interested.length > 0 &&
        interested.map((item) => {
          if (item.productId === null) {
            return 'その他';
          }

          return item.productId.toString();
        })) ||
        [],
    );
  }, [checkedArray, defaultValue, firstTime, products]);

  // その他にチェックがある時trueを返す
  const isOtherCheckBoxChecked = useMemo(() => checkedArray?.includes('その他'), [checkedArray]);

  return (
    <Box>
      <Box
        className={`${classes.title} ${
          isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
        }`}
        display="flex"
        alignItems="center"
      >
        {isMandatory ? (
          <MandatoryHeadlineLabel title="気になったテーマ" />
        ) : (
          <SubHeadlineLabel text="気になったテーマ" />
        )}
        <Typography className={classes.titleText} variant="caption">
          取扱商材
        </Typography>
      </Box>
      {products?.map(
        (commodity: LpRelationProduct | InquiryProduct) =>
          commodity.productId !== null && (
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  className={classes.checkbox}
                  value={commodity.productName}
                  checked={checkedArray?.includes(commodity.productId.toString())}
                  disableRipple
                  onChange={(e) =>
                    handleChangeCheckBox(e, commodity.productId.toString())
                  }
                />
              }
              label={commodity.productName}
              key={commodity.productId}
            />
          ),
      )}
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Checkbox
            className={classes.checkbox}
            value="その他"
            disableRipple
            checked={checkedArray?.includes('その他')}
            onChange={(e) => handleChangeCheckBox(e)}
          />
        }
        label="その他"
      />
      <Box className={clsx(classes.multiField,{[classes.disabled]: !isOtherCheckBoxChecked})}>
        <MultipleLinesTextField
          error={
            error === 'その他を選択した場合は、詳細を入力してください' ||
            error === '500文字以内で入力してください。'
              ? error
              : undefined
          }
          label="その他を選択した場合はこちらに詳細をご入力ください"
          rows={7}
          value={othersText}
          handleChangeText={handleChangeOthersText}
          disabled={!isOtherCheckBoxChecked}
        />
      </Box>
      {error && error !== 'その他を選択した場合は、詳細を入力してください' && 
        error !== '500文字以内で入力してください。' && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default InquiryInterestedThemeFields;

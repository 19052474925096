import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Pagination, Box, TotalCountText } from 'components/atoms';

/**
 * Interface
 */

export interface PaginationBarProps {
  currentPage?: number;
  maxPage?: number;
  handleChangePage?: (page: number) => void;
  totalCount?: number;
}

/**
 * Presenter
 */

const useStyles = makeStyles((theme) =>
  createStyles({
    pagination: {
      '& .Mui-selected': {
        backgroundColor: 'transparent',
      },
      '& .Mui-selected .MuiTouchRipple-root': {
        width: 4,
        height: 4,
        backgroundColor: theme.palette.green[800],
        borderRadius: '50%',
        top: theme.spacing(3),
        left: 'auto',
        right: 'auto',
      },
      '& .MuiPaginationItem-icon': {
        fill: theme.palette.green[800],
        fontSize: '1.70rem',
      },
    },
  }),
);

const PaginationBar = (props: PaginationBarProps) => {
  const { currentPage, maxPage, handleChangePage, totalCount } = props;

  const handleChangeValue = (page: number) => {
    if (!handleChangePage) return;
    handleChangePage(page);
  };

  const classes = useStyles({});

  return (
    <Box
      ml="auto"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      height={30}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-around">
        <Pagination
          className={classes.pagination}
          size="small"
          count={maxPage !== 0 ? maxPage : 1}
          page={currentPage}
          defaultPage={1}
          boundaryCount={2}
          onChange={(event, page) => handleChangeValue(page)}
        />

        {totalCount !== undefined && <TotalCountText totalCount={totalCount} />}
      </Box>
    </Box>
  );
};
export default PaginationBar;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LpMainImage } from 'core/domain/settings'

const initialState = null

export function createInitialState(): Array<LpMainImage> | null {
  return initialState
}

export const SET_LP_MAIN_VISUAL_IMAGES = 'lp-main-visual-images/set' as const
export const CLEAR_LP_MAIN_VISUAL_IMAGES = 'lp-main-visual-images/clear' as const

export function setLpMainVisualImages(data: Array<LpMainImage>) {
  return {
    type: SET_LP_MAIN_VISUAL_IMAGES,
    payload: {
      data,
    },
  }
}

export function clearLpMainVisualImages() {
  return {
    type: CLEAR_LP_MAIN_VISUAL_IMAGES,
  }
}

type SetLpMainVisualImagesAction = ReturnType<typeof setLpMainVisualImages>
type ClearLpMainVisualImagesAction = ReturnType<typeof clearLpMainVisualImages>
type Action = SetLpMainVisualImagesAction | ClearLpMainVisualImagesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_MAIN_VISUAL_IMAGES:
      return action.payload.data
    case CLEAR_LP_MAIN_VISUAL_IMAGES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modules, Usecases } from 'core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomerIcon } from 'assets/images';
import { CustomerBox, HeaderDropMenu } from 'components/atoms';
import { hooks } from 'libs';
import { setInquiriesQueryParameter } from 'core/modules/inquiriesQueryParameter';

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    headerMenu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
    },
  }),
);

/**
 * Presenter
 */

export default function HeaderBarCustomer() {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { navigate } = hooks.useNavigate();
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const name = 'お客さま';
  const listButtonName = 'お問い合わせ一覧を見る';
  const disable = false;

  const allInquiries = useSelector(
    (state: Modules.AppState) => state.allInquiries,
  );
  const token = hooks.useAuth().accessToken;

  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.inquiries.sendGetInquiries(token, userInfo.tenantId));

    let timeoutId: NodeJS.Timeout;
    const handleTimeoutFunc = () => {
      dispatch(Usecases.inquiries.sendGetInquiries(token, userInfo.tenantId));
      timeoutId = setTimeout(() => {
        handleTimeoutFunc();
      }, 60000);
    };

    handleTimeoutFunc();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userInfo]);

  const filterdInquiries = useMemo(() => {
    if (!allInquiries) return [];

    return allInquiries.inquiries.filter((inquiry) => inquiry.unread);
  }, [allInquiries]);

  const unreadCount = useMemo(() => {
    if (!allInquiries) return 0;

    return allInquiries.inquiries.filter((inquiry) => inquiry.unread).length;
  }, [allInquiries]);

  const filteredItem = React.memo((
    { index, style }:
    { index: number, style: React.CSSProperties | undefined }
  ) => (
    <div style={style}>
      <CustomerBox inquiry={filterdInquiries[index]} key={index} />
    </div>
  ));

  const handleClickListHeaderButton = () => {
    // 「自分のみ表示」と「未読のみ表示」の両方をON
    dispatch(
      setInquiriesQueryParameter({
        page: 1,
        row: 10,
        unread: true,
        own: true,
      }),
    );

    navigate('/adm/inquiries');
  };

  return (
    <div className={classes.headerMenu}>
      <HeaderDropMenu
        name={name}
        logo={<CustomerIcon />}
        listButtonName={listButtonName}
        unreadCount={unreadCount}
        disable={disable}
        handleClickListHeaderButton={handleClickListHeaderButton}
        filteredItem={filteredItem}
        filteredItemLength={filterdInquiries.length}
      />
    </div>
  );
}
/* eslint-enable */

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  SearchTextField,
  CommonSwitch,
} from 'components/atoms';
// import clsx from 'clsx';

import {
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { NoticesQueryParameter } from 'core/domain/notices';

/**
 * Interface
 */

export interface NoticesSearchFieldProps {
  switchLabel: string;
  switchValue: boolean | null;
  handleChangeFreeWord?: (freeWord: string) => void;
  noticesQueryParameter: NoticesQueryParameter | null;
  handleChangeUnreadOnlySwitch?: (checked: boolean) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    freeWord: {
      width: 245,
    },
    switch: {
      marginLeft: 'auto',
      display: 'flex',
    },
    switchDescription: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.gray[800],
    },
  }),
);

const NoticesSearchField = (props: NoticesSearchFieldProps) => {
  const classes = useStyles({});
  const {
    switchLabel,
    handleChangeFreeWord,
    noticesQueryParameter,
    handleChangeUnreadOnlySwitch,
    switchValue,
  } = props;

  return (
    <Box>
      <Box className={classes.section}>
        <Typography className={classes.title} variant="caption">
          フリーワード検索
        </Typography>
        <Box className={classes.freeWord}>
          <SearchTextField
            label="フリーワード検索"
            handleChangeText={handleChangeFreeWord}
            value={
              noticesQueryParameter?.free_word
                ? noticesQueryParameter.free_word
                : ''
            }
          />
        </Box>
        <Typography className={classes.description} variant="caption">
          登録されている情報全体からフリーワードで検索します。
        </Typography>

        {switchLabel && (
          <Box className={classes.switch}>
            <CommonSwitch
              checked={switchValue ?? false}
              value={switchValue ?? false}
              handleChangeSwitch={handleChangeUnreadOnlySwitch}
            />
            <Typography className={classes.switchDescription} variant="caption">
              {switchLabel}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NoticesSearchField;

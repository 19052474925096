import { Users } from "core/domain/user";

const initialState:Users = {
    "users": [
        ],
        "totalCount": 0
}

export const createInitialState = ():Users => initialState

export const SET_USERS = 'users/set' as const;
export const CLEAR_USERS = 'users/clear' as const

export const setUsers = (data:Users) => ({
    type: SET_USERS,
    payload: {
        data
    }
})

export const clearUsers = () => ({
    type: CLEAR_USERS
})

type SetUsersAction = ReturnType<typeof setUsers>
type ClearUsersAction = ReturnType<typeof clearUsers>
type Action = SetUsersAction | ClearUsersAction

export const reducer = (state = createInitialState(), action: Action) => {
    switch (action.type) {
        case SET_USERS:
            return action.payload.data
        case CLEAR_USERS:
            return initialState
        default:
            return state
    }
}
import React, { useCallback } from 'react';
import { Box, HeadlineLabel, SelectBox } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { VariousSettings } from 'core/domain/variousSettings';

const ADD_TERM_OPTIONS = [
  {
    value: 1,
    label: '１週間',
  },
  {
    value: 2,
    label: '２週間',
  },
  {
    value: 3,
    label: '１ヶ月',
  },
  {
    value: 4,
    label: '３ヶ月',
  },
  {
    value: 5,
    label: '６ヶ月',
  },
  {
    value: 6,
    label: '１年',
  },
  {
    value: 7,
    label: '２年',
  },
  {
    value: 8,
    label: '３年',
  },
  {
    value: 99,
    label: '全期間',
  },
];

interface VariousSettingsRankingAreaProps {
  error?: string | undefined;
  handleInputChange: (v: number, key: keyof VariousSettings) => void;
  isInit: boolean;
  newVariousSettings: VariousSettings | null;
}

const VariousSettingsRankingArea = (props: VariousSettingsRankingAreaProps) => {
  const { error, handleInputChange, isInit, newVariousSettings } = props;

  const handleChange = useCallback(
    (v: string) => {
      handleInputChange(+v, 'defaultAddTerm');
    },
    [handleInputChange],
  );

  return (
    <Box>
      <HeadlineLabel text="ランキング設定" />
      <MandatoryHeadlineLabel title="デフォルト集計期間" />
      {/* 更新時のセレクトボックス（初期値あり） */}
      {!isInit && newVariousSettings ? (
        <SelectBox
          label="デフォルト集計期間"
          options={ADD_TERM_OPTIONS}
          defaultValue={newVariousSettings.defaultAddTerm || 4}
          handleChange={handleChange}
          error={error}
        />
      ) : null}
      {/* 初期登録時のセレクトボックス（初期値なし） */}
      {isInit ? (
        <SelectBox
          label="デフォルト集計期間"
          options={ADD_TERM_OPTIONS}
          defaultValue={4}
          handleChange={handleChange}
          error={error}
        />
      ) : null}
    </Box>
  );
};

export default VariousSettingsRankingArea;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Button } from 'components/atoms'

/**
 * Interface
 */
export interface SecondaryButtonProps {
  text: string,
  icon?: React.ReactNode
  disabled?: boolean
  click?: ()=>void
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      background: theme.palette.text.secondary,
      color: theme.palette.green[900],
      padding: 0,
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
      boxShadow: 'none',
      borderColor: theme.palette.green[900],
      borderStyle: 'solid',
      borderWidth: '1px',
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        boxShadow: 'none'
      },
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
)

/**
 * Presenter
 */

export default function SecondaryButton(props:SecondaryButtonProps) {

  const classes = useStyles({})
  const { text, icon, click, disabled } = props

  return (
    <Button
      variant="contained"
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
      })}
      startIcon={icon}
      onClick={!disabled ? click : undefined}
    >
      {text}
    </Button>
  )
}/* eslint-enable */

import { useEffect } from 'react';
import NoticesDetail from 'components/pages/NoticesDetail';
import { Modules, Usecases } from 'core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { hooks } from 'libs';
import { clearNoticesDetail } from 'core/modules/noticesDetail';
import { useParams } from 'react-router-dom';
/**
 * Presenter
 */

const NoticesDetailContainer = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  // お知らせ詳細のデータ取ってくる
  const noticesDetail = useSelector(
    (state: Modules.AppState) => state.noticesDetail,
    shallowEqual,
  );
  // URLで指定したお知らせのnoticeIdをこれにいれる
  const { noticeId } = useParams<{ noticeId: string }>();

  // お知らせ詳細取得ＡＰＩへ
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.notices.sendGetNoticesDetail(
        token,
        userInfo.tenantId,
        Number.parseInt(noticeId, 10),
      ),
    );

    dispatch(clearNoticesDetail());
  }, [userInfo, token, noticeId, dispatch]);

  // ヘッダーバーのお知らせ未読件数を更新
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.notices.sendGetHeaderNotices(token, userInfo.tenantId));
  }, [userInfo, token, noticeId, dispatch, noticesDetail]);

  //  お知らせ詳細
  return noticesDetail && <NoticesDetail noticesDetail={noticesDetail} />;
};

export default NoticesDetailContainer;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  SecondaryButton,
  PrimaryButton,
  Typography,
} from 'components/atoms';
import { DateTimePicker } from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import { RelaseLpIcon, CloseIcon } from 'assets/images';
import { validDate, checkPastDay } from 'libs/date';
import {
  DATE_NOT_ENTERED,
  INVALID_DATE,
  LP_OPEN_PERIOD_CAUNTION,
  TIME_NOT_ENTERED,
  PAST_DATE_SELECTED,
} from 'constants/text';

/**
 * Interface
 */

export interface LpOpenDialogProps {
  open: boolean;
  date?: string;
  time?: string;
  handleCancel: () => void;
  handleSubmit: (date?: string, time?: string) => void;
  isRelease?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 1806,
      minHeight: 226,
      padding: '32px 24px 24px 24px',
      margin: 0,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    openPeriod: {
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      // marginTop: theme.spacing(1),
      padding: 0,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      },
    },
    cauntion: {
      color: theme.palette.red[900],
      marginTop: theme.spacing(3),
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
  }),
);

export default function LpOpenDialog(props: LpOpenDialogProps) {
  const classes = useStyles({});
  const { open, date, time, handleCancel, handleSubmit, isRelease } = props;
  const [openPeriodDate, setOpenPeriodDate] = useState<string | undefined>(
    date,
  );
  const [openPeriodTime, setOpenPeriodTime] = useState<string | undefined>(
    time,
  );
  const [openPeriodDateValidationError, setOpenPeriodDateValidationError] =
    useState<string | undefined>(undefined);
  const [openPeriodTimeValidationError, setOpenPeriodTimeValidationError] =
    useState<string | undefined>(undefined);

  const handleChangeOpenPeriodDate = (date?: string) => {
    setOpenPeriodDate(date);
  };

  const handleChangeOpenPeriodTime = (time?: string) => {
    setOpenPeriodTime(time);
  };

  const handleSubmitOpenPeriod = () => {
    if (!openPeriodDate && openPeriodTime) {
      setOpenPeriodDateValidationError(DATE_NOT_ENTERED);

      return;
    }

    if (openPeriodDate && !openPeriodTime) {
      setOpenPeriodTimeValidationError(TIME_NOT_ENTERED);

      return;
    }

    if (openPeriodDate && !validDate(openPeriodDate.replaceAll('/', ''))) {
      setOpenPeriodDateValidationError(INVALID_DATE);

      return;
    }
    if (openPeriodDate && openPeriodTime && checkPastDay(openPeriodDate.replaceAll('/', ''),openPeriodTime.split(':').join(''))) {
      setOpenPeriodDateValidationError(PAST_DATE_SELECTED);

      return;
    }

    setOpenPeriodDateValidationError(undefined);
    handleSubmit(openPeriodDate, openPeriodTime);
  };
  const buttonText=(val?:boolean)=>{
    if(val)return '決定する'

    return '公開する'
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box>
        <Typography variant="body2" className={classes.title}>
        {(date && time) || isRelease
            ? ''
            : 'ランディングページを公開します。よろしいですか？'}
        </Typography>
        <Box className={classes.openPeriod}>
          <DateTimePicker
            dateError={openPeriodDateValidationError}
            timeError={openPeriodTimeValidationError}
            subtitle="公開終了日時"
            width="332px"
            date={openPeriodDate}
            time={openPeriodTime}
            handleChangeDate={(date: string) =>
              handleChangeOpenPeriodDate(date)
            }
            handleChangeTime={(time: string) =>
              handleChangeOpenPeriodTime(time)
            }
          />
        </Box>
        <Typography variant="body2" className={classes.cauntion}>
          {LP_OPEN_PERIOD_CAUNTION}
        </Typography>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton
                icon={<CloseIcon className={classes.closeIcon} />}
                click={() => {
                  date ? setOpenPeriodDate(date) : setOpenPeriodDate(undefined);
                  time ? setOpenPeriodTime(time) : setOpenPeriodTime(undefined);
                  setOpenPeriodDateValidationError(undefined);
                  setOpenPeriodTimeValidationError(undefined);
                  handleCancel();
                }}
                text="キャンセル"
              />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton
                icon={<RelaseLpIcon />}
                click={() => {
                  setOpenPeriodDateValidationError(undefined);
                  setOpenPeriodTimeValidationError(undefined);
                  handleSubmitOpenPeriod();
                }}
                text={buttonText(isRelease)}
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
/* eslint-enable */

import { Typography, Box, ButtonLink, Grid, Divider } from 'components/atoms';
import { Content } from 'components/organisms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { ArrowLeftIcon } from 'assets/images';
import { formatDateYYYYMMDD, formateTimeHHMMColon } from 'libs/date';
import { NoticesDetail, NoticesFiles } from 'core/domain/notices';
import { SALAD_BAR_PINK_800, SALAD_BAR_GREEN_700 } from 'constants/color';
import { downloadFileFromUrl } from 'libs/downloader';
import React, { useMemo, useState } from 'react';

/**
 * Presenter
 */

/*
【枠外】
・リンク：一覧に戻る
・テキスト：お知らせ
【枠内】
・お知らせタイトル
・登録日：yyyymmdd
・アイコン
・添付ファイル
・本文・リンク

*/

export interface NoticeProps {
  noticesDetail: NoticesDetail;
}
// 本文内のURLをリンク化する
export const getUrlInText = (text: string): string[] => {
  const matches = text.match(/((URL?：[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g);

  return matches || [];
};
export const UrlTextToComponent: React.FC<{ text: string }> = ({ text }) =>
  useMemo(() => {
    const urls = getUrlInText(text);
    let texts: (string | JSX.Element | undefined)[] = [text];
    urls.forEach((url) => {
      const linkUrl = url.split('').splice(4).join('').toString(); // 文字列”URL:”除去
      const target = texts[texts.length - 1]; // 一番最後をとる
      if (typeof target !== 'string') return;
      const split = target.split(url);
      const a: (JSX.Element | string | undefined)[] = [
        split.shift(),
        split.join(url),
      ];
      // eslint-disable-next-line prefer-destructuring
      a[2] = a[1];

      a[1] = (
        <a rel="noreferrer" href={linkUrl}>
          {url}
        </a>
      );
      texts.length -= 1;
      texts = [...texts, ...a];
    });

    return (
      <>
        {texts.map((txt, i) => (
          <React.Fragment key={i}>{txt}</React.Fragment>
        ))}
      </>
    );
  }, [text]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '14px',
      position: 'relative',
      wordWrap: 'break-word',
    },
    body: {
      fontSize: '14px',
      position: 'relative',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    buttonContainer: {
      height: '54px',
      fontSize: '18px',
      marginTop: 28,
    },
    button: {
      height: '48px',
      width: '332px',
      margin: '40px auto',
    },
    link: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      color: theme.palette.green[800],
      cursor: 'pointer',
    },
    goBackLink: {
      marginBottom: theme.spacing(2),
    },
    box: {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
    },
    container: {
      margin: '16px 0px',
    },
    titleDivider: {
      height: 2,
    },
    subtitle: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      color: theme.palette.gray[800],
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: 20,
    },
    description: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
    },
    usePeriod: {
      margin: '0px 16px',
    },
    btnRed: {
      color: 'white',
      backgroundColor: SALAD_BAR_PINK_800,
      maxHeight: '24px',
      width: '56px',
      borderRadius: '3px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 24,
      paddingTop: 4,
      paddingBottom: 4,
    },
    buttonDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    btnGreen: {
      backgroundColor: SALAD_BAR_GREEN_700,
      maxHeight: '24px',
      width: '180px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 24,
      paddingTop: 4,
      paddingBottom: 4,
    },
  }),
);

const NoticeDetails = (props: NoticeProps) => {
  const classes = useStyles({});
  const navigate = hooks.useNavigate();
  const { noticesDetail } = props;
  const { noticeFiles } = noticesDetail;

  // 添付ファイルDL制御
  const [noticeFileDisabled, setNoticeFileDisabled] = useState<number | null>(null);

  return (
    <Box mt={5}>
      {/* 一覧に戻るボタン */}
      <Box
        className={classes.goBackLink}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        <ButtonLink
          icon={<ArrowLeftIcon />}
          iconPosition="start"
          text="一覧へ戻る"
          click={() => {
            navigate.navigate('/adm/notices');
          }}
        />
      </Box>

      <Content title="お知らせ詳細画面">
        <Typography variant="h3" component="h3">
          {noticesDetail?.subject}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              <span>
                掲載開始日時：{` `}
                {formatDateYYYYMMDD(noticesDetail?.publishStartDate)}
                {` `}
              </span>
              <span className={classes.usePeriod}>
                {formateTimeHHMMColon(noticesDetail?.publishStartTime)}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              <span>
                掲載終了日時：{` `}
                {formatDateYYYYMMDD(noticesDetail?.publishEndDate)}
                {` `}
              </span>
              <span className={classes.usePeriod}>
                {formateTimeHHMMColon(noticesDetail?.publishEndTime)}
              </span>
            </Typography>
          </Grid>
          <Box className={classes.buttonDiv}>
            {/* 重要のアイコン表示非表示 */}
            {noticesDetail?.noticeType === 1 && (
              <Box className={classes.btnRed}>重要</Box>
            )}
            {noticesDetail?.noticeType === 3 && (
              <Box className={classes.btnGreen}>サービスからのお知らせ</Box>
            )}
          </Box>
          <Divider className={classes.titleDivider} />
          <Grid
            container
            spacing={0}
            justify="center"
            className={classes.container}
          >
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                添付ファイル
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex">
                <Typography
                  variant="body2"
                  className={classes.description}
                  display="inline"
                >
                  {noticeFiles.map((doc: NoticesFiles, index: number) => (
                    <ButtonLink
                      click={async () => {
                        setNoticeFileDisabled(index);
                        await downloadFileFromUrl(doc.url, doc.noticeFileName);
                        setTimeout(() => setNoticeFileDisabled(null), 1000);
                      }}
                      tooltipText={doc.noticeFileName}
                      text={doc.noticeFileName}
                      iconPosition="end"
                      key={index}
                      disabled={noticeFileDisabled === index}
                    />
                  ))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            spacing={0}
            justify="center"
            className={classes.container}
          >
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                display="inline"
              >
                本文
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                className={classes.description}
                display="inline"
              >
                {/* message本文とリンクURL */}
                <UrlTextToComponent text={noticesDetail.content} />
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </Typography>
      </Content>
    </Box>
  );
};
export default NoticeDetails;

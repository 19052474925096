import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Modules } from 'core';

import {
  Tabs,
  Tab,
  Box,
  HeadlineLabel,
  OneLineTextField,
  ButtonLink,
  LetterCountLabel,
  NormalEditTopButton,
  SupportTextLabel,
  Grid,
  Typography,
  MultipleLinesTextField,
  SubHeadlineLabel,
  FormControl,
  RadioGroup,
  CommonRadioButton,
  PasswordTextField,
} from 'components/atoms';
import { MandatoryHeadlineLabel, PreviewButton } from 'components/molecules';
import { ErrorIcon, NoImagePortrait } from 'assets/images';
import {
  ConfirmDialog,
  DateTimePicker,
  NormalEditCustomerNameDialog,
  TagSelectDialog,
} from 'components/organisms';
import { TYPOGRAPHY } from 'constants/index';
import {
  LpAvailableRequest,
  LPRegisterRequest,
  LpView,
  Tokuisaki,
  LpTags,
} from 'core/domain/lp';
import { setNewLp } from 'core/modules/newLp';
import { formateTimeHHMMColon } from 'libs/date';
import { TagsRequest } from 'core/domain/tag';

import clsx from 'clsx';
import { NormalEditErrors } from 'core/domain/validationErrors';
import { setNormalEditErrors } from 'core/modules/normalEditErrors';
import { User } from 'core/domain/user';
import { DesignTemplate } from 'core/domain/settings';
import { useRouteMatch } from 'react-router';
import { hooks } from 'libs';
import { LP_OPEN_PERIOD_CAUNTION } from 'constants/text';
import { MyLibrary } from 'core/domain/myLibrary';

/**
 * Interface
 */
export interface LpsettingProps {
  newLp: LPRegisterRequest | null;
  lpTokuisaki: Tokuisaki | null;
  tokuisakiParam: string;
  setTokuisakiParam: React.Dispatch<React.SetStateAction<string>>;
  userDisplayName: string | undefined;
  tags: LpTags | null;
  tagsRequest: TagsRequest;
  normalEditErrors: NormalEditErrors | null;
  previewData?: LpView;
  userInfo: User | null;
  designTemplates: Array<DesignTemplate> | null;
  myLibrary?: MyLibrary | null;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonLink: {
      marginTop: theme.spacing(3),
    },
    design: {
      borderColor: theme.palette.gray[600],
      borderWidth: 1,
      borderRadius: 16,
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      width: 284,
      height: 340,
      padding: theme.spacing(3),
      '&:hover': {
        borderColor: theme.palette.green[800],
        borderWidth: 2,
        backgroundColor: theme.palette.green[700],
        cursor: 'pointer',
        '& p': {
          color: theme.palette.green[900],
        },
      },
    },
    tabs: {
      borderBottomColor: theme.palette.gray[600],
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      marginTop: theme.spacing(5),
    },
    tab: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    errorIcon: {
      marginRight: theme.spacing(1),
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    designGrid: {
      marginTop: 25,
      overflow: 'hidden',
      overflowX: 'auto',
      height: 408,
      flexWrap: 'nowrap',
    },
    designTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    preview: {
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    currentDesign: {
      borderColor: theme.palette.green[800],
      borderWidth: 2,
      backgroundColor: theme.palette.green[700],
      color: theme.palette.green[900],
    },
    templateThumbnail: {
      width: 162,
      height: 245,
    },
    cauntion: {
      whiteSpace: 'pre-wrap',
      color: theme.palette.red[900],
      marginTop: theme.spacing(6),
      paddingLeft: theme.spacing(2),
      // borderColor: theme.palette.red[900],
      // borderWidth: 4,
      // borderStyle: 'solid',
      paddingTop: 1,
      paddingBottom: 1,
    },
    description: {
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    mailTextName: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_200,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
    },
    passwordDescription: {
      color: theme.palette.gray[800],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
  }),
);

interface Display {
  key: string;
  name: string;
}
const citeDisplayContents: Display[] = [
  { key: 'pageTitle', name: 'ランディングページのタブに表示されるタイトル' },
  { key: 'tokuisakiName', name: '表示用得意先名' },
  { key: 'tantoName', name: '表示用送信者名' },
];

const tabs = ['サイト表示内容', '管理用情報', 'ベースデザイン選択', '公開設定'];

/**
 * Presenter
 */
const NormalEditLPSetting = (props: LpsettingProps) => {
  const classes = useStyles({});
  const { url } = useRouteMatch();
  const navigate = hooks.useNavigate();
  const [value, setValue] = useState(0);
  const [openCustomerNameDialog, setOpenCustomerNameDialog] = useState(false);
  const [openTagSelectDialog, setOpenTagSelectDialog] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const designTemplates = useSelector(
    (state: Modules.AppState) => state.designTemplates,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const {
    newLp,
    lpTokuisaki,
    tokuisakiParam,
    setTokuisakiParam,
    tags,
    normalEditErrors,
    previewData,
    myLibrary,
  } = props;

  // ラジオボタンパスワード設定
  const [passwordSet, setPasswordSetting] = useState('1');

  // パスワードtextボックス活性非活性
  const [passwordTextBox, setpasswordTextBox] = useState(true);

  // ラジオボタンの値をセット
  const handleChangePasswordSetting = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value !== newLp?.passwordSetting?.toString()) {
      setPasswordSetting(event.target.value);
    }
  };

  // 画面表示とnewLpのpassword設定を合わせる
  useEffect(() => {
    if (
      passwordSet !== newLp?.passwordSetting?.toString() &&
      newLp?.passwordSetting?.toString()
    ) {
      setPasswordSetting(newLp?.passwordSetting?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLp?.passwordSetting]);

  useEffect(() => {
    if (designTemplates) {
      dispatch(
        setNewLp({
          ...newLp,
          designTemplateId: newLp?.designTemplateId
            ? newLp?.designTemplateId
            : designTemplates[0].designTemplateId,
        }),
      );
    } else {
      dispatch(
        setNewLp({
          ...newLp,
          designTemplateId: newLp?.designTemplateId
            ? newLp?.designTemplateId
            : 1,
        }),
      );
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [designTemplates]);

  // パスワードエラー判定
  useEffect(() => {
    if (newLp?.passwordSetting?.toString() === '1') {
      dispatch(
        setNormalEditErrors({
          ...normalEditErrors,
          password: undefined,
        }),
      );
    } else if (
      (newLp?.passwordSetting?.toString() === '2' ||
        newLp?.passwordSetting?.toString() === '3') &&
      newLp?.password &&
      newLp?.password?.length <= 100 &&
      newLp?.password?.length >= 8 &&
      /^[0-9a-zA-Z]+$/.test(newLp?.password) === true
    ) {
      dispatch(
        setNormalEditErrors({
          ...normalEditErrors,
          password: undefined,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLp?.passwordSetting, newLp?.password]);

  // パスワードのテキストボックス活性非活性
  useEffect(() => {
    if (passwordSet === '1') {
      setpasswordTextBox(true);
    } else {
      setpasswordTextBox(false);
    }
    dispatch(
      setNewLp({
        ...newLp,
        passwordSetting: Number.parseInt(passwordSet, 10),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSet]);

  // マイライブラリからコピーボタン活性非活性
  const [copyFromMyLibraryDisabled, setcopyFromMyLibraryDisabled] =
    useState(false);

  // マイライブラリ上書き確認ダイアログ
  const [openSubjectDialog, setOpenSubjectDialog] = useState(false);

  // マイライブラリからコピーする
  const subjectCopy = () => {
    dispatch(
      setNewLp({
        ...newLp,
        mailSubject: myLibrary?.mailSubject,
        mailText: myLibrary?.mailText,
        mailSignature: myLibrary?.mailSignature,
      }),
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        greetText: undefined,
      }),
    );
  };

  // マイライブラリのsubjectで上書きするか否か
  const subjectCopyFromMyLibrary = (
    subjectValue?: string,
    mailValue?: string,
    signatureValue?: string,
  ) => {
    if (subjectValue || mailValue || signatureValue) {
      // 空でない項目ある場合上書き時にダイアログ表示
      setOpenSubjectDialog(true);
    } else {
      subjectCopy();
    }
  };

  // マイライブラリからコピーボタン活性非活性処理
  useEffect(() => {
    if (
      myLibrary?.mailSubject ||
      myLibrary?.mailText ||
      myLibrary?.mailSignature
    ) {
      setcopyFromMyLibraryDisabled(false);
    } else {
      setcopyFromMyLibraryDisabled(true);
    }
  }, [myLibrary]);

  useEffect(() => {
    if (!newLp || !newLp.lpTag) return;
    const tmpTags = newLp.lpTag.split(',');
    setSelectedTags([...tmpTags]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTagSelectDialog]);

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const handleSelectedTagChange = (value: string) => {
    const tags = value.split(',');
    setSelectedTags([...tags]);
  };

  const handleTextChange = (key: string, value: string) => {
    dispatch(
      setNewLp({
        ...newLp,
        [key]: value,
      }),
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        [key]: undefined,
      }),
    );
    if (key === 'lpTag') {
      handleSelectedTagChange(value);
    }
  };

  const citeDisplayContentValue = (key: string) => {
    switch (key) {
      case 'pageTitle':
        return newLp?.pageTitle;
      case 'tokuisakiName':
        return newLp?.tokuisakiName;
      case 'tantoName':
        return newLp?.tantoName;
      default:
        return '';
    }
  };

  const createCurrentCount = (key: string) => {
    switch (key) {
      case 'pageTitle':
        return newLp?.pageTitle?.length
          ? newLp?.pageTitle?.length.toString()
          : '0';
      case 'tokuisakiName':
        return newLp?.tokuisakiName?.length
          ? newLp?.tokuisakiName?.length.toString()
          : '0';
      case 'tantoName':
        return newLp?.tantoName?.length
          ? newLp?.tantoName?.length.toString()
          : '0';
      default:
        return '0';
    }
  };

  const handleChangeDate = (date: string) => {
    const period = {
      ...newLp?.openPeriod,
      openPeriodDate: date.replaceAll('/', ''),
    } as LpAvailableRequest;
    dispatch(
      setNewLp({
        ...newLp,
        openPeriod: period,
      }),
    );

    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        openPeriodDate: undefined,
        openPeriodTime: !date ? undefined : normalEditErrors?.openPeriodTime,
      }),
    );
  };

  const handleChangeTime = (time: string) => {
    const period = {
      ...newLp?.openPeriod,
      openPeriodTime: time.replace(':', ''),
    };
    dispatch(
      setNewLp({
        ...newLp,
        openPeriod: period,
      }),
    );
    dispatch(
      setNormalEditErrors({
        ...normalEditErrors,
        openPeriodTime: undefined,
        openPeriodDate: !time ? undefined : normalEditErrors?.openPeriodDate,
      }),
    );
  };

  const handleSubmitTags = () => {
    if (selectedTags[0] === '') {
      selectedTags.splice(0, 1);
    }
    dispatch(
      setNewLp({
        ...newLp,
        lpTag: selectedTags.join(','),
      }),
    );
    setOpenTagSelectDialog(false);
  };

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag].filter((tag) => !!tag));
  };

  const selectDesignTemplate = (templateId: number) => {
    dispatch(
      setNewLp({
        ...newLp,
        designTemplateId: templateId,
      }),
    );
  };

  const citeDisplayErrors = (key: string) => {
    switch (key) {
      case 'pageTitle':
        return normalEditErrors?.pageTitle;
      case 'tokuisakiName':
        return normalEditErrors?.tokuisakiName;
      case 'tantoName':
        return normalEditErrors?.tantoName;
      default:
        return '';
    }
  };

  const goBack = useMemo(() => {
    const paths = url.split('/');
    paths.pop();

    if (newLp?.products && newLp.products[newLp?.products?.length - 1]) {
      const id = newLp.products[newLp?.products?.length - 1].productId;

      return (
        <NormalEditTopButton
          click={() =>
            navigate.navigate(`${paths.join('/')}/product/${id}/edit`)
          }
          text={newLp.products[newLp?.products?.length - 1].productName}
        />
      );
    }

    return (
      <NormalEditTopButton
        click={() => navigate.navigate(`${paths.join('/')}/greeting`)}
        text="挨拶の編集"
      />
    );
  }, [navigate, newLp, url]);

  const displayErrorIcon = (index: number): boolean => {
    let hasError: boolean;
    switch (index) {
      case 0:
        hasError =
          !!normalEditErrors?.pageTitle ||
          !!normalEditErrors?.tokuisakiName ||
          !!normalEditErrors?.tantoName;

        return hasError;
      case 1:
        hasError = !!normalEditErrors?.title || !!normalEditErrors?.lpTag;

        return hasError;
      case 3:
        hasError =
          !!normalEditErrors?.mailSubject ||
          !!normalEditErrors?.mailText ||
          !!normalEditErrors?.password ||
          !!normalEditErrors?.mailSignature ||
          !!normalEditErrors?.openPeriodDate ||
          !!normalEditErrors?.openPeriodTime;

        return hasError;
      default:
        return false;
    }
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  return (
    <div>
      <Tabs
        className={classes.tabs}
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleTabChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={classes.tab}
            label={tab}
            icon={
              displayErrorIcon(index) ? (
                <ErrorIcon className={classes.errorIcon} />
              ) : undefined
            }
          />
        ))}
      </Tabs>
      {/* サイト表示内容 */}
      <Box hidden={value !== 0} width={696} height={366} mt={4} mx="auto">
        <HeadlineLabel text={tabs[0]} />
        {citeDisplayContents.map((content, index) => (
          <Box key={index}>
            <Box display="flex">
              <Box flexGrow={1}>
                <MandatoryHeadlineLabel title={content.name} />
              </Box>
              <Box
                hidden={content.name !== '表示用得意先名'}
                className={classes.buttonLink}
              >
                <ButtonLink
                  click={() => setOpenCustomerNameDialog(true)}
                  fontSize="13px"
                  text="リストから選択"
                />
              </Box>
            </Box>
            <OneLineTextField
              error={citeDisplayErrors(content.key)}
              label={`${content.name}を入力`}
              handleChangeText={(text) => handleTextChange(content.key, text)}
              value={citeDisplayContentValue(content.key)}
            />
            <LetterCountLabel
              limit="30"
              count={createCurrentCount(content.key)}
            />
          </Box>
        ))}
      </Box>
      {/* 管理用情報 */}
      <Box hidden={value !== 1} width={696} height={366} mt={4} mx="auto">
        <HeadlineLabel text={tabs[1]} />
        <MandatoryHeadlineLabel title="管理用タイトル" />
        <OneLineTextField
          error={normalEditErrors?.title}
          label="管理用タイトルを入力"
          value={newLp?.title}
          handleChangeText={(text) => handleTextChange('title', text)}
        />
        <LetterCountLabel
          limit="30"
          count={`${newLp?.title?.length ? newLp.title.length : '0'}`}
        />
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box flexGrow={1}>
            <SubHeadlineLabel text="タグ" />
          </Box>
          <Box mt={3}>
            <ButtonLink
              fontSize="13px"
              text="＋リストから追加する"
              click={() => setOpenTagSelectDialog(true)}
            />
          </Box>
        </Box>
        <OneLineTextField
          error={normalEditErrors?.lpTag}
          label="ランディングページタグを入力"
          value={newLp?.lpTag}
          handleChangeText={(text) => handleTextChange('lpTag', text)}
        />
        <SupportTextLabel text="複数登録の場合はカンマ(,)で区切ってください。" />
      </Box>
      {/* ベースデザイン選択 */}
      <Box hidden={value !== 2} mt={4}>
        <HeadlineLabel text={tabs[2]} />
        <Grid className={classes.designGrid} container spacing={2}>
          {designTemplates &&
            // APIから受け取った順に表示する
            designTemplates.map((design, index) => (
              <Grid key={index} item>
                <Box
                  onClick={() => selectDesignTemplate(design.designTemplateId)}
                  textAlign="center"
                  className={clsx(
                    classes.design,
                    newLp?.designTemplateId === design.designTemplateId &&
                      classes.currentDesign,
                    // newLp?.designTemplateId === index + 1 &&
                    // classes.currentDesign,
                  )}
                >
                  <Typography variant="body1" className={classes.designTitle}>
                    {design.designTemplateName}
                  </Typography>
                  <img
                    className={classes.templateThumbnail}
                    src={
                      design.thumbnailUrl
                        ? design.thumbnailUrl
                        : NoImagePortrait
                    }
                  />
                  <Box
                    width={144}
                    mt={3}
                    mx="auto"
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Typography variant="body2" className={classes.preview}>
                      プレビュー
                    </Typography>
                    <PreviewButton
                      previewData={previewData}
                      templateId={design.designTemplateId}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      {/* 公開設定 */}
      <Box hidden={value !== 3} width={696} mx="auto" mt={4} pb={6}>
        <HeadlineLabel text={tabs[3]} />
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box flexGrow={1}>
            <MandatoryHeadlineLabel title="メールタイトル" />
          </Box>
          <Box mt={3}>
            <ButtonLink
              disabled={copyFromMyLibraryDisabled}
              click={() =>
                subjectCopyFromMyLibrary(
                  newLp?.mailSubject,
                  newLp?.mailText,
                  newLp?.mailSignature,
                )
              }
              text="マイライブラリからコピー"
              fontSize="13px"
            />
          </Box>
        </Box>
        <OneLineTextField
          error={normalEditErrors?.mailSubject}
          handleChangeText={(text) => handleTextChange('mailSubject', text)}
          value={newLp?.mailSubject}
        />
        <LetterCountLabel
          limit="30"
          count={`${
            newLp?.mailSubject?.length ? newLp?.mailSubject.length : '0'
          }`}
        />
        <MandatoryHeadlineLabel title="メール本文" />
        <Typography variant="body1">
          <span className={classes.mailTextName}>〇〇〇〇〇 〇〇〇〇〇〇</span>
          様
          <span className={classes.preview}>
            {' '}
            ※メール本文冒頭には、送信先リストの「お客さま会社」「お客さま氏名」が入ります
          </span>
        </Typography>
        <MultipleLinesTextField
          error={normalEditErrors?.mailText}
          value={newLp?.mailText}
          handleChangeText={(text) => handleTextChange('mailText', text)}
          label="メール本文を入力"
          rows={7}
        />
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box flexGrow={1}>
            <span className={classes.description}>
              URL：公開ランディングページのURLが入ります
            </span>
          </Box>
          <Box>
            <LetterCountLabel
              limit="300"
              count={`${
                newLp?.mailText?.length ? newLp?.mailText.length : '0'
              }`}
            />
          </Box>
        </Box>
        <MandatoryHeadlineLabel title="パスワード設定" />
        <FormControl>
          <RadioGroup
            aria-label="order"
            value={passwordSet}
            onChange={handleChangePasswordSetting}
          >
            <CommonRadioButton value="1" label="設定しない" />
            <CommonRadioButton value="2" label="設定して本文に自動で入れる" />
            <CommonRadioButton
              value="3"
              label="設定してパスワードを別でお知らせする"
            />
          </RadioGroup>
          <span className={classes.passwordDescription}>
          ※システムからは自動送信されません。ご自身でメール等でお客さまへご連絡ください。
          </span>
        </FormControl>
        <Box width={332}>
          <PasswordTextField
            error={normalEditErrors?.password}
            disabled={passwordTextBox}
            value={newLp?.password}
            handleChangeText={(text) => handleTextChange('password', text)}
          />
        </Box>
        <SupportTextLabel text="半角英数字8文字以上" />
        <SubHeadlineLabel text="メール署名" />
        <MultipleLinesTextField
          error={normalEditErrors?.mailSignature}
          value={newLp?.mailSignature}
          handleChangeText={(text) => handleTextChange('mailSignature', text)}
          label="自分のメール署名を入力"
          rows={7}
        />
        <LetterCountLabel
          limit="300"
          count={`${
            newLp?.mailSignature?.length ? newLp?.mailSignature.length : '0'
          }`}
        />
        <DateTimePicker
          dateError={normalEditErrors?.openPeriodDate}
          timeError={normalEditErrors?.openPeriodTime}
          subtitle="公開終了日時"
          width="332px"
          date={
            newLp?.openPeriod?.openPeriodDate
              ? newLp?.openPeriod?.openPeriodDate
              : undefined
          }
          time={
            newLp?.openPeriod?.openPeriodTime
              ? formateTimeHHMMColon(newLp?.openPeriod?.openPeriodTime)
              : undefined
          }
          handleChangeDate={(date) => handleChangeDate(date)}
          handleChangeTime={(time) => handleChangeTime(time)}
        />
        <Typography variant="body2" className={classes.cauntion}>
          {LP_OPEN_PERIOD_CAUNTION}
        </Typography>
      </Box>
      {/* 表示用得意先名入力子画面 */}
      <NormalEditCustomerNameDialog
        tokuisakiParam={tokuisakiParam}
        setTokuisakiParam={setTokuisakiParam}
        handleSubmit={(text) => {
          if (text) handleTextChange('tokuisakiName', text);
          setOpenCustomerNameDialog(false);
        }}
        onClose={() => setOpenCustomerNameDialog(false)}
        open={openCustomerNameDialog}
        tokuisakis={lpTokuisaki?.tokuisakiNames}
      />
      {/* タグ選択子画面 */}
      <TagSelectDialog
        handleCheck={handleTagCheck}
        tags={tags?.lpTags ? tags?.lpTags : undefined}
        usedTags={tags?.usedLpTags ? tags?.usedLpTags : undefined}
        open={openTagSelectDialog}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setOpenTagSelectDialog(false);
        }}
        handleSubmit={() => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          handleSubmitTags();
        }}
        selectedTags={selectedTags}
        tagType="edit"
        category="LP"
      />
      {goBack}
      {/* マイライブラリからコピー上書きの時に出すダイアログ メール件名 */}
      <ConfirmDialog
        buttonText="OK"
        open={openSubjectDialog}
        title="上書きします"
        text="内容を上書きしても"
        handleCancel={() => setOpenSubjectDialog(false)}
        handleSubmit={() => {
          subjectCopyFromMyLibrary();
          setOpenSubjectDialog(false);
        }}
      />
    </div>
  );
};

export default NormalEditLPSetting;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { AuthError } from 'core/domain/auth'

const initialState = null

export function createInitialState(): AuthError | null {
  return null
}

export const SET_AUTH_ERROR = 'auth-error/set' as const
export const CLEAR_AUTH_ERROR = 'auth-error/clear' as const

export function setAuthError(error: AuthError) {
  return {
    type: SET_AUTH_ERROR,
    payload: {
      error,
    },
  }
}
export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  }
}

type SetAuthErrorAction = ReturnType<typeof setAuthError>
type ClearAuthErrorAction = ReturnType<typeof clearAuthError>
export type Action = SetAuthErrorAction | ClearAuthErrorAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_AUTH_ERROR:
      return action.payload.error
    case CLEAR_AUTH_ERROR:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

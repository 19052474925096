import React, { useMemo } from 'react';
import SimpleListBox from '../../molecules/SimpleListBox';
import { Notice } from '../../../core/domain/notices';
import { convertPublishDateToString } from '../../../libs/converter';
import StatusLabel from '../../atoms/StatusLabel';
import palette from '../../../styles/palette';
import { Box, ButtonLink } from '../../atoms';
import { openLinkUrl } from '../../../libs/link';

/**
 * Interface
 */
interface TenantNotices {
  notices?: Notice[];
}

/**
 * Style
 */

const statusToColor = (
  status: number,
):
  | {
      color: keyof typeof palette;
      label: string;
    }
  | undefined => {
  switch (status) {
    case 1:
      return {
        color: 'red',
        label: '重要',
      };
    case 2:
      return {
        color: 'green',
        label: 'サービスからのお知らせ',
      };
    default:
      return undefined;
  }
};

const TenantNoticesList = (props: TenantNotices) => {
  // 検索条件
  // 集計期間変更時、パラメーターにセット

  const { notices } = props;

  const dataList = useMemo(() => {
    if (!notices) return undefined;

    const result = notices.slice();
    result.length = 5;

    return result.map((notice) => {
      const startDateTitle = convertPublishDateToString({
        date: notice.publishStartDate,
        time: notice.publishStartTime,
      });
      const endDate = convertPublishDateToString({
        date: notice.publishEndDate,
        time: notice.publishEndTime,
      });
      const endDateTitle = endDate ? ` - ${endDate}` : '';
      const status = statusToColor(notice.noticeType);

      return {
        title: notice.subject,
        subTitle: startDateTitle + endDateTitle,
        key: notice.noticeId,
        placeholder: notice.noticeFiles ? (
          <Box display="flex">
            {notice.noticeFiles.map((file) => (
              <ButtonLink
                key={file.noticeFileId}
                text={file.noticeFileName}
                disabled={!file.url}
                click={() => openLinkUrl(file.url || '')}
              />
            ))}
          </Box>
        ) : (
          <></>
        ),
        label: status ? (
          <StatusLabel
            label={status.label}
            color={status.color}
            variant="contained"
          />
        ) : undefined,
      };
    });
  }, [notices]);

  return <>{dataList && <SimpleListBox list={dataList} />}</>;
};
export default TenantNoticesList;

import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  PrimaryButton,
  Typography,
  InformationIconButton,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import {
  CheckIcon,
  HeartIcon,
  DocumentIcon,
  NoImage,
  PlusIcon,
  ReleaseIcon,
  FavoriteIconGreen,
} from 'assets/images';
import clsx from 'clsx';
import { MaterialImage } from 'core/domain/material';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
} from 'constants/code';

/**
 * Interface
 */
export interface NormalEditProductCardProps {
  productName: string;
  interestedCount: number;
  downloadCount: number;
  click: () => void;
  handleAddProduct: () => void;
  selected: boolean;
  productImage: MaterialImage | null;
  productId: number;
  useLpCount: number;
  favouriteCount: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      width: 184,
      height: 278,
      '&:hover': {
        borderColor: theme.palette.green[800],
        backgroundColor: theme.palette.green[700],
      },
    },
    deselectBox: {
      borderWidth: 1,
      borderColor: theme.palette.gray[600],
    },
    selectedBox: {
      borderWidth: 2,
      borderColor: theme.palette.green[900],
      backgroundColor: theme.palette.green[700],
    },
    box: {
      margin: 12,
    },
    imageBox: {
      position: 'relative',
    },
    img: {
      width: 160,
      height: 87,
      objectFit: 'contain',
      pointerEvents: 'none',
    },
    name: {
      fontWeight: 'bold',
      lineHeight: '28px',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    label: {
      color: theme.palette.green[900],
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
      fontWeight: 'bold',
      marginLeft: 4,
    },
    heart: {
      width: 14,
      height: 13,
    },
    doc: {
      width: 14,
      height: 15,
    },
    selected: {
      '& #Icon_awesome-check-circle': {
        fill: theme.palette.green[900],
      },
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: 'auto',
    },
    selectedLabel: {
      marginLeft: theme.spacing(1),
      marginRight: 'auto',
    },
  }),
);

/**
 * Presenter
 */

const NormalEditProductCard = (props: NormalEditProductCardProps) => {
  const classes = useStyles({});
  const {
    productName,
    interestedCount,
    downloadCount,
    click,
    handleAddProduct,
    selected,
    productImage,
    productId,
    useLpCount,
    favouriteCount,
  } = props;

  const button = selected ? (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <CheckIcon className={classes.selected} />
      <Typography className={clsx(classes.label, classes.selectedLabel)}>
        選択済み
      </Typography>
    </Box>
  ) : (
    <PrimaryButton
      click={handleAddProduct}
      icon={<PlusIcon />}
      text="追加する"
    />
  );

  const img = useMemo(() => {
    if (!productImage || (!productImage.url && !productImage.thumbnailUrl))
      return <img src={NoImage} className={classes.img} />;

    switch (productImage.materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        return <img src={productImage.url} className={classes.img} />;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return (
          <video
            src={productImage.url}
            className={classes.img}
            poster={
              productImage.thumbnailUrl ? productImage.thumbnailUrl : undefined
            }
          />
        );
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return (
          <img
            src={
              productImage.thumbnailUrl ? productImage.thumbnailUrl : NoImage
            }
            className={classes.img}
          />
        );
      default:
        return <img src={NoImage} className={classes.img} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImage]);

  return (
    <Box
      className={clsx(
        classes.root,
        selected ? classes.selectedBox : classes.deselectBox,
      )}
    >
      <Box className={classes.box}>
        <Box className={classes.imageBox}>
          <InformationIconButton handleClick={click} />
          {img}
        </Box>
        <Box height={76}>
          <Typography variant="body2" className={classes.name}>
            {productId} | {productName}
          </Typography>
        </Box>
        <Box>
          <Box display="flex" flexDirection="row">
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              width="80px"
            >
              <HeartIcon className={classes.heart} />
              <Typography
                component="span"
                variant="body2"
                className={classes.label}
              >
                {interestedCount.toLocaleString() || 0}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              width="80px"
            >
              <DocumentIcon className={classes.doc} />
              <Typography
                component="span"
                variant="body2"
                className={classes.label}
              >
                {downloadCount.toLocaleString() || 0}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              width="80px"
            >
              <ReleaseIcon className={classes.heart} />
              <Typography
                component="span"
                variant="body2"
                className={classes.label}
              >
                {useLpCount.toLocaleString() || 0}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              width="80px"
            >
              <FavoriteIconGreen className={classes.heart} />
              <Typography
                component="span"
                variant="body2"
                className={classes.label}
              >
                {favouriteCount.toLocaleString() || 0}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mx="auto" mt={1} width={108} height={27}>
          {button}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(NormalEditProductCard);

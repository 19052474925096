/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Link } from 'components/atoms'
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography'

/**
 * Interface
 */

export interface DeleteMediaElementButtonProps {
  text: string
  click?: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      color: theme.palette.pink[900],
      cursor: 'pointer',
      height: '100%',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
    },
  }),
)

/**
 * Presenter
 */

export default function DeleteMediaElementButton(props: DeleteMediaElementButtonProps) {

  const classes = useStyles({})
  const { text, click } = props

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Link className={classes.root} onClick={click}>
        {text}を削除
      </Link>
    </Box>
  )
}/* eslint-enable */

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar } from 'components/atoms';
import {
  Content,
  InquiriesTable,
  InquirySearchField,
} from 'components/organisms';
import { hooks } from 'libs';
import { InquiriesQueryParameter, Inquiries } from 'core/domain/inquiries';

/**
 * Interface
 */
export interface InquiriesProps {
  rows: number;
  inquiries: Inquiries | null;
  inquiriesQueryParameter: InquiriesQueryParameter | null;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangePage: (page: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeOwnSwitch?: (checked: boolean) => void;
  handleChangeUnreadSwitch?: (checked: boolean) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */

const InquiriesPage = (props: InquiriesProps) => {
  const classes = useStyles({});
  const {
    inquiries,
    rows,
    handleChangeSortKey,
    inquiriesQueryParameter,
    handleChangePage,
    handleChangeFreeWord,
    handleChangeOwnSwitch,
    handleChangeUnreadSwitch,
  } = props;
  const { navigate } = hooks.useNavigate();

  return (
    <Box mt={5}>
      <Content title="お問い合わせ一覧">
        <div>
          <InquirySearchField
            switchLabel="自分のみ表示"
            inquiriesQueryParameter={inquiriesQueryParameter}
            handleChangeFreeWord={handleChangeFreeWord}
            handleChangeOwnSwitch={handleChangeOwnSwitch}
            handleChangeUnreadSwitch={handleChangeUnreadSwitch}
            switchOwnValue={inquiriesQueryParameter?.own || null}
            switchUnreadValue={inquiriesQueryParameter?.unread || null}
          />
          <Box className={classes.table}>
            <InquiriesTable
              clickInquiry={(inquiryId) =>
                navigate(`/adm/inquiries/${inquiryId}`)
              }
              inquiries={inquiries}
              handleChangeSortKey={handleChangeSortKey}
              inquiriesQueryParameter={inquiriesQueryParameter}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  inquiriesQueryParameter ? inquiriesQueryParameter.page : 1
                }
                maxPage={
                  inquiries ? Math.ceil(inquiries?.totalCount / rows) : 1
                }
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={inquiries?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
    </Box>
  );
};
export default InquiriesPage;

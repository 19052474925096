import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { ProductSortKey, ProductStatus } from 'core/domain/product';
import { setDashboardAddTerm } from 'core/modules/dashboardAddTerm';
import { clearProducts } from 'core/modules/products';
import { clearProductTags } from 'core/modules/productTags';
import { setProductSearchCondition } from 'core/modules/productSearchCondition';
import Products from 'components/pages/Products';
import {
  clearProductTagsRequest,
  setProductTagsRequest,
} from 'core/modules/productTagsRequest';
import { hooks } from 'libs';
import { validDate } from 'libs/date';
import { INVALID_DATE } from 'constants/text';

const pageRows = 10;

export interface ProductSearchValidationError {
  addTermFrom?: string;
  addTermTo?: string;
}

/**
 * Presenter
 */
const ProductsPageContainer = () => {
  const dispatch = useDispatch();

  const products = useSelector(
    (state: Modules.AppState) => state.products,
    shallowEqual,
  );
  const productSearchCondition = useSelector(
    (state: Modules.AppState) => state.productSearchCondition,
    shallowEqual,
  );
  const productTagsRequest = useSelector(
    (state: Modules.AppState) => state.productTagsRequest,
    shallowEqual,
  );
  const productTags = useSelector(
    (state: Modules.AppState) => state.productTags,
    shallowEqual,
  );

  const defaultAddTerm = useSelector(
    (state: Modules.AppState) => state.addTerm,
    shallowEqual,
  );

  const addTerm = useSelector(
    (state: Modules.AppState) => state.dashboardAddTerm,
    shallowEqual,
  );

  const [validationError, setValidationError] = useState<
    ProductSearchValidationError | undefined
  >(undefined);

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  //

  useEffect(
    () => () => {
      dispatch(clearProducts());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!userInfo) return;
    if (productSearchCondition) return;

    if (!defaultAddTerm) {
      dispatch(Usecases.settings.sendGetAddTerm(token, userInfo.tenantId));

      return;
    }

    if (!addTerm) dispatch(setDashboardAddTerm(defaultAddTerm));

    // 検索条件を初期化した場合、エラーをクリア
    // ※サイドバーからの同画面呼び出し対策
    setValidationError(undefined);
  }, [
    dispatch,
    productSearchCondition,
    token,
    defaultAddTerm,
    addTerm,
    userInfo,
  ]);

  // 商材タグリクエストパラメータ初期値設定
  useEffect(() => {
    dispatch(setProductTagsRequest({ tagType: 'list', sortKey: 'tag' }));

    return () => {
      dispatch(clearProductTagsRequest());
    };
    // 初期値設定（初回だけ動けばOK）なので、ルール無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 商材一覧取得/商材一覧取得リクエスト
  useEffect(() => {
    if (!productSearchCondition || !userInfo || validationError) return;
    dispatch(
      Usecases.products.sendGetProductsByPage(
        token,
        userInfo.tenantId,
        productSearchCondition,
      ),
    );

    // validationErrorは、エラー時に検索させたくないだけなので、監視不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSearchCondition, token, userInfo, addTerm]);

  // 現在のページを取得しリクエストパラメーターに設定
  useEffect(() => {
    if (
      products &&
      products.products &&
      products.products.length === 0 &&
      products.totalCount > 0
    ) {
      const latestPage = Math.ceil(products?.totalCount / pageRows);
      dispatch(
        setProductSearchCondition({
          ...productSearchCondition,
          page: latestPage,
          row: pageRows,
        }),
      );
    }
  }, [dispatch, productSearchCondition, products]);

  // 商材タグ一覧取得
  useEffect(() => {
    if (!userInfo || !productTagsRequest.tagType || !productTagsRequest.sortKey)
      return undefined;
    dispatch(
      Usecases.products.sendGetProductTags(
        token,
        userInfo.tenantId,
        productTagsRequest,
      ),
    );

    return () => {
      dispatch(clearProductTags());
    };
  }, [dispatch, productTagsRequest, token, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    if (!addTerm) return;
    if (productSearchCondition) return;

    dispatch(
      setProductSearchCondition({
        page: 1,
        row: pageRows,
        add_term_from: addTerm?.addTermFrom || undefined,
        add_term_to: addTerm?.addTermTo || undefined,
      }),
    );

    // 検索条件を初期化した場合、エラーをクリア
    // ※サイドバーからの同画面呼び出し対策
    setValidationError(undefined);
  }, [dispatch, token, addTerm, productSearchCondition, userInfo]);

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (productSearchCondition?.sort_key === sortKey) {
      order = productSearchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        sort_key: sortKey as ProductSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * お気に入りラジオボタンチェック時
   * リクエストパラメーターに設定
   * @param status
   */
  const handleCheckFavorite = (status: string) => {
    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        status: `${status}` as ProductStatus,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        free_word: freeWord,
      }),
    );
  };

  /**
   * タグ検索値変更時、リクエストパラメーターに設定
   * @param tags
   */
  const handleChangeTag = (tags: string) => {
    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        tags,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  const handleChangeAddTerm = (date: string, isFrom: boolean) => {
    let isError = false;
    isError = date ? !validDate(date) : false;
    const scPropName = isFrom ? 'add_term_from' : 'add_term_to';
    const atPropName = isFrom ? 'addTermFrom' : 'addTermTo';

    let isAnotherError = false;

    const newValidationError = { ...validationError };
    if (isError) {
      // エラーの場合、変更中項目にメッセージを表示するようエラーオブジェクトを更新
      newValidationError[atPropName] = INVALID_DATE;
      setValidationError(newValidationError);

      return;
    }
    if (validationError) {
      // 編集中項目にエラーが無い場合は、もう1つの項目にエラーが無いかチェック
      isAnotherError = (
        Object.keys(validationError) as (keyof ProductSearchValidationError)[]
      ).some((key) => key !== atPropName && validationError[key]);
    }

    const newCondition = {
      ...productSearchCondition,
      page: productSearchCondition?.page || 1,
      row: productSearchCondition?.row || pageRows,
    };

    if (isAnotherError) {
      // validationErrorが存在する（＝前回エラーあり）&変更中項目ではない
      // ＝もう片方が引き続きエラー.変更通項目のエラーのみ解除してエラーオブジェクトを更新
      newValidationError[atPropName] = undefined;
      setValidationError(newValidationError);
    } else {
      // 開始・終了共にエラーなしなので、エラーオブジェクトを未定義にする
      setValidationError(undefined);
    }

    // 編集中項目についてはエラーなしなので、検索条件に反映
    newCondition[scPropName] = date;
    dispatch(setProductSearchCondition(newCondition));
  };

  /**
   * ☆クリック時商材お気に入り更新
   */
  const handleClickFavorite = useCallback(
    async (productId: number, checked: boolean) => {
      if (!userInfo) return;
      // 通信の順番を維持するため、ルール無効化
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        Usecases.products.sendPatchProductFavorite(
          token,
          userInfo.tenantId,
          productId,
          checked,
        ),
      );
      if (!productSearchCondition) {
        dispatch(
          Usecases.products.sendGetProductsByPage(token, userInfo.tenantId, {
            page: 1,
            row: pageRows,
          }),
        );

        return;
      }

      dispatch(
        Usecases.products.sendGetProductsByPage(
          token,
          userInfo.tenantId,
          productSearchCondition,
        ),
      );
    },
    [dispatch, productSearchCondition, token, userInfo],
  );

  /**
   * ステータスチェックボックス変更時リクエストパラメーターに設定
   * @param status
   * @returns
   */
  const handleCheckStatus = (status: number) => {
    if (!productSearchCondition || !productSearchCondition.product_status) {
      dispatch(
        setProductSearchCondition({
          ...productSearchCondition,
          product_status: `${status}`,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }
    const statusArray = productSearchCondition.product_status.split(',');
    const index = statusArray.indexOf(`${status}`);
    if (index < 0) {
      statusArray.push(`${status}`);
    } else {
      statusArray.splice(index, 1);
    }

    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        product_status: statusArray.join(','),
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  // タグxボタン押下
  const deleteTag = (val: string) => {
    if (!productSearchCondition || !productSearchCondition.tags) {
      dispatch(
        setProductSearchCondition({
          ...productSearchCondition,
          tags: '',
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }

    const newTags = productSearchCondition.tags
      .split(',')
      .filter((tag: string) => tag !== val);
    dispatch(
      setProductSearchCondition({
        ...productSearchCondition,
        tags: newTags.join(','),
        free_word: freeWord?.freeWord,
      }),
    );
  };

  return (
    <Products
      products={products}
      rows={pageRows}
      productSearchCondition={productSearchCondition}
      handleChangePage={handleChangePage}
      handleChangeSortKey={handleChangeSortKey}
      handleCheckFavorite={handleCheckFavorite}
      handleCheckStatus={handleCheckStatus}
      handleChangeFreeWord={handleChangeFreeWord}
      handleChangeTag={handleChangeTag}
      tags={productTags}
      searchValidateError={validationError}
      handleClickFavorite={handleClickFavorite}
      handleChangeAddTerm={handleChangeAddTerm}
      deleteTag={deleteTag}
    />
  );
};
export default ProductsPageContainer;

import { useCallback, useEffect } from 'react';
import Inquiries from 'components/pages/Inquiries';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Usecases, Modules } from 'core';
import { setInquiriesQueryParameter } from 'core/modules/inquiriesQueryParameter';
import { InquiriesSortKey } from 'core/domain/inquiries';
import { hooks } from 'libs';

const pageRows = 10;

/**
 * Presenter
 */

const InquiriesPageContainer = () => {
  const dispatch = useDispatch();
  const inquiries = useSelector(
    (state: Modules.AppState) => state.inquiries,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const inquiriesQueryParameter = useSelector(
    (state: Modules.AppState) => state.inquiriesQueryParameter,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector((state: Modules.AppState) => state.freeWord);

  // 問い合わせ一覧取得
  useEffect(() => {
    if (!inquiriesQueryParameter) {
      dispatch(
        setInquiriesQueryParameter({
          page: 1,
          row: pageRows,
          unread: true,
          own: false,
        }),
      );

      return;
    }
    if (!userInfo) return;
    dispatch(
      Usecases.inquiries.sendGetInquiriesTable(
        token,
        userInfo.tenantId,
        inquiriesQueryParameter,
      ),
    );
  }, [inquiriesQueryParameter, userInfo, dispatch, token]);

  // 問い合わせ一覧取得リクエストパラメーターpage、row設定
  useEffect(() => {
    if (
      inquiries &&
      inquiries.inquiries.length === 0 &&
      inquiries.totalCount > 0
    ) {
      if (!inquiriesQueryParameter) {
        dispatch(
          setInquiriesQueryParameter({
            page: 1,
            row: pageRows,
          }),
        );
      } else {
        const lastPage = Math.ceil(inquiries?.totalCount / pageRows);
        dispatch(
          setInquiriesQueryParameter({
            ...inquiriesQueryParameter,
            page: lastPage,
            row: pageRows,
          }),
        );
      }
    }
  }, [inquiries, dispatch, inquiriesQueryParameter]);

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (inquiriesQueryParameter?.sort_key === (sortKey as InquiriesSortKey)) {
      order = inquiriesQueryParameter.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setInquiriesQueryParameter({
        ...inquiriesQueryParameter,
        sort_key: sortKey as InquiriesSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setInquiriesQueryParameter({
        ...inquiriesQueryParameter,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setInquiriesQueryParameter({
        ...inquiriesQueryParameter,
        free_word: freeWord,
      }),
    );
  };

  const handleChangeOwnSwitch = (checked: boolean) => {
    dispatch(
      setInquiriesQueryParameter({
        ...inquiriesQueryParameter,
        own: checked,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  const handleChangeUnreadSwitch = useCallback(
    (checked: boolean) => {
      dispatch(
        setInquiriesQueryParameter({
          ...inquiriesQueryParameter,
          unread: checked,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );
    },
    [dispatch, inquiriesQueryParameter, freeWord?.freeWord],
  );

  return (
    <Inquiries
      inquiries={inquiries}
      rows={pageRows}
      inquiriesQueryParameter={inquiriesQueryParameter}
      handleChangeSortKey={handleChangeSortKey}
      handleChangePage={handleChangePage}
      handleChangeFreeWord={handleChangeFreeWord}
      handleChangeOwnSwitch={handleChangeOwnSwitch}
      handleChangeUnreadSwitch={handleChangeUnreadSwitch}
    />
  );
};
export default InquiriesPageContainer;

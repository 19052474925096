import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  ButtonLink,
  SubHeadlineLabel,
  MultipleLinesTextField,
  SupportTextLabel,
  LetterCountLabel,
  PrimaryButton,
  SecondaryButton,
  OneLineTextField,
  Divider,
  MessageAlert,
  ErrorText,
  Typography,
} from 'components/atoms';
import {
  Content,
  MediaCard,
  DateTimePicker,
  PageControlBox,
  RelationProductsDialog,
  FileUploadDialog,
  VideoEmbedDialog,
  NoticeDialog,
  ConfirmDialog,
  ThumbnailUploadDialog,
} from 'components/organisms';
import { ImageIcon, MovieIcon, URLLinkIcon } from 'assets/images';
import { ExampleDetail, ExampleRegisterRequest } from 'core/domain/example';
import { setNewExample } from 'core/modules/newExample';
import {
  validDate,
  formateTimeHHMMColon,
  checkPastDay,
  checkWithin5Days,
} from 'libs/date';
import { Products } from 'core/domain/product';
import { OpenDialogType } from 'components/organisms/FileUploadDialog';
import { hooks } from 'libs';
import { fileToBase64 } from 'libs/file';
import {
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_STATUS_END,
} from 'constants/code';
import clsx from 'clsx';
import { LpView } from 'core/domain/lp';
import {
  DATE_NOT_ENTERED,
  DIALOG_NO_APPROVAL_USER,
  END_OF_OPEN_CAUTION,
  INVALID_DATE,
  TIME_NOT_ENTERED,
} from 'constants/text';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { AppError } from 'core/domain/appError';
import { Modules } from 'core';
import { setError } from 'core/modules/error';
import { useParams } from 'react-router';
import { MaterialImageRegisterRequest } from 'core/domain/material';

/**
 * Interface
 */

export interface ExampleRegisterEditProps {
  newExample: ExampleRegisterRequest | null;
  relationProducts: Products | null;
  handleExampleRegister: (draft: boolean) => void;
  exampleDetail: ExampleDetail | null;
  previewData?: LpView;
  error: AppError | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    movieButton: {
      marginLeft: 8,
      marginRight: 8,
    },
    container: {
      marginTop: 57,
    },
    mediaHeader: {
      '& .MuiTypography-root': {
        marginTop: 4,
      },
    },
    divider: {
      marginTop: 48,
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    caution: {
      color: theme.palette.red[900],
      marginTop: theme.spacing(3),
      textAlign: 'left',
      border: '1px solid #DB3218',
      whiteSpace: 'pre-line',
      width: '580px',
      padding: '10px 15px',
    },
  }),
);

interface ValidationError {
  exampleName?: string;
  customerName?: string;
  workflowMessage?: string;
  exampleContent?: string;
  openPeriodDate?: string;
  openPeriodTime?: string;
  usePeriodDate?: string;
  usePeriodTime?: string;
  contact?: string;
  exampleImage?: string;
  exampleProductIds?: string;
}

/**
 * Presenter
 */

const ExampleRegisterEdit = (props: ExampleRegisterEditProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();
  const { hasMaterialApprovalUser } = hooks.useApproval();
  const {
    newExample,
    relationProducts,
    handleExampleRegister,
    exampleDetail,
    previewData,
    error,
  } = props;
  const [relationProductDialogVisible, setRelationProductDialogVisible] =
    useState(false);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [fileDialogType, setFileDialogType] =
    useState<OpenDialogType>('hidden');
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [videoEmbedDialogOpen, setVideoEmbedDialogOpen] = useState(false);
  const [thumbnailDialogOpen, setThumbnailDialogOpen] = useState(false);
  const [openNoApprovalUserDialog, setOpenNoApprovalUserDialog] =
    useState(false);
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [openEndConfirmDialog, setOpenEndConfirmDialog] = useState(false);
  const [openExpireConfirmDialog, setOpenExpireConfirmDialog] = useState(false);
  const [imageThumbnail, setImageThumbnail] = useState<string>('');
  const [draft, setDraft] = useState(false);
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [initialNewExample, setInitialNewExample] = useState<
    ExampleRegisterRequest | undefined | null
  >(undefined);
  const [nextPagePath, setNextPagePath] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const { exampleId } = useParams<{ exampleId: string }>();

  useEffect(() => {
    if (fileDialogType !== 'hidden') {
      setFileDialogOpen(true);
    } else {
      setFileDialogOpen(false);
    }
  }, [fileDialogType]);

  useEffect(() => {
    if (error) return;
    if (userInfo && !userInfo.tenantAdmin && !userInfo.materialAuthor) {
      dispatch(
        setError({
          title: '以下のエラーにより、処理を中止しました。',
          error: ['権限がありません。'],
        }),
      );

      return;
    }

    const res = navigate.block((pathname: string) => {
      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);

        return false;
      }

      if ((!newExample && !initialNewExample) || isRegister || goAnotherPage)
        return undefined;

      if (newExample && initialNewExample) {
        if (JSON.stringify(newExample) !== JSON.stringify(initialNewExample)) {
          setOpenExitConfirmDialog(true);

          return false;
        }
      }

      if (newExample && !initialNewExample) {
        if (exampleDetail) return undefined;
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    if (!newExample || initialNewExample) return undefined;

    // eslint-disable-next-line consistent-return
    return () => {
      res();
    };
  }, [
    newExample,
    isRegister,
    goAnotherPage,
    error,
    navigate,
    initialNewExample,
    exampleDetail,
    userInfo,
    dispatch,
  ]);

  useEffect(() => {
    if ((initialNewExample === null && !exampleId) || initialNewExample) return;

    setInitialNewExample(newExample ? { ...newExample } : null);
  }, [exampleId, initialNewExample, newExample]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  useEffect(() => {
    if (!exampleDetail || !exampleDetail.exampleImage) return;
    // サムネイル取得
    setImageThumbnail(exampleDetail.exampleImage.thumbnailUrl);
  }, [exampleDetail]);

  const setFileData = (imageType: number, file: File, url?: string) => {
    if (url && !file) {
      const imageData = {
        materialImageId: newExample?.exampleImage
          ? newExample?.exampleImage.materialImageId
          : undefined,
        materialImageType: imageType,
        data: '',
        url,
      };
      dispatch(
        setNewExample({
          ...newExample,
          exampleImage: imageData,
        }),
      );

      return;
    }
    void fileToBase64(file).then((res) => {
      const imageData = {
        materialImageId: newExample?.exampleImage
          ? newExample?.exampleImage.materialImageId
          : undefined,
        materialImageType: imageType,
        data: res as string,
        url,
        thumbnailData: 
          imageType === CODE_MATERIAL_IMAGE_TYPE_VIDEO &&
          newExample?.exampleImage?.thumbnailData
            ? newExample?.exampleImage.thumbnailData
            : undefined,
        thumbnailUrl: 
          newExample &&
          newExample.exampleImage &&
          newExample.exampleImage.thumbnailUrl
           ? newExample.exampleImage.thumbnailUrl
           : '',
      };
      dispatch(
        setNewExample({
          ...newExample,
          exampleImage: imageData,
        }),
      );
    });
  };

  const handleFileSubmit = (files: File[]) => {
    if (!files.length || !files[0]) return;

    setFileDialogOpen(false);
    switch (fileDialogType) {
      case 'image':
        setFileData(CODE_MATERIAL_IMAGE_TYPE_IMAGE, files[0]);
        break;
      case 'movie':
        setFileData(CODE_MATERIAL_IMAGE_TYPE_VIDEO, files[0]);
        break;
      case 'hidden':
        break;
      default:
        break;
    }

    setFileDialogType('hidden');
  };

  const handleLinkSubmit = (files: File[], url: string) => {
    setFileData(CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK, files[0], url);

    setVideoEmbedDialogOpen(false);
  };

  const handleChangeExampleName = (exampleName: string) => {
    dispatch(
      setNewExample({
        ...newExample,
        exampleName,
      }),
    );
  };

  const handleChangeCustomerName = (customerName: string) => {
    dispatch(
      setNewExample({
        ...newExample,
        customerName,
      }),
    );
  };

  const handleChangeContact = (contact: string) => {
    dispatch(
      setNewExample({
        ...newExample,
        contact,
      }),
    );
  };

  const handleChangeExampleContent = (exampleContent: string) => {
    dispatch(
      setNewExample({
        ...newExample,
        exampleContent,
      }),
    );
  };

  const handleChangeUsePeriodDate = (usePeriodDate: string) => {
    const period = {
      ...newExample?.materialPeriod,
      usePeriodDate:
        usePeriodDate.length !== 0
          ? usePeriodDate.replaceAll('/', '')
          : undefined,
    };
    dispatch(
      setNewExample({
        ...newExample,
        materialPeriod: period,
      }),
    );
  };

  const handleChangeOpenPeriodDate = (openPeriodDate: string) => {
    const period = {
      ...newExample?.materialPeriod,
      openPeriodDate:
        openPeriodDate.length !== 0
          ? openPeriodDate.replaceAll('/', '')
          : undefined,
    };
    dispatch(
      setNewExample({
        ...newExample,
        materialPeriod: period,
      }),
    );
  };

  const handleChangeUsePeriodTime = (usePeriodTime: string) => {
    const period = {
      ...newExample?.materialPeriod,
      usePeriodTime:
        usePeriodTime.length !== 0 ? usePeriodTime.replace(':', '') : undefined,
    };
    dispatch(
      setNewExample({
        ...newExample,
        materialPeriod: period,
      }),
    );
  };

  const handleChangeOpenPeriodTime = (openPeriodTime: string) => {
    const period = {
      ...newExample?.materialPeriod,
      openPeriodTime:
        openPeriodTime.length !== 0
          ? openPeriodTime.replace(':', '')
          : undefined,
    };
    dispatch(
      setNewExample({
        ...newExample,
        materialPeriod: period,
      }),
    );
  };

  const handleChangeWorkflowMessage = (workflowMessage: string) => {
    dispatch(
      setNewExample({
        ...newExample,
        workflowMessage,
      }),
    );
  };

  const handleRelationProduct = (productIds: Array<number>) => {
    dispatch(
      setNewExample({
        ...newExample,
        exampleProductIds: productIds,
      }),
    );
  };

  const handleDeleteMedia = () => {
    dispatch(
      setNewExample({
        ...newExample,
        exampleImage: undefined,
      }),
    );

    // サムネイル削除
    setImageThumbnail('');
  };

  const onFileUploadDialog = () => {
    setFileDialogType('hidden');
  };

  const onVideoEmbedDialogClose = () => {
    setVideoEmbedDialogOpen(false);
  };

  const onThumbnailDialogClose = () => {
    setThumbnailDialogOpen(false);
  }

  const handleChangeMedia = (imageType?: number) => {
    switch (imageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
        setFileDialogType('image');
        break;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        setFileDialogType('movie');
        break;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        setVideoEmbedDialogOpen(true);
        break;
      default:
        break;
    }
  };

  // 動画用サムネ
  const handleThumbnailSubmit = (
    thumbnailData: File[],
    image: MaterialImageRegisterRequest | undefined,
  ) => {
    if (!thumbnailData || !image) return;

    thumbnailData.map((data) => {
      void fileToBase64(data).then((res) => {
        const imageData = {
          materialImageId: newExample?.exampleImage
            ? newExample?.exampleImage.materialImageId
            : undefined,
          materialImageType: CODE_MATERIAL_IMAGE_TYPE_VIDEO,
          data: image.data,
          url: newExample?.exampleImage
            ? newExample.exampleImage?.url
            : '',
          thumbnailData: res as string,
        };
        dispatch(
          setNewExample({
            ...newExample,
            exampleImage: imageData,
          }),
        );
      });
      
      setThumbnailDialogOpen(false);
    });
  };

  const productNames = useMemo(() => {
    if (!newExample?.exampleProductIds && !relationProducts?.products)
      return '';
    const products = relationProducts?.products?.filter((product) =>
      newExample?.exampleProductIds?.includes(product.productId),
    );

    return products?.map((product) => product.productName).join(',');
  }, [newExample, relationProducts?.products]);

  const enableDraft = useMemo(() => {
    if (!exampleDetail) return true;

    return (
      exampleDetail.materialPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_DRAFT ||
      exampleDetail.materialPeriod.materialStatus ===
        CODE_MATERIAL_STATUS_DECLINE
    );
  }, [exampleDetail]);

  const clearValidation = () => {
    setValidationError({
      exampleName: undefined,
      customerName: undefined,
      workflowMessage: undefined,
      exampleContent: undefined,
      usePeriodDate: undefined,
      usePeriodTime: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
    });
  };

  const registerIfValidParams = (
    draft: boolean,
    register: (draft: boolean) => void,
  ) => {
    clearValidation();

    const validation: ValidationError = {
      exampleName: undefined,
      customerName: undefined,
      workflowMessage: undefined,
      exampleContent: undefined,
      usePeriodDate: undefined,
      usePeriodTime: undefined,
      openPeriodDate: undefined,
      openPeriodTime: undefined,
      exampleProductIds: undefined,
      contact: undefined,
    };

    // 必須チェック
    // 導入事例名称
    if (!newExample || !newExample?.exampleName)
      validation.exampleName = '導入事例名称は必須です。';
    // お客さま名
    if (!draft && (!newExample || !newExample?.customerName))
      validation.customerName = '導入お客様名は必須です。';
    // 関連商材
    if (
      !draft &&
      (!newExample ||
        !newExample.exampleProductIds ||
        !newExample.exampleProductIds[0])
    )
      validation.exampleProductIds = '関連商材は必須です。';
    // 事例説明
    if (!draft && (!newExample || !newExample.exampleContent))
      validation.exampleContent = '事例説明は必須です。';
    // イメージ画像・動画
    if (!draft && (!newExample || !newExample.exampleImage))
      validation.exampleImage = 'ファイルを選択してください。';

    // 文字数
    // 導入事例名称 100
    if (newExample) {
      if (newExample.exampleName && newExample.exampleName.length > 100)
        validation.exampleName = '導入事例名称は100文字以内で入力してください';
      // お客さま名 100
      if (newExample.customerName && newExample.customerName.length > 100)
        validation.customerName = '導入お客様名は100文字以内で入力してください';
      // 事例説明 1000
      if (newExample.exampleContent && newExample.exampleContent.length > 1000)
        validation.exampleContent = '事例説明は1000文字以内で入力してください';
      // 承認申請時の申し送り事項 1000 (darft: false)
      if (
        newExample.workflowMessage &&
        newExample.workflowMessage.length > 1000
      )
        validation.workflowMessage =
          '承認申請時の申し送り事項は1000文字以内で入力してください';
      if (newExample.contact && newExample.contact.length > 100)
        validation.contact = '問い合わせ先は100文字以内で入力してください';
    }

    // フォーマットチェック
    if (newExample) {
      // 利用期限日
      if (
        newExample.materialPeriod &&
        newExample.materialPeriod.usePeriodDate &&
        !validDate(newExample.materialPeriod.usePeriodDate)
      )
        validation.usePeriodDate = INVALID_DATE;
      if (
        newExample.materialPeriod &&
        !newExample.materialPeriod.usePeriodDate &&
        newExample.materialPeriod.usePeriodTime
      )
        validation.usePeriodDate = DATE_NOT_ENTERED;
      if (
        newExample.materialPeriod &&
        newExample.materialPeriod.usePeriodDate &&
        !newExample.materialPeriod.usePeriodTime
      )
        validation.usePeriodTime = TIME_NOT_ENTERED;
      // 公開日
      if (
        newExample.materialPeriod &&
        newExample.materialPeriod.openPeriodDate &&
        !validDate(newExample.materialPeriod.openPeriodDate)
      )
        validation.openPeriodDate = INVALID_DATE;
      if (
        newExample.materialPeriod &&
        !newExample.materialPeriod.openPeriodDate &&
        newExample.materialPeriod.openPeriodTime
      )
        validation.openPeriodDate = DATE_NOT_ENTERED;
      if (
        newExample.materialPeriod &&
        newExample.materialPeriod.openPeriodDate &&
        !newExample.materialPeriod.openPeriodTime
      )
        validation.openPeriodTime = TIME_NOT_ENTERED;
    }

    // [新規登録・下書き・差し戻し・承認申請時]過去日チェック
    if (
      newExample &&
      newExample.materialPeriod &&
      (!exampleDetail ||
        (exampleDetail &&
          exampleDetail.materialPeriod.materialStatus ===
            CODE_MATERIAL_STATUS_DRAFT) ||
        (exampleDetail &&
          exampleDetail.materialPeriod.materialStatus ===
            CODE_MATERIAL_STATUS_DECLINE))
    ) {
      if (
        checkWithin5Days(
          newExample.materialPeriod.openPeriodDate,
          newExample.materialPeriod.openPeriodTime,
        )
      )
        validation.openPeriodDate = '5日以内の期限は設定できません。';
      if (
        checkPastDay(
          newExample.materialPeriod.openPeriodDate,
          newExample.materialPeriod.openPeriodTime,
        )
      )
        validation.openPeriodDate =
          '公開期限が過去の日時です。未来の日時を指定してください。';
      if (
        checkPastDay(
          newExample.materialPeriod.usePeriodDate,
          newExample.materialPeriod.usePeriodTime,
        )
      )
        validation.usePeriodDate =
          '利用期限が過去の日時です。未来の日時を指定してください。';
    }

    // 公開中,一時停止、公開終了、利用期間外過去日チェック
    if (
      exampleDetail &&
      [
        CODE_MATERIAL_STATUS_RELEASED,
        CODE_MATERIAL_STATUS_SUSPENSION,
        CODE_MATERIAL_STATUS_END,
        CODE_MATERIAL_STATUS_EXPIRE,
      ].some((e) => e === exampleDetail.materialPeriod.materialStatus)
    ) {
      if (
        checkWithin5Days(
          newExample?.materialPeriod?.openPeriodDate,
          newExample?.materialPeriod?.openPeriodTime,
        )
      )
        validation.openPeriodDate = '5日以内の期限は設定できません。';
    }

    if (
      validation.exampleName ||
      validation.customerName ||
      validation.exampleProductIds ||
      validation.exampleContent ||
      validation.workflowMessage ||
      validation.usePeriodDate ||
      validation.usePeriodTime ||
      validation.openPeriodDate ||
      validation.openPeriodTime ||
      validation.contact ||
      validation.exampleImage
    ) {
      setValidationError(validation);
    } else if (
      exampleDetail &&
      exampleDetail.materialPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_APPROVAL &&
      checkPastDay(
        newExample?.materialPeriod?.usePeriodDate,
        newExample?.materialPeriod?.usePeriodTime,
      )
    ) {
      setOpenExpireConfirmDialog(true);
      setDraft(draft);
    } else if (
      exampleDetail &&
      exampleDetail.materialPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_APPROVAL &&
      checkPastDay(
        newExample?.materialPeriod?.openPeriodDate,
        newExample?.materialPeriod?.openPeriodTime,
      )
    ) {
      setOpenEndConfirmDialog(true);
      setDraft(draft);
    } else if (
      exampleDetail &&
      exampleDetail?.materialPeriod?.materialStatus <
        CODE_MATERIAL_STATUS_RELEASED &&
      !draft &&
      !hasMaterialApprovalUser()
    ) {
      // 承認者未設定の場合ダイアログ表示
      setOpenNoApprovalUserDialog(true);
    } else if (
      !draft &&
      exampleDetail &&
      exampleDetail.materialPeriod.materialStatus >=
        CODE_MATERIAL_STATUS_RELEASED
    ) {
      setIsRegister(true);
      register(true);
    } else {
      setIsRegister(true);
      register(draft);
    }
    setTimeout(() => {
      setIsSubmitting((prev) => !prev);
    }, 1000);
  };

  const hasValidationError = useMemo(() => {
    if (
      validationError &&
      (validationError.exampleName ||
        validationError.customerName ||
        validationError.exampleContent ||
        validationError.exampleProductIds ||
        validationError.workflowMessage ||
        validationError.usePeriodDate ||
        validationError.usePeriodTime ||
        validationError.openPeriodDate ||
        validationError.openPeriodTime ||
        validationError.contact ||
        validationError.exampleImage)
    ) {
      return true;
    }

    return false;
  }, [validationError]);

  const register = (draft: boolean) => {
    setIsSubmitting((prev) => !prev);
    registerIfValidParams(draft, handleExampleRegister);
  };

  const handleExampleRegisterWrapper = (isExpire?: boolean) => {
    if (!handleExampleRegister) return;
    setInitialNewExample(undefined);
    handleExampleRegister(isExpire || draft);
  };

  // 動画サムネ用ダイアログ
  const handleChangeThumbnail = () => {
    setThumbnailDialogOpen(true);
  };

  return (
    <Box
      mt={5}
      className={clsx(classes.root, {
        [classes.alertVisible]: hasValidationError,
      })}
    >
      {hasValidationError && (
        <Box className={classes.messageAlert}>
          <MessageAlert text="入力に誤りがあります。" severity="error" />
        </Box>
      )}
      <NoticeDialog
        open={openNoApprovalUserDialog}
        handleClose={() => setOpenNoApprovalUserDialog(false)}
        title={DIALOG_NO_APPROVAL_USER}
      />
      <FileUploadDialog
        handleSubmit={handleFileSubmit}
        onClose={onFileUploadDialog}
        open={fileDialogOpen}
        isProduct={false}
        fileType={fileDialogType}
      />
      <VideoEmbedDialog
        open={videoEmbedDialogOpen}
        url={newExample?.exampleImage?.url}
        onClose={onVideoEmbedDialogClose}
        handleSubmit={handleLinkSubmit}
      />
      <ThumbnailUploadDialog
        materialImage={
          newExample?.exampleImage
        }
        open={thumbnailDialogOpen}
        onClose={onThumbnailDialogClose}
        handleSubmit={handleThumbnailSubmit}
      />
      <ConfirmDialog
        showIcon
        buttonText="公開終了する"
        open={openEndConfirmDialog}
        title="公開終了"
        text="公開終了になります。"
        handleCancel={() => setOpenEndConfirmDialog(false)}
        handleSubmit={() => {
          setOpenEndConfirmDialog(false);
          handleExampleRegisterWrapper();
          setDraft(false);
        }}
      />
      <ConfirmDialog
        showIcon
        buttonText="利用期間外にする"
        open={openExpireConfirmDialog}
        title="利用期間外"
        text="利用期間外になります。"
        handleCancel={() => setOpenExpireConfirmDialog(false)}
        handleSubmit={() => {
          setIsRegister(true);
          setOpenExpireConfirmDialog(false);
          // 公開期限日過去日チェック
          if (
            exampleDetail &&
            exampleDetail.materialPeriod.materialStatus >=
              CODE_MATERIAL_STATUS_APPROVAL &&
            checkPastDay(
              newExample?.materialPeriod?.openPeriodDate,
              newExample?.materialPeriod?.openPeriodTime,
            )
          ) {
            setOpenEndConfirmDialog(true);
          } else {
            handleExampleRegisterWrapper(true);
          }
        }}
      />
      <Content title="導入事例登録・編集">
        <div>
          <MandatoryHeadlineLabel title="導入事例名称" />
          <OneLineTextField
            error={validationError?.exampleName}
            label="導入事例名称を入力"
            value={newExample?.exampleName}
            handleChangeText={(text: string) => handleChangeExampleName(text)}
          />
          <LetterCountLabel
            limit="20"
            count={
              newExample && newExample.exampleName
                ? `${newExample.exampleName.length}`
                : '0'
            }
          />
          <MandatoryHeadlineLabel title="導入お客様名" />
          <Box width={332}>
            <OneLineTextField
              error={validationError?.customerName}
              label="導入お客様名を入力"
              value={newExample?.customerName}
              handleChangeText={(text: string) =>
                handleChangeCustomerName(text)
              }
            />
          </Box>
          <SubHeadlineLabel text="お問い合わせ先" />
          <OneLineTextField
            error={validationError?.contact}
            label="お問い合わせ先"
            value={newExample?.contact}
            handleChangeText={(text: string) => handleChangeContact(text)}
          />
          <LetterCountLabel
            onlyInputCount
            count={newExample?.contact ? `${newExample.contact.length}` : '0'}
          />
          <Box display="flex">
            <Box flexGrow={1}>
              <MandatoryHeadlineLabel title="関連商材" />
            </Box>
            <Box mt={3}>
              <ButtonLink
                text="＋リストから追加する"
                click={() => setRelationProductDialogVisible(true)}
              />
            </Box>
          </Box>
          <Box style={{ pointerEvents: 'none' }}>
            <OneLineTextField
              error={validationError?.exampleProductIds}
              label="関連商材を選択"
              value={productNames}
            />
          </Box>
          <MandatoryHeadlineLabel title="事例説明" />
          <MultipleLinesTextField
            error={validationError?.exampleContent}
            rows={7}
            value={newExample?.exampleContent}
            handleChangeText={(text: string) =>
              handleChangeExampleContent(text)
            }
          />
          <LetterCountLabel
            limit="150"
            count={
              newExample && newExample.exampleContent
                ? `${newExample.exampleContent.length}`
                : '0'
            }
          />
          <Box display="flex" className={classes.container} height={36}>
            <Box flexGrow={1} className={classes.mediaHeader}>
              <MandatoryHeadlineLabel title="イメージ画像・動画" />
              <SupportTextLabel text="※登録は1件までです。" />
            </Box>
            <Box width={218}>
              <PrimaryButton
                disabled={!!newExample?.exampleImage}
                click={() => setFileDialogType('image')}
                text="新規画像をアップロード"
                icon={<ImageIcon />}
              />
            </Box>
            <Box className={classes.movieButton} width={218}>
              <PrimaryButton
                disabled={!!newExample?.exampleImage}
                click={() => setFileDialogType('movie')}
                text="新規動画をアップロード"
                icon={<MovieIcon />}
              />
            </Box>
            <Box width={204}>
              <PrimaryButton
                disabled={!!newExample?.exampleImage}
                click={() => setVideoEmbedDialogOpen(true)}
                text="新規動画URL埋め込み"
                icon={<URLLinkIcon />}
              />
            </Box>
          </Box>
          <Box mt={4}>
            {newExample?.exampleImage && (
              <MediaCard
                sortable={false}
                url={newExample?.exampleImage?.url}
                materialImageType={newExample?.exampleImage.materialImageType}
                base64={newExample.exampleImage.data}
                thumbnailUrl={imageThumbnail}
                handleChangeMedia={() =>
                  handleChangeMedia(
                    newExample?.exampleImage &&
                      newExample?.exampleImage.materialImageType,
                  )
                }
                handleDeleteMedia={() => handleDeleteMedia()}
                thumbnailData={newExample.exampleImage.thumbnailData}
                handleChangeThumbnail={() => handleChangeThumbnail()}
              />
            )}
            {validationError?.exampleImage && (
              <ErrorText error={validationError.exampleImage} />
            )}
          </Box>
          <DateTimePicker
            dateError={validationError?.usePeriodDate}
            timeError={validationError?.usePeriodTime}
            subtitle="利用期限"
            supportText="ランディングページの新規作成・編集にて組み込める期限"
            width="332px"
            date={
              newExample?.materialPeriod?.usePeriodDate
                ? newExample?.materialPeriod?.usePeriodDate
                : undefined
            }
            time={
              newExample?.materialPeriod?.usePeriodTime
                ? formateTimeHHMMColon(
                    newExample?.materialPeriod?.usePeriodTime,
                  )
                : undefined
            }
            handleChangeDate={(date) => handleChangeUsePeriodDate(date)}
            handleChangeTime={(time) => handleChangeUsePeriodTime(time)}
          />
          <DateTimePicker
            dateError={validationError?.openPeriodDate}
            timeError={validationError?.openPeriodTime}
            subtitle="公開期限"
            supportText="公開中のランディングページに組み込んでいる場合、その情報が表示される期限　※5日以内の期限設定はできません"
            width="332px"
            date={
              newExample?.materialPeriod?.openPeriodDate
                ? newExample?.materialPeriod?.openPeriodDate
                : undefined
            }
            time={
              newExample?.materialPeriod?.openPeriodTime
                ? formateTimeHHMMColon(
                    newExample?.materialPeriod?.openPeriodTime,
                  )
                : undefined
            }
            handleChangeDate={(date) => handleChangeOpenPeriodDate(date)}
            handleChangeTime={(time) => handleChangeOpenPeriodTime(time)}
          />
          <br />
          <SupportTextLabel text="※期限制限をしない場合は空欄のままにしてください。後から編集可能です" />
          <Typography variant="body2" className={classes.caution}>
            {END_OF_OPEN_CAUTION}
          </Typography>
          <br />
          <SubHeadlineLabel text="承認申請時の申し送り事項" />
          <MultipleLinesTextField
            error={validationError?.workflowMessage}
            rows={7}
            value={newExample?.workflowMessage}
            handleChangeText={(text: string) =>
              handleChangeWorkflowMessage(text)
            }
          />
          <LetterCountLabel
            onlyInputCount
            count={
              newExample?.workflowMessage
                ? `${newExample.workflowMessage.length}`
                : '0'
            }
          />
          <Divider className={classes.divider} />
          <Box
            display="flex"
            justifyContent="center"
            className={classes.container}
          >
            <Box m={1} width={332} height={48}>
              <SecondaryButton
                disabled={!enableDraft}
                text="下書き保存"
                click={() => register(true)}
              />
            </Box>
            <Box m={1} width={332} height={48}>
              <PrimaryButton
                disabled={isSubmitting}
                text={
                  exampleDetail?.materialPeriod.materialStatus &&
                  exampleDetail?.materialPeriod.materialStatus >=
                    CODE_MATERIAL_STATUS_RELEASED
                    ? '保存する'
                    : '承認を申請する'
                }
                click={() => register(false)}
              />
            </Box>
          </Box>
          <PageControlBox
            enableDraft={enableDraft}
            handleDraftSave={() => register(true)}
            handleEnd={() => {
              if (newExample) {
                setOpenExitConfirmDialog(true);
              }
              navigate.navigateReplace('/adm/examples');
            }}
            previewData={previewData}
            isProductOrExample
          />
        </div>
      </Content>
      <RelationProductsDialog
        open={relationProductDialogVisible}
        products={relationProducts?.products ? relationProducts?.products : []}
        relationProductIds={
          newExample?.exampleProductIds ? newExample?.exampleProductIds : []
        }
        handleClose={() => setRelationProductDialogVisible(false)}
        handleSubmit={(productIds: Array<number>) => {
          handleRelationProduct(productIds);
          setRelationProductDialogVisible(false);
        }}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
    </Box>
  );
};
export default ExampleRegisterEdit;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ButtonLink,
  Divider,
  SecondaryButton,
} from 'components/atoms';
import { PreviewButton, PreviewHeightFixedButton } from 'components/molecules';
import { LpView } from 'core/domain/lp';

/**
 * Interface
 */

export interface PageControlBoxProps {
  enableDraft?: boolean;
  handleDraftSave?: () => void;
  handleEnd?: () => void;
  previewData?: LpView;
  isProductOrExample?: boolean;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      height: '145px',
      width: '104px',
      position: 'fixed',
      bottom: 24,
      right: 0,
      backgroundColor: theme.palette.text.secondary,
    },
    draftButton: {
      marginLeft: 6,
      marginRight: 5,
      marginTop: 8,
    },
    finishButton: {
      marginTop: 8,
      marginBottom: 8,
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
);

export default function PageControlBox(props: PageControlBoxProps) {
  const classes = useStyles({});
  const {
    handleDraftSave,
    handleEnd,
    enableDraft,
    previewData,
    isProductOrExample,
  } = props;

  return (
    <Box className={classes.root} boxShadow={3} pb={1}>
      <Box display="flex" justifyContent="center" mt={1}>
        <Typography display="block" className={classes.previewCaption}>
          プレビュー
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={1} mt={1}>
        {isProductOrExample ? (
          <PreviewHeightFixedButton previewData={previewData} />
        ) : (
          <PreviewButton previewData={previewData} />
        )}
      </Box>
      <Divider />
      <Box height={27} width="90%" className={classes.draftButton}>
        <SecondaryButton
          disabled={!enableDraft}
          text="下書き保存"
          click={handleDraftSave}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.finishButton}
      >
        <ButtonLink text="終了" click={handleEnd} />
      </Box>
    </Box>
  );
}
/* eslint-enable */

import {
  Box,
  Typography,
  OneLineTextField,
  CommonTextSwitch,
  MandatoryLabel,
} from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_300 } from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface UserNameFieldProps {
  name: string;
  status: boolean;
  error?: string;
  handleNameChange: (v: string) => void;
  handleToggleBtnChange: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
    },
    formArea: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      '& > :first-child': {
        width: 340,
        marginRight: theme.spacing(2),
      },
      '& > :nth-child(2)': {
        marginRight: theme.spacing(1),
      },
    },
  }),
);

const UserNameField = (props: UserNameFieldProps) => {
  const { name, status, error, handleNameChange, handleToggleBtnChange } =
    props;
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Typography className={classes.title} variant="caption">
          氏名
        </Typography>
        <MandatoryLabel margin="0 0 0 8px" />
      </Box>
      <Box className={classes.formArea}>
        <Box>
          <OneLineTextField
            label="氏名を入力"
            maxLength={100}
            value={name}
            error={error}
            handleChangeText={handleNameChange}
          />
        </Box>
        <Typography variant="caption">ユーザーステータス</Typography>
        <CommonTextSwitch
          handleChange={handleToggleBtnChange}
          checked={status}
          checkedText="有効"
          uncheckedText="無効"
        />
      </Box>
    </Box>
  );
};

export default UserNameField;

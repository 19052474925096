import { InquiriesForm } from 'core/domain/settings'

const initialState: InquiriesForm = {
  'inquiryForms': [],
  'updateDate': '',
}

export const createInitialState = (): InquiriesForm => initialState;

export const SET_INQUIRIES_FORM = 'inquiries-form/set' as const;
export const CLEAR_INQUIRIES_FORM = 'inquiries-form/clear' as const;

export const setInquiriesForm = (data: InquiriesForm) => ({
  type: SET_INQUIRIES_FORM,
  payload: {
    data
  },
});

export const clearInquiriesForm = () => ({
  type: CLEAR_INQUIRIES_FORM
});

type SetInquiriesFormAction = ReturnType<typeof setInquiriesForm>;
type ClearInquiriesFormAction = ReturnType<typeof clearInquiriesForm>;
type Action = SetInquiriesFormAction | ClearInquiriesFormAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_INQUIRIES_FORM:
      return action.payload.data;
    case CLEAR_INQUIRIES_FORM:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import {
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_LP_STATUS_AWAITING_APPROVAL,
  CODE_LP_STATUS_DRAFT,
  CODE_LP_STATUS_DECLINE,
  CODE_LP_STATUS_APPROVAL,
  CODE_LP_STATUS_RELEASED,
  CODE_LP_STATUS_SUSPENSION,
  CODE_LP_STATUS_EXPIRE,
  CODE_LP_STATUS_END,
  CODE_LP_WORKFLOW_STATUS_DECLINE,
  CODE_LP_WORKFLOW_STATUS_APPROVAL,
  CODE_LP_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_EXPIRE,
} from 'constants/code';
import { Severity } from 'components/atoms/MessageAlert';

interface LpControl {
  show: boolean;
  disabled: boolean;
}

export const useLpDetailPermission = () => {
  const lpDetail = useSelector((state: Modules.AppState) => state.lpDetail);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const lpWorkflows = useSelector(
    (state: Modules.AppState) => state.lpWorkflows,
  );
  const lpProductDetails = useSelector(
    (state: Modules.AppState) => state.lpProductDetails,
  );
  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
  );

  const step = useMemo(() => {
    if (!lpWorkflows || !lpWorkflows.length) return 0;
    const workflow = lpWorkflows[0];
    let step = 0;
    const regex = new RegExp(/^step[1-3]Status/);
    const stepRegex = new RegExp(/[1-3]/);
    for (const [key, value] of Object.entries(workflow)) {
      if (regex.test(key) && value === CODE_LP_WORKFLOW_STATUS_DECLINE) {
        return 0;
      }
      if (
        regex.test(key) &&
        value === CODE_LP_WORKFLOW_STATUS_AWAITING_APPROVAL
      ) {
        const res = stepRegex.exec(key);
        if (res) {
          const tmpStep = Number.parseInt(res[0]);
          if (step === 0 || step > tmpStep) {
            step = tmpStep;
          }
        }
      }
    }

    if (
      step === 0 &&
      workflow.workflowStatus === CODE_LP_WORKFLOW_STATUS_APPROVAL
    ) {
      return (
        [
          workflow.step1ApprovalUserId,
          workflow.step2ApprovalUserId,
          workflow.step3ApprovalUserId,
        ].filter((userId) => !!userId).length + 1
      );
    }

    return step;
  }, [lpWorkflows]);

  const approvalUsers = useMemo(() => {
    if (!lpWorkflows || lpWorkflows.length === 0) return undefined;
    const currentWorkflow = lpWorkflows[0];
    const users = [
      currentWorkflow.step1ApprovalUserName,
      currentWorkflow.step2ApprovalUserName,
      currentWorkflow.step3ApprovalUserName,
    ];

    return users.filter((user) => user);
  }, [lpWorkflows]);

  const currentStepUserId = useMemo(() => {
    if (!lpWorkflows || lpWorkflows.length === 0) return 0;
    const workflow = lpWorkflows[0];

    return [
      workflow.step1ApprovalUserId,
      workflow.step2ApprovalUserId,
      workflow.step3ApprovalUserId,
    ][step - 1];
  }, [lpWorkflows, step]);

  const isCreateUser = useMemo(() => {
    if (!userInfo || !lpDetail) return;

    return (
      userInfo?.lpAuthor && lpDetail?.createdUser.userId === userInfo.userId
    );
  }, [userInfo, lpDetail]);

  const isCurrentApproveUser = useMemo(() => {
    if (!userInfo) return;

    return userInfo.lpApprover && userInfo.userId === currentStepUserId;
  }, [userInfo, currentStepUserId]);

  // このLPを元に作成する
  const lpCreatePermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo?.lpAuthor || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_RELEASED:
        case CODE_MATERIAL_STATUS_SUSPENSION:
        case CODE_MATERIAL_STATUS_EXPIRE:
        case CODE_MATERIAL_STATUS_END:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // ゴミ箱に移動する
  const lpDeletePermission = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_LP_STATUS_DRAFT:
        case CODE_LP_STATUS_DECLINE:
        case CODE_LP_STATUS_APPROVAL:
        case CODE_LP_STATUS_END:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: true,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // LPを編集する
  const lpEditPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      if (lpDetail.lpStatus === CODE_LP_STATUS_AWAITING_APPROVAL) {
        return {
          show: true,
          disabled: true,
        };
      }
      if (
        !lpDetail.anotherlpStatus &&
        lpDetail.lpStatus <= CODE_LP_STATUS_SUSPENSION
      ) {
        return {
          show: true,
          disabled: false,
        };
      }
      switch (lpDetail.anotherlpStatus) {
        case CODE_LP_STATUS_DRAFT:
        case CODE_LP_STATUS_DECLINE:
        case CODE_LP_STATUS_AWAITING_APPROVAL:
          return {
            show: true,
            disabled: true,
          };
        case CODE_LP_STATUS_APPROVAL:
        case CODE_LP_STATUS_RELEASED:
        case CODE_LP_STATUS_SUSPENSION:
          return {
            show: true,
            disabled: false,
          };
        case CODE_LP_STATUS_END:
        case CODE_LP_STATUS_EXPIRE:
          return {
            show: false,
            disabled: true,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // 備考・メモを編集する
  const lpMemoEditPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      return {
        show: true,
        disabled: false,
      };
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // LPを差し戻す
  const lpDeclinePermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || isCurrentApproveUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_DRAFT:
        case CODE_MATERIAL_STATUS_DECLINE:
          return {
            show: true,
            disabled: true,
          };
        case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [
    lpDetail,
    userInfo,
    currentStepUserId,
    isCurrentApproveUser,
    lpWorkflows,
  ]);

  // LPを承認する
  const lpApprovePermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCurrentApproveUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_DRAFT:
        case CODE_MATERIAL_STATUS_DECLINE:
          return {
            show: true,
            disabled: true,
          };
        case CODE_MATERIAL_STATUS_AWAITING_APPROVAL:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, isCurrentApproveUser]);

  // LPを公開する
  const lpOpenPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_APPROVAL:
          return {
            show: true,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // LP公開期限変更
  const lpPeriodChangePermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_RELEASED:
        case CODE_MATERIAL_STATUS_SUSPENSION:
          return {
            show: true,
            disabled: false,
          };
        case CODE_MATERIAL_STATUS_END:
          return {
            show: false,
            disabled: true,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // メール配信ボタン
  const lpMailPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        // 公開中は表示・活性
        case CODE_MATERIAL_STATUS_RELEASED:
          return {
            show: true,
            disabled: false,
          };
        // 承認済み・一時停止時は表示・非活性
        case CODE_MATERIAL_STATUS_APPROVAL:
        case CODE_MATERIAL_STATUS_SUSPENSION:
          return {
            show: true,
            disabled: true,
          };
        // 公開終了非表示
        case CODE_MATERIAL_STATUS_END:
          return {
            show: false,
            disabled: true,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // 公開を一時停止する
  const lpSuspenstionPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_APPROVAL:
          return {
            show: false,
            disabled: true,
          };
        case CODE_MATERIAL_STATUS_RELEASED:
          return {
            show: true,
            disabled: false,
          };
        case CODE_MATERIAL_STATUS_END:
          return {
            show: false,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // 公開を再開する
  const lpReopenPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      switch (lpDetail.lpStatus) {
        case CODE_MATERIAL_STATUS_SUSPENSION:
          return {
            show: true,
            disabled: false,
          };
        case CODE_MATERIAL_STATUS_END:
          return {
            show: false,
            disabled: false,
          };
        default:
          return {
            show: false,
            disabled: false,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  // 承認通知
  const notification = useMemo(() => {
    if (
      lpDetail &&
      isCreateUser &&
      lpDetail?.lpStatus === CODE_MATERIAL_STATUS_DECLINE
    ) {
      return {
        text: '申請が差し戻されました。差し戻し理由を確認の上、修正してください。',
        severity: 'error' as Severity,
      };
    }
    if (
      userInfo?.lpApprover &&
      currentStepUserId === userInfo?.userId &&
      lpDetail?.lpStatus === CODE_MATERIAL_STATUS_AWAITING_APPROVAL
    ) {
      return {
        text: 'ランディングページの承認依頼です。内容を確認の上、承認を行ってください。',
        severity: 'warning' as Severity,
      };
    }

    if (
      lpDetail &&
      lpDetail.lpStatus === CODE_MATERIAL_STATUS_APPROVAL &&
      isCreateUser
    ) {
      return {
        text: 'ランディングページの承認がおりました。内容を確認の上、公開を実行してください。',
        severity: 'warning' as Severity,
      };
    }

    // return undefined
  }, [lpDetail, currentStepUserId, userInfo, isCreateUser, lpWorkflows]);

  const enableFavorite = useMemo(() => {
    if (!lpDetail) return false;

    return (
      lpDetail.lpStatus === CODE_LP_STATUS_RELEASED ||
      lpDetail.lpStatus === CODE_LP_STATUS_SUSPENSION ||
      lpDetail.lpStatus === CODE_LP_STATUS_END
    );
  }, [lpDetail]);

  // 公開URLの表示
  const lpReleasedURLPermission = useMemo(() => {
    if (!isCreateUser && !userInfo?.tenantAdmin && !userInfo?.lpApprover)
      return false;

    return true;
  }, [isCreateUser, userInfo?.tenantAdmin, userInfo?.lpApprover]);

  const showUrl = useMemo(() => {
    if (!lpDetail) return false;
    switch (lpDetail.lpStatus) {
      case CODE_LP_STATUS_DRAFT:
      case CODE_LP_STATUS_DECLINE:
      case CODE_LP_STATUS_AWAITING_APPROVAL:
      case CODE_LP_STATUS_APPROVAL:
      case CODE_LP_STATUS_EXPIRE:
        return false;
      case CODE_LP_STATUS_RELEASED:
      case CODE_LP_STATUS_SUSPENSION:
      case CODE_LP_STATUS_END:
        return true;
      default:
        return false;
    }
  }, [lpDetail]);

  const hasInvalidMaterial = () => {
    const exampleIndex = lpExampleDetails.findIndex(
      (e) => e.materialPeriod.materialStatus >= CODE_MATERIAL_STATUS_SUSPENSION,
    );
    const productIndex = lpProductDetails.findIndex(
      (p) => p.productPeriod.materialStatus >= CODE_MATERIAL_STATUS_SUSPENSION,
    );
    if (exampleIndex > -1) {
      return '導入事例';
    }
    if (productIndex > -1) {
      return '商材';
    }

    return false;
  };

  // お問い合わせ履歴表示
  const showInquiriesHistory = useMemo(() => {
    if (!userInfo) return false;
    if (userInfo.lpAuthor || userInfo.tenantAdmin || userInfo.lpApprover) {
      return true;
    }

    return false;
  }, [userInfo]);

  const showInquiryRegisterButton = useMemo(() => {
    if (!userInfo) return false;
    if (isCreateUser || userInfo.tenantAdmin || userInfo.lpApprover) {
      return true;
    }

    return false;
  }, [userInfo]);

  // LPを編集する
  const lpOpenSettingsEditPermission: LpControl | undefined = useMemo(() => {
    if (!userInfo || !lpDetail) return;
    if (isCreateUser || userInfo.tenantAdmin) {
      if (lpDetail.lpStatus === CODE_LP_STATUS_AWAITING_APPROVAL) {
        return {
          show: false,
          disabled: false,
        };
      }
      if (
        !lpDetail.anotherlpStatus &&
        (lpDetail.lpStatus === CODE_LP_STATUS_SUSPENSION ||
          lpDetail.lpStatus === CODE_LP_STATUS_RELEASED ||
          lpDetail.lpStatus === CODE_LP_STATUS_APPROVAL)
      ) {
        return {
          show: true,
          disabled: false,
        };
      }
      switch (lpDetail.anotherlpStatus) {
        case CODE_LP_STATUS_DRAFT:
        case CODE_LP_STATUS_DECLINE:
        case CODE_LP_STATUS_AWAITING_APPROVAL:
          return {
            show: false,
            disabled: true,
          };
        case CODE_LP_STATUS_APPROVAL:
        case CODE_LP_STATUS_RELEASED:
        case CODE_LP_STATUS_SUSPENSION:
          return {
            show: true,
            disabled: false,
          };
        case CODE_LP_STATUS_END:
        case CODE_LP_STATUS_EXPIRE:
          return {
            show: false,
            disabled: true,
          };
      }
    }
  }, [lpDetail, userInfo, isCreateUser, lpWorkflows]);

  return {
    step,
    lpDeletePermission,
    lpCreatePermission,
    lpEditPermission,
    lpMemoEditPermission,
    lpDeclinePermission,
    lpApprovePermission,
    lpOpenPermission,
    lpPeriodChangePermission,
    lpSuspenstionPermission,
    lpReopenPermission,
    notification,
    enableFavorite,
    approvalUsers,
    lpReleasedURLPermission,
    showUrl,
    hasInvalidMaterial,
    showInquiriesHistory,
    showInquiryRegisterButton,
    lpMailPermission,
    lpOpenSettingsEditPermission,
  };
}; /* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Lps } from 'core/domain/lp'

const initialState = null

export function createInitialState(): Lps | null {
  return initialState
}

export const SET_LPS_OWN = 'lps-own/set' as const
export const CLEAR_LPS_OWN = 'lps-own/clear' as const

export function setLpsOwn(data: Lps) {
  return {
    type: SET_LPS_OWN,
    payload: {
      data,
    },
  }
}

export function clearLpsOwn() {
  return {
    type: CLEAR_LPS_OWN,
  }
}

type SetLpsOwnAction = ReturnType<typeof setLpsOwn>
type ClearLpsOwnAction = ReturnType<typeof clearLpsOwn>
type Action = SetLpsOwnAction | ClearLpsOwnAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LPS_OWN:
      return action.payload.data
    case CLEAR_LPS_OWN:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

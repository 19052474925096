import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import {
  User,
  ManageUsersSearchCondition,
  LPApprovers,
  MaterialApprovers,
  UserRegisterRequest,
  UserInfoDetail,
  UserInfoDetailRequest,
  Users,
  UpdateUsers,
} from 'core/domain/user';
import { setUserInfo } from 'core/modules/userInfo';
import { setLPApproversInfo } from 'core/modules/lpApprovers';
import { setMaterialApproversInfo } from 'core/modules/materialApprovers';
import { setUserInfoDetail } from 'core/modules/userInfoDetail';
import { setManageUsers } from 'core/modules/manageUsers';
import { setMyLibrary } from 'core/modules/myLibrary';
import { MyLibrary } from 'core/domain/myLibrary';
import { setUsers } from 'core/modules/users';

const didCallGetUserInfo = (dispatch: Dispatch, data: User) => {
  dispatch(setUserInfo(data));
};

export const sendGetUser =
  (token: string | undefined) => async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `users/profile`,
      token,
      null,
      dispatch,
      didCallGetUserInfo,
    );
  };

const didCallGetlpApproversInfo = (dispatch: Dispatch, data: LPApprovers) => {
  dispatch(setLPApproversInfo(data));
};

export const sendGetLPApproversInfo =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users/admin/lps`,
      token,
      null,
      dispatch,
      didCallGetlpApproversInfo,
    );
  };

const didCallGetmaterialApproversInfo = (
  dispatch: Dispatch,
  data: MaterialApprovers,
) => {
  dispatch(setMaterialApproversInfo(data));
};

export const sendGetMaterialApproversInfo =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users/admin/materials`,
      token,
      null,
      dispatch,
      didCallGetmaterialApproversInfo,
    );
  };
// >>>>>>> c295610d8ac2022faf74e69c326d0a54b4650b98

const didCallGetUserInfoDetail = (dispatch: Dispatch, data: UserInfoDetail) => {
  dispatch(setUserInfoDetail(data));
};

export const sendGetUserInfoDetail =
  (token: string | undefined, tenantId: string, userId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users/${userId}`,
      token,
      null,
      dispatch,
      didCallGetUserInfoDetail,
    );
  };

const didCallPutUsersProfileApi = (dispatch: Dispatch, data: User) => {
  dispatch(setUserInfo(data));
};

export const sendPutUsersProfile =
  (token: string | undefined, tenantId: string, body: UserRegisterRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/users/profile`,
      token,
      JSON.stringify(body),
      dispatch,
      didCallPutUsersProfileApi,
    );
  };

const didCallPutUserInfoDetail = (dispatch: Dispatch, data: UserInfoDetail) => {
  dispatch(setUserInfoDetail(data));
};

export const sendPutUserInfoDetail =
  (
    token: string | undefined,
    tenantId: string,
    userId: string,
    body: UserInfoDetailRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/users/${userId}`,
      token,
      JSON.stringify(body),
      dispatch,
      didCallPutUserInfoDetail,
    );
  };

export const sendPostLoginHistory =
  (token: string | undefined) => async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `users/login/history`,
      token,
      null,
      dispatch,
    );
  };

const didCallGetManageUsers = (dispatch: Dispatch, data: Users) => {
  dispatch(setManageUsers(data));
};

export const sendGetManageUsers =
  (
    token: string | undefined,
    tenantId: string,
    param: ManageUsersSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users${api}`,
      token,
      null,
      dispatch,
      didCallGetManageUsers,
    );
  };

// setManageUsersSearchCondition;
const didCallPostUserInfoDetail = (
  dispatch: Dispatch,
  data: UserInfoDetail,
) => {
  dispatch(setUserInfoDetail(data));
};

export const sendPostUserInfoDetail =
  (token: string | undefined, tenantId: string, body: UserInfoDetailRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/users`,
      token,
      JSON.stringify(body),
      dispatch,
      didCallPostUserInfoDetail,
    );
  };

//  (dispatch: Dispatch, data: UpdateUsers) => {
const didPostUsersUpdateStatus = () => {
  // do nothing.
};

export const sendPostUsersUpdateStatus =
  (token: string | undefined, tenantId: string, body: UpdateUsers) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/users/status`,
      token,
      JSON.stringify(body),
      dispatch,
      didPostUsersUpdateStatus,
    );
  };

export const sendPutNewUsersCsv =
  (token: string | undefined, tenantId: string, newUsersCsv: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      // ユーザ情報一括登録ファイルアップロードAPI：POST /{tenant_id}/users/upload
      `${tenantId}/users/upload`,
      token,
      JSON.stringify({ data: newUsersCsv }),
      dispatch,
      didPostUsersUpdateStatus,
    );
  };
const didCallGetMyLibrary = (dispatch: Dispatch, data: MyLibrary) => {
  dispatch(setMyLibrary(data));
};

export const sendGetMyLibrary =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users/my-library`,
      token,
      null,
      dispatch,
      didCallGetMyLibrary,
    );
  };
const didcallPostMyLibrary = (dispatch: Dispatch, data: MyLibrary) => {
  dispatch(setMyLibrary(data));
};

export const sendPostMyLibrary =
  (token: string | undefined, tenantId: string, body: MyLibrary) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/users/my-library`,
      token,
      JSON.stringify(body),
      dispatch,
      didcallPostMyLibrary,
    );
  };

export const sendPutMyLibrary =
  (token: string | undefined, tenantId: string, body: MyLibrary) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/users/my-library`,
      token,
      JSON.stringify(body),
      dispatch,
      didcallPostMyLibrary,
    );
  };
const didCallGetUsers = (dispatch: Dispatch, data: Users) => {
  dispatch(setUsers(data));
};

export const sendGetUsers =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users`,
      token,
      null,
      dispatch,
      didCallGetUsers,
    );
  };

export const sendGetFreeWordUsers =
  (token: string | undefined, tenantId: string, freeWord: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/users?free_word=${encodeURIComponent(freeWord)}`,
      token,
      null,
      dispatch,
      didCallGetUsers,
    );
  };

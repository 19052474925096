/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { Redirect } from 'react-router-dom'
import { hooks } from 'libs'
import LoginPage from 'components/pages/Login'

/**
 * Interface
 */

interface ChildrenProps {
  handleLogin: () => void
}

interface LoginPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

function LoginPageContainer(props: LoginPageContainerProps) {
  const { children } = props
  const { authenticated, login } = hooks.useAuth()

  if (authenticated) {
    return <Redirect to="/adm/dashboard" push />
  }

  const handleLogin = () => {
    login()
  }

  return children({
    handleLogin,
  })
}

export default function LoginComponent() {
  return (
    <LoginPageContainer>
      {props => <LoginPage {...props} />}
    </LoginPageContainer>
  )
}
/* eslint-enable */

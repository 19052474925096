import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Usecases, Modules } from 'core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CollapseIcon, ExpandIcon } from 'assets/images';
import {
  CommonTextSwitch,
  Collapse,
  IconButton,
  HeadlineLabel,
  Box,
  SearchTextField,
  Typography,
  AdditionalTag,
  CommonRadioButton,
  MandatoryLabel,
  FormControl,
  RadioGroup,
  Divider,
  SearchProductTag,
} from 'components/atoms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
} from 'constants/typography';
import clsx from 'clsx';
import {
  NormalEditProductCard,
  ProductInformationDialog,
  TagSelectDialog,
} from 'components/organisms';
import {
  Product,
  ProductDetail,
  Products,
  ProductsQueryParameter,
  ProductTags,
} from 'core/domain/product';
import { ExampleDetail } from 'core/domain/example';
import { LPRegisterRequest, LpRelationProduct } from 'core/domain/lp';
import { useDispatch, useSelector } from 'react-redux';
import { setNewLp } from 'core/modules/newLp';
import { hooks } from 'libs';
import {
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MODE_REFERENCE,
} from 'constants/code';

/**
 * Interface
 *  */
export interface NormalEditSelectProductProps {
  requestParam: ProductsQueryParameter;
  setRequestParam: React.Dispatch<React.SetStateAction<ProductsQueryParameter>>;
  products: Products | null;
  productTags: ProductTags | null;
  productDetail: ProductDetail | null;
  setProductId: React.Dispatch<React.SetStateAction<number>>;
  productExamples: ExampleDetail[] | null;
  handleRecommendedUpdate: () => Promise<void>;
  // handleAddProduct:(id:number) => void
  newLp: LPRegisterRequest | null;
  handleClickFavorite: () => void;
  deleteTag:(val:string) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 59,
    },
    box: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      marginTop: 13,
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      // eslint-disable-next-line
      color: theme.palette.gray[800],
    },
    tags: {
      paddingTop: 5,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    switch: {
      marginLeft: theme.spacing(6) + 3,
      marginRight: theme.spacing(1),
    },
    icon: {
      width: 32,
      height: 32,
      padding: 0,
      '&:hover': {
        '& #Icon_ionic-ios-arrow-back': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        '& #Icon_ionic-ios-arrow-back-2': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        // eslint-disable-next-line
        backgroundColor: theme.palette.green[700],
        borderColor: '#D9EFDB',
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
    collapse: {
      marginBottom: theme.spacing(1),
    },
    product: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    products: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);

/**
 * Presenter
 */

const NormalEditSelectProductPage = (props: NormalEditSelectProductProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [openSelectTagDialog, setOpenSelectTagDialog] = useState(false);
  const [tags, setTags] = useState<Array<string>>([]);
  const [openProductInformationDialog, setOpenProductInformationDialog] =
    useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [productList, setProductList] = useState<Product[]>([]);
  const {
    requestParam,
    setRequestParam,
    products,
    productTags,
    setProductId,
    productDetail,
    handleRecommendedUpdate,
    // handleAddProduct,
    newLp,
    handleClickFavorite,
    deleteTag,
  } = props;
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;

  const handleChangeRequestParam = (key: string, value: string) => {
    setRequestParam({
      ...requestParam,
      [key]: value,
    });
  };

  const handleSubmitTags = (tags: string[]) => {
    setOpenSelectTagDialog(false);
    if (tags) {
      setTags(tags);
      const tag = tags.join(',');
      setRequestParam({
        ...requestParam,
        tags: tag,
      });
    }
  };

  const handleProductInformationDialogOpen = (id: number) => {
    setProductId(id);
    setOpenProductInformationDialog(true);
  };

  const handleAddProduct = (id: number) => {
    if (!productList.length) return;

    const target = productList.find((product) => product.productId === id);
    if (!target) return;

    const lpRelationProduct: LpRelationProduct = {
      productId: id,
      productName: target.productName,
    };
    const productsArray: LpRelationProduct[] = newLp?.products
      ? [...newLp.products, lpRelationProduct]
      : [lpRelationProduct];
    dispatch(
      setNewLp({
        ...newLp,
        products: productsArray,
      }),
    );

    if (!userInfo) return;
    dispatch(
      Usecases.lps.sendGetProductDetail(
        token,
        userInfo.tenantId,
        target.productId,
        CODE_MODE_REFERENCE,
      ),
    );
  };

  const handleProductsLoader = async () => {
    if (!userInfo || !products) return;
    // 1回目は初期表示時であるから2回目からのページでリクエスト
    const pageNumFromSecondReq = page + 1;

    if (Math.floor(products.totalCount / 20) < page) {
      setHasMore(false);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendGetProducts(token, userInfo.tenantId, {
        ...requestParam,
        page: pageNumFromSecondReq,
        row: 20,
      }),
    );

    setPage((prev) => prev + 1);
  };

  const handleCheck = (selectedTag: string, checked: boolean) => {
    if (checked && tags.includes(selectedTag)) {
      setTags(tags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && tags.includes(selectedTag)) {
      const index = tags.indexOf(selectedTag);
      tags.splice(index, 1);
      setTags([...tags]);

      return;
    }
    setTags([...tags, selectedTag]);
  };

  const isSelectedProduct = (id: number) => {
    if (!newLp || !newLp.products || !newLp.products[0]) return false;
    const find = newLp.products.findIndex(
      (product) => product.productId === id,
    );
    if (find > -1) {
      return true;
    }

    return false;
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    setProductList([]);
    setPage(1);
    setHasMore(true);
  }, [requestParam]);

  useEffect(() => {
    if (!products || !products.products) return;

    const product = products.products;
    setProductList((prev) => [...prev, ...product]);
  }, [products]);

  useEffect(()=>{
    if(!requestParam || !requestParam.tags) {
      setTags([]);
      setInitialSelectedTags([]);
  
      return
    }

    setTags(requestParam.tags.split(','));
    setInitialSelectedTags(JSON.parse(JSON.stringify(requestParam.tags.split(','))));
  // eslint-disable-next-line
  },[requestParam?.tags])

  return (
    <Box className={classes.root} mx="auto">
      <TagSelectDialog
        open={openSelectTagDialog}
        onClose={() => {
          setTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setOpenSelectTagDialog(false);
        }}
        tags={productTags?.productTags ? productTags?.productTags : undefined}
        selectedTags={tags}
        handleCheck={handleCheck}
        usedTags={
          productTags?.usedProductTags
            ? productTags?.usedProductTags
            : undefined
        }
        handleSubmit={(tags) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(tags)));
          handleSubmitTags(tags);
        }}
        category="Product"
        tagType="open"
      />
      {productDetail && (
        <ProductInformationDialog
          onClose={() => setOpenProductInformationDialog(false)}
          open={openProductInformationDialog}
          handleRecommendedUpdate={handleRecommendedUpdate}
          productDetail={productDetail}
          handleClickFavorite={handleClickFavorite}
        />
      )}
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <HeadlineLabel text="商材選択" />
        <MandatoryLabel margin="0 0 0 8px" />
      </Box>
      <Box className={classes.box}>
        <Box className={classes.section}>
          <Typography className={classes.title} variant="caption">
            フリーワード検索
          </Typography>
          <Box className={classes.freeWord}>
            <SearchTextField
              handleChangeText={(text) =>
                handleChangeRequestParam('free_word', text)
              }
              label="検索ワードを入力"
            />
          </Box>
          <Typography className={classes.description} variant="caption">
            登録されている情報全体からフリーワードで検索します。
          </Typography>
          <IconButton
            onClick={() => setOpen(!open)}
            style={!open ? { visibility: 'visible' } : { visibility: 'hidden' }}
            className={classes.icon}
          >
            <ExpandIcon />
          </IconButton>
        </Box>
        <Collapse className={classes.collapse} in={open} timeout="auto">
          <Box className={classes.section}>
            <Typography
              className={clsx([classes.title, classes.tagTitle])}
              variant="overline"
            >
              タグ検索
            </Typography>
            <Box className={classes.tags}>
              {tags.map((tag, index) => (
                <SearchProductTag key={index} item={tag} 
                deleteTag={
                  (val:string)=>{
                    deleteTag(val)
                  }
                }
                />
              ))}
              <AdditionalTag click={() => setOpenSelectTagDialog(true)} />
            </Box>
          </Box>
          <Box className={classes.section}>
            <Typography
              className={clsx([classes.title, classes.tagTitle])}
              variant="overline"
            >
              並び順
            </Typography>
            <Box>
              <FormControl>
                <RadioGroup
                  aria-label="order"
                  value={requestParam?.sort_key}
                  onChange={(e) =>
                    handleChangeRequestParam('sort_key', e.target.value)
                  }
                  row
                >
                  <CommonRadioButton value="updatedDate" label="新着順" />
                  <CommonRadioButton
                    value="interestedCount"
                    label="興味アリ数順"
                  />
                  <CommonRadioButton
                    value="downloadCount"
                    label="資料ダウンロード数順"
                  />
                  <CommonRadioButton
                    value="uselpCount"
                    label="ランディングページ利用数順"
                  />
                  <CommonRadioButton
                    value="favoriteCount"
                    label="お気に入り登録数順"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              flexGrow={1}
            >
              <Typography
                className={classes.switch}
                component="span"
                variant="caption"
              >
                オススメ優先
              </Typography>
              <CommonTextSwitch
                checked={requestParam?.recommended}
                handleChange={(checked) =>
                  setRequestParam({
                    ...requestParam,
                    recommended: checked,
                  })
                }
                checkedText="有効"
                uncheckedText="無効"
              />
            </Box>
            <IconButton onClick={() => setOpen(!open)} className={classes.icon}>
              <CollapseIcon />
            </IconButton>
          </Box>
        </Collapse>
        <Divider />
        <Box height="auto">
          <InfiniteScroll
            loadMore={handleProductsLoader}
            hasMore={hasMore}
            initialLoad={false}
            className={classes.products}
          >
            {/* 公開中の商材のみを商材選択リストに表示する */}
            {productList &&
              productList.map(
                (product, index) =>
                  product.productStatus === CODE_MATERIAL_STATUS_RELEASED && (
                    <Box className={classes.product} key={index}>
                      <NormalEditProductCard
                        productId={product.productId}
                        productImage={product.productImage}
                        selected={isSelectedProduct(product.productId)}
                        handleAddProduct={() =>
                          handleAddProduct(product.productId)
                        }
                        click={() =>
                          handleProductInformationDialogOpen(product.productId)
                        }
                        productName={product.productName}
                        interestedCount={
                          product.productReaction.interestedCount
                        }
                        downloadCount={product.productReaction.downloadCount}
                        useLpCount={product.productReaction.useLpCount}
                        favouriteCount={product.favoriteCount}
                      />
                    </Box>
                  ),
              )}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default NormalEditSelectProductPage;
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { Box, SearchTextField, SecondaryButton } from 'components/atoms';
import { UserAuthorityPullDownField } from 'components/organisms';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
  SALAD_BAR_DESKTOP_FONT_SIZE_50,
} from 'constants/typography';
import { ManageUsersSearchCondition } from 'core/domain/user';

/**
 * Interface
 */

export interface ManageUsersSearchFieldProps {
  searchCondition: ManageUsersSearchCondition | null;
  handleCheckStatus?: (status: string) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleChangeSwitch?: (checked: boolean) => void;
  handleOpenUserBulkRegistration?: () => void;
  handleOpenStatusBatchChange?: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&>div:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    tagTitle: {
      marginBottom: 'auto',
    },
    freeWord: {
      width: 245,
    },
    description: {
      marginLeft: theme.spacing(2),
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.gray[800],
    },
    radios: {
      '& .MuiFormControlLabel-label': {
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      },
    },
    radio: {
      color: theme.palette.gray[800],
    },
    switch: {
      marginLeft: 'auto',
      display: 'flex',
    },
    switchDescription: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    button: {
      width: 190,
      height: 42,
      '&>button': {
        padding: theme.spacing(1),
      },
      marginLeft: 'auto',
      display: 'flex',
    },
  }),
);

const ManageUsersSearchField = (props: ManageUsersSearchFieldProps) => {
  const classes = useStyles({});
  const { searchCondition, handleChangeFreeWord, handleOpenStatusBatchChange } =
    props;

  // const { navigate } = hooks.useNavigate();

  return (
    <Box className={classes.root}>
      <Box className={classes.section}>
        <Box className={classes.freeWord} padding={1}>
          <SearchTextField
            label="フリーワード検索"
            handleChangeText={handleChangeFreeWord}
            value={searchCondition?.free_word ? searchCondition.free_word : ''}
          />
        </Box>
        <Box padding={1}>
          <UserAuthorityPullDownField
            error={undefined}
            type="authority"
          />
        </Box>

        <Box className={classes.button}>
          <SecondaryButton
            text="ステータス一括変更"
            click={() =>
              handleOpenStatusBatchChange ? handleOpenStatusBatchChange() : null
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManageUsersSearchField;

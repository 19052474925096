/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Switch } from 'components/atoms'

/**
 * Interface
 */
export interface CommonTextSwitchProps{
  checkedText: string
  uncheckedText: string
  checked?: boolean
  handleChange?: (checked:boolean) => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 49,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: 'none',
    },
    track: {
      background: theme.palette.gray[700],
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        textAlign: 'center',
      },
      '&:before': {
        content: (props:CommonTextSwitchProps)=>`'${props.checkedText}'`,
        fontSize: '11px',
        left: 4,
        opacity: 0,
      },
      '&:after': {
        content: (props:CommonTextSwitchProps)=>`'${props.uncheckedText}'`,
        fontSize: '11px',
        right: 4,
      },
    },
    checked: {
      background: theme.palette.green[800],
      '&$switchBase': {
        transform: 'translateX(26px)',
      },
      '& $thumb': {
        padding: 0,
        color: theme.palette.common.white,
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)

export default function CommonTextSwitch(props: CommonTextSwitchProps) {

  const classes = useStyles(props)
  const { checked, handleChange } = props

  return (
    <Box>
      <Switch
        checked={!!checked}
        onChange={(e) => {
          if(!handleChange) return
          handleChange(e.target.checked)
        }}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          switchBase: classes.switchBase,
          track: classes.track,
          checked: classes.checked, }} />
    </Box>
  )
}/* eslint-enable */

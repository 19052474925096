/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, SecondaryButton, Typography } from 'components/atoms'
import { ErrorOutlineIcon, CloseIcon } from 'assets/images'
import { TYPOGRAPHY } from 'constants/index'

/**
 * Interface
 */

export interface AuthErrorDialogProps {
  open: boolean
  title?: string
  handleClose?: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 632,
      height: 224,
      padding: '32px 24px 0 24px',
      margin: 0,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        width: 64,
        height: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: 582,
      height: 16,
      borderRadius: 4,
      background: theme.palette.gray[400],
      borderColor: theme.palette.gray[700],
      borderStyle: 'solid',
      borderWidth: 1,
      padding: theme.spacing(3),
    },
    users: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    desciption: {
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      padding: 0
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      }
    },
  }),
)

export default function AuthErrorDialog(props: AuthErrorDialogProps) {
  const classes = useStyles({})
  const { open, title, handleClose } = props

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{style:{borderRadius: 16 }}}
      classes={{ paper: classes.dialog}}
      disableBackdropClick
      open={open}
      onClose={() => handleClose && handleClose()}>
      <Box>
        <Box className={classes.icon}>
          <ErrorOutlineIcon />
        </Box>
        <Typography variant="body2" className={classes.title} >{title}</Typography>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={102} height={36}>
              <SecondaryButton icon={<CloseIcon className={classes.closeIcon} />} click={() => handleClose && handleClose()} text="閉じる" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
/* eslint-enable */

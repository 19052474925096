import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  SecondaryButton,
  PrimaryButton,
  MessageAlert,
} from 'components/atoms';
import {
  Content,
  UserIdField,
  UserNameField,
  UserEmailField,
  UserAuthSettings,
  UserNotesFields,
  ConfirmDialog,
  SuccessDialog,
} from 'components/organisms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_300 } from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modules } from 'core';
import { useNavigate } from 'libs/hooks';
import { UserInfoDetailRequest } from 'core/domain/user';
import { setUserInfoDetailReq } from 'core/modules/userInfoDetailReq';
import {
  sendPutUserInfoDetail,
  sendPostUserInfoDetail,
} from 'core/usecases/user';
import { hooks } from 'libs';
import clsx from 'clsx';
import { setError } from 'core/modules/error';

interface UserRegisterProps {
  newUserInfoDetail: UserInfoDetailRequest | null;
  initialUserInfoDetail: UserInfoDetailRequest | undefined;
  handleCancel: () => void;
  handleInitialize: () => void;
}

interface Validations {
  name?: string;
  email?: string;
  auth?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    messageAlert: {
      '& > :first-child': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        top: 64,
      },
    },
    alertVisible: {
      paddingTop: 49,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    fields: {
      borderBottom: '1px solid #c4c4c4',
      marginBottom: theme.spacing(4),
      '& > :nth-child(n)': {
        marginBottom: theme.spacing(5),
      },
      '& > :last-child': {
        marginBottom: theme.spacing(6),
      },
    },
  }),
);

const PATTERN = new RegExp(
  /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
);

const UPPERCASE_PATTERN = new RegExp(/[A-Z]+/);

const UserRegister = (props: UserRegisterProps) => {
  const {
    newUserInfoDetail,
    initialUserInfoDetail,
    handleCancel,
    handleInitialize,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const error = useSelector((state: Modules.AppState) => state.error);
  const token = hooks.useAuth().accessToken;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  const [validationError, setValidationError] = useState<Validations>();
  const [nextPagePath, setNextPagePath] = useState<string>('');
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [goAnotherPage, setGoAnotherPage] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openingAlertType, setOpeningAlertType] = useState<
    'inputError' | 'connectError' | 'connectSuccess' | ''
  >('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const [nextUserRegistDialogOpen, setNextUserRegistDialogOpen] =
    useState(false);

  const handleNameChange = useCallback(
    (v: string) => {
      if (!newUserInfoDetail) return;
      dispatch(
        setUserInfoDetailReq({
          ...newUserInfoDetail,
          userName: v,
        }),
      );
    },
    [dispatch, newUserInfoDetail],
  );

  const handleToggleBtnChange = useCallback(() => {
    if (!newUserInfoDetail) return;
    dispatch(
      setUserInfoDetailReq({
        ...newUserInfoDetail,
        available: !newUserInfoDetail.available,
      }),
    );
  }, [dispatch, newUserInfoDetail]);

  const handleMailAddressChange = useCallback(
    (v: string) => {
      if (!newUserInfoDetail) return;
      dispatch(
        setUserInfoDetailReq({
          ...newUserInfoDetail,
          mailAddress: v,
        }),
      );
    },
    [dispatch, newUserInfoDetail],
  );

  const handleAuthChange = useCallback(
    (
      v: '作成者' | '承認者' | '',
      key: 'landingPage' | 'productIntroductionExample' | 'tenantAdministrator',
    ) => {
      if (!newUserInfoDetail) return;
      if (v === '作成者' && key === 'landingPage')
        dispatch(
          setUserInfoDetailReq({
            ...newUserInfoDetail,
            lpAuthor: !newUserInfoDetail.lpAuthor,
          }),
        );
      if (v === '承認者' && key === 'landingPage')
        dispatch(
          setUserInfoDetailReq({
            ...newUserInfoDetail,
            lpApprover: !newUserInfoDetail.lpApprover,
          }),
        );
      if (v === '作成者' && key === 'productIntroductionExample')
        dispatch(
          setUserInfoDetailReq({
            ...newUserInfoDetail,
            materialAuthor: !newUserInfoDetail.materialAuthor,
          }),
        );
      if (v === '承認者' && key === 'productIntroductionExample')
        dispatch(
          setUserInfoDetailReq({
            ...newUserInfoDetail,
            materialApprover: !newUserInfoDetail.materialApprover,
          }),
        );
      if (v === '' && key === 'tenantAdministrator')
        dispatch(
          setUserInfoDetailReq({
            ...newUserInfoDetail,
            tenantAdmin: !newUserInfoDetail.tenantAdmin,
          }),
        );
    },
    [dispatch, newUserInfoDetail],
  );

  const handleNoteChange = useCallback(
    (v: string, key: string) => {
      if (!newUserInfoDetail) return;
      dispatch(
        setUserInfoDetailReq({
          ...newUserInfoDetail,
          [key]: v,
        }),
      );
    },
    [dispatch, newUserInfoDetail],
  );

  const validationInputValue = () => {
    const validations: Validations = {
      name: undefined,
      email: undefined,
      auth: undefined,
    };

    if (!newUserInfoDetail?.userName)
      validations.name = '氏名を入力してください。';
    if (!newUserInfoDetail?.mailAddress)
      validations.email = 'メールアドレスを入力してください。';
    if (
      newUserInfoDetail?.mailAddress &&
      !PATTERN.exec(newUserInfoDetail.mailAddress)
    ) {
      validations.email = 'メールアドレスの形式で入力してください。';
    }

    if (
      newUserInfoDetail?.mailAddress &&
      UPPERCASE_PATTERN.exec(newUserInfoDetail.mailAddress)
    ) {
      validations.email = 'メールアドレスは小文字で入力してください。';
    }

    // メールアドレス入力済みかつパターンに一致していたら区切ごとの最大文字数チェック
    if (
      newUserInfoDetail?.mailAddress &&
      PATTERN.exec(newUserInfoDetail.mailAddress)
    ) {
      // ＠左側が64文字超えたらerror

      const atLeftcheck =
        newUserInfoDetail?.mailAddress?.split('@') || undefined;

      if (atLeftcheck && atLeftcheck[0]?.length > 64) {
        validations.email = 'ローカル部分は64文字以内で入力してください。';
      }
      // ＠右側を、ドット区切りで配列にしてそれぞれ63文字以内か
      // aaa@bbb.ccc→bbb,ccc
      // aaa@bbb.ccc.ddd→ bbb,ccc,ddd
      if (atLeftcheck) {
        const atRightcheck = atLeftcheck[1]?.split('.') || undefined;
        atRightcheck.map((domain) => {
          if (domain.length > 63) {
            validations.email = 'メールアドレスの形式で入力してください。';
          }
        });
      } // if(atLeftcheck)
    }

    if (
      !newUserInfoDetail?.lpAuthor &&
      !newUserInfoDetail?.lpApprover &&
      !newUserInfoDetail?.materialAuthor &&
      !newUserInfoDetail?.materialApprover &&
      !newUserInfoDetail?.tenantAdmin
    )
      validations.auth = '上記の権限のうち、最低１つは選択してください。';

    if (!Object.values(validations).every((e) => e === undefined)) {
      setOpeningAlertType('inputError');
      setValidationError(validations);

      return;
    }
    // ここでAPI通信
    if (!userInfo || !userInfo.tenantId || !newUserInfoDetail) return;
    const isTenantAdmin = newUserInfoDetail.tenantAdmin;
    if (userId) {
      // 既存ユーザ編集の場合 PUT
      dispatch(
        sendPutUserInfoDetail(
          token,
          userInfo.tenantId,
          userId,
          !isTenantAdmin
            ? newUserInfoDetail
            : {
                ...newUserInfoDetail,
                lpAuthor: true,
                lpApprover: true,
                materialAuthor: true,
                materialApprover: true,
              },
        ),
      );
      setNextUserRegistDialogOpen(true);
    } else {
      // 新規ユーザ編集の場合 Post
      dispatch(
        sendPostUserInfoDetail(
          token,
          userInfo?.tenantId,
          !isTenantAdmin
            ? newUserInfoDetail
            : {
                ...newUserInfoDetail,
                lpAuthor: true,
                lpApprover: true,
                materialAuthor: true,
                materialApprover: true,
              },
        ),
      );
    }
    setIsRegister(true);
    setSuccessDialogOpen(true);
  };

  const handleClick = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
    setValidationError(undefined);
    validationInputValue();
  };

  useEffect(() => {
    if (error && error?.error[0] === '権限がありません。')
      setGoAnotherPage(true);
  }, [error]);

  useEffect(() => {
    if (error) return;
    if (userInfo && !userInfo.tenantAdmin) {
      dispatch(
        setError({
          title: '以下のエラーにより、処理を中止しました。',
          error: ['権限がありません。'],
        }),
      );

      return;
    }

    const checkIsEdit = (): boolean => {
      if (!newUserInfoDetail || !initialUserInfoDetail) return false;
      // JSON.stringifyは順序が違うと値が同じでもfalseになるので同じように並べ替え
      const sortedNewUserInfoDetail = Object.fromEntries(
        Object.entries(newUserInfoDetail).sort(),
      );
      const sortedInitialUserInfoDetail = Object.fromEntries(
        Object.entries(initialUserInfoDetail).sort(),
      );

      return (
        JSON.stringify(sortedNewUserInfoDetail) !==
        JSON.stringify(sortedInitialUserInfoDetail)
      );
    };

    const res = navigate.block((pathname: string) => {
      setNextPagePath(pathname);

      if (error) {
        setIsRegister(false);

        return false;
      }

      if (
        (!newUserInfoDetail && !initialUserInfoDetail) ||
        isRegister ||
        goAnotherPage
      )
        return undefined;

      if (newUserInfoDetail && initialUserInfoDetail) {
        if (checkIsEdit()) {
          setIsConfirmDialogOpen(true);

          return false;
        }
      }

      if (newUserInfoDetail && !initialUserInfoDetail) {
        setIsConfirmDialogOpen(true);

        return false;
      }

      return undefined;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      res();
    };
  }, [
    navigate,
    newUserInfoDetail,
    initialUserInfoDetail,
    isRegister,
    goAnotherPage,
    error,
    dispatch,
    userInfo,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  // アラートが出ると、二重送信防止用のボタンのdisableがfalseにする
  useEffect(() => {
    setIsSubmitting(false);
  }, [openingAlertType, isRegister]);

  // 入力するとアラートが消える
  useEffect(() => {
    setOpeningAlertType('');
  }, [newUserInfoDetail]);

  return (
    <>
      <Box
        mt={5}
        className={clsx(classes.root, {
          [classes.alertVisible]: validationError,
        })}
      >
        {openingAlertType === 'inputError' && (
          <Box className={classes.messageAlert}>
            <MessageAlert text="入力に誤りがあります。" severity="error" />
          </Box>
        )}
        {openingAlertType === 'connectError' && (
          <Box className={classes.messageAlert}>
            <MessageAlert text="保存に失敗しました。" severity="error" />
          </Box>
        )}
        {openingAlertType === 'connectSuccess' && (
          <Box className={classes.messageAlert}>
            <MessageAlert text="保存が完了しました。" severity="success" />
          </Box>
        )}

        <SuccessDialog
          title="保存しました"
          open={successDialogOpen}
          handleClose={() => {
            setSuccessDialogOpen(false);
            if (!userId) setNextUserRegistDialogOpen(true);
            else {
              navigate.navigate('/adm/users');
            }
          }}
        />

        <ConfirmDialog
          open={nextUserRegistDialogOpen && !successDialogOpen && !userId}
          title=""
          text="次のユーザを登録しますか?"
          buttonText="OK"
          handleCancel={() => navigate.navigate('/adm/users')}
          handleSubmit={() => {
            setNextUserRegistDialogOpen(false);
            handleInitialize();
          }}
          disableConfirmText
        />

        <ConfirmDialog
          open={isConfirmDialogOpen}
          title=""
          text="保存されていない入力は破棄されますが、"
          buttonText="終了する"
          handleCancel={() => setIsConfirmDialogOpen(!isConfirmDialogOpen)}
          handleSubmit={() => {
            setGoAnotherPage(true);
            setIsConfirmDialogOpen(false);
            handleCancel();
          }}
        />

        <Content title="ユーザー登録・編集">
          <>
            <Box className={classes.fields}>
              {userId && <UserIdField userId={userId} />}
              <UserNameField
                name={newUserInfoDetail ? newUserInfoDetail.userName : ''}
                status={newUserInfoDetail ? newUserInfoDetail.available : true}
                error={validationError?.name}
                handleNameChange={handleNameChange}
                handleToggleBtnChange={handleToggleBtnChange}
              />
              <UserEmailField
                email={newUserInfoDetail?.mailAddress ?? ''}
                error={validationError?.email}
                handleMailAddressChange={handleMailAddressChange}
                disableLabel
              />
              <UserAuthSettings
                auth={{
                  landingPage: {
                    author: newUserInfoDetail?.lpAuthor,
                    approve: newUserInfoDetail?.lpApprover,
                  },
                  productIntroductionExample: {
                    author: newUserInfoDetail?.materialAuthor,
                    approve: newUserInfoDetail?.materialApprover,
                  },
                  tenantAdministrator: {
                    admin: newUserInfoDetail?.tenantAdmin,
                  },
                }}
                error={validationError?.auth}
                handleAuthChange={handleAuthChange}
              />
              <UserNotesFields
                notes={{
                  note1: newUserInfoDetail ? newUserInfoDetail.note1 : '',
                  note2: newUserInfoDetail ? newUserInfoDetail.note2 : '',
                  note3: newUserInfoDetail ? newUserInfoDetail.note3 : '',
                }}
                handleNoteChange={handleNoteChange}
              />
            </Box>
            <Grid container spacing={2} justify="center">
              <Grid item xs={4}>
                <SecondaryButton
                  text="キャンセル"
                  click={() => navigate.navigateReplace('/adm/users')}
                />
              </Grid>
              <Grid item xs={4}>
                <PrimaryButton
                  text="保存"
                  click={handleClick}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </>
        </Content>
      </Box>
    </>
  );
};

export default UserRegister;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';

import { MenuProps } from '@material-ui/core/Menu';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  PrimaryButton,
} from 'components/atoms';
import { SALAD_BAR_GRAY_500, SALAD_BAR_PINK_800 } from 'constants/color';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import clsx from 'clsx';
import { CloseIcon } from '../../../assets/images';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

/**
 * Interface
 */
export interface HeaderDropMenuProps {
  name: string;
  headerName?: string;
  listButtonName: string;
  logo?: React.ReactNode;
  unreadCount: number;
  disable: boolean;
  handleClickListHeaderButton?: () => void;
  filteredItem?: any;
  filteredItemLength: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'normal',
      fontSize: '12px',
      boxShadow: 'none',
      fill: '#82827e',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        boxShadow: 'none',
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
      '&:selected': {
        color: theme.palette.primary.main,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiCheckbox-root': {
        color: '#707070',
      },
      '& .MuiIconButton-label': {
        width: '15px',
        height: '15px',
        backgroundColor: 'white',
      },
    },
    item: {
      backgroundColor: SALAD_BAR_GRAY_500,
      height: 42,
      width: 375,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
      justifyContent: 'space-between',
    },
    active: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    icon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    boldtext: {
      fontSize: '14px',
      color: '#262622',
      fontWeight: 'bold',
      marginLeft: '20px',
    },
    closeIcon: {
      marginright: '20px',
      backgroundColor: '#EDEDE6',
    },
    btnbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '44px',
      width: '375px',
      position: 'relative',
    },
    btn: {
      height: 27,
      width: 177,
      '& .MuiButton-label': {
        fontSize: 13,
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        color: 'white',
        backgroundColor: SALAD_BAR_PINK_800,
        border: '2px solid white',
        left: 0,
        right: 'auto',
        transform: 'scale(1) translate(12px, -36%)',
        fontSize: 10,
      },
    },
    noBadge: {
      '& .MuiBadge-badge': {
        display: 'none',
      },
    },
    button: {
      height: 27,
      minWidth: 177,
      padding: 0,
      color: 'white',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      backgroundColor: theme.palette.green[900],
      position: 'absolute',
      top: '20%',
      left: '25%',
      '&:hover': {
        background: theme.palette.green[900],
      },
    },
    autoSizerContainer: {
      height: '610px',
      width: '393px',
      marginLeft: '18px',
    },
  }),
);

const StyledMenu = withStyles({
  paper: {
    top: '64px !important',
    border: '1px solid #d3d4d5',
    borderRadius: '0px',
    left: 'auto !important',
    right: 0,
    '& ul': {
      padding: 0,
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: 0,
    fontWeight: 'normal',
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
    '&:selected': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}))(MenuItem);

export default function HeaderDropMenu(props: HeaderDropMenuProps) {
  const {
    name,
    headerName,
    logo,
    listButtonName,
    unreadCount,
    disable,
    handleClickListHeaderButton,
    filteredItem,
    filteredItemLength,
  } = props;
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={clsx(classes.root, { [classes.active]: anchorEl })}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="inherit"
        onClick={handleClick}
      >
        <div>
          <Badge
            className={clsx(classes.badge, {
              [classes.noBadge]: unreadCount === 0,
            })}
            badgeContent={unreadCount}
            max={100}
            overlap="rectangle"
          >
            <div className={clsx(classes.icon, { [classes.active]: anchorEl })}>
              {logo}
            </div>
          </Badge>
          <div style={{ height: '21px' }}>{name}</div>
        </div>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        <StyledMenuItem className={classes.root}>
          <Box>
            <Box className={classes.item}>
              <Box className={classes.boldtext}>{headerName || name}</Box>
              <Box>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.btnbox}>
              <Box className={classes.btn}>
                <PrimaryButton
                  disabled={disable}
                  text={listButtonName}
                  click={() => {
                    handleClickListHeaderButton &&
                      handleClickListHeaderButton();
                    handleClose();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </StyledMenuItem>
        <StyledMenuItem className={classes.root} onClick={() => handleClose()}>
          {filteredItem && filteredItemLength > 0 &&
            <Box className={classes.autoSizerContainer}>
              <AutoSizer>
              {({ height, width }: { height: number, width: number}) => (
                <List
                  height={height}
                  itemCount={filteredItemLength}
                  itemSize={86}
                  overscanCount={10}
                  width={width}
                >
                  {filteredItem}
                </List>
              )}
              </AutoSizer>
            </Box>
          }
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
/* eslint-enable */

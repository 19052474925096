import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NoticesTargetType } from 'core/domain/notices';
import Index from '../components/pages/TenantDashboard';
import { sendGetSettingNotices } from '../core/usecases/settings';
import { hooks } from '../libs';
import { Modules } from '../core';
import { sendGetUsages, sendGetUsagesHistory } from '../core/usecases/dashboards';
import { sendGetTenantDashboardNotices } from '../core/usecases/notices';
import { clearUsage } from '../core/modules/usage';
import { clearSettingNotices } from '../core/modules/settingNotices';
import { clearTenantDashboardNotices } from '../core/modules/tenantDashboardNotices';
import { clearUsagesHistory } from '../core/modules/usages';

const TenantDashboardPageContainer = () => {
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;

  const tenantId = useSelector(
    (state: Modules.AppState) => state.userInfo?.tenantId,
    shallowEqual,
  );

  useEffect(() => {
    if (!token || !tenantId) return;
    dispatch(
      sendGetSettingNotices(token, tenantId, {
        page: 1,
        row: 3,
        status: [1, 2],
        target: NoticesTargetType.serviceNotices,
      }),
    );
    dispatch(sendGetUsages(token, tenantId));
    dispatch(sendGetTenantDashboardNotices(token, tenantId));

    const todayYear = new Date().getFullYear().toString();
    dispatch(sendGetUsagesHistory(token, tenantId, todayYear));
  }, [dispatch, token, tenantId]);

  useEffect(
    () => () => {
      dispatch(clearUsage());
      dispatch(clearSettingNotices());
      dispatch(clearTenantDashboardNotices());
      dispatch(clearUsagesHistory());
    },
    [dispatch],
  );

  return <Index />;
};

export default TenantDashboardPageContainer;

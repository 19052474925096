import { CustomersQueryParameter } from 'core/domain/customer';

const initialState = null;

export const createInitialState = (): CustomersQueryParameter | null =>
  initialState;

export const SET_CUSTOMERS_QUERY_PARAMETER =
  'customers-query-parameter/set' as const;
export const CLEAR_CUSTOMERS_QUERY_PARAMETER =
  'customers-query-parameter/clear' as const;

export const setCustomersQueryParameter = (data: CustomersQueryParameter) => ({
  type: SET_CUSTOMERS_QUERY_PARAMETER,
  payload: {
    data,
  },
});

export const clearCustomersQueryParameter = () => ({
  type: CLEAR_CUSTOMERS_QUERY_PARAMETER,
});

type SetCustomersQueryParameterAction = ReturnType<
  typeof setCustomersQueryParameter
>;
type ClearCustomersQueryParameterAction = ReturnType<
  typeof clearCustomersQueryParameter
>;
type Action =
  | SetCustomersQueryParameterAction
  | ClearCustomersQueryParameterAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMERS_QUERY_PARAMETER:
      return action.payload.data;
    case CLEAR_CUSTOMERS_QUERY_PARAMETER:
      return initialState;
    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  FormControl,
  RadioGroup,
  CommonRadioButton,
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  IconButton,
  Checkbox,
  Typography,
  LpAdminSearchTextField,
  FormControlLabel,
  FormGroup,
  SecondaryButton,
} from 'components/atoms';
import { CloseIcon, CloseWhiteIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Modules, Usecases } from 'core';
import { useDispatch, useSelector } from 'react-redux';
import { hooks } from 'libs';
import { UserInfoDetail } from 'core/domain/user';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

/**
 * Interface
 */
export interface ImageSelectDialogProps {
  open: boolean;
  handleCancel: () => void;
  dialogType: number;
  selectManager: (data: Array<UserInfoDetail>) => void;
  selectedUsers?: Array<UserInfoDetail>;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    box: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 8,
      margin: 12,
      width: '95%',
      border: `1px solid ${theme.palette.gray[600]}`,
    },

    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    scroll: {
      overflow: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 470px)',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: 'max(-9%, -50px)',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      borderColor: theme.palette.green[800],
    },
    img: {
      width: 152,
      height: 152,
      objectFit: 'cover',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.green[800],
      },
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        width: 64,
        height: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
    autoSizerContainer: {
      height: 'calc(100vh - 470px)',
      width : '857px',
      overflow: 'hidden',
    },
  }),
);

const LpManagerSelectDialog = (props: ImageSelectDialogProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;
  const { open, handleCancel, selectManager, dialogType, selectedUsers } =
    props;
  const [freeWord, setFreeWord] = useState('');
  // 担当者変更用
  const [selectedUser, setSelectedUser] = useState<Array<UserInfoDetail>>([]);
  // 担当者絞り込み用
  const [selectedUserTag, setSelectedUserTag] = useState<Array<UserInfoDetail>>(
    [],
  );
  const users = useSelector((state: Modules.AppState) => state.users);
  const handleChangeFreeWord = (text: string) => setFreeWord(text);
  // 担当者変更時の「決定ボタン」活性非活性
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  // 選択状態が残るので選択をクリア
  useEffect(() => {
    // 残っているフリーワードの値クリア
    setFreeWord('');
    // 絞り込み
    if (dialogType === 2) {
      if (!selectedUsers) {
        setSelectedUserTag([]);
      } else {
        setSelectedUserTag(selectedUsers);
      }

      return;
    }

    setSelectedUser([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dialogType]);

  // 決定ボタン活性非活性
  useEffect(() => {
    if (selectedUser.length < 1) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.user.sendGetUsers(token, userInfo.tenantId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(e.target.value);
    setSelectedUser([...users.users.filter((item) => item.userId === id)]);
  };

  const handleSelectCheckBox = (checked: boolean, data: UserInfoDetail) => {
    if (checked) {
      setSelectedUserTag([...selectedUserTag, data]);
    } else {
      setSelectedUserTag(
        selectedUserTag.filter((item) => item.userId !== data.userId),
      );
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.user.sendGetFreeWordUsers(token, userInfo.tenantId, freeWord),
    );

    // ダイアログ内検索エリアで検索時、選択をクリア
    if (dialogType === 1) {
      setSelectedUser([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeWord]);

  const searchRow = React.memo((
    { index, style } :
    { index: number, style: React.CSSProperties | undefined }
  ) => (
    <FormControlLabel
      style={style}
      className={classes.checkbox}
      control={
        <Checkbox
          checked={Boolean(
            selectedUserTag.filter(
              (i) => i.userId === users.users[index].userId,
            ).length,
          )}
          onChange={(e) =>
            handleSelectCheckBox(e.target.checked, users.users[index])
          }
        />
      }
      label={`${users.users[index].userName}/${users.users[index].mailAddress || ''}`}
      key={index}
    />
  ));

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box px={5}>
        <Typography>氏名またはメールアドレスを入力してください</Typography>
        <Box p={3} className={classes.box}>
          <LpAdminSearchTextField
            handleChangeText={handleChangeFreeWord}
            value={freeWord}
          />
          <Typography>選択してください</Typography>
          <Box px={5} pb={2} className={classes.scroll}>
            {dialogType === 1 ? (
              <FormControl>
                <RadioGroup
                  aria-label="users"
                  value={String(selectedUser[0]?.userId)}
                  onChange={handleSelectRadioButton}
                >
                  {users.users
                    .filter((item) => item.lpAuthor)
                    .map((item, index) => (
                      <CommonRadioButton
                        key={index}
                        value={String(item.userId)}
                        label={`${item.userName}/${item.mailAddress || ''}`}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            ) : (
              ''
            )}

            {dialogType === 2 ? (
              <FormGroup>
                {users.users && users.users.length > 0 && (
                  <Box className={classes.autoSizerContainer}>
                    <AutoSizer>
                    {({ height } : { height: number }) =>(
                      <List
                        height={height}
                        itemCount={users.users.length}
                        itemSize={42}
                        overscanCount={10}
                        width='857px'
                      >
                        {searchRow}
                      </List>
                    )}
                    </AutoSizer>
                  </Box>
                )}
              </FormGroup>
            ) : (
              ''
            )}
          </Box>
        </Box>
        {dialogType === 1 && (
          // 担当者変更
          <DialogActions className={classes.actions}>
            <Box width={332} height={48}>
              <SecondaryButton
                click={handleCancel}
                icon={<CloseIcon className={classes.closeIcon} />}
                text="キャンセル"
              />
            </Box>
            <Box display="flex" mt={0}>
              <Box ml={2} width={332} height={48}>
                <PrimaryButton
                  disabled={buttonDisabled}
                  click={() =>
                    selectManager(
                      selectedUser === undefined ? [] : selectedUser,
                    )
                  }
                  text="決定"
                />
              </Box>
            </Box>
          </DialogActions>
        )}
        {dialogType === 2 && (
          // 絞り込み
          <DialogActions className={classes.actions}>
            <Box width={332} height={48}>
              <SecondaryButton
                click={handleCancel}
                icon={<CloseIcon className={classes.closeIcon} />}
                text="キャンセル"
              />
            </Box>
            <Box display="flex" mt={0}>
              <Box ml={2} width={332} height={48}>
                <PrimaryButton
                  click={() =>
                    selectManager(
                      selectedUserTag === undefined ? [] : selectedUserTag,
                    )
                  }
                  text="決定"
                />
              </Box>
            </Box>
          </DialogActions>
        )}
      </Box>

      <IconButton onClick={handleCancel} className={classes.closeButton}>
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
};

export default LpManagerSelectDialog;
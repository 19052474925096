import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from 'components/atoms';
import { AppLogoIcon } from 'assets/images';
import HeaderBarItem from 'components/molecules/HeaderBarItem';
import { useSelector } from 'react-redux';
import { Modules } from 'core';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.text.secondary,
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      borderColor: theme.palette.gray[400],
    },
    inner: {
      height: '64px', // FIXME: constants で管理する
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(3)}px`,
    },
    logoImage: {
      width: '116px',
      height: '27px',
    },
    tenantNameText: {
      fontSize: '14px',
      color: theme.palette.gray[800],
      marginLeft: '15px',
      bottom: '5px',
      position: 'relative',
    },

    environmentText: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#F04848',
      marginLeft: '15px',
      bottom: '5px',
      position: 'relative',
    },
  }),
);

/**
 * Presenter
 */

const HeaderBar = React.memo(() => {
  const classes = useStyles({});
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);

  return (
    <header>
      <Box className={classes.root}>
        <div className={classes.inner}>
          <Box>
            <img src={AppLogoIcon} className={classes.logoImage} />

            <span className={classes.tenantNameText}>
              {userInfo?.tenantName}
            </span>

            {
              // eslint-disable-next-line no-restricted-globals
              /localhost/.exec(location.hostname) ? (
                <span className={classes.environmentText}>localhost</span>
              ) : (
                <></>
              )
            }

            {
              // eslint-disable-next-line no-restricted-globals
              /m.dev.salad-bar.net/.exec(location.hostname) ? (
                <span className={classes.environmentText}>開発環境</span>
              ) : (
                <></>
              )
            }

            {
              // eslint-disable-next-line no-restricted-globals
              /m.stg.salad-bar.net/.exec(location.hostname) ? (
                <span className={classes.environmentText}>検証環境1</span>
              ) : (
                <></>
              )
            }

            {
              // eslint-disable-next-line no-restricted-globals
              /m.stg2.salad-bar.net/.exec(location.hostname) ? (
                <span className={classes.environmentText}>検証環境2</span>
              ) : (
                <></>
              )
            }
          </Box>

          <HeaderBarItem />
        </div>
      </Box>
    </header>
  );
});
export default HeaderBar;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */

import { LpProductDetailCounts } from 'core/domain/lp';

const initialState = null;

export const createInitialState = (): LpProductDetailCounts | null =>
  initialState;

export const SET_LP_PRODUCT_DETAIL_COUNTS =
  'LpProductDetailCounts/set' as const;
export const CLEAR_LP_PRODUCT_DETAIL_COUNTS =
  'LpProductDetailCounts/clear' as const;

export const setLpProductDetailCounts = (data: LpProductDetailCounts) => ({
  type: SET_LP_PRODUCT_DETAIL_COUNTS,
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data,
  },
});

export const clearLpProductDetailCounts = () => ({
  type: CLEAR_LP_PRODUCT_DETAIL_COUNTS,
});

type SetLpProductDetailCountsAction = ReturnType<
  typeof setLpProductDetailCounts
>;
type ClearLpProductDetailCountsAction = ReturnType<
  typeof clearLpProductDetailCounts
>;
type Action = SetLpProductDetailCountsAction | ClearLpProductDetailCountsAction;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_LP_PRODUCT_DETAIL_COUNTS:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return action.payload.data;
    case CLEAR_LP_PRODUCT_DETAIL_COUNTS:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return initialState;
    default:
      return state;
  }
};

import { ApprovalStatus } from 'core/domain/dashboard';

const initialState = null;

export const createInitialState = (): ApprovalStatus | null => initialState;

export const SET_APPROVAL_STATUS = 'approval-status/set' as const;
export const CLEAR_APPROVAL_STATUS = 'approval-status/clear' as const;

export const setApprovalStatus = (data: ApprovalStatus) => ({
  type: SET_APPROVAL_STATUS,
  payload: {
    data,
  },
});

export const clearApprovalStatus = () => ({
  type: CLEAR_APPROVAL_STATUS,
});

type SetApprovalStatusAction = ReturnType<typeof setApprovalStatus>;
type ClearApprovalStatusAction = ReturnType<typeof clearApprovalStatus>;
type Action = SetApprovalStatusAction | ClearApprovalStatusAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_APPROVAL_STATUS:
      return action.payload.data;
    case CLEAR_APPROVAL_STATUS:
      return initialState;
    default:
      return state;
  }
};

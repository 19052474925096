import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  IconButton,
  Typography,
  SecondaryButton,
} from 'components/atoms';
import { CloseIcon, CloseWhiteIcon, ErrorOutlineIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Trash } from 'core/domain/trashes';

/**
 * Interface
 */
export interface TrashesDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (id?: number) => void;
  checkedTrashes: Array<Trash>;
  dialogType: number;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    box: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 8,
      margin: 12,
      width: '93%',
      border: `1px solid ${theme.palette.gray[600]}`,
      padding: theme.spacing(2),
      textAlign: 'left',
    },
    dialog: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      width: 1024,
      minWidth: 1024,
      margin: 0,
      overflow: 'visible',
    },
    scroll: {
      overflow: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 550px)',
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: 'max(-9%, -50px)',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    ImageCard: {
      margin: theme.spacing(1),
      width: 152,
      height: 152,
    },
    selectedImg: {
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: '2px !important',
      borderColor: theme.palette.green[800],
    },
    img: {
      width: 152,
      height: 152,
      objectFit: 'cover',
      boxSizing: 'border-box',
      borderWidth: 1,
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.green[800],
      },
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        width: 64,
        height: 64,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

const TrashesDialog = (props: TrashesDialogProps) => {
  const classes = useStyles({});
  const {
    open,
    handleCancel,
    handleSubmit,
    checkedTrashes,
    dialogType
  } = props;
  const dialogTypeList = ['削除', '元に戻' ];

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box>
        <Box textAlign="center" className={classes.icon}>
          <ErrorOutlineIcon />
        </Box>
        <Typography variant="body2" className={classes.title}>
          以下{checkedTrashes.length}件のレコードを
          <br />
          {dialogTypeList[dialogType]}しますがよろしいですか？
        </Typography>
        <Box p={1} pb={2} textAlign="center">
          <Box className={classes.box}>
            <Box className={classes.scroll} p={1}>
              <Typography>選択したレコード</Typography>
              {checkedTrashes.map((item, index) => (
                <Typography variant="body2" key={index}>
                  ・{item.title}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
        <DialogActions className={classes.actions}>
          <Box width={332} height={48}>
            <SecondaryButton
              click={handleCancel}
              icon={<CloseIcon className={classes.closeIcon} />}
              text="キャンセル"
            />
          </Box>
          <Box p={0} display="flex" mt={0}>
            <Box ml={2} width={332} height={48}>
              <PrimaryButton click={handleSubmit} text="決定" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
      <IconButton onClick={handleCancel} className={classes.closeButton}>
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
};

export default TrashesDialog;

import {
  Box,
  Grid,
  SubHeadlineLabel,
  ButtonLink,
  OneLineTextField,
  SupportTextLabel,
} from 'components/atoms';

interface CustomerTagsFieldProps {
  value: string;
  handleChange: (v: string) => void;
  setTagSelectDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string;
}

const CustomerTagsField = (props: CustomerTagsFieldProps) => {
  const { value, handleChange, setTagSelectDialogOpen, error } = props;

  return (
    <Box>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <SubHeadlineLabel text="顧客タグ" />
        </Grid>
        <Grid item>
          <ButtonLink
            click={() => setTagSelectDialogOpen(true)}
            text="＋リストから追加する"
          />
        </Grid>
      </Grid>
      <OneLineTextField
        label="タグを入力"
        value={value}
        handleChangeText={handleChange}
        error={error}
      />
      <SupportTextLabel text="複数登録の場合はカンマ(,)で区切ってください。" />
    </Box>
  );
};

export default CustomerTagsField;

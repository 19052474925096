/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useMemo, useCallback } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, SecondaryButton, Typography } from 'components/atoms'
import { SuccessIcon, CloseIcon } from 'assets/images'
import { TYPOGRAPHY } from 'constants/index'
import { MaterialWorkflow } from 'core/domain/material'
import { CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL } from 'constants/code'

/**
 * Interface
 */

export interface OpenedDialogProps {
  open: boolean
  handleClose: () => void
  workflow: MaterialWorkflow
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 632,
      padding: '32px 24px 15px 24px',
      margin: 0,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: 582,
      borderRadius: 4,
      background: theme.palette.gray[400],
      borderColor: theme.palette.gray[700],
      borderStyle: 'solid',
      borderWidth: 1,
      padding: theme.spacing(3),
    },
    users: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    desciption: {
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      padding: 0
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      }
    },
  }),
)

export default function OpenedDialog(props: OpenedDialogProps) {
  const classes = useStyles({})
  const { open, handleClose, workflow } = props

  const approvalUsers = useMemo(() => [workflow.step1ApprovalUserName, workflow.step2ApprovalUserName, workflow.step3ApprovalUserName], [workflow])

  const step = useMemo(() => [workflow.step1Status, workflow.step2Status, workflow.step3Status].findIndex(status =>
      status === CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL
    ) + 1, [workflow])

  const userColor = useCallback((index: number) => {
    if (index + 1 === step) {
      return '#44A557'
    } if (index + 1 > step) {
      return '#82827E'
    }

    return '#262622'
  }, [step])

  const arrowColor = useCallback((index: number) => {
    if (index + 2 === step) {
      return '#44A557'
    } if (index + 1 >= step) {
      return '#82827E'
    }

    return '#262622'
  }, [step])

  const users = useMemo(() => 
    // 該当承認フェーズ以外のユーザは、文字色を変更する
     (
      <Typography variant="caption" className={classes.users}>
        <span>
          承認ルート：
        </span>
        {approvalUsers.map((user, index) => {
          const userLabel =
              (<span style={{ color : userColor(index) }}>
                {user}
              </span>)
          const arrow = index + 1 !== approvalUsers.length ?
            <span style={{ color : arrowColor(index) }}>
              {' > '}
            </span>
            : null

          return <span key={index}>{userLabel}{arrow}</span>
        })}
      </Typography>
    )
  , [approvalUsers, step])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{style:{borderRadius: 16 }}}
      classes={{ paper: classes.dialog}}
      disableBackdropClick
      open={open}
      onClose={() => handleClose()}>
      <Box>
        <Box className={classes.icon}>
          <SuccessIcon />
        </Box>
        <Typography variant="body2" className={classes.title} >承認しました</Typography>
        <Box mt={4} className={classes.content}>
          {users}
        </Box>
        <Box mt={2} className={classes.desciption}>
          {approvalUsers[step - 1] && <Typography variant="caption" >{`次の承認者は「${approvalUsers[step - 1]}」さんです`}</Typography>}
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={102} height={36}>
              <SecondaryButton icon={<CloseIcon className={classes.closeIcon} />} click={() => handleClose()} text="閉じる" />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
/* eslint-enable */

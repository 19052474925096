/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { createSelector } from 'reselect'

import { AppState } from 'core/modules'

function getNetwork(state: AppState) {
  return state.network
}

export const isCommunicating = createSelector(getNetwork, network => 0 < network)
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useEffect } from 'react'
import NormalMainVisualEdit from 'components/pages/NormalMainVisualEdit'
import {useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { hooks } from 'libs'
import { Modules, Usecases } from 'core'
import { clearTokuisakiTitles } from 'core/modules/tokuisakiTitles'
import { clearLpMainVisualImages } from 'core/modules/lpMainVisualImages'

/**
 * Presenter
 */

export default function NormalMainVisualEditContainer() {

  const navigate = hooks.useNavigate()
  const lpDetail = useSelector((state:Modules.AppState) => state.lpDetail)
  const newLp = useSelector((state:Modules.AppState) => state.newLp)
  const normalEditErrors = useSelector((state:Modules.AppState) => state.normalEditErrors)
  const tokuisakiTitles = useSelector((state:Modules.AppState) => state.tokuisakiTitles)
  const lpMainVisualImages = useSelector((state:Modules.AppState) => state.lpMainVisualImages)
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)
  const token = hooks.useAuth().accessToken
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  // 得意先名取得/メインビジュアル取得
  useEffect(() => {
    if (!userInfo) return
    dispatch(Usecases.settings.sendGetTokuisakiTitles(token, userInfo.tenantId))
    dispatch(Usecases.settings.sendGetMainVisualImage(token, userInfo.tenantId))

    return () => {
      dispatch(clearTokuisakiTitles())
      dispatch(clearLpMainVisualImages())
    }
  }, [userInfo])

  /**
   * 挨拶編集画面へ遷移
   */
  const goToEditGreeting = () => {
    navigate.navigate(`${url}/greeting`)
  }

  return <NormalMainVisualEdit
    normalEditErrors={normalEditErrors}
    newLp={newLp}
    lpDetail={lpDetail}
    goNext={goToEditGreeting}
    tokuisakiTitles={tokuisakiTitles}
    lpMainVisualImages={lpMainVisualImages}
  />
}
/* eslint-enable */

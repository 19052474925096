/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Chip, SvgIcon } from 'components/atoms'
import { TagIcon, TagIconHover } from 'assets/images'
import { SALAD_BAR_DESKTOP_FONT_SIZE_50 } from 'constants/typography'


interface ProductTagProps {
  item: string
}
/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      height: 'auto',
      minHeight: 26,
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_50,
      color: theme.palette.green[900],
      background: theme.palette.green[700],
      borderColor: theme.palette.green[700],
      borderWidth: '1px',
      borderStyle: 'solid',
      marginLeft: 2,
      marginRight: 2,
      padding: '4px 0',
      '&:hover': {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.green[900],
      },
      '& .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
    icon: {
      backgroundColor: 'Transparent',
      width: '12px',
      height: '12px',
      marginLeft: '12px',
    }
  }),
)

/**
 * Presenter
 */

export default function ProductTag(props: ProductTagProps) {
  const classes = useStyles({})
  const [hovered, setHovered] = useState(false)
  const {item} = props

  return (
    <Chip
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      icon={<SvgIcon className={classes.icon} component={!hovered ? TagIcon : TagIconHover} />}
      label={item} />
  )
}/* eslint-enable */

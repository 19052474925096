import React, { useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SortDownIcon } from 'assets/images';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  PaginationBar,
  Tooltip,
  Link,
  InquiryStatusLabel,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { LpInquiries } from 'core/domain/lp';
import {
  INQUIRY_TYPE_TEL,
  INQUIRY_TYPE_MAIL,
  INQUIRY_TYPE_OTHER,
  INQUIRY_TYPE_INQUIRY_FORM,
} from 'constants/text';
import {
  CODE_INQUIRY_TYPE_TEL,
  CODE_INQUIRY_TYPE_MAIL,
  CODE_INQUIRY_TYPE_OTHER,
  CODE_INQUIRY_TYPE_INQUIRY_FORM,
} from 'constants/code';
import { formatDateTimeISO8601 } from 'libs/date';
import clsx from 'clsx';

export interface ContactHistoryTableProps {
  lpInquiries?: LpInquiries;
  handleChangeSortParam?: (keyValue: string, orderValue: string) => void;
  lpTitle: string;
  lpCreatUser: string;
  pagination?: boolean;
  clickInquiry?: (id: number) => void;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    head: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    sortLabel: {
      color: theme.palette.gray[900],
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    status: {
      width: 120,
    },
    category: {
      width: 160,
    },
    date: {
      width: 102,
    },
    lp: {
      width: 100,
    },
    createUser: {
      width: 100,
    },
    company: {
      width: 107,
    },
    position: {
      width: 80,
    },
    name: {
      width: 130,
    },
    mail: {
      width: 200,
    },
    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
        fontWeight: 'bold',
        cursor: 'pointer',
      },
    },
  }),
);

interface InquiryTable {
  id: number;
  status: React.ReactNode;
  category: string;
  title: React.ReactNode;
  date: string;
  lp: string;
  createUser: string;
  company: string;
  position: string;
  name: string;
  mail: string;
}

interface TableHeader {
  label: string;
  key: string;
}

const headers: Array<TableHeader> = [
  {
    label: 'ステータス',
    key: 'status',
  },
  {
    label: 'お問い合わせ種別',
    key: 'inquiryType',
  },
  {
    label: 'お問い合わせID',
    key: 'inquiryId',
  },
  {
    label: '受信日時',
    key: 'inquiryDate',
  },
  {
    label: 'お問い合わせ元\nランディングページ',
    key: 'lpName',
  },
  {
    label: 'お問い合わせ元\nランディングページ担当者',
    key: 'lpCreatedUser',
  },
  {
    label: '貴社名',
    key: 'companyName',
  },
  {
    label: '役職',
    key: 'jobTitle',
  },
  {
    label: 'お名前',
    key: 'customerName',
  },
  {
    label: 'メールアドレス',
    key: 'mailAddress',
  },
];

const createData = (
  id: number,
  status: React.ReactNode,
  category: string,
  title: React.ReactNode,
  date: string,
  lp: string,
  createUser: string,
  company: string,
  position: string,
  name: string,
  mail: string,
): InquiryTable => ({
    id,
    status,
    category,
    title,
    date,
    lp,
    createUser,
    company,
    position,
    name,
    mail,
  })

interface InquiryType {
  type: number;
  label: string;
}

const INQUIRY_TYPES: Array<InquiryType> = [
  {
    type: CODE_INQUIRY_TYPE_TEL,
    label: INQUIRY_TYPE_TEL,
  },
  {
    type: CODE_INQUIRY_TYPE_MAIL,
    label: INQUIRY_TYPE_MAIL,
  },
  {
    type: CODE_INQUIRY_TYPE_OTHER,
    label: INQUIRY_TYPE_OTHER,
  },
  {
    type: CODE_INQUIRY_TYPE_INQUIRY_FORM,
    label: INQUIRY_TYPE_INQUIRY_FORM,
  },
];

type Order = 'asc' | 'desc';

interface SortParam {
  sortKey?: string;
  sortOrder?: Order;
}

 const ContactHistoryTable = (props: ContactHistoryTableProps) => {
  const {
    clickInquiry,
    lpInquiries,
    handleChangeSortParam,
    lpTitle,
    lpCreatUser,
    pagination,
  } = props;
  const [currentInquiryPage, setCurrentInquiryPage] = useState<number>(1);
  const [sortParam, setSortParam] = useState<SortParam>({
    sortKey: undefined,
    sortOrder: 'asc',
  });

  const inquiryTypeLabel = (type: number) => {
    const inquiryType = INQUIRY_TYPES.find(
      (inquiryType) => inquiryType.type === type,
    );
    if (!inquiryType) return '';

    return inquiryType.label;
  };

  const inquiries = useMemo<Array<InquiryTable>>(() => {
    if (!lpInquiries || !lpInquiries.inquiries) return [];

    return lpInquiries.inquiries.map((inquiry) =>
      createData(
        inquiry.inquiryId,
        <InquiryStatusLabel status={inquiry.newestSupportType} />,
        inquiryTypeLabel(inquiry.inquiryType),
        <ButtonLink text={inquiry.inquiryDetail} />,
        formatDateTimeISO8601(inquiry.inquiryDate),
        lpTitle,
        lpCreatUser,
        inquiry.companyName,
        inquiry.jobTitle,
        inquiry.customerName,
        inquiry.mailAddress,
      ),
    );
  }, [lpInquiries, lpTitle, lpCreatUser]);

  const displayInquiries = useMemo(() => {
    const index = (currentInquiryPage - 1) * 4;

    return inquiries.slice(index, index + 4);
  }, [inquiries, currentInquiryPage]);

  const handleChangeInquiryPage = (page: number) => {
    setCurrentInquiryPage(page);
  };

  const handleClickHeader = (sortKey: string) => {
    if (!handleChangeSortParam) return;
    const newSortParam = {
      sortKey,
      sortOrder: 'asc' as Order,
    };
    if (sortParam.sortKey === sortKey) {
      newSortParam.sortKey = sortKey;
      const order: Order = sortParam.sortOrder === 'asc' ? 'desc' : 'asc';
      newSortParam.sortOrder = order;
    }

    setSortParam(newSortParam);

    handleChangeSortParam(newSortParam.sortKey, newSortParam.sortOrder);
  };

  const classes = useStyles({});

  return (
    <div>
      <TableContainer className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>
                  <TableSortLabel
                    active
                    direction={sortParam?.sortOrder}
                    IconComponent={() =>
                      header.key === sortParam?.sortKey &&
                      sortParam?.sortOrder === 'asc' ? (
                        <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                      ) : (
                        <SortDownIcon />
                      )
                    }
                    onClick={() => handleClickHeader(header.key)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {displayInquiries.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Box className={classes.status}>{row.status}</Box>
                </TableCell>
                <TableCell>
                  <Box className={classes.category}>{row.category}</Box>
                </TableCell>
                <TableCell className={classes.link}>
                  {/* <ButtonLink
                    text={`${row.id}`}
                    tooltipText={`${row.id}`}
                    
                    click={() => clickInquiry && clickInquiry(row.id)}
                  /> */}
                  <Link underline="always" onClick={() => clickInquiry && clickInquiry(row.id)}>{row.id}</Link>
                </TableCell>
                <TableCell>
                  <Box className={classes.date}>{row.date}</Box>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.lp} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.lp)}>
                      {row.lp}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.createUser || ''} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.createUser)}>
                      {row.createUser}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.company || ''} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.company)}>
                      {row.company}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.position || ''} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.position)}>
                      {row.position}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.name || ''} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.name)}>
                      {row.name}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.mail} arrow placement="top">
                    <Box className={clsx(classes.overflow, classes.mail)}>
                      {row.mail}
                    </Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Box mt={5}>
          <PaginationBar
            currentPage={currentInquiryPage}
            maxPage={Math.ceil(inquiries.length / 4)}
            handleChangePage={(page: number) => handleChangeInquiryPage(page)}
          />
        </Box>
      )}
    </div>
  );
}

export default ContactHistoryTable;
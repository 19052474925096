import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modules, Usecases } from 'core';
import ProductRegisterEdit from 'components/pages/ProductRegisterEdit';
import { clearProductDetail } from 'core/modules/productDetail';
import { clearNewProduct, setNewProduct } from 'core/modules/newProduct';
import { clearProductTags } from 'core/modules/productTags';
import { MaterialImageRegisterRequest } from 'core/domain/material';
import { hooks } from 'libs';
import { convertNewProductToLpView } from 'libs/converter';
import { setProductTagsRequest } from 'core/modules/productTagsRequest';
import { CODE_MODE_EDIT } from 'constants/code';
import { removeIncorrectTagEntry } from 'libs/validation';
import { ProductRegisterRequest } from 'core/domain/product';

/**
 * Presenter
 */
const ProductRegisterEditPageContainer = () => {
  const dispatch = useDispatch();
  const { productId } = useParams<{ productId: string }>();
  const navigate = hooks.useNavigate();

  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
    shallowEqual,
  );
  const newProduct = useSelector(
    (state: Modules.AppState) => state.newProduct,
    shallowEqual,
  );
  const productTags = useSelector(
    (state: Modules.AppState) => state.productTags,
    shallowEqual,
  );
  const productTagsRequest = useSelector(
    (state: Modules.AppState) => state.productTagsRequest,
    shallowEqual,
  );

  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
    shallowEqual,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const lpSettings = useSelector(
    (state: Modules.AppState) => state.lpSettings,
    shallowEqual,
  );

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;

  // 編集の場合、商材詳細情報取得
  useEffect(() => {
    if (productId && userInfo) {
      dispatch(
        Usecases.products.sendGetProductDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(productId, 10),
          CODE_MODE_EDIT,
        ),
      );
    }

    return () => {
      dispatch(clearProductDetail());
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [userInfo]);

  // 商材タグ取得時のリクエストパラムを設定
  // 編集画面なので「tagType: 'edit' 」とする
  useEffect(() => {
    dispatch(setProductTagsRequest({ tagType: 'edit', sortKey: 'tag' }));
  }, [dispatch]);

  // 取得した商材詳細情報を登録用のデータに変換
  useEffect(() => {
    if (productDetail) {
      const images = productDetail.productImages
        ? (productDetail.productImages?.map((image) => ({
            materialImageId: image.materialImageId,
            materialImageType: image.materialImageType,
            url: image.url,
            thumbnailUrl: image.thumbnailUrl,
          })) as MaterialImageRegisterRequest[])
        : undefined;

      dispatch(
        setNewProduct({
          productName: productDetail.productName,
          productTag: productDetail.productTag,
          contact: productDetail.contact,
          catchCopy: productDetail.catchCopy,
          subCopy: productDetail.subCopy,
          productContent: productDetail.productContent,
          productDocuments: productDetail.productDocuments,
          productExternalSite: productDetail.productExternalSite,
          productImages: images,
          productPeriod: productDetail.productPeriod,
        }),
      );
    }

    return () => {
      dispatch(clearNewProduct());
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [productDetail]);

  // 商材タグ取得
  useEffect(() => {
    if (!userInfo || !productTagsRequest.tagType || !productTagsRequest.sortKey)
      return undefined;

    dispatch(
      Usecases.products.sendGetProductTags(
        token,
        userInfo.tenantId,
        productTagsRequest,
      ),
    );

    return () => {
      dispatch(clearProductTags());
    };
  }, [productTagsRequest, userInfo, dispatch, token]);

  // 商材登録
  const handleProductRegister = useCallback(
    async (draft: boolean, tag: string) => {
      if (!newProduct || !userInfo) return;

      const requestData: ProductRegisterRequest = {
        ...newProduct,
        productTag: removeIncorrectTagEntry(tag),
        productDocuments: newProduct.productDocuments || [],
        productImages: newProduct.productImages || [],
      };

      if (productId) {
        // eslint-disable-next-line   @typescript-eslint/await-thenable
        await dispatch(
          Usecases.products.sendPutProducts(
            token,
            userInfo.tenantId,
            productId,
            requestData,
            draft,
          ),
        );
      } else {
        // eslint-disable-next-line   @typescript-eslint/await-thenable
        await dispatch(
          Usecases.products.sendPostProduct(
            token,
            userInfo.tenantId,
            requestData,
            draft,
          ),
        );
      }
      navigate.navigatePop();
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [newProduct, productId, userInfo],
  );

  // 商材登録情報をLPプレビュー用データに変換
  const previewData = useMemo(
    () => ({
      ...convertNewProductToLpView(
        newProduct,
        lpSettings,
        inquiriesGreetText,
        inquiryForms,
      ),
      isPreview: true,
    }),
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [newProduct],
  );

  return (
    <ProductRegisterEdit
      newProduct={newProduct}
      productDetail={productDetail}
      productTags={productTags}
      handleProductRegister={handleProductRegister}
      previewData={previewData}
      error={error}
    />
  );
};

export default ProductRegisterEditPageContainer;

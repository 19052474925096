import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  // DialogActions,
  SecondaryButton,
  PrimaryButton,
  Typography,
  // Divider,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  // DialogTitle,
} from 'components/atoms';
import { Modules } from 'core';

import { TYPOGRAPHY } from 'constants/index';

import { useSelector } from 'react-redux';
import { CloseWhiteIcon } from 'assets/images';
import { useState } from 'react';

/**
 * Interface
 */
export interface UsersStatusBatchChangeComfirmProps {
  open: boolean;
  userStatus: boolean;
  handleCancel: () => void;
  handleSubmit: (available: boolean) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   width: '100%',
    //   overflow: 'hidden',
    //   overflowX: 'scroll',
    // },
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 1806,
      minHeight: 226,
      padding: '32px 24px 24px 24px',
      margin: 0,
    },
    box: {
      backgroundColor: theme.palette.gray[400],
      borderRadius: 8,
      border: `1px solid ${theme.palette.gray[600]}`,
      padding: `${theme.spacing(1.5)}px`,
    },
    scroll: {
      overflow: 'auto',
      height: 'calc(100vh - 350px)',
    },
    button: {
      width: 190,
      height: 46,
      // marginLeft: 'auto',
      display: 'inline',

      '&>button': {
        padding: theme.spacing(1),
      },
    },
    table: {
      minWidth: 500,
      tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 150,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    detail: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
    closeButton: {
      position: 'fixed',
      right: 30,
      top: 10,
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
  }),
);

const UsersStatusBatchChangeComfirm = (
  props: UsersStatusBatchChangeComfirmProps,
) => {
  const classes = useStyles({});
  const manageUsers = useSelector(
    (state: Modules.AppState) => state.manageUsers,
  );

  const { open, userStatus, handleCancel, handleSubmit } = props;

  // const [radioValue, setRadioValue] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // handleSubmit(openPeriodDate, openPeriodTime);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      onBackdropClick={handleCancel}
    >
      <Typography variant="body2" className={classes.title}>
        ステータスの変更を実行しますか?
      </Typography>
      <Box>
        <Box className={classes.box}>
          <Box className={classes.scroll}>
            <Box marginBottom={1.5}>
              <Box fontWeight="fontWeightBold">変更後のユーザーステータス</Box>
              <Box padding={1.5}>{userStatus ? '有効' : '無効'}</Box>
            </Box>

            {/* <Divider /> */}

            <Box marginTop={1.5}>
              <Box>
                <Box fontWeight="fontWeightBold">選択したユーザー</Box>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '5%' }}>ユーザ名</TableCell>
                        <TableCell style={{ width: '5%' }}>
                          メールアドレス
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {manageUsers &&
                        manageUsers.users
                          .filter((item) => {
                            if (item.checked) return true;

                            return false;
                          })
                          .map((user, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography className={classes.detail}>
                                  {user.userName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.detail}>
                                  {user.mailAddress}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          mt={2}
          display="flex"
          justifyContent="space-around"
          alignContent="center"
          padding={1.5}
          flex-direction="row"
        >
          <Box className={classes.button} display="inline" padding={1.5}>
            <SecondaryButton
              text="編集画面に戻る"
              click={() => handleCancel()}
            />
          </Box>

          <Box className={classes.button} display="inline" padding={1.5}>
            <PrimaryButton
              disabled={isSubmitting}
              text="決定"
              click={() => {
                setIsSubmitting((prev) => !prev);
                handleSubmit(userStatus);
                setTimeout(() => {
                  setIsSubmitting((prev) => !prev);
                }, 1000);
              }}
            />
          </Box>
        </Box>
      </Box>
      <IconButton onClick={handleCancel} className={classes.closeButton}>
        <CloseWhiteIcon />
      </IconButton>
    </Dialog>
  );
};
export default UsersStatusBatchChangeComfirm;

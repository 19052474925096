/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CloseWhiteIcon } from 'assets/images';
import {
  Dialog,
  Box,
  PrimaryButton,
  IconButton,
  DialogActions,
  HeadlineLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';
import { Product } from 'core/domain/product';

/**
 * Interface
 */

export interface RelationProductsDialogProps {
  open: boolean;
  products: Array<Product>;
  relationProductIds: Array<number>;
  handleClose: () => void;
  handleSubmit: (productIds: Array<number>) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(3),
      width: 632,
      height: 399,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    content: {
      display: 'flex',
      width: 598,
      height: 262,
      borderRadius: 4,
      background: theme.palette.gray[400],
      borderColor: theme.palette.gray[700],
      borderStyle: 'solid',
      borderWidth: 1,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginBottom: theme.spacing(1) + theme.spacing(1) / 2,
      overflow: 'hidden',
      overflowY: 'auto',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.gray[800],
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    checkboxGroup: {
      flexWrap: 'nowrap',
    },
  }),
);

/**
 * Presenter
 */

export default function RelationProductsDialog(
  props: RelationProductsDialogProps,
) {
  const classes = useStyles({});
  const { open, products, relationProductIds, handleClose, handleSubmit } =
    props;
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);

  useEffect(() => {
    setSelectedIds(relationProductIds);
  }, []);

  const handleCheck = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((productId) => productId !== id));

      return;
    }

    setSelectedIds([...selectedIds, id]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
    >
      <Box>
        <HeadlineLabel text="関連商材をリストから選択" />
        <Box mt={4} className={classes.content}>
          <FormGroup className={classes.checkboxGroup}>
            {products.map(
              (product, index) =>
                product.productStatus === 5 && (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(product.productId)}
                        onChange={() => handleCheck(product.productId)}
                      />
                    }
                    label={`${product.productId}｜${product.productName}`}
                    key={index}
                  />
                ),
            )}
          </FormGroup>
        </Box>
      </Box>
      <DialogActions>
        <Box width={332} height={48} mx="auto">
          <PrimaryButton text="決定" click={() => handleSubmit(selectedIds)} />
        </Box>
        <IconButton
          className={classes.closeButton}
          onClick={() => handleClose()}
        >
          <CloseWhiteIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
} /* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Dialog, DialogActions, SecondaryButton, Typography, ButtonLink, CopyClipboardButton, PrimaryButton } from 'components/atoms'
import { SuccessIcon, CloseIcon, CheckIcon } from 'assets/images'
import { TYPOGRAPHY } from 'constants/index'
import { openLinkUrl } from 'libs/link'

/**
 * Interface
 */

export interface OpendDialogProps {
  open: boolean
  url: string
  handleMailOpen: () => void
  handleClose: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 806,
      height: 370,
      padding: '64px 24px 0 24px',
      margin: 0,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      height: 16,
      borderRadius: 4,
      background: theme.palette.gray[400],
      borderColor: theme.palette.gray[700],
      borderStyle: 'solid',
      borderWidth: 1,
      padding: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    url: {
      whiteSpace: 'nowrap',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    desciption: {
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      padding: 0
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      }
    },
  }),
)

export default function OpendDialog(props: OpendDialogProps) {
  const classes = useStyles({})
  const { open, url, handleClose,handleMailOpen} = props
  

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{style:{borderRadius: 16 }}}
      classes={{ paper: classes.dialog}}
      disableBackdropClick
      open={open}
      onClose={() => handleClose()}>
      <Box>
        <Box className={classes.icon}>
          <SuccessIcon />
        </Box>
        <Typography variant="body2" className={classes.title} >ランディングページを公開しました</Typography>
        <Box mt={4} className={classes.content} >
          <Box ml={1} display='flex' alignItems="center">
            <Typography variant="caption" className={classes.url} >公開URL：</Typography>
            <ButtonLink tooltipText={url} text={url} click={() => openLinkUrl(url)}/>
          </Box>
          <Box ml={1}><CopyClipboardButton text={url} /></Box>
        </Box>
        <Box mt={2} className={classes.desciption}>
          <Typography variant="caption" >お客さまへ公開したページを「メール配信」でご案内しましょう</Typography>
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton icon={<CloseIcon className={classes.closeIcon} />} click={() => handleClose()} text="閉じる" />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton icon={<CheckIcon />}
              text="メール配信設定へ進む" 
              click={() => handleMailOpen()}
              />

            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
/* eslint-enable */

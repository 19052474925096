import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  PaginationBar,
  PrimaryButton,
  Link,
  Typography,
} from 'components/atoms';
import {
  Content,
  ManageUsersTable,
  ManageUsersSearchField,
  UsersStatusBatchChangeDialog,
  FileUploadDialog,
  SuccessDialog,
} from 'components/organisms';
import { hooks } from 'libs';

import { useSelector, shallowEqual } from 'react-redux';
import { Modules } from 'core';

import { Users } from 'core/domain/user';
import { PlusIcon, BulkIcon } from 'assets/images';

/**
 * Interface
 */

export interface ManageUsersPageProps {
  manageUsers: Users | null;
  rows: number;
  isUsersStatusBatchChangeDialogModalOpen: boolean;
  isComfirmOpen: boolean;
  setComfirmOpen: (value: boolean) => void;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleSelectAuthority?: (freeWord: string) => void;
  handleOpenUserBulkRegistration?: () => void;
  handleOpenStatusBatchChange?: () => void;
  handleCloseStatusBatchChange?: () => void;
  handleUsersStatusBatchChange: (available: boolean) => void;
  fileDialogOpen: boolean;
  setFileDialogOpen: (arg0: boolean) => void;
  handleFileSubmit: (files: File[]) => void;
  onFileUploadDialog: () => void;
  csvUploadSuccessDialogOpen: boolean;
  setCsvUploadSuccessDialogOpen: (state: boolean) => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    button: {
      width: 190,
      height: 46,
      // marginLeft: 'auto',
      display: 'inline',

      '&>button': {
        padding: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */

const ManageUsers = (props: ManageUsersPageProps) => {
  const classes = useStyles({});
  const { navigate } = hooks.useNavigate();
  // const { setCurrentManageUsersUrl } = hooks.useManageUsersNavigation();

  const manageUsers = useSelector(
    (state: Modules.AppState) => state.manageUsers,
    shallowEqual,
  );

  const manageUsersSearchCondition = useSelector(
    (state: Modules.AppState) => state.manageUsersSearchCondition,
    shallowEqual,
  );
  const {
    // manageUsers,
    rows,
    isUsersStatusBatchChangeDialogModalOpen,
    isComfirmOpen,
    setComfirmOpen,
    handleChangePage,
    handleChangeSortKey,
    handleChangeFreeWord,
    // handleSelectAuthority,
    handleOpenStatusBatchChange,
    handleCloseStatusBatchChange,
    handleUsersStatusBatchChange,
    fileDialogOpen,
    setFileDialogOpen,
    handleFileSubmit,
    onFileUploadDialog,
    csvUploadSuccessDialogOpen,
    setCsvUploadSuccessDialogOpen,
  } = props;

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  useState(false);

  // 一括登録テンプレートDLボタン制御
  const [csvTemplateDisabled, setCsvTemplateDisabled] = useState(false);

  const SideButtons = () => (
    <Box display="flex" flexDirection="column" textAlign="right">
      <Box className={classes.sideItems}>
        <PrimaryButton
          text="新規登録"
          icon={<PlusIcon />}
          click={() => navigate('/adm/users/register')}
        />
        <PrimaryButton
          click={() => setFileDialogOpen(true)}
          text="一括登録"
          icon={<BulkIcon />}
        />
      </Box>
      <Box padding={1.0}>
        <Typography variant="caption">
          <Link
            href={`${
              process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT ||
              window.location.origin
            }/csvTemplate/users.csv`}
            underline="always"
            onClick={() => {
              setCsvTemplateDisabled(true);
              setTimeout(() => setCsvTemplateDisabled(false), 1000);
            }}
            style={
              !csvTemplateDisabled
                ? { pointerEvents: 'auto' }
                : { pointerEvents: 'none', color: 'gray' }
            }
          >
            一括登録テンプレート
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box mt={5}>
      <FileUploadDialog
        handleSubmit={handleFileSubmit}
        onClose={onFileUploadDialog}
        open={fileDialogOpen}
        isProduct={false}
        fileType="csv"
      />
      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={() => setSuccessDialogOpen(false)}
      />

      <SuccessDialog
        title="登録処理が完了され次第、お知らせにて通知いたします。"
        text="※件数により反映されるまでに数分かかる場合がございます"
        open={csvUploadSuccessDialogOpen}
        handleClose={() => setCsvUploadSuccessDialogOpen(false)}
      />

      <Content title="ユーザー一覧" sideItem={SideButtons}>
        <>
          <ManageUsersSearchField
            searchCondition={manageUsersSearchCondition}
            handleChangeFreeWord={handleChangeFreeWord}
            handleOpenStatusBatchChange={handleOpenStatusBatchChange}
          />

          <Box className={classes.table}>
            <ManageUsersTable
              clickManageUsers={(id: number, approved: boolean) => {
                if (!approved) {
                  navigate(`/adm/manageUsers/${id}`);
                } else {
                  navigate(`/adm/manageUsers/${id}/approved`);
                }
              }}
              handleChangeSortKey={handleChangeSortKey}
              manageUsersSearchCondition={manageUsersSearchCondition}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  manageUsersSearchCondition
                    ? manageUsersSearchCondition.page
                    : 1
                }
                maxPage={
                  manageUsers ? Math.ceil(manageUsers?.totalCount / rows) : 1
                }
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={manageUsers?.totalCount}
              />
            </Box>
          </Box>

          {handleCloseStatusBatchChange !== undefined ? (
            <UsersStatusBatchChangeDialog
              open={isUsersStatusBatchChangeDialogModalOpen}
              isComfirmOpen={isComfirmOpen}
              setComfirmOpen={setComfirmOpen}
              handleCancel={handleCloseStatusBatchChange}
              handleSubmit={handleCloseStatusBatchChange}
              handleUsersStatusBatchChange={handleUsersStatusBatchChange}
            />
          ) : (
            <></>
          )}
        </>
      </Content>
    </Box>
  );
};
export default ManageUsers;

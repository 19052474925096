import React, { useState, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, ListItem, Typography } from 'components/atoms';
// import ArrowIcon from 'assets/images/inc_arrow_top.svg';
import { ArrowIcon } from 'assets/images';
import { formatDateTimeISO8601 } from 'libs/date';
import { SALAD_BAR_BLUE_900 } from 'constants/color';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/**
 * Interface
 */

export interface MailHistoryListItemProps {
  sendDate: string;
  reserveDate: string;
  name: string;
  message: string;
  subject: string;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      color: theme.palette.gray[800],
      marginRight: 8,
    },
    name: {
      color: theme.palette.gray[800],
      marginLeft: 32,
    },
    boldContents: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      fontWeight: 'bold',
    },
    descriptionOpen: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    descriptionClose: {
      color: theme.palette.gray[900],
    },
    dispArea: {
      position: 'relative',
    },
    openIcon: {
      margin: '0 0 0 auto',
      border: '1px solid gray',
      cursor: 'pointer',
      padding: 3,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
    },
    open: {
      transform: 'rotate(-90deg)',
    },
    close: {
      transform: 'rotate(90deg)',
    },
    btnBlue: {
      color: 'white',
      backgroundColor: SALAD_BAR_BLUE_900,
      maxHeight: '16px',
      width: '56px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
      paddingTop: 4,
      paddingBottom: 4,
      fontSize: '12px',
    },
    dateArea: {
      display: 'flex',
      flexDirection: 'row',
    },
  }),
);

const MailHistoryListItem = (props: MailHistoryListItemProps) => {
  const classes = useStyles({});
  const { sendDate, reserveDate, subject, message, name } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formatName = useMemo(() => {
    let result = '';
    if (isOpen) {
      result = name;
    } else {
      result = `${name.slice(0, 80)}${name.length > 80 ? '...' : ''}`;
    }

    return result;
  }, [name, isOpen]);

  const formatSubject = useMemo(() => {
    let result = '';
    if (isOpen) {
      result = subject;
    } else {
      result = `${subject.slice(0, 80)}${subject.length > 80 ? '...' : ''}`;
    }

    return result;
  }, [subject, isOpen]);

  const formatMessage = useMemo(() => {
    let result = '';
    if (isOpen) {
      result = message;
    } else {
      result = `${message.slice(0, 80)}${message.length > 80 ? '...' : ''}`;
    }

    return result;
  }, [message, isOpen]);

  return (
    <ListItem>
      <Box width="100%" className={classes.dispArea}>
        <Typography variant="caption" className={classes.date}>
          {/* {formatDateTimeISO8601(date)} */}
          <div className={classes.dateArea}>
            {/* 送信済み日時無いなら送信予約日時を表示 */}
            {!sendDate && formatDateTimeISO8601(reserveDate)}
            {!sendDate && reserveDate && (
              <Box className={classes.btnBlue}>予約</Box>
            )}
            {/* 送信済み */}
            {formatDateTimeISO8601(sendDate)}
            <Box
              className={classes.openIcon}
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />} */}
              <ArrowIcon
                className={`${isOpen ? classes.open : classes.close}`}
              />
            </Box>
          </div>
        </Typography>
        <br />

        {/* メール送信履歴オープン時 */}
        {isOpen && (
          <Box display="flex" alignItems="center">
            <Typography variant="caption" className={classes.boldContents}>
              送信先：
            </Typography>

            <Typography variant="body2" className={classes.descriptionOpen}>
              {formatName}
            </Typography>
            <Typography variant="body2" className={classes.descriptionOpen}>
              {`（${formatName.split(',').length}件）`}
            </Typography>
          </Box>
        )}
        {isOpen && formatSubject && (
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.boldContents}>
                メールタイトル：
              </Typography>

              <Typography variant="body2" className={classes.descriptionOpen}>
                {formatSubject}
              </Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.descriptionOpen}>
              〇〇〇〇〇 〇〇〇〇〇〇様
              ※メール本文冒頭には、送信先リストの「お客さま会社」「お客さま氏名」が入ります
            </Typography>
          </>
        )}
        {isOpen && formatMessage && (
          <Typography variant="body2" className={classes.descriptionOpen}>
            {formatMessage}
          </Typography>
        )}

        {/* メール送信履歴クローズ時 */}
        {!isOpen && (
          <Box display="flex" alignItems="center">
            <Typography variant="caption" className={classes.boldContents}>
              送信先：
            </Typography>

            <Typography variant="body2" className={classes.descriptionOpen}>
              {formatName}
            </Typography>
            <Typography variant="body2" className={classes.descriptionOpen}>
              {`（${formatName.split(',').length}件）`}
            </Typography>
          </Box>
        )}
        {!isOpen && formatSubject && (
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.boldContents}>
                メールタイトル：
              </Typography>

              <Typography variant="body2" className={classes.descriptionClose}>
                {formatSubject}
              </Typography>
            </Box>

            <Typography variant="subtitle2" className={classes.descriptionOpen}>
              〇〇〇〇〇 〇〇〇〇〇〇様
              ※メール本文冒頭には、送信先リストの「お客さま会社」「お客さま氏名」が入ります
            </Typography>
          </>
        )}
        {!isOpen && formatMessage && (
          <Typography variant="body2" className={classes.descriptionClose}>
            {formatMessage}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
};

export default MailHistoryListItem;

import { LpOpenSettings } from 'core/domain/lp'

const initialState = null;

export const createInitialState = (): LpOpenSettings | null => initialState;

export const SET_LP_OPEN_SETTINGS = 'lp_open_settings/set' as const;
export const CLEAR_LP_OPEN_SETTINGS = 'lp_open_settings/clear' as const;

export const setLpOpenSettings = (data: LpOpenSettings) => ({
  type: SET_LP_OPEN_SETTINGS,
  payload: {
    data,
  },
});

export const clearLpOpenSettings = () => ({
  type: CLEAR_LP_OPEN_SETTINGS,
});

type SetLpOpenSettingsTypeAction = ReturnType<typeof setLpOpenSettings>;
type ClearLpOpenSettingsTypeAction = ReturnType<typeof clearLpOpenSettings>;
type Action = SetLpOpenSettingsTypeAction | ClearLpOpenSettingsTypeAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_LP_OPEN_SETTINGS:
      return action.payload.data;
    case CLEAR_LP_OPEN_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ProductComment } from 'core/domain/lp'

const initialState = null 

export function createInitialState(): ProductComment | null {
  return initialState
}

export const SET_PRODUCT_COMMENT = 'product-comment/set' as const
export const CLEAR_PRODUCT_COMMENT = 'product-comment/clear' as const

export function setProductComment(data:ProductComment){
  return {
    type: SET_PRODUCT_COMMENT,
    payload: {
      data,
    },
  }
}

export function clearProductComment(){
  return {
    type: CLEAR_PRODUCT_COMMENT,
  }
}

type SetProductCommentAction = ReturnType<typeof setProductComment>
type ClearProductCommentAction = ReturnType<typeof clearProductComment>
type Action = SetProductCommentAction | ClearProductCommentAction

export function reducer(state = createInitialState(), action: Action){
  switch (action.type) {
    case SET_PRODUCT_COMMENT:
      return action.payload.data
    case CLEAR_PRODUCT_COMMENT:
      return initialState
    default: 
      return state
  }
}/* eslint-enable */

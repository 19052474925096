import React from 'react';
import {
  ButtonLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from 'components/atoms';
import { SortDownIcon } from 'assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { setLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';
import { LpSortKey } from 'core/domain/lp';
import { hooks } from 'libs';
import { formatDateTimeSecISO8601 } from 'libs/date';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TYPOGRAPHY } from 'constants/index';
import { Modules } from 'core';

const TABLE_HEADER_ITEMS = [
  {
    title: 'ランディングページ名',
    sortKey: 'title',
  },
  {
    title: '得意先名',
    sortKey: 'tokuisakiName',
  },
  {
    title: 'ページ\n閲覧数',
    sortKey: 'accessCount',
  },
  {
    title: '興味アリ\n獲得数',
    sortKey: 'interestedCount',
  },
  {
    title: '資料\nダウンロード数',
    sortKey: 'downloadCount',
  },
  {
    title: 'お問い合わせ数',
    sortKey: 'inquiryCount',
  },
  {
    title: '担当者',
    sortKey: 'lpTantoUser',
  },
  {
    title: '最終更新日時',
    sortKey: 'updatedDate',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    tokuisakiName: {
      '&>td, p': {
        maxWidth: 244,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    tantoName: {
      '&>td, p': {
        maxWidth: 132,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
);

const DashboardLPListTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchCondition = useSelector(
    (state: Modules.AppState) => state.lpOwnSearchCondition,
  );
  const lpList = useSelector((state: Modules.AppState) => state.dashboardLps);
  const { navigate } = hooks.useNavigate();
  const { setCurrentLpsUrl } = hooks.useLpsNavigation();

  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (searchCondition?.sort_key === sortKey) {
      order = searchCondition.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setLpOwnSearchCondition({
        ...searchCondition,
        sort_key: sortKey as LpSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item) => (
              <TableCell key={item.sortKey}>
                <TableSortLabel
                  active
                  direction={searchCondition?.sort_order}
                  IconComponent={() =>
                    item.sortKey === searchCondition?.sort_key &&
                    searchCondition?.sort_order === 'asc' ? (
                      <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <SortDownIcon />
                    )
                  }
                  onClick={() => handleChangeSortKey(item.sortKey)}
                >
                  {item.title}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {lpList &&
            lpList.lps &&
            lpList.lps.map((lp) => (
              <TableRow key={lp.lpId}>
                <TableCell className={classes.link}>
                  <ButtonLink
                    text={lp.title}
                    tooltipText={lp.title}
                    click={() => {
                      navigate(`/adm/lps/${lp.lpId}/approved`);
                      setCurrentLpsUrl();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tokuisakiName}>
                  <Tooltip title={lp.tokuisakiName || ''} arrow placement="top">
                    <Typography variant="body2">
                      {lp.tokuisakiName || ''}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={Number(lp.lpReaction.accessCount).toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {Number(lp.lpReaction.accessCount).toLocaleString() || '0'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={Number(lp.lpReaction.interestedCount).toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {Number(lp.lpReaction.interestedCount).toLocaleString() || '0'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={Number(lp.lpReaction.downloadCount).toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {Number(lp.lpReaction.downloadCount).toLocaleString() || '0'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={Number(lp.lpReaction.inquiryCount).toLocaleString() || '0'}
                    arrow
                    placement="top"
                  >
                    <span style={{display: "flow-root"}}>
                      {Number(lp.lpReaction.inquiryCount).toLocaleString() || '0'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tantoName}>
                  <Tooltip
                    title={lp.lpTantoUserName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {lp.lpTantoUserName || ''}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {formatDateTimeSecISO8601(lp.updatedDate).replace(' ', '\n')}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardLPListTable;

import { Notices } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): Notices | null => initialState;

export const SET_NOTICES = 'notices/set' as const;
export const CLEAR_NOTICES = 'notices/clear' as const;

export const setNotices = (data: Notices) => ({
  type: SET_NOTICES,
  payload: {
    data,
  },
});

export const clearNotices = () => ({
  type: CLEAR_NOTICES,
});

type SetNoticesAction = ReturnType<typeof setNotices>;
type ClearNoticesAction = ReturnType<typeof clearNotices>;
type Action = SetNoticesAction | ClearNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NOTICES:
      return action.payload.data;
    case CLEAR_NOTICES:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { Box, ButtonLink, HeadlineLabel } from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { DashboardLPListTable } from 'components/organisms';
import { User } from 'core/domain/user';
import { useDispatch } from 'react-redux';
import { setLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';

interface DashboardLPListProps {
  userInfo: User | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      '& > :first-child': {
        marginRight: theme.spacing(3),
      },
      '& > :nth-child(n)': {
        cursor: 'pointer',
      },
    },
  }),
);

const DashboardLPList = (props: DashboardLPListProps) => {
  const { userInfo } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = hooks.useNavigate();

  const handleLpsOwnNavigate = () => {
    dispatch(
      setLpOwnSearchCondition({
        page: 1,
        row: 10,
        status: '5',
      }),
    );
    navigate.navigate('/adm/lps/own');
  };

  const handleLpsSubordinatesNavigate = () => {
    dispatch(
      setLpOwnSearchCondition({
        page: 1,
        row: 10,
        subordinates: true,
        status: '5',
      }),
    );
    navigate.navigate('/adm/lps/subordinates');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <HeadlineLabel text="公開中のランディングページ一覧" />
        <Box className={classes.links} display="flex">
          {userInfo && (userInfo.tenantAdmin || userInfo.lpAuthor) ? (
            <ButtonLink
              text="わたしのランディングページ一覧 ＞"
              click={handleLpsOwnNavigate}
              fontSize="16px"
            />
          ) : null}
          {userInfo && (userInfo.tenantAdmin || userInfo.lpApprover) ? (
            <ButtonLink
              text="部下のランディングページ一覧 ＞"
              click={handleLpsSubordinatesNavigate}
              fontSize="16px"
            />
          ) : null}
        </Box>
      </Box>
      <DashboardLPListTable />
    </Box>
  );
};

export default DashboardLPList;

import React from 'react';
import {
  DashboardCommentaryArea,
  DashboardHeadlineLabel,
} from 'components/organisms';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CircleArrowDownIcon } from 'assets/images';

interface DashboardContentHeaderProps {
  balloon: string;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.MuiPaper-root': {
        backgroundColor: 'transparent',
      },
      '&.MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
    headerLabel: {
      marginBottom: theme.spacing(3),
      borderBottom: '2px solid #DCDCDA',

      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
  }),
);

const DashboardContentHeader = (props: DashboardContentHeaderProps) => {
  const { balloon, title } = props;
  const classes = useStyles();

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<CircleArrowDownIcon />}
        className={classes.headerLabel}
      >
        <DashboardHeadlineLabel title={title} />
      </AccordionSummary>
      <AccordionDetails>
        <DashboardCommentaryArea balloon={balloon} />
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardContentHeader;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ProfileFixedPhraseTexts } from 'core/domain/settings'

const initialState = null

export function createInitialState(): ProfileFixedPhraseTexts | null {
  return initialState
}

export const SET_PROFILE_FIXED_PHRASE_TEXTS = 'profile-fixed-phrase-texts/set' as const
export const CLEAR_PROFILE_FIXED_PHRASE_TEXTS = 'profile-fixed-phrase-texts/clear' as const

export function setProfileFixedPhraseTexts(data: ProfileFixedPhraseTexts) {
  return {
    type: SET_PROFILE_FIXED_PHRASE_TEXTS,
    payload: {
      data,
    },
  }
}

export function clearProfileFixedPhraseTexts() {
  return {
    type: CLEAR_PROFILE_FIXED_PHRASE_TEXTS,
  }
}

type SetProfileFixedPhraseTextsAction = ReturnType<typeof setProfileFixedPhraseTexts>
type ClearProfileFixedPhraseTextsAction = ReturnType<typeof clearProfileFixedPhraseTexts>
type Action = SetProfileFixedPhraseTextsAction | ClearProfileFixedPhraseTextsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PROFILE_FIXED_PHRASE_TEXTS:
      return action.payload.data
    case CLEAR_PROFILE_FIXED_PHRASE_TEXTS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

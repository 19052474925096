/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Checkbox, FormControlLabel, Typography } from 'components/atoms'
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography'
/**
 * Interface
 */
export interface TagSelectCheckBoxProps {
  /** タグ名 */
  name: string
  /** 利用数 */
  count: number
  handleCheck?: (tagName: string,flg:boolean) => void
  checked:boolean
}

/**
* Style
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.gray[400],
      height: 52,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    name: {
      fontWeight: 'bold',
    },
    count: {
      color: theme.palette.gray[800],
      marginRight: theme.spacing(3),
    },
    formControlLabel: {
      '&, .MuiCheckbox-root': {
        color: theme.palette.gray[700],
      },
      '&, .Mui-checked': {
        color:theme.palette.green[900],
      },
      '&, .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
        fontWeight: 'bold',
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
      },
      marginLeft: theme.spacing(3),
    },
  }),
)

/**
* Presenter
*/

export default function TagSelectCheckBox(props: TagSelectCheckBoxProps) {

  const classes = useStyles({})
  const { name, count, handleCheck, checked } = props

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" className={classes.root}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
          checked={checked}
            onChange={(e,flg) => {
              if(!handleCheck) return
              handleCheck(name,flg)
            }}
          />
        }
        label={name}
      />
      <Typography className={classes.count} variant="body2">({count.toLocaleString()})</Typography>
    </Box>
  )
}/* eslint-enable */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Link,
} from 'components/atoms';
import {
  SIDEBAR_MENU_DASHBOARD,
  SIDEBAR_MENU_CREATE_LPS,
  SIDEBAR_MENU_MY_LPS,
  SIDEBAR_MENU_SUBORDINATES_LPS,
  SIDEBAR_MENU_INQUIRIES,
  SIDEBAR_MENU_ALL_LPS,
  SIDEBAR_MENU_PRODUCTS,
  SIDEBAR_MENU_PRODUCT_EXAMPLES,
  SIDEBAR_MENU_TENANT_DASHBOARD,
  SIDEBAR_MENU_NOTICES,
  SIDEBAR_MENU_USERS,
  SIDEBAR_MENU_MY_LIBRARY,
  SIDEBAR_MENU_INQUIRY_FORMS,
  SIDEBAR_MENU_CUSTOMER,
  SIDEBAR_MENU_NOTICES_LIST,
  SIDEBAR_MENU_LPS,
  SIDEBAR_MENU_SETTINGS,
  SIDEBAR_MENU_TRASHES,
  SIDEBAR_IMAGE_CUT,
} from 'constants/text';
import { CSSProperties } from '@material-ui/styles';
import paletteOption from 'styles/palette';
import CreateLpIcon from 'assets/images/icn_create_lp.svg';
import MyLpIcon from 'assets/images/icn_my_lp.svg';
import SubordinatesLpIcon from 'assets/images/icn_subordinates_lp.svg';
import InquiryIcon from 'assets/images/icn_inquiry.svg';
import AllLpIcon from 'assets/images/icn_all_lp.svg';
import ProductIcon from 'assets/images/icn_product.svg';
import ProductExampleIcon from 'assets/images/icn_product_example.svg';
import MultipleArrowLeftIcon from 'assets/images/icn_multiple_arrow_left.svg';
import HumbergerIcon from 'assets/images/icn_humberger.svg';
import LibraryIcon from 'assets/images/icn_library.svg';
import ImageCutIcon from 'assets/images/icn_image_cut.svg';
import UserIcon from 'assets/images/icn_user_management.svg';
import RefreshIcon from 'assets/images/icn_reflesh.svg';
import NoticeIcon from 'assets/images/icn_news.svg';
import AdminDashboardIcon from 'assets/images/icn_admin_dashboard.svg';
import FormIcon from 'assets/images/icn_form.svg';
import CustomerIcon from 'assets/images/icn_customer_management.svg';
import NoticesListIcon from 'assets/images/icn_notices_list.svg';
import SettingsIcon from 'assets/images/icn_settings.svg';
import LPIcon from 'assets/images/icn_lp.svg';
import TrashesIcon from 'assets/images/icn_trashes.svg';
import { Modules, Usecases } from 'core';
import { setSidebarShift } from 'core/modules/sidebarShift';
import { setSidebarAdminMode } from 'core/modules/sidebarAdminMode';
import {
  SidebarPermission,
  useSidebarPermission,
} from 'libs/hooks/useSidebarPermission';
import { clearProductSearchCondition } from 'core/modules/productSearchCondition';
import { clearLpOwnSearchCondition } from 'core/modules/lpOwnSearchCondition';
import { clearLpSearchCondition } from 'core/modules/lpSearchCondition';
import { clearInquiriesQueryParameter } from 'core/modules/inquiriesQueryParameter';
import { clearExampleQueryParameter } from 'core/modules/examplesQueryParameter';
import { openLinkUrl } from 'libs/link';
import { clearCustomersQueryParameter } from 'core/modules/customersQueryParameter';
import { clearNoticesQueryParameter } from 'core/modules/noticesQueryParameter';
import { clearNoticesSearchCondition } from 'core/modules/noticesSearchCondition';
import { clearManageUsersSearchCondition } from 'core/modules/manageUsersSearchCondition';
import { clearTrashesSearchCondition } from 'core/modules/trashesSearchCondition';
import { hooks } from 'libs';

import { ImageCut } from 'core/domain/myLibrary';
import { fileToBase64 } from 'libs/file';
import { clearImageCut } from 'core/modules/imageCut';
import { ImageCutDialog, ImageCutPreview, SuccessDialog } from '..';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% - 64px)',
      backgroundColor: theme.palette.green[800],
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingBottom: theme.spacing(1),
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: `${theme.palette.text.secondary}29`, // Alpha 16%
      whiteSpace: 'pre',
      overflow: 'hidden',
      '&& $span': {
        marginRight: 'auto',
        lineHeight: 1,
        color: theme.palette.text.secondary,
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    headerTitle: {
      paddingLeft: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: `auto 0`,
      minWidth: 24,
      maxWidth: 24,
      marginLeft: 0,
    },
    multipleMenuIcon: {
      marginTop: 5,
    },
    text: {
      overflow: 'none',
      fontSize: theme.typography.fontSize,
      marginLeft: theme.spacing(1),
    },
    adminText: {
      flex: '0 auto',
    },
    listItem: {
      alignItems: 'flex-start',
      backgroundColor: 'inherit',
      borderRadius: 8,
      padding: theme.spacing(1),
      whiteSpace: 'pre',
    },
    adminModeListItem: {
      backgroundColor: theme.palette.green[600],
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      alignItems: 'flex-start',
      borderRadius: 8,
      padding: theme.spacing(1),
      whiteSpace: 'pre',
      justifyContent: 'center',
    },
    hide: {
      display: 'none',
    },
    menuIcon: {
      padding: theme.spacing(1),
    },
    scroll: {
      height: 'calc(100% - 80px)',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    linkBox: {
      display: 'flex',
      flexDirection: 'column',
      ' & > a': {
        borderRadius: 8,
      },
    },
    iconLinkBox: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    textLinkBox: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
    downloadText: {
      color: theme.palette.text.secondary,
      width: '100%',
      display: 'inline-block',
      textAlign: 'center',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    downloadTextSmall: {
      color: theme.palette.text.secondary,
      width: '100%',
      fontSize: '75%',
      display: 'inline-block',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

interface SidebarMenu {
  icon: string;
  text: string;
  to: string;
  permissionName: keyof SidebarPermission;
  dispatchAction?: { type: string };
}

const SIDEBAR_MENUS: Array<SidebarMenu> = [
  {
    icon: AdminDashboardIcon, // ダッシュボード
    text: SIDEBAR_MENU_DASHBOARD,
    to: '/adm/dashboard',
    permissionName: 'dashboardPermission',
  },
  {
    icon: CreateLpIcon,
    text: SIDEBAR_MENU_CREATE_LPS,
    to: '/adm/lps/create-mode-selection',
    permissionName: 'newLpPermission',
  },
  {
    icon: MyLpIcon,
    text: SIDEBAR_MENU_MY_LPS,
    to: '/adm/lps/own',
    permissionName: 'myLpsPermission',
    dispatchAction: clearLpOwnSearchCondition(),
  },
  {
    icon: SubordinatesLpIcon,
    text: SIDEBAR_MENU_SUBORDINATES_LPS,
    to: '/adm/lps/subordinates',
    permissionName: 'subordinatesLpsPermission',
    dispatchAction: clearLpOwnSearchCondition(),
  },
  {
    icon: InquiryIcon,
    text: SIDEBAR_MENU_INQUIRIES,
    to: '/adm/inquiries',
    permissionName: 'inquiriesPermission',
    dispatchAction: clearInquiriesQueryParameter(),
  },
  {
    icon: NoticesListIcon,
    text: SIDEBAR_MENU_NOTICES_LIST,
    to: '/adm/notices',
    permissionName: 'noticesListPermission',
    dispatchAction: clearNoticesQueryParameter(),
  },
  {
    icon: CustomerIcon,
    text: SIDEBAR_MENU_CUSTOMER,
    to: '/adm/customers',
    permissionName: 'customersPermission',
    dispatchAction: clearCustomersQueryParameter(),
  },
  {
    icon: AllLpIcon,
    text: SIDEBAR_MENU_ALL_LPS,
    to: '/adm/lps',
    permissionName: 'lpsPermission',
    dispatchAction: clearLpSearchCondition(),
  },
  {
    icon: ProductIcon,
    text: SIDEBAR_MENU_PRODUCTS,
    to: '/adm/products',
    permissionName: 'productsPermission',
    dispatchAction: clearProductSearchCondition(),
  },
  {
    icon: ProductExampleIcon,
    text: SIDEBAR_MENU_PRODUCT_EXAMPLES,
    to: '/adm/examples',
    permissionName: 'examplesPermission',
    dispatchAction: clearExampleQueryParameter(),
  },
  {
    icon: LibraryIcon,
    text: SIDEBAR_MENU_MY_LIBRARY,
    to: '/adm/my-libraries',
    permissionName: 'myLibrariesPermission',
  },
  {
    icon: ImageCutIcon,
    text: SIDEBAR_IMAGE_CUT,
    to: '',
    permissionName: 'imageCutPermission',
  },
  {
    icon: TrashesIcon,
    text: SIDEBAR_MENU_TRASHES,
    to: '/adm/trashes',
    permissionName: 'trashesPermission',
    dispatchAction: clearTrashesSearchCondition(),
  },
];

const SIDEBAR_ADMIN_MENUS: Array<SidebarMenu> = [
  {
    icon: AdminDashboardIcon, // 管理者用ダッシュボード
    text: SIDEBAR_MENU_TENANT_DASHBOARD,
    to: '/adm/tenant/dashboard',
    permissionName: 'adminPermission',
  },
  {
    icon: NoticeIcon, // お知らせ管理
    text: SIDEBAR_MENU_NOTICES,
    to: '/adm/notices-manage',
    permissionName: 'adminPermission',
    dispatchAction: clearNoticesSearchCondition(),
  },
  {
    icon: UserIcon, // ユーザー管理
    text: SIDEBAR_MENU_USERS,
    to: '/adm/users',
    permissionName: 'adminPermission',
    dispatchAction: clearManageUsersSearchCondition(),
  },
  {
    icon: FormIcon, // お問い合わせフォーム管理
    text: SIDEBAR_MENU_INQUIRY_FORMS,
    to: '/adm/inquiry-forms',
    permissionName: 'adminPermission',
  },
  {
    icon: LPIcon, // ランディングページ管理
    text: SIDEBAR_MENU_LPS,
    to: '/adm/lp-manage',
    permissionName: 'adminPermission',
    dispatchAction: clearLpSearchCondition(),
  },
  {
    icon: SettingsIcon, // 各種設定
    text: SIDEBAR_MENU_SETTINGS,
    to: '/adm/settings',
    permissionName: 'adminPermission',
  },
];

const current: CSSProperties = {
  backgroundColor: paletteOption.default[900],
};

const ADMIN_URL_LIST = [
  ...SIDEBAR_ADMIN_MENUS.map((menu) => menu.to),
  '/adm/notices/register',
  /^\/adm\/notices\/[0-9]+\/edit/,
  '/adm/users/register',
  /^\/adm\/users\/[0-9]+\/edit/,
];

const EXCEPTION_URL_LIST = [
  /^\/adm\/lps\/[0-9]+/,
  /^\/adm\/lps\/[0-9]+\/approved/,
];

const Sidebar = React.memo(() => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const location = useLocation();
  const permissions = useSidebarPermission();
  const sidebarShift = useSelector(
    (state: Modules.AppState) => state.sidebarShift,
  );
  const sidebarAdminMode = useSelector(
    (state: Modules.AppState) => state.sidebarAdminMode,
  );

  const toggleSidebar = useCallback(() => {
    dispatch(setSidebarShift(!sidebarShift));
  }, [dispatch, sidebarShift]);

  const sidebarIcon = useCallback(
    (
      icon: string,
      text: string,
      multiple: boolean,
      adminModeStyle: boolean = false,
    ) => (
      <ListItem
        button
        className={clsx({
          [classes.adminModeListItem]: adminModeStyle,
          [classes.listItem]: !adminModeStyle,
        })}
      >
        <ListItemIcon
          className={clsx(classes.icon, {
            [classes.multipleMenuIcon]: multiple,
          })}
        >
          <img className={classes.icon} src={icon} />
        </ListItemIcon>
        <ListItemText
          secondary={text}
          className={clsx(classes.text, {
            [classes.adminText]: adminModeStyle,
            [classes.hide]: sidebarShift,
          })}
        />
      </ListItem>
    ),
    [sidebarShift, classes],
  );

  const modeChangeButtonTitle = useMemo(
    () =>
      // 管理者メニュー表示時に"通常メニュー"と表示
      sidebarAdminMode ? '通常メニュー' : '管理者メニュー',
    [sidebarAdminMode],
  );

  const visibleMenus = useMemo(
    () => (sidebarAdminMode ? SIDEBAR_ADMIN_MENUS : SIDEBAR_MENUS),
    [sidebarAdminMode],
  );

  const handleChangeAdminMode = useCallback(() => {
    dispatch(setSidebarAdminMode(!sidebarAdminMode));
  }, [dispatch, sidebarAdminMode]);

  const handleManualDownload = () => {
    openLinkUrl(
      `${
        process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT || window.location.origin
      }/manual/SALAD-BAR_MANUAL.pdf`,
    );
  };

  const handlePrivacyPolicy = () => {
    openLinkUrl('https://www.toppan.co.jp/privacy.html');
  };

  const handleOperatingCompany = () => {
    openLinkUrl('https://www.toppan.co.jp/');
  };

  const handleUserPolicy = () => {
    openLinkUrl(
      `${
        process.env.REACT_APP_SALAD_BAR_LP_ENDPOINT || window.location.origin
      }/terms/SALAD-BAR_SERVICE_TERM.pdf`,
    );
  };

  useEffect(() => {
    if (EXCEPTION_URL_LIST.some((e) => location.pathname.match(e))) return;

    const isAdmin = ADMIN_URL_LIST.some((e) => location.pathname.match(e));
    dispatch(setSidebarAdminMode(isAdmin));
  }, [dispatch, location.pathname]);

  // 画像切抜
  const [imageCutOpen, setImageCutOpen] = useState(false);
  const [imageCutPreviewOpen, setImageCutPreviewOpen] = useState(false);
  const [imageCutSuccessDialogOpen, setImageCutSuccessDialogOpen] =
    useState(false);

  const [beforeCutImage, setBeforeCutImage] = useState<ImageCut | undefined>(
    undefined,
  );
  // エラー時
  const error = useSelector((state: Modules.AppState) => state.error);
  const network = useSelector((state: Modules.AppState) => state.network);
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const token = hooks.useAuth().accessToken;
  // 切抜画像データ
  // const imageCut = useSelector((state: Modules.AppState) => state.imageCut);

  // APIリクエストへ
  const handleRegister = (newImageCutData: string) => {
    if (!userInfo || !newImageCutData) return;
    dispatch(
      Usecases.myLibrary.sendPostImageCut(
        token,
        userInfo.tenantId,
        newImageCutData,
        1,
      ),
    );
  };

  // ファイルをBase64にする
  const setImageCutData = async (file: File) => {
    await fileToBase64(file).then((res) => {
      const newImageCutData = {
        data: res as string,
      };

      setBeforeCutImage(newImageCutData);

      // APIリクエストへ
      handleRegister(newImageCutData.data);
    });
  };

  // 切抜ダイアログ画像アップ
  const handleSubmit = async (files: File[]) => {
    if (!files.length || !files[0]) return;
    // 画像アップ
    await setImageCutData(files[0]);
    // プレビュー開く
    setImageCutPreviewOpen(true);
  };

  // 画像アップダイアログ閉じる
  const onImageCutDialog = () => {
    setImageCutOpen(false);
  };

  // エラー時
  useEffect(() => {
    if (error) {
      setImageCutPreviewOpen(false);
      setImageCutOpen(false);
    }
  }, [error]);

  // プレビュー保存押下
  const onImageCutPreviewDialog = () => {
    // APIレスポンスで返ってきた画像確認後、
    // 元画像をmode2でリクエスト
    if (!userInfo || !beforeCutImage) return;
    dispatch(
      Usecases.myLibrary.sendPostImageCut(
        token,
        userInfo.tenantId,
        beforeCutImage.data,
        2,
      ),
    );

    // 保存完了ダイアログ出す
    setImageCutSuccessDialogOpen(true);
    // 画像をクリア
    setBeforeCutImage(undefined);
    dispatch(clearImageCut());
  };

  // プレビュー閉じる押下
  const handleCancelImagePreviewDialog = () => {
    // 画像をクリア
    dispatch(clearImageCut());
    setImageCutPreviewOpen(false);
  };

  // 完了メッセージダイアログボタン押下時
  const handleImageCutSuccessClose = () => {
    // 画像をクリア
    dispatch(clearImageCut());
    // メッセージダイアログ閉じる
    setImageCutSuccessDialogOpen(false);
    // プレビュー閉じる
    setImageCutPreviewOpen(false);
    // アップロードダイアログ閉じる
    setImageCutOpen(false);

    if (!userInfo) return;

    dispatch(Usecases.myLibrary.sendGetMyLibrary(token, userInfo.tenantId));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {!sidebarShift && (
          <Typography variant="overline" className={classes.headerTitle}>
            メニュー
          </Typography>
        )}
        <IconButton
          className={classes.menuIcon}
          onClick={() => toggleSidebar()}
        >
          <img src={!sidebarShift ? MultipleArrowLeftIcon : HumbergerIcon} />
        </IconButton>
      </Box>
      <List className={classes.scroll}>
        {visibleMenus.map((sidebarMenu, index) =>
          permissions[sidebarMenu.permissionName] ? (
            <Box
              className={clsx(classes.linkBox, {
                [classes.iconLinkBox]: sidebarShift,
                [classes.textLinkBox]: !sidebarShift,
              })}
              key={index}
            >
              <NavLink
                exact
                // 画像切抜の時は現在の画面から遷移しない
                to={
                  (sidebarMenu.text !== SIDEBAR_IMAGE_CUT && sidebarMenu.to) ||
                  location.pathname
                }
                className={classes.link}
                // 画像切抜以外の時current
                activeStyle={
                  (sidebarMenu.text !== SIDEBAR_IMAGE_CUT && current) ||
                  undefined
                }
                onClick={() => {
                  // 画像切抜ダイアログ
                  if (sidebarMenu.text === SIDEBAR_IMAGE_CUT) {
                    setImageCutOpen(true);
                  }
                  if (sidebarMenu.dispatchAction) {
                    dispatch(sidebarMenu.dispatchAction);
                  }
                }}
              >
                {sidebarShift ? (
                  <Tooltip title={sidebarMenu.text} placement="right" arrow>
                    {sidebarIcon(sidebarMenu.icon, sidebarMenu.text, false)}
                  </Tooltip>
                ) : (
                  sidebarIcon(
                    sidebarMenu.icon,
                    sidebarMenu.text,
                    sidebarMenu.text.includes('\n'),
                  )
                )}
              </NavLink>
            </Box>
          ) : (
            ''
          ),
        )}
        {permissions.adminPermission && (
          <Box
            className={clsx(classes.linkBox, {
              [classes.iconLinkBox]: sidebarShift,
              [classes.textLinkBox]: !sidebarShift,
            })}
          >
            <Link underline="none" onClick={() => handleChangeAdminMode()}>
              {sidebarShift ? (
                <Tooltip title={modeChangeButtonTitle} placement="right" arrow>
                  {sidebarIcon(RefreshIcon, modeChangeButtonTitle, false, true)}
                </Tooltip>
              ) : (
                sidebarIcon(RefreshIcon, modeChangeButtonTitle, false, true)
              )}
            </Link>
          </Box>
        )}
        {!sidebarShift && !sidebarAdminMode && (
          <Box mt={1} mb={3} className={classes.linkBox}>
            <Link underline="none" onClick={() => handleManualDownload()}>
              <Typography
                component="span"
                variant="body2"
                className={classes.downloadText}
              >
                マニュアルダウンロード
              </Typography>
            </Link>
          </Box>
        )}
        {!sidebarShift && (
          <Box mt="auto" className={clsx(classes.linkBox, classes.textLinkBox)}>
            <Link underline="none" onClick={() => handlePrivacyPolicy()}>
              <Typography
                component="span"
                variant="body2"
                className={classes.downloadTextSmall}
              >
                プライバシーポリシー
              </Typography>
            </Link>
            <Link underline="none" onClick={() => handleOperatingCompany()}>
              <Typography
                component="span"
                variant="body2"
                className={classes.downloadTextSmall}
              >
                運営会社
              </Typography>
            </Link>
            <Link underline="none" onClick={() => handleUserPolicy()}>
              <Typography
                component="span"
                variant="body2"
                className={classes.downloadTextSmall}
              >
                利用規約
              </Typography>
            </Link>
          </Box>
        )}
      </List>

      {/* アップロード画面 */}
      <ImageCutDialog
        handleSubmit={handleSubmit}
        onClose={() => onImageCutDialog()}
        open={imageCutOpen}
        fileType="image"
      />
      {/* プレビュー画面 */}
      <ImageCutPreview
        open={imageCutPreviewOpen}
        handleSubmit={() => onImageCutPreviewDialog()}
        handleCancel={() => handleCancelImagePreviewDialog()}
      />
      {/* 切抜完了 */}
      <SuccessDialog
        open={imageCutSuccessDialogOpen && network === 0 && !error}
        title="マイライブラリに保存されました"
        handleClose={() => handleImageCutSuccessClose()}
      />
    </Box>
  );
});
export default Sidebar;

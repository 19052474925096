/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from 'components/atoms'
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography'

export interface AdditionalTagProps{
  click?:() => void
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 26,
      minWidth: 48,
      padding: 0,
      color: theme.palette.green[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
    },
  }),
)

/**
 * Presenter
 */

export default function AdditionalTag(props:AdditionalTagProps) {
  const classes = useStyles({})
  const { click } = props

  return (
    <Button 
      onClick={click}
      className={classes.button}>
      ＋ 選択
    </Button>
  )
}/* eslint-enable */

import React, {CSSProperties, useMemo} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import palette from '../../../styles/palette';

/**
 * Interface
 */
type Variant = 'outline' | 'contained';

export interface StatusLabelProps {
  label: string;
  color: keyof typeof palette;
  variant?: Variant;
  width?: CSSProperties['width'];
}

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      borderStyle: 'solid',
      textAlign: 'center',
      fontWeight: 'bold',
    },
  }),
);
const StatusLabel = (props: StatusLabelProps) => {
  const {color, label, variant, width} = props;
  const classes = useStyles();
  const colorStyle: CSSProperties = useMemo(() => {
    switch (variant) {
      case 'contained':
        return {
          color: 'white',
          backgroundColor: (palette[color] as { 900: string })[900],
          borderRadius: 10,
          fontSize: 13,
          padding: '0 15px',
          width,
        };
      default:
        return {
          color: '#fff',
          backgroundColor: (palette[color] as { 900: string })[900] || "#000",
          borderWidth: 2,
          borderRadius: 20,
          width,
        };
    }
  }, [color, variant, width]);

  return (
    <Typography
      style={colorStyle}
      className={`${classes.root}`}
      variant="caption"
      display="block"
    >
      {label}
    </Typography>
  );
};
export default StatusLabel;

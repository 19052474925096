/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import { DesignTemplate } from 'core/domain/settings';

const initialState = null;

export function createInitialState(): Array<DesignTemplate> | null {
  return initialState;
}

export const SET_DESIGN_ALL_TEMPLATES = 'design-all-template/set' as const;
export const CLEAR_DESIGN_ALL_TEMPLATES = 'design-all-template/clear' as const;

export function setDesignAllTemplates(data: Array<DesignTemplate>) {
  return {
    type: SET_DESIGN_ALL_TEMPLATES,
    payload: {
      data,
    },
  };
}

export function clearDesignAllTemplates() {
  return {
    type: CLEAR_DESIGN_ALL_TEMPLATES,
  };
}

type SetDesignAllTemplatesAction = ReturnType<typeof setDesignAllTemplates>;
type ClearDesignAllTemplatesAction = ReturnType<typeof clearDesignAllTemplates>;
type Action = SetDesignAllTemplatesAction | ClearDesignAllTemplatesAction;

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_DESIGN_ALL_TEMPLATES:
      return action.payload.data;
    case CLEAR_DESIGN_ALL_TEMPLATES:
      return initialState;
    default:
      return state;
  }
}
/* eslint-enable */

import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'components/atoms';
import { TargetPeriodLabel } from 'components/molecules';
import { Content, DashboardRankingCardArea } from 'components/organisms';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { CVIcon, DLIcon, GoodIcon, PVIcon } from 'assets/images';
import { ReactionRange } from 'components/pages/LPDetail';
import { validDate } from 'libs/date';
import { INVALID_DATE } from 'constants/text';

interface DashboardRankingLPAreaProps {
  searchReaction: (
    start: string,
    end: string,
    type: 'total' | 'ranking',
  ) => void;
}

interface ValidationError {
  startReactionRange?: string;
  endReactionRange?: string;
}

interface RankingAccessor {
  accessor:
    | 'lpAccessRanking'
    | 'inquiryRanking'
    | 'interestedRanking'
    | 'productDocumentDownloadRanking';
  icon: React.ReactNode;
  title: string;
  type: 'lps' | 'products';
}

const RANKING_DATALIST_ACCESSORS: RankingAccessor[] = [
  {
    accessor: 'lpAccessRanking',
    icon: <PVIcon />,
    title: 'ランディングページ ページ閲覧数ランキング',
    type: 'lps',
  },
  {
    accessor: 'inquiryRanking',
    icon: <CVIcon />,
    title: 'ランディングページ お問い合わせ数ランキング',
    type: 'lps',
  },
  {
    accessor: 'interestedRanking',
    icon: <GoodIcon />,
    title: '商材 興味アリ獲得数ランキング',
    type: 'products',
  },
  {
    accessor: 'productDocumentDownloadRanking',
    icon: <DLIcon />,
    title: '商材 資料ダウンロード数ランキング',
    type: 'products',
  },
];

const DashboardRankingLPArea = (props: DashboardRankingLPAreaProps) => {
  const { searchReaction } = props;
  const reactionRanking = useSelector(
    (state: Modules.AppState) => state.reactionRanking,
  );
  const addTerm = useSelector(
    (state: Modules.AppState) => state.dashboardAddTerm,
  );
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >(undefined);
  const [reactionRange, setReactionRange] = useState<ReactionRange>();

  const searchReactionByRange = useCallback(
    (start: string | undefined, end: string | undefined) => {
      const [strStart, strEnd] = [start || '', end || ''];
      const startDate = validDate(strStart);
      const endDate = validDate(strEnd);

      if (startDate !== undefined && endDate !== undefined) {
        setValidationError(undefined);
        searchReaction(strStart, strEnd, 'ranking');
      }

      setValidationError({
        startReactionRange: startDate !== undefined ? undefined : INVALID_DATE,
        endReactionRange: endDate !== undefined ? undefined : INVALID_DATE,
      });
    },
    [searchReaction],
  );

  const handleChangeStartDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      start: date.replaceAll('/', ''),
    });
  };

  const handleChangeEndDate = (date: string) => {
    setReactionRange({
      ...reactionRange,
      end: date.replaceAll('/', ''),
    });
  };

  useEffect(() => {
    if (reactionRange)
      searchReactionByRange(reactionRange.start, reactionRange.end);
  }, [reactionRange, searchReactionByRange]);

  useEffect(() => {
    if (reactionRange || !addTerm) return;
    setReactionRange({
      start: addTerm?.addTermFrom,
      end: addTerm?.addTermTo,
    });
  }, [reactionRange, addTerm]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <TargetPeriodLabel
          start={reactionRange?.start}
          end={reactionRange?.end}
          startError={validationError?.startReactionRange}
          endError={validationError?.endReactionRange}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
        />
      </Box>
      <Content title="">
        <>
          {reactionRanking &&
            RANKING_DATALIST_ACCESSORS.map((accessor) => (
              <DashboardRankingCardArea
                key={accessor.accessor}
                lps={reactionRanking[accessor.accessor]}
                icon={accessor.icon}
                reactionRange={reactionRange}
                title={accessor.title}
                type={accessor.type}
              />
            ))}
        </>
      </Content>
    </>
  );
};

export default DashboardRankingLPArea;

import { Customers } from 'core/domain/customer';

const initialState = null;

export const createInitialState = (): Customers | null => initialState;

export const SET_CUSTOMERS = 'customers/set' as const;
export const CLEAR_CUSTOMERS = 'customers/clear' as const;

export const setCustomers = (data: Customers) => ({
  type: SET_CUSTOMERS,
  payload: {
    data,
  },
});

export const clearCustomers = () => ({
  type: CLEAR_CUSTOMERS,
});

type SetCustomersAction = ReturnType<typeof setCustomers>;
type ClearCustomersAction = ReturnType<typeof clearCustomers>;
type Action = SetCustomersAction | ClearCustomersAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return action.payload.data;
    case CLEAR_CUSTOMERS:
      return initialState;
    default:
      return state;
  }
};

import { Trashes } from 'core/domain/trashes';

const initialState: Trashes = {
  "trashes": [],
  "totalCount": 0
};

export const createInitialState = (): Trashes => initialState;

export const SET_TRASHES = 'trashes/set' as const;
export const CLEAR_TRASHES = 'trashes/clear' as const;

export const setTrashes = (data: Trashes) => ({
  type: SET_TRASHES,
  payload: {
    data
  },
});

export const clearTrashes = () => ({
  type: CLEAR_TRASHES
});

type SetTrashesAction = ReturnType<typeof setTrashes>;
type ClearTrashesAction = ReturnType<typeof clearTrashes>;
type Action = SetTrashesAction | ClearTrashesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TRASHES:
      return action.payload.data;
    case CLEAR_TRASHES:
      return initialState;
    default:
      return state;
  }
};


import {
  useState,
  useMemo,
  ChangeEvent,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Divider,
  Grid,
  Tabs,
  Tab,
  Box,
  FormControl,
  RadioGroup,
  CommonRadioButton,
  MultipleLinesTextField,
  FormControlLabel,
  Checkbox,
  PrimaryButton,
  InquiryStatusLabel,
  SecondaryButton,
} from 'components/atoms';
import { Modules } from 'core';

import { TYPOGRAPHY } from 'constants/index';
import {
  SALAD_BAR_DESKTOP_FONT_SIZE_100,
  SALAD_BAR_DESKTOP_FONT_SIZE_75,
} from 'constants/typography';
import {
  InquiryProduct,
  InquirySupport,
  InquirySupports,
  InquirySupportsRegisterRequest,
} from 'core/domain/inquiries';
import {
  formatDateTimeISO8601,
  formatDateYYYYMMDDSeparateSlash,
  formateTimeHHMMColon,
} from 'libs/date';
import {
  INQUIRY_TYPE_TEL,
  INQUIRY_TYPE_MAIL,
  INQUIRY_TYPE_OTHER,
  INQUIRY_TYPE_INQUIRY_FORM,
} from 'constants/text';
import { useNavigate } from 'libs/hooks';
import { MultipleLinesTextFieldRef } from 'components/atoms/MultipleLinesTextField';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../ConfirmDialog';

/**
 * Interface
 */
export interface InquiryDetailsProps {
  inquirySupports?: InquirySupports | null;
  handleRegisterSupport: (request: InquirySupportsRegisterRequest) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      backgroundColor: 'white',
      marginTop: '20px',
      padding: '24px',
      borderRadius: '16px',
      maxWidth: 'inherit',
    },
    container: {
      margin: '10px 0px',
    },
    tab: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      border: '1px solid',
      borderRadius: '5px 5px 0 0',
      borderColor: theme.palette.gray[600],
      backgroundColor: theme.palette.gray[400],
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        borderBottomColor: 'white',
      },
    },
    indicator: {
      backgroundColor: 'transparent',
    },
    tabContents: {
      marginTop: '-1px',
      width: '100%',
      height: '100%',
      borderTop: 'solid 1px',
      borderTopColor: theme.palette.gray[600],
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    radioBtn: {
      display: 'flex',
      flexDirection: 'row',
      // width: '416px',
      justifyContent: 'space-between',
    },
    status: {
      marginLeft: `${theme.spacing(1) / 2}px`,
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      marginRight: theme.spacing(2),
      flexShrink: 0,
      fontWeight: 'bold',
    },
    checkbox: {
      marginRight: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: '#b0b0a7',
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
        transform: `translateX(-${theme.spacing(1) / 2}px)`,
      },
      '& .Mui-checked': {
        color: `${theme.palette.green[800]} !important`,
      },
    },
    chkBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    priBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableHeader: {
      marginTop: theme.spacing(3),
      height: 44,
      marginBottom: 0,
    },
    tableItem: {
      padding: theme.spacing(1),
    },
    editBtn: {
      marginLeft: 'auto',
    },
    supportTable: {
      padding: '16px 0px',
    },
    selectedSupport: {
      backgroundColor: theme.palette.green[700],
    },
  }),
);
const tabs = ['お問い合わせ内容', '対応状況'];

const InquiryDetails = (props: InquiryDetailsProps) => {
  const { inquirySupports, handleRegisterSupport } = props;
  const classes = useStyles({});
  const [value, setValue] = useState(0);

  const inquiryDetail = useSelector(
    (state: Modules.AppState) => state.inquiryDetail,
  );

  const [inquirySupportsRegisterRequest, setInquirySupportsRegisterRequest] =
    useState<InquirySupportsRegisterRequest>({
      supportType: 1,
      orderedProductIds: [],
    });
  const [
    initialInquirySupportsRegisterRequest,
    setInitialInquirySupportsRegisterRequest,
  ] = useState<InquirySupportsRegisterRequest>({
    supportType: 1,
    orderedProductIds: [],
  });
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [nextPagePath, setNextPagePath] = useState('');
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const supportDetailTextFieldEl = useRef<MultipleLinesTextFieldRef>(null);
  const navigate = useNavigate();

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  // テキストエリア空白エラー用
  const [textError, setTextError] = useState<string>('');

  const label = useMemo(() => {
    switch (inquiryDetail?.inquiryType) {
      case 1:
        return INQUIRY_TYPE_TEL;
      case 2:
        return INQUIRY_TYPE_MAIL;
      case 3:
        return INQUIRY_TYPE_OTHER;
      case 9:
        return INQUIRY_TYPE_INQUIRY_FORM;
      default:
        break;
    }

    return null;
  }, [inquiryDetail]);

  useEffect(() => {
    let tmpOrderedProductIds: number[] = [];
    if (inquirySupports && inquirySupports.inquirySupports[0])
      tmpOrderedProductIds =
        inquirySupports?.inquirySupports[0].orderedProductIds;

    setInquirySupportsRegisterRequest({
      supportType: 1,
      orderedProductIds: tmpOrderedProductIds,
    });
    setInitialInquirySupportsRegisterRequest({
      supportType: 1,
      orderedProductIds: tmpOrderedProductIds,
    });
  }, [inquirySupports]);

  useEffect(() => {
    const res = navigate.block((pathname) => {
      setNextPagePath(pathname);
      if (
        (!inquirySupportsRegisterRequest &&
          !initialInquirySupportsRegisterRequest) ||
        goAnotherPage
      )
        return;

      if (
        inquirySupportsRegisterRequest &&
        initialInquirySupportsRegisterRequest
      ) {
        if (
          JSON.stringify(inquirySupportsRegisterRequest) !==
          JSON.stringify(initialInquirySupportsRegisterRequest)
        ) {
          setOpenExitConfirmDialog(true);

          // eslint-disable-next-line consistent-return
          return false;
        }
      }

      if (
        inquirySupportsRegisterRequest &&
        !initialInquirySupportsRegisterRequest
      ) {
        setOpenExitConfirmDialog(true);

        // eslint-disable-next-line consistent-return
        return false;
      }
    });

    if (
      !inquirySupportsRegisterRequest ||
      initialInquirySupportsRegisterRequest
    )
      return;
    setInitialInquirySupportsRegisterRequest({
      ...inquirySupportsRegisterRequest,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      res();
    };
  }, [
    inquirySupportsRegisterRequest,
    goAnotherPage,
    initialInquirySupportsRegisterRequest,
    navigate,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  const setRadioButton = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    // setCurrent(value) )
    setInquirySupportsRegisterRequest({
      ...inquirySupportsRegisterRequest,
      supportType: Number(value),
    });
  };
  const handleChangeText = (text: string) => {
    if (text.length > 5000) return;
    setInquirySupportsRegisterRequest({
      ...inquirySupportsRegisterRequest,
      supportDetail: text,
    });
  };
  const handleCheck = (id: number) => {
    if (inquirySupportsRegisterRequest.orderedProductIds?.includes(id)) {
      setInquirySupportsRegisterRequest({
        ...inquirySupportsRegisterRequest,
        orderedProductIds:
          inquirySupportsRegisterRequest.orderedProductIds.filter(
            (productId) => productId !== id,
          ),
      });

      return;
    }
    setInquirySupportsRegisterRequest({
      ...inquirySupportsRegisterRequest,
      orderedProductIds: [
        ...inquirySupportsRegisterRequest.orderedProductIds,
        id,
      ],
    });
  };

  const hancleClickCancel = () => {
    setInquirySupportsRegisterRequest({
      supportType: 1,
      orderedProductIds: [],
    });
  };

  const handleClickSupport = (request: InquirySupport) => {
    setInquirySupportsRegisterRequest({
      inquirySupportId: request.inquirySupportId,
      supportType: request.supportType,
      supportDetail: request.supportDetail,
      orderedProductIds: request.orderedProductIds,
    });

    if (supportDetailTextFieldEl && supportDetailTextFieldEl.current)
      supportDetailTextFieldEl.current?.setFocus();
  };

  const getOrderedDate = useCallback(
    (ele: InquiryProduct) => {
      if (!inquirySupports || inquirySupports.inquirySupports.length === 0) {
        return null;
      }

      let date;
      if (
        inquirySupports.inquirySupports[0].orderedProductIds.includes(
          ele.productId,
        )
      )
        date = inquirySupports.inquirySupports[0].createdDate;

      if (date) return formatDateTimeISO8601(date);

      return null;
    },
    [inquirySupports],
  );

  useEffect(() => {
    if (inquirySupportsRegisterRequest.supportDetail) setTextError('');
  }, [inquirySupportsRegisterRequest.supportDetail]);

  return (
    <>
      {/* データ未入手時の画面表示を抑制 */}
      {inquiryDetail && (
        <Container className={classes.root}>
          {/* タブ区切りでお問い合わせ内容と対応状況を表示 */}
          <Tabs
            textColor="primary"
            TabIndicatorProps={{ className: classes.indicator }}
            value={value}
            onChange={handleTabChange}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} className={classes.tab} label={tab} />
            ))}
          </Tabs>
          {/* お問い合わせ内容 */}
          <Box hidden={value !== 0} className={classes.tabContents}>
            <Grid container spacing={0} className={classes.container}>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  初回お問い合わせ
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  className={classes.description}
                  display="inline"
                >
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="body2"
                  className={classes.description}
                  display="inline"
                >
                  {`${formatDateYYYYMMDDSeparateSlash(
                    inquiryDetail.inquiryDate,
                  )} ${formateTimeHHMMColon(inquiryDetail.inquiryTime)}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              className={classes.container}
              style={{ background: '#F7F7F5' }}
            >
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                  style={{ paddingLeft: '5px' }}
                >
                  気になったテーマ
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  取扱商材
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  資料ダウンロード
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  受注
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '-11px' }} />
            {inquiryDetail?.products?.map((ele, index) => (
              <Box key={index}>
                <Grid container spacing={0} className={classes.container}>
                  <Grid item xs={2}>
                    {ele.interested && (
                      <span
                        className={classes.subtitle}
                        style={{ marginLeft: '25px' }}
                      >
                        ◯
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      className={classes.description}
                      display="inline"
                    >
                      {ele.productName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      className={classes.description}
                      display="inline"
                    >
                      {ele.downloadDate &&
                        formatDateTimeISO8601(ele.downloadDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      className={classes.description}
                      display="inline"
                    >
                      {getOrderedDate(ele)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Divider />
            {inquiryDetail &&
              inquiryDetail.others &&
              inquiryDetail.others.map((ele, index) => (
                <Box key={index}>
                  <Grid container spacing={0} className={classes.container}>
                    <Grid item xs={2}>
                      <Typography
                        variant="body2"
                        className={classes.subtitle}
                        display="inline"
                      >
                        {ele.inquiryItemName}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                        display="inline"
                      >
                        {ele.value.replaceAll(',,', ',')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>

          {/* 対応状況 */}
          <Box hidden={value !== 1} className={classes.tabContents}>
            <Grid container spacing={0} className={classes.container}>
              <FormControl>
                <RadioGroup
                  value={inquirySupportsRegisterRequest.supportType?.toString()}
                  onChange={setRadioButton}
                >
                  <div className={classes.radioBtn}>
                    <div>
                      <Typography
                        variant="body2"
                        className={classes.subtitle}
                        style={{ color: 'black', marginTop: '5px' }}
                      >
                        対応状況：
                      </Typography>
                    </div>
                    <div>
                      <CommonRadioButton value="1" label="返信済み" />
                    </div>
                    <div>
                      <CommonRadioButton value="2" label="電話対応済み" />
                    </div>
                    <div>
                      <CommonRadioButton value="4" label="資料送付済み" />
                    </div>
                    <div>
                      <CommonRadioButton value="5" label="アポイント設定済み" />
                    </div>
                    <div>
                      <CommonRadioButton value="3" label="対応中" />
                    </div>
                    <div>
                      <CommonRadioButton value="6" label="その他" />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container spacing={0} className={classes.container}>
              <Grid item xs={12} style={{ color: 'black' }}>
                <MultipleLinesTextField
                  error={textError}
                  label="対応した内容を記入する"
                  rows={5}
                  value={inquirySupportsRegisterRequest.supportDetail}
                  handleChangeText={handleChangeText}
                  ref={supportDetailTextFieldEl}
                />
              </Grid>
            </Grid>

            {/* 受注決定欄 */}
            <Box className={classes.chkBox}>
              <Typography
                className={classes.title}
                variant="overline"
                style={{ marginTop: '3px' }}
              >
                受注決定：
              </Typography>
              <Box className={classes.status}>
                {inquiryDetail &&
                  inquiryDetail.products &&
                  inquiryDetail.products.map((ele, index) =>
                    ele.productId ? (
                      <FormControlLabel
                        key={index}
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            checked={inquirySupportsRegisterRequest.orderedProductIds?.includes(
                              ele.productId,
                            )}
                            onChange={() => handleCheck(ele.productId)}
                          />
                        }
                        label={ele.productName}
                      />
                    ) : (
                      ''
                    ),
                  )}
              </Box>
            </Box>

            {/* 登録ボタン */}
            <Box className={classes.priBtn}>
              <Box width={332} height={48} marginLeft={10} marginRight={10}>
                <SecondaryButton
                  click={() => {
                    hancleClickCancel();
                  }}
                  text="キャンセル"
                />
              </Box>
              <Box width={332} height={48} marginLeft={10} marginRight={10}>
                <PrimaryButton
                  click={() => {
                    if (!inquirySupportsRegisterRequest.supportDetail) {
                      setTextError('対応状況を入力してください。');
                    } else {
                      handleRegisterSupport(inquirySupportsRegisterRequest);
                    }
                  }}
                  text="登録する"
                />
              </Box>
            </Box>

            {/* 返信済一覧表 */}
            <Grid
              container
              spacing={0}
              className={classes.tableHeader}
              style={{ background: '#F7F7F5' }}
            >
              <Grid item xs={2} className={classes.tableItem}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                  style={{ paddingLeft: '5px' }}
                >
                  対応状況
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.tableItem}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  登録日時
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.tableItem}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  対応内容
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {inquirySupports &&
              inquirySupports.inquirySupports &&
              inquirySupports.inquirySupports.map((ele, index) => (
                <Grid
                  key={index}
                  className={
                    ele.inquirySupportId ===
                    inquirySupportsRegisterRequest.inquirySupportId
                      ? classes.selectedSupport
                      : undefined
                  }
                >
                  <Grid container spacing={0} className={classes.supportTable}>
                    <Grid item xs={2} className={classes.tableItem}>
                      <Box
                        className={classes.status}
                        style={{ width: '80px', height: '24px' }}
                      >
                        <InquiryStatusLabel status={ele.supportType} />
                      </Box>
                    </Grid>
                    <Grid item xs={2} className={classes.tableItem}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                        display="inline"
                      >
                        {formatDateTimeISO8601(ele.createdDate)}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} className={classes.tableItem}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                        display="inline"
                      >
                        {ele.supportDetail}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.tableItem}>
                      <Box width={80} height={27} className={classes.editBtn}>
                        <PrimaryButton
                          text="修正"
                          click={() => handleClickSupport(ele)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              ))}
          </Box>
          <ConfirmDialog
            buttonText="終了する"
            open={openExitConfirmDialog}
            title="編集画面を終了します"
            text="保存されていない入力は破棄されますが"
            handleCancel={() => setOpenExitConfirmDialog(false)}
            handleSubmit={() => {
              setGoAnotherPage(true);
              setOpenExitConfirmDialog(false);
            }}
          />
        </Container>
      )}
    </>
  );
};

export default InquiryDetails;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { CircularProgress } from 'components/atoms'
import { CenterAlignmentTemplate } from 'components/templates'

export default function InitializePage() {
  return (
    <CenterAlignmentTemplate>
      <CircularProgress size={64} />
    </CenterAlignmentTemplate>
  )
}
/* eslint-enable */

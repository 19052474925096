import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import MyLibraryMenu from '../../molecules/MyLibraryMenu';

library.add(faEllipsisV);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    media: {
      paddingTop: '56.25%', // 16:9
    },
    setting: {
      position: 'absolute',
      top: '5%',
      right: '5%',
    },
  }),
);

interface itemProps {
  imgUrl: string | undefined;
  fileId: number;
  used: boolean;
  deleteImage: (id: number, index: number) => void;
  index: number;
}

const MyLibraryItem: React.FC<itemProps> = (props) => {
  const classes = useStyles();
  const { imgUrl, fileId, deleteImage, used, index } = props;

  return (
    <Card className={classes.root}>
      {!used && (
        <MyLibraryMenu handleDelete={() => deleteImage(fileId, index)} />
      )}
      <CardMedia component="img" height="200" image={imgUrl} style={{objectFit: 'contain'}} />
    </Card>
  );
};

export default MyLibraryItem;

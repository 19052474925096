import React, { useEffect, useState } from 'react';
import { Box } from 'components/atoms';
import {
  Content,
  DashboardApprovalStatusArea,
  DashboardContentHeader,
  DashboardLPList,
  DashboardNoticeArea,
  DashboardRankingLPArea,
  DashboardTotalReactionArea,
} from 'components/organisms';
import { User } from 'core/domain/user';

interface DashboardPageProps {
  searchReaction: (
    start: string,
    end: string,
    type: 'total' | 'ranking',
  ) => void;
  userInfo: User | null;
}

/**
 * Presenter
 */

const COMMENTARY_BALLOON = [
  '自分や部下の作ったランディングページへの、お客さまのリアクションを確認できます。\n閲覧や拡散の依頼、感想を聞くなどのお客さまとのコミュニケーションや、分析などに活かしましょう！',
  'お客さまからの多くのリアクションを獲得したランディングページを、ランキング形式で表示しています。\nプレビューで確認し、詳細画面で「このランディングページを元に作成する」など、活用しましょう！',
];

const DashboardPage = (props: DashboardPageProps) => {
  const { searchReaction, userInfo } = props;
  const [isRender, setIsRender] = useState<boolean>(false);

  useEffect(() => {
    const isHasAuthority =
      (userInfo &&
        (userInfo.tenantAdmin || userInfo.lpAuthor || userInfo.lpApprover)) ||
      false;
    setIsRender(isHasAuthority);
  }, [userInfo]);

  return (
    <Box>
      {isRender ? <DashboardApprovalStatusArea /> : null}
      <Content title="">
        <DashboardNoticeArea />
      </Content>
      {isRender ? (
        <>
          {' '}
          <Box>
            <DashboardContentHeader
              balloon={COMMENTARY_BALLOON[0]}
              title="あなたのランディングページ"
            />
            <Content title="">
              <>
                <DashboardLPList userInfo={userInfo} />
                <DashboardTotalReactionArea searchReaction={searchReaction} />
              </>
            </Content>
          </Box>
          <Box>
            <DashboardContentHeader
              balloon={COMMENTARY_BALLOON[1]}
              title="みんなのランディングページ"
            />
          </Box>
          <DashboardRankingLPArea searchReaction={searchReaction} />
        </>
      ) : null}
    </Box>
  );
};

export default DashboardPage;


import { MyLibrary } from 'core/domain/myLibrary'

const initialState = null

export const SET_MY_LIBRARY = 'my-library/set' as const
export const CLEAR_MY_LIBRARY = 'my-library/clear' as const

export const createInitialState = (): MyLibrary | null => initialState

export const setMyLibrary = (data: MyLibrary) => ({
  type: SET_MY_LIBRARY,
  payload: {
    data,
  },
})

export const clearMyLibrary = () => ({
  type: CLEAR_MY_LIBRARY,
})

type SetMyLibraryAction = ReturnType<typeof setMyLibrary>
type ClearMyLibraryAction = ReturnType<typeof clearMyLibrary>
type Action = SetMyLibraryAction | ClearMyLibraryAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_MY_LIBRARY:
      return action.payload.data
    case CLEAR_MY_LIBRARY:
      return initialState
    default:
      return state
  }
}
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { LPApprovers } from '../domain/user'

const initialState = null

export function createInitialState(): LPApprovers | null {
  return initialState
}

export const SET_LPAPPROVER_INFO = 'lpApprover-info/set' as const
export const CLEAR_LPAPPROVER_INFO = 'lpApprover-info/clear' as const

export function setLPApproversInfo(data: LPApprovers) {
  return {
    type: SET_LPAPPROVER_INFO,
    payload: {
      data,
    }
  }
}

export function clearLPApproversInfo() {
  return {
    type: CLEAR_LPAPPROVER_INFO
  }
}

type SetLPApproversInfo = ReturnType<typeof setLPApproversInfo>
type ClearLPApproversInfo = ReturnType<typeof clearLPApproversInfo>
type Action = SetLPApproversInfo | ClearLPApproversInfo

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LPAPPROVER_INFO:
      return action.payload.data
    case CLEAR_LPAPPROVER_INFO:
      return initialState
    default:
      return state
  }
}/* eslint-enable */

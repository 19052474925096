/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


export function openLinkUrl(url: string) {
  const tempLink = document.createElement("a")
  tempLink.href = url
  tempLink.target = "_blank"
  tempLink.click()
}/* eslint-enable */

import { InquiryDetail } from 'core/domain/inquiries';

const initialState = null;

export const createInitialState = (): InquiryDetail | null => initialState;

export const SET_INQUIRY_DETAIL = 'inquiry-detail/set' as const;
export const CLEAR_INQUIRY_DETAIL = 'inquiry-detail/clear' as const;

export const setInquiryDetail = (data: InquiryDetail) => ({
  type: SET_INQUIRY_DETAIL,
  payload: {
    data,
  },
});

export const clearInquiryDetail = () => ({
  type: CLEAR_INQUIRY_DETAIL,
});

type SetInquiryDetailAction = ReturnType<typeof setInquiryDetail>;
type ClearInquiryDetailAction = ReturnType<typeof clearInquiryDetail>;
type Action = SetInquiryDetailAction | ClearInquiryDetailAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_INQUIRY_DETAIL:
      return action.payload.data;
    case CLEAR_INQUIRY_DETAIL:
      return initialState;
    default:
      return state;
  }
};

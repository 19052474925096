/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from 'components/atoms'

/**
 * Interface
 */
export interface PrimaryButtonProps {
  text: string,
  icon?: React.ReactNode
  click?: () => void
  disabled?:boolean
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      background: theme.palette.orange[800],
      borderColor: theme.palette.orange[900],
      borderStyle: 'solid',
      borderWidth: '1px',
      color: theme.palette.text.secondary,
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.orange[900],
        boxShadow: 'none'
      }
    },
  }),
)

/**
 * Presenter
 */

export default function TertiaryButton(props:PrimaryButtonProps) {

  const classes = useStyles({})
  const { text, icon, click, disabled } = props

  return (
    <Button
    disabled={disabled}
      variant="contained"
      className={classes.root}
      startIcon={icon}
      onClick={click}
    >
      {text}
    </Button>
  )
}/* eslint-enable */

import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { Trashes, TrashesSearchCondition } from 'core/domain/trashes';
import { setTrashes } from 'core/modules/trashes';


// ゴミ箱一覧取得API
const didCallGetTrashes = (dispatch: Dispatch, data: Trashes) => {
  dispatch(setTrashes(data));
};

export const sendGetTrashes =
  (
    token: string | undefined,
    tenantId: string,
    param: TrashesSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = Api.createApi(param);
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/trashes?${api}`,
      token,
      null,
      dispatch,
      didCallGetTrashes,
    );
  };

// ゴミ箱削除API
const didCallPostTrashesDelete = () => {
  // do nothing.
};

export const sendPostTrashesDelete =
  (
    token: string | undefined,
    tenantId: string,
    data: Array<number>,
    nextFunc: (() => void) | null | undefined,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/trashes/delete`,
      token,
      JSON.stringify({ trashIds: data }),
      dispatch,
      didCallPostTrashesDelete,
    ).finally(nextFunc);
  };

// ゴミ箱戻しAPI
const didCallPostTrashesUndo = () => {
  // do nothing.
};

export const sendPostTrashesUndo =
  (
    token: string | undefined,
    tenantId: string,
    data: Array<number>,
    nextFunc: (() => void) | null | undefined,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/trashes/undo`,
      token,
      JSON.stringify({ trashIds: data }),
      dispatch,
      didCallPostTrashesUndo,
    ).finally(nextFunc);
  };

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { AppError } from 'core/domain/appError'

const initialState = null

export function createInitialState(): AppError | null {
  return null
}

export const SET_ERROR = 'error/set' as const
export const CLEAR_ERROR = 'error/clear' as const

export function setError(error: AppError) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  }
}
export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

type SetErrorAction = ReturnType<typeof setError>
type ClearErrorAction = ReturnType<typeof clearError>
export type Action = SetErrorAction | ClearErrorAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ERROR:
      return action.payload.error
    case CLEAR_ERROR:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  PrimaryButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'components/atoms';
import { TYPOGRAPHY } from 'constants/index';
import { BoxProps, TableCellProps } from '@material-ui/core';
import { NoticeDetail } from '../../../core/domain/settings';
import { convertPublishDateToString } from '../../../libs/converter';
import StatusLabel from '../../atoms/StatusLabel';
import { hooks } from '../../../libs';
import RadiusBox from '../../atoms/RadiusBox';
import palette from '../../../styles/palette';
import {
  get2ByteCheckedLength,
  getIndexTo2ByteIndex,
} from '../../../libs/string';

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1000,
      // tableLayout: 'fixed',
    },
    head: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },

    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },

    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
  }),
);

export interface ContactHistoryTableProps {
  notices?: Array<NoticeDetail>;
}

interface TableHeader<T> {
  label: string;
  key?: keyof T;
  CustomComponent?: React.FC<{ self: T }>;
  boxProps?: BoxProps;
  tableCellProps?: TableCellProps;
}

const headers: Array<TableHeader<NoticeDetail>> = [
  {
    label: 'ステータス',
    tableCellProps: {
      style: {
        width: 80,
      },
    },
    CustomComponent: ({ self }) => {
      const { color, label } = useMemo(() => {
        // switch (self.noticeType) {
        switch (self.noticeStatus) {
          case 1:
            return { color: 'blue' as const, label: '掲載予約' };
          case 2:
            return { color: 'orange' as const, label: '掲載中' };
          case 3:
            return { color: 'gray' as const, label: '掲載終了' };
          default:
            return { color: 'default' as const, label: '' };
        }
      }, [self.noticeStatus]);

      return <StatusLabel width="100%" color={color} label={label} />;
    },
  },
  {
    label: '件名',
    tableCellProps: {
      style: {
        width: 180,
        color: palette.green[900],
      },
    },
    CustomComponent: ({ self }) => {
      const useToolTip = get2ByteCheckedLength(self.subject) > 40;
      const showLabel = useToolTip
        ? self.subject.slice(0, getIndexTo2ByteIndex(self.subject, 40) || 60)
        : self.subject;

      return (
        <>
          <Box color="#222222">
            <Tooltip title={self.subject || ''}>
              <Box>
                {useToolTip ? (
                  <Typography variant="body2">{showLabel}...</Typography>
                ) : (
                  <Typography variant="body2">{showLabel}</Typography>
                )}
              </Box>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
  {
    label: '登録者',
    tableCellProps: {
      style: {
        width: 120,
      },
    },
    CustomComponent: ({ self }) => {
      const useToolTip =
        self.noticeCreatedUser?.userName &&
        get2ByteCheckedLength(self.noticeCreatedUser?.userName) > 40;
      const showLabel = self.noticeCreatedUser?.userName
        ? self.noticeCreatedUser.userName.slice(
            0,
            getIndexTo2ByteIndex(self.noticeCreatedUser?.userName, 40) || 60,
          )
        : undefined;

      return (
        <>
          <Box color="#222222">
            <Tooltip title={self.noticeCreatedUser?.userName || ''}>
              <Box>
                {useToolTip ? (
                  <Typography variant="body2">{showLabel}...</Typography>
                ) : (
                  <Typography variant="body2">{showLabel}</Typography>
                )}
              </Box>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
  {
    label: '掲載開始日時',
    tableCellProps: {
      style: {},
    },
    CustomComponent: ({ self }) => (
      <>
        {convertPublishDateToString({
          date: self.publishStartDate,
          time: self.publishStartTime,
        })}
      </>
    ),
  },
  {
    label: '掲載終了日時',
    tableCellProps: {
      style: {},
    },
    CustomComponent: ({ self }) => (
      <>
        {convertPublishDateToString({
          date: self.publishEndDate,
          time: self.publishEndTime,
        })}
      </>
    ),
  },
  {
    label: '',
    tableCellProps: {
      style: {
        width: 60,
      },
    },
    boxProps: {
      height: 30,
    },
    CustomComponent: ({ self }) => {
      const { navigate } = hooks.useNavigate();

      return (
        <PrimaryButton
          text="詳細"
          click={() => navigate(`/adm/notices/${self.noticeId}/edit`)}
        />
      );
    },
  },
];

const TenantNoticesTable = (props: ContactHistoryTableProps) => {
  const { notices } = props;

  const classes = useStyles({});

  return (
    <RadiusBox>
      <TableContainer className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {notices?.map((row) => (
              <TableRow key={row.noticeId}>
                {headers.map((header, index) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TableCell key={index} {...header.tableCellProps}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Box {...header.boxProps}>
                      {header.key
                        ? row[header.key]
                        : header.CustomComponent && (
                            <header.CustomComponent self={row} />
                          )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RadiusBox>
  );
};
export default TenantNoticesTable;

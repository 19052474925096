import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  WorkflowStatusLabel,
  OpenStatusLabel,
  Tooltip,
  Typography,
} from 'components/atoms';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { Lps, LpSearchCondition } from 'core/domain/lp';
import { formatDateTimeSecISO8601 } from 'libs/date';

import clsx from 'clsx';

/**
 * Interface
 */

export interface MyLpsTableProps {
  own: boolean;
  lps: Lps | null;
  clickLp?: (id: number, approved: boolean) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleClickLpProductInterestedCount: (lpId: number) => void;
  handleClickLpProductDocumentDownloadCount: (lpId: number) => void;
  searchCondition: LpSearchCondition | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.gray[400],
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    status: {
      width: 96,
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 150,
      },
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: '公開\nステータス',
    sortKey: 'open',
  },
  {
    title: '承認\nステータス',
    sortKey: 'workflow',
  },
  {
    title: 'ランディングページID',
    sortKey: 'lpId',
  },
  {
    title: 'ランディングページ名',
    sortKey: 'title',
  },
  {
    title: '得意先名',
    sortKey: 'tokuisakiName',
  },
  {
    title: 'ページ\n閲覧数',
    sortKey: 'accessCount',
  },
  {
    title: '興味アリ\n獲得数',
    sortKey: 'interestedCount',
  },
  {
    title: '資料\nダウンロード数',
    sortKey: 'downloadCount',
  },
  {
    title: 'お問い合わせ数',
    sortKey: 'inquiryCount',
  },
  {
    title: '担当者',
    sortKey: 'lpTantoUser',
  },
  {
    title: '最終更新日時',
    sortKey: 'updatedDate',
  },
];

const MyLPsTable = (props: MyLpsTableProps) => {
  const classes = useStyles({});
  const {
    own,
    lps,
    clickLp,
    handleChangeSortKey,
    handleClickLpProductInterestedCount,
    handleClickLpProductDocumentDownloadCount,
    searchCondition,
  } = props;

  const headerItems = useMemo(
    () =>
      !own
        ? TABLE_HEADER_ITEMS
        : TABLE_HEADER_ITEMS.filter((item) => item.title !== '担当者'),
    [own],
  );

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {headerItems.map((item, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active
                  direction={searchCondition?.sort_order}
                  IconComponent={() =>
                    item.sortKey === searchCondition?.sort_key &&
                    searchCondition?.sort_order === 'asc' ? (
                      <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <SortDownIcon />
                    )
                  }
                  onClick={() => handleChangeSortKey(item.sortKey)}
                >
                  {item.title}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {lps &&
            lps.lps.map((lp, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box className={classes.status}>
                    <OpenStatusLabel status={lp.openStatus} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className={classes.status}>
                    <WorkflowStatusLabel status={lp.workflowStatus} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Tooltip title={lp.lpId || ''} arrow placement="top">
                    <span style={{ display: 'flex' }}>{lp.lpId}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.link}>
                  <ButtonLink
                    text={lp.title}
                    tooltipText={lp.title}
                    click={() => clickLp && clickLp(lp.lpId, lp.approved)}
                  />
                </TableCell>
                <TableCell className={clsx(classes.midCell, classes.overflow)}>
                  <Tooltip
                    title={lp.tokuisakiName ? lp.tokuisakiName : ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">{lp.tokuisakiName}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      lp.lpReaction
                        ? Number(lp.lpReaction.accessCount).toLocaleString()
                        : '-'
                    }
                    arrow
                    placement="top"
                  >
                    <span style={{ display: 'flow-root' }}>
                      {lp.lpReaction
                        ? Number(lp.lpReaction.accessCount).toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Box
                    textAlign="right"
                    width="100%"
                    marginLeft="auto"
                    marginRight={0}
                  >
                    <ButtonLink
                      alignRight
                      text={
                        lp.lpReaction
                          ? Number(
                              lp.lpReaction.interestedCount,
                            ).toLocaleString()
                          : '-'
                      }
                      tooltipText={
                        lp.lpReaction
                          ? Number(
                              lp.lpReaction.interestedCount,
                            ).toLocaleString()
                          : '-'
                      }
                      click={() => {
                        handleClickLpProductInterestedCount(lp.lpId);
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <ButtonLink
                    alignRight
                    text={
                      lp.lpReaction
                        ? Number(lp.lpReaction.downloadCount).toLocaleString()
                        : '-'
                    }
                    tooltipText={
                      lp.lpReaction
                        ? Number(lp.lpReaction.downloadCount).toLocaleString()
                        : '-'
                    }
                    click={() => {
                      handleClickLpProductDocumentDownloadCount(lp.lpId);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      lp.lpReaction
                        ? Number(lp.lpReaction.inquiryCount).toLocaleString()
                        : '-'
                    }
                    arrow
                    placement="top"
                  >
                    <span style={{ display: 'flow-root' }}>
                      {lp.lpReaction
                        ? Number(lp.lpReaction.inquiryCount).toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                {!own && (
                  <TableCell
                    className={clsx(classes.midCell, classes.overflow)}
                  >
                    <Tooltip title={lp.lpTantoUserName} arrow placement="top">
                      <Typography variant="body2">
                        {lp.lpTantoUserName}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell>
                  {formatDateTimeSecISO8601(lp.updatedDate).replace(' ', '\n')}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default MyLPsTable;

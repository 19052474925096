import { Notices } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): Notices | null => initialState;

export const SET_DASHBOARD_NOTICES = 'dashboardNotices/set' as const;
export const CLEAR_DASHBOARD_NOTICES = 'dashboardNotices/clear' as const;

export const setDashboardNotices = (data: Notices) => ({
  type: SET_DASHBOARD_NOTICES,
  payload: {
    data,
  },
});

export const clearDashboardNotices = () => ({
  type: CLEAR_DASHBOARD_NOTICES,
});

type SetDashboardNoticesAction = ReturnType<typeof setDashboardNotices>;
type ClearDashboardNoticesAction = ReturnType<typeof clearDashboardNotices>;
type Action = SetDashboardNoticesAction | ClearDashboardNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_DASHBOARD_NOTICES:
      return action.payload.data;
    case CLEAR_DASHBOARD_NOTICES:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ExampleDetail } from 'core/domain/example'

const initialState = null

export const SET_PRODUCT_EXAMPLES = 'product-examples/set' as const
export const CLEAR_PRODUCT_EXAMPLES = 'product-examples/clear' as const

export function createInitialState(): Array<ExampleDetail> | null {
  return initialState
}

export function setProductExamples(data: Array<ExampleDetail>) {
  return {
    type: SET_PRODUCT_EXAMPLES,
    payload: {
      data,
    },
  }
}

export function clearProductExamples() {
  return {
    type: CLEAR_PRODUCT_EXAMPLES,
  }
}

type SetProductExamplesAction = ReturnType<typeof setProductExamples>
type ClearProductExamplesAction = ReturnType<typeof clearProductExamples>
type Action = SetProductExamplesAction | ClearProductExamplesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCT_EXAMPLES:
      return action.payload.data
    case CLEAR_PRODUCT_EXAMPLES:
      return initialState
    default:
      return state
  }
}


/* eslint-enable */

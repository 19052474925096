import React from 'react';
import { Box, SelectBox, SubHeadlineLabel } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setManageUsersSearchCondition } from 'core/modules/manageUsersSearchCondition';
import { Modules } from 'core';
import { PULLDOWN_OPTIONS } from './options';

interface UserAuthorityPullDownFieldProps {
  error: string | undefined;

  // 「lp作成者」「LP承認者」「商材・事例作成者」「商材・事例承認者」「管理者」;
  type: 'authority';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
  }),
);

const UserAuthorityPullDownField = (props: UserAuthorityPullDownFieldProps) => {
  const { error, type } = props;

  const classes = useStyles();

  const isMandatory = false;
  const title = ' ';

  const manageUsersSearchCondition = useSelector(
    (state: Modules.AppState) => state.manageUsersSearchCondition,
    shallowEqual,
  );

  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
  );

  const dispatch = useDispatch();

  const handleChangePullDown = (value: string) => {
    dispatch(
      setManageUsersSearchCondition({
        ...manageUsersSearchCondition,
        free_word:freeWord?.freeWord,
        user_authority: value,
      }),
    );
  };

  return (
    <Box
      className={`${classes.root} ${
        isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot
      }`}
      display="flex"
      alignItems="center"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title={title} />
      ) : (
        <SubHeadlineLabel text={title} />
      )}
      <Box>
        <SelectBox
          defaultValue={manageUsersSearchCondition?.user_authority}
          error={error}
          label="権限で絞り込む"
          options={PULLDOWN_OPTIONS[type]}
          handleChange={(value) => {
            handleChangePullDown(value);
          }}
        />
      </Box>
    </Box>
  );
};

export default UserAuthorityPullDownField;

import { VariousSettings } from 'core/domain/variousSettings';

const initialState = null;

export const createInitialState = (): VariousSettings | null => initialState;

export const SET_NEW_VARIOUS_SETTINGS = 'new-various-settings/set' as const;
export const CLEAR_NEW_VARIOUS_SETTINGS = 'new-various-settings/clear' as const;

export const setNewVariousSettings = (data: VariousSettings) => ({
  type: SET_NEW_VARIOUS_SETTINGS,
  payload: {
    data,
  },
});

export const clearNewVariousSettings = () => ({
  type: CLEAR_NEW_VARIOUS_SETTINGS,
});

type SetNewVariousSettings = ReturnType<typeof setNewVariousSettings>;
type ClearNewVariousSettings = ReturnType<typeof clearNewVariousSettings>;
type Action = SetNewVariousSettings | ClearNewVariousSettings;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NEW_VARIOUS_SETTINGS:
      return action.payload.data;
    case CLEAR_NEW_VARIOUS_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

import { Dispatch } from 'redux';
import * as Api from 'libs/api';
import { setLpDetail } from 'core/modules/lpDetail';
import { setLpWorkflows } from 'core/modules/lpWorkflows';
import {
  LpDetail,
  LpInquiries,
  LpReaction,
  LPRegisterRequest,
  Lps,
  LpTags,
  LpWorkflows,
  ProductComment,
  Tokuisaki,
  LpSearchCondition,
  LpSearchDefaultAddTerm,
  MailHistory,
  MailSendRequest,
  LpsAdmin,
  LpView,
  LpProductDetailCounts,
  LpOpenSettings,
} from 'core/domain/lp';
import { setLpReaction } from 'core/modules/lpReaction';
import { setLpInquiries } from 'core/modules/lpInquiries';
import { setLpTags } from 'core/modules/lpTags';
import { setLpsOwn } from 'core/modules/lpsOwn';
import { setLpsSubordinates } from 'core/modules/lpsSubordinates';
import { TagsRequest } from 'core/domain/tag';
import { setLpsAdmin } from 'core/modules/lpsAdmin';
import { setProductComment } from 'core/modules/productComment';
import { setLpTokuisaki } from 'core/modules/lpTokuisaki';
import { setLpProductDetailCounts } from 'core/modules/lpProductDetailCounts';
import { setLps } from 'core/modules/lps';
import { setLpSearchDefaultAddTerm } from 'core/modules/lpSearchDefaultAddTerm';
import { appendLpProductDetails } from 'core/modules/lpProductDetails';
import { appendLpExampleDetails } from 'core/modules/lpExampleDetails';
import { ProductDetail } from 'core/domain/product';
import { ExampleDetail } from 'core/domain/example';
import { setMailHistory } from 'core/modules/mailHistory';
import { downloadFileFromBase64 } from 'libs/downloader';
import { setLpView } from 'core/modules/lpView';

const didCallGetLpDetailApi = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendGetLpDetail =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    approved: boolean,
    mode: number,
  ) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/lps/{lp_id}
    await Api.callApi(
      Api.HttpMethod.post,
      !approved
        ? `${tenantId}/lps/${lpId}`
        : `${tenantId}/lps/${lpId}/approved`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallGetLpDetailApi,
    );
  };

const didCallGetLpSearchDefaultAddTermApi = (
  dispatch: Dispatch,
  data: LpSearchDefaultAddTerm,
) => {
  dispatch(setLpSearchDefaultAddTerm(data));
};

export const sendGetLpSearchDefaultAddTerm =
  (token: string | undefined, tenantId: string) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/settings/dashboard/add-term
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/settings/dashboard/add-term`,
      token,
      null,
      dispatch,
      didCallGetLpSearchDefaultAddTermApi,
    );
  };

const didCallGetLpReactionApi = (dispatch: Dispatch, data: LpReaction) => {
  dispatch(setLpReaction(data));
};

export const sendGetLpReaction =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    start?: string,
    end?: string,
  ) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/lps/{lp_id}/reaction
    const param = {
      start_date: start || undefined,
      end_date: end || undefined,
    };
    const api = param ? `?${Api.createApi(param)}` : '';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/reaction${api}`,
      token,
      null,
      dispatch,
      didCallGetLpReactionApi,
    );
  };

export const sendPatchLpFavorite =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    favorite: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/lps/{lp_id}/favorite
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/lps/${lpId}/favorite`,
      token,
      JSON.stringify({ favorite }),
      dispatch,
    );
  };

const didCallGetLpWorkflowsApi = (dispatch: Dispatch, data: LpWorkflows) => {
  dispatch(setLpWorkflows(data.lpWorkflows));
};

export const sendGetLpWorkflows =
  (token: string | undefined, tenantId: string, lpId: number) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/lps/{lp_id}/workflows
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/workflows`,
      token,
      null,
      dispatch,
      didCallGetLpWorkflowsApi,
    );
  };

const didCallGetLpInquiryTableApi = (dispatch: Dispatch, data: LpInquiries) => {
  dispatch(setLpInquiries(data));
};

export const sendGetLpInquiries =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    sortKey?: string,
    sortOrder?: string,
  ) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/lps/{lp_id}/inquiries
    const query =
      sortKey && sortOrder
        ? `?sort_key=${sortKey}&sort_order=${sortOrder}`
        : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/inquiries${query}`,
      token,
      null,
      dispatch,
      didCallGetLpInquiryTableApi,
    );
  };

const didCallPatchLpNoteApi = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendPatchLpNote =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    note: string,
    approved: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/lps/{lp_id}/note
    await Api.callApi(
      Api.HttpMethod.patch,
      !approved
        ? `${tenantId}/lps/${lpId}/note`
        : `${tenantId}/lps/${lpId}/note/approved`,
      token,
      JSON.stringify({ note }),
      dispatch,
      didCallPatchLpNoteApi,
    );
  };

const didCallPatchLpWorkflowStatusApi = (
  dispatch: Dispatch,
  data: LpWorkflows,
) => {
  dispatch(setLpWorkflows(data.lpWorkflows));
};

export const sendPatchLpWorkflowStatus =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    workflowId: number,
    step: number,
    status: number,
    message?: string,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/lps/{lp_id}/workflows/{workflow_id}/status
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/lps/${lpId}/workflows/${workflowId}/status`,
      token,
      JSON.stringify({ step, status, message }),
      dispatch,
      didCallPatchLpWorkflowStatusApi,
    );
  };

const didCallPostLpStopApi = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendPostLpStop =
  (token: string | undefined, tenantId: string, lpId: number) =>
  async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/lps/{lp_id}/stop
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/stop`,
      token,
      null,
      dispatch,
      didCallPostLpStopApi,
    );
  };

const didCallPostLpReopenApi = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendPostLpReopen =
  (token: string | undefined, tenantId: string, lpId: number) =>
  async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/lps/{lp_id}/reopen
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/reopen`,
      token,
      null,
      dispatch,
      didCallPostLpReopenApi,
    );
  };

const didCallPostLpOpenApi = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendPostLpOpen =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    date?: string,
    time?: string,
  ) =>
  async (dispatch: Dispatch) => {
    // Send POST /{tenant_id}/lps/{lp_id}/open
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/open`,
      token,
      JSON.stringify({ openPeriodDate: date, openPeriodTime: time }),
      dispatch,
      didCallPostLpOpenApi,
    );
  };

export const sendPostLp =
  (token: string | undefined, tenantId: string, data: LPRegisterRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps`,
      token,
      JSON.stringify({ ...data }),
      dispatch,
    );
  };

export const sendPutLp =
  (
    token: string | undefined,
    tenantId: string,
    lpId: string,
    data: LPRegisterRequest,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.put,
      `${tenantId}/lps/${lpId}`,
      token,
      JSON.stringify({ ...data }),
      dispatch,
    );
  };

const didCallGetLpTags = (dispatch: Dispatch, data: LpTags) => {
  dispatch(setLpTags(data));
};

export const sendGetLpTags =
  (token: string | undefined, tenantId: string, tags: TagsRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/tags?tag_type=${tags.tagType || ''}&sort_key=${
        tags.sortKey || ''
      }`,
      token,
      null,
      dispatch,
      didCallGetLpTags,
    );
  };

const didCallGetProductComment = (dispatch: Dispatch, data: ProductComment) => {
  dispatch(setProductComment(data));
};

export const sendGetProductComment =
  (token: string | undefined, tenantId: string, productId: number) =>
  async (dispatch: Dispatch) => {
    // FIXME:product id IF設計修正されたら反映する
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/products/${productId}/recent-comment`,
      token,
      null,
      dispatch,
      didCallGetProductComment,
    );
  };

const didCallGetLPTokuisaki = (dispatch: Dispatch, data: Tokuisaki) => {
  dispatch(setLpTokuisaki(data));
};

export const sendGetLPTokuisaki =
  (token: string | undefined, tenantId: string, param: string) =>
  async (dispatch: Dispatch) => {
    let apiName = `${tenantId}/lps/tokuisaki`;
    if (param) {
      apiName += `?free_word=${encodeURIComponent(param)}`;
    }
    await Api.callApi(
      Api.HttpMethod.get,
      apiName,
      token,
      null,
      dispatch,
      didCallGetLPTokuisaki,
    );
  };

const didCallGetLpsOwn = (dispatch: Dispatch, data: Lps) => {
  dispatch(setLpsOwn(data));
};

export const sendGetLpsOwn =
  (
    token: string | undefined,
    tenantId: string,
    param: LpSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/own${api}`,
      token,
      null,
      dispatch,
      didCallGetLpsOwn,
    );
  };

const didCallGetlpsSubordinates = (dispatch: Dispatch, data: Lps) => {
  dispatch(setLpsSubordinates(data));
};

export const sendGetLpsSubordinates =
  (
    token: string | undefined,
    tenantId: string,
    param: LpSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/subordinates${api}`,
      token,
      null,
      dispatch,
      didCallGetlpsSubordinates,
    );
  };

const didCallGetlps = (dispatch: Dispatch, data: Lps) => {
  dispatch(setLps(data));
};

export const sendGetLps =
  (
    token: string | undefined,
    tenantId: string,
    param: LpSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '';
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps${api}`,
      token,
      null,
      dispatch,
      didCallGetlps,
    );
  };

const didCallGetProductDetailApi = (
  dispatch: Dispatch,
  data: ProductDetail,
) => {
  dispatch(appendLpProductDetails(data));
};

export const sendGetProductDetail =
  (
    token: string | undefined,
    tenantId: string,
    productId: number,
    mode: number,
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/products/${productId}`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallGetProductDetailApi,
    );
  };

const didCallGetExampleDetailApi = (
  dispatch: Dispatch,
  data: ExampleDetail,
) => {
  dispatch(appendLpExampleDetails(data));
};

export const sendGetExampleDetail =
  (
    token: string | undefined,
    tenantId: string,
    exampleId: number,
    mode: number,
  ) =>
  async (dispatch: Dispatch) => {
    // Send GET /{tenant_id}/examples/{example_id}
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/examples/${exampleId}`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallGetExampleDetailApi,
    );
  };

const didCallPostLpTrash = (dispatch: Dispatch, data: LpDetail) => {
  dispatch(setLpDetail(data));
};

export const sendPostLpTrash =
  (token: string | undefined, tenantId: string, lpId: string) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/trash`,
      token,
      null,
      dispatch,
      didCallPostLpTrash,
    );
  };

export const sendPostLpMail =
  (token: string, tenantId: string, lpId: string, body: MailSendRequest) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/mail`,
      token,
      JSON.stringify(body),
      dispatch,
    );
  };

const didCallGetMailHistory = (dispatch: Dispatch, data: MailHistory) => {
  dispatch(setMailHistory(data));
};

export const sendGetMailHistory =
  (token: string | undefined, tenantId: string, lpId: string, page: number) =>
  async (dispatch: Dispatch) => {
    const query = `?page=${page}&row=5`;
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/mail${query}`,
      token,
      null,
      dispatch,
      didCallGetMailHistory,
    );
  };

const didCallGetLpsAdmin = (dispatch: Dispatch, data: LpsAdmin) => {
  dispatch(setLpsAdmin(data));
};

export const sendGetLpsAdmin =
  (
    token: string | undefined,
    tenantId: string,
    param: LpSearchCondition | null,
  ) =>
  async (dispatch: Dispatch) => {
    const api = param ? `?${Api.createApi(param)}` : '?page=1&row=10';

    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/admin${api}`,
      token,
      null,
      dispatch,
      didCallGetLpsAdmin,
    );
  };

const didCallPostStopLps = (dispatch: Dispatch, data: LpsAdmin) => {
  dispatch(setLpsAdmin(data));
};

export const sendPostStopLps =
  (token: string | undefined, tenantId: string, data: Array<number>) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/stop`,
      token,
      JSON.stringify({ lpIds: data }),
      dispatch,
      didCallPostStopLps,
    );
  };

const didCallPostDeleteLps = (dispatch: Dispatch, data: LpsAdmin) => {
  dispatch(setLpsAdmin(data));
};

export const sendPostDeleteLps =
  (
    token: string | undefined,
    tenantId: string,
    data: Array<number>,
    updateDispProcess: (() => void) | null | undefined, // 更新後の処理
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/trash`,
      token,
      JSON.stringify({ lpIds: data }),
      dispatch,
      didCallPostDeleteLps,
    ).finally(updateDispProcess);
  };

const didCallPostChangeTantoLps = (dispatch: Dispatch, data: LpsAdmin) => {
  dispatch(setLpsAdmin(data));
};

export const PostChangeTantoLps =
  (
    token: string | undefined,
    tenantId: string,
    data: Array<number>,
    userId: number | null,
    updateDispProcess: (() => void) | null | undefined, // 更新後の処理
  ) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/tanto`,
      token,
      JSON.stringify({ lpIds: data, lpTantoUserId: userId }),
      dispatch,
      didCallPostChangeTantoLps,
    ).finally(updateDispProcess);
  };
/* eslint-enable */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const didCallLpReactionDownLoad = (dispatch: Dispatch, data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  void downloadFileFromBase64(data.data, 'ページ閲覧履歴.zip');
};

export const sendLpReactionDownLoad =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    start: string | undefined,
    end: string | undefined,
  ) =>
  async (dispatch: Dispatch) => {
    const param = {
      start_date: start || undefined,
      end_date: end || undefined,
    };

    const api = start || end ? `?${Api.createApi(param)}` : '';

    await Api.callApi(
      Api.HttpMethod.post,
      `${tenantId}/lps/${lpId}/reaction/download${api}`,
      token,
      null,
      dispatch,
      didCallLpReactionDownLoad,
    );
  };

const didCallPostLpView = (dispatch: Dispatch, data: LpView) => {
  dispatch(setLpView(data));
};

export const sendPostLpView =
  (token: string | undefined, tenantId: string, lpId: number, mode: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `/${tenantId}/lps/${lpId}/preview`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallPostLpView,
    );
  };

export const sendPostApprovedLpView =
  (token: string | undefined, tenantId: string, lpId: number, mode: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.post,
      `/${tenantId}/lps/${lpId}/approved/preview`,
      token,
      JSON.stringify({ mode }),
      dispatch,
      didCallPostLpView,
    );
  };

const didCallGetLpProductInterestedCount = (
  dispatch: Dispatch,
  data: LpProductDetailCounts,
) => {
  dispatch(setLpProductDetailCounts(data));
};

export const sendGetLpProductInterestedCount =
  (token: string | undefined, tenantId: string, lpId: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/interested-count`,
      token,
      null,
      dispatch,
      didCallGetLpProductInterestedCount,
    );
  };

const didCallGetLpProductDocumentDownloadCount = (
  dispatch: Dispatch,
  data: LpProductDetailCounts,
) => {
  dispatch(setLpProductDetailCounts(data));
};

export const sendGetLpProductDocumentDownloadCount =
  (token: string | undefined, tenantId: string, lpId: number) =>
  async (dispatch: Dispatch) => {
    await Api.callApi(
      Api.HttpMethod.get,
      `${tenantId}/lps/${lpId}/document-download-count`,
      token,
      null,
      dispatch,
      didCallGetLpProductDocumentDownloadCount,
    );
  };
/** ランディングページ公開情報更新 */
const didCallPatchLpOpenSettings =
  (/* dispatch: Dispatch, data: LpDetail */) => {
    // dispatch(setLpDetail(data));
  };

export const sendPatchLpOpenSettings =
  (
    token: string | undefined,
    tenantId: string,
    lpId: number,
    data: LpOpenSettings,
    nextFunc: (() => void) | null | undefined,
  ) =>
  async (dispatch: Dispatch) => {
    // Send PATCH /{tenant_id}/lps/{lp_id}
    await Api.callApi(
      Api.HttpMethod.patch,
      `${tenantId}/lps/${lpId}`,
      token,
      JSON.stringify(data),
      dispatch,
      didCallPatchLpOpenSettings,
    ).finally(nextFunc);
  };

import { ReactionRanking } from 'core/domain/dashboard';

const initialState = null;

export const createInitialState = (): ReactionRanking | null => initialState;

export const SET_REACTION_RANKING = 'reaction-ranking/set' as const;
export const CLEAR_REACTION_RANKING = 'reaction-ranking/clear' as const;

export const setReactionRanking = (data: ReactionRanking) => ({
  type: SET_REACTION_RANKING,
  payload: {
    data,
  },
});

export const clearReactionRanking = () => ({
  type: CLEAR_REACTION_RANKING,
});

type SetReactionRankingAction = ReturnType<typeof setReactionRanking>;
type ClearReactionRankingAction = ReturnType<typeof clearReactionRanking>;
type Action = SetReactionRankingAction | ClearReactionRankingAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_REACTION_RANKING:
      return action.payload.data;
    case CLEAR_REACTION_RANKING:
      return initialState;
    default:
      return state;
  }
};

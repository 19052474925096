/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { TantoCompanies } from 'core/domain/settings'

const initialState = null

export function createInitialState(): TantoCompanies | null {
  return initialState
}

export const SET_TANTO_COMPANIES = 'tanto-companies/set' as const
export const CLEAR_TANTO_COMPANIES = 'tanto-companies/clear' as const

export function setTantoCompanies(data: TantoCompanies) {
  return {
    type: SET_TANTO_COMPANIES,
    payload: {
      data,
    },
  }
}

export function clearTantoCompanies() {
  return {
    type: CLEAR_TANTO_COMPANIES,
  }
}

type SetTantoCompaniesAction = ReturnType<typeof setTantoCompanies>
type ClearTantoCompaniesAction = ReturnType<typeof clearTantoCompanies>
type Action = SetTantoCompaniesAction | ClearTantoCompaniesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TANTO_COMPANIES:
      return action.payload.data
    case CLEAR_TANTO_COMPANIES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Modules } from "core"
import { useSelector } from "react-redux"

export const useApproval = () => {
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)
  
  const hasMaterialApprovalUser = () => {
    if(userInfo?.materialApprovalUsers?.step1ApprovalUserId){
      return true
    }

      return false
    
  }

  const hasLpApprovalUser = () => {
    if(userInfo?.lpApprovalUsers?.step1ApprovalUserId){
      return true
    }

      return false
    
  }
  
  return {
    hasMaterialApprovalUser,
    hasLpApprovalUser,
  }
}/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider } from '@material-ui/core';
import { SALAD_BAR_GRAY_700 } from 'constants/color';
import { COPYRIGHT } from 'constants/text';

/**
 * Style
 */

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '64px',
    },
    textBox: {
      height: '35px',
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#F7F7F5',
    },
    text: {
      color: SALAD_BAR_GRAY_700,
      fontSize: '12px',
    },
  }),
);

/**
 * Presenter
 */

const FooterBar = React.memo(() => {
  const classes = useStyles({});

  return (
    <Box component="div" className={classes.root}>
      <Divider />
      <Box className={classes.textBox}>
        <Typography className={classes.text}>{COPYRIGHT}</Typography>
      </Box>
    </Box>
  );
});

export default FooterBar;
/* eslint-enable */

import React, { useState, useEffect } from 'react';
import { Box, OneLineTextField, SubHeadlineLabel } from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface InquiryMailAddressFieldProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  onChange: (n: number, s: string, v: string) => void;
  // setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  secondTimesOnwords: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    fieldsArea: {
      width: 320,
      '& > :first-child': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

const PATTERN = new RegExp(
  /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
);

const InquiryMailAddressField = (props: InquiryMailAddressFieldProps) => {
  const {
    defaultValue,
    error,
    inquiryItemId,
    isMandatory = false,
    onChange,
    // setIsDisabled,
    secondTimesOnwords,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState<string>(defaultValue || '');
  const [confirmValue, setConfirmValue] = useState<string>('');
  const [confirmValueError, setConfirmValueError] = useState<string>('');
  const [defaultSetFlag, setDefaultSetFlag] = useState(false);

  useEffect(() => {
    // 新規登録ではdefaultValueを代入しない
    if (!secondTimesOnwords) return;
    // defaultValueは1度取得したら再代入しない
    if (defaultSetFlag) return;

    setValue(defaultValue || '');
    setConfirmValue(defaultValue || '');
    setDefaultSetFlag(true);
  }, [defaultValue, secondTimesOnwords, defaultSetFlag]);

  const handleConfirmValueError = () => {
    setConfirmValueError('入力したメールアドレスが一致しません');
    onChange(inquiryItemId, 'メールアドレス', '');
    // setIsDisabled(true);
  };

  const handleMailAddressFormatError = () => {
    setConfirmValueError('メールアドレスの形式で入力してください');
    onChange(inquiryItemId, 'メールアドレス', '');
    // setIsDisabled(true);
  };

  const handleLocalCharactorLimitError = () => {
    setConfirmValueError(
      'メールアドレスのローカル部は64文字までで入力してください',
    );
    onChange(inquiryItemId, 'メールアドレス', '');
    // setIsDisabled(true);
  };

  const handleDomainCharactorLimitError = () => {
    setConfirmValueError(
      'メールアドレスのドメイン部は64文字までで入力してください',
    );
    onChange(inquiryItemId, 'メールアドレス', '');
    // setIsDisabled(true);
  };

  const handleCharactorLimitError = () => {
    setConfirmValueError('メールアドレスは256文字までで入力してください');
    onChange(inquiryItemId, 'メールアドレス', '');
    // setIsDisabled(true);
  };

  const handleTrueValueSet = () => {
    // setIsDisabled(false);
    setConfirmValueError('');
    onChange(inquiryItemId, 'メールアドレス', value);
  };

  return (
    <Box
      className={`${classes.root}
        ${isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot}`}
      display="flex"
    >
      {isMandatory ? (
        <MandatoryHeadlineLabel title="メールアドレス" />
      ) : (
        <SubHeadlineLabel text="メールアドレス" />
      )}
      <Box className={classes.fieldsArea}>
        <OneLineTextField
          value={value}
          error={error}
          handleChangeText={setValue}
          handleBlur={(v: string) => {
            if (v !== value || (confirmValue && v !== confirmValue)) {
              handleConfirmValueError();

              return;
            }
            if (!PATTERN.test(v)) {
              handleMailAddressFormatError();

              return;
            }
            
            if (v.length > 256) {
              handleCharactorLimitError();

              return;
            }

            if (v.split('@')[0].length > 64) {
              handleLocalCharactorLimitError();

              return;
            }

            if (v.split('@')[1].length > 64) {
              handleDomainCharactorLimitError();

              return;
            }

            handleTrueValueSet();
          }}
        />
        <OneLineTextField
          error={confirmValueError}
          label="確認用メールアドレス"
          value={confirmValue}
          handleChangeText={setConfirmValue || error}
          handleBlur={(v: string) => {
            if (v !== value || v.split('@').length > 2) {
              handleConfirmValueError();

              return;
            }

            if (!PATTERN.test(v)) {
              handleMailAddressFormatError();

              return;
            }

            if (v.length > 256) {
              handleCharactorLimitError();

              return;
            }

            if (v.split('@')[0].length > 64) {
              handleLocalCharactorLimitError();

              return;
            }

            if (v.split('@')[1].length > 64) {
              handleDomainCharactorLimitError();

              return;
            }

            handleTrueValueSet();
          }}
        />
      </Box>
    </Box>
  );
};

export default InquiryMailAddressField;

import { COLORS } from 'constants/index';

const pallets = {
  primary: {
    light: COLORS.SALAD_BAR_GREEN_800,
    main: COLORS.SALAD_BAR_GREEN_800,
    dark: COLORS.SALAD_BAR_GREEN_900,
    contrastText: COLORS.SALAD_BAR_GREEN_800,
  },
  secondary: {
    light: COLORS.SALAD_BAR_GREEN_800,
    main: COLORS.SALAD_BAR_GREEN_800,
    dark: COLORS.SALAD_BAR_GREEN_900,
    contrastText: COLORS.SALAD_BAR_GREEN_800,
  },
  default: {
    800: COLORS.SALAD_BAR_GREEN_800,
    900: COLORS.SALAD_BAR_GREEN_900,
  },
  text: {
    primary: COLORS.SALAD_BAR_BLACK,
    secondary: COLORS.SALAD_BAR_WHITE,
    disabled: COLORS.SALAD_BAR_BLACK,
    hint: COLORS.SALAD_BAR_GREEN_800,
  },
  gray: {
    300: COLORS.SALAD_BAR_GRAY_300,
    400: COLORS.SALAD_BAR_GRAY_400,
    500: COLORS.SALAD_BAR_GRAY_500,
    600: COLORS.SALAD_BAR_GRAY_600,
    700: COLORS.SALAD_BAR_GRAY_700,
    800: COLORS.SALAD_BAR_GRAY_800,
    900: COLORS.SALAD_BAR_GRAY_900,
  },
  green: {
    600: COLORS.SALAD_BAR_GREEN_600,
    700: COLORS.SALAD_BAR_GREEN_700,
    800: COLORS.SALAD_BAR_GREEN_800,
    900: COLORS.SALAD_BAR_GREEN_900,
  },
  pink: {
    600: COLORS.SALAD_BAR_PINK_600,
    700: COLORS.SALAD_BAR_PINK_700,
    800: COLORS.SALAD_BAR_PINK_800,
    900: COLORS.SALAD_BAR_PINK_900,
  },
  orange: {
    700: COLORS.SALAD_BAR_ORANGE_700,
    800: COLORS.SALAD_BAR_ORANGE_800,
    900: COLORS.SALAD_BAR_ORANGE_900,
  },
  yellow: {
    700: COLORS.SALAD_BAR_YELLOW_700,
    800: COLORS.SALAD_BAR_YELLOW_800,
    900: COLORS.SALAD_BAR_YELLOW_900,
  },
  brown: {
    800: COLORS.SALAD_BAR_BROWN_800,
    700: COLORS.SALAD_BAR_BROWN_900,
  },
  blue: {
    900: COLORS.SALAD_BAR_BLUE_900,
  },
  red: {
    800: COLORS.SALAD_BAR_RED_800,
    900: COLORS.SALAD_BAR_RED_900,
  },
};

export default pallets;

import React, { useMemo, useState } from 'react';
import { Box, PrimaryButton, Typography } from 'components/atoms';
import { FileUploadDialog } from 'components/organisms';
import { ErrorIcon, NoImage } from 'assets/images';
import { fileToBase64 } from 'libs/file';
import { CompanyLogo, VariousSettings } from 'core/domain/variousSettings';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MandatoryTitleLabel } from 'components/molecules';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';

interface VariousSettingsCompanyLogoAreaProps {
  companyLogo: CompanyLogo | undefined;
  error: string | undefined;
  handleInputChange: (v: CompanyLogo, key: keyof VariousSettings) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: 240,
      maxHeight: 240,
      objectFit: 'contain',
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

const VariousSettingsCompanyLogoArea = (
  props: VariousSettingsCompanyLogoAreaProps,
) => {
  const { companyLogo, error, handleInputChange } = props;
  const classes = useStyles();
  const [fileDialogOpen, setFileDialogOpen] = useState<boolean>(false);

  const handleFileDialogClose = () => {
    setFileDialogOpen(false);
  };

  const handleLogoFileDialogOpen = () => {
    setFileDialogOpen(true);
  };

  const dispatchImage = (image: CompanyLogo | undefined, data: string) => {
    const imageData = {
      ...image,
      data,
    };

    handleInputChange(imageData, 'companyLogo');
  };

  const setFileData = (files: File[]) => {
    const image = companyLogo || undefined;
    files.map((file) => {
      fileToBase64(file)
        .then((res) => {
          dispatchImage(image, res as string);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    });
  };

  const handleFileSubmit = (files: File[]) => {
    if (!files.length || !files[0]) return;

    setFileData(files);
    setFileDialogOpen(false);
  };

  const logoImageUrl = useMemo(() => {
    if (companyLogo?.data) return companyLogo.data;
    if (companyLogo?.url) return companyLogo.url;

    return NoImage;
  }, [companyLogo?.data, companyLogo?.url]);

  return (
    <Box mt={5}>
      <FileUploadDialog
        open={fileDialogOpen}
        onClose={handleFileDialogClose}
        fileType="image"
        handleSubmit={handleFileSubmit}
      />
      <MandatoryTitleLabel mb title="企業ロゴ" />
      <Box display="flex" mt={3}>
        <img src={logoImageUrl} className={classes.img} />
        <Box width={160} height={35} ml={5}>
          <PrimaryButton
            text="画像をアップロード"
            click={handleLogoFileDialogOpen}
          />
        </Box>
      </Box>
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default VariousSettingsCompanyLogoArea;

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Modules } from 'core'

interface CustomerControl {
  show: boolean
  disabled: boolean
}

export const useCustomersPermission = () => {

  const userInfo = useSelector((state: Modules.AppState) => state.userInfo)

  const customerCreatePermission: CustomerControl = useMemo(() => {
    if (!userInfo) {
      return {
        show: true,
        disabled: true,
      }
    }
    if (userInfo.lpAuthor || userInfo.tenantAdmin) {
      return {
        show: true,
        disabled: false,
      }
    }
    if (userInfo.lpApprover && userInfo.lpAuthor) {
      return {
        show: true,
        disabled: false,
      }
    }
    if (userInfo.lpApprover) {
      return {
        show: false,
        disabled: true,
      }
    }

    return {
      show: false,
      disabled: true,
    }
  }, [userInfo])



  return {
    customerCreatePermission,
  }
}
import { MailHistory } from 'core/domain/lp';

const initialState = null;
export const createInitialState = (): MailHistory | null => initialState;

export const SET_MAIL_HISTORY = 'mail-history/set' as const;
export const CLEAR_MAIL_HISTORY = 'mail-history' as const;

export const setMailHistory = (data: MailHistory) => ({
  type: SET_MAIL_HISTORY,
  payload: {
    data,
  },
});

export const clearMailHistory = () => ({
  type: CLEAR_MAIL_HISTORY,
});

type SetMailHistory = ReturnType<typeof setMailHistory>;
type ClearMailHistory = ReturnType<typeof clearMailHistory>;
type Action = SetMailHistory | ClearMailHistory;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_MAIL_HISTORY:
      return action.payload.data;
    case CLEAR_MAIL_HISTORY:
      return initialState;
    default:
      return state;
  }
};

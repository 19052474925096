/** 素材ステータスコード */
const CODE_MATERIAL_STATUS_DRAFT = 1; // 下書き
const CODE_MATERIAL_STATUS_DECLINE = 2; // 下書き（差戻し）
const CODE_MATERIAL_STATUS_AWAITING_APPROVAL = 3; // 申請中
const CODE_MATERIAL_STATUS_APPROVAL = 4; // 承認済み
const CODE_MATERIAL_STATUS_RELEASED = 5; // 公開中
const CODE_MATERIAL_STATUS_SUSPENSION = 6; // 公開一時停止
const CODE_MATERIAL_STATUS_EXPIRE = 7; // 公開期限終了
const CODE_MATERIAL_STATUS_END = 8; // 公開終了
const CODE_MATERIAL_STATUS_IN_TRASH = 98; // ゴミ箱
const CODE_MATERIAL_STATUS_DELETED = 99; // 削除

/** 素材ワークフローステータスコード */
const CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE = 0; // 申し送り
const CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL = 1; // 申請中
const CODE_MATERIAL_WORKFLOW_STATUS_DECLINE = 2; // 差戻し
const CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL = 3; // 承認済

/** 素材イメージ種別 */
const CODE_MATERIAL_IMAGE_TYPE_IMAGE = 1; // 画像
const CODE_MATERIAL_IMAGE_TYPE_VIDEO = 2; // 動画リンク
const CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK = 3; // 動画URL

/** お問い合わせ種別 */
const CODE_INQUIRY_TYPE_TEL = 1; // 電話
const CODE_INQUIRY_TYPE_MAIL = 2; // メール
const CODE_INQUIRY_TYPE_OTHER = 3; // その他
const CODE_INQUIRY_TYPE_INQUIRY_FORM = 9; // お問い合わせフォーム

/** 対応状況ステータス */
const CODE_STATUS_REPLIED = 1; // 返信済
const CODE_STATUS_IN_TELEPHONE_READY = 2; // 電話対応済
const CODE_STATUS_SUPPORTING = 3; // 対応中
const CODE_STATUS_DOCUMENT_SENDED = 4; // 資料送付済み
const CODE_STATUS_APPOINTMENT_SET = 5; // アポイント設定済み
const CODE_STATUS_OTHERS = 6; // その他
/** LPステータスコード */
const CODE_LP_STATUS_DRAFT = 1;
const CODE_LP_STATUS_DECLINE = 2; // 下書き（差戻し）
const CODE_LP_STATUS_AWAITING_APPROVAL = 3; // 申請中
const CODE_LP_STATUS_APPROVAL = 4; // 承認済み
const CODE_LP_STATUS_RELEASED = 5; // 公開中
const CODE_LP_STATUS_SUSPENSION = 6; // 公開一時停止
const CODE_LP_STATUS_EXPIRE = 7; // 公開期限終了
const CODE_LP_STATUS_END = 8; // 公開終了
const CODE_LP_STATUS_IN_TRASH = 98; // ゴミ箱
const CODE_LP_STATUS_DELETED = 99; // 削除

/** LP公開ステータス */
const CODE_LP_OPEN_STATUS_DRAFT = 1;
const CODE_LP_OPEN_STATUS_RELEASED = 5;
const CODE_LP_OPEN_STATUS_SUSPENSION = 6;
const CODE_LP_OPEN_STATUS_END = 8;
const CODE_LP_OPEN_STATUS_TRASH = 98;
const CODE_LP_OPEN_STATUS_OTHER = 99;

/** LPワークフローステータス */
const CODE_LP_WORKFLOW_STATUS_AWAITING_APPROVAL = 1; // 申請中
const CODE_LP_WORKFLOW_STATUS_DECLINE = 2; // 差戻し
const CODE_LP_WORKFLOW_STATUS_APPROVAL = 3; // 承認済み

/** LP一覧ワークフローステータス */
const CODE_LP_LIST_WORKFLOW_STATUS_DECLINE = 2;
const CODE_LP_LIST_WORKFLOW_STATUS_AWAITING_APPROVAL = 3;
const CODE_LP_LIST_WORKFLOW_STATUS_APPROVAL = 4;
const CODE_LP_LIST_WORKFLOW_STATUS_OTHER = 99;

/** モード */
const CODE_MODE_REFERENCE = 1;
const CODE_MODE_EDIT = 2;

export {
  CODE_MATERIAL_STATUS_DRAFT,
  CODE_MATERIAL_STATUS_DECLINE,
  CODE_MATERIAL_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_STATUS_APPROVAL,
  CODE_MATERIAL_STATUS_RELEASED,
  CODE_MATERIAL_STATUS_SUSPENSION,
  CODE_MATERIAL_STATUS_EXPIRE,
  CODE_MATERIAL_STATUS_END,
  CODE_MATERIAL_STATUS_IN_TRASH,
  CODE_MATERIAL_STATUS_DELETED,
  CODE_MATERIAL_WORKFLOW_STATUS_MESSAGE,
  CODE_MATERIAL_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_MATERIAL_WORKFLOW_STATUS_DECLINE,
  CODE_MATERIAL_WORKFLOW_STATUS_APPROVAL,
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
  CODE_INQUIRY_TYPE_TEL,
  CODE_INQUIRY_TYPE_MAIL,
  CODE_INQUIRY_TYPE_OTHER,
  CODE_INQUIRY_TYPE_INQUIRY_FORM,
  CODE_STATUS_REPLIED,
  CODE_STATUS_IN_TELEPHONE_READY,
  CODE_STATUS_SUPPORTING,
  CODE_STATUS_DOCUMENT_SENDED,
  CODE_STATUS_APPOINTMENT_SET,
  CODE_STATUS_OTHERS,
  CODE_LP_STATUS_DRAFT,
  CODE_LP_STATUS_DECLINE,
  CODE_LP_STATUS_AWAITING_APPROVAL,
  CODE_LP_STATUS_APPROVAL,
  CODE_LP_STATUS_RELEASED,
  CODE_LP_STATUS_SUSPENSION,
  CODE_LP_STATUS_EXPIRE,
  CODE_LP_STATUS_END,
  CODE_LP_STATUS_IN_TRASH,
  CODE_LP_STATUS_DELETED,
  CODE_LP_OPEN_STATUS_DRAFT,
  CODE_LP_OPEN_STATUS_RELEASED,
  CODE_LP_OPEN_STATUS_SUSPENSION,
  CODE_LP_OPEN_STATUS_END,
  CODE_LP_OPEN_STATUS_TRASH,
  CODE_LP_OPEN_STATUS_OTHER,
  CODE_LP_LIST_WORKFLOW_STATUS_DECLINE,
  CODE_LP_LIST_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_LP_LIST_WORKFLOW_STATUS_APPROVAL,
  CODE_LP_LIST_WORKFLOW_STATUS_OTHER,
  CODE_LP_WORKFLOW_STATUS_AWAITING_APPROVAL,
  CODE_LP_WORKFLOW_STATUS_DECLINE,
  CODE_LP_WORKFLOW_STATUS_APPROVAL,
  CODE_MODE_REFERENCE,
  CODE_MODE_EDIT,
};

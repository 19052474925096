/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


const breakpoints =  {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 576, // Mobile
    sm: 768, // Mobile
    md: 992, // Desktop
    lg: 1200, // Desktop
    xl: 1201, // Desktop
  },
}

export default breakpoints
/* eslint-enable */


import { ImageCut } from 'core/domain/myLibrary'

const initialState = null

export const SET_IMAGE_CUT = 'image-cut/set' as const
export const CLEAR_IMAGE_CUT = 'image-cut/clear' as const

export const createInitialState = (): ImageCut | null => initialState

export const setImageCut = (data: ImageCut) => ({
  type: SET_IMAGE_CUT,
  payload: {
    data,
  },
})

export const clearImageCut = () => ({
  type: CLEAR_IMAGE_CUT,
})

type SetImageCutAction = ReturnType<typeof setImageCut>
type ClearImageCutAction = ReturnType<typeof clearImageCut>
type Action = SetImageCutAction | ClearImageCutAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_IMAGE_CUT:
      return action.payload.data
    case CLEAR_IMAGE_CUT:
      return initialState
    default:
      return state
  }
}
/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React from 'react';
import { Box, ButtonLink, Typography, Divider, Grid } from 'components/atoms';
import { Content, InquiryOverview, InquiryDetails } from 'components/organisms';
import {
  InquiryDetail,
  InquirySupports,
  InquirySupportsRegisterRequest,
} from 'core/domain/inquiries';
import { ArrowLeftIcon } from 'assets/images';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { hooks } from 'libs';

import { TYPOGRAPHY } from 'constants/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.green[800],
    },
    productSubtitle: {
      margin: '33px 0px 0px 0px',
    },
    productName: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: theme.palette.gray[900],
      marginBottom: theme.spacing(1),
    },
    preview: {
      float: 'right',
    },
    thickBorder: {
      marginTop: 18,
      height: 3,
      backgroundColor: theme.palette.gray[600],
      borderColor: theme.palette.gray[600],
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
    },
    caption: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
    },
    usePeriod: {
      margin: '0px 16px',
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    container: {
      margin: '16px 0px',
    },
    videoLink: {
      height: '100%',
      marginLeft: '16px',
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginRight: theme.spacing(1),
    },
    marginBottom: {
      marginBottom: 8,
    },
    multipleDescriptionLeft: {
      transform: `translateX(${theme.spacing(1) / 2}px)`,
    },
    multipleDescriptionRight: {
      transform: `translateX(${-theme.spacing(1) / 2}px)`,
    },
    secBtn: {
      marginRight: '8px',
    },
    priBtn: {
      marginLeft: '8px',
    },
    detail: {
      marginLeft: '0px',
    },
    togglerTxt1: {
      '& .MuiButton-label': {
        fontSize: '18px',
        color: theme.palette.gray[800],
      },
    },
  }),
);

/**
 * Interface
 */

export interface InquiryEditPageProps {
  inquiryDetail: InquiryDetail | null;
  inquirySupports: InquirySupports | null;
  handleRegisterSupport: (request: InquirySupportsRegisterRequest) => void;
}

// {/* 貴社名 *必須 */}
// {/* 部署名 *必須 */}
// {/* お名前 *必須 */}
// {/* 役職 */}
// {/* 業種 *必須 */}
// {/* メールアドレス *必須 */}
// {/* 確認用アドレス *必須 */}
// {/* 電話番号 *必須 */}
// {/* 都道府県 *必須 */}
// {/* 郵便番号 *必須 */}
// {/* 住所 *必須 */}
// {/* 性別 */}
// {/* 年齢 */}
// {/* 年代 */}
// {/* 生年月日 */}

/**
 * Presenter
 */

export default function InquiryEditPage(props: InquiryEditPageProps) {
  const { inquiryDetail, inquirySupports, handleRegisterSupport } = props;
  const classes = useStyles({});

  const navigate = hooks.useNavigate();

  return (
    <>
      {/* お問い合わせ登録・編集 */}
      <Box mt={5}>
        <Content title="ページ名；お問い合わせ登録・編集">
          {/* Grid1 */}

          <Grid>
            {/* 1-1段目 LP名表示 */}
            <Grid container spacing={0} className={classes.container}>
              {/* ランディングページ名称 */}
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  display="inline"
                >
                  ランディングページ名称
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body2"
                  className={classes.description}
                  display="inline"
                >
                  {inquiryDetail?.lpName}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            {/* 2段目以降 各項目と入力欄を表として表示 */}
            {/* 入力欄の初期値 存在するidなら問い合わせ情報の中身を入れる。ないならnull */}
            {/* Dividerで各行に区切り線 */}

            {/* {inquiryDetail?.inquiryFormAnswers.map((ele, index) => (
              <Grid key={index} item xs={12}>
                <Grid container spacing={0} className={classes.container}>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      className={classes.subtitle}
                      display="inline"
                    >
                      {ele.inquiryItemName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    className={classes.multipleDescriptionLeft}
                  >
                    <Typography
                      variant="body2"
                      className={classes.description}
                      display="inline"
                    >
                      {ele.value}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
            ))} */}
          </Grid>
        </Content>
      </Box>

      {/* 表2
    気になったテーマ(チェックボタン) * 必須, | 取り扱い商材
    チェックボタン | その他入力フォーム
    ご相談・ご要望入力フォーム
    資料送付を希望する(チェックボタン)
    自由項目1
    自由項目2
    自由項目3

    キャンセル/登録ボタン */}
    </>
  );
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { ProductRegisterRequest } from 'core/domain/product'

const initialState = null 

export function createInitialState(): ProductRegisterRequest | null {
  return initialState
}

export const SET_NEW_PRODUCT = 'new-product/set' as const
export const CLEAR_NEW_PRODUCT = 'new-product/clear' as const

export function setNewProduct(data:ProductRegisterRequest){
  return {
    type: SET_NEW_PRODUCT,
    payload: {
      data,
    },
  }
}

export function clearNewProduct(){
  return {
    type: CLEAR_NEW_PRODUCT,
  }
}

type SetNewProductAction = ReturnType<typeof setNewProduct>
type ClearNewProductAction = ReturnType<typeof clearNewProduct>
type Action = SetNewProductAction | ClearNewProductAction

export function reducer(state = createInitialState(), action: Action){
  switch (action.type) {
    case SET_NEW_PRODUCT:
      return action.payload.data
    case CLEAR_NEW_PRODUCT:
      return initialState
    default: 
      return state
  }
}/* eslint-enable */

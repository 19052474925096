import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import NormalEditPage from 'components/pages/NormalEdit';
import { clearSidebarShown, setSidebarShown } from 'core/modules/sidebarShown';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearHeaderShown, setHeaderShown } from 'core/modules/headerShown';
import { Modules, Usecases } from 'core';
import { clearLpDetail } from 'core/modules/lpDetail';
import { setNewLp, clearNewLp } from 'core/modules/newLp';
import { useNavigate } from 'libs/hooks';
import { clearLpProductDetails } from 'core/modules/lpProductDetails';
import { clearLpExampleDetails } from 'core/modules/lpExampleDetails';
import { setLpView } from 'core/modules/lpView';
import { convertNewLpToLpView } from 'libs/converter';
import { clearNormalEditErrors } from 'core/modules/normalEditErrors';
import { hooks } from 'libs';
import { LpTantoImageDataRequest, LpMainImageRequest } from 'core/domain/lp';
import { clearIsDuplicatedLp } from 'core/modules/isDuplicatedLP';
import { setError } from 'core/modules/error';
import { CODE_MODE_EDIT, CODE_MODE_REFERENCE } from 'constants/code';
import { clearExampleDetail } from 'core/modules/exampleDetail';
import { clearProductDetail } from 'core/modules/productDetail';

const NormalEditPageContainer = () => {
  const lpDetail = useSelector(
    (state: Modules.AppState) => state.lpDetail,
    shallowEqual,
  );
  const newLp = useSelector(
    (state: Modules.AppState) => state.newLp,
    shallowEqual,
  );
  const lpProductDetails = useSelector(
    (state: Modules.AppState) => state.lpProductDetails,
    shallowEqual,
  );
  const lpExampleDetails = useSelector(
    (state: Modules.AppState) => state.lpExampleDetails,
    shallowEqual,
  );
  const isDuplicatedLP = useSelector(
    (state: Modules.AppState) => state.isDuplicatedLP,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  const { lpId } = useParams<{ lpId: string }>();
  const navigate = useNavigate();
  const isApproved = useHistory().location.pathname.includes('approved');
  const isRegister = useHistory().location.pathname.includes('register');
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const designTemplates = useSelector(
    (state: Modules.AppState) => state.designTemplates,
    shallowEqual,
  );

  const inquiriesGreetText = useSelector(
    (state: Modules.AppState) => state.inquiriesGreetText,
    shallowEqual,
  );
  const inquiryForms = useSelector(
    (state: Modules.AppState) => state.inquiryForms,
    shallowEqual,
  );
  const lpSettings = useSelector(
    (state: Modules.AppState) => state.lpSettings,
    shallowEqual,
  );

  // 設定値取得LPプレビュー用
  useEffect(() => {
    if (!userInfo) return;
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
    dispatch(
      Usecases.settings.sendGetInquiriesGreetText(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetInquiryForms(token, userInfo.tenantId, 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 新規作成時権限無い場合ダイアログ表示、ボタン押下でダッシュボードへ遷移
  useEffect(() => {
    if (!userInfo?.lpAuthor && !userInfo?.tenantAdmin) {
      if (!lpId)
        dispatch(
          setError({
            title: '以下のエラーにより、処理を中止しました。',
            error: ['権限がありません。'],
          }),
        );
    }
  }, [userInfo, dispatch, lpId]);

  // サイドバー、ヘッダーを非表示にする/担当名の初期値設定
  useEffect(() => {
    dispatch(setSidebarShown(false));
    dispatch(setHeaderShown(false));
    dispatch(
      setNewLp({
        ...newLp,
        tantoName:
          newLp?.tantoName || newLp?.tantoName === ''
            ? newLp?.tantoName
            : userInfo?.displayName,
      }),
    );

    return () => {
      dispatch(clearSidebarShown());
      dispatch(clearNormalEditErrors());
      dispatch(clearNewLp());
      dispatch(clearLpProductDetails());
      dispatch(clearLpExampleDetails());
      dispatch(clearIsDuplicatedLp());
      dispatch(clearHeaderShown());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LP得意先取得、デザインテンプレートデータ取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.settings.sendGetDesignTemplates(token, userInfo.tenantId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    // 流用、編集、設定されているデザインテンプレートIdをセット
    if (lpDetail?.designTemplateId && lpId) {
      dispatch(
        setNewLp({
          ...newLp,
          designTemplateId: lpDetail.designTemplateId || undefined,
        }),
      );
    } else {
      dispatch(
        // 選択されているデザインテンプレートない場合
        setNewLp({
          ...newLp,
          designTemplateId:
            (designTemplates && designTemplates[0].designTemplateId) ||
            undefined,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail?.designTemplateId]);

  // 編集の場合、LP詳細情報を取得
  useEffect(() => {
    if (!userInfo) return;
    if (lpId) {
      dispatch(
        Usecases.lps.sendGetLpDetail(
          token,
          userInfo.tenantId,
          Number.parseInt(lpId, 10),
          isApproved,
          isDuplicatedLP ? CODE_MODE_REFERENCE : CODE_MODE_EDIT,
        ),
      );
    }

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearLpDetail());
    };
  }, [userInfo, token, lpId, isApproved, dispatch, isDuplicatedLP]);

  // 編集の場合、商材編集画面に表示する商材と導入事例情報の詳細取得
  useEffect(() => {
    if (!userInfo || !lpDetail || !lpDetail.products) return;

    lpDetail.products.forEach((product) => {
      dispatch(
        Usecases.lps.sendGetProductDetail(
          token,
          userInfo.tenantId,
          product.productId,
          CODE_MODE_REFERENCE,
        ),
      );
      if (product.exampleIds)
        product.exampleIds.forEach((id) => {
          dispatch(
            Usecases.lps.sendGetExampleDetail(
              token,
              userInfo.tenantId,
              id,
              CODE_MODE_REFERENCE,
            ),
          );
        });
    });
  }, [userInfo, lpDetail, dispatch, token]);

  useEffect(
    () =>
      // eslint-disable-next-line consistent-return
      () => {
        dispatch(clearProductDetail());
        dispatch(clearExampleDetail());
      },
    [dispatch],
  );

  // 編集の場合、取得したLP詳細情報をLP登録用データに変換
  useEffect(() => {
    if (lpDetail && lpId) {
      dispatch(
        setNewLp({
          // 全流用する場合の記述
          // title:lpDetail.title,
          // pageTitle:lpDetail.pageTitle,
          // header:lpDetail.header,
          // tokuisakiName:lpDetail.tokuisakiName,
          // tokuisakiDisplayed:lpDetail.tokuisakiDisplayed ? lpDetail.tokuisakiDisplayed : false,
          // tokuisakiTitle:lpDetail.tokuisakiTitle,
          // lpMainImageData:lpDetail.lpMainImage ? {lpMainImageId: lpDetail.lpMainImage.lpMainImageId, url: lpDetail.lpMainImage.url} as LpMainImageRequest : undefined,
          // tantoName: isDuplicatedLP ? userInfo?.displayName : lpDetail.tantoName,
          // tantoImageData:lpDetail.tantoImageImage ? {lpTantoImageId: lpDetail.tantoImageImage.lpTantoImageId, url: lpDetail.tantoImageImage.url} as LpTantoImageDataRequest : undefined,
          // profileFixedPhraseText:lpDetail.profileFixedPhraseText,
          // profileDisplayed:lpDetail.profileDisplayed ? lpDetail.profileDisplayed : false,
          // tantoCompany: lpDetail.tantoCompany,
          // greetText:lpDetail.greetText,
          // beginningText:lpDetail.beginningText,
          // products:lpDetail.products,
          // designTemplateId:lpDetail.designTemplateId,
          // lpTag:lpDetail.lpTag,
          // openPeriod:lpDetail.openPeriod,
          // mailSubject:lpDetail.mailSubject,
          // mailText:lpDetail.mailText,
          // mailSignature:lpDetail.mailSignature,
          // passwordSetting:lpDetail.passwordSetting,
          // password:lpDetail.password,

          pageTitle: lpDetail.pageTitle,
          header: lpDetail.header,
          tokuisakiDisplayed: lpDetail.tokuisakiDisplayed
            ? lpDetail.tokuisakiDisplayed
            : false,
          tokuisakiTitle: lpDetail.tokuisakiTitle,
          lpMainImageData: lpDetail.lpMainImage
            ? ({
                lpMainImageId: lpDetail.lpMainImage.lpMainImageId,
                url: lpDetail.lpMainImage.url,
              } as LpMainImageRequest)
            : undefined,
          profileFixedPhraseText: lpDetail.profileFixedPhraseText,
          profileDisplayed: lpDetail.profileDisplayed
            ? lpDetail.profileDisplayed
            : false,
          tantoCompany: lpDetail.tantoCompany,
          greetText: lpDetail.greetText,
          beginningText: lpDetail.beginningText,
          designTemplateId: lpDetail.designTemplateId,
          lpTag: lpDetail.lpTag,

          // 流用時に空白初期化する項目

          tantoImageData:
            lpDetail.tantoImageImage && !isDuplicatedLP && !isRegister
              ? ({
                  lpTantoImageId: lpDetail.tantoImageImage.lpTantoImageId,
                  url: lpDetail.tantoImageImage.url,
                } as LpTantoImageDataRequest)
              : undefined,

          products: lpDetail.products
            ? lpDetail.products.map((product) => ({
                ...product,
                productSalesImage:
                  isDuplicatedLP || isRegister
                    ? undefined
                    : product.productSalesImage,
              }))
            : [],

          // サイト表示内容タブ
          tokuisakiName:
            isDuplicatedLP || isRegister ? undefined : lpDetail.tokuisakiName,
          tantoName: isDuplicatedLP
            ? userInfo?.displayName
            : lpDetail.tantoName,

          // 管理情報タブ
          title: isDuplicatedLP || isRegister ? undefined : lpDetail.title,

          // 公開設定タブ
          mailSubject:
            isDuplicatedLP || isRegister ? undefined : lpDetail.mailSubject,
          mailText:
            isDuplicatedLP || isRegister ? undefined : lpDetail.mailText,
          mailSignature:
            isDuplicatedLP || isRegister ? undefined : lpDetail.mailSignature,

          passwordSetting:
            isDuplicatedLP || isRegister ? undefined : lpDetail.passwordSetting,
          password:
            isDuplicatedLP || isRegister ? undefined : lpDetail.password,
          openPeriod: {
            openPeriodDate:
              isDuplicatedLP || isRegister
                ? undefined
                : lpDetail?.openPeriod?.openPeriodDate,
            openPeriodTime:
              isDuplicatedLP || isRegister
                ? undefined
                : lpDetail?.openPeriod?.openPeriodTime,
          },
        }),
      );
    }

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearNewLp());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDetail, dispatch, isRegister]);

  /**
   * 「下書き保存」押下時、LP登録処理
   * @param tag 重複チェック・空白除去したタグ
   * @returns
   */
  const handleSaveDraft = async (tag: string | undefined) => {
    if (!newLp || !userInfo) return;
    const lp = { ...newLp };
    lp.draft = true;
    lp.lpTag = tag;
    if (lpId && !isRegister) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        Usecases.lps.sendPutLp(token, userInfo.tenantId, lpId, lp),
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(Usecases.lps.sendPostLp(token, userInfo.tenantId, lp));
    }
    navigate.navigate(`/adm/lps/own`);
  };

  // LPプレビュー用にデータ変換
  useEffect(() => {
    if (!newLp) return;

    dispatch(
      setLpView(
        convertNewLpToLpView(
          newLp,
          lpProductDetails,
          lpExampleDetails,
          lpSettings,
          inquiriesGreetText,
          inquiryForms,
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLp, lpProductDetails, lpExampleDetails]);

  // 新規作成時の初期テンプレートIDを取得
  // useEffect(() => {
  //   dispatch(
  //     setNewLp({
  //       ...newLp,
  //       designTemplateId: newLp?.designTemplateId ? newLp?.designTemplateId : 1,
  //     }),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  /**
   * 「承認を申請する」押下時、LP登録処理
   */
  const handleSubmitApply = useCallback(
    async (workflow: string) => {
      if (!newLp || !userInfo) return;
      const lp = { ...newLp };
      lp.draft = false;
      lp.workflowMessage = workflow;
      if (lpId && !isRegister) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await dispatch(
          Usecases.lps.sendPutLp(token, userInfo.tenantId, lpId, lp),
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await dispatch(Usecases.lps.sendPostLp(token, userInfo.tenantId, lp));
      }

      navigate.navigate(`${url}/apply-completed`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newLp, userInfo],
  );

  return (
    <NormalEditPage
      handleSaveDraft={handleSaveDraft}
      handleSubmitApply={handleSubmitApply}
    />
  );
};

export default NormalEditPageContainer;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import {
  SALAD_BAR_GREEN_900,
  SALAD_BAR_PINK_800,
  SALAD_BAR_GREEN_700,
} from 'constants/color';
import { Notice } from 'core/domain/notices';
import {
  formatDateYYYYMMDDSeparateSlash,
  formateDateToJpString,
  formateTimeHHMMColon,
} from 'libs/date';
import { hooks } from 'libs';

/**
 * Interface
 */

export interface NotificationBoxProps {
  notice: Notice;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '375px',
      position: 'relative',
      borderColor: 'rgba(37, 36, 30, 0.08)',
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: 'solid',
      padding: `${theme.spacing(2)}px 0`,
    },
    content: {
      width: '343px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      left: '4%',
      top: '13%',
    },
    head: {
      width: '343px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headText: {
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#262622',
    },
    date: {
      fontSize: '12px',
      color: '#262622',
      marginRight: '12px',
    },
    text: {
      fontSize: '13px',
      color: '#82827E',
      width: '343px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    dateDiv: {
      width: 'auto',
      maxHeight: '24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      margin: `${theme.spacing(1)}px 0`,
    },
    buttonDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    btnRed: {
      color: 'white',
      backgroundColor: SALAD_BAR_PINK_800,
      maxHeight: '24px',
      width: '56px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnGreen: {
      backgroundColor: SALAD_BAR_GREEN_700,
      maxHeight: '24px',
      width: '164px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    readText: {
      color: '#82827E',
    },
  }),
);

/**
 * Presenter
 */

const NotificationBox = (props: NotificationBoxProps) => {
  const classes = useStyles({});
  const { notice } = props;
  const { navigate } = hooks.useNavigate();

  return (
    <Box
      className={classes.root}
      onClick={() => navigate(`/adm/notices/${notice.noticeId}`)}
    >
      <Box className={classes.content}>
        <Box className={classes.head}>
          <Typography className={classes.headText}>{notice.subject}</Typography>
        </Box>
        <Box className={classes.dateDiv}>
          {notice.publishStartDate && (
            <Typography className={classes.date}>
              {formateDateToJpString(
                formatDateYYYYMMDDSeparateSlash(notice.publishStartDate),
              )}
              {` ${formateTimeHHMMColon(notice.publishStartTime)}`}
            </Typography>
          )}
          <Box className={classes.buttonDiv}>
            {notice.noticeType === 1 && (
              <Box className={classes.btnRed}>重要</Box>
            )}
            {notice.noticeType === 3 && (
              <Box
                className={classes.btnGreen}
                style={{ color: SALAD_BAR_GREEN_900 }}
              >
                サービスからのお知らせ
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default NotificationBox;

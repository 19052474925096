/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from 'components/atoms'

/**
 * Interface
 */

export interface ReactionCountLabelProps {
  title: string,
  count: number,
}

/**
 * Style
 */

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    root: {
      fontSize: 13,
      color: theme.palette.gray[900],
      textAlign: "center",
      verticalAlign: "center",
    },
    count: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
    },
  }),
)

/**
 * Presenter
 */

export default function ReactionCountLabel(props: ReactionCountLabelProps) {

  const { title, count } = props
  const classes = useStyles({})

  return (
    <Typography className={classes.root} variant="caption" display="block">
      {title}<span className={classes.count}>{count.toLocaleString()}件</span>
    </Typography>
  )

}/* eslint-enable */

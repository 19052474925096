import { UserInfoDetail } from 'core/domain/user'

const initialState = null

export const createInitialState = (): UserInfoDetail | null => initialState

export const SET_USER_INFO_DETAIL = 'user-info-detail/set' as const
export const CLEAR_USER_INFO_DETAIL = 'user-info-detail/clear' as const

export const setUserInfoDetail = (data: UserInfoDetail) => ({
  type: SET_USER_INFO_DETAIL,
  payload: {
    data,
  }
})

export const clearUserInfoDetail = () => ({
  type: CLEAR_USER_INFO_DETAIL,
})

type SetUserInfoDetail = ReturnType<typeof setUserInfoDetail>
type ClearUserInfo = ReturnType<typeof clearUserInfoDetail>
type Action = SetUserInfoDetail | ClearUserInfo

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USER_INFO_DETAIL:
      return action.payload.data
    case CLEAR_USER_INFO_DETAIL:
      return initialState
    default:
      return state
  }
}

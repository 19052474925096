import { TotalReaction } from 'core/domain/dashboard';

const initialState = null;

export const createInitialState = (): TotalReaction | null => initialState;

export const SET_TOTAL_REACTION = 'total-reaction/set' as const;
export const CLEAR_TOTAL_REACTION = 'total-reaction/clear' as const;

export const setTotalReaction = (data: TotalReaction) => ({
  type: SET_TOTAL_REACTION,
  payload: {
    data,
  },
});

export const clearTotalReaction = () => ({
  type: CLEAR_TOTAL_REACTION,
});

type SetTotalReactionAction = ReturnType<typeof setTotalReaction>;
type ClearTotalReactionAction = ReturnType<typeof clearTotalReaction>;
type Action = SetTotalReactionAction | ClearTotalReactionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TOTAL_REACTION:
      return action.payload.data;
    case CLEAR_TOTAL_REACTION:
      return initialState;
    default:
      return state;
  }
};

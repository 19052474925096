import React from 'react';
import { Box, ListItem, ButtonLink, CloseIconButton } from 'components/atoms';

/**
 * Interface
 */
export interface DownloadDocumentRowProps {
  name: string;
  clickFile: (a: number) => void;
  clickClose: (a: number) => void;
  index: number;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const DownloadDocumentRow = (props: DownloadDocumentRowProps) => {
  const { name, clickFile, clickClose, index, icon, disabled } = props;

  const onClickFile = () => {
    clickFile(index);
  };

  const onClick = () => {
    clickClose(index);
  };

  return (
    <ListItem style={{ padding: 0 }}>
      <Box display="flex" alignItems="center" overflow="hidden">
        <Box flexGrow={1} overflow="hidden">
          <ButtonLink
            text={name}
            tooltipText={name}
            click={onClickFile}
            iconPosition="end"
            icon={icon || undefined}
            disabled={disabled}
          />
        </Box>
        <Box>
          <CloseIconButton click={onClick} />
        </Box>
      </Box>
    </ListItem>
  );
};
export default DownloadDocumentRow;

import React, { useEffect } from 'react';
import Examples from 'components/pages/Examples';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { setExampleQueryParameter } from 'core/modules/examplesQueryParameter';
import { ExampleSortKey } from 'core/domain/example';
import { hooks } from 'libs';

const pageRows = 10;
/**
 * Presenter
 */

const ExamplesPageContainer = () => {
  const dispatch = useDispatch();
  const examples = useSelector(
    (state: Modules.AppState) => state.examples,
    shallowEqual,
  );
  const exampleQueryParameter = useSelector(
    (state: Modules.AppState) => state.exampleQueryParameter,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  // 導入事例一覧取得リクエストパラメーター設定
  useEffect(() => {
    if (
      examples &&
      examples.examples &&
      examples.examples.length === 0 &&
      examples.totalCount > 0
    ) {
      if (!exampleQueryParameter) {
        dispatch(
          setExampleQueryParameter({
            page: 1,
            row: pageRows,
          }),
        );
      } else {
        const lastPage = Math.ceil(examples?.totalCount / pageRows);
        dispatch(
          setExampleQueryParameter({
            ...exampleQueryParameter,
            page: lastPage,
            row: pageRows,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examples]);

  // 導入事例一覧取得
  useEffect(() => {
    if (!exampleQueryParameter) {
      dispatch(
        setExampleQueryParameter({
          page: 1,
          row: pageRows,
        }),
      );

      return;
    }
    if (!userInfo) return;
    dispatch(
      Usecases.examples.sendGetExamples(
        token,
        userInfo.tenantId,
        exampleQueryParameter,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleQueryParameter, userInfo]);

  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (exampleQueryParameter?.sort_key === (sortKey as ExampleSortKey)) {
      order = exampleQueryParameter.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setExampleQueryParameter({
        ...exampleQueryParameter,
        sort_key: sortKey as ExampleSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setExampleQueryParameter({
        ...exampleQueryParameter,
        page,
        row: pageRows,
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラーメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setExampleQueryParameter({
        ...exampleQueryParameter,
        free_word: freeWord,
      }),
    );
  };

  /**
   * ステータスチェックボックスチェック変更時処理
   * クエリパラメーターにセット
   */
  const handleCheckStatus = (status: number) => {
    if (!exampleQueryParameter || !exampleQueryParameter.example_status) {
      dispatch(
        setExampleQueryParameter({
          ...exampleQueryParameter,
          example_status: `${status}`,
          // 検索タイミングで最新のフリーワード検索を行う
          free_word: freeWord?.freeWord,
        }),
      );

      return;
    }
    const statusArray = exampleQueryParameter.example_status.split(',');
    const index = statusArray.indexOf(`${status}`);
    if (index < 0) {
      statusArray.push(`${status}`);
    } else {
      statusArray.splice(index, 1);
    }

    dispatch(
      setExampleQueryParameter({
        ...exampleQueryParameter,
        example_status: statusArray.join(','),
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  return (
    <Examples
      examples={examples}
      rows={pageRows}
      handleChangeSortKey={handleChangeSortKey}
      exampleQueryParameter={exampleQueryParameter}
      handleChangePage={handleChangePage}
      handleCheckStatus={handleCheckStatus}
      handleChangeFreeWord={handleChangeFreeWord}
    />
  );
};
export default ExamplesPageContainer;

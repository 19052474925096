/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */



export enum UserFlgs {
  None = 0,
  /** テナント管理者 */
  TenantAdmin = 1 << 0,
  /** LP作成者 */
  LpAuthor = 1 << 1,
  /** LP承認者 */
  LpApprover = 1 << 2,
  /** 素材作成者 */
  MaterialAuthor = 1 << 3,
  /** 素材承認者 */
  MaterialApprover = 1 << 4,
}

export type UserFlg = {
  flags: UserFlgs
}/* eslint-enable */

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar, PrimaryButton } from 'components/atoms';
import {
  Content,
  ExamplesTable,
  ExampleSearchField,
} from 'components/organisms';
import { PlusIcon } from 'assets/images';
import { hooks } from 'libs';
import { Examples, ExamplesQueryParameter } from 'core/domain/example';

/**
 * Interface
 */
export interface ExamplesProps {
  rows: number;
  examples: Examples | null;
  exampleQueryParameter: ExamplesQueryParameter | null;
  handleChangeSortKey: (sortKey: string) => void;
  handleChangePage: (page: number) => void;
  handleChangeFreeWord?: (freeWord: string) => void;
  handleCheckStatus?: (status: number) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */
const Example = (props: ExamplesProps) => {
  const classes = useStyles({});
  const { exampleNewCreatePermission } = hooks.useExamplesPermission();
  const {
    examples,
    rows,
    handleChangeSortKey,
    exampleQueryParameter,
    handleChangePage,
    handleChangeFreeWord,
    handleCheckStatus,
  } = props;
  const { navigate } = hooks.useNavigate();

  return (
    <Box mt={5}>
      <Content
        title="導入事例一覧"
        sideItem={
          exampleNewCreatePermission.show && (
            <Box className={classes.sideItems}>
              <PrimaryButton
                disabled={exampleNewCreatePermission?.disabled}
                text="新規登録"
                icon={<PlusIcon />}
                click={() => navigate('/adm/examples/register')}
              />
              {/* <PrimaryButton text="一括登録" icon={<BulkIcon />} disabled /> */}
            </Box>
          )
        }
      >
        <div>
          <ExampleSearchField
            handleCheckStatus={handleCheckStatus}
            exampleQueryParameter={exampleQueryParameter}
            handleChangeFreeWord={handleChangeFreeWord}
          />
          <Box className={classes.table}>
            <ExamplesTable
              examples={examples}
              clickExample={(id) => navigate(`/adm/examples/${id}`)}
              handleChangeSortKey={handleChangeSortKey}
              exampleQueryParameter={exampleQueryParameter}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  exampleQueryParameter ? exampleQueryParameter.page : 1
                }
                maxPage={examples ? Math.ceil(examples?.totalCount / rows) : 1}
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={examples?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
    </Box>
  );
};

export default Example;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Container, Drawer } from 'components/atoms'
import { useSelector } from 'react-redux'
import { Modules } from 'core'

/**
 * Interface
 */

export interface NormalEditPageTemplateProps {
  headerBar: React.ReactNode
  sidebar: React.ReactNode
  children: React.ReactElement
}

/**
 * Style
 */

// FIXME: constants で管理する
const pageMinWidth = 1366
const sidebarWidth = 290
const sidebarShiftWidth = 72
const headerHeight = 64

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      fallbacks: {
        display: '-ms-grid',
      },
      '-ms-grid-rows': `${headerHeight}px 1fr`,
      '-ms-grid-columns': '1fr',
      gridTemplate: `
      'pageHeaderBar' ${headerHeight}px
      'pageContent' auto
      `,
      minWidth: pageMinWidth,
      minHeight: `100vh`,
      backgroundColor: theme.palette.gray[400],
    },
    headerBar: {
      gridArea: 'pageHeaderBar',
      '-ms-grid-row': 1,
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      position: 'fixed',
    },
    content: {
      gridArea: 'pageContent',
      '-ms-grid-row': 2,
      width: '100%',
      paddingBottom: theme.spacing(4),
    },
    container: {
      height: `calc(100% - ${headerHeight}px)`,
      // padding: `0 ${theme.spacing(4)}px`,
    },
    sidebar: {
      width: sidebarWidth,
      flexShrink: 0,
      willChange: 'width'
    },
    sidebarPaper: {
      width: sidebarWidth,
      paddingTop: 54,
      borderWidth: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      backgroundColor: theme.palette.gray[400],
    },
    sidebarWidth: {
      width: sidebarWidth,
    },
    sidebarShiftWidth: {
      width: sidebarShiftWidth,
    },
    main: {
      minWidth: pageMinWidth - sidebarWidth,
      willChange: 'width',
      marginLeft: 'auto',
      flexGrow: 1,
      transition: theme.transitions.create(['width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      width: `calc(100vw - ${sidebarWidth}px)`,
    },
    mainShift: {
      minWidth: pageMinWidth - sidebarShiftWidth,
      width: `calc(100vw - ${sidebarShiftWidth}px)`,
    },
    mainFull: {
      minWidth: pageMinWidth,
      width: '100vw',
    },
  }),
)

/**
 * Presenter
 */

export default function NormalEditPageTemplate(props: NormalEditPageTemplateProps) {
  const { children, headerBar, sidebar } = props
  const normalEditSidebarShown = useSelector((state:Modules.AppState) => state.normalEditSidebarShown)
  const classes = useStyles()

  return (
    <div className={`${classes.root} ieHeightAuto`}>
      <div className={classes.headerBar}>{headerBar && headerBar}</div>
      <div className={classes.content}>
        <Drawer
          open={normalEditSidebarShown}
          className={clsx(classes.sidebar)}
          variant="persistent"
          anchor="left"
          classes={{
            paper: clsx(classes.sidebarPaper),
          }}
        >
          {sidebar && sidebar}
        </Drawer>
        <main className={clsx(classes.main,{[classes.mainFull]: !normalEditSidebarShown})}>
          <Container maxWidth={false} className={classes.container}>{children}</Container>
        </main>
      </div>
    </div>
  )
}
/* eslint-enable */

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


const initialState = false

export function createInitialState(): boolean {
  return initialState
}

export const SET_IS_DUPLICATED_LP
 = 'is-duplicated-lp/set' as const
export const CLEAR_IS_DUPLICATED_LP
 = 'is-duplicated-lp/clear' as const

export function setIsDuplicatedLp(isDuplicatedLp: boolean) {
  return {
    type: SET_IS_DUPLICATED_LP
    ,
    payload: {
      isDuplicatedLp,
    },
  }
}

export function clearIsDuplicatedLp() {
  return {
    type: CLEAR_IS_DUPLICATED_LP
    ,
  }
}

type SetIsDuplicatedLp = ReturnType<typeof setIsDuplicatedLp>
type ClearIsDuplicatedLp = ReturnType<typeof clearIsDuplicatedLp>
type Action = SetIsDuplicatedLp | ClearIsDuplicatedLp

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_IS_DUPLICATED_LP:
      return action.payload.isDuplicatedLp
    case CLEAR_IS_DUPLICATED_LP:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

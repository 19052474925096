import React from 'react';
import { useSelector } from 'react-redux';
import { Modules } from 'core';
import { Box } from 'components/atoms';
import { DashboardApprovalStatusItem } from 'components/organisms';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface ApprovalStatusAccessor {
  accessor:
    | 'lpApprovedCount'
    | 'lpReturnCount'
    | 'lpApprovalTaskCount'
    | 'lpDraftCount'
    | 'lpApprovalCount'
    | 'lpOpenCount'
    | 'lpPauseCount'
    | 'lpCloseCount';
  approveStatus: string;
  color: 'red' | 'green';
  title: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#EDEDE6',
      padding: '12px 0',
    },
    span: {
      width: 2,
      height: 40,
      display: 'inline-block',
      background: '#DCDCDA',
      marginLeft: 8,
    },
  }),
);

const APPROVAL_STATUS_ACCESSORS: ApprovalStatusAccessor[] = [
  {
    accessor: 'lpApprovedCount',
    approveStatus: '4',
    color: 'red',
    title: '承認済',
  },
  {
    accessor: 'lpReturnCount',
    approveStatus: '2',
    color: 'red',
    title: '差し戻し',
  },
  {
    accessor: 'lpApprovalCount',
    approveStatus: '3',
    color: 'green',
    title: '申請中',
  },
  {
    accessor: 'lpDraftCount',
    approveStatus: '1',
    color: 'green',
    title: '下書き',
  },
  {
    accessor: 'lpApprovalTaskCount',
    approveStatus: '3',
    color: 'red',
    title: '承認待ち',
  },
  {
    accessor: 'lpOpenCount',
    approveStatus: '5',
    color: 'green',
    title: '公開中',
  },
  {
    accessor: 'lpPauseCount',
    approveStatus: '6',
    color: 'green',
    title: '公開一時停止',
  },
  {
    accessor: 'lpCloseCount',
    approveStatus: '8',
    color: 'green',
    title: '公開終了',
  },
];

const DashboardApprovalStatusArea = () => {
  const classes = useStyles();
  const approvalStatus = useSelector(
    (state: Modules.AppState) => state.approvalStatus,
  );

  return (
    <Box className={classes.root} display="flex">
      {approvalStatus &&
        APPROVAL_STATUS_ACCESSORS.map((accessor) => {
          if (
            accessor.color === 'red' &&
            approvalStatus[accessor.accessor] !== null
          )
            return (
              <DashboardApprovalStatusItem
                key={accessor.accessor}
                approveStatus={accessor.approveStatus}
                approveStatusCount={approvalStatus[accessor.accessor]}
                color={accessor.color}
                title={accessor.title}
              />
            );

          return null;
        })}
      <span className={classes.span} />
      {approvalStatus &&
        APPROVAL_STATUS_ACCESSORS.map((accessor) => {
          if (
            accessor.color === 'green' &&
            approvalStatus[accessor.accessor] !== null
          )
            return (
              <DashboardApprovalStatusItem
                key={accessor.accessor}
                approveStatus={accessor.approveStatus}
                approveStatusCount={approvalStatus[accessor.accessor]}
                color={accessor.color}
                title={accessor.title}
              />
            );

          return null;
        })}
    </Box>
  );
};

export default DashboardApprovalStatusArea;

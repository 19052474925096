/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { IconButton } from 'components/atoms'
import { InformationIcon } from 'assets/images'

/**
 * Interface
 */
export interface InformationIconButtonProps{
  handleClick:()=>void
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    information: {
      position: 'absolute',
      right: 4,
      top: 4,
      padding: 0,
      '& #Icon_material-error-outline': {
        fill: theme.palette.green[900],
      },
      '& #path_864': {
        fill: "#D9EFDB",
      },
      '& #circle_16-2': {
        fill: theme.palette.green[700],
      }
    },
  }),
)

/**
 * Presenter
 */

export default function InformationIconButton(props:InformationIconButtonProps) {

  const classes = useStyles({})
  const { handleClick } = props

  return (
    <IconButton 
      className={classes.information}
      onClick={handleClick}>
      <InformationIcon />
    </IconButton>
  )
}/* eslint-enable */

import { CustomerRegisterRequest } from 'core/domain/customer'

const initialState = null

export const createInitialState = (): CustomerRegisterRequest | null => initialState

export const SET_NEW_CUSTOMER = 'new-customer/set' as const
export const CLEAR_NEW_CUSTOMER = 'new-customer/clear' as const

export const setNewCustomer = (data:CustomerRegisterRequest) => ({
  type: SET_NEW_CUSTOMER,
  payload: {
    data,
  },
})

export const clearNewCustomer = () => ({
  type: CLEAR_NEW_CUSTOMER,
})

type SetNewProductAction = ReturnType<typeof setNewCustomer>
type ClearNewProductAction = ReturnType<typeof clearNewCustomer>
type Action = SetNewProductAction | ClearNewProductAction

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_NEW_CUSTOMER:
      return action.payload.data
    case CLEAR_NEW_CUSTOMER:
      return initialState
    default:
      return state
  }
}

import React, { ReactNode } from 'react';
import { Box } from 'components/atoms';
import { BoxProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import RadiusBox from '../../atoms/RadiusBox';

/**
 * Interface
 */

interface ListItem {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  placeholder?: string | ReactNode;
  key?: number | string;
  label?: ReactNode;
}

export interface SimpleListProps extends BoxProps {
  list: Array<ListItem>;
}

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: `1px solid ${theme.palette.gray[600]}`,
        padding: '10px 0',
      },
      '& >:last-child': {
        border: 'none',
      },
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.gray[800],
    },
    title: {
      fontSize: 15,
      fontWeight: 'bold',
      color: theme.palette.gray[900],
    },
  }),
);

const SimpleItem = (props: ListItem) => {
  const { placeholder, title, subTitle, label } = props;
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.subTitle}>
        {subTitle}
        <Box component="span" ml={subTitle ? 1 : 0}>
          {label}
        </Box>
      </Box>
      <Box className={classes.title}>{title}</Box>
      <Box>{placeholder}</Box>
    </Box>
  );
};
const SimpleListBox = (props: SimpleListProps) => {
  const { list } = props;
  const classes = useStyles();

  return (
    <RadiusBox className={classes.root}>
      {list.map((data, index) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SimpleItem key={index} {...data} />
      ))}
    </RadiusBox>
  );
};
export default SimpleListBox;

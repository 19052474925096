import { AddTerm } from 'core/domain/dashboard';

const initialState = null;

export const createInitialState = (): AddTerm | null => initialState;

export const SET_DASHBOARD_ADD_TERM = 'dashboard-add-term/set' as const;
export const CLEAR_DASHBOARD_ADD_TERM = 'dashboard-add-term/clear' as const;

export const setDashboardAddTerm = (data: AddTerm) => ({
  type: SET_DASHBOARD_ADD_TERM,
  payload: {
    data,
  },
});

export const clearDashboardAddTerm = () => ({
  type: CLEAR_DASHBOARD_ADD_TERM,
});

type SetDashboardAddTermAction = ReturnType<typeof setDashboardAddTerm>;
type ClearDashboardAddTermAction = ReturnType<typeof clearDashboardAddTerm>;
type Action = SetDashboardAddTermAction | ClearDashboardAddTermAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_DASHBOARD_ADD_TERM:
      return action.payload.data;
    case CLEAR_DASHBOARD_ADD_TERM:
      return initialState;
    default:
      return state;
  }
};

import { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, PaginationBar, PrimaryButton } from 'components/atoms';
import {
  Content,
  ProductsTable,
  ProductSearchField,
  TagSelectDialog,
} from 'components/organisms';
import { PlusIcon } from 'assets/images';
import { hooks } from 'libs';
import {
  Products,
  ProductsQueryParameter,
  ProductTags,
} from 'core/domain/product';
import { ProductSearchValidationError } from 'containers/ProductsPageContainer';

/**
 * Interface
 */
export interface ProductsPageProps {
  products: Products | null;
  rows: number;
  productSearchCondition: ProductsQueryParameter | null;
  handleChangePage: (page: number) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleCheckFavorite: (status: string) => void;
  handleCheckStatus: (status: number) => void;
  handleChangeFreeWord: (freeWord: string) => void;
  handleChangeTag: (tags: string) => void;
  tags: ProductTags | null;
  handleClickFavorite: (productId: number, checked: boolean) => void;
  searchValidateError?: ProductSearchValidationError;
  handleChangeAddTerm: (date: string, isFrom: boolean) => void;
  deleteTag?: (val: string) => void;
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    },
    sideItems: {
      display: 'flex',
      '&>button': {
        width: 120,
        height: 36,
      },
      '&>button:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

/**
 * Presenter
 */
const ProductsPage = (props: ProductsPageProps) => {
  const classes = useStyles({});
  const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const { navigate } = hooks.useNavigate();
  const { productNewCreatePermission } = hooks.useProductsPermission();
  const {
    products,
    rows,
    productSearchCondition,
    handleChangePage,
    handleChangeSortKey,
    handleCheckFavorite,
    handleChangeFreeWord,
    handleChangeTag,
    tags,
    handleClickFavorite,
    handleCheckStatus,
    searchValidateError,
    handleChangeAddTerm,
    deleteTag,
  } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>(
    productSearchCondition && productSearchCondition?.tags
      ? productSearchCondition?.tags?.split(',')
      : [],
  );

  const handleTagCheck = (selectedTag: string, checked: boolean) => {
    if (checked && selectedTags.includes(selectedTag)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));

      return;
    }
    if (!checked && selectedTags.includes(selectedTag)) {
      const index = selectedTags.indexOf(selectedTag);
      selectedTags.splice(index, 1);
      setSelectedTags([...selectedTags]);

      return;
    }
    setSelectedTags([...selectedTags, selectedTag]);
  };

  const [initialSelectedTags, setInitialSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (!productSearchCondition || !productSearchCondition.tags) {
      setSelectedTags([]);
      setInitialSelectedTags([]);

      return;
    }

    setSelectedTags(productSearchCondition.tags.split(','));
    setInitialSelectedTags(
      JSON.parse(JSON.stringify(productSearchCondition.tags.split(','))),
    );
    // eslint-disable-next-line
  }, [productSearchCondition?.tags]);

  return (
    <Box mt={5}>
      <Content
        title="商材一覧"
        sideItem={
          productNewCreatePermission.show && (
            <Box className={classes.sideItems}>
              <PrimaryButton
                disabled={productNewCreatePermission?.disabled}
                text="新規登録"
                icon={<PlusIcon />}
                click={() => navigate('/adm/products/register')}
              />
              {/* <PrimaryButton text="一括登録" icon={<BulkIcon />} disabled /> */}
            </Box>
          )
        }
      >
        <div>
          <ProductSearchField
            searchCondition={productSearchCondition}
            handleCheckFavorite={handleCheckFavorite}
            handleCheckStatus={handleCheckStatus}
            handleChangeFreeWord={handleChangeFreeWord}
            addTags={() => setTagDialogVisible(true)}
            searchValidateError={searchValidateError}
            handleChangeAddTerm={handleChangeAddTerm}
            deleteTag={(val: string) => {
              if (deleteTag) deleteTag(val);
            }}
          />
          <Box className={classes.table}>
            <ProductsTable
              products={products}
              handleChangeSortKey={handleChangeSortKey}
              handleClickFavorite={handleClickFavorite}
              productSearchCondition={productSearchCondition}
              clickProduct={(id) => navigate(`/adm/products/${id}`)}
            />
            <Box mt={5}>
              <PaginationBar
                currentPage={
                  productSearchCondition ? productSearchCondition.page : 1
                }
                maxPage={products ? Math.ceil(products?.totalCount / rows) : 1}
                handleChangePage={(page: number) => handleChangePage(page)}
                totalCount={products?.totalCount}
              />
            </Box>
          </Box>
        </div>
      </Content>
      <TagSelectDialog
        category="Product"
        tagType="list"
        handleCheck={handleTagCheck}
        tags={tags?.productTags ? tags?.productTags : undefined}
        usedTags={undefined}
        open={tagDialogVisible}
        onClose={() => {
          setSelectedTags(JSON.parse(JSON.stringify(initialSelectedTags)));
          setTagDialogVisible(false);
        }}
        handleSubmit={(tags) => {
          setInitialSelectedTags(JSON.parse(JSON.stringify(selectedTags)));
          if (handleChangeTag) handleChangeTag(tags.join(','));
          setTagDialogVisible(false);
        }}
        selectedTags={selectedTags}
      />
    </Box>
  );
};
export default ProductsPage;

import React, { useEffect, useState } from 'react';
import {
  Box,
  CalendarText,
  InquirySelectBox,
  OneLineTextField,
  Typography,
} from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrorIcon } from 'assets/images';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import {
  INQUIRY_TYPE_MAIL,
  INQUIRY_TYPE_OTHER,
  INQUIRY_TYPE_TEL,
} from 'constants/text';
import { validDate } from 'libs/date';

interface FirstInquiryFieldProps {
  defaultValue:
    | {
        inquiryDate: string;
        inquiryTime: string;
        inquiryType: number;
      }
    | undefined;
  error: string | undefined;
  onChange: (type: 'inquiryDate' | 'inquiryType', value: string) => void;
  setErrorText: (errorText: string) => void;
  errorText: string
  setErrorDateText: (errorDateText: string) => void;
  errorDateText: string;

}

const INQUIRY_OPTIONS = [
  {
    value: 1,
    label: INQUIRY_TYPE_TEL,
  },
  {
    value: 2,
    label: INQUIRY_TYPE_MAIL,
  },
  {
    value: 3,
    label: INQUIRY_TYPE_OTHER,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',

        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
      '& > :nth-child(3)': {
        marginRight: theme.spacing(1),
      },
    },
    rightSpacing: {
      marginRight: theme.spacing(2),
    },
    inputArea: {
      '& > first-child': {
        width: 240,
      },
    },
    error: {
      marginTop: 12,
      marginLeft: '20%',
      display: 'flex',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

// 時間フォーマットチェック
const PATTERN = new RegExp(/^[0-9]*$/);

const FirstInquiryField = (props: FirstInquiryFieldProps) => {
  const { defaultValue, error, onChange, setErrorText, errorText, setErrorDateText, errorDateText } = props;
  const classes = useStyles();
  const [dateValue, setDateValue] = useState<string>('');
  const [hourMinuteValue, setHourMinuteValue] = useState<string>('');

  const checkHourMinuteFormat = (hourMinute: string) => {
    if (!hourMinute.includes(':')) {
      setErrorText('〇〇:〇〇の形式で入力してください');

      return false;
    }

    // 存在しない時刻チェック
    if (hourMinute.includes(':')) {
      const timeCheck = hourMinute.split(':');

      if (
        Number.parseInt(timeCheck[0], 10) > 23 ||
        Number.parseInt(timeCheck[1], 10) > 59
      ) {
        setErrorText('存在しない時刻です');

        return false;
      }
    }

    const isCheckedArray = hourMinute.split(':').map((v, i): boolean => {
      if (!PATTERN.test(v)) {
        setErrorText('半角数字で入力してください');

        return false;
      }

      if (i > 1 || v.length > 2 || v.length < 2) {
        // ":"が複数あるか、時間・分がhh:mmになっていない場合
        setErrorText('〇〇:〇〇の形式で入力してください');

        return false;
      }

      return true;
    });

    return !isCheckedArray.includes(false);
  };

  const handleInquiryChange = (v: string) => {
    onChange('inquiryType', v);
  };

  const handleDateChange = (date: string) => {
    let isError = false;
    isError = date.replaceAll('/', '')
      ? !validDate(date.replaceAll('/', ''))
      : false;

    // 日付フォーマットエラーあり
    if (isError) {
      setErrorDateText('日付の形式で入力してください。');

      return;
    }

    // 日付フォーマットエラー無しの場合
    if (date && validDate(date.replaceAll('/', ''))) {
      
      // エラー表示をクリア
      setErrorDateText('');
    }

    setDateValue(date.replaceAll('/', ''));

    const inquiryDate = `${date.replaceAll('/', '-')}T${hourMinuteValue}`;

    onChange('inquiryDate', inquiryDate);
  };

  const handleHourMinuteChange = (v: string) => {
    const formattedHourMinute = v;
    // フォーマット正しくないと登録ボタン非活性
    if (!checkHourMinuteFormat(formattedHourMinute)) {

      return;
    }

    const inquiryDate = `${dateValue.replaceAll('/', '-')}T${v}`;
    onChange('inquiryDate', inquiryDate);
  };

  useEffect(() => {
    if (
      dateValue ||
      hourMinuteValue ||
      !defaultValue?.inquiryDate ||
      !defaultValue
    )
      return;

    if (defaultValue.inquiryDate.includes('T')) {
      setDateValue(defaultValue.inquiryDate.split('T')[0]);
      const formattedHourMinute = defaultValue.inquiryDate
        .split('T')[1]
        .split(':')
        .slice(0, 2)
        .join(':');
      setHourMinuteValue(formattedHourMinute);
    } else if (defaultValue.inquiryDate.length === 8) {
      // dateとtimeがわかれている場合
      setDateValue(
        `${defaultValue.inquiryDate.slice(
          0,
          4,
        )}/${defaultValue.inquiryDate.slice(
          4,
          6,
        )}/${defaultValue.inquiryDate.slice(6)}`,
      );
      if (defaultValue.inquiryTime.length === 5) {
        setHourMinuteValue(
          `${defaultValue.inquiryTime.slice(
            0,
            1,
          )}:${defaultValue.inquiryTime.slice(
            1,
            3,
          )}:${defaultValue.inquiryTime.slice(3)}`,
        );
      } else if (defaultValue.inquiryTime.length === 6) {
        setHourMinuteValue(
          `${defaultValue.inquiryTime.slice(
            0,
            2,
          )}:${defaultValue.inquiryTime.slice(
            2,
            4,
          )}:${defaultValue.inquiryTime.slice(4)}`,
        );
      } else if (defaultValue.inquiryTime.length === 4) {
        setHourMinuteValue(
          `${defaultValue.inquiryTime.slice(
            0,
            2,
          )}:${defaultValue.inquiryTime.slice(2, 4)}`,
        );
      } else {
        setHourMinuteValue(
          `${defaultValue.inquiryTime.slice(
            0,
            1,
          )}:${defaultValue.inquiryTime.slice(1, 3)}`,
        );
      }
    }
  }, [dateValue, hourMinuteValue, defaultValue]);

  useEffect(() => {
    setErrorText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourMinuteValue]);

  return (
    <Box>
      <Box className={classes.root} display="flex">
        <MandatoryHeadlineLabel title="初回お問い合わせ" />
        {defaultValue && defaultValue.inquiryType ? (
          <InquirySelectBox
            className={classes.rightSpacing}
            defaultValue={defaultValue.inquiryType}
            label="問い合わせ方法を選択"
            options={INQUIRY_OPTIONS}
            handleChange={handleInquiryChange}
          />
        ) : (
          <InquirySelectBox
            className={classes.rightSpacing}
            label="問い合わせ方法を選択"
            options={INQUIRY_OPTIONS}
            handleChange={handleInquiryChange}
          />
        )}
        <Box>
          <CalendarText
            value={dateValue}
            handleChangeDate={handleDateChange}
            error={errorDateText}
          />
        </Box>
        <Box className={classes.inputArea}>
          <OneLineTextField
            error={errorText}
            label="時刻を入力"
            value={hourMinuteValue}
            handleChangeText={setHourMinuteValue}
            handleBlur={handleHourMinuteChange}
          />
        </Box>
      </Box>
      {error && (
        <Box className={classes.error}>
          <ErrorIcon />
          <Typography className={classes.errorText}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FirstInquiryField;

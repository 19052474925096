import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Modules, Usecases } from 'core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  ButtonLink,
  Tooltip,
  Typography,
  Box,
  OpenStatusLabel,
} from 'components/atoms';
import { FavoriteButton, PreviewButton } from 'components/molecules';
import FavoriteGrayIcon from 'assets/images/icn_favorite_gray.svg';
import { SortDownIcon } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { LpSearchCondition, LpView } from 'core/domain/lp';
import { formatDateISO8601 } from 'libs/date';
import clsx from 'clsx';
import { clearLpDetail } from 'core/modules/lpDetail';
import { clearProductDetail } from 'core/modules/productDetail';
import { hooks } from 'libs';
import { CODE_MODE_REFERENCE } from 'constants/code';
import { clearProductExamples } from 'core/modules/productExamples';

/**
 * Interface
 */

export interface LpsTableProps {
  clickLp?: (id: number, approved: boolean) => void;
  handleChangeSortKey: (sortKey: string) => void;
  handleClickFavorite: (lpId: number, checked: boolean) => void;
  handleClickLpProductInterestedCount: (lpId: number) => void;
  handleClickLpProductDocumentDownloadCount: (lpId: number) => void;
  lpSearchCondition: LpSearchCondition | null;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      minWidth: 1366,
      // tableLayout: 'fixed',
    },
    header: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.gray[400],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
      borderBottomColor: theme.palette.gray[700],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      '&tr,th': {
        minWidth: 92,
        height: 46,
        whiteSpace: 'pre',
        fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
        padding: `0 ${theme.spacing(2)}px`,
        lineHeight: '18px',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
        color: theme.palette.gray[800],
        fontWeight: 'bold',
      },
      '& .MuiTableSortLabel-root': {
        width: '100%',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
        color: theme.palette.gray[800],
        '&>svg': {
          marginLeft: 'auto',
        },
      },
    },
    headerFavorite: {
      width: 16,
      height: 16,
      display: 'block',
    },
    body: {
      '&>tr': {
        height: 74,
      },
      '&>tr, td': {
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'pre-wrap',
      },
    },
    link: {
      '&>td, a': {
        maxWidth: 262,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&>td, span': {
        width: '100%',
      },
    },
    overflow: {
      '&>td, p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
    },
    midCell: {
      '&>td, p': {
        maxWidth: 150,
      },
    },
    lpName: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 337,
      minWidth: 205,
    },
    status: {
      width: 96,
    },
  }),
);

interface TableHeaderItems {
  title: string;
  sortKey: string;
}

const TABLE_HEADER_ITEMS: Array<TableHeaderItems> = [
  {
    title: 'お気に入り',
    sortKey: 'favoriteCount',
  },
  {
    title: '公開ステータス',
    sortKey: 'open',
  },
  {
    title: 'ランディングページID',
    sortKey: 'lpId',
  },
  {
    title: 'ランディングページ名',
    sortKey: 'title',
  },
  {
    title: '得意先名',
    sortKey: 'tokuisakiName',
  },
  {
    title: 'ページ\n閲覧数',
    sortKey: 'accessCount',
  },
  {
    title: '興味アリ\n獲得数',
    sortKey: 'interestedCount',
  },
  {
    title: '資料\nダウンロード数',
    sortKey: 'downloadCount',
  },
  {
    title: 'お問い合わせ数',
    sortKey: 'inquiryCount',
  },
  {
    title: '公開日',
    sortKey: 'openDate',
  },
  {
    title: '担当者',
    sortKey: 'lpTantoUser',
  },
  {
    title: '部署',
    sortKey: 'deptName',
  },
];

const LPsTable = React.memo((props: LpsTableProps) => {
  const classes = useStyles({});
  const {
    clickLp,
    handleChangeSortKey,
    handleClickFavorite,
    lpSearchCondition,
    handleClickLpProductInterestedCount,
    handleClickLpProductDocumentDownloadCount,
  } = props;

  const lps = useSelector((state: Modules.AppState) => state.lps, shallowEqual);

  // プレビューボタン用ここから(DashboardReactionCardItemから流用)
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const lpView = useSelector(
    (state: Modules.AppState) => state.lpView,
    shallowEqual,
  );
  const lpSettings = useSelector(
    (state: Modules.AppState) => state.lpSettings,
    shallowEqual,
  );
  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
    shallowEqual,
  );
  const [previewData, setPreviewData] = useState<LpView>();

  const handleClick = (contentId: number) => {
    dispatch(clearLpDetail());
    dispatch(clearProductDetail());

    if (!token || !userInfo) return;
    dispatch(
      // eslint-disable-next-line
      Usecases.lps.sendPostApprovedLpView(
        token,
        userInfo.tenantId,
        contentId,
        CODE_MODE_REFERENCE,
      ),
    );
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
  };

  const handleClose = () => {
    setPreviewData(undefined);
  };

  useEffect(() => {
    if (!userInfo || !productDetail) return;

    dispatch(clearProductExamples());
    dispatch(
      Usecases.products.sendGetProductExamples(
        token,
        userInfo.tenantId,
        productDetail.productId,
      ),
    );
  }, [dispatch, productDetail, token, userInfo]);

  useEffect(() => {
    if (!lpSettings) return;
    setPreviewData(lpView || undefined);
  }, [lpSettings, lpView, productDetail]);
  // プレビューボタン用ここまで

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            {TABLE_HEADER_ITEMS.map((item, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active
                  direction={lpSearchCondition?.sort_order}
                  IconComponent={() =>
                    item.sortKey === lpSearchCondition?.sort_key &&
                    lpSearchCondition?.sort_order === 'asc' ? (
                      <SortDownIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <SortDownIcon />
                    )
                  }
                  onClick={() => handleChangeSortKey(item.sortKey)}
                >
                  {item.title !== 'お気に入り' ? (
                    item.title
                  ) : (
                    <img
                      className={classes.headerFavorite}
                      src={FavoriteGrayIcon}
                    />
                  )}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {lps &&
            lps.lps.map((lp, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FavoriteButton
                    favorite={lp.favorite}
                    favoriteCount={lp.favoriteCount}
                    click={() => handleClickFavorite(lp.lpId, !lp.favorite)}
                  />
                </TableCell>
                
                <TableCell>
                <Box className={classes.status}>
                  <OpenStatusLabel status={lp.openStatus} />
                </Box>
                </TableCell>

                <TableCell>
                  <Tooltip title={lp.lpId || ''} arrow placement="top">
                    <span style={{ display: 'flex' }}>{lp.lpId}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.link}>
                  <Box className={classes.lpName}>
                    <ButtonLink
                      text={lp.title}
                      tooltipText={lp.title}
                      click={() => {
                        if (clickLp) clickLp(lp.lpId, lp.approved);
                      }}
                    />
                    <PreviewButton
                      handleClick={() => handleClick(lp.lpId)}
                      handleCloseClick={handleClose}
                      previewData={previewData}
                      templateId={Number(previewData?.designTemplateId)}
                    />
                  </Box>
                </TableCell>
                <TableCell className={clsx(classes.midCell, classes.overflow)}>
                  <Tooltip title={lp.tokuisakiName || ''}>
                    <Typography variant="body2">{lp.tokuisakiName}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      lp.lpReaction
                        ? Number(lp.lpReaction.accessCount).toLocaleString()
                        : '-'
                    }
                    arrow
                    placement="top"
                  >
                    <span style={{ display: 'flow-root' }}>
                      {lp.lpReaction
                        ? Number(lp.lpReaction.accessCount).toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Box
                    textAlign="right"
                    width="100%"
                    marginLeft="auto"
                    marginRight={0}
                  >
                    <ButtonLink
                      alignRight
                      text={
                        lp.lpReaction
                          ? Number(
                              lp.lpReaction.interestedCount,
                            ).toLocaleString()
                          : '-'
                      }
                      tooltipText={
                        lp.lpReaction
                          ? Number(
                              lp.lpReaction.interestedCount,
                            ).toLocaleString()
                          : '-'
                      }
                      click={() => {
                        handleClickLpProductInterestedCount(lp.lpId);
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <ButtonLink
                    alignRight
                    text={
                      lp.lpReaction
                        ? Number(lp.lpReaction.downloadCount).toLocaleString()
                        : '-'
                    }
                    tooltipText={
                      lp.lpReaction
                        ? Number(lp.lpReaction.downloadCount).toLocaleString()
                        : '-'
                    }
                    click={() => {
                      handleClickLpProductDocumentDownloadCount(lp.lpId);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      lp.lpReaction
                        ? Number(lp.lpReaction.inquiryCount).toLocaleString()
                        : '-'
                    }
                    arrow
                    placement="top"
                  >
                    <span style={{ display: 'flow-root' }}>
                      {lp.lpReaction
                        ? Number(lp.lpReaction.inquiryCount).toLocaleString()
                        : '-'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {lp.openDate
                    ? formatDateISO8601(lp.openDate.split('.')[0])
                    : ''}
                </TableCell>
                <TableCell className={clsx(classes.midCell, classes.overflow)}>
                  <Tooltip
                    title={lp.lpTantoUserName || ''}
                    arrow
                    placement="top"
                  >
                    <Typography variant="body2">
                      {lp.lpTantoUserName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={clsx(classes.midCell, classes.overflow)}>
                  <Tooltip title={lp.deptName || ''} arrow placement="top">
                    <Typography variant="body2">{lp.deptName}</Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
export default LPsTable;

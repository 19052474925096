/**
 * Black / White
 */
const SALAD_BAR_WHITE = '#FFFFFF';
const SALAD_BAR_BLACK = '#000000';

/* GREEN */
// Colorで使えるindexが決まっていたので、グラデーション順は諦めた
const SALAD_BAR_GREEN_600 = '#62B372';
const SALAD_BAR_GREEN_700 = '#E6F7EA';
const SALAD_BAR_GREEN_800 = '#48A45A'; // Main
const SALAD_BAR_GREEN_900 = '#298E41';

/* GRAY */
const SALAD_BAR_GRAY_900 = '#262622';
const SALAD_BAR_GRAY_800 = '#82827E';
const SALAD_BAR_GRAY_700 = '#B0B0AB';
const SALAD_BAR_GRAY_600 = '#DCDCDA';
const SALAD_BAR_GRAY_500 = '#EDEDE6';
const SALAD_BAR_GRAY_400 = '#F7F7F5';
const SALAD_BAR_GRAY_300 = '#FFFFFF';

/* PINK */
const SALAD_BAR_PINK_900 = '#DB3218';
const SALAD_BAR_PINK_800 = '#F04848';
const SALAD_BAR_PINK_700 = '#FFE1DD';
const SALAD_BAR_PINK_600 = '#FFF2F0';

/* ORANGE */
const SALAD_BAR_ORANGE_900 = '#D87F24';
const SALAD_BAR_ORANGE_800 = '#EB9632';
const SALAD_BAR_ORANGE_700 = '#FCF4EC';

/* YELLOW */
const SALAD_BAR_YELLOW_900 = '#D69B23';
const SALAD_BAR_YELLOW_800 = '#F2C24F';
const SALAD_BAR_YELLOW_700 = '#FDF6E3';

/* BROWN */
const SALAD_BAR_BROWN_900 = '#865E3B';
const SALAD_BAR_BROWN_800 = '#F7F2ED';

/* BLUE */
const SALAD_BAR_BLUE_900 = '#3D9FCB';

/* RED */
const SALAD_BAR_RED_800 = '#FFF2F0';
const SALAD_BAR_RED_900 = '#DB3218';

export {
  SALAD_BAR_WHITE,
  SALAD_BAR_BLACK,
  SALAD_BAR_GREEN_700,
  SALAD_BAR_GREEN_600,
  SALAD_BAR_GREEN_800,
  SALAD_BAR_GREEN_900,
  SALAD_BAR_GRAY_300,
  SALAD_BAR_GRAY_400,
  SALAD_BAR_GRAY_500,
  SALAD_BAR_GRAY_600,
  SALAD_BAR_GRAY_700,
  SALAD_BAR_GRAY_900,
  SALAD_BAR_GRAY_800,
  SALAD_BAR_PINK_600,
  SALAD_BAR_PINK_700,
  SALAD_BAR_PINK_800,
  SALAD_BAR_PINK_900,
  SALAD_BAR_ORANGE_700,
  SALAD_BAR_ORANGE_800,
  SALAD_BAR_ORANGE_900,
  SALAD_BAR_YELLOW_700,
  SALAD_BAR_YELLOW_800,
  SALAD_BAR_YELLOW_900,
  SALAD_BAR_BROWN_800,
  SALAD_BAR_BROWN_900,
  SALAD_BAR_BLUE_900,
  SALAD_BAR_RED_800,
  SALAD_BAR_RED_900,
};

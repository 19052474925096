import React from 'react';
import { Box, Typography } from 'components/atoms';
import { AttentionIcon } from 'assets/images';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface DashboardCommentaryProps {
  balloon: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    image: {
      width: 150,
      height: 150,
    },
    balloon: {
      position: 'relative',
      height: 100,
      margin: '15px 0',
      padding: '30px 0 0 40px',
      color: theme.palette.gray[800],
      fontSize: 14,
      background: ' #fff',
      borderRadius: 15,
      flex: 1,
      whiteSpace: 'pre-line',
      boxSizing: 'border-box',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '-10px',
        border: '15px solid transparent',
        borderTop: '2px solid #e0edff',
        borderColor: '#fff',
        transform: 'rotate(45deg)',
      },
    },
  }),
);

const DashboardCommentary = (props: DashboardCommentaryProps) => {
  const { balloon } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <img src={AttentionIcon} className={classes.image} />
      <Typography className={classes.balloon}>{balloon}</Typography>
    </Box>
  );
};

export default DashboardCommentary;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import * as React from 'react'
import { AuthContext } from 'contexts'

export const useAuth = () => {
  const auth = React.useContext(AuthContext)

  return auth
}
/* eslint-enable */

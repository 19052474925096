/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Usecases, Modules } from 'core';
import MainPage from 'components/pages/Main';

import { hooks } from 'libs';
import { clearAuthError } from 'core/modules/authError';
import { clearError } from 'core/modules/error';

import { setUserInfo } from 'core/modules/userInfo';
import { CODE_MODE_REFERENCE } from 'constants/code';

export default function MainPageContainer() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: Modules.AppState) => state.userInfo);
  const authError = useSelector((state: Modules.AppState) => state.authError);
  const error = useSelector((state: Modules.AppState) => state.error);
  const token = hooks.useAuth().accessToken;
  const { navigate } = hooks.useNavigate();
  const { logout } = hooks.useAuth();

  // ユーザー情報取得
  useEffect(() => {
    // FIXME: 動作確認用ダミーデータセット
    // dispatch(
    //   setUserInfo({
    //     tenantId: 'TOPPAN',
    //     userId: 1,
    //     displayName: '凸版テナント管理者',
    //     deptName: '所属１',
    //     profileImage: {
    //       profileImageId: 14,
    //       url: 'https://cdn.dev.salad-bar.net/TOPPAN/14',
    //     },
    //     lpApprovalUsers: {
    //       step1ApprovalUserId: 2,
    //       step2ApprovalUserId: 3,
    //       step3ApprovalUserId: 4,
    //     },
    //     materialApprovalUsers: {
    //       step1ApprovalUserId: 1,
    //       step2ApprovalUserId: undefined,
    //       step3ApprovalUserId: undefined,
    //     },
    //     lpNoticeApply: true,
    //     lpNoticeRemand: true,
    //     lpNoticeApproved: true,
    //     lpNoticeDocumentRequest: true,
    //     lpAuthor: false,
    //     lpApprover: false,
    //     materialAuthor: true,
    //     materialApprover: true,
    //     tenantAdmin: false,
    //   }),
    // );

    // token取得
    dispatch(Usecases.user.sendGetUser(token));
  }, []);

  // LPお問い合わせ挨拶文/LPお問い合わせ項目設定取得/LP基本設定取得/
  // ダッシュボード集計期間/LPデザインテンプレート一覧取得
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      Usecases.settings.sendGetInquiriesGreetText(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetInquiryForms(
        token,
        userInfo.tenantId,
        CODE_MODE_REFERENCE,
      ),
    );
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
    // dispatch(Usecases.settings.sendGetAddTerm(token, userInfo.tenantId));
    dispatch(
      Usecases.settings.sendGetDesignTemplates(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetDesignAllTemplates(token, userInfo.tenantId),
    );
  }, [userInfo]);

  /**
   * 認証エラーダイアログを閉じる
   */
  const handleCloseAuthErrorDialog = () => {
    dispatch(clearAuthError());
    logout();
  };

  /**
   * APIエラーダイアログを閉じる
   */
  const handleCloseErrorDialog = () => {
    navigate('/adm/dashboard');
    dispatch(clearError());
  };

  return (
    <MainPage
      userInfo={userInfo}
      error={error}
      authError={authError}
      handleCloseErrorDialog={handleCloseErrorDialog}
      handleCloseAuthErrorDialog={handleCloseAuthErrorDialog}
    />
  );
}
/* eslint-enable */

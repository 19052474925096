/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography'
import { ErrorIcon } from 'assets/images'
import { Box, Typography } from 'components/atoms'

/**
 * Interface
 */
export interface ErrorTextProps {
  error: string
}
/**
* Style
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900]
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
)

/**
* Presenter
*/

export default function ErrorText(props: ErrorTextProps) {

  const classes = useStyles({})
  const { error } = props

  return (
    <Box className={classes.error}>
      <ErrorIcon />
      <Typography className={classes.errorText}>{error}</Typography>
    </Box>
  )
}/* eslint-enable */

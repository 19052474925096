/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Divider,
  Grid,
  Box,
  ButtonLink,
  RecommendButton,
} from 'components/atoms';
import {
  PreviewHeightFixedButton,
  ProductExampleLabel,
} from 'components/molecules';
import { StatusBar } from 'components/organisms';
import { LinkIcon, NoImage } from 'assets/images';
import { TYPOGRAPHY } from 'constants/index';
import { ExampleDetail } from 'core/domain/example';
import {
  formatDateISO8601,
  formatDateYYYYMMDD,
  formateTimeHHMMColon,
} from 'libs/date';
import { MaterialImage } from 'core/domain/material';
import {
  CODE_MATERIAL_IMAGE_TYPE_IMAGE,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO,
  CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK,
} from 'constants/code';
import { LpView } from 'core/domain/lp';
import { hooks } from 'libs';
import { shallowEqual, useSelector } from 'react-redux';
import { Modules } from 'core';
import { SALAD_BAR_DESKTOP_FONT_SIZE_100 } from 'constants/typography';

/**
 * Interface
 */

interface ExampleDetailListProps {
  exampleDetail: ExampleDetail;
  handleRecommendedUpdate: () => void;
  isDialog?: boolean;
  previewData?: LpView;
  handleDelete?: () => void;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.green[800],
    },
    productSubtitle: {
      margin: '33px 0px 0px 0px',
    },
    productName: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: theme.palette.gray[900],
      marginBottom: theme.spacing(1),
    },
    preview: {
      float: 'right',
    },
    thickBorder: {
      marginTop: 10,
      height: 3,
      backgroundColor: theme.palette.gray[600],
      borderColor: theme.palette.gray[600],
    },
    subtitle: {
      color: theme.palette.gray[800],
      fontWeight: 'bold',
    },
    caption: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_75,
      color: theme.palette.gray[800],
    },
    usePeriod: {
      margin: '0px 16px',
    },
    description: {
      color: theme.palette.gray[900],
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
    container: {
      margin: '16px 0px',
    },
    videoLink: {
      height: '100%',
      marginLeft: '16px',
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
    marginBottom: {
      marginBottom: 8,
    },
    exampleImage: {
      width: 245,
      height: 164,
    },
    image: {
      width: 245,
      height: 163,
      objectFit: 'contain',
    },
    selectBox: {
      width: 140,
      border: '1px solid #ccc',
      borderRadius: 4,
      padding: '8px',
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_100,
    },
  }),
);

export default function ExampleDetailList(props: ExampleDetailListProps) {
  const classes = useStyles({});
  const {
    exampleDetail,
    handleRecommendedUpdate,
    isDialog,
    previewData,
    handleDelete,
  } = props;
  const {
    exampleReaction,
    exampleImage,
    exampleCreatedUser,
    materialPeriod,
    products,
  } = exampleDetail;
  const { deletePermission, step, approvalUsers, enableRecommend } =
    hooks.useExampleDetailPermission();

  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  const getImageUrl = (materialImage: MaterialImage) => {
    switch (materialImage.materialImageType) {
      case CODE_MATERIAL_IMAGE_TYPE_IMAGE:
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO:
        return materialImage.url;
      case CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK:
        return materialImage.thumbnailUrl
          ? materialImage.thumbnailUrl
          : NoImage;
      default:
        break;
    }

    return undefined;
  };

  // プレビュー用プルダウン
  const designTemplates = useSelector(
    (state: Modules.AppState) => state.designTemplates,
  );
  const [templateId, setTemplateId] = useState<number>(1);
  useEffect(() => {
    // 初期化
    setTemplateId(designTemplates ? designTemplates[0].designTemplateId : 1);
  }, []);
  const handleChange = (value: any) => {
    setTemplateId(Number(value));
  };

  return (
    <div>
      <StatusBar
        isDialog={isDialog}
        status={materialPeriod.materialStatus}
        approvalUsers={approvalUsers}
        step={step}
        lpCount={exampleReaction ? exampleReaction?.useLpCount : 0}
        deletePermission={deletePermission}
        handleDelete={handleDelete}
      />
      <Box mt={4} mb={1} display="flex" marginBottom="9px">
        <RecommendButton
          disabled={!enableRecommend}
          recommended={exampleDetail.recommended}
          click={handleRecommendedUpdate}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Typography
            variant="h4"
            style={{ marginTop: 'auto', marginBottom: '8px' }}
          >
            {exampleDetail.exampleMaterialId}
          </Typography>
          <Typography variant="h4" className={classes.productName}>
            {exampleDetail.exampleName}
          </Typography>
          <Typography
            display="block"
            variant="caption"
            className={`${classes.caption} ${classes.marginBottom}`}
          >
            <span>{`登録日：${formatDateISO8601(
              exampleDetail.createdDate,
            )}`}</span>
            <span
              className={classes.usePeriod}
            >{`利用期限：${formatDateYYYYMMDD(
              materialPeriod.usePeriodDate,
            )} ${formateTimeHHMMColon(materialPeriod.usePeriodTime)}`}</span>
            <span
              className={classes.usePeriod}
            >{`公開期限：${formatDateYYYYMMDD(
              materialPeriod.openPeriodDate,
            )} ${formateTimeHHMMColon(materialPeriod.openPeriodTime)}`}</span>
          </Typography>
        </Box>
        {!isDialog && (
          <Box marginBottom="5px">
            <Box marginBottom="5px">
              <select
                defaultValue={1}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                className={classes.selectBox}
                value={templateId}
              >
                {designTemplates?.map((option) => (
                  <option
                    key={option.designTemplateId}
                    value={option.designTemplateId}
                  >
                    {option.designTemplateName}
                  </option>
                ))}
              </select>
            </Box>
            <Box ml="auto" display="flex" flexWrap="wrap" alignItems="center">
              <Typography className={classes.previewCaption}>
                プレビュー
              </Typography>
              <PreviewHeightFixedButton
                previewData={previewData}
                templateId={templateId}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Divider classes={{ root: classes.thickBorder }} />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            導入お客様名
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {exampleDetail.customerName}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {/* 権限によって登録者/部署を表示・非表示制御する */}
      {(userInfo?.tenantAdmin ||
        userInfo?.materialAuthor ||
        userInfo?.materialApprover) && (
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              className={classes.subtitle}
              display="inline"
            >
              登録者/部署
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={classes.description}
              display="inline"
            >
              {exampleCreatedUser.deptName
                ? `${exampleCreatedUser.userName}／${exampleCreatedUser.deptName}`
                : `${exampleCreatedUser.userName}`}
            </Typography>
          </Grid>
        </Grid>
      )}
      {(userInfo?.tenantAdmin ||
        userInfo?.materialAuthor ||
        userInfo?.materialApprover) && <Divider />}
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            お問い合わせ先
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {exampleDetail.contact}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            className={classes.subtitle}
            display="inline"
          >
            事例説明
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={classes.description}
            display="inline"
          >
            {exampleDetail.exampleContent}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.container}>
        <Typography variant="body2" className={classes.subtitle}>
          イメージ画像・動画
        </Typography>
        {/* <img className={classes.exampleImage} src={exampleImage ? exampleImage.url : NoImage} /> */}
        {exampleImage && (
          <Grid container spacing={0} className={classes.container}>
            <Grid item>
              {exampleImage.materialImageType ===
              CODE_MATERIAL_IMAGE_TYPE_VIDEO ? (
                <video
                  src={getImageUrl(exampleImage)}
                  className={classes.image}
                  poster={
                    exampleImage.thumbnailUrl
                      ? exampleImage.thumbnailUrl
                      : undefined
                  }
                />
              ) : (
                <img
                  src={getImageUrl(exampleImage)}
                  className={classes.image}
                />
              )}
            </Grid>
            {exampleImage.materialImageType ===
              CODE_MATERIAL_IMAGE_TYPE_VIDEO_LINK && (
              <Grid item>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.videoLink}
                >
                  <Box>
                    <ButtonLink
                      text={exampleImage.url}
                      icon={<LinkIcon />}
                      iconPosition="end"
                      click={() => window.open(exampleImage.url)}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <Divider />
      <Box className={classes.container}>
        <Typography variant="body2" className={classes.subtitle}>
          関連商材
        </Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            {products &&
              products.map(
                (product, index) =>
                  product && (
                    <Grid item xs={4} key={index}>
                      <ProductExampleLabel text={product.productName} />
                    </Grid>
                  ),
              )}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
/* eslint-enable */

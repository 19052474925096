import React, { useCallback, useEffect, useState } from 'react';
import NormalEditSelectProductPage from 'components/pages/NormaleditSelectProduct';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ProductsQueryParameter } from 'core/domain/product';
import { Modules, Usecases } from 'core';
import { clearProducts } from 'core/modules/products';
import { clearProductTags } from 'core/modules/productTags';
import { clearProductDetail } from 'core/modules/productDetail';
import { hooks } from 'libs';
import { setProductTagsRequest } from 'core/modules/productTagsRequest';
import { CODE_MODE_REFERENCE } from 'constants/code';

const NormalEditPageContainer = () => {
  const [requestParam, setRequestParam] = useState<ProductsQueryParameter>({
    free_word: '',
    tags: '',
    sort_key: 'updatedDate',
    sort_order: 'desc',
    recommended: false,
    product_status: '5',
  } as ProductsQueryParameter);
  const [productId, setProductId] = useState(-1);
  const products = useSelector(
    (state: Modules.AppState) => state.products,
    shallowEqual,
  );
  const productTagsRequest = useSelector(
    (state: Modules.AppState) => state.productTagsRequest,
    shallowEqual,
  );
  const productTags = useSelector(
    (state: Modules.AppState) => state.productTags,
    shallowEqual,
  );
  const productDetail = useSelector(
    (state: Modules.AppState) => state.productDetail,
  );
  const productExamples = useSelector(
    (state: Modules.AppState) => state.productExamples,
    shallowEqual,
  );
  const newLp = useSelector(
    (state: Modules.AppState) => state.newLp,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  const token = hooks.useAuth().accessToken;
  const dispatch = useDispatch();

  // 商材情報一覧取得

  useEffect(() => {
    if (!userInfo) return undefined;
    dispatch(
      Usecases.products.sendGetProducts(token, userInfo.tenantId, {
        ...requestParam,
        page: 1,
        row: 20,
      }),
    );

    return () => {
      dispatch(clearProducts());
    };
  }, [requestParam, userInfo, dispatch, token]);

  // 商材タグ取得リクエストパラメーター初期値設定
  useEffect(() => {
    dispatch(setProductTagsRequest({ tagType: 'open', sortKey: 'tag' }));
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  // 商材タグ取得
  useEffect(() => {
    if (!userInfo || !productTagsRequest.tagType || !productTagsRequest.sortKey)
      return undefined;
    dispatch(
      Usecases.products.sendGetProductTags(
        token,
        userInfo.tenantId,
        productTagsRequest,
      ),
    );

    return () => {
      dispatch(clearProductTags());
    };
  }, [productTagsRequest, userInfo, dispatch, token]);

  // 商材詳細情報取得
  useEffect(() => {
    if (productId === -1 || !userInfo) return undefined;
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        productId,
        CODE_MODE_REFERENCE,
      ),
    );

    return () => {
      dispatch(clearProductDetail());
    };
  }, [productId, userInfo, dispatch, token]);

  /**
   * 商材オススメ更新
   */
  const handleRecommendedUpdate = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductRecommended(
        token,
        userInfo.tenantId,
        productId,
        !productDetail.recommended,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        productId,
        CODE_MODE_REFERENCE,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail, userInfo]);

  /**
   * 商材お気に入り更新
   */
  const handleClickFavorite = useCallback(async () => {
    if (!productDetail || !userInfo) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      Usecases.products.sendPatchProductFavorite(
        token,
        userInfo.tenantId,
        productId,
        !productDetail.favorite,
      ),
    );
    dispatch(
      Usecases.products.sendGetProductDetail(
        token,
        userInfo.tenantId,
        productId,
        CODE_MODE_REFERENCE,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail, userInfo]);

  // タグxボタン押下
  const deleteTag = (val: string) => {
    if (!requestParam || !requestParam.tags) {
      setRequestParam({
        ...requestParam,
        tags: '',
      });

      return;
    }

    const newTags = requestParam.tags
      .split(',')
      .filter((tag: string) => tag !== val);
    setRequestParam({
      ...requestParam,
      tags: newTags.join(','),
    });
  };

  return (
    <NormalEditSelectProductPage
      newLp={newLp}
      // handleAddProduct={handleAddProduct}
      handleRecommendedUpdate={handleRecommendedUpdate}
      setProductId={setProductId}
      productDetail={productDetail}
      productExamples={productExamples}
      requestParam={requestParam}
      setRequestParam={setRequestParam}
      products={products}
      productTags={productTags}
      handleClickFavorite={handleClickFavorite}
      deleteTag={deleteTag}
    />
  );
};

export default NormalEditPageContainer;

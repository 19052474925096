import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import RadiusBox from '../../atoms/RadiusBox';
import { Box } from '../../atoms';

/**
 * Interface
 */
interface ServiceUsageProps {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  value: number | string;
}
/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.gray[600]}`,
      display: 'inline-block',
      width: '100%',
    },
    rootChild: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5),
    },
    title: {
      color: theme.palette.gray[800],
      fontSize: 14,
      width: '100%',
    },
    value: {
      color: theme.palette.green[800],
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 28,
      marginRight: '25px',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const ServiceUsage = (props: ServiceUsageProps) => {
  const { icon, title, value } = props;
  const classes = useStyles();

  return (
    <RadiusBox className={classes.root}>
      <Box className={classes.rootChild}>
        <RadiusBox className={classes.icon}>{icon}</RadiusBox>
        <Box ml={2} mr={4} className={classes.title} whiteSpace="pre-wrap">
          {title}
        </Box>
        <span className={classes.value}>{value}</span>
      </Box>
    </RadiusBox>
  );
};

export default ServiceUsage;

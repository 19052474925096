import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Tooltip } from 'components/atoms'
import { FavoriteIcon, FavoriteOutlineIcon } from 'assets/images'

/**
 * Interface
 */
export interface FavoriteButton{
  disabled?: boolean
  favorite?: boolean
  favoriteCount?: number
  click?: () => void
}

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.yellow[900],
      padding: 0,
      margin: 0,
      paddingBottom: 1,
      '&:hover': {
        backgroundColor: 'Transparent',
        boxShadow: 'none',
      },
    },
    buttonRoot: {
      marginLeft: 4,
      minWidth: 34
    },
  }),
)

/**
 * Presenter
 */

const FavoriteButton = (props: FavoriteButton) => {

  const classes = useStyles({})
  const { disabled, favorite, favoriteCount, click } = props

  return (
    <Tooltip title={favorite ? "お気に入り登録を解除する" : "お気に入りに登録する"} arrow placement="top">
      <span style={disabled ? { pointerEvents: 'none' } : {}}>
        <Button
          classes={{root:classes.buttonRoot}}
          className={classes.root}
          disabled={disabled}
          onClick={click}
        >
          {favorite ? <FavoriteIcon/> : <FavoriteOutlineIcon/>}
          <span style={{marginLeft:4, height: 21}}>{`${favoriteCount?.toLocaleString() || '0'}`}</span>
        </Button>
      </span>
    </Tooltip>
  )
}

export default FavoriteButton;
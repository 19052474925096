import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  SecondaryButton,
  PrimaryButton,
  Typography,
} from 'components/atoms';
import { useLocation } from 'react-router-dom';

import {
  ConfirmDialog,
  Content,
  InquiryFormsItem,
  InquirySelectDialog,
  NoticeDialog,
  SuccessDialog,
} from 'components/organisms';
import { inquiryItemOptions } from 'core/domain/inquiries';
import { InquiryForm } from 'core/domain/settings';
import { hooks } from 'libs';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginBottom: 25,
      color: theme.palette.gray[900],
    },
  }),
);

const typeOptions = [
  {
    inputType: 1,
    label: 'テキストボックス',
  },
  {
    inputType: 2,
    label: 'テキストエリア',
  },
  {
    inputType: 3,
    label: 'ラジオボタン',
  },
  {
    inputType: 4,
    label: 'チェックボックス',
  },
  {
    inputType: 5,
    label: 'プルダウン',
  },
  {
    inputType: 8,
    label: 'カレンダー',
  },
];

interface FormEditPageProps {
  inquiryFormsProps: Array<InquiryForm> | null;
  handleSubmit: (data: Array<InquiryForm>) => void;
  choicesDialogOpen: boolean;
  handleCloseChoicesDialogOpen: () => void;
  successDialogOpen: boolean;
  handleCloseSuccessDialogOpen: () => void;
}

interface selectedOptions {
  index?: number;
  options?: Array<inquiryItemOptions>;
}

const FormEditPage: React.FC<FormEditPageProps> = (props) => {
  const location = useLocation();

  const navigate = hooks.useNavigate();
  const {
    inquiryFormsProps,
    handleSubmit,
    choicesDialogOpen,
    handleCloseChoicesDialogOpen,
    successDialogOpen,
    handleCloseSuccessDialogOpen,
  } = props;
  const [inquiryForms, setInquiryFormsForms] = useState<Array<InquiryForm>>([]);
  const [showOptionsDialog, setOptionsDialog] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<selectedOptions>({});
  const [openExitConfirmDialog, setOpenExitConfirmDialog] = useState(false);
  const [initialOrderdInquiryForms, setInitialOrderdInquiryForms] = useState<
    InquiryForm[] | null | undefined
  >(undefined);
  const [nextPagePath, setNextPagePath] = useState<string>('');
  const [goAnotherPage, setGoAnotherPage] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [firstTime, setFirstTime] = useState(false);

  const classes = useStyles({});

  useEffect(() => {
    if (!inquiryFormsProps) return;

    const newData = inquiryFormsProps.slice();

    setInquiryFormsForms(
      newData.map((item) => {
        if (item.inquiryItemId === 3 || item.inquiryItemId === 6)
          return {
            ...item,
            displayed: true,
            required: true,
          };

        if (item.inquiryItemId === 16)
          return {
            ...item,
            required: false,
          };

        return item;
      }),
    );

    if (!firstTime) setFirstTime(true);
    else
      setInitialOrderdInquiryForms(
        JSON.parse(JSON.stringify(inquiryFormsProps)),
      );
  }, [firstTime, inquiryFormsProps]);

  // 入力の変更を検知
  useEffect(() => {
    const res = navigate.block((pathname: string) => {
      // 入力情報と保存済み情報が存在することを確認
      // ページ遷移が許可されていればblockしない

      // 遷移先が同じパスなら遷移自体キャンセル
      if (pathname === location.pathname) return undefined;

      setNextPagePath(pathname);

      if (
        (!inquiryForms && !initialOrderdInquiryForms) ||
        isRegister ||
        goAnotherPage
      )
        return undefined;

      if (
        inquiryForms &&
        initialOrderdInquiryForms &&
        JSON.stringify(inquiryForms) !==
          JSON.stringify(initialOrderdInquiryForms)
      ) {
        setOpenExitConfirmDialog(true);

        return false;
      }

      return undefined;
    });

    return () => res();
  }, [
    goAnotherPage,
    initialOrderdInquiryForms,
    navigate,
    inquiryForms,
    isRegister,
    location.pathname,
  ]);

  useEffect(() => {
    if (goAnotherPage) navigate.navigate(nextPagePath);
  }, [goAnotherPage, navigate, nextPagePath]);

  const handleCheck = (i: number) => {
    if (!inquiryForms) return;
    const newData = inquiryForms.slice();
    newData[i].displayed = !inquiryForms[i].displayed;

    if (!newData[i].displayed) newData[i].required = false;
    setInquiryFormsForms(newData);
    setIsRegister(false);
  };

  const handleSelect = (i: number, value: string) => {
    if (!inquiryForms) return;
    const newData = inquiryForms.slice();
    newData[i].inputType = typeOptions.filter(
      (item) => item.label === value,
    )[0].inputType;
    setInquiryFormsForms(newData);
    setIsRegister(false);
  };

  const handleChangeRequired = (i: number) => {
    if (!inquiryForms) return;
    const newData = inquiryForms.slice();
    newData[i].required = !inquiryForms[i].required;
    if (newData[i].required) newData[i].displayed = true;
    setInquiryFormsForms(newData);
    setIsRegister(false);
  };

  const orderdInquiryForms = useMemo(
    () =>
      inquiryForms.sort((first, second) => {
        if (first.displayOrder > second.displayOrder) {
          return 1;
        }
        if (first.displayOrder < second.displayOrder) {
          return -1;
        }

        return 0;
      }),
    [inquiryForms],
  );

  const changeOrder = (index: number, derection: boolean) => {
    if (!inquiryForms) return;
    const newData = orderdInquiryForms.slice();
    if (derection) {
      const current = newData[index].displayOrder;
      const to = newData[index - 1].displayOrder;

      newData[index - 1].displayOrder = current;
      newData[index].displayOrder = to;
    } else {
      const to = newData[index + 1].displayOrder;
      const current = newData[index].displayOrder;

      newData[index + 1].displayOrder = current;
      newData[index].displayOrder = to;
    }
    setInquiryFormsForms(newData);
    setIsRegister(false);
  };

  const isFirstLast = (index: number) => {
    if (!inquiryForms) return '';
    if (index === 0) return 'first';
    if (inquiryForms.length - 1 === index) return 'last';

    return '';
  };

  const handleChangeName = (index: number, text: string) => {
    if (!inquiryForms) return;
    const newData = inquiryForms.slice();
    newData[index].inquiryItemName = text;
    setInquiryFormsForms(newData);
    setIsRegister(false);
  };

  const handleOpenOptionsDialog = (
    options: Array<inquiryItemOptions>,
    index: number,
  ) => {
    setSelectedOptions({ index, options });
    setOptionsDialog(true);
    setIsRegister(false);
  };
  const closeOptionDialog = () => setOptionsDialog(false);
  const changeOptions = (
    options: Array<inquiryItemOptions> | undefined,
    index: number | undefined,
  ) => {
    if (!inquiryForms || options === undefined || index === undefined) return;
    const newData = inquiryForms.slice();
    newData[index].inquiryItemOptions = options;
    setInquiryFormsForms(newData);
    setOptionsDialog(false);
    setIsRegister(false);
  };

  const handleChangeOptions = (
    index: number | undefined,
    options: Array<inquiryItemOptions> | undefined,
  ) => {
    changeOptions(options, index);
  };

  return (
    <>
      <SuccessDialog
        title="保存しました"
        open={successDialogOpen}
        handleClose={handleCloseSuccessDialogOpen}
      />
      <NoticeDialog
        open={choicesDialogOpen}
        title="自由項目の選択肢情報を入力してください"
        handleClose={handleCloseChoicesDialogOpen}
      />
      <ConfirmDialog
        buttonText="終了する"
        open={openExitConfirmDialog}
        title="編集画面を終了します"
        text="保存されていない入力は破棄されますが"
        handleCancel={() => setOpenExitConfirmDialog(false)}
        handleSubmit={() => {
          setInquiryFormsForms([]);
          setGoAnotherPage(true);
          setOpenExitConfirmDialog(false);
        }}
      />
      <Box mt={5}>
        {showOptionsDialog && (
          <InquirySelectDialog
            index={selectedOptions?.index}
            open={showOptionsDialog}
            formOptions={selectedOptions?.options}
            handleCancel={closeOptionDialog}
            handleSubmit={handleChangeOptions}
          />
        )}
        <Content title="お問い合わせフォーム管理">
          <div>
            <Box>
              <Typography variant="h4" className={classes.title}>
                設定項目の選択・編集
              </Typography>
              <Box>
                お問い合わせフォームに入れる項目にチェックを入れてください。
              </Box>
              <Box>
                回答を必須にする項目には右の「必須」にチェックを入れてください。右端の∧∨をクリックすると、項目の順番を入れ替えることができます。
              </Box>
            </Box>
            {orderdInquiryForms
              ? orderdInquiryForms.map((formItem, index) => (
                  <InquiryFormsItem
                    key={index}
                    item={formItem}
                    isFree={formItem.inquiryItemId >= 18}
                    isFirstLast={isFirstLast(index)}
                    changeOrder={(direction: boolean) =>
                      changeOrder(index, direction)
                    }
                    handleCheck={() => handleCheck(index)}
                    handleSelect={(value: string) => handleSelect(index, value)}
                    handleChangeRequired={() => handleChangeRequired(index)}
                    handleChangeName={(text: string) =>
                      handleChangeName(index, text)
                    }
                    handleOpenOptionsDialog={(
                      options: Array<inquiryItemOptions>,
                    ) => handleOpenOptionsDialog(options, index)}
                  />
                ))
              : ''}
            <Grid container spacing={2} justify="center">
              <Grid item xs={4}>
                <SecondaryButton
                  text="キャンセル"
                  click={() => {
                    if (orderdInquiryForms && !initialOrderdInquiryForms) {
                      setOpenExitConfirmDialog(true);
                    }
                    navigate.navigate('/adm/tenant/dashboard');
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <PrimaryButton
                  text="保存"
                  click={() => {
                    handleSubmit(inquiryForms);
                    setIsRegister(true);
                    setInitialOrderdInquiryForms(
                      JSON.parse(JSON.stringify(inquiryFormsProps)),
                    );
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Content>
      </Box>
    </>
  );
};

export default FormEditPage;

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { MaterialWorkflow } from 'core/domain/material'

const initialState = null

export function createInitialState(): Array<MaterialWorkflow> | null {
  return initialState
}

export const SET_LP_WORKFLOWS = 'lp-workflows/set' as const
export const CLEAR_LP_WORKFLOWS = 'lp-workflows/clear' as const

export function setLpWorkflows(data: Array<MaterialWorkflow>) {
  return {
    type: SET_LP_WORKFLOWS,
    payload: {
      data,
    },
  }
}

export function clearLpWorkflows() {
  return {
    type: CLEAR_LP_WORKFLOWS,
  }
}

type SetLpWorkflowsAction = ReturnType<typeof setLpWorkflows>
type ClearLpWorkflowsAction = ReturnType<typeof clearLpWorkflows>
type Action = SetLpWorkflowsAction | ClearLpWorkflowsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LP_WORKFLOWS:
      return action.payload.data.reverse()
    case CLEAR_LP_WORKFLOWS:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

import React, { useEffect, useState } from 'react';
import VariousSettingsPage from 'components/pages/VariousSettingsPage';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modules, Usecases } from 'core';
import { removeIncorrectTagEntry } from 'libs/validation';
import { hooks } from 'libs';
import { sendGetVariousSettings } from 'core/usecases/settings';
import { clearVariousSettings } from 'core/modules/variousSettings';
import { setNewVariousSettings } from 'core/modules/newVariousSettings';
import { VariousSettings } from 'core/domain/variousSettings';

const VariousSettingsPageContainer = () => {
  const dispatch = useDispatch();
  const token = hooks.useAuth().accessToken;
  const variousSettings = useSelector(
    (state: Modules.AppState) => state.variousSettings,
    shallowEqual,
  );
  const newVariousSettings = useSelector(
    (state: Modules.AppState) => state.newVariousSettings,
    shallowEqual,
  );
  const error = useSelector(
    (state: Modules.AppState) => state.error,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );

  const [isRegister, setIsRegister] = useState<boolean>(false);

  const handleVariousSettingsRegister = (
    requestData: VariousSettings,
    type: 'post' | 'put',
  ) => {
    const processingRequestData: VariousSettings = {
      ...requestData,
      tenantLpMainImage: requestData.tenantLpMainImage.filter(
        (image) => image.data || image.url,
      ),
      authenticationMark: requestData.authenticationMark.filter(
        (image) =>
          image.authenticationMarkFileData || image.authenticationMarkFileUrl,
      ),
      tokuisakiTitleOptions: removeIncorrectTagEntry(
        requestData.tokuisakiTitleOptions,
      ),
      tantoCompanyOptions: removeIncorrectTagEntry(
        requestData.tantoCompanyOptions,
      ),
      tagManagerHead: removeIncorrectTagEntry(requestData.tagManagerHead || ''),
      tagManagerBody: removeIncorrectTagEntry(requestData.tagManagerBody || ''),
    };

    if (!userInfo) return;
    if (type === 'post') {
      dispatch(
        Usecases.settings.sendPostVariousSettings(
          token,
          userInfo.tenantId,
          processingRequestData,
        ),
      );
    } else if (type === 'put') {
      dispatch(
        Usecases.settings.sendPutVariousSettings(
          token,
          userInfo.tenantId,
          processingRequestData,
        ),
      );
    }
  };

  // LPプレビュー用(登録後再取得)
  useEffect(() => {
    if (!userInfo|| !variousSettings) return;
    dispatch(Usecases.settings.sendGetLpSettings(token, userInfo.tenantId));
    dispatch(
      Usecases.settings.sendGetInquiriesGreetText(token, userInfo.tenantId),
    );
    dispatch(
      Usecases.settings.sendGetInquiryForms(token, userInfo.tenantId, 1),
    );
  }, [dispatch, token, userInfo, variousSettings]);

  useEffect(() => {
    if (!userInfo || variousSettings) return;
    dispatch(sendGetVariousSettings(token, userInfo.tenantId));
  }, [dispatch, token, userInfo, variousSettings]);

  useEffect(() => {
    dispatch(clearVariousSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!variousSettings || isRegister) return;
    dispatch(setNewVariousSettings({ ...variousSettings }));

    // eslint-disable-next-line consistent-return
    // return () => {
    //   dispatch(clearNewVariousSettings());
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, variousSettings]);

  return (
    <VariousSettingsPage
      error={error}
      handleVariousSettingsRegister={handleVariousSettingsRegister}
      newVariousSettings={newVariousSettings}
      isRegister={isRegister}
      setIsRegister={setIsRegister}
    />
  );
};

export default VariousSettingsPageContainer;

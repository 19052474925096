/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import { Lps } from 'core/domain/lp'

const initialState = null

export function createInitialState(): Lps | null {
  return initialState
}

export const SET_LPS_SUBORDINATES = 'lps-subordinates/set' as const
export const CLEAR_LPS_SUBORDINATES = 'lps-subordinates/clear' as const

export function setLpsSubordinates(data: Lps) {
  return {
    type: SET_LPS_SUBORDINATES,
    payload: {
      data,
    },
  }
}

export function clearLpsSubordinates() {
  return {
    type: CLEAR_LPS_SUBORDINATES,
  }
}

type SetLpsSubordinatesAction = ReturnType<typeof setLpsSubordinates>
type ClearLpsSubordinatesAction = ReturnType<typeof clearLpsSubordinates>
type Action = SetLpsSubordinatesAction | ClearLpsSubordinatesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LPS_SUBORDINATES:
      return action.payload.data
    case CLEAR_LPS_SUBORDINATES:
      return initialState
    default:
      return state
  }
}
/* eslint-enable */

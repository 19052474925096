import { Lps } from 'core/domain/lp';

const initialState = null;

export const createInitialState = (): Lps | null => initialState;

export const SET_DASHBOARD_LPS = 'dashboard-lps/set' as const;
export const CLEAR_DASHBOARD_LPS = 'dashboard-lps/clear' as const;

export const setDashboardLps = (data: Lps) => ({
  type: SET_DASHBOARD_LPS,
  payload: {
    data,
  },
});

export const clearDashboardLps = () => ({
  type: CLEAR_DASHBOARD_LPS,
});

type SetDashboardLpsAction = ReturnType<typeof setDashboardLps>;
type ClearDashboardLpsAction = ReturnType<typeof clearDashboardLps>;
type Action = SetDashboardLpsAction | ClearDashboardLpsAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_DASHBOARD_LPS:
      return action.payload.data;
    case CLEAR_DASHBOARD_LPS:
      return initialState;
    default:
      return state;
  }
};

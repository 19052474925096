import { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Divider,
  DialogActions,
  Box,
  Dialog,
  Typography,
  SearchTextField,
  FormControl,
  RadioGroup,
  CommonRadioButton,
  IconButton,
  PrimaryButton,
  SupportTextLabel,
  HeadlineLabel,
  SubHeadlineLabel,
  SecondaryButton,
} from 'components/atoms';
import { CheckIcon, CloseIcon } from 'assets/images';


export interface NormalEditCustomerNameDialogProps {
  open: boolean;
  onClose: () => void;
  tokuisakis: string[] | undefined;
  handleSubmit: (value: string) => void;
  tokuisakiParam: string;
  setTokuisakiParam: React.Dispatch<React.SetStateAction<string>>;
}
/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: 1076,
      margin: 0,
      overflowY: 'unset',
    },
    icon: {
      width: 32,
      height: 32,
      padding: 0,
      '&:hover': {
        '& #Icon_ionic-ios-arrow-back': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        '& #Icon_ionic-ios-arrow-back-2': {
          // eslint-disable-next-line
          fill: theme.palette.green[900],
        },
        // eslint-disable-next-line
        backgroundColor: theme.palette.green[700],
        borderColor: '#D9EFDB',
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
    scroll: {
      overflowX: 'auto',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
      '& span svg path': {
        fill: theme.palette.common.white,
      },
    },
    company: {
      overflowY: 'auto',
    },
    container: {
      width: 1028,
      height: 360,
      borderRadius: 4,
      // eslint-disable-next-line
      backgroundColor: theme.palette.gray[400],
      // eslint-disable-next-line
      borderColor: theme.palette.gray[500],
      borderStyle: 'solid',
      borderWidth: 1,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        // eslint-disable-next-line
        fill: theme.palette.green[800],
      },
    },
  }),
);

/**
 * Presenter
 */

const NormalEditCustomerNameDialog = (
  props: NormalEditCustomerNameDialogProps,
) => {
  const classes = useStyles({});
  const {
    open,
    onClose,
    tokuisakis,
    handleSubmit,
    tokuisakiParam,
    setTokuisakiParam,
  } = props;
  const [current, setCurrent] = useState('');

  useEffect(() => {
    if (!open) setCurrent('');
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={onClose}
    >
      <Box mt={3} ml={3}>
        <HeadlineLabel text="ランディングページの表示得意先名を選択してください" />
      </Box>
      <Box mt={2} mr={3} ml={3} mb={3} className={classes.container}>
        <Box width={696} mx="auto">
          <Box>
            <Box mt={3}>
              <SubHeadlineLabel text="フリーワード検索" />
              <SearchTextField
                maxLength={100}
                value={tokuisakiParam}
                handleChangeText={(text) => {
                  setTokuisakiParam(text);
                  setCurrent('');
                }}
                label="検索ワードを入力"
              />
            </Box>
            <SupportTextLabel text="登録されている情報全体からフリーワードで検索します。" />
          </Box>
          <Box>
            <Typography variant="overline">
              表示用得意先名を選択してください
            </Typography>
            <Box height={210} pl={1} className={classes.scroll}>
              <FormControl>
                <RadioGroup
                  value={current}
                  onChange={(e, value) => setCurrent(value)}
                >
                  {tokuisakis &&
                    tokuisakis.map((tokuisaki, index) => (
                      <CommonRadioButton
                        key={index}
                        value={tokuisaki}
                        label={tokuisaki}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <DialogActions>
        <Box mt={2} mb={2} width={332} height={48} ml="auto">
          <SecondaryButton
          click={() => {onClose();
            setTokuisakiParam('');
          }}
          icon={<CloseIcon className={classes.closeIcon} />}
          text="キャンセル"
          />
        </Box>
        <Box mt={2} mb={2} width={332} height={48} mr="auto">
          <PrimaryButton
            click={() => {
              handleSubmit(current);
              setTokuisakiParam('');
            }}
            icon={<CheckIcon />}
            text="決定"
          />
        </Box>
        <IconButton
          onClick={() => {
            onClose();
            setTokuisakiParam('');
          }}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
export default NormalEditCustomerNameDialog;

import { NoticesSearchCondition } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): NoticesSearchCondition | null =>
  initialState;

// eslint-disable-next-line  camelcase
export const SET_Notices_SEARCH_CONDITION =
  'noticesSearchCondition/set' as const;

// eslint-disable-next-line  camelcase
export const CLEAR_Notices_SEARCH_CONDITION =
  'noticesSearchCondition/clear' as const;

export const setNoticesSearchCondition = (data: NoticesSearchCondition) => ({
  type: SET_Notices_SEARCH_CONDITION,
  payload: {
    data,
  },
});

export const clearNoticesSearchCondition = () => ({
  type: CLEAR_Notices_SEARCH_CONDITION,
});

type SetNoticesSearchConditionAction = ReturnType<
  typeof setNoticesSearchCondition
>;
type ClearNoticesSearchConditionAction = ReturnType<
  typeof clearNoticesSearchCondition
>;
type Action =
  | SetNoticesSearchConditionAction
  | ClearNoticesSearchConditionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    // eslint-disable-next-line  camelcase
    case SET_Notices_SEARCH_CONDITION:
      return action.payload.data;
    // eslint-disable-next-line  camelcase
    case CLEAR_Notices_SEARCH_CONDITION:
      return initialState;

    default:
      return state;
  }
};

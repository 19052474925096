/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */


import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { hooks } from 'libs'
import InitializePage from 'components/pages/Initialize'

/**
 * By using the isLoggedIn of children() of this component, we switch Routes based on the time a user has already logged in or not
 * We're going to confirm login if we can't verify session in auth: Auth like the first accessing or reloading
 *
 * - If we have not confirmed session in Auth0, we're going to confirm that
 * - If we have confirmed session in Auth0, just diplay a page
 * - If we have not confirmed session in Auth0, we're going to redirect a user to the login page
 */

type ChildrenFn = ({ isLoggedIn }: { isLoggedIn: boolean }) => React.ReactElement

interface RouteGroupSwitchProps extends RouteComponentProps {
  children: ChildrenFn
}

function RouteGroupSwitch(props: RouteGroupSwitchProps) {
  const { authenticated, sessionChecked, renewSession } = hooks.useAuth()
  const [isSessionCheckRequired, setSessionCheckRequired] = React.useState<boolean>(!sessionChecked)

  React.useEffect(() => {
    if (isSessionCheckRequired) {
      renewSession()
        .then(() => {
          // Login session recovered
          setSessionCheckRequired(false)
        })
        .catch(() => {
          // No login session, login required
          setSessionCheckRequired(false)
        })
    }
  }, [renewSession, isSessionCheckRequired])

  if (isSessionCheckRequired) {
    return <InitializePage />
  }

  return props.children({ isLoggedIn: authenticated })
}

export default withRouter(RouteGroupSwitch)
/* eslint-enable */

import {
  Box,
  Typography,
  OneLineTextField,
  SupportTextLabel,
  MandatoryLabel,
} from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_300 } from 'constants/typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface UserEmailFieldProps {
  email: string;
  error?: string;
  disableLabel?: boolean;
  handleMailAddressChange: (v: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleArea: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_300,
      fontWeight: 'bold',
    },
    formArea: {
      marginTop: theme.spacing(3),
      '& > :first-child': {
        width: 340,
        marginRight: theme.spacing(2),
      },
    },
  }),
);

const UserEmailField = (props: UserEmailFieldProps) => {
  const { email, error, handleMailAddressChange, disableLabel } = props;
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classes.titleArea}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        <Typography className={classes.title} variant="caption">
          メールアドレス
        </Typography>
        <MandatoryLabel margin="0 0 0 8px" />
      </Box>
      <Box className={classes.formArea}>
        <OneLineTextField
          label="メールアドレスを入力"
          maxLength={254}
          value={email}
          error={error}
          handleChangeText={handleMailAddressChange}
        />
        {!disableLabel && (
          <SupportTextLabel text="半角英数字で入力してください。" />
        )}
      </Box>
    </Box>
  );
};

export default UserEmailField;

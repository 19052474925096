import { useEffect } from 'react';
import Notices from 'components/pages/Notices';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { setNoticesQueryParameter } from 'core/modules/noticesQueryParameter';
import { clearNotices } from 'core/modules/notices';

import { Usecases, Modules } from 'core';

import { NoticesSortKey } from 'core/domain/notices';
import { hooks } from 'libs';

const pageRows = 10;

/**
 * Presenter
 */

const NoticesContainer = () => {
  const dispatch = useDispatch();
  const notices = useSelector(
    (state: Modules.AppState) => state.notices,
    shallowEqual,
  );
  const noticesQueryParameter = useSelector(
    (state: Modules.AppState) => state.noticesQueryParameter,
    shallowEqual,
  );
  const userInfo = useSelector(
    (state: Modules.AppState) => state.userInfo,
    shallowEqual,
  );
  // 現在入力されているフリーワードを取得
  const freeWord = useSelector(
    (state: Modules.AppState) => state.freeWord,
    shallowEqual,
  );

  const token = hooks.useAuth().accessToken;

  useEffect(
    () => () => {
      dispatch(clearNotices());
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  // お知らせ一覧取得
  useEffect(() => {
    if (!noticesQueryParameter) {
      dispatch(
        setNoticesQueryParameter({
          page: 1,
          row: pageRows,
          sort_key: 'publishStartDateTime',
          sort_order: 'desc',
          unread: true,
        }),
      );

      return;
    }

    if (!userInfo) return;

    dispatch(
      Usecases.notices.sendGetNoticesTable(
        token,
        userInfo.tenantId,
        noticesQueryParameter,
      ),
    );
  }, [noticesQueryParameter, userInfo, dispatch, token]);

  // お知らせ一覧取得リクエストパラメーターpage、row設定
  useEffect(() => {
    if (notices && notices.notices.length === 0 && notices.totalCount > 0) {
      if (!noticesQueryParameter) {
        dispatch(
          setNoticesQueryParameter({
            page: 1,
            row: pageRows,
            sort_key: 'publishStartDateTime',
            sort_order: 'desc',
            unread: true,
          }),
        );
      } else {
        const latestpage = Math.ceil(notices?.totalCount / pageRows);
        dispatch(
          setNoticesQueryParameter({
            ...noticesQueryParameter,
            page: latestpage,
            row: pageRows,
          }),
        );
      }
    }
  }, [notices, dispatch, noticesQueryParameter]);

  /**
   * ページネーション押下時処理
   * @param page
   */
  const handleChangePage = (page: number) => {
    dispatch(
      setNoticesQueryParameter({
        ...noticesQueryParameter,
        page,
        row: pageRows,
      }),
    );
  };
  /**
   * ソート変更処理
   * @param sortKey ソート対象テーブル列
   */
  const handleChangeSortKey = (sortKey: string) => {
    let order = 'asc';
    if (noticesQueryParameter?.sort_key === (sortKey as NoticesSortKey)) {
      order = noticesQueryParameter.sort_order === 'asc' ? 'desc' : 'asc';
    }

    dispatch(
      setNoticesQueryParameter({
        ...noticesQueryParameter,
        sort_key: sortKey as NoticesSortKey,
        sort_order: order as 'asc' | 'desc',
      }),
    );
  };

  /**
   * フリーワード検索押下時、パラメーターにセット
   * @param freeWord
   */
  const handleChangeFreeWord = (freeWord: string) => {
    dispatch(
      setNoticesQueryParameter({
        ...noticesQueryParameter,
        free_word: freeWord,
      }),
    );
  };

  /**
   * 未読のみ表示ラジオボタン切り替え時
   * リクエストパラメーターに設定
   * @param checked
   */
  const handleChangeUnreadOnlySwitch = (checked: boolean) => {
    dispatch(
      setNoticesQueryParameter({
        ...noticesQueryParameter,
        unread: checked,
        // 検索タイミングで最新のフリーワード検索を行う
        free_word: freeWord?.freeWord,
      }),
    );
  };

  return (
    <Notices
      notices={notices}
      rows={pageRows}
      noticesQueryParameter={noticesQueryParameter}
      handleChangeSortKey={handleChangeSortKey}
      handleChangePage={handleChangePage}
      handleChangeFreeWord={handleChangeFreeWord}
      handleChangeUnreadOnlySwitch={handleChangeUnreadOnlySwitch}
    />
  );
};
export default NoticesContainer;

import React from 'react';
import {
  MultipleLinesTextField,
  OneLineTextField,
  SubHeadlineLabel,
  SupportTextLabel,
} from 'components/atoms';
import { MandatoryHeadlineLabel } from 'components/molecules';

interface TagInputFieldProps {
  error?: string | undefined;
  handleChange: (v: string) => void;
  maxLength: number;
  multiline?: boolean;
  label: string;
  isMandatory?: boolean;
  value: string;
}

const TagInputField = (props: TagInputFieldProps) => {
  const {
    error,
    handleChange,
    maxLength,
    multiline = false,
    label,
    isMandatory = false,
    value,
  } = props;

  return (
    <>
      {!isMandatory ? (
        <SubHeadlineLabel text={label} />
      ) : (
        <MandatoryHeadlineLabel title={label} />
      )}
      {multiline ? (
        <MultipleLinesTextField
          handleChangeText={handleChange}
          maxLength={maxLength}
          label={`${label}を入力`}
          value={value}
          rows={3}
        />
      ) : (
        <OneLineTextField
          error={error}
          handleChangeText={handleChange}
          maxLength={maxLength}
          label={`${label}を入力`}
          value={value}
        />
      )}
      <SupportTextLabel text="複数登録の場合はカンマ（,)で区切ってください" />
    </>
  );
};

export default TagInputField;

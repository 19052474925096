import { LpSearchDefaultAddTerm } from 'core/domain/lp';

const initialState = null;

export const createInitialState = (): LpSearchDefaultAddTerm | null =>
  initialState;

// eslint-disable-next-line camelcase
export const SET_Lp_Search_Default_Add_Term =
  'lp-search-default-add-term/set' as const;
// eslint-disable-next-line camelcase
export const CLEAR_Lp_Search_Default_Add_Term =
  'lp-search-default-add-term/clear' as const;

export const setLpSearchDefaultAddTerm = (data: LpSearchDefaultAddTerm) => ({
  type: SET_Lp_Search_Default_Add_Term,
  payload: {
    data,
  },
});

export const clearLpSearchDefaultAddTerm = () => ({
  type: CLEAR_Lp_Search_Default_Add_Term,
});

type SetLpSearchDefaultAddTermAction = ReturnType<
  typeof setLpSearchDefaultAddTerm
>;
type ClearLpSearchDefaultAddTermAction = ReturnType<
  typeof clearLpSearchDefaultAddTerm
>;
type Action =
  | SetLpSearchDefaultAddTermAction
  | ClearLpSearchDefaultAddTermAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    // eslint-disable-next-line camelcase
    case SET_Lp_Search_Default_Add_Term:
      return action.payload.data;
    // eslint-disable-next-line camelcase
    case CLEAR_Lp_Search_Default_Add_Term:
      return initialState;
    default:
      return state;
  }
};

/* 2021-07-07以降、本ファイルを編集する場合は下記eslint-disableと末尾のeslint-enableのコメントを削除すること */
/* eslint-disable */

import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogActions,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from 'components/atoms';
import { PreviewButton } from 'components/molecules';
import { TYPOGRAPHY } from 'constants/index';
import { CloseIcon, CheckIcon } from 'assets/images';
import { LpView } from 'core/domain/lp';

/**
 * Interface
 */

export interface ApplyDialogProps {
  open: boolean;
  title: string;
  contentTitle: string;
  handleCancel: () => void;
  handleApply: () => void;
  previewData?: LpView;
  templateId?: number;
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      paddingTop: '0px !important',
      transform: 'initial !important',
    },
    dialog: {
      width: 806,
      height: 264,
      padding: '32px 24px 0 24px',
      margin: 0,
    },
    title: {
      fontWeight: 'bold',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_300,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 756,
      height: 34,
      borderRadius: 4,
      background: theme.palette.gray[400],
      borderColor: theme.palette.gray[700],
      borderStyle: 'solid',
      borderWidth: 1,
      padding: theme.spacing(3),
    },
    contentTitle: {
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_50,
    },
    previewCaption: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.gray[800],
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
      '& .MuiIconButton-root': {
        backgroundColor: 'inherit',
      },
    },
    actions: {
      padding: 0,
    },
    closeIcon: {
      width: 14,
      height: 14,
      '& path': {
        fill: theme.palette.green[800],
      },
    },
  }),
);

export default function ApplyDialog(props: ApplyDialogProps) {
  const classes = useStyles({});
  const {
    title,
    contentTitle,
    open,
    handleCancel,
    handleApply,
    previewData,
    templateId,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 16 } }}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      open={open}
      onClose={() => handleCancel()}
    >
      <Box>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
        <Box mt={4} className={classes.content}>
          <Typography variant="caption" className={classes.contentTitle}>
            {contentTitle}
          </Typography>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography className={classes.previewCaption}>
              プレビュー
            </Typography>
            <PreviewButton previewData={previewData} templateId={templateId} />
          </Box>
        </Box>
        <DialogActions className={classes.actions}>
          <Box p={0} display="flex" mx="auto" mt={4}>
            <Box width={332} height={48}>
              <SecondaryButton
                icon={<CloseIcon className={classes.closeIcon} />}
                click={() => handleCancel()}
                text="キャンセル"
              />
            </Box>
            <Box width={332} height={48} ml={2}>
              <PrimaryButton
                disabled={isSubmitting}
                icon={<CheckIcon />}
                click={() => {
                  setIsSubmitting((prev) => !prev);
                  handleApply();
                  setTimeout(() => {
                    setIsSubmitting((prev) => !prev);
                  }, 1000);
                }}
                text="承認する"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
/* eslint-enable */

import { ManageUsersSearchCondition } from 'core/domain/user';

const initialState = null;

export const createInitialState = (): ManageUsersSearchCondition | null =>
  initialState;

export const SET_MANAGE_USERS_SEARCH_CONDITION =
  'manage-search-search-condition/set' as const;
export const CLEAR_MANAGE_USERS_SEARCH_CONDITION =
  'manage-search-search-condition/clear' as const;

export const setManageUsersSearchCondition = (
  data: ManageUsersSearchCondition,
) => ({ type: SET_MANAGE_USERS_SEARCH_CONDITION, payload: { data } });

export const clearManageUsersSearchCondition = () => ({
    type: CLEAR_MANAGE_USERS_SEARCH_CONDITION,
  });

type SetManageUsersSearchConditionAction = ReturnType<
  typeof setManageUsersSearchCondition
>;
type ClearManageUsersSearchConditionAction = ReturnType<
  typeof clearManageUsersSearchCondition
>;
type Action =
  | SetManageUsersSearchConditionAction
  | ClearManageUsersSearchConditionAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_MANAGE_USERS_SEARCH_CONDITION:
      return action.payload.data;
    case CLEAR_MANAGE_USERS_SEARCH_CONDITION:
      return initialState;
    default:
      return state;
  }
};

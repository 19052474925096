import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

/**
 * Interface
 */

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      borderRadius: theme.spacing(2),
    },
  }),
);

const RadiusBox = (props: BoxProps) => {
  const classes = useStyles({});

  // eslint-disable-next-line react/jsx-props-no-spreading,react/destructuring-assignment
  return <Box {...props} className={clsx(classes.root, props.className)} />;
};
export default RadiusBox;

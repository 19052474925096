import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Divider, List, PaginationBar } from 'components/atoms';
import { MailHistoryListItem } from 'components/molecules';

import { MailHistory } from 'core/domain/lp';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '10px',
    },
    divider: {
      marginTop: '16px',
      marginBottom: '16px',
    },
  }),
);

interface MailHistoryListProps {
  mailHistory: MailHistory;
  handleMailHistoryPageChange: (page: number) => void;
  pagination?: boolean;
}

const MailHistoryList = (props: MailHistoryListProps) => {
  const { mailHistory, pagination, handleMailHistoryPageChange } = props;
  const classes = useStyles({});
  const [currentMailHistoryPage, setCurrentMailHistoryPage] =
    useState<number>(1);

  const handleChangeMailSendPage = (page: number) => {
    setCurrentMailHistoryPage(page);
    if (!pagination) return;

    handleMailHistoryPageChange(page);
  };

  

  return (
    <div>
      <List>
        {mailHistory.mails.map((history, index) => (
          <div key={index}>
            <MailHistoryListItem
              reserveDate={history.mailSendReserveDateTime}
              sendDate={history.mailSendDateTime}
              name={history.mailSenderName}
              subject={history.mailSubject}
              message={history.mailText}
            />
            {index + 1 !== mailHistory.totalCount && (
              <Divider className={classes.divider} />
            )}
          </div>
        ))}
      </List>
      {mailHistory && (
        <Box mt={5}>
          <PaginationBar
            currentPage={currentMailHistoryPage}
            maxPage={Math.ceil(mailHistory.totalCount / 5)}
            handleChangePage={handleChangeMailSendPage}
          />
        </Box>
      )}
    </div>
  );
};

export default MailHistoryList;

import { SearchFilter } from 'core/domain/trashes';

const initialState = null;

export const createInitialState = (): SearchFilter[] | null => initialState;

export const SET_TRASHES_SEARCH_DATA_TYPE = 'trashes-search-data-type/set' as const;
export const CLEAR_TRASHES_SEARCH_DATA_TYPE = 'trashes-search-data-type/clear' as const;

export const setTrashesSearchDataType = (data: SearchFilter[]) => ({
    type: SET_TRASHES_SEARCH_DATA_TYPE,
    payload: {
      data,
    },
  });

export const clearTrashesSearchDataType = () => ({
    type: CLEAR_TRASHES_SEARCH_DATA_TYPE,
  });

type SetTrashesSearchDataTypeAction = ReturnType<typeof setTrashesSearchDataType>;
type ClearTrashesSearchDataTypeAction = ReturnType<typeof clearTrashesSearchDataType>;
type Action = SetTrashesSearchDataTypeAction | ClearTrashesSearchDataTypeAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TRASHES_SEARCH_DATA_TYPE:
      return action.payload.data;
    case CLEAR_TRASHES_SEARCH_DATA_TYPE:
      return initialState;
    default:
      return state;
  }
};

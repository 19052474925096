import { TagsRequest } from 'core/domain/tag';

const initialState: TagsRequest = {
  tagType: 'list',
  sortKey: 'tag',
};

export const createInitialState = (): TagsRequest => initialState;

export const SET_CUSTOMER_TAGS_REQUEST = 'customer-tags-request/set' as const;
export const CLEAR_CUSTOMER_TAGS_REQUEST =
  'customer-tags-request/clear' as const;

export const setCustomerTagsRequest = (data: TagsRequest) => ({
  type: SET_CUSTOMER_TAGS_REQUEST,
  payload: {
    data,
  },
});

export const clearCustomerTagsRequest = () => ({
  type: CLEAR_CUSTOMER_TAGS_REQUEST,
});

type SetCustomerTagsRequestAction = ReturnType<typeof setCustomerTagsRequest>;
type ClearCustomerTagsRequestAction = ReturnType<
  typeof clearCustomerTagsRequest
>;
type Action = SetCustomerTagsRequestAction | ClearCustomerTagsRequestAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_CUSTOMER_TAGS_REQUEST:
      return action.payload.data;
    case CLEAR_CUSTOMER_TAGS_REQUEST:
      return initialState;
    default:
      return state;
  }
};

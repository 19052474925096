import { Notices } from 'core/domain/notices';

const initialState = null;

export const createInitialState = (): Notices | null => initialState;

export const SET_HEADER_NOTICES = 'headerNotices/set' as const;
export const CLEAR_HEADER_NOTICES = 'headerNotices/clear' as const;

export const setHeaderNotices = (data: Notices) => ({
  type: SET_HEADER_NOTICES,
  payload: {
    data,
  },
});

export const clearHeaderNotices = () => ({
  type: CLEAR_HEADER_NOTICES,
});

type SetHeaderNoticesAction = ReturnType<typeof setHeaderNotices>;
type ClearHeaderNoticesAction = ReturnType<typeof clearHeaderNotices>;
type Action = SetHeaderNoticesAction | ClearHeaderNoticesAction;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_HEADER_NOTICES:
      return action.payload.data;
    case CLEAR_HEADER_NOTICES:
      return initialState;
    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react'
import { Box, InquiryMultipleLinesTextField, OneLineTextField, SubHeadlineLabel } from 'components/atoms'
import { MandatoryHeadlineLabel } from 'components/molecules'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface InquiryItemsFieldProps {
  defaultValue: string | undefined;
  error: string | undefined;
  inquiryItemId: number;
  isMandatory?: boolean;
  multiline?: boolean;
  onChange: (n: number, s: string, v: string) => void;
  setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > :first-child': {
        width: '20%',
      },
    },
    unMandatoryRoot: {
      '& > :first-child': {
        margin: 0,
      },
    },
    mandatoryRoot: {
      '& > :first-child': {
        '& > :first-child': {
          margin: 0,
          marginRight: theme.spacing(1),
        },
        '& > :nth-child(2)': {
          margin: 0,
        },
      },
    },
    oneLine: {
      '& > :nth-child(2)': {
        width: 320,
      },
    },
    multiLine: {
      '& > :nth-child(2)': {
        width: 520,
      },
    },
  }),
);

const PATTERN = new RegExp(/^[0-9]*$/)

const InquiryItemsInputField = (props: InquiryItemsFieldProps) => {
  const { defaultValue, error, inquiryItemId, isMandatory = false, multiline = false, onChange, setIsDisabled, title, disabled } = props
  const classes = useStyles()
  const [value, setValue] = useState<string>(defaultValue || "")
  const [numError, setNumError] = useState<string>("")

  useEffect(() => {
    if (value) return

    setValue(defaultValue || "")
  }, [value, defaultValue])

  return (
    <Box
      className={
        `${classes.root}
        ${isMandatory ? classes.mandatoryRoot : classes.unMandatoryRoot}
        ${multiline ? classes.multiLine : classes.oneLine}`
      }
      display="flex"
      alignItems="start"
    >
      {
        isMandatory ? <MandatoryHeadlineLabel title={title} /> : <SubHeadlineLabel text={title}/>
      }
      {
        multiline
          ? (
            <Box>
              <InquiryMultipleLinesTextField
                error={error}
                rows={7}
                value={value}
                handleChangeText={(v: string) => {
                  setValue(v)
                  onChange(inquiryItemId, title, v)
                }}
                disabled={disabled}
              />
            </Box>
          )
          : (
            <Box>
              <OneLineTextField
                error={numError || error}
                value={value}
                handleChangeText={(v: string) => {
                  setValue(v)
                  onChange(inquiryItemId, title, v)
                }}
                handleBlur={(v: string) => {
                  if (!(["電話番号", "郵便番号", "年齢"].includes(title)) || !setIsDisabled) return
                  if (!PATTERN.test(v)) {
                    setNumError("半角数字で入力してください")
                    onChange(inquiryItemId, title, "")
                    setIsDisabled(true)

                    return
                  }
                  setIsDisabled(false)
                  setNumError("")
                }}
                disabled={disabled}
              />
            </Box>
          )
      }
    </Box>
  )
}

export default InquiryItemsInputField

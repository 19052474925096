import { UserRegisterRequest } from '../domain/user';

const initialState = null;

export const createInitialState = (): UserRegisterRequest | null =>
  initialState;

export const SET_USER_INFO_REQ = 'user-req/set' as const;
export const CLEAR_USER_INFO_REQ = 'user-req/clear' as const;

export const setUserInfoReq = (data: UserRegisterRequest) => ({
  type: SET_USER_INFO_REQ,
  payload: {
    data,
  },
});

export const clearUserInfoReq = () => ({
  type: CLEAR_USER_INFO_REQ,
});

type SetUserInfoReq = ReturnType<typeof setUserInfoReq>;
type ClearUserInfoReq = ReturnType<typeof clearUserInfoReq>;
type Action = SetUserInfoReq | ClearUserInfoReq;

export const reducer = (state = createInitialState(), action: Action) => {
  switch (action.type) {
    case SET_USER_INFO_REQ:
      return action.payload.data;
    case CLEAR_USER_INFO_REQ:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { Box, ButtonLink, Grid, Typography } from 'components/atoms';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { hooks } from 'libs';
import { RankingLp, RankingProduct } from 'core/domain/dashboard';
import { DashboardReactionCardItem } from 'components/organisms';
import { useDispatch } from 'react-redux';
import { setLpSearchCondition } from 'core/modules/lpSearchCondition';
import { setProductSearchCondition } from 'core/modules/productSearchCondition';
import { ReactionRange } from 'components/pages/LPDetail';

interface DashboardRankingCardAreaProps {
  lps: RankingLp[] | RankingProduct[];
  icon: React.ReactNode;
  reactionRange: ReactionRange | undefined;
  title: string;
  type: 'lps' | 'products';
}

type LpType = RankingLp | RankingProduct;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
  }),
);

const DashboardRankingCardArea = (props: DashboardRankingCardAreaProps) => {
  const { lps, icon, reactionRange, title, type } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { navigate } = hooks.useNavigate();
  const hasMt = title !== 'ランディングページ ページ閲覧数' ? 5 : 0;
  const link = type === 'lps' ? '/adm/lps' : '/adm/products';
  const linkTitle =
    type === 'lps'
      ? 'ランキング順にみんなのランディングページ一覧を見る ＞'
      : 'ランキング順に商材一覧を見る ＞';
  const sortKey =
    (title.includes('ページ閲覧数') && 'accessCount') ||
    (title.includes('お問い合わせ数') && 'inquiryCount') ||
    (title.includes('興味アリ獲得数') && 'interestedCount') ||
    (title.includes('資料ダウンロード数') && 'downloadCount');

  const handleNavigate = () => {
    const commonBody = {
      page: 1,
      row: 10,
      sort_order: 'desc' as 'asc' | 'desc',
      add_term_from: reactionRange?.start || undefined,
      add_term_to: reactionRange?.end || undefined,
    };
    if (type === 'lps') {
      dispatch(
        setLpSearchCondition({
          ...commonBody,
          sort_key: sortKey as 'accessCount' | 'inquiryCount',
        }),
      );
    } else if (type === 'products') {
      dispatch(
        setProductSearchCondition({
          ...commonBody,
          sort_key: sortKey as 'interestedCount' | 'downloadCount',
        }),
      );
    }

    navigate(link);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={hasMt} mb={1}>
        <Box display="flex" alignItems="center">
          {icon}
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <ButtonLink text={linkTitle} click={handleNavigate} />
      </Box>
      <Grid container>
        {lps &&
          lps
            .sort((a: LpType, b: LpType) => a.rank - b.rank)
            .map((lp: LpType) => {
              if (lp && Object.values(lp).every((e) => e === null)) return null;

              return (
                <Grid item xs={4} key={lp.rank}>
                  <DashboardReactionCardItem content={lp} cardType="everyone" />
                </Grid>
              );
            })}
      </Grid>
    </>
  );
};

export default DashboardRankingCardArea;

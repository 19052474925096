import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TYPOGRAPHY } from 'constants/index';
import { Box, TextareaAutosize, Typography } from 'components/atoms';
import { SALAD_BAR_DESKTOP_FONT_SIZE_75 } from 'constants/typography';
import { ErrorIcon } from 'assets/images';
import clsx from 'clsx';

/**
 * Interface
 */

export interface MultipleLinesTextFieldProps {
  label?: string;
  rows: number;
  value?: string;
  error?: string;
  handleChangeText?: (text: string) => void;
  disabled?: boolean;
  maxLength?: number;
}

export type MultipleLinesTextFieldRef = {
  setFocus: () => void;
};

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      width: '100%',
      lineHeight: '21px',
      fontSize: TYPOGRAPHY.SALAD_BAR_DESKTOP_FONT_SIZE_100,
      padding: `8px 16px`,
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      boxSizing: 'border-box',
      outline: 'none',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      resize: 'none',
      '&:hover': {
        backgroundColor: theme.palette.green[700],
        borderColor: theme.palette.green[900],
        borderWidth: 2,
        borderStyle: 'solid',
      },
      '&:focus': {
        backgroundColor: theme.palette.green[700],
        borderColor: theme.palette.green[900],
        borderWidth: 2,
        borderStyle: 'solid',
      },
    },
    errorRoot: {
      color: theme.palette.red[900],
      backgroundColor: theme.palette.red[800],
      '&:hover': {
        backgroundColor: `${theme.palette.red[800]} !important`,
        borderColor: `${theme.palette.red[900]} !important`,
      },
      '&:focus': {
        backgroundColor: `${theme.palette.red[800]} !important`,
        borderColor: `${theme.palette.red[900]} !important`,
      },
    },
    error: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
      '& path': {
        fill: theme.palette.red[900],
      },
    },
    errorText: {
      marginLeft: theme.spacing(1) / 2,
      color: theme.palette.red[900],
      fontSize: SALAD_BAR_DESKTOP_FONT_SIZE_75,
      lineHeight: '16px',
    },
  }),
);

/**
 * Presenter
 */

const MultipleLinesTextField = forwardRef(
  (props: MultipleLinesTextFieldProps, ref) => {
    const classes = useStyles({});
    const { label, rows, value, handleChangeText, error, disabled, maxLength } =
      props;
    const textareaEl = useRef<HTMLTextAreaElement>(null);

    const handleChangeValue = (value: string) => {
      if (!handleChangeText) return;
      handleChangeText(value);
    };

    useImperativeHandle(ref, () => ({
      setFocus: () => {
        if (textareaEl && textareaEl.current) {
          textareaEl.current.focus();
        }
      },
    }));

    return (
      <Box>
        <TextareaAutosize
          disabled={disabled}
          className={clsx(classes.root, {
            [classes.errorRoot]: !!error,
          })}
          placeholder={label}
          rowsMin={rows}
          value={value || ''}
          onChange={(event) => handleChangeValue(event.target.value)}
          ref={textareaEl}
          maxLength={maxLength}
        />
        {error && (
          <Box className={classes.error}>
            <ErrorIcon />
            <Typography className={classes.errorText}>{error}</Typography>
          </Box>
        )}
      </Box>
    );
  },
);

export default MultipleLinesTextField;
